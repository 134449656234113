import styled from 'styled-components';
import { ModalProps } from '../../../Components/Modal/Modal';

export const EditModalContainer = styled.div`
  max-width: 22rem;
  margin-left: auto;
  margin-right: auto;
`;

export type EditUserModalProps = Pick<ModalProps, 'onClose'>;
