import React, { ReactNode } from 'react';
import { getStylesheet } from '../../../styles';
import { usePatientContext } from '../../contexts/patientContext';
import { DividerSoft, Heading, Layout, Skeleton } from '../../core';
import { IHStackProps } from '../../core/Layout';

type PagePatientHeaderProps = IHStackProps & {
  preHeading?: ReactNode;
};

/**
 * NOTE: This only works on patient routes.
 */
export const PagePatientHeader = ({
  preHeading,
  ...topStyles
}: PagePatientHeaderProps): JSX.Element => {
  const { patient, patientLoading } = usePatientContext();

  if (patientLoading) {
    return <Skeleton h={50} />;
  }

  const userName = patient?.name ?? 'Unknown User';
  const organizationName = patient?.organization?.name ?? '';

  return (
    <Layout.HStack {...styles.header} testID="page-user-header" {...topStyles}>
      <Layout.VStack {...styles.stack} space={4}>
        <Layout.VStack space={1}>
          <Heading.h2>{userName}</Heading.h2>

          <Layout.Flex {...styles.subHeader}>
            {preHeading}

            <Heading.h5>{organizationName}</Heading.h5>
          </Layout.Flex>
        </Layout.VStack>

        <DividerSoft />
      </Layout.VStack>
    </Layout.HStack>
  );
};

const styles = getStylesheet({
  header: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingY: 4,
    width: '100%',
  },

  stack: {
    width: '100%',
  },

  subHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 3,
  },
});
