import styled from 'styled-components';
import { colors } from '../../globalStyles';

export const FlairPosition = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledTextIcon = styled.img`
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
`;

export const Dot = styled.div<{ size?: string }>`
  background-color: ${({ color }) => color || 'grey'};
  border-radius: 50%;
  width: ${({ size }) => size || '0.5rem'};
  height: ${({ size }) => size || '0.5rem'};
  margin-right: 0.5rem;
`;

export const Reticle = styled.div`
  border: 2px solid ${({ color }) => color || 'grey'};
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.5rem;
`;

export const UnreadMessageIcon = styled.p`
  border-radius: 50vmin;
  color: black;
  background-color: ${({ color = colors.green }) => color};
  font-size: 0.75rem;
  margin: 0;
  text-align: center;
  padding: 0.25rem 0.75rem;
  line-height: 14px;
`;
