import { ITextProps, Link as NativeLink } from 'native-base';
import React, { ComponentProps, FC } from 'react';

import { NavigateOptions, To, useSmartNavigate } from '../../utils/navigate/useSmartNavigate';

type LinkProps = ComponentProps<typeof NativeLink> & {
  bold?: boolean;
  cursor?: string; // For some reason this isn't showing up in the NativeLink Component Props.
  to: To;
  toOptions?: NavigateOptions;
};

export const Link = ({ to, toOptions, ...props }: LinkProps): JSX.Element => {
  const smartNavigate = useSmartNavigate();

  // This can be overridden by providing an onPress prop.
  const onPress = (): void => {
    smartNavigate(to, toOptions);
  };

  return <NativeLink onPress={onPress} {...props} />;
};

const linkFactory =
  (textStyle: Partial<ITextProps>): FC<LinkProps> =>
  ({ children, bold, ...props }) => {
    return (
      <Link _text={{ bold, ...textStyle }} cursor="pointer" {...props}>
        {children}
      </Link>
    );
  };

// LINKS
export const para = linkFactory({
  variant: 'paragraph',
});

export const paraSmall = linkFactory({
  variant: 'paragraphSmall',
});

export const paraLarge = linkFactory({
  variant: 'paragraphLarge',
});

export const caption = linkFactory({
  variant: 'caption',
});
