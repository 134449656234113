import { CareType, OrganizationCareFlow, PaymentSource } from '../../graphQL';
import { RequireJust } from '../../types';

export function getDisplayableType<T extends { careTypes: CareType[] }>(hasCareTypes: T) {
  const careTypeToName: Record<CareType, string> = {
    [CareType.Psychiatry]: 'Psychiatric Provider',
    [CareType.Therapy]: 'Therapist',
  };
  return careTypeToName[hasCareTypes.careTypes[0]];
}

export type OrganizationWithCareFlows = {
  careFlows: RequireJust<OrganizationCareFlow, 'careType' | 'defaultPaymentSource'>[];
};

export const isSelfPayReferral = (
  provider: { organizations: OrganizationWithCareFlows[] },
  careType: CareType
) => {
  return Boolean(
    provider.organizations.length && hasSelfPayFlow(provider.organizations[0], [careType])
  );
};

export const hasSelfPayFlow = (org: OrganizationWithCareFlows, forCareTypes?: CareType[]) => {
  return org.careFlows.some(
    i =>
      i.defaultPaymentSource === PaymentSource.SelfPay &&
      (!forCareTypes || forCareTypes.includes(i.careType))
  );
};

export const hasAnySelfPayFlow = (org: {
  careFlows: RequireJust<OrganizationCareFlow, 'defaultPaymentSource' | 'careType'>[];
}) => hasSelfPayFlow(org);
