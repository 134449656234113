import { Button } from 'baseui/button';
import { CheckIndeterminate, Delete } from 'baseui/icon';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../globalStyles/colors';
import { useCustomNavBlocker } from '../../Hooks';
import { padding } from '../../utils';

export interface TabBodyProps {
  closeTab: () => void;
  setDirty: (dirty: boolean) => void;
  showConfirm: boolean;
  confirmExit: () => void;
  cancelExit: () => void;
  handleClose: () => void;
  minimize: () => void;
}

export interface TabProps {
  title: string;
  tabKey: string;
  onClose: () => void;
  renderBody: React.FC<{ tab: TabBodyProps }>;
}

const Tab = styled.div<{ expanded?: boolean }>`
  min-width: ${({ expanded }) => (expanded ? '500px' : '280px')};
  max-width: 750px;
  overflow: hidden;
  pointer-events: auto;
  box-shadow: 2px -2px 8px 1px rgba(0, 0, 0, 0.35);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-right: 10px;
  background: white;
  display: flex;
  flex-direction: column;

  ${({ expanded }) =>
    expanded
      ? css`
          width: 40%;
          height: 85%;
        `
      : ''}
`;

const TabTop = styled.div`
  background: ${colors.primary};
  padding: 20px;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 1rem;
`;

const TabBodyWrapper = styled.div<{ expanded?: boolean }>`
  min-width: auto;
  height: 100%;
  min-height: 100px;
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
  overflow-y: scroll;
`;

export const PopoverTab = React.forwardRef<HTMLDivElement, TabProps>(
  ({ title, onClose, renderBody: Body }, ref) => {
    const [expanded, setExpanded] = useState(true);
    const navBlocker = useCustomNavBlocker();

    const handleClose = () => {
      const shouldClose = navBlocker.clickClose();
      if (shouldClose) onClose();
    };

    useEffect(() => {
      if (navBlocker.exitConfirmed) onClose();
    }, [navBlocker.exitConfirmed, onClose]);

    const bodyProps = {
      ...navBlocker,
      handleClose,
      closeTab: onClose,
      minimize: () => setExpanded(false),
    };

    return (
      <Tab expanded={expanded}>
        <TabTop onClick={() => setExpanded(true)}>
          <div style={{ flex: 1 }}>{title}</div>
          {expanded && (
            <Button
              kind="minimal"
              size="compact"
              $style={{ ...padding('0') }}
              onClick={event => {
                event.stopPropagation();
                setExpanded(false);
              }}
            >
              <CheckIndeterminate size={28} color="white" title="minimize" />
            </Button>
          )}
          <Button kind="minimal" size="compact" $style={{ ...padding('0') }} onClick={handleClose}>
            <Delete size={28} color="white" title="close" />
          </Button>
        </TabTop>
        <TabBodyWrapper ref={ref} expanded={expanded}>
          <Body tab={bodyProps} />
        </TabBodyWrapper>
      </Tab>
    );
  }
);
