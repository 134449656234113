import { Modal } from 'baseui/modal';
import React, { useCallback, useState } from 'react';
import { FinalButton } from '../../../../Components/FinalButton';
import * as Styles from '../Styles';

type ModalProps = {
  date: string;
  onConfirmRepeating: () => void | Promise<void>;
  onConfirmSingle: () => void | Promise<void>;
  onCancel: () => void;
};

export const RepeatingScheduleConfirmModal = ({
  date,
  onConfirmRepeating,
  onConfirmSingle,
  onCancel,
}: ModalProps) => {
  const [loading, setLoading] = useState(false);

  const onSaveFactory = useCallback(
    (runner: () => void | Promise<void>) => {
      return async () => {
        try {
          setLoading(true);
          await runner();
        } finally {
          setLoading(false);
        }
      };
    },
    [setLoading]
  );
  return (
    <Modal isOpen onClose={onCancel} unstable_ModalBackdropScroll>
      <Styles.AvailabilityModalBody>
        <Styles.AvailabilityModalHeader>
          You&apos;re editing a date with a repeating schedule.
        </Styles.AvailabilityModalHeader>
        <Styles.AvailabilityModalSubheader>
          Which availability would you like to update?
        </Styles.AvailabilityModalSubheader>
        <FinalButton
          kind="primary"
          className="w-100 mb2"
          onClick={onSaveFactory(onConfirmSingle)}
          loading={loading}
          disabled={loading}
        >
          Only this date {date}
        </FinalButton>
        <FinalButton
          kind="outline_black"
          className="w-100"
          onClick={onSaveFactory(onConfirmRepeating)}
          loading={loading}
          disabled={loading}
        >
          This and all future repeated dates
        </FinalButton>
      </Styles.AvailabilityModalBody>
    </Modal>
  );
};
