import { Modal } from 'baseui/modal';
import { capitalize, pick } from 'lodash';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useEvents } from '../../Components/Events/EventsProvider';
import { FinalButton } from '../../Components/FinalButton';
import { Checkbox, FormControl, Input, Textarea } from '../../Components/Form';
import { Modal as ModalStyle, Text } from '../../globalStyles';
import {
  CampusResourcesQuery,
  ResourceType,
  useCreateOrUpdateOrganizationResourceMutation,
  useDeleteOrganizationResourceMutation,
} from '../../graphQL';

type Resource = CampusResourcesQuery['organization']['resources'][number];

function ResourceDisplay({ resource }: { resource: Resource }) {
  const listedKeys: (keyof Resource)[] = [
    'contact',
    'location',
    'phone',
    'email',
    'hours',
    'website',
  ];
  return (
    <div className="mr2 mb4">
      <Text.bodyBold>{resource.name}</Text.bodyBold>
      <Text.body className="mb3" style={{ whiteSpace: 'pre-wrap' }}>
        {resource.description}
      </Text.body>
      {listedKeys
        .filter(fieldKey => !!resource[fieldKey])
        .map(fieldKey => (
          <Text.body key={fieldKey}>
            <span className="b">{capitalize(fieldKey)}:</span> {resource[fieldKey]}
          </Text.body>
        ))}
    </div>
  );
}

interface ResourceModalProps {
  organizationId: number;
  resource?: Resource;
  onClose: () => void;
  refetch: () => Promise<unknown>;
  /**
   * Required if resource is undefined
   */
  modalResourceType: Partial<ResourceType> | null;
}

interface FormFields {
  name: string;
  resourceType?: ResourceType;
  resourceSubtype?: string | null;
  tags: string[];
  contact?: string | null;
  location?: string | null;
  showAsCrisisResource?: boolean;
  phone?: string | null;
  email?: string | null;
  hours?: string | null;
  website?: string | null;
  description?: string | null;
}

export function ResourceModalV2({
  organizationId,
  resource,
  onClose,
  refetch,
  modalResourceType,
}: ResourceModalProps) {
  const fieldNames = [
    'name',
    'contact',
    'location',
    'phone',
    'email',
    'hours',
    'website',
    'description',
    'showAsCrisisResource',
  ] as const;
  const { track } = useEvents();
  const [mutate] = useCreateOrUpdateOrganizationResourceMutation({
    onCompleted: d => {
      if (resource?.id) {
        track('campus-resource.edited', {
          organizationId,
          resourceId: d.createOrUpdateOrganizationResource.id,
        });
      } else {
        track('campus-resource.added', {
          organizationId,
          resourceId: d.createOrUpdateOrganizationResource.id,
        });
      }
    },
  });
  const [saving, setSaving] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [resourceCrisis, setResourceCrisis] = useState(resource?.showAsCrisisResource);
  const form = useForm<FormFields>({
    defaultValues: {
      tags: [],
      ...pick(resource, fieldNames),
    },
  });

  let resourceType: ResourceType;
  if (resource) {
    resourceType = resource.resourceType;
  } else if (modalResourceType) {
    resourceType = modalResourceType;
  } else {
    return null;
  }

  const onSubmit = form.handleSubmit(async values => {
    setSaving(true);
    try {
      await mutate({
        variables: {
          organizationId,
          resource: {
            id: resource?.id,
            resourceType,
            showAsCrisisResource: resourceCrisis,
            tags: [],
            ...pick(values, fieldNames),
          },
          // CscHandbook - This will be fixed once CscHandbook feature flag is removed. passing [] for tags cause problems otherwise
        },
      });
      await refetch();
      onClose();
    } finally {
      setSaving(false);
    }
  });
  const fields = form.watch();

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      {confirmDelete && resource && (
        <ConfirmDeleteModal
          resourceId={resource.id}
          organizationId={organizationId}
          onClose={() => setConfirmDelete(false)}
          onDelete={onClose}
          refetch={refetch}
        />
      )}
      <ModalStyle.body>
        <FormProvider {...form}>
          <div className="mt4">
            <Text.h4>{resource ? 'Edit' : 'Add'} Campus Resource</Text.h4>
            <Text.h2 className="mb4">{resourceTypeNamesV2[resourceType]}</Text.h2>
          </div>
          <FormControl name="name" label="Resource Name" required>
            <Input name="name" ref={form.register({ required: true })} />
          </FormControl>
          <Text.h2 className="mb2">Contact &amp; Details</Text.h2>
          <Text.body className="mb3">
            All fields are optional -- fill out any applicable fields below.
          </Text.body>
          <FormControl name="contact" label="Contact Person">
            <Text.bodySmallGrey>
              If there is a specific person to contact in regards to this resource, please add their
              name.
            </Text.bodySmallGrey>
            <Input name="contact" ref={form.register} placeholder="Jane Smith" />
          </FormControl>
          <FormControl name="location" label="Location / Address">
            <Input name="location" ref={form.register} placeholder="Address / campus building" />
          </FormControl>
          <FormControl name="phone" label="Phone">
            <Text.bodySmallGrey>
              If this resource can be contacted by phone, please add the number number below.
            </Text.bodySmallGrey>
            <Input name="phone" ref={form.register} placeholder="603-325-2312" />
          </FormControl>
          <FormControl name="email" label="Email Address">
            <Input name="email" ref={form.register} placeholder="resource@college.edu" />
          </FormControl>
          <FormControl name="hours" label="Hours">
            <Input name="hours" ref={form.register} />
          </FormControl>
          <FormControl name="website" label="Website">
            <Input
              name="website"
              ref={form.register}
              placeholder="https://college.edu/counseling"
            />
          </FormControl>
          <FormControl name="description" label="Additional Details">
            <Text.bodySmallGrey>Short description of offered resource</Text.bodySmallGrey>
            <Textarea
              name="description"
              ref={form.register}
              placeholder="Crisis, consultation, and counseling services"
            />
          </FormControl>
          <FormControl name="showAsCrisisResource">
            <Checkbox
              name="showAsCrisisResource"
              checked={resourceCrisis}
              onChange={() => setResourceCrisis(!resourceCrisis)}
            >
              <CrisisLabel>Show as a Crisis Resource</CrisisLabel>
            </Checkbox>
          </FormControl>
          <hr className="mt4 mb4" />
          <Text.h2 className="mb2">Preview Campus Resource</Text.h2>
          <Text.bodySmallGrey>
            View how this resource will be displayed to students.
          </Text.bodySmallGrey>
          <PreviewBox>
            {fields.name ? (
              <ResourceDisplay resource={fields as Resource} />
            ) : (
              <Text.bodyGrey className="i">
                Fill out the fields above to view how this campus resources will be displayed to
                patients.
              </Text.bodyGrey>
            )}
          </PreviewBox>
          <FinalButton
            className="w-100"
            onClick={onSubmit}
            loading={saving}
            disabled={!fields.name}
          >
            Save
          </FinalButton>
          {resource && (
            <FinalButton
              kind="minimal_danger"
              className="w-100 mt2"
              onClick={() => setConfirmDelete(true)}
            >
              Delete
            </FinalButton>
          )}
        </FormProvider>
      </ModalStyle.body>
    </Modal>
  );
}

interface ConfirmDeleteModalProps {
  onClose: () => void;
  onDelete: () => void;
  refetch: () => Promise<unknown>;
  resourceId: number;
  organizationId: number;
}

function ConfirmDeleteModal({
  onClose,
  resourceId,
  refetch,
  organizationId,
  onDelete,
}: ConfirmDeleteModalProps) {
  const { track } = useEvents();
  const [deleteResource] = useDeleteOrganizationResourceMutation({
    onCompleted: _ => track('campus-resource.deleted', { resourceId, organizationId }),
  });
  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    setDeleting(true);
    try {
      await deleteResource({ variables: { resourceId } });
      await refetch();
      onDelete();
    } finally {
      setDeleting(false);
    }
  };
  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      <ModalStyle.body>
        <Text.h2 className="mb3">Delete this resource?</Text.h2>
        <Text.body className="mb3">
          This resource will be permanently removed and will no longer be visible to students.
        </Text.body>
        <FinalButton
          kind="minimal_danger"
          className="w-100"
          onClick={handleDelete}
          loading={deleting}
        >
          Delete
        </FinalButton>
        <ModalStyle.closeLink onClick={onClose}>Cancel</ModalStyle.closeLink>
      </ModalStyle.body>
    </Modal>
  );
}

export const resourceTypeNamesV2: Record<ResourceType, string> = {
  [ResourceType.HealthServices]: 'Health Services',
  [ResourceType.ResidenceLife]: 'Residence Life',
  [ResourceType.PublicSafetyCampusPolice]: 'Public Safety/Campus Police',
  [ResourceType.UrgentCareCenter]: 'Urgent Care Center',
  [ResourceType.LocalHospitalCommunityProvider]: 'Local Hospitals or Other Community Providers',
  [ResourceType.PreferredCampusPharmacy]: 'Preferred Campus Pharmacy',
  [ResourceType.DisabilityAccessibilityCenter]: 'Disability/Accesibility Center',
  [ResourceType.LgbtqiaCenter]: 'LGBTQIA+ Center',
  [ResourceType.Other]: 'Other',
  // everything below this are legacy resources:
  [ResourceType.AcademicAndAccessibility]: 'Academic Support & Accessibility',
  [ResourceType.Diversity]: 'Diversity & Inclusion',
  [ResourceType.Emergency]: 'Campus Crisis Resources',
  [ResourceType.Health]: 'Health & Wellness',
  [ResourceType.Safety]: 'Safety',
  [ResourceType.Additional]: 'Additional',
};

const PreviewBox = styled.div`
  background-color: rgba(208, 225, 253, 1);
  padding: 20px;
  border-radius: 4px;
  white-space: pre-wrap;
  margin: 20px 0px 30px 0px;
`;

const CrisisLabel = styled(Text.bodyBold)`
  position: relative;
  bottom: 0.25em;
`;
