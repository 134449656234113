import React from 'react';
import styled from 'styled-components';
import { Text } from '../../globalStyles';
import { Modal } from '../Modal/Modal';
import {
  CloseModalButton,
  RemoveMemberButton,
  RemoveMemberModalBodyDiv,
  RemoveMemberModalTitleH2,
} from './RemoveCampusTeamMemberModal';

type UnsavedCampusTeamMemberModalProps = {
  isOpen: boolean;
  onClose: () => void;
  removeUnsavedMembers: () => void;
};

export const UnsavedCampusTeamMemberModal = ({
  isOpen,
  onClose,
  removeUnsavedMembers,
}: UnsavedCampusTeamMemberModalProps) => {
  const discardChanges = () => {
    removeUnsavedMembers();
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="campusTeam">
      <UnsavedCareTeamModalBodyDiv>
        <UnsavedCareTeamModalTitleH2>Discard unsaved changes?</UnsavedCareTeamModalTitleH2>
        <div className="mb4 pt1 flex-1">
          <Text.body>You have unsaved changed that will be lost if you continue.</Text.body>
          <br />
          <Text.body>Are you sure you want to continue?</Text.body>
        </div>
        <DiscardUnsavedCareTeamButton className="b" onClick={discardChanges}>
          Discard Changes
        </DiscardUnsavedCareTeamButton>
        <CloseModalButton className="b" onClick={onClose}>
          Cancel
        </CloseModalButton>
      </UnsavedCareTeamModalBodyDiv>
    </Modal>
  );
};

const UnsavedCareTeamModalBodyDiv = styled(RemoveMemberModalBodyDiv)``;
const UnsavedCareTeamModalTitleH2 = styled(RemoveMemberModalTitleH2)``;
const DiscardUnsavedCareTeamButton = styled(RemoveMemberButton)``;
