import { Modal } from 'baseui/modal';
import React from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';
import { FinalButton } from '../FinalButton';

interface Props {
  onCancel: () => void;
  onSubmit: () => void;
  onDiscardChanges: () => void;
}

const Wrapper = styled.div`
  padding: 3em 3em 2em 3em;
`;
const CancelLink = styled.a`
  text-decoration: none;
  color: ${colors.grey.dark};
  cursor: pointer;
  text-align: center;
  display: block;
  margin-top: 2em;
`;

export function ConfirmExitModal({ onCancel, onSubmit, onDiscardChanges }: Props) {
  return (
    <Modal isOpen onClose={onCancel}>
      <Wrapper>
        <Text.h2 className="mb3">Submit changes to the Safety Plan?</Text.h2>
        <Text.body className="mb3">
          If you continue without submitting, all the changes you have made will be lost.
        </Text.body>
        <div className="flex justify-around">
          <FinalButton kind="outline_black" onClick={onDiscardChanges}>
            Discard Changes
          </FinalButton>
          <FinalButton kind="primary" onClick={onSubmit}>
            Submit
          </FinalButton>
        </div>
        <CancelLink onClick={onCancel}>Cancel</CancelLink>
      </Wrapper>
    </Modal>
  );
}
