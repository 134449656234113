import { KIND, Tag } from 'baseui/tag';
import React from 'react';
import { EngagementTag } from '../../../graphQL';

export const engagementTagCopy: Record<EngagementTag, string> = {
  [EngagementTag.Disengaged]: 'Disengaged',
  [EngagementTag.IncompleteTasks]: 'Incomplete Tasks',
  [EngagementTag.LimitedSessions]: 'Limited Sessions',
  [EngagementTag.NoShow]: 'No Show',
  [EngagementTag.Unverified]: 'Unverified',
  [EngagementTag.NeedsInsuranceDetails]: 'Needs Insurance Details',
};

const engagementTagToKind: Record<EngagementTag, KIND[keyof KIND]> = {
  [EngagementTag.Disengaged]: 'negative',
  [EngagementTag.IncompleteTasks]: 'warning',
  [EngagementTag.LimitedSessions]: 'negative',
  [EngagementTag.NoShow]: 'neutral',
  [EngagementTag.Unverified]: 'neutral',
  [EngagementTag.NeedsInsuranceDetails]: 'accent',
};

type Props = {
  tag: EngagementTag;
};

export const EngagementPill = React.memo(({ tag }: Props) => {
  return (
    <Tag
      kind={engagementTagToKind[tag]}
      closeable={false}
      overrides={{
        Root: { style: { paddingTop: '0px', paddingBottom: '0px' } },
        Text: { style: { fontSize: '0.875em', lineHeight: '1.125em', maxWidth: 'initial' } },
      }}
    >
      {engagementTagCopy[tag]}
    </Tag>
  );
});
