import React from 'react';
import { Controller } from 'react-hook-form';
import { AdminAssessmentQuery } from '../../graphQL';
import { PillCheckbox } from '../EzForm';

type Question = AdminAssessmentQuery['assessment']['questionnaires'][number]['questions'][number];
type Props = { question: Question; name: string };
export const AssessmentQuestion = ({ question, name }: Props) => {
  const Render = question.type && questionMap.get(question.type);

  if (!Render) return null;

  return (
    <div className="mt4" id={name}>
      {question.overrideDescription && <p>{question.overrideDescription}</p>}
      <div className="mb3 b">{question.text}</div>
      <div className="normal">
        <Render question={question} name={name} />
      </div>
    </div>
  );
};

const Radio = ({ question, name }: Props) => {
  const row = (question.options?.map(o => o.text).join().length ?? 0) < 7;

  return (
    <Controller
      defaultValue={null}
      name={name}
      render={({ value, onChange }) => {
        return (
          <PillCheckbox
            options={question.options?.map(o => o.text) ?? []}
            value={value}
            row={row}
            onChange={e => onChange(e)}
            multi={false}
          />
        );
      }}
    />
  );
};

const questionMap = new Map<string, (props: Props) => JSX.Element>([['radio', Radio]]);
