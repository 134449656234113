import { Pressable as BasePressable, IPressableProps } from 'native-base';
import React from 'react';

export type PressableProps = IPressableProps & {
  testID: string;
};

export const Pressable = ({ children, ...props }: PressableProps): JSX.Element => {
  return <BasePressable {...props}>{children}</BasePressable>;
};
