import { useMemo, useState } from 'react';
import {
  CareType,
  RemainingHoursForRangeQuery,
  useProviderNetworkOrganizationQuery,
  useRemainingHoursForRangeQuery,
} from '../../graphQL';
import { Nullable } from '../../types';
import { hasDedicatedGroupModel } from '../Organization/organizationUtils';

type HookArgs = {
  variables: {
    organizationId: number;
    userId?: number;
    careType: CareType;
    appointmentType: string;
    start: string;
    end: string;
    isOrganizationCareFlow: boolean;
  };
  skip?: boolean;
};

export const useOrganizationDGMConstraints = ({
  variables: {
    organizationId,
    userId,
    careType,
    appointmentType,
    start,
    end,
    isOrganizationCareFlow,
  },
  skip,
}: HookArgs) => {
  const [nextAvailableAppointmentDate, setNextAvailableAppointmentDate] =
    useState<Nullable<Date>>();
  const [remainingHours, setRemainingHours] =
    useState<Nullable<RemainingHoursForRangeQuery['remainingHoursForRange']>>(null);

  const { data: orgData, loading: loadingOrganization } = useProviderNetworkOrganizationQuery({
    variables: { organizationId: organizationId! },
    fetchPolicy: 'cache-first',
    skip,
  });

  const dedicatedGroupModelActive = useMemo(() => {
    if (!orgData || !isOrganizationCareFlow) return false;
    return hasDedicatedGroupModel(orgData.organization);
  }, [orgData]);

  const { loading: loadingRemainingHours } = useRemainingHoursForRangeQuery({
    variables: {
      organizationId: organizationId!,
      careType: careType!,
      appointmentType: appointmentType!,
      start,
      end,
      userId,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ remainingHoursForRange, nextAvailableAppointment }) => {
      setRemainingHours(remainingHoursForRange);
      setNextAvailableAppointmentDate(nextAvailableAppointment);
    },
    skip: skip || !dedicatedGroupModelActive,
  });

  return {
    remainingHours,
    nextAvailableAppointmentDate,
    dedicatedGroupModelActive,
    organizationName: orgData?.organization.name,
    loading: loadingOrganization || loadingRemainingHours,
  };
};
