import { TriangleDown, TriangleUp } from 'baseui/icon';
import React from 'react';
import styled from 'styled-components';

interface Props {
  direction?: 'asc' | 'desc' | false;
  children: React.ReactNode;
}

export const IconsContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 0.5rem;
  align-items: center;
  cursor: pointer;

  & > *:not(:first-child) {
    margin-top: -0.55rem;
  }
`;

export const SortIndicator = ({ direction, children }: Props) => (
  <div className="flex items-center pointer">
    {children}
    <IconsContainer>
      {direction !== 'desc' && <TriangleUp />}
      {direction !== 'asc' && <TriangleDown />}
    </IconsContainer>
  </div>
);
