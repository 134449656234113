import React from 'react';
import styled from 'styled-components';
import { Text } from '../../globalStyles';
import { FinalButton, FinalButtonProps } from '../FinalButton';

export interface PopoverTabFooterProps {
  text?: string;
  buttons?: FinalButtonProps[];
  remainingQuestions?: number;
  totalQuestions?: number;
  onScrollTrigger?: () => void;
}

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d8d8d8;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  bottom: 0;
`;

export const PopoverTabFooter: React.FC<PopoverTabFooterProps> = ({
  text,
  buttons,
  remainingQuestions,
  totalQuestions,
  onScrollTrigger,
}) => {
  const hasRemainingQuestions = totalQuestions && remainingQuestions && remainingQuestions > 0;
  return (
    <Footer>
      <div className="flex flex-column">
        <Text.captionBold>{text}</Text.captionBold>
        {!!hasRemainingQuestions && (
          <Text.caption>
            {`${remainingQuestions}/${totalQuestions} required questions left. `}
            <Text.linkButton onClick={onScrollTrigger}>
              Highlight incomplete required questions
            </Text.linkButton>
          </Text.caption>
        )}
      </div>
      <div className="flex gap-2">
        {buttons?.map((button, i) => (
          <FinalButton className="mw5 w6" key={i} {...button}>
            <span>{button.children}</span>
          </FinalButton>
        ))}
      </div>
    </Footer>
  );
};
