const merge = require('lodash/merge');

const normalizeConstraintInput = require('./normalizeConstraintInput.js');
const isScreenSharingSource = require('./isScreenSharingSource');

function createSourceConstraint(media, enableRenegotiation, usingOptionalMandatoryStyle) {
  if (!media.publish && enableRenegotiation) {
    // FIXME: I'm still not quite happy with how this works. With renegotiation, when publish
    // changes to true we shouldn't have to recalculate constraints. I think Publisher should handle
    // this override instead and this component shouldn't know anything about renegotiation.
    // Related: OPENTOK-31082
    return false;
  }

  if (typeof media.source === 'boolean') {
    return media.source;
  }

  if (usingOptionalMandatoryStyle) {
    return { mandatory: { sourceId: media.source } };
  }

  // getDisplayMedia doesn't support exact constraints
  if (isScreenSharingSource(media.source)) {
    return { deviceId: media.source };
  }
  return { deviceId: { exact: media.source } };
}

function generateAudioConstraints(opt) {
  let constraints = createSourceConstraint(
    opt.audio,
    opt.enableRenegotiation,
    opt.usingOptionalMandatoryStyle
  );

  if (constraints === false) {
    return false;
  }

  const {
    usingOptionalMandatoryStyle,
    enableStereo,
    echoCancellation,
    noiseSuppression,
    autoGainControl,
    disableAudioProcessing,
  } = opt;

  const addConstraint = (constraint) => {
    if (typeof constraints !== 'object') return;

    // Chrome<61 uses optional/mandatory style because a boolean is equivalent to an ideal value
    // See https://bugs.chromium.org/p/chromium/issues/detail?id=700223#c3
    if (usingOptionalMandatoryStyle) {
      merge(constraints, { optional: [] });
      constraints.optional.push(constraint);
    } else {
      merge(constraints, constraint);
    }
  };

  if (
    (enableStereo || disableAudioProcessing || !echoCancellation
      || !noiseSuppression || !autoGainControl) &&
    constraints === true
  ) {
    constraints = {};
  }

  if (enableStereo) {
    addConstraint({ channelCount: 2 });
  }

  ['echoCancellation', 'autoGainControl', 'noiseSuppression'].forEach((audioConstraintName) => {
    const constraintValue = !disableAudioProcessing && opt[audioConstraintName];
    addConstraint({ [audioConstraintName]: constraintValue });
  });
  return constraints;
}

function generateVideoConstraints(opt) {
  let constraints = createSourceConstraint(
    opt.video,
    opt.enableRenegotiation,
    opt.usingOptionalMandatoryStyle
  );

  if (constraints === false) {
    return false;
  }

  const {
    videoDimensions,
    frameRate,
    maxResolution,
    facingMode,
    usingOptionalMandatoryStyle,
  } = opt;

  if (
    (videoDimensions || frameRate || maxResolution || facingMode) &&
    constraints === true
  ) {
    constraints = {};
  }

  if (videoDimensions) {
    const width = videoDimensions.width;
    const height = videoDimensions.height;

    if (usingOptionalMandatoryStyle) {
      merge(constraints, { optional: [] });

      constraints.optional.push(
        { minWidth: width },
        { maxWidth: width },
        { minHeight: height },
        { maxHeight: height }
      );
    } else {
      merge(constraints, {
        width: { ideal: width },
        height: { ideal: height },
      });
    }
  }

  if (frameRate) {
    if (usingOptionalMandatoryStyle) {
      merge(constraints, { optional: [] });

      constraints.optional.push(
        { minFrameRate: frameRate },
        { maxFrameRate: frameRate }
      );
    } else {
      merge(constraints, {
        frameRate: { ideal: frameRate },
      });
    }
  }

  if (maxResolution) {
    if (usingOptionalMandatoryStyle) {
      merge(constraints, {
        mandatory: {
          maxWidth: maxResolution.width,
          maxHeight: maxResolution.height,
        },
      });
    } else {
      merge(constraints, {
        width: { max: maxResolution.width },
        height: { max: maxResolution.height },
      });
    }
  }

  if (facingMode) {
    if (usingOptionalMandatoryStyle) {
      merge(constraints, { optional: [] });

      constraints.optional.push({ facingMode });
    } else {
      merge(constraints, {
        facingMode: { ideal: facingMode },
      });
    }
  }

  return constraints;
}

function generateConstraints(opt) {
  return {
    audio: generateAudioConstraints(opt),
    video: generateVideoConstraints(opt),
  };
}

module.exports = function generateConstraintInfo(opt) {
  const normOpt = normalizeConstraintInput(opt);
  const constraints = generateConstraints(normOpt);

  return {
    constraints,
    publishAudio: normOpt.audio.publish,
    publishVideo: normOpt.video.publish,
    frameRate: normOpt.frameRate,
    videoDimensions: normOpt.videoDimensions,
    audioDeviceId: typeof normOpt.audio.source === 'string' ? normOpt.audio.source : undefined,
    videoDeviceId: typeof normOpt.video.source === 'string' ? normOpt.video.source : undefined,
  };
};
