import { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Select } from '.';
import { Text } from '../../globalStyles';
import { Option } from './Select/types';

export type TimeRange = [string | null, string | null];

type TimeSelectProps = {
  startTime: Moment;
  value: TimeRange;
  onChange: (r: TimeRange) => void;
  intervalMinutes?: 15 | 30 | 60;
  placeHolder?: string;
};

export const TimeRangeSelect = ({
  startTime,
  intervalMinutes = 15,
  value: [lower, upper],
  onChange,
  placeHolder = '',
}: TimeSelectProps) => {
  const options = getRangeOptions(startTime, intervalMinutes);
  return (
    <div className="flex flex-row items-center gap-2">
      <SelectWrapper>
        <Text.body kind="grayText">Start</Text.body>
        <Select
          placeholder={placeHolder}
          value={lower ?? undefined}
          options={options}
          onChange={v => onChange([(v as string) ?? null, upper ?? null])}
        />
      </SelectWrapper>
      <Dash />
      <SelectWrapper>
        <Text.body kind="grayText">End</Text.body>
        <Select
          placeholder={placeHolder}
          value={upper ?? undefined}
          options={options}
          onChange={v => onChange([lower ?? null, (v as string) ?? null])}
        />
      </SelectWrapper>
    </div>
  );
};

const SelectWrapper = styled.div`
  width: 9.25em;
`;

const Dash = styled.div`
  margin-top: 1em;
  border-bottom: 0.125em solid black;
  width: 0.75em;
`;

const getRangeOptions = (startTime: Moment, minuteInterval: number) => {
  const endOfDay = startTime.clone().endOf('day');
  const runner = startTime.clone();

  const options: Option[] = [];

  while (runner <= endOfDay) {
    options.push({
      id: runner.format(),
      label: runner.format('hh:mm a'),
    });

    runner.add(minuteInterval, 'minutes');
  }

  return options;
};
