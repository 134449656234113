import { Avatar } from 'baseui/avatar';
import { compact, isNil, uniq, zip } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';
import { TraitCategory, useProviderNetworkProfileQuery } from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { UnexpectedError } from '../../Pages/Shared';
import { states } from '../../states';
import { englishList } from '../../utils';
import { Icon, icons } from '../Icons';
import { Tag } from '../Labels';
import { LoadingPage } from '../LoadingOverlay';
import { belongsToOrganization } from '../Permissions';
import { SimpleAvailability } from './Availability/SimpleAvailability';
import { useProviderNetworkContext } from './ProviderNetworkContext';
import { getDisplayableType } from './providerNetworkUtils';

type ProfileProps = {
  providerId: number;
  withSimpleAvailability?: boolean;
};

const endTime = moment().add(3, 'months').toDate(); // endTime is declared outside the component to prevent infinite graphql queries

export function ProviderNetworkProfile({ providerId, withSimpleAvailability }: ProfileProps) {
  const { organizationId } = useProviderNetworkContext();
  const [readMoreOpen, setReadMoreOpen] = useState(false);

  const { data, loading, error } = useProviderNetworkProfileQuery({
    variables: { providerId, organizationId, endTime },
  });

  if (loading) return <LoadingPage className="pv5" />;
  if (error || !data) return <UnexpectedError />;

  const { provider } = data;

  const activeProviderPayers = provider.insuranceCredentials.filter(c => c.active);

  const paymentTypesAccepted = compact([
    activeProviderPayers.length > 0 && 'In-Network Insurance',
    'Out of Pocket / Out of Network',
    organizationId &&
      belongsToOrganization(provider, organizationId) &&
      'University-Sponsored Care (if eligible)',
  ]);

  const payerNames = uniq(activeProviderPayers.map(i => i.payer.name).sort());

  const traitsByCategory = provider.traits.reduce((acc, curr) => {
    const existingValues = acc[curr.category] ?? [];
    return { ...acc, [curr.category]: [...existingValues, ...curr.values] };
  }, {} as Record<TraitCategory, string[]>);

  const specialties = traitsByCategory[TraitCategory.Specialties] ?? [];
  const therapyTypes = traitsByCategory[TraitCategory.TherapyTypes] ?? [];
  const languages = traitsByCategory[TraitCategory.Languages] ?? [];
  const raceAndEthnicity = traitsByCategory[TraitCategory.Ethnicity] ?? [];
  const sexuality = traitsByCategory[TraitCategory.Sexuality] ?? [];
  const faith = traitsByCategory[TraitCategory.Faith] ?? [];

  const sliceThresold = 250;

  const characteristics: JSX.Element[] = compact([
    !!languages.length && (
      <div key="language">
        <Text.label className="mb1">Languages</Text.label>
        <Text.body>{languages.join(', ')}</Text.body>
      </div>
    ),
    !!raceAndEthnicity.length && (
      <div key="ethnicity">
        <Text.label className="mb1">Race &amp; Ethnicity</Text.label>
        <Text.body>{raceAndEthnicity.join(', ')}</Text.body>
      </div>
    ),
    provider.gender && (
      <div key="gender">
        <Text.label className="mb1">Gender</Text.label>
        <Text.body>{provider.gender ?? ''}</Text.body>
      </div>
    ),
    !!sexuality.length && (
      <div key="sexuality">
        <Text.label className="mb1">Sexuality</Text.label>
        <Text.body>{sexuality.join(', ')}</Text.body>
      </div>
    ),
    !!faith.length && (
      <div key="faith">
        <Text.label className="mb1">Faith</Text.label>
        <Text.body>{faith.join(', ')}</Text.body>
      </div>
    ),
  ]);

  return (
    <ProfileContainer>
      <div className="flex flex-row gap-3">
        <Avatar
          size="7rem"
          src={provider.portrait?.url ?? icons.assetsDefaultPicturePng}
          name={getFullName(provider)}
        />
        <div className="flex-1">
          <div className="flex flex-row items-baseline gap-2">
            <h1 className="mb1 black">{getFullName(provider)}</h1>
            {provider.pronouns && <Text.captionGrey>{provider.pronouns}</Text.captionGrey>}
          </div>
          <Text.bodyGreyBold className="mb3">{getDisplayableType(provider)}</Text.bodyGreyBold>
          <div className="flex flex-row gap-2 mb1">
            <Icon icon="iconsBlackClipboardSvg" size={16} />
            <Text.bodySmall>
              <span className="b">Accepting new patients in:</span>{' '}
              {englishList(
                provider.geoStates.map(abbr => states[abbr as keyof typeof states]).sort()
              )}
            </Text.bodySmall>
          </div>
          <div className="flex flex-row gap-2">
            <Icon icon="iconsBlackAccountsSvg" size={16} />
            <Text.bodySmall>
              <span className="b">Payment accepted:</span> {paymentTypesAccepted.join(', ')}
            </Text.bodySmall>
          </div>
        </div>
      </div>
      <Divider />
      <div>
        <Text.label>About {provider.firstName}</Text.label>
        <Text.body>
          {readMoreOpen ? provider.funFact : provider.funFact.slice(0, sliceThresold)}
          {provider.funFact.length > sliceThresold && !readMoreOpen && '...'}{' '}
          {provider.funFact.length > sliceThresold && (
            <Text.linkButton className="b" onClick={() => setReadMoreOpen(v => !v)}>
              Read {readMoreOpen ? 'Less' : 'More'}
            </Text.linkButton>
          )}
        </Text.body>
      </div>
      {withSimpleAvailability && (
        <>
          <Divider />
          <div>
            <SimpleAvailability
              maxDisplayableDates={7}
              maxDisplayableTimes={5}
              provider={{ ...provider, upcomingAvailability: provider.upcomingIntakeAvailabiility }}
            />
          </div>
        </>
      )}
      <Divider />
      <div className="flex flex-row justify-between">
        <div className="flex-1">
          <Text.label className="mb2">Specialties</Text.label>
          {specialties.map(s => (
            <Tag kind="primary" key={s} className="br-pill mb2">
              <Text.label className="nowrap truncate" kind="primary">
                {s}
              </Text.label>
            </Tag>
          ))}
        </div>
        {!!therapyTypes.length && (
          <div className="flex-1">
            <Text.label>Types of Therapy</Text.label>
            <ul className="pl2 mt2">
              {therapyTypes.map(t => (
                <li key={t}>{t}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <Divider />
      <div>
        <Text.label className="mb2">Accepted Insurance Plans</Text.label>
        {!!payerNames.length &&
          payerNames.map(p => (
            <div key={p} className="flex flex-row gap-2 items-center">
              <Icon icon="iconsCircleCheckFilledSvg" size={15} />
              <Text.body>{p}</Text.body>
            </div>
          ))}
        {!payerNames.length && <Text.bodySmall>None</Text.bodySmall>}
      </div>
      <Divider />
      {!!characteristics.length && (
        <>
          <div>
            <CharacteristicsGrid>{characteristics}</CharacteristicsGrid>
          </div>
          <Divider />
        </>
      )}
      <div>
        <Text.label>Qualifications</Text.label>
        {provider.qualifications2.map((q, i) => (
          <div className="mb2" key={`${q.name}-${i}`}>
            <Text.bodyBold>
              {q.name}({q.abbreviation})
            </Text.bodyBold>
            {zip(q.states, q.licences)
              .filter(([s]) => !!s)
              .map(([s, n]) => (
                <Text.body key={`${s}-${n}`}>
                  {s} {isNil(n) ? '' : `#${n}`}
                </Text.body>
              ))}
          </div>
        ))}
      </div>
      <Divider />
      <div>
        <Text.label>Education</Text.label>
        {provider.degrees.map((d, i) => (
          <div className="mb2" key={`${d.university}-${i}`}>
            <Text.bodyBold>{d.university}</Text.bodyBold>
            <Text.body>{d.degree}</Text.body>
          </div>
        ))}
      </div>
    </ProfileContainer>
  );
}

const CharacteristicsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
`;

const ProfileContainer = styled.div`
  padding: 5rem;
`;

const Divider = styled.hr`
  border: 1px solid ${colors.grey.lightBorder};
  margin: 2em 0;
`;
