import styled from 'styled-components';
import { Text } from '../../globalStyles';

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px 30px;
`;

export const SectionHead = styled(Text.h3)`
  margin: 2em 0 1.25em 0;
`;

export const FieldName = styled.div`
  font-weight: bold;
  margin-bottom: 0.75em;
`;
