import { Avatar } from 'baseui/avatar';
import moment from 'moment';
import React from 'react';
import { LoginAsUser } from '../../Components/Debug/LoginAsUser';
import { QuickEmailLink, TextIcon } from '../../Components/Flair';
import { icons } from '../../Components/Icons';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import {
  isMantraProvider,
  isMcpUser,
  MantraAdminOnly,
  NonReferralOnly,
  OzOnly,
  useCurrentProvider,
} from '../../Components/Permissions';
import {
  CoachingStatus,
  HubStatusForUser,
  User,
  UserHealthieStatus,
  useUserCoachingHealthieStatusQuery,
  useUserHealthieStatusQuery,
} from '../../graphQL';
import { getFullPreferredName, getUserOrgAbbreviation } from '../../modelUtils/users';
import { useDrilldownContext } from './helpers';
import { Styles, Text } from './styles';
import { UnstyledLink } from '../Shared';
import { ColorLookup, colors } from '../../globalStyles';
import { Tooltip } from '../../Components/Tooltip';
import { titleCase } from '../../utils';

export const Profile = () => {
  const { currentProvider } = useCurrentProvider();
  const { user } = useDrilldownContext();
  const userHealthieStatusQuery = useUserHealthieStatusQuery({ variables: { userId: user.id } });
  const userCoachingHealthieStatusQuery = useUserCoachingHealthieStatusQuery({
    variables: { userId: user.id },
  });

  const { selfie, customerId, organization, hubOnboardingStatus } = user;
  const name = getFullPreferredName(user, 'p l');
  const url = selfie?.url ?? icons.assetsDefaultPicturePng;

  const userHealthieStatus = userHealthieStatusQuery.data?.userHealthieStatus;
  const userCoachingStatus = userCoachingHealthieStatusQuery.data?.userCoachingHealthieStatus
    .userCoachingHealthieStatus as CoachingStatus | null;

  let logoLinkTo = `/organizations/${organization?.id}/admin?tab=handbook`;
  if (isMcpUser(currentProvider)) {
    logoLinkTo = `/organizations/${organization?.id}/handbook`;
  } else if (isMantraProvider(currentProvider)) {
    logoLinkTo = `/organizations/${organization?.id}/provider?tab=handbook`;
  }

  return (
    <div className="relative flex pv3 ph4 mt2">
      {organization && (
        <UniversityLogo
          organizationId={organization.id}
          right="16px"
          top="8px"
          linkTo={logoLinkTo}
        />
      )}
      <div className="mr3 mt2">
        <Avatar size="60px" src={url} name={name} />
      </div>
      <div>
        <span className="flex items-center">
          <Text.h1 className="mv0 mr2" data-cy="userName">
            {name}
          </Text.h1>
          <QuickEmailLink user={user} />
          <span className="ml2">
            <LoginAsUser userId={user.id} />
          </span>
        </span>
        <Text.bodyGrey>
          <NonReferralOnly>{customerId} </NonReferralOnly>
          {user.organizationIdentification && (
            <>
              ({getUserOrgAbbreviation(user)} ID: {user.organizationIdentification})
            </>
          )}
        </Text.bodyGrey>
        <AgeAndPronouns user={user} />
        <div className="flex flex-row justify-between">
          {organization && (
            <UnstyledLink to={logoLinkTo}>
              <Text.body>
                <TextIcon preset="university">{organization.name}</TextIcon>
              </Text.body>
            </UnstyledLink>
          )}
          {hubOnboardingStatus && (
            <OzOnly>
              <HubStatusLabel hubOnboardingStatus={hubOnboardingStatus} />
            </OzOnly>
          )}
          {userHealthieStatus && (
            <MantraAdminOnly>
              <HealthieStatusLabel healthieStatus={userHealthieStatus} />
            </MantraAdminOnly>
          )}
          {userCoachingStatus && (
            <MantraAdminOnly>
              <HealthieCoachingStatusLabel coachingStatus={userCoachingStatus} />
            </MantraAdminOnly>
          )}
        </div>
      </div>
    </div>
  );
};

type AgeAndPronounsUser = Pick<User, 'birthDate' | 'pronouns'>;
export const AgeAndPronouns = ({ user }: { user: AgeAndPronounsUser }) => {
  const { pronouns } = user;

  const age = user.birthDate ? moment().diff(moment(user.birthDate), 'years') : 'unknown';

  return (
    <Text.body className="flex items-center">
      {age} y/o
      {pronouns ? `, ${pronouns}` : ', unspecified pronouns'}
    </Text.body>
  );
};

const HubStatusLabel = ({ hubOnboardingStatus }: { hubOnboardingStatus: HubStatusForUser }) => {
  const { isHubEnabled, hasHubOnboarded } = hubOnboardingStatus;
  if (isHubEnabled && hasHubOnboarded) {
    return (
      <Styles.profileLabelContainer color={colors.successBg}>
        <Text.bodySmallBold kind="successDark">Hub Activated</Text.bodySmallBold>
        <Tooltip
          content="This student has activated their Mantra Care Hub account and has access to digital skill-building."
          size={14}
          className="ml2"
        />
      </Styles.profileLabelContainer>
    );
  }
  if (isHubEnabled && !hasHubOnboarded) {
    return (
      <Styles.profileLabelContainer color={colors.grayTextBg}>
        <Text.bodySmallBold kind="grayText">Hub Eligible</Text.bodySmallBold>
        <Tooltip
          content="This student's organization allows access to the Mantra Care Hub, but this student has not activated their Care Hub account."
          size={14}
          className="ml2"
        />
      </Styles.profileLabelContainer>
    );
  }

  return null;
};

const HealthieStatusLabel = ({ healthieStatus }: { healthieStatus: UserHealthieStatus }) => {
  const { userIsInitialized } = healthieStatus;
  if (userIsInitialized) {
    return (
      <Styles.profileLabelContainer color={colors.successBg}>
        <Text.bodySmallBold kind="successDark">Healthie Ready</Text.bodySmallBold>
        <Tooltip
          content="This student is fully integrated with Healthie"
          size={14}
          className="ml2"
        />
      </Styles.profileLabelContainer>
    );
  }

  return null;
};

const healthieCoachingStatusContentMap: Record<
  CoachingStatus,
  {
    backgroundColor: string;
    textColor: ColorLookup;
  }
> = {
  [CoachingStatus.Active]: { backgroundColor: colors.successBg, textColor: 'successDark' },
  [CoachingStatus.Inactive]: { backgroundColor: colors.grayTextBg, textColor: 'grayText' }, // not in use currently
  [CoachingStatus.Onboarding]: { backgroundColor: colors.grayTextBg, textColor: 'grayText' },
};

const HealthieCoachingStatusLabel = ({ coachingStatus }: { coachingStatus: CoachingStatus }) => {
  if (coachingStatus === CoachingStatus.Inactive) {
    return null;
  }
  return (
    <Styles.profileLabelContainer
      color={healthieCoachingStatusContentMap[coachingStatus].backgroundColor}
    >
      <Text.bodySmallBold kind={healthieCoachingStatusContentMap[coachingStatus].textColor}>
        Coaching: {titleCase(coachingStatus)}
      </Text.bodySmallBold>
    </Styles.profileLabelContainer>
  );
};
