import React from 'react';
import { Text } from '../../globalStyles';
import { Warning } from '../Warning';

export const OutsideReferralPeriod = (): JSX.Element => {
  return (
    <Warning>
      <Text.h2>Cannot make a referral outside of the school semester.</Text.h2>
      <Text.body>
        Please contact
        <Text.externalLink href="mailto:hi@mantrahealth.com" target="_blank">
          {' '}
          hi@mantrahealth.com{' '}
        </Text.externalLink>
        to discuss next steps.
      </Text.body>
    </Warning>
  );
};
