import { useToast } from 'native-base';
import React from 'react';
import { useMarkEmbedPatientCoachingInactiveMutation } from '../../../../graphQL';
import { getStylesheet } from '../../../../styles';
import { Button, Heading, Layout, SimpleModal, Text } from '../../../core';

export const CoachingMarkInactiveModal = ({
  onClose,
  onCompleted,
  patientId,
}: {
  onClose: () => void;
  onCompleted: () => void;
  patientId: number;
}): JSX.Element => {
  const toast = useToast();

  const [markUserInactive] = useMarkEmbedPatientCoachingInactiveMutation({
    variables: {
      userId: patientId,
    },
    onCompleted: ({ markEmbedPatientCoachingInactive }) => {
      if (!markEmbedPatientCoachingInactive) {
        toast.show({
          description: 'Patient could not be marked as inactive.',
          duration: 3000,
        });
        return;
      }

      onCompleted();
    },
    onError: () => {
      toast.show({
        description: 'Patient could not be marked as inactive.',
        duration: 3000,
      });
    },
  });

  const onMarkInactivePress = (): void => {
    void markUserInactive();
  };

  return (
    <SimpleModal onClose={onClose}>
      <Layout.VStack {...styles.topWrapper} space={3}>
        <Heading.h4>Are you sure you want to mark this student as inactive?</Heading.h4>

        <Text.para>
          They will no longer be able to chat with their coach and will have to re-take the Care
          Assessment in order to resume coaching
        </Text.para>

        <Layout.HStack {...styles.buttons} space={2}>
          <Button.dangerMedium testID="button-coach-assignment-open" onPress={onMarkInactivePress}>
            Mark Inactive
          </Button.dangerMedium>

          <Button.secondaryMedium testID="button-coach-assignment-open" onPress={onClose}>
            Cancel
          </Button.secondaryMedium>
        </Layout.HStack>
      </Layout.VStack>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  topWrapper: {
    paddingTop: 10,
    paddingBottom: 3,
    paddingX: 2,
  },

  buttons: {
    paddingTop: 7,
  },
});
