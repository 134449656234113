import styled from 'styled-components';

type SpannedElement<T> = {
  val: T;
  spanStart: number;
  startIdx: number;
  span: number;
};

type GetSpannedArgs<T> = {
  acc: SpannedElement<T>[];
  curr: SpannedElement<T> | null;
  startIdx: number;
  endIdx: number;
  getVal: (i: number) => T;
};

export function getSpanned<T>(
  args: Omit<GetSpannedArgs<T>, 'acc' | 'curr' | 'startIdx'>
): SpannedElement<T>[] {
  return getSpannedHelper({ ...args, startIdx: 0, acc: [], curr: null });
}

function getSpannedHelper<T>(args: GetSpannedArgs<T>): SpannedElement<T>[] {
  const { acc, curr, startIdx, endIdx, getVal } = args;

  if (startIdx > endIdx) {
    return curr ? [...acc, curr] : acc;
  }

  const val = getVal(startIdx);
  const nextIdx = startIdx + 1;

  if (!curr) {
    return getSpannedHelper({
      ...args,
      curr: { val, spanStart: startIdx + 1, startIdx, span: 1 },
      startIdx: nextIdx,
    });
  }
  if (val === curr.val) {
    return getSpannedHelper({ ...args, curr: { ...curr, span: curr.span + 1 }, startIdx: nextIdx });
  }

  return getSpannedHelper({
    ...args,
    acc: [...acc, curr],
    curr: { val, spanStart: startIdx + 1, startIdx, span: 1 },
    startIdx: nextIdx,
  });
}

export const GridSpan = styled.div<{ start: number; span: number }>`
  grid-column-start: ${({ start }) => start};
  grid-column-end: span ${({ span }) => span};
`;
