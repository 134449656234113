import styled from 'styled-components';
import { Text } from '../Users/styles';

export const CircleContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbdbdf;
`;

export const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr auto;
  grid-gap: 20px;
  grid-template-areas: 'banner banner';
  margin-bottom: 4rem;
`;

export const WidgetContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 32px;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const DashboardItem = styled.div``;

export const BannerItem = styled.div`
  grid-area: banner;
`;

export const BigText = styled(Text.body)`
  font-size: 2.625em;
  font-weight: bold;
`;
