import { isNil } from 'lodash';
import { FriendlyAllocation } from '../../../util';

export type CompleteAllocation = FriendlyAllocation;
export type NewAllocation = Omit<FriendlyAllocation, 'startTime' | 'endTime'>;
export type LocalAllocation = CompleteAllocation | NewAllocation;

export const isCompletedAllocation = (alloc: LocalAllocation): alloc is CompleteAllocation => {
  return (
    'startTime' in alloc && !isNil(alloc.startTime) && 'endTime' in alloc && !isNil(alloc.endTime)
  );
};
