import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';

export const AddButton = styled(Text.body)`
  cursor: pointer;
  color: ${colors.primary};
  display: inline-block;
  margin-right: 10px;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;

  &:hover {
    background: ${colors.grey.light};
  }
`;

export const DeleteButton = styled.div`
  cursor: pointer;
  display: inline-block;
  border-radius: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.25rem;
  text-align: center;

  &:hover {
    background: ${colors.grey.light};
  }

  &.left {
    margin: auto 0.5rem auto 0px;
  }

  &.right {
    margin: auto 0px auto 0.5rem;
  }
`;
