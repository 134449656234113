import React from 'react';
import styled from 'styled-components';
import { colors } from '../../globalStyles';
import { Text } from '../../Pages/Users/styles';
import { FinalButton } from '../FinalButton';

interface Props {
  count: number;
  source: string;
  onClick?: () => void;
  isLoading?: boolean;
}

export const MessageWidget = ({ count, source, onClick, isLoading }: Props) => {
  if (count === 0) return null;
  return (
    <FinalButton
      kind="outline_black"
      className="w-100 tl relative"
      loading={Boolean(isLoading)}
      onClick={onClick}
      style={{ background: 'white', borderColor: colors.grey.widgetBorder }}
      iconLeft={{ icon: 'iconsBlackMessageSvg', size: 20, alt: 'Messages', className: 'ml2 mr2' }}
    >
      <StatusIndicator color={count > 0 ? 'lightgreen' : undefined} />
      <Text.body>
        You have {count} unread message{count === 1 ? '' : 's'} from {source}.
      </Text.body>
    </FinalButton>
  );
};

const StatusIndicator = styled.div`
  position: absolute;
  background-color: ${props => props.color || 'none'};
  height: 100%;
  width: 0.5rem;
  left: 0;
  top: 0;
  bottom: 0;
`;
