import { useState } from 'react';
import { MAX_UPLOAD_SIZE } from '../constants';
import { useAdminBeginUploadMutation } from '../graphQL';

type FileUploadProps<T> = {
  callback?: () => void;
  completeFunction: (key: string, args?: T) => Promise<{ data?: any }>;
};

export function useImageUploader<T>({ callback, completeFunction }: FileUploadProps<T>) {
  const [beginUpload] = useAdminBeginUploadMutation();
  const [error, setError] = useState<string>();
  const [progress, setProgress] = useState(0);

  const accepts = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

  async function upload(file: File, args?: T) {
    try {
      setProgress(25);
      const beginResponse = await beginUpload({
        variables: { contentType: file.type },
      });

      if (!beginResponse.data || !beginResponse.data.adminBeginUpload)
        return setError('There was a problem uploading this file');

      setProgress(66);
      const { fields, key, url } = beginResponse.data.adminBeginUpload;

      const formData = new FormData();
      fields.forEach(([name, value]) => formData.append(name, value));
      formData.append('file', file);
      await fetch(url, { method: 'POST', body: formData });

      setProgress(90);

      const completeResponse = await completeFunction(key, args);

      if (!completeResponse?.data) {
        return setError('There was a problem uploading this file');
      }
      setProgress(100);

      callback && callback();
      setTimeout(() => setProgress(0), 0);
    } catch (e) {
      setError('There was a problem uploading this file');
    }
  }

  const onRetry = () => {
    setProgress(0);
    setError(undefined);
  };

  const onDropRejected = (files: File[]) => {
    if (files.some(f => !accepts.includes(f.type))) {
      setError('Invalid file format');
    } else if (files.some(f => f.size > MAX_UPLOAD_SIZE)) {
      setError('File size is too large');
    } else {
      setError('Unknown error uploading file');
    }
  };

  const onDropAccepted = (files: File[]) => {
    const f = files[0];
    if (!f) return;
    upload(f);
  };

  return {
    upload,
    error,
    progress,
    onDropRejected,
    onDropAccepted,
    onRetry,
    accepts,
    maxSize: MAX_UPLOAD_SIZE,
  };
}
