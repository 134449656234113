import React from 'react';
import { UnexpectedError } from '../../../../Pages/Shared';
import { useLocation, Redirect } from '../../../routes';
import { PageLoading } from '../pageCore';
import { useLoadEmbedUid } from './hooks/useLoadEmbedUid';

export const PatientEntryPage = (): JSX.Element => {
  const location = useLocation();
  const { patientId, error, loading } = useLoadEmbedUid();

  if (loading === true) {
    return <PageLoading pageName="patient entry" />;
  }

  if (error !== undefined || patientId === undefined) {
    return <UnexpectedError message={error?.message ?? 'Patient not found'} cannotRetry />;
  }

  const to = {
    ...location,
    pathname: location.pathname.replace('patient-entry', `patient/${patientId}`),
  };

  return <Redirect to={to} />;
};
