import { last, minBy, pick } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import {
  DetailedAvailability,
  SelectAvailabilityFn,
} from '../../Components/ProviderNetwork/Availability/DetailedAvailability';
import { ForBookingUser } from '../../Components/ProviderNetwork/Availability/types';
import {
  NetworkProviders,
  ProviderNetworkContextProvider,
  useProviderNetworkContext,
} from '../../Components/ProviderNetwork/ProviderNetworkContext';
import {
  ProviderNetworkRow,
  RenderAvailability,
} from '../../Components/ProviderNetwork/ProviderNetworkRow';
import { ProviderNetworkShell } from '../../Components/ProviderNetwork/ProviderNetworkShell';
import { ConsentNeededBlocker } from '../../Components/ProviderNetwork/ProvidersMinorConsentNeeded';
import { ProvidersNotFound } from '../../Components/ProviderNetwork/ProvidersNotFound';
import { useDefaultCareTypeParam } from '../../Components/ProviderNetwork/hooks/useDefaultParams';
import { ContractSessionCapacityReached } from '../../Components/Scheduling/ContractSessionCapacityReached';
import { ContractStudentCapacityReached } from '../../Components/Scheduling/ContractStudentCapacityReached';
import { useMoveToFirstProviderNetworkAvailability } from '../../Hooks/useMoveToFirstProviderNetworkAvailability';
import {
  AppointmentTemplateFragment,
  Entitlement,
  MinorConsentStatus,
  StateCodes,
  useMinorConsentStatusForUserQuery,
  useOrganizationEntitlementsQuery,
} from '../../graphQL';
import { Nullable } from '../../types';
import { LoadingWidget } from '../Users/Widgets/Loading';
import { AppointmentType } from './types';

type AppointmentTemplate = Pick<
  AppointmentTemplateFragment,
  'duration' | 'description' | 'appointmentType' | 'careType'
>;

type AvailabilityProps = {
  user: ForBookingUser;
  appointmentTemplate: AppointmentTemplate;
  onSelectAvailability: SelectAvailabilityFn;
  onBack: () => void;
};

export const ProviderAvailabilityList = ({
  user,
  appointmentTemplate,
  onBack,
  onSelectAvailability,
}: AvailabilityProps) => {
  const { careType: appointmentCareType, appointmentType } = appointmentTemplate;
  const careType = useDefaultCareTypeParam(appointmentCareType);

  return (
    <ProviderNetworkContextProvider withDateRange searchBy={{ user, careType, appointmentType }}>
      <ProviderNetworkShell>
        <Body
          onBack={onBack}
          appointmentTemplate={appointmentTemplate}
          user={user}
          onSelectAvailability={onSelectAvailability}
        />
      </ProviderNetworkShell>
    </ProviderNetworkContextProvider>
  );
};

export const nextAvailableIntake = ({
  providers,
  dgmOrSessionsActive: dgmActive,
  days,
}: {
  providers: NetworkProviders;
  dgmOrSessionsActive?: boolean;
  days?: Nullable<Moment[]>;
}) => {
  return dgmActive
    ? minBy(
        providers
          .flatMap(p => p.upcomingAvailabilityV4.availability)
          .map(v => moment(v.start))
          .filter(v => v.isAfter(last(days))) // may need to change this later, could be connected to [19909]
          .map(v => v.toDate()),
        v => v.valueOf()
      )
    : undefined;
};

type BodyProps = {
  user: ForBookingUser;
  appointmentTemplate: AppointmentTemplate;
  onSelectAvailability: SelectAvailabilityFn;
  onBack: () => void;
};

const Body = ({ user, appointmentTemplate, onSelectAvailability, onBack }: BodyProps) => {
  const { duration, appointmentType } = appointmentTemplate;
  const {
    providers,
    startDate,
    dedicatedGroupModelActive,
    days,
    searchVariables,
    sessionModelActive,
    contractSessionCapacityReached,
    contractStudentCapacityReached,
    organizationName,
  } = useProviderNetworkContext();

  const organizationId = user.organization?.id;
  const { data: entitlementData } = useOrganizationEntitlementsQuery({
    variables: { id: organizationId! },
    skip: !organizationId,
  });

  const wholeCampusCare =
    entitlementData?.organization.entitlements.some(
      ent => ent.key === Entitlement.WholeCampusCare
    ) ?? false;

  const { data: minorConsentRecord, loading: minorConsentLoading } =
    useMinorConsentStatusForUserQuery({
      variables: { userId: user.id },
    });

  const minorConsentBlocker =
    minorConsentRecord?.minorConsentStatusForUser.status === MinorConsentStatus.Incomplete;

  const ProviderAvailability: RenderAvailability = useMemo(
    () => props =>
      (
        <DetailedAvailability
          provider={pick(props.provider, ['id', 'name', 'careTypes'])}
          nextAvailability={
            // upcomingIntakeAvailability has the appointment times sorted on the backend.
            props.provider.upcomingAvailabilityV4.availability.filter(a =>
              moment(a.start).isAfter(startDate)
            )[0]
          }
          forBooking={{
            user,
            duration,
            appointmentType: appointmentType as AppointmentType,
          }}
          patientState={
            searchVariables.state ||
            (user.primaryAddressState as StateCodes | undefined) ||
            undefined
          }
          onSelectAvailability={onSelectAvailability}
          forWholeCampusCareOrg={wholeCampusCare}
        />
      ),
    [user, duration, onSelectAvailability, wholeCampusCare]
  );

  useMoveToFirstProviderNetworkAvailability();

  const suggestedProviderLookup = useMemo(
    () => new Set(user.suggestedProviders?.map(p => p.id) ?? []),
    [user]
  );

  // percolate suggested providers to top and keep providers sorted by upcoming appt
  const providersWithSuggestedAtTop = useMemo(() => {
    let i = 0;
    return providers.reduce((acc, p) => {
      if (suggestedProviderLookup.has(p.id)) {
        const percolated = [...acc.slice(0, i), p, ...acc.slice(i)];
        i += 1;
        return percolated;
      }
      return [...acc, p];
    }, [] as typeof providers);
  }, [providers, suggestedProviderLookup]);

  const nextAvailableProviderDate = nextAvailableIntake({
    providers: providersWithSuggestedAtTop,
    dgmOrSessionsActive: dedicatedGroupModelActive || sessionModelActive,
    days,
  });

  if (minorConsentLoading) {
    return <LoadingWidget />;
  }

  if (minorConsentBlocker) {
    return <ConsentNeededBlocker />;
  }

  return (
    <>
      {providersWithSuggestedAtTop.map(p => (
        <ProviderNetworkRow
          key={p.id}
          provider={p}
          renderAvailability={ProviderAvailability}
          providerTopLabel={suggestedProviderLookup.has(p.id) ? 'Suggested Provider' : undefined}
        />
      ))}
      {(sessionModelActive && contractStudentCapacityReached && (
        <ContractStudentCapacityReached
          organizationName={organizationName ?? 'Your organization'}
        />
      )) ||
        (sessionModelActive &&
          !contractStudentCapacityReached &&
          contractSessionCapacityReached && (
            <ContractSessionCapacityReached
              organizationName={organizationName ?? 'Your organization'}
            />
          )) ||
        (!providersWithSuggestedAtTop.length && (
          <ProvidersNotFound
            nextAvailableProviderDate={nextAvailableProviderDate}
            onBack={onBack}
            providerAvailabilityHelp={sessionModelActive ? 'modal' : 'email'}
          />
        ))}
    </>
  );
};
