import React from 'react';
import { Modal as M, Text } from '../../globalStyles';
import { AvailabilityBlockedReason } from '../../graphQL';
import { borderRadius } from '../../utils';
import { FinalButton } from '../FinalButton';
import { modalFactory } from '../Modal';

const warnings: Record<AvailabilityBlockedReason, { title: string; body?: string }> = {
  [AvailabilityBlockedReason.IntakesMaxedForDay]: {
    title:
      'Booking the selected timeslot would exceed the recommended limit of daily intakes for this provider',
  },
  [AvailabilityBlockedReason.BackToBackIntake]: {
    title:
      'Booking the selected timeslot would exceed the limit of daily back-to-back intakes for this provider.',
    body: 'Providers should not exceed 2 back-to-back intake appointments without proper admin time. Please get confirmation from provider before continuing to book into this timeslot.',
  },
  [AvailabilityBlockedReason.DedicatedHoursMaxedForWeek]: {
    title: 'Dedicated Group Model Hours are maxed for the week',
    body: undefined,
  },
  [AvailabilityBlockedReason.NoDgmHoursLeftForState]: {
    title: "Provider has no more Dedicated Group Model hours left for patient's state this week",
    body: undefined,
  },
  [AvailabilityBlockedReason.NoDgmIntakesLeftForState]: {
    title: "Provider has no more Dedicated Group Model Intakes left for patient's state this week",
    body: undefined,
  },
  [AvailabilityBlockedReason.FfsAllocationBlockedForSameDay]: {
    title: 'Fee for service (FFS) hours booked the same day need prior permission from provider',
    body: undefined,
  },
  [AvailabilityBlockedReason.StudentCapacity]: {
    title:
      "Booking the selected timeslot would exceed this organization's purchased sessions and/or capacity.",
    body: 'Exceed limits for organization?',
  },
  [AvailabilityBlockedReason.ContractSessions]: {
    title:
      "Booking the selected timeslot would exceed this organization's purchased sessions and/or capacity.",
    body: 'Exceed limits for organization?',
  },
  [AvailabilityBlockedReason.SessionsModelWeeklyIntakes]: {
    title: "Booking the selected timeslot would exceed this week's internally configured limits.",
    body: 'Exceed weekly limits for organization?',
  },
  [AvailabilityBlockedReason.SessionsModelWeeklySessions]: {
    title: "Booking the selected timeslot would exceed this week's internally configured limits.",
    body: 'Exceed weekly limits for organization?',
  },
};

type MaxIntakeModalProps = {
  onContinue: () => void | Promise<void>;
  onClose: () => void;
  kind: AvailabilityBlockedReason;
};

const Modal = modalFactory({ style: { ...borderRadius('4px'), maxWidth: '520px', width: '100%' } });

export const BlockedSlotModal = ({ kind, onClose, onContinue }: MaxIntakeModalProps) => {
  const warning = warnings[kind];
  return (
    <Modal isOpen onClose={onClose}>
      <div className="pa4">
        <Text.h2 className="mb4">
          <u>Warning</u>: {warning.title}
        </Text.h2>
        {warning.body && <Text.body className="mb4">{warning.body}</Text.body>}
        <FinalButton className="mb2 db w-100" kind="danger" onClick={onContinue}>
          Continue booking this timeslot
        </FinalButton>
        <FinalButton className="mb2 db w-100" kind="outline_black" onClick={onClose}>
          Select different timeslot
        </FinalButton>
        <M.closeLink className="b" onClick={onClose}>
          Close
        </M.closeLink>
      </div>
    </Modal>
  );
};
