import { first } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { MantraSpinner } from '../../../Components/LoadingOverlay';
import { useSidebarQuery } from '../../../Components/NavLayout/Counts';
import { useCurrentProvider } from '../../../Components/Permissions';
import { MessageWidget } from '../../../Components/Widgets/MessageWidget';
import { Permission, useMessageUsersQuery, useSidebarDataQuery } from '../../../graphQL';
import { UnstyledLink } from '../../Shared';

const useGetMessageChannels = () => {
  const { hasPermission } = useCurrentProvider();
  const hasPatientMessagePerms = hasPermission(Permission.PatientMessage);
  const hasProviderMessagePerms = hasPermission(Permission.ProviderMessage);

  const { data, loading } = useMessageUsersQuery({
    variables: {
      privateChannels: hasPatientMessagePerms,
      careTeamChannels: hasProviderMessagePerms,
      providerId: undefined,
    },
  });

  let messagesTab = '';
  let channelsKey: 'privateChannels' | 'careTeamChannels' | undefined;
  if (hasPatientMessagePerms) {
    messagesTab = 'messages';
    channelsKey = 'privateChannels';
  } else if (hasProviderMessagePerms) {
    messagesTab = 'provider-messages';
    channelsKey = 'careTeamChannels';
  }

  return {
    channels: channelsKey ? data?.[channelsKey] : undefined,
    loading,
    messagesTab,
  };
};

export function UnreadMessagesWidget() {
  const [, variables] = useSidebarQuery();
  const { data: unreadMessages, loading: unreadMessagesLoading } = useSidebarDataQuery({
    variables,
  });
  const { channels, loading: messagesLoading, messagesTab } = useGetMessageChannels();

  if (unreadMessagesLoading || messagesLoading) {
    return (
      <div className="mt4 flex justify-center items-center">
        <MantraSpinner size={32} />
      </div>
    );
  }

  const unreadMessageCount = unreadMessages?.unreadCareTeamMessages ?? 0;
  if (unreadMessageCount < 1) {
    return null;
  }

  const unreadChannels = channels?.filter(({ channel }) => channel.unreadMessageCount > 0) ?? [];
  const unreadChannel = first(unreadChannels);

  let toUrl = '/messages';
  if (unreadChannels.length === 1 && unreadChannel) {
    toUrl = `/users/${unreadChannel.user.id}?tab=${messagesTab}`;
  }

  return (
    <UnreadMessagesContainerDiv>
      <UnstyledLink to={toUrl}>
        <MessageWidget count={unreadMessageCount} source="collaborating providers" />
      </UnstyledLink>
    </UnreadMessagesContainerDiv>
  );
}

const UnreadMessagesContainerDiv = styled.div`
  flex-grow: 1;
  margin-top: 2em;
  margin-right: 2em;
`;
