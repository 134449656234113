import { noop } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MantraAccordion, MantraAccordionPanel } from '../../../Components/Accordion';
import { useConfigContext } from '../../../Components/ConfigContext';
import { FinalButton } from '../../../Components/FinalButton';
import { Icon, InlineSVG } from '../../../Components/Icons';
import { HasEntitlement, isMantraAdmin } from '../../../Components/Permissions';
import { Flag } from '../../../featureFlags';
import { colorMap, colors, Text } from '../../../globalStyles';
import {
  Entitlement,
  useNotifyOrganizationCrisisTeamMutation,
  VideoCallDataFragment,
  VideoCallSidebarQuery,
} from '../../../graphQL';
import type { Nullable } from '../../../types';
import { panelLookup } from '../../Handbook/Handbook';
import { EmergencyProtocolSection } from '../../Handbook/Section/EmergencyProtocolSection';
import { CrisisProtcolModal } from '../CrisisProtocolModal';
import { CrisisModeSection } from '../VideoCallSidebar';
import { areCrisisHoursActive } from '../videoCallUtils';

const MANTRA_EMERGENCY_PROTOCOL_GOOGLE_DOC_URL =
  'https://docs.google.com/document/d/1kmGxBq4o4z18NWaVdKYMWtBW_gzHmRNy5lcplgEfP_Y';
const ONE_SECOND_MS = 1000;

type AppointmentOrganization = NonNullable<VideoCallDataFragment['user']['organization']>;

type CrisisComponentProps = {
  data: VideoCallSidebarQuery;
  appointment: VideoCallDataFragment;
};

export const CrisisTab = ({ data, appointment }: CrisisComponentProps) => {
  const { featureFlags } = useConfigContext();
  return featureFlags.includes(Flag.CscPartTwo) ? (
    <CrisisTabV2 data={data} appointment={appointment} />
  ) : (
    <CrisisModeSection appointment={appointment} />
  );
};

const CrisisTabV2 = ({ data, appointment }: CrisisComponentProps) => {
  const {
    adminCrisisProtocolEngagedAt,
    adminCrisisTeamNotifiedAt,
    adminHostCrisisId,
    user: apptUser,
  } = appointment;

  const apptOrganization = apptUser?.organization;
  const [crisisId, setCrisisId] = useState<Nullable<string>>(adminHostCrisisId);

  return (
    <>
      <CrisisModeContainerDiv className="flex flex-column items-center mt4 mb5">
        <EngageAdminCrisisProtocol
          apptOrganization={apptOrganization}
          adminCrisisProtocolEngagedAt={adminCrisisProtocolEngagedAt}
          appointmentId={appointment.id}
          onComplete={arg0 => setCrisisId(arg0.crisisId)}
        />
        {crisisId && apptOrganization && (
          <HasEntitlement organization={apptOrganization} entitlement={Entitlement.CrisisTeam}>
            <EngageAdminCrisisTeam
              apptOrganization={apptOrganization}
              adminCrisisTeamNotifiedAt={adminCrisisTeamNotifiedAt}
              crisisId={crisisId}
            />
          </HasEntitlement>
        )}
      </CrisisModeContainerDiv>
      <Text.label className="mb3">RESOURCES</Text.label>
      <div className="flex items-center gap-2">
        <Icon
          icon="iconsExternalLinkSvg"
          className="pointer"
          size={13}
          onClick={() => window.open(MANTRA_EMERGENCY_PROTOCOL_GOOGLE_DOC_URL, '_blank')}
        />
        <div>
          <Text.bodyBold>Provider Emergency Protocol</Text.bodyBold>
          <Text.caption>Opens in a new tab</Text.caption>
        </div>
      </div>
      {/* only show if user is a part of an organization */}
      {data.user.organization && (
        <>
          <Hr />
          <MantraAccordion>
            <MantraAccordionPanel
              title={
                <>
                  <Text.bodyBold>Campus Emergency Protocol</Text.bodyBold>
                  <Text.caption>Click to expand campus-specific resources</Text.caption>
                </>
              }
            >
              <EmergencyProtocolSection
                verticalDisplay
                organizationId={data.user.organization.id}
                editable={false}
                refetch={noop}
                selectedField={null}
                setSelectedField={noop}
                openEdit={() => undefined}
                submitFunction={() => () => Promise.resolve()}
                handbookInfo={data.user.organization.safeOperatingHandbook!}
                editableSectionRowInfo={panelLookup.emergencyProtocol.editableSectionRowInfo}
              />
            </MantraAccordionPanel>
          </MantraAccordion>
        </>
      )}
    </>
  );
};

type EngageAdminCrisisProtocolProps = {
  appointmentId: number;
  apptOrganization?: Nullable<AppointmentOrganization>;
  adminCrisisProtocolEngagedAt?: Nullable<Date>;
  onComplete: (args: { crisisId: string }) => void;
};

const EngageAdminCrisisProtocol = ({
  appointmentId,
  apptOrganization,
  adminCrisisProtocolEngagedAt,
  onComplete,
}: EngageAdminCrisisProtocolProps) => {
  const crisisTeamProviders = apptOrganization?.crisisTeamProviders ?? [];
  const organizationCrisisProviders = crisisTeamProviders.filter(
    provider => !isMantraAdmin(provider)
  );
  const crisisHours = apptOrganization?.currentCrisisHours;

  const [crisisProtocolEngagedAt, setCrisisProtocolEngagedAt] = useState<Nullable<Date>>(
    adminCrisisProtocolEngagedAt
  );

  const [crisisModalOpen, setCrisisModalOpen] = useState(false);

  return (
    <>
      <CrisisProtcolModal
        isOpen={crisisModalOpen}
        onClose={() => {
          setCrisisModalOpen(false);
        }}
        onSuccess={crisisId => {
          setCrisisProtocolEngagedAt(new Date());
          onComplete({ crisisId });
          setCrisisModalOpen(false);
        }}
        appointmentId={appointmentId}
        crisisHours={crisisHours}
        organizationCrisisProviders={organizationCrisisProviders}
      />
      <InlineSVG className="mb2" kind="grey.ozGray2" icon="users" width={24} height={24} />
      <Text.label className="mb3 tc" kind="grey.ozGray2">
        Real-time Provider Support
      </Text.label>
      <Text.bodyBold className="mb3 tc">
        Send a request for standby support from the Mantra on-call team
      </Text.bodyBold>
      {crisisProtocolEngagedAt ? (
        <CompletedStepBox
          completedAt={moment(crisisProtocolEngagedAt)}
          label="SUPPORT REQUESTED IN SLACK"
          caption="Coordinate with team within Slack."
        />
      ) : (
        <>
          <FinalButton kind="primary" onClick={() => setCrisisModalOpen(true)}>
            Request standby support
          </FinalButton>
        </>
      )}
    </>
  );
};

type EngageAdminCrisisTeamProps = {
  apptOrganization: AppointmentOrganization;
  adminCrisisTeamNotifiedAt?: Nullable<Date>;
  crisisId: string;
};

const EngageAdminCrisisTeam = ({
  apptOrganization,
  adminCrisisTeamNotifiedAt,
  crisisId,
}: EngageAdminCrisisTeamProps) => {
  const crisisHours = apptOrganization?.currentCrisisHours;

  const [crisisTeamNotifiedAt, setCrisisTeamNotifiedAt] =
    useState<Nullable<Date>>(adminCrisisTeamNotifiedAt);

  const [crisisHoursActive, setCrisisHoursActive] = useState(
    !!crisisHours && areCrisisHoursActive(crisisHours)
  );

  const [inviteCrisisTeam, { loading: loadingInviteCrisisTeam }] =
    useNotifyOrganizationCrisisTeamMutation({
      ignoreResults: true,
      onCompleted: () => setCrisisTeamNotifiedAt(new Date()),
    });

  /*
   * We want to ensure that the crisisTeam is available to the provider
   * if their hours start mid-way through the call (check every 1.5min)
   */
  useEffect(() => {
    if (!crisisHours) return;
    const intervalId = setInterval(
      () => setCrisisHoursActive(areCrisisHoursActive(crisisHours)),
      1.5 * 60 * ONE_SECOND_MS
    );
    return () => clearInterval(intervalId);
  }, [setCrisisHoursActive, crisisHours]);

  if (!adminCrisisTeamNotifiedAt && !crisisHoursActive) {
    return null;
  }

  return (
    <>
      <Hr />
      {crisisTeamNotifiedAt ? (
        <CompletedStepBox
          completedAt={moment(crisisTeamNotifiedAt)}
          label="CAMPUS CRISIS TEAM INVITED"
          caption="We’ve sent a link to the campus crisis team member(s) to securely join this call to
          assist."
        />
      ) : (
        <>
          <Text.bodyBold className="mb2 tc">
            {apptOrganization.name} has a Campus Crisis Team
          </Text.bodyBold>
          <Text.bodySmall className="mb3 tc">
            You may <strong>invite them to this call</strong> by clicking the invite button below.
          </Text.bodySmall>
          <FinalButton
            kind="danger"
            loading={loadingInviteCrisisTeam}
            onClick={() => inviteCrisisTeam({ variables: { crisisId } })}
          >
            Invite Crisis Team to this call
          </FinalButton>
        </>
      )}
    </>
  );
};

type CompletedStepBoxProps = {
  label: string;
  completedAt: Moment;
  caption: string;
};

const CompletedStepBox = ({ label, completedAt, caption }: CompletedStepBoxProps) => {
  return (
    <CompletedStepContainerDiv className="w-100 flex flex-row gap-2">
      <InlineSVG
        className="flex-shrink-0"
        fill={colorMap.success[2]}
        icon="checkmark-circle-2"
        width={18}
        height={18}
      />
      <div>
        <Text.label className="mb2" kind="black">
          {label}
        </Text.label>
        <Text.caption className="mb2" kind="grey.ozGray2">
          {completedAt.format('M/DD/YYYY h:mma z')}
        </Text.caption>
        <Text.caption kind="black">{caption}</Text.caption>
      </div>
    </CompletedStepContainerDiv>
  );
};

const CrisisModeContainerDiv = styled.div`
  padding: 1.5rem;
  border-radius: 0.25rem;
  background: ${colorMap.background[0]};
  border: 1px solid ${colors.grey.lightBorder};
`;

const CompletedStepContainerDiv = styled.div`
  padding: 1.25rem;
  border-radius: 0.25rem;
  background: ${colorMap.success[5]};
  border: 1px solid ${colorMap.success[1]};
`;

const Hr = styled.div`
  border: 1px solid ${colors.grey.lightBorder};
  margin: 24px 0;
`;
