import { MaskedInput, MaskedInputProps as BaseMaskedInputProps } from 'baseui/input';
import React from 'react';
import { Control, Controller, ValidationRules } from 'react-hook-form';

interface MaskedPhoneInputProps {
  ext?: boolean;
  name: string;
}

export type MaskedInputProps<T = {}> = T & {
  name: string;
  control: Control<any>;
  rules?: ValidationRules;
} & BaseMaskedInputProps;

export function MaskedPhoneInput({
  ext,
  control,
  name,
  rules,
  ...props
}: MaskedInputProps<MaskedPhoneInputProps>) {
  const { inputRef } = props;
  if (inputRef !== undefined)
    throw Error(
      'If you are using this for a form, preregister or else you will end up with weirdness.'
    );

  const defaultValue = control.getValues()[name] ?? '';

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ ...handler }) => (
        <MaskedInput
          placeholder={`(123) 456-7890${ext ? ' Ext. 321' : ''}`}
          type="tel"
          mask={`(999) 999-9999${ext ? ' Ext. 99999' : ''}`}
          {...handler}
          {...props}
        />
      )}
    />
  );
}

export function MaskedZipInput({ control, name, rules, ...props }: MaskedInputProps) {
  const { inputRef } = props;
  if (inputRef !== undefined)
    throw Error(
      'If you are using this for a form, preregister or else you will end up with weirdness.'
    );

  const defaultValue = control.getValues()[name] ?? '';

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ ...handler }) => (
        <MaskedInput placeholder="00000" type="zip" mask="99999" {...handler} {...props} />
      )}
    />
  );
}
