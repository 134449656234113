import { Notification } from 'baseui/notification';
import { camelCase, toInteger } from 'lodash';
import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useEvents } from '../../Components/Events/EventsProvider';
import { FinalButton } from '../../Components/FinalButton';
import { InputRHF, PhoneInputRHF, SelectRHF } from '../../Components/Form';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { OrganizationStaffRoles } from '../../Components/Organization/OrganizationStaffRoles';
import { PaddedPage, Text } from '../../globalStyles';
import { CreateStaffRole, useAdminCreateStaffMutation } from '../../graphQL';
import { stripPhoneNumber } from '../../utils';
import { getMcpRoleOptionsV2 } from '../Provider/util';
import { UnexpectedError } from '../Shared';
import * as Styles from './styles';
import { useAdminCheck } from './useAdminCheck';

interface FormFields {
  firstName: string;
  lastName: string;
  email: string;
  postNominalTitles: string;
  classification: string;
  phone: string;
  confirmPhone: string;
  role: string;
  organizationId?: number;
}

const required = 'This field is required.';

export function AddStaffPage() {
  const { track } = useEvents();
  const { canSelectCampus, orgOptions, organizationId, loading, entitlementsByOrg, error } =
    useAdminCheck();

  const form = useForm<FormFields>();
  const formRef = useRef<HTMLFormElement>(null);
  const selectedOrgId = form.watch('organizationId') || organizationId;

  const history = useHistory();
  // prettier-ignore
  const [ createStaff, { loading: mutationLoading, error: mutationError }] = useAdminCreateStaffMutation({
    onCompleted: d => track('staff.created', { providerId: d.adminCreateStaff.id, organizationId: selectedOrgId })
  });
  if (loading) return <LoadingPage />;

  if (error || !entitlementsByOrg) return <UnexpectedError />;

  const onSubmit = form.handleSubmit(async (values: FormFields) => {
    try {
      const result = await createStaff({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            postNominalTitles: values.postNominalTitles,
            // toInteger is used because organizationId is a float that needs to be converted to an Int before entering the db
            organizationId: toInteger(selectedOrgId),
            classification: values.classification,
            phone: stripPhoneNumber(values.phone),
            role: camelCase(values.role) as CreateStaffRole,
          },
        },
      });
      history.push(`/providers/${result.data!.adminCreateStaff.id}`);
    } catch (err) {
      if (formRef.current) {
        formRef.current.scrollIntoView();
      }
    }
  });

  return (
    <PaddedPage>
      <Text.h1 className="mb3">Create Staff Account</Text.h1>
      <ContentWrapper>
        <div>
          <FormProvider {...form}>
            {mutationError && (
              <Notification kind="negative">
                {mutationError.message.replace('GraphQL error:', '')}
              </Notification>
            )}
            <Styles.FormGrid>
              {canSelectCampus && (
                <SelectRHF
                  name="organizationId"
                  defaultValue={orgOptions.find(o => Number(o.id) === organizationId)?.id}
                  options={orgOptions}
                  controlProps={{ label: 'Organization' }}
                  rules={{ required: canSelectCampus ? required : false }}
                />
              )}
              <SelectRHF
                name="role"
                options={getMcpRoleOptionsV2({
                  entitlements: entitlementsByOrg[selectedOrgId],
                })}
                controlProps={{ label: 'Account Type', required: true }}
                rules={{ required: canSelectCampus ? required : false }}
              />
            </Styles.FormGrid>
            <Styles.SectionHead>Basics</Styles.SectionHead>
            <Styles.FormGrid>
              <InputRHF
                name="firstName"
                controlProps={{ label: 'First Name', required: true }}
                rules={{ required }}
                placeholder="First Name"
              />
              <InputRHF
                name="lastName"
                controlProps={{ label: 'Last Name', required: true }}
                rules={{ required }}
                placeholder="Last Name"
              />
            </Styles.FormGrid>
            <Styles.FormGrid>
              <InputRHF
                name="postNominalTitles"
                controlProps={{ label: 'Post-Nominal Title(s)' }}
                placeholder="Ph.D, LCSW, PMHNP"
              />
              <InputRHF
                name="classification"
                controlProps={{ label: 'Job Title' }}
                placeholder="Staff Psychologist"
              />
            </Styles.FormGrid>
            <br />
            <Styles.SectionHead>Contact</Styles.SectionHead>
            <Styles.FormGrid>
              <InputRHF
                name="email"
                controlProps={{ label: 'University Email', required: true }}
                placeholder="name@domain.edu"
                type="email"
                rules={{ required }}
              />
            </Styles.FormGrid>
            <Styles.FormGrid>
              <PhoneInputRHF
                name="phone"
                controlProps={{ label: 'Office Phone Number', required: true }}
                placeholder="(123) 456-7890"
                rules={{
                  required,
                  validate: {
                    valid: value => {
                      const val = stripPhoneNumber(value).length >= 10;
                      return val || 'Please enter a valid phone number.';
                    },
                  },
                }}
              />
              <PhoneInputRHF
                name="confirmPhone"
                controlProps={{ label: 'Confirm Office Phone Number', required: true }}
                placeholder="(123) 456-7890"
                rules={{
                  required,
                  validate: {
                    match: value => {
                      const val = value === form.watch('phone');
                      return val || 'Phone numbers do not match.';
                    },
                  },
                }}
              />
              <FinalButton
                className="w-100 mt4"
                data-cy="createStaffSubmit"
                loading={mutationLoading}
                onClick={onSubmit}
                kind="primary"
              >
                Save
              </FinalButton>
            </Styles.FormGrid>
          </FormProvider>
        </div>
        <OrganizationStaffRoles
          organization={{
            entitlements: entitlementsByOrg[selectedOrgId],
          }}
        />
      </ContentWrapper>
    </PaddedPage>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8rem;
`;
