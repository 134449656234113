import React from 'react';
import styled, { css } from 'styled-components';
import { colorMap, colors } from '../globalStyles';
import { cx } from '../utils';

export const PillLabel = styled.div`
  padding: 2px 8px 2px 8px;
  background: rgba(143, 155, 179, 0.16);
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  min-width: fit-content;
  max-width: fit-content;
`;

const tagKindMap = {
  black: css`
    background-color: ${colors.black};
    color: ${colors.white};
  `,
  gray: css`
    color: ${colors.grayText};
    background-color: ${colorMap.background[0]};
  `,
  primary: css`
    color: ${colorMap.primary[0]};
    background-color: ${colorMap.primary[5]};
  `,
} as const;

const tagSizeMap = {
  compact: css`
    padding: 0.15rem 0.5rem;
  `,
  normal: css`
    padding: 0.25rem 0.75rem;
  `,
} as const;

type TagProps = {
  kind?: keyof typeof tagKindMap;
  size?: keyof typeof tagSizeMap;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'title'>;
export const Tag: React.FC<TagProps> = ({
  children,
  size = 'normal',
  kind = 'black',
  className,
  ...props
}) => {
  return (
    <StyledTag className={cx(`tag_kind_${kind}`, `tag_size_${size}`, className)} {...props}>
      {children}
    </StyledTag>
  );
};

const StyledTag = styled.div`
  border-radius: 50vmin;
  cursor: default;
  width: fit-content;

  ${Object.entries(tagKindMap).map(
    ([key, value]) =>
      css`
        &.tag_kind_${key} {
          ${value}
        }
      `
  )}

  ${Object.entries(tagSizeMap).map(
    ([key, value]) => css`
      &.tag_size_${key} {
        ${value}
      }
    `
  )}
`;
