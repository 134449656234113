import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../globalStyles';
import { getAuthToken } from '../../token';
import { useCurrentProvider } from '../Permissions';
import { ImpersonationBanner } from './ImpersonationBanner';
import { Navigation } from './Navigation';
import { NavigationSmall } from './NavigationSmall';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

const BodyWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  position: relative;
  background-color: ${colors.grey.lightBackground};
  box-sizing: border-box;
`;

// use this context to scroll to the top of the page
const PanelContext = React.createContext<HTMLDivElement | null>(null);
export const usePanelRef = () => useContext(PanelContext);

export const NavLayout: React.FC = ({ children }) => {
  const token = getAuthToken();
  const data = JSON.parse(token!) as any;
  const { currentProvider } = useCurrentProvider();
  const panelRef = useRef<HTMLDivElement | null>(null);
  const [panelContext, setPanelContext] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    setPanelContext(panelRef.current);
  }, [panelRef, setPanelContext]);

  return (
    <>
      <Wrapper className="flex flex-column flex-row-l">
        {/* width > 60em */}
        <Navigation isImpersonating={!!data.impersonation} />
        {/* width < 60em */}
        <NavigationSmall isImpersonating={!!data.impersonation} />
        <BodyWrapper ref={panelRef}>
          {data.impersonation && <ImpersonationBanner providerName={currentProvider.name} />}
          <PanelContext.Provider value={panelContext}>{children}</PanelContext.Provider>
        </BodyWrapper>
      </Wrapper>
    </>
  );
};
