import { flatten } from 'lodash';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { colorMap, colors, Text } from '../../globalStyles';
import { Hashable, HasId } from '../../types';
import { baseInputStyles, InvisibleInput } from '../Form';
import { SearchAndAddEzMultirow, SearchAndAddMultirowComponentArgs } from './SearchAndAddMultirow';

type SortByFn<T> = (t: T) => number;
type SortDir = 'asc' | 'desc';

type SearchAndAddProps<T> = {
  searchOptions: SearchOption<T>[];
  renderSearchOption?: (props: RenderSearchOptionProps<T>) => JSX.Element;
  component: (props: SearchAndAddMultirowComponentArgs<T>) => JSX.Element;
  selectedValues?: T[];
  onAddValue: (newValue: T) => void;
  onRemoveValue: (removed: T, idx: number) => void;
  onEditValue: (updated: T, idx: number) => void;
  sortBy?: SortByFn<T> | SortByFn<T>[];
  sortDir?: SortDir;
};

export function SearchAndAdd<T extends HasId<Hashable>>({
  searchOptions,
  renderSearchOption,
  component,
  onAddValue,
  onRemoveValue,
  onEditValue,
  selectedValues = [],
  sortBy,
  sortDir = 'asc',
}: SearchAndAddProps<T>) {
  const sortedValues = sortBy
    ? [...selectedValues].sort((a, b) => {
        const sortByFnArr = flatten([sortBy]);
        for (const sortFn of sortByFnArr) {
          const diff = sortFn(b) - sortFn(a);
          if (diff !== 0) {
            return sortDir === 'asc' ? diff : -diff;
          }
        }
        return 0;
      })
    : selectedValues;

  return (
    <>
      <Search
        searchOptions={searchOptions}
        renderSearchOption={renderSearchOption}
        onSelectMember={v => onAddValue(v)}
        selectedCareTeam={sortedValues}
      />
      <Text.label className="mt4">Current Team</Text.label>
      <SearchAndAddEzMultirow
        values={sortedValues}
        component={component}
        onAddValue={onAddValue}
        onEditValue={onEditValue}
        onRemoveValue={onRemoveValue}
      />
    </>
  );
}

type SearchOption<T> = { label: string; data: T };
type RenderSearchOptionProps<T> = { option: SearchOption<T> };

type SearchProps<T> = {
  searchOptions: SearchOption<T>[];
  renderSearchOption?: (p: RenderSearchOptionProps<T>) => JSX.Element;
  onSelectMember: (o: T) => void;
  selectedCareTeam: T[];
};

export function Search<T extends HasId<Hashable>>({
  searchOptions,
  renderSearchOption: O,
  onSelectMember,
  selectedCareTeam,
}: SearchProps<T>) {
  const selectedCareTeamIdLookup = new Set(selectedCareTeam.map(c => c.id));
  const whiteSpaceReg = /\s+/;
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string>();

  const optionsRemaining = searchOptions.filter(
    option => !selectedCareTeamIdLookup.has(option.data.id)
  );

  const optionsFound = useMemo(() => {
    return optionsRemaining.filter(
      o =>
        !query ||
        [...query].every(
          typedLetter =>
            whiteSpaceReg.test(typedLetter) ||
            o.label.toLowerCase().includes(typedLetter.toLowerCase())
        )
    );
  }, [query]);

  return (
    <div className="mt3" style={{ position: 'relative' }}>
      <Text.caption>Add Campus Team User</Text.caption>
      <SearchAndAddStyledSelectDiv>
        <InvisibleInput
          autoComplete="off"
          data-baseweb="select" // for now since tests use it
          tabIndex={0}
          className="flex-auto"
          value={query ?? ''}
          onChange={e => setQuery(e.target.value)}
          onClick={() => setOpen(true)}
          aria-haspopup
          placeholder="Search users"
        />
      </SearchAndAddStyledSelectDiv>
      {open && query && (
        <Dropdown>
          {optionsFound && (
            <ul role="listbox">
              {optionsFound.map((o, i) => {
                return (
                  <li key={i}>
                    <DropdownButton
                      type="button"
                      onClick={() => {
                        onSelectMember(o.data);
                        setQuery(undefined);
                      }}
                    >
                      {O ? <O option={o} /> : <Text.body>{o.label}</Text.body>}
                    </DropdownButton>
                  </li>
                );
              })}
            </ul>
          )}
        </Dropdown>
      )}
    </div>
  );
}

const Dropdown = styled.div`
  top: 110%;
  left: 0;
  max-height: 12rem;
  border-radius: 0.25rem;
  width: 100%;
  overflow-y: auto;
  z-index: 10;
  background-color: white;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
  transition-duration: 0.1s;
  transition-property: transform;
  position: absolute;

  & ul {
    top: 30px;
    z-index: 30;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & li {
    outline: none;

    &.group-header {
      padding: 0.5rem;
      font-weight: bold;
      cursor: default;
    }

    &.option {
      cursor: pointer;
      padding: 0.55rem 1rem;
      &:hover,
      &:focus,
      &.selected {
        background-color: rgb(238, 238, 238);
      }
    }
  }
`;

const DropdownButton = styled.button`
  background-color: ${colors.white};
  border: none;
  width: 100%;
  text-align: left;
  font-weight: 700;
`;

export const SearchAndAddStyledSelectDiv = styled.div`
  ${baseInputStyles}
  background-color: ${colorMap.background[0]};
  border: 1px solid ${colors.grey.ozGray4};
  display: inline-flex;
  position: relative;
  cursor: text;
  gap: 0.5rem;
  margin-top: 0.5rem;

  /* still used by other select elements */
  & div.end {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    padding-right: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: default;
  }
`;
