import moment from 'moment';
import React, { useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { Text } from '../../globalStyles';
import {
  OrganizationAnnouncement,
  OrganizationAnnouncementsByOrgIdQuery,
  useOrganizationAnnouncementsByOrgIdQuery,
} from '../../graphQL';
import { UnexpectedError } from '../Shared';
import { useOrganizationId } from '../Organizations/util';
import { FinalButton } from '../../Components/FinalButton';
import { MantraAdminOnly, McpOnly, OzOnly } from '../../Components/Permissions';
import { CampusAnnouncementModal } from './CampusAnnouncementModal';
import { Icon } from '../../Components/Icons';
import {
  AnnouncementDiv,
  AnnouncementsContainerDiv,
  AnnouncementsSectionDiv,
  ContentDiv,
  CurrentUpdatesIcon,
  EmptyContentDiv,
  HeaderContainerDiv,
  HeaderDiv,
  LeftSectionDiv,
  RightSectionDiv,
} from './styles';

type CampusAnnouncementsProps = {
  organizationId: number;
};

type AnnouncementsProps = {
  announcements: OrganizationAnnouncement[];
};

type AnnouncementProps = {
  announcement: OrganizationAnnouncement;
  organizationId: number;
  refetch: () => Promise<ApolloQueryResult<OrganizationAnnouncementsByOrgIdQuery>>;
};

export type RowInfoProps = {
  id?: number;
  title: string;
  details?: string | null | undefined;
} | null;

const DATE_TIME_FORMAT = 'M/D/YY h:mma z';

export function CampusAnnouncementsFromParams() {
  const organizationId = useOrganizationId();
  return <CampusAnnouncements organizationId={organizationId} />;
}

export function CampusAnnouncements({ organizationId }: CampusAnnouncementsProps) {
  const [showingModal, setShowingModal] = useState(false);
  const { data, error, loading, refetch } = useOrganizationAnnouncementsByOrgIdQuery({
    variables: { organizationId },
  });
  if (loading) return <LoadingPage />;
  if (!data || error) return <UnexpectedError />;

  const allAnnouncements: OrganizationAnnouncement[] = data.organizationAnnouncementsByOrgId;
  const currentCarePeriodAnnouncements: OrganizationAnnouncement[] = allAnnouncements.filter(
    ann => ann.inCurrentCarePeriod
  );
  const previousCarePeriodAnnouncements: OrganizationAnnouncement[] = allAnnouncements.filter(
    ann => !ann.inCurrentCarePeriod
  );

  const EmptyList = () => (
    <EmptyContentDiv>
      <div className="i">No Updates</div>
    </EmptyContentDiv>
  );

  const Announcements = ({ announcements }: AnnouncementsProps) => {
    return (
      <>
        {announcements.map(announcement => {
          return (
            <Announcement
              key={announcement.id}
              announcement={announcement}
              organizationId={organizationId}
              refetch={refetch}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <OzOnly>
        <HeaderContainerDiv isOzView>
          <Text.h2>Campus Updates</Text.h2>
          <MantraAdminOnly>
            <FinalButton loading={loading} onClick={() => setShowingModal(true)}>
              + Add Campus Update
            </FinalButton>
          </MantraAdminOnly>
        </HeaderContainerDiv>
        <AnnouncementsContainerDiv>
          <AnnouncementsSectionDiv>
            <HeaderDiv>
              <Text.label>CURRENT CARE PERIOD</Text.label>
              {!!currentCarePeriodAnnouncements.length && (
                <CurrentUpdatesIcon>{currentCarePeriodAnnouncements.length}</CurrentUpdatesIcon>
              )}
            </HeaderDiv>
            {currentCarePeriodAnnouncements.length ? (
              <Announcements announcements={currentCarePeriodAnnouncements} />
            ) : (
              <EmptyList />
            )}
          </AnnouncementsSectionDiv>
          <AnnouncementsSectionDiv>
            <HeaderDiv>
              <Text.label>PREVIOUS CARE PERIOD</Text.label>
            </HeaderDiv>
            {previousCarePeriodAnnouncements.length ? (
              <Announcements announcements={previousCarePeriodAnnouncements} />
            ) : (
              <EmptyList />
            )}
          </AnnouncementsSectionDiv>
        </AnnouncementsContainerDiv>
        <CampusAnnouncementModal
          showModal={showingModal}
          setShowModal={setShowingModal}
          organizationId={organizationId}
          refetch={refetch}
        />
      </OzOnly>
      <McpOnly>
        <HeaderContainerDiv isOzView={false}>
          <Text.h1>Campus Updates</Text.h1>
          <Text.bodySmallGrey>
            Updates are displayed to Mantra Providers to inform their work with students from your
            organization.
          </Text.bodySmallGrey>
        </HeaderContainerDiv>
        <AnnouncementsContainerDiv>
          <AnnouncementsSectionDiv>
            <Text.bodySmallBold className="tracked">LATEST CAMPUS UPDATES</Text.bodySmallBold>
            {allAnnouncements.length ? (
              <Announcements announcements={allAnnouncements} />
            ) : (
              <EmptyList />
            )}
          </AnnouncementsSectionDiv>
        </AnnouncementsContainerDiv>
      </McpOnly>
    </>
  );
}

export const Announcement: React.FC<AnnouncementProps> = ({
  announcement,
  organizationId,
  refetch,
}) => {
  const [showFullText, setShowFullText] = useState(false);
  const [selectedRow, setSelectedRow] = useState<RowInfoProps>(null);
  const [showingModal, setShowingModal] = useState(false);
  const { id, title, details, createdAt } = announcement;
  const formattedDateTime = moment(createdAt).tz(moment.tz.guess()).format(DATE_TIME_FORMAT);
  const name = announcement.createdByProviderName;
  return (
    <AnnouncementDiv>
      <LeftSectionDiv>
        <Text.bodyBold>{`${formattedDateTime}`}</Text.bodyBold>
        <Text.bodySmall kind="grayText">Posted by {name}</Text.bodySmall>
      </LeftSectionDiv>
      <RightSectionDiv>
        <Text.bodyBold>{title}</Text.bodyBold>
        <ContentDiv showFullText={showFullText} isExpanded={showFullText}>
          <Text.body>{details}</Text.body>
        </ContentDiv>
        {!!details && (
          <ReadMoreLink id={id} showFullText={showFullText} setShowFullText={setShowFullText} />
        )}
      </RightSectionDiv>
      <MantraAdminOnly>
        <Icon
          icon="iconsPencilSvg"
          className="pointer"
          size={13}
          onClick={() => {
            setSelectedRow({ id, title, details });
            setShowingModal(true);
          }}
        />
      </MantraAdminOnly>
      <CampusAnnouncementModal
        rowInfo={selectedRow}
        showModal={showingModal}
        setShowModal={setShowingModal}
        organizationId={organizationId}
        refetch={refetch}
        setSelectedRow={setSelectedRow}
      />
    </AnnouncementDiv>
  );
};

type ReadMoreLinkProps = {
  id: number;
  showFullText: boolean;
  setShowFullText: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReadMoreLink: React.FC<ReadMoreLinkProps> = ({ id, showFullText, setShowFullText }) => {
  return (
    <Text.bodyBold
      id={`${id}`}
      kind="primary"
      onClick={() => {
        setShowFullText(!showFullText);
      }}
    >
      {showFullText ? 'Less' : 'Read more'}
    </Text.bodyBold>
  );
};
