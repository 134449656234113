import { AppView } from '../../graphQL';

const terms = {
  [AppView.Oz]: null,
  [AppView.Mcp]: 'https://terms.mantrahealth.com/Terms-of-Use-PHI.pdf',
  [AppView.Referral]:
    'https://terms.mantrahealth.com/Terms-of-Use-University-Providers-Referral-Portal-non-PHI.pdf',
} as const;

export const getTerms = <T extends AppView>(view: T): typeof terms[T] => terms[view];
