import { createContext, useContext } from 'react';

export type IEditTask = null | number | 'new';

export type ITaskPageContext = {
  editing: IEditTask;
  setEditing: (arg: IEditTask) => void;
  refetch: () => void;
  refetchCount: number;
};

export const TaskPageContext = createContext<ITaskPageContext | null>(null);

export const useTaskPageContext = () => {
  const context = useContext(TaskPageContext);
  if (!context) throw new Error('invalid location for task page context');
  return context;
};
