import React from 'react';
import styled from 'styled-components';
import { Text } from '../../globalStyles';
import { FinalButton } from '../FinalButton';
import { InlineSVG } from '../Icons';

type ContractSessionsCapacityReachedProps = {
  organizationName: string;
};

export const ContractSessionCapacityReached = ({
  organizationName,
}: ContractSessionsCapacityReachedProps) => {
  return (
    <CenteredMaxWidth>
      <InlineSVG kind="greyIcon" icon="alert-circle" size={42} />
      <Text.h3 className="mt2 mb4">No sessions remaining</Text.h3>
      <Text.body className="mb3">
        {organizationName} has used all allotted sessions for this academic year
      </Text.body>
      <FinalButton
        kind="primary"
        className="w-100"
        onClick={() => {
          window.open('mailto:partnersuccess@mantrahealth.com');
        }}
      >
        Contact Mantra Partner Success
      </FinalButton>
    </CenteredMaxWidth>
  );
};

const CenteredMaxWidth = styled.div`
  margin: 0 auto 0 auto;
  padding: 2rem 0 2rem 0;
  text-align: center;
  max-width: 26rem;
`;
