import styled from 'styled-components';

export const PopoverContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
`;

export const PopoverBody = styled.div`
  padding: 2rem 4rem;
  flex-grow: 1;
  ${/* Important for Edge */ ''}
  position: relative;
`;
