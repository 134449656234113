import { Modal } from 'baseui/modal';
import React, { useState } from 'react';
import { FinalButton } from '../../../../Components/FinalButton';
import { englishList } from '../../../../utils';
import * as Styles from '../Styles';

type ModalProps = {
  dates: string[];
  isUpdating: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmAvailabilityOverrideModal = ({
  dates,
  onConfirm,
  onCancel,
  isUpdating,
}: ModalProps) => {
  const count = dates.length;
  return (
    <Modal isOpen onClose={onCancel} unstable_ModalBackdropScroll>
      <Styles.AvailabilityModalBody>
        <Styles.AvailabilityModalHeader>
          Override date-specific availability?
        </Styles.AvailabilityModalHeader>
        <Styles.AvailabilityModalSubheader>
          You have a date-specific availability set on {count} future {count > 1 ? 'dates' : 'date'}
          : {englishList(dates)}. Would you like to override{' '}
          {count > 1 ? 'these dates' : 'this date'}?
        </Styles.AvailabilityModalSubheader>
        <FinalButton kind="primary" className="w-100 mb2" onClick={onCancel} disabled={isUpdating}>
          Do NOT override
        </FinalButton>
        <FinalButton
          kind="outline_black"
          className="w-100"
          onClick={onConfirm}
          loading={isUpdating}
        >
          Override Availability
        </FinalButton>
      </Styles.AvailabilityModalBody>
    </Modal>
  );
};

type ModalPropsV2 = {
  dates: string[];
  onConfirm: () => void | Promise<void>;
  onCancel: () => void;
};

export const ConfirmAvailabilityOverrideModalV2 = ({
  dates,
  onConfirm,
  onCancel,
}: ModalPropsV2) => {
  const [loading, setLoading] = useState(false);
  const count = dates.length;

  const onOverride = async () => {
    try {
      setLoading(true);
      await onConfirm();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen onClose={onCancel} unstable_ModalBackdropScroll>
      <Styles.AvailabilityModalBody>
        <Styles.AvailabilityModalHeader>
          Override date-specific availability?
        </Styles.AvailabilityModalHeader>
        <Styles.AvailabilityModalSubheader>
          You have a date-specific availability set on {count} future {count > 1 ? 'dates' : 'date'}
          : {englishList(dates)}. Would you like to override{' '}
          {count > 1 ? 'these dates' : 'this date'}?
        </Styles.AvailabilityModalSubheader>
        <FinalButton kind="primary" className="w-100 mb2" onClick={onCancel}>
          Do NOT override
        </FinalButton>
        <FinalButton kind="outline_black" className="w-100" onClick={onOverride} loading={loading}>
          Override Availability
        </FinalButton>
      </Styles.AvailabilityModalBody>
    </Modal>
  );
};
