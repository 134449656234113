import React from 'react';
import { Text } from '../globalStyles';
import { cx } from '../utils';
import { InlineSVG } from './Icons';

export const BackLinkButton = ({
  className,
  ...props
}: Pick<
  React.HTMLProps<HTMLButtonElement>,
  'className' | 'style' | 'id' | 'onClick' | 'disabled' | 'aria-label'
>) => (
  <Text.linkButton className={cx('flex flex-row items-center gap-1', className)} {...props}>
    <InlineSVG kind="link" icon="chevron-left" width={14} height={14} />
    back
  </Text.linkButton>
);
