import React from 'react';
import { Text } from '../../globalStyles';
import { FinalButton } from '../FinalButton';
import { InlineSVG } from '../Icons';
import * as Styles from './Styles';

export const ContractNoAvailability = () => {
  return (
    <Styles.CenteredMaxWidth>
      <InlineSVG kind="greyIcon" icon="alert-circle" size={42} />
      <Text.h3 className="mt2 mb4">Provider availability limited</Text.h3>
      <Text.body className="mb3">
        Provider availability is currently limited in the state selected.
      </Text.body>
      <br />
      <Text.body className="mb3">
        You may escalate this issue by contacting Mantra’s Partner Success team.
      </Text.body>
      <FinalButton
        kind="primary"
        className="w-100"
        onClick={() => {
          window.open('mailto:partnersuccess@mantrahealth.com');
        }}
      >
        Contact Mantra Partner Success
      </FinalButton>
    </Styles.CenteredMaxWidth>
  );
};
