import { Checkbox } from 'baseui/checkbox';
import { StatefulDatepicker } from 'baseui/datepicker';
import { Select as BaseSelect } from 'baseui/select';
import { flatMap, keyBy, min } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { overrides, Text } from '../../globalStyles';
import { SectionFormat, useAppointmentsForNoteSelectorQuery } from '../../graphQL';
import { useDrilldownContext } from '../../Pages/Users/helpers';
import { EzMultitext, PillCheckbox } from '../EzForm';
import { Textarea, Input } from '../Form/Input';
import { Select } from '../Form';
import { Grid } from '../Grid';
import { Icon } from '../Icons';
import { useCurrentProvider } from '../Permissions';
import { DiagnosisCode, getLabeledDiagnosisCode } from './diagnosisCodes';
import { EditSection, Medication, RX } from './formTypes';
import { useDiagnosisSearch, useFormHookHandler, useMultiSelectHandler } from './hooks';
import * as Styles from './styles';
import { appointmentLabel } from './util';
import { visitNotes } from '../../utils';

type Props = {
  question: EditSection;
  name: string;
  noteKey: string;
  noteTitle: string;
  queueForSave: () => void;
};

const Multiselect = ({ question, name, queueForSave }: Props) => {
  const handleMultiSelect = useMultiSelectHandler(question);

  return (
    <Controller
      name={name}
      defaultValue={[]}
      render={ctrl => {
        const { options, onChange } = handleMultiSelect(ctrl);

        return (
          <div>
            <PillCheckbox
              multi
              value={ctrl.value}
              options={options}
              onChange={e => {
                onChange(e);
                queueForSave();
              }}
              selectedIcon={<Icon icon="iconsCheckBlackSvg" />}
              unselectedIcon={<Styles.SelectedCircle />}
            />
          </div>
        );
      }}
    />
  );
};

const SingleSelect = ({ question, name, queueForSave }: Props) => {
  const { onChangeHook } = useFormHookHandler(question);

  return (
    <Controller
      defaultValue={null}
      name={name}
      render={({ value, onChange }) => {
        const options = question.options ?? [];
        const row = options.join().length <= 10;

        const onSelect = (e: string) => {
          onChangeHook();
          onChange(e);
          queueForSave();
        };

        return (
          <div>
            <PillCheckbox
              options={options}
              value={value}
              row={row}
              onChange={e => onSelect(e)}
              multi={false}
            />
          </div>
        );
      }}
    />
  );
};

const Multitext = ({ name, queueForSave }: Props) => (
  <Controller
    defaultValue={[]}
    name={name}
    render={({ value, onChange }) => (
      <EzMultitext
        value={value}
        onChange={v => {
          onChange(v);
          queueForSave();
        }}
      />
    )}
  />
);

const FormTextArea = ({ question, name, queueForSave }: Props) => {
  const { register } = useFormContext();

  return (
    <Textarea
      defaultValue=""
      name={name}
      ref={register()}
      onChange={() => queueForSave()}
      placeholder={question.placeholder ?? ''}
    />
  );
};

const SingleLineInput = ({ question, name, queueForSave }: Props) => {
  const { register } = useFormContext();

  return (
    <Input
      defaultValue=""
      name={name}
      autoComplete="off"
      ref={register()}
      onChange={() => queueForSave()}
      placeholder={question.placeholder ?? ''}
    />
  );
};

const FormTextTitle = ({ question, name, queueForSave }: Props) => {
  const { register } = useFormContext();

  return (
    <Input
      name={name}
      ref={register()}
      onChange={() => queueForSave()}
      placeholder={question.placeholder ?? ''}
      style={{ fontSize: '25px' }}
    />
  );
};

const Calendar = ({ name, question, queueForSave }: Props) => (
  <Controller
    name={name}
    defaultValue={null}
    render={({ value, onChange }) => {
      const onDateChange = ({ date }: { date?: Date | Date[] }) => {
        if (!date) return onChange(null);
        const newDate = Array.isArray(date) ? date[0] : date;
        onChange(newDate.toISOString());
        queueForSave();
      };

      return (
        <div>
          <StatefulDatepicker
            clearable
            initialState={{ value: value ? new Date(value) : undefined }}
            formatString="MM/dd/yyyy"
            placeholder={question.placeholder ?? 'MM/DD/YYYY'}
            maxDate={new Date()}
            onChange={onDateChange}
          />
        </div>
      );
    }}
  />
);

const Sign = ({ name, queueForSave }: Props) => (
  <Controller
    defaultValue={null}
    name={name}
    render={({ value, onChange }) => {
      const parsedValue = value ? moment(value) : null;
      const onSign = () => {
        onChange(parsedValue ? null : new Date().toISOString());
        queueForSave();
      };

      return (
        <div>
          <div style={{ maxWidth: 200, display: 'inline-block' }}>
            <PillCheckbox
              multi={false}
              options={['E-Sign']}
              value={value ? 'E-Sign' : undefined}
              selectedIcon={<Icon icon="iconsCheckBlackSvg" />}
              onChange={() => onSign()}
            />
          </div>
          {parsedValue && (
            <Text.bodyGrey>Signed {parsedValue.format('MM/DD/YYYY hh:mm az')}</Text.bodyGrey>
          )}
        </div>
      );
    }}
  />
);

const RxQuestion = ({ question, queueForSave, name }: Props) => {
  // prettier-ignore
  const options = flatMap(question.options?.map<Medication>(
    o => JSON.parse(o)) ?? [],
    o => o.prescriptions.map(p => ({ ...p, groupName: o.groupName }))
  );

  const nameToRx = keyBy(options, o => o.name);

  return (
    <Controller
      name={name}
      defaultValue={[]}
      render={({ value, onChange }) => {
        const typedValue = (Array.isArray(value) ? value : []) as RX[];

        const handleChange = (idx: number, newVal: any) => {
          onChange(typedValue.filter(Boolean).map((v, i) => (i === idx ? { ...v, ...newVal } : v)));
          queueForSave();
        };

        const addSection = () => {
          onChange([...typedValue, { created: new Date().getTime() }]);
          queueForSave();
        };
        const remove = (idx: number) => {
          onChange(typedValue.filter((_, i) => i !== idx));
          queueForSave();
        };

        return (
          <div>
            {typedValue.map((item, index) => {
              if (!item) return null;
              return (
                <div key={item.created ?? index} className="mb3">
                  <div className="flex items-center">
                    <Styles.DeleteButton type="button" onClick={() => remove(index)}>
                      <Icon icon="iconsRedXSvg" />
                    </Styles.DeleteButton>
                    <h3 className="f4">RX #{index + 1}</h3>
                  </div>
                  <Grid gridTemplateColumns="3fr 2fr" gap=".5rem" className="mb2">
                    <Select
                      value={item.group}
                      options={options.map(o => ({ id: o.name, label: o.name }))}
                      placeholder="Medication"
                      onChange={group => handleChange(index, { group, dose: null })}
                    />
                    <Select
                      value={item.dose}
                      placeholder="Dosage"
                      options={nameToRx[item.group!]?.doses.map(id => ({ id, label: id })) ?? []}
                      onChange={dose => handleChange(index, { dose })}
                    />
                  </Grid>

                  <Textarea
                    value={item.note}
                    onChange={e => handleChange(index, { note: e.target.value })}
                    placeholder={question.placeholder || ''}
                  />
                </div>
              );
            })}
            <Styles.AddButton onClick={addSection}>
              + Add {typedValue.length ? 'another' : 'first'} Rx
            </Styles.AddButton>
          </div>
        );
      }}
    />
  );
};

const DiagnosisDropdown = ({ question, name, queueForSave }: Props) => {
  const { options, onInputChange, onInputBlur } = useDiagnosisSearch(question);

  return (
    <Controller
      defaultValue={null}
      name={name}
      render={({ value, onChange }) => {
        const selectValue = [];
        if (value) {
          selectValue.push({
            id: value,
            label: getLabeledDiagnosisCode(value as DiagnosisCode),
          });
        }
        return (
          <BaseSelect
            maxDropdownHeight="12rem"
            options={options}
            value={selectValue}
            placeholder={question.placeholder}
            onChange={({ option }) => {
              onChange((option?.id as string) ?? null);
              queueForSave();
            }}
            onInputChange={onInputChange}
            onBlur={onInputBlur}
            data-cy="diagnosis"
          />
        );
      }}
    />
  );
};

const DiagnosisMultiDropdown = ({ question, name, queueForSave }: Props) => {
  const { options, onInputChange, onInputBlur } = useDiagnosisSearch(question);

  return (
    <Controller
      defaultValue={[]}
      name={name}
      render={({ value, onChange }) => {
        const handleValueAdded = (added: string) => {
          onChange([...value, added as string]);
          queueForSave();
        };
        const handleValueRemoved = (index: number) => {
          onChange([...value.slice(0, index), ...value.slice(index + 1)]);
          queueForSave();
        };
        const filtered = options.filter(i => !value.includes(i.id));
        const maxSelectionsReached =
          question.maxSelections && value.length >= question.maxSelections;

        return (
          <>
            {!maxSelectionsReached && (
              <BaseSelect
                maxDropdownHeight="12rem"
                options={filtered}
                placeholder={question.placeholder}
                onChange={({ option }) => option && handleValueAdded(option?.id as string)}
                onInputChange={onInputChange}
                onBlur={onInputBlur}
                data-cy="diagnoses"
              />
            )}
            <div>
              {(value as string[]).map((item, index) => (
                <div className="mt3 mb3 flex items-center" key={item}>
                  <Styles.DeleteButton onClick={() => handleValueRemoved(index)}>
                    <Icon icon="iconsRedXSvg" />
                  </Styles.DeleteButton>
                  <div>{getLabeledDiagnosisCode(item)}</div>
                </div>
              ))}
            </div>
          </>
        );
      }}
    />
  );
};

const MultiCheckBox = ({ name, question, queueForSave }: Props) => {
  const handleMultiSelect = useMultiSelectHandler(question);

  return (
    <Controller
      name={name}
      defaultValue={[]}
      render={ctrl => {
        const { options, checked, onChangeIndex } = handleMultiSelect(ctrl);

        return (
          <div style={{ columnCount: min([options.length, 2]) }}>
            {options.map((option, index) => (
              <div className="dib w-100 ma1" key={option}>
                <Checkbox
                  key={option}
                  onChange={() => {
                    onChangeIndex(index);
                    queueForSave();
                  }}
                  checked={checked[index]}
                  overrides={overrides.checkbox}
                >
                  <span className="f6">{option}</span>
                </Checkbox>
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};

const CheckBox = ({ name, question, queueForSave }: Props) => {
  const { onChangeHook } = useFormHookHandler(question);

  return (
    <Controller
      defaultValue={null}
      name={name}
      render={({ value, onChange }) => {
        const options = question.options ?? [];

        const onCheckItem = (index: number) => {
          // uncheck if already checked
          if (options[index] === value) {
            onChangeHook();
            queueForSave();
            return onChange(options[-1]);
          }
          onChangeHook();
          onChange(options[index]);
          queueForSave();
        };

        return (
          <>
            {options.map((option, index) => (
              <div className="dib w-100 ma1" key={option}>
                <Checkbox
                  key={option}
                  onChange={() => onCheckItem(index)}
                  checked={options[index] === value}
                  overrides={overrides.checkbox}
                >
                  <span className="f6">{option}</span>
                </Checkbox>
              </div>
            ))}
          </>
        );
      }}
    />
  );
};

export const Appointment = ({ name, queueForSave, question, noteKey, noteTitle }: Props) => {
  const { user } = useDrilldownContext();
  const { currentProvider } = useCurrentProvider();
  const startTime = new Date('2021-04-01T00:00:00');
  const { data, loading } = useAppointmentsForNoteSelectorQuery({
    variables: { userId: user.id, startTime },
  });

  // Notes can vary in type, but  this map standardizes the process for intake and check-in validation.
  const noteKeyMap: Record<typeof visitNotes[number], string> = {
    'initial-evaluation': 'intake',
    'initial-evaluation-therapy': 'intake',
    'follow-up-therapy': 'checkin',
    'follow-up': 'checkin',
  };
  const validateNoteType = noteKeyMap[noteKey];

  const { setValue, watch, register } = useFormContext();

  useEffect(() => {
    // register the appointmentId field to make sure it appears in the end values,
    // otherwise it wil only appear if edited
    register('appointmentId');
  }, [register]);
  const appointmentCutoff = moment().add(5, 'days');

  const appointmentId = watch('appointmentId');

  const options = useMemo(() => {
    return (
      data?.adminAppointments
        .filter(
          apt =>
            apt.provider.id === currentProvider.id &&
            !apt.medicalNote &&
            ['complete', 'upcoming'].includes(apt.status) &&
            moment(apt.startTime).isBefore(appointmentCutoff)
        )
        .sort((a, b) => moment(b.startTime).diff(moment(a.startTime)))
        .map(apt => ({
          id: apt.id,
          label: appointmentLabel(apt),
          description: apt.description,
          type: apt.appointmentType,
        })) ?? []
    );
  }, [data]);

  const isMostRecentAppointment = options[0]?.id === appointmentId;

  const selectedAppointment = options.find(({ id }) => id === appointmentId);

  const selectedAppointmentType = selectedAppointment?.type;

  const isValidNoteType = useMemo(() => {
    if (!selectedAppointmentType) return null;
    const validAppointmentTypes = selectedAppointmentType === validateNoteType;
    return validAppointmentTypes;
  }, [selectedAppointmentType]);

  const selectedDescription = selectedAppointment?.description;

  const selectedLabel = selectedAppointment?.label.split('-')[0];

  const filteredAppointmentLabels = options
    ?.filter(({ type }) => {
      return type === validateNoteType;
    })
    .slice(1)
    .map(({ label }) => label);

  if (!isValidNoteType) {
    question.warning = {
      copy: [
        {
          style: { tag: 'bodyBold' },
          text: `The appointment type (${selectedDescription}) selected does not match the note type (${noteTitle}). Please make sure you are using the appropriate note type for this visit.`,
        },
      ],
      background: 'dangerBg',
      triggers: [validateNoteType === 'checkin' ? 'intake' : 'checkin'],
      dependsKey: 'appointmentType',
    };
  }

  if (isValidNoteType && !isMostRecentAppointment) {
    /**
     * Two validations are required: appointment type and most recent appointment.
     * Only this question currently has this requirement, but the trigger system allows only one validation that is in the API.
     * To work around this, we initialize the warning as an empty array in the API and set errors dynamically on the front-end.
     */
    question.warning = {
      copy: [
        {
          style: { tag: 'bodyBold' },
          text: `The appointment you have selected (${selectedLabel}) is not the soonest upcoming appointment. Please verify visit date and time before submitting.`,
        },
      ],
      background: 'dangerBg',
      triggers: filteredAppointmentLabels,
      dependsKey: 'appointment',
    };
  }

  return (
    <Controller
      name={name}
      defaultValue=""
      render={({ onChange, onBlur }) => (
        <Select
          onBlur={onBlur}
          value={appointmentId}
          onChange={value => {
            // use the label as the value of this question, so it is easy to store and display later,
            // but also store the ID separately to associate the note with an appointment on the backend
            setValue('appointmentType', options.find(({ id }) => id === value)?.type);
            setValue('appointmentId', value);
            onChange(options.find(({ id }) => id === value)?.label);
            queueForSave();
          }}
          options={options}
          disabled={loading}
          placeholder={loading ? 'Loading...' : 'Select...'}
        />
      )}
    />
  );
};

export const Templates: Record<SectionFormat, (p: Props) => JSX.Element | null> = {
  [SectionFormat.Multiselect]: Multiselect,
  [SectionFormat.Multitext]: Multitext,
  [SectionFormat.Singlelineinput]: SingleLineInput,
  [SectionFormat.Select]: SingleSelect,
  [SectionFormat.Sign]: Sign,
  [SectionFormat.Date]: Calendar,
  [SectionFormat.Textarea]: FormTextArea,
  [SectionFormat.Formtexttitle]: FormTextTitle,
  [SectionFormat.Rx]: RxQuestion,
  [SectionFormat.Title]: () => null,
  [SectionFormat.Diagnosis]: DiagnosisDropdown,
  [SectionFormat.Multidiagnosis]: DiagnosisMultiDropdown,
  [SectionFormat.Multicheckbox]: MultiCheckBox,
  [SectionFormat.Checkbox]: CheckBox,
  [SectionFormat.Appointment]: Appointment,
  // TODO (used only on patient portal)
  [SectionFormat.Radio]: () => null,
  [SectionFormat.Scale]: () => null,
};
