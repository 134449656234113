import { Input } from 'baseui/input';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FinalButton } from '../../Components/FinalButton';
import { Checkbox, Select } from '../../Components/Form';
import { Icon } from '../../Components/Icons';
import { SortIndicator } from '../../Components/ListView/SortIndicator';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { PaddedPage, Text } from '../../globalStyles';
import {
  CareStatus,
  NextStepsFilter,
  SortDirection,
  usePaginatedUsersQuery,
  useUsersOrganizationsLazyQuery,
} from '../../graphQL';
import { useDebounce } from '../../Hooks';
import { states } from '../../states';
import { PageTitle, UnexpectedError } from '../Shared';
import {
  CareFlowStatusColumn,
  careStatusOptions,
  NameColumn,
  nextStepsOptions,
  SponsoredCareColumn,
} from './Shared';
import { Value } from '../../Components/Form/Select/types';

interface Filters {
  organizationId?: number;
  geoState?: string;
  careStatus?: CareStatus;
  nextSteps?: NextStepsFilter;
}

interface Sort {
  column?: string;
  direction?: 'asc' | 'desc';
}

export function ProviderUsersList() {
  const { currentProvider } = useCurrentProvider();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [filters, setFilters] = useState<Filters>({});
  const [searchAllMatches, setSearchAllMatches] = useState(false);
  const [sort, setSort] = useState<Sort>({});
  const [searchableOrgs, setSearchableOrgs] = useState<OrgProps[]>([
    { label: currentProvider.organizations[0]?.name, id: currentProvider.organizations[0]?.id },
  ]);

  return (
    <PaddedPage data-cy="patient-list">
      <PageTitle>Patients</PageTitle>
      <div className="flex flex-row mt3 justify-start flex-wrap gap-3">
        <div>
          <Input
            value={searchValue}
            onChange={event => setSearchValue((event.target as HTMLInputElement).value)}
            startEnhancer={<Icon icon="iconsSearchSvg" alt="Search" />}
            placeholder="Search"
          />
        </div>
        <div>
          <Select
            options={{
              DTC: currentProvider.geoStates.map(state => ({ id: state, label: states[state] })),
              'Sponsored Care': searchableOrgs,
            }}
            placeholder="Organization"
            value={filters.organizationId || filters.geoState}
            onChange={val =>
              setFilters(f => ({
                ...f,
                organizationId: typeof val === 'number' ? val : undefined,
                geoState: typeof val === 'string' ? val : undefined,
              }))
            }
            clearable
          />
        </div>
        <div>
          <Select
            options={careStatusOptions}
            placeholder="Status"
            value={filters.careStatus}
            onChange={val => setFilters(f => ({ ...f, careStatus: val as CareStatus }))}
            clearable
          />
        </div>
        <div>
          <Select
            options={nextStepsOptions}
            placeholder="Next Steps"
            value={filters.nextSteps}
            onChange={val => setFilters(f => ({ ...f, nextSteps: val as NextStepsFilter }))}
            clearable
          />
        </div>
        <div className="flex flex-row items-center">
          <Checkbox
            checked={searchAllMatches}
            onChange={checked => {
              setSearchAllMatches(checked);
            }}
          >
            Search all matches
          </Checkbox>
        </div>
      </div>
      <Headers>
        <SortableHeader column="name" sort={sort} setSort={setSort}>
          <Text.body>Name</Text.body>
        </SortableHeader>
        <SortableHeader column="createdAt" sort={sort} setSort={setSort}>
          <Text.body>Date Enrolled</Text.body>
        </SortableHeader>
        <Text.body>Organization</Text.body>
        <Text.body>Email</Text.body>
        <Text.body>Sponsored Care</Text.body>
        <Text.body>Status</Text.body>
      </Headers>
      <Rows
        filters={filters}
        sort={sort}
        search={debouncedSearchValue}
        searchableOrgs={searchableOrgs}
        searchAllMatches={searchAllMatches}
        setSearchableOrgs={setSearchableOrgs}
      />
    </PaddedPage>
  );
}

interface SortableHeaderProps {
  column: string;
  sort: Sort;
  setSort: React.Dispatch<React.SetStateAction<Sort>>;
  children: React.ReactNode;
}

const SortableHeader = ({ column, sort, setSort, children }: SortableHeaderProps) => {
  return (
    <ClickableHeader
      type="button"
      onClick={() =>
        setSort(val => ({
          column,
          direction: val.column === column && val.direction === 'asc' ? 'desc' : 'asc',
        }))
      }
    >
      <SortIndicator direction={sort.column === column && sort.direction}>{children}</SortIndicator>
    </ClickableHeader>
  );
};

interface OrgProps {
  label: string;
  id: Value;
}

interface RowsProps {
  filters: Filters;
  search: string;
  sort: Sort;
  searchAllMatches?: boolean;
  searchableOrgs: OrgProps[];
  setSearchableOrgs: any;
}

const Rows = ({
  filters,
  search,
  sort,
  searchAllMatches,
  setSearchableOrgs,
  searchableOrgs,
}: RowsProps) => {
  const history = useHistory();
  const pageSize = 50;
  const cursors = useRef<(string | null)[]>([null]);
  const [activePageIndex, setActivePageIndex] = useState(0);
  const skip = searchAllMatches && search.length < 3;
  const { data, loading, error } = usePaginatedUsersQuery({
    fetchPolicy: 'cache-first',
    skip,
    variables: {
      params: {
        organizationId: filters.organizationId,
        nextSteps: filters.nextSteps,
        careStatus: filters.careStatus,
        geoState: filters.geoState,
        search,
        dtc: !!filters.geoState,
        sortDirection:
          sort.direction === 'desc' ? SortDirection.Descending : SortDirection.Ascending,
        sortField: sort.column,
        includeAllPatients: searchAllMatches,
        excludeMyPatients: searchAllMatches,
        cursor: cursors.current[activePageIndex],
        limit: pageSize,
      },
    },
    onCompleted: res => {
      if (!cursors.current.includes(res.paginatedUsers.cursor)) {
        cursors.current = [...cursors.current, res.paginatedUsers.cursor];
      }
    },
  });

  const [getUsersOrganizations, { data: orgsData, loading: orgsLoading, error: orgsError }] =
    useUsersOrganizationsLazyQuery();

  useEffect(() => {
    getUsersOrganizations({
      variables: {
        params: {
          organizationId: filters.organizationId,
          nextSteps: filters.nextSteps,
          careStatus: filters.careStatus,
          geoState: filters.geoState,
          search,
          dtc: !!filters.geoState,
          sortDirection:
            sort.direction === 'desc' ? SortDirection.Descending : SortDirection.Ascending,
          sortField: sort.column,
          includeAllPatients: searchAllMatches,
          excludeMyPatients: searchAllMatches,
          cursor: cursors.current[activePageIndex],
          limit: pageSize,
        },
      },
    });
  }, [searchAllMatches]);

  useEffect(() => {
    cursors.current = [null];
    setActivePageIndex(0);
  }, [filters, search, sort]);

  useEffect(() => {
    if (orgsData) {
      setSearchableOrgs(orgsData.usersOrganizations.map(v => ({ id: v.id, label: v.name })));
    }
  }, [orgsData]);

  const pageStart = pageSize * activePageIndex + 1;
  const pageEnd = Math.min(pageSize * (activePageIndex + 1), data?.paginatedUsers.count ?? 0);

  return (
    <div>
      {(loading || orgsLoading) && <LoadingPage />}
      {(error ||
        orgsError ||
        (!data && !loading && !skip) ||
        (!orgsData && !orgsLoading && !skip)) && <UnexpectedError />}
      {skip && (
        <div className="mt4">
          <Text.bodyBold kind="grayText" className="mb2">
            0 other matches across all your organizations
          </Text.bodyBold>
        </div>
      )}
      {data && (
        <div className="mt4">
          <Text.bodyBold kind="grayText" className="mb2">
            {data.paginatedUsers.count} {searchAllMatches ? 'other' : ''}{' '}
            {data.paginatedUsers.count === 1 ? 'match' : 'matches'}{' '}
            {searchAllMatches ? 'across all your organizations' : 'in your care panel'}
          </Text.bodyBold>
          {data.paginatedUsers.items.map((user, index) => (
            <UserRow
              key={user.id}
              first={index === 0}
              onClick={() => history.push(`/users/${user.id}`)}
            >
              <UserCell>
                <NameColumn user={user} />
              </UserCell>
              <UserCell>
                <Text.body>{moment(user.createdAt).format('M/D/YYYY')}</Text.body>
              </UserCell>
              <UserCell>
                <Text.body>{user.organization?.name ?? 'DTC'}</Text.body>
              </UserCell>
              <UserCell>
                <Text.body>{user.email}</Text.body>
              </UserCell>
              <UserCell>
                <SponsoredCareColumn user={user} />
              </UserCell>
              <UserCell>
                <CareFlowStatusColumn user={user} />
              </UserCell>
            </UserRow>
          ))}
          {(data.paginatedUsers.hasMore || activePageIndex > 0) && (
            <div className="flex flex-row items-end mt3 mb3 items-center justify-end gap-2">
              <Text.body className="mr3">
                Viewing {pageStart}-{pageEnd} of {data.paginatedUsers.count}
              </Text.body>
              <FinalButton
                kind="minimal_black"
                iconLeft="iconsLeftChevronSvg"
                onClick={() => setActivePageIndex(val => val - 1)}
                disabled={activePageIndex === 0}
              />
              <FinalButton
                kind="minimal_black"
                iconLeft="iconsRightChevronSvg"
                onClick={() => setActivePageIndex(val => val + 1)}
                disabled={pageEnd >= data.paginatedUsers.count}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  column-gap: 10px;
  padding: 14px 38px;
  word-break: break-word;
  width: 100%;
`;

const Headers = styled(GridRow)`
  background: #f7f7f7;
  border: 1px solid #d8d8d8;
  margin: 32px 0 16px 0;
  outline: 0;
`;

const UserRow = styled(GridRow).attrs({ as: 'button' })<{ first?: boolean }>`
  border: 1px solid #d8d8d8;
  ${({ first }) => !first && 'border-top: none;'}
  min-height: 0;
  min-width: 0;
  background: none;
  text-align: initial;
  cursor: pointer;
`;

const UserCell = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
`;

const ClickableHeader = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: 0;
`;
