import { Accordion, Panel, PanelOverrides } from 'baseui/accordion';
import moment from 'moment';
import React, { useState } from 'react';
import { useEvents } from '../../../Components/Events/EventsProvider';
import { FinalButton } from '../../../Components/FinalButton';
import { Icon } from '../../../Components/Icons';
import { isMcpOrReferralUser, useCurrentProvider } from '../../../Components/Permissions';
import { Text } from '../../../globalStyles';
import { useGetAllUploadsOnUserQuery } from '../../../graphQL';
import { fileAccordionFactory } from '../baseuiOverrides';
import { FileEdit } from '../Files/FileEdit';
import { FileUpload } from '../Files/FileUpload';
import { uploadTypeLabels } from '../Files/Input';
import { Upload } from '../Files/interfaces';
import { useDrilldownContext, ViewWarning } from '../helpers';
import { Styles } from '../styles';
import { LoadingWidget } from '../Widgets/Loading';

export const FilesTab = () => {
  const [uploadOpen, setUploadOpen] = useState(false);
  const [editOpen, setEditOpen] = useState<Upload | null>();

  const { currentProvider } = useCurrentProvider();
  const { user, refetch: userRefetch } = useDrilldownContext();
  const {
    data,
    loading,
    refetch: uploadsRefetch,
  } = useGetAllUploadsOnUserQuery({
    variables: { id: user.id },
    fetchPolicy: 'network-only',
  });

  if (loading) return <LoadingWidget />;
  const uploads = data?.adminUser.uploads ?? [];
  const isMcp = isMcpOrReferralUser(currentProvider);

  const refetch = () => {
    userRefetch();
    uploadsRefetch();
  };

  return (
    <div>
      <ViewWarning limited restricted>
        Due to limited record access, some files may not be visible.
      </ViewWarning>
      <Styles.widget>
        <div>
          <div className="mb3 flex flex-row justify-between align-center">
            <Text.h2>Files</Text.h2>
            <FinalButton
              kind="outline_black"
              iconLeft={{ icon: 'iconsBlackUploadSvg', size: 20 }}
              onClick={() => setUploadOpen(!uploadOpen)}
            >
              Upload File
            </FinalButton>
          </div>
          {uploads.length === 0 && (
            <Text.body className="mb4">
              <em>No uploads</em>
            </Text.body>
          )}
        </div>
        {uploads.map((upload, i) => (
          <UploadRow
            key={String(upload.createdAt)}
            upload={upload}
            isMcp={isMcp}
            editUpload={u => setEditOpen(u)}
            panelOverrides={fileAccordionFactory({ isLastRow: i === uploads.length - 1 })}
          />
        ))}
      </Styles.widget>
      {uploadOpen && <FileUpload closeModal={() => setUploadOpen(false)} refetch={userRefetch} />}
      {editOpen && (
        <FileEdit closeModal={() => setEditOpen(null)} upload={editOpen} refetch={refetch} />
      )}
    </div>
  );
};

type RowProps = {
  upload: Upload;
  isMcp: boolean;
  editUpload: (u: Upload) => void;
  panelOverrides?: PanelOverrides<any>;
};

const UploadRow = ({ upload, isMcp, editUpload, panelOverrides }: RowProps) => {
  const metrics = useEvents();
  const { user } = useDrilldownContext();
  const { createdAt, url, contentType, name, createdByProvider, type, globallyShared, canEdit } =
    upload;
  const parsedTime = moment(createdAt);

  const Title = (
    <div className="mr2" key={String(createdAt)}>
      <div className="flex items-center justify-between w-100">
        <div className="flex items-center">
          <Styles.infoDiv>
            <Text.caption>
              {parsedTime.format('MM/DD/YYYY')}
              <br />
              {parsedTime.format('hh:mm a')}
            </Text.caption>
          </Styles.infoDiv>
          <Icon icon="iconsBlackUploadSvg" alt={contentType} className="mr2" />
          {createdByProvider && `${createdByProvider.name} uploaded`} {name}
        </div>
      </div>
    </div>
  );

  return (
    <Accordion>
      <Panel title={Title} overrides={panelOverrides}>
        <Styles.fileDetailsContainer>
          <div className="flex flex-column justify-center gap-2">
            {canEdit && (
              <FinalButton
                onClick={() => editUpload(upload)}
                iconLeft="iconsPencilSvg"
                kind="minimal_gray"
              >
                Edit Details
              </FinalButton>
            )}
            <FinalButton
              kind="outline_black"
              iconRight="iconsRightChevronSvg"
              onClick={() => {
                metrics.track(`user.file.opened`, {
                  type,
                  contentType,
                  userId: user.id,
                  customerId: user.customerId,
                });
                window.open(url, '_blank');
              }}
            >
              Open File
            </FinalButton>
          </div>
          <Styles.verticalSlash />
          <div className="flex flex-column justify-center">
            <div>
              <Text.label>Type</Text.label>
              <Text.body>{uploadTypeLabels[type]}</Text.body>
            </div>
            {!isMcp && (
              <div className="mt3">
                <Text.bodyBold className="dib">
                  {globallyShared ? 'Visible' : 'Not Visible'}
                </Text.bodyBold>
                <Text.body className="dib">&nbsp;to MCP users</Text.body>
              </div>
            )}
          </div>
        </Styles.fileDetailsContainer>
      </Panel>
    </Accordion>
  );
};
