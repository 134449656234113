import moment from 'moment';
import React from 'react';
import { useCocExport } from '../../../Components/AsyncJobs/useCocExport';
import { Icon } from '../../../Components/Icons';
import { Text } from '../../../globalStyles';
import { useGetAllNotesForUserQuery } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { InTextSpinner, Styles } from '../styles';

export const CocExportWidget = () => {
  const { user } = useDrilldownContext();
  const { data } = useGetAllNotesForUserQuery({ variables: { id: user.id } });
  const { beginExport, status } = useCocExport({ userId: user.id });

  if (!data) return null;
  const continuityNote = data.adminUser.notes.find(n => n.key === 'continuity-of-care');
  const { submittedAt } = continuityNote ?? {};

  if (!continuityNote || !submittedAt) return null;
  if (moment().diff(moment(submittedAt), 'd') > 30) return null;
  return (
    <Styles.widget color="grey">
      <div
        className="flex justify-between items-center"
        style={{ marginTop: '-12px', marginBottom: '-12px', marginLeft: '-14px' }}
      >
        <div className="flex items-center">
          <Icon icon="iconsNoteFadedSvg" className="mr2" alt="Export" />
          <Text.body>{moment(submittedAt).format('L')} - Continuity of Care Record</Text.body>
        </div>
        {status && status !== 'complete' ? (
          <InTextSpinner />
        ) : (
          <Text.linkButton disabled={!!status} onClick={beginExport}>
            Export
          </Text.linkButton>
        )}
      </div>
    </Styles.widget>
  );
};
