import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useQueryParams } from '../../Hooks';
import { useOrganizationOptions } from '../../Hooks/useOrganizationOptions';
import { stateOptions } from '../../states';
import { Select } from '../Form';
import { useNetworkSearchOptions } from './hooks';
import { useProviderNetworkContext } from './ProviderNetworkContext';
import { SearchSelections } from './Styles';
import { ProviderSearchFields } from './types';

export const ProviderNetworkOptions = () => {
  const {
    isSelfPayFlow,
    organizationId,
    searchVariables: { state, payerId, careType },
  } = useProviderNetworkContext();

  const options = useNetworkSearchOptions();
  const [parsedSearch, updateQueryParams] = useQueryParams();
  const { orgOpts } = useOrganizationOptions();

  const shouldCollectInsurance = isSelfPayFlow;

  /* in case the user toggles between care types */
  useEffect(() => {
    if (!shouldCollectInsurance && !isNil(payerId)) {
      updateQueryParams({ ...parsedSearch, payerId: undefined });
    }
  }, [shouldCollectInsurance, parsedSearch, payerId, updateQueryParams]);

  const onChange = (key: keyof ProviderSearchFields, value: string | number | null) => {
    const updatedParams: Partial<ProviderSearchFields> = { ...parsedSearch, [key]: value };
    if (!shouldCollectInsurance) {
      updatedParams.payerId = undefined;
    }
    updateQueryParams(updatedParams);
  };

  return (
    <div className="flex flex-wrap gap-3 w-100 justify-between">
      <SearchSelections>
        <Select
          iconLeft="iconsClipboardSvg"
          value={careType}
          onChange={v => onChange('careType', v)}
          hideKaret
          placeholder="Type of care"
          options={options.careTypes}
        />
        <Select
          iconLeft="iconsLocationPinSvg"
          value={state}
          onChange={v => onChange('state', v)}
          hideKaret
          placeholder="State"
          options={stateOptions}
          clearable
        />
        {shouldCollectInsurance && (
          <Select
            iconLeft="iconsPaymentSvg"
            value={payerId ? Number(payerId) : undefined}
            onChange={v => onChange('payerId', v)}
            hideKaret
            placeholder="Insurance"
            options={options.payers}
            clearable
          />
        )}
        {orgOpts.length > 1 && (
          <Select
            value={organizationId ? Number(organizationId) : undefined}
            onChange={v => onChange('organizationId', v)}
            placeholder="Organization"
            options={orgOpts}
            hideKaret
          />
        )}
      </SearchSelections>
    </div>
  );
};
