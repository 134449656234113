import { isMantraAdmin, useCurrentProvider } from '../../Components/Permissions';
import {
  BaseReportFragment,
  DiagnosisReportFragment,
  ReportScope,
  useDiagnosesBreakdownQuery,
  useOrganizationReportQuery,
  useOverviewDiagnosesBreakdownQuery,
  useOverviewReportQuery,
} from '../../graphQL';

type Args = {
  scope: ReportScope;
  orgId?: number;
  skip?: boolean;
  carePeriodId?: number;
};

type OrgReportResult = {
  loading?: boolean;
  data?: BaseReportFragment;
};

type DiagnosesResult = {
  loading?: boolean;
  data?: DiagnosisReportFragment['diagnosesBreakdown'];
};

export const useOrgReport = ({ orgId, scope, skip, carePeriodId }: Args): OrgReportResult => {
  const { currentProvider } = useCurrentProvider();
  const isAdminQuery = scope !== ReportScope.Org;

  const orgResult = useOrganizationReportQuery({
    variables: { organizationId: orgId!, carePeriodId },
    skip: isAdminQuery,
  });
  const adminResult = useOverviewReportQuery({
    variables: { scope },
    skip: !isAdminQuery || !isMantraAdmin(currentProvider),
  });

  if (skip) return { loading: false };

  const loading = orgResult.loading || adminResult.loading;
  const data = isAdminQuery
    ? adminResult.data?.adminOverviewReport
    : orgResult.data?.organization.reporting;

  return { loading, data };
};

export const useDiagnosesBreakdown = ({ orgId, scope, skip }: Args): DiagnosesResult => {
  const { currentProvider } = useCurrentProvider();
  const isAdminQuery = scope !== ReportScope.Org;

  const orgResult = useDiagnosesBreakdownQuery({
    variables: { organizationId: orgId! },
    skip: isAdminQuery,
  });

  const adminResult = useOverviewDiagnosesBreakdownQuery({
    variables: { scope },
    skip: !isAdminQuery || !isMantraAdmin(currentProvider),
  });

  if (skip) return { loading: false };

  const loading = orgResult.loading || adminResult.loading;
  const data = isAdminQuery
    ? adminResult.data?.adminOverviewReport.diagnosesBreakdown
    : orgResult.data?.organization.reporting.diagnosesBreakdown;

  return { loading, data };
};
