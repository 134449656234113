import { isNil, times } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { Select } from '../../../../Components/Form';
import { isMantraAdmin, useCurrentProvider } from '../../../../Components/Permissions';
import { TimeZone } from '../../../../utils';
import { LocalAllocation } from './Hooks/types';
import { isWithinRestrictedTimeET } from './availabilityUtils';

// time options increment by this value
const TIME_INTERVAL = 15;
const SLOTS_PER_DAY = Math.floor((60 * 24) / TIME_INTERVAL);

export type TimeType = 'startTime' | 'endTime';

type TimeSelectProps<T extends TimeType> = {
  timeType: T;
  timezone: TimeZone;
  forDate: Moment;
  allocation: LocalAllocation;
  onChange: (timeType: T, updatedMoment: Moment) => void;
  style?: React.CSSProperties;
  className?: string;
  id?: string;
  disabled?: boolean;
};

// Using JS Date, destroys the timezone
export function TimeSelect<T extends TimeType>({
  timeType,
  timezone,
  forDate,
  allocation,
  onChange,
  style,
  className,
  id,
  disabled = false,
}: TimeSelectProps<T>) {
  const { currentProvider } = useCurrentProvider();
  const isAdmin = isMantraAdmin(currentProvider);

  const selectionStartMoment = hasTimeType(allocation, timeType)
    ? allocation[timeType].clone().tz(timezone)
    : null;

  const selectionOptions = useMemo(() => {
    return selectionStartMoment
      ? generateFilteredSlots({
          startTime: selectionStartMoment,
          limitedTimes: !isAdmin && !disabled,
        })
      : generateFilteredSlots({
          startTime: getDateInTz(forDate, timezone),
          limitedTimes: !isAdmin && !disabled,
        });
  }, [selectionStartMoment, forDate, timezone]);

  const currentSelection = selectionStartMoment
    ? selectionOptions.findIndex(s => s.isSame(selectionStartMoment))
    : undefined;

  const convertBackToAllocTimezone = useCallback(
    (idx: number) => selectionOptions[idx].clone().tz(allocation.timezone),
    [selectionOptions, allocation.timezone]
  );

  return (
    <Select
      id={id}
      placeholder="Select"
      disabled={disabled}
      clearable={false}
      value={currentSelection}
      options={selectionOptions.map((s, i) => ({
        id: i,
        label: s.format('h:mm a'),
      }))}
      onChange={e => onChange(timeType, convertBackToAllocTimezone(e as number))}
      style={style}
      className={className}
    />
  );
}

const getDateInTz = (m: Moment, timezone: TimeZone) => {
  return moment.tz({ date: m.date(), month: m.month(), year: m.year() }, timezone).startOf('day');
};

const generateFilteredSlots = ({
  startTime,
  limitedTimes = false,
}: {
  startTime: moment.Moment;
  limitedTimes?: boolean;
}): moment.Moment[] => {
  const slots = times(SLOTS_PER_DAY, i => {
    return startTime
      .clone()
      .startOf('day')
      .add(i * TIME_INTERVAL, 'minutes');
  });

  if (limitedTimes) {
    return slots.filter(slot => !isWithinRestrictedTimeET(slot));
  }
  return slots;
};

export const hasTimeType = <T extends TimeType>(
  allocation: LocalAllocation,
  timeType: T
): allocation is LocalAllocation & { [t in T]: Moment } => {
  return timeType in allocation && !isNil(allocation[timeType as keyof LocalAllocation]);
};
