import { compact } from 'lodash';
import React from 'react';
import { Text } from '../../globalStyles';
import { NextSteps } from '../../graphQL';

const baseFormCopy = {
  residentialLocationDuringBreak: {
    eyebrow: 'Residental location during break',
    label:
      'Please confirm with the patient what state they will be residing in during the upcoming Break.',
    required: true,
    subtitle:
      "Select the main residential state that the patient will be in during the upcoming break. We'll use this to determine whether the patient is eligible to see you due to state-by-state telehealth restrictions and licensure.",
  },
  interestedInContinuingCare: {
    eyebrow: 'Patient interest',
    required: true,
  },
  enrollment: {
    eyebrow: 'Enrollment',
    label: 'Is the patient eligible for sponsored care based on intended enrollment?',
    subtitle: null,
    required: true,
  },
  additionalNotes: {
    label: 'Additional notes',
  },
};

interface DescriptionProps {
  endDate?: string;
  nextStartDate?: string;
}

export const formCopy = {
  selfPay: {
    residentialLocationDuringBreak: baseFormCopy.residentialLocationDuringBreak,
    interestedInContinuingCare: {
      ...baseFormCopy.interestedInContinuingCare,
      label: () => (
        <Text.bodyBold>
          Is the patient interested in continuing care with Mantra via{' '}
          <Text.span kind="danger">self-pay</Text.span>? <Text.span kind="danger">*</Text.span>
        </Text.bodyBold>
      ),
      subtitle: () => (
        <Text.bodySmall>
          If the patient is interested in potentially continuing care, select ‘Yes’. Pricing for
          self-pay care is cash-only.
        </Text.bodySmall>
      ),
    },
    enrollment: {
      ...baseFormCopy.enrollment,
      label:
        'Is the patient intending to still be enrolled full-time at [ORGANIZATION] next Semester?',
    },
    additionalNotes: baseFormCopy.additionalNotes,
  },
  sponsored: {
    residentialLocationDuringBreak: baseFormCopy.residentialLocationDuringBreak,
    interestedInContinuingCare: {
      eyebrow: baseFormCopy.interestedInContinuingCare.eyebrow,
      label: () => (
        <Text.bodyBold>
          Is the patient interested in continuing care with Mantra via{' '}
          <Text.span kind="success">sponsored care</Text.span>?{' '}
          <Text.span kind="danger">*</Text.span>
        </Text.bodyBold>
      ),
      required: baseFormCopy.interestedInContinuingCare.required,
      subtitle: () => (
        <Text.bodySmall>
          If the patient is eligible and interested, we&apos;ll keep their account active so they
          can book sponsored visits throughout the break.
        </Text.bodySmall>
      ),
    },
    enrollment: {
      ...baseFormCopy.enrollment,
      subtitle: 'View enrollment eligibility within the [ORGANIZATION] handbook',
    },
    additionalNotes: baseFormCopy.additionalNotes,
  },
};

export const nextStepsModuleCopy = {
  lessThan3ApptsLeft: {
    NotSelected: {
      label: 'Not selected',
      textColor: 'danger',
      description: ({ endDate }: DescriptionProps) => (
        <>
          This patient&apos;s care will transition to the next steps selected once their sponsored
          care ends on {endDate}, or when all sponsored sessions have been used by the patient.
        </>
      ),
    },
    [NextSteps.ActiveSponsored]: {
      label: 'Continue spon care',
      textColor: 'success',
      description: ({ nextStartDate }: DescriptionProps) => (
        <>
          Patient&apos;s university <b>sponsored care will remain active</b> into the next care{' '}
          period starting {nextStartDate}, or when all sponsored sessions have been used by the
          patient.
        </>
      ),
    },
    [NextSteps.ActiveSelfPay]: {
      label: 'Self-Pay (Pending)',
      textColor: 'success',
      description: ({ nextStartDate }: DescriptionProps) => (
        <>
          Patient&apos;s university <b>sponsored care will remain active</b> into the next care{' '}
          period starting {nextStartDate}, or when all sponsored sessions have been used by the
          patient.
        </>
      ),
    },
    [NextSteps.OnHold]: {
      label: 'On Hold',
      textColor: 'text',
      description: ({ endDate }: DescriptionProps) => (
        <>
          This patient&apos;s care will be <b>put On Hold </b> once their sponsored care ends on{' '}
          {endDate}, or when all sponsored sessions have been used by the patient. Patient will not
          be able to book visits or message provider during this time.
        </>
      ),
    },
    [NextSteps.Cancel]: {
      label: 'Cancel',
      textColor: 'text',
      description: ({ endDate }: DescriptionProps) => (
        <>
          This patient&apos;s care will be <b>Cancelled</b> once their sponsored care ends on{' '}
          {endDate}, or when all sponsored sessions have been used by the patient. Patient will not
          be able to access their Mantra account. Please inform Mantra Care Navigation of the
          discharge of this patient.
        </>
      ),
    },
  },
  moreThan2ApptsLeft: {
    NotSelected: {
      label: 'Not selected',
      textColor: 'danger',
      description: ({ endDate }: DescriptionProps) => (
        <>
          This patient&apos;s care will transition to the next steps selected once their sponsored
          care ends on {endDate}, or when all sponsored sessions have been used by the patient.
        </>
      ),
    },
    [NextSteps.ActiveSponsored]: {
      label: 'Continue spon care',
      textColor: 'success',
      description: ({ nextStartDate }: DescriptionProps) => (
        <>
          Patient&apos;s university sponsored care will remain active into the next care period{' '}
          starting {nextStartDate}.
        </>
      ),
    },
    [NextSteps.ActiveSelfPay]: {
      label: 'Self-Pay (Pending)',
      textColor: 'success',
      description: ({ endDate }: DescriptionProps) => (
        <>
          Patient will <b>continue as a self-pay patient</b> once their sponsored care ends on{' '}
          {endDate}.
        </>
      ),
    },
    [NextSteps.OnHold]: {
      label: 'On Hold',
      textColor: 'text',
      description: ({ endDate }: DescriptionProps) => (
        <>
          This patient&apos;s care will be <b>put On Hold </b> once their sponsored care ends on{' '}
          {endDate}. Patient will not be able to book visits or message provider during this time.
        </>
      ),
    },
    [NextSteps.Cancel]: {
      label: 'Cancel',
      textColor: 'text',
      description: ({ endDate }: DescriptionProps) => (
        <>
          This patient&apos;s care will be <b>Cancelled</b> once their sponsored care ends on{' '}
          {endDate}. Patient will not be able to access their Mantra account. Please inform Mantra
          Care Navigation of the discharge of this patient.
        </>
      ),
    },
  },
};

interface ResultsProps {
  isEligible: boolean;
  interestedInContinuingCare: boolean;
  sessionsEnding: boolean;
  isSponsoredCare: boolean;
  endingDueToBreak?: boolean | null;
  needsReferral: boolean;
  firstDayOfBreak: string | null | undefined;
  lastDayOfBreak: string | null | undefined;
  lastDayOfTerm: string | null | undefined;
  providerInState: boolean;
}

export interface CopyBlock {
  block: string;
  condition: boolean;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  list?: any[];
}

// Copy for eligiblility results form with conditions included
export const resultsCopy = ({
  isEligible,
  interestedInContinuingCare,
  sessionsEnding,
  isSponsoredCare,
  endingDueToBreak,
  needsReferral,
  firstDayOfBreak,
  lastDayOfBreak,
  lastDayOfTerm,
  providerInState,
}: ResultsProps) => ({
  blocks: [
    {
      block: 'eligibility',
      condition: isEligible,
      title: `${
        interestedInContinuingCare
          ? 'Eligible'
          : 'Although not interested, this patient is eligible'
      } ${isSponsoredCare ? 'to continue sponsored' : 'for self-pay'} ${
        sessionsEnding
          ? `care once all university-sponsored sessions have been used`
          : `care during break, starting ${firstDayOfBreak}`
      }.`,
      description:
        interestedInContinuingCare && sessionsEnding
          ? 'Some universities refill sponsored sessions each academic year for students who remain enrolled. If eligible to reactivate sponsored care in the future, we’ll allow the patient to return to sponsored sessions within their portal.'
          : null,
    },
    {
      block: 'eligibility',
      condition: !isEligible,
      title: sessionsEnding
        ? `Not eligible to continue Mantra care once all university-sponsored sessions have been used.`
        : `Not eligible to continue Mantra care during break, starting ${firstDayOfBreak}.`,
      description: !providerInState
        ? `We’ll need to pause or cancel this patient’s Mantra care due to their ineligible location.`
        : `We’ll need to pause this patient’s Mantra care during break (${firstDayOfBreak} - ${lastDayOfBreak}).`,
    },
    {
      block: 'next-steps',
      condition: isEligible && interestedInContinuingCare,
      title: isSponsoredCare ? 'CONTINUE SPONSORED CARE' : 'SELF-PAY',
      description: isSponsoredCare
        ? 'Select next steps of ‘Continue sponsored care’ for this patient if they want to continue their university-sponsored care during break.'
        : `Select next steps of ‘Self-Pay’ for this patient if they want to continue care once they’ve used all of their sponsored sessions. We’ll reach out to the patient to have them set up their payment details.`,
    },
    {
      block: 'next-steps',
      condition: !isEligible || !interestedInContinuingCare,
      title: 'ON HOLD',
      description: `If the patient is expected to return to Mantra care in the future (i.e. next academic year), select Next Steps as ‘On Hold’${
        endingDueToBreak
          ? '. The patient will not be able to message you or book appointments while On Hold, but can reach out to Mantra Patient Support if they have any questions.'
          : ' so the patient can reactivate care when possible.'
      }`,
    },
    {
      block: 'next-steps',
      condition: !isEligible || !interestedInContinuingCare,
      title: 'CANCEL',
      description: `If the patient is NOT likely to return to Mantra care in the future, set next steps as ‘Cancel’, so we can properly discharge this patient once they are out of sponsored sessions. This patient will no longer appear on your caseload once discharged, and their Mantra portal will be disabled.`,
    },
    {
      block: 'care-navigation',
      condition: !!careNavigationBullets(isEligible, isSponsoredCare, needsReferral).length,
      title: 'Create Task for Care Navigation (optional)',
      description: () => careNavigationBullets(isEligible, isSponsoredCare, needsReferral),
    },
    {
      block: 'scheduling-visits',
      condition: !(isEligible && isSponsoredCare && interestedInContinuingCare),
      list: [
        <li key="scheduling-visits-do-not-book">
          <Text.bodySmall>
            <b>Do not book any visits</b>{' '}
            {sessionsEnding
              ? 'if this patient does not have any sponsored sessions remaining.'
              : `for this patient beyond ${lastDayOfTerm}.`}
          </Text.bodySmall>
        </li>,
      ],
    },
    {
      block: 'scheduling-visits',
      condition: !(isSponsoredCare && interestedInContinuingCare) && !sessionsEnding && isEligible,
      list: [
        <li key="scheduling-visits-care-navigatork">
          <Text.bodySmall>
            A Care Navigator will facilitate scheduling the first self-pay visit once the patient
            has confirmed they’d like to continue with self-pay care.
          </Text.bodySmall>
        </li>,
        <li key="scheduling-visits-note-reactivate">
          <Text.bodySmall>
            <b>Note:</b> This patient will be able to reactivate their university-sponsored Mantra
            care after break, if their university offers sponsored care. We’ll reach out to them to
            get them reactivated and scheduled.
          </Text.bodySmall>
        </li>,
      ],
    },
    {
      block: 'scheduling-visits',
      condition: !(isSponsoredCare && interestedInContinuingCare) && !sessionsEnding && !isEligible,
      list: [
        <li key="scheduling-visits-future">
          <Text.bodySmall>
            If the patient is eligible for sponsored care in the future, we’ll reach out to them to
            get them reactivated and scheduled.
          </Text.bodySmall>
        </li>,
      ],
    },
    {
      block: 'scheduling-visits',
      condition: !(isSponsoredCare && interestedInContinuingCare) && sessionsEnding,
      list: [
        <li key="scheduling-visits-reminder">
          <Text.bodySmall>
            <b>Reminder:</b> Sessions are deducted from a patient’s session count once the
            appointment is marked as completed.
          </Text.bodySmall>
        </li>,
      ],
    },
    {
      block: 'scheduling-visits',
      condition: isEligible && isSponsoredCare && interestedInContinuingCare,
      list: [
        <li key="scheduling-visits-book-now">
          <Text.bodySmall>
            <b>Book now!</b> You may continue to book upcoming sponsored care visits for this
            patient, since they’ll be continuing sponsored care.
          </Text.bodySmall>
        </li>,
        <li key="scheduling-visits-note-lack-of-availability">
          <Text.bodySmall>
            <b>Note:</b> If this patient cannot be seen due to lack of availability over break,
            please assign a task to Care Navigation with details.
          </Text.bodySmall>
        </li>,
      ],
    },
  ],
});

const careNavigationBullets = (
  isEligible: boolean,
  isSponsoredCare: boolean,
  needsReferral: boolean
) =>
  compact([
    isEligible && !isSponsoredCare && (
      <>
        <b>Request patient outreach with more self-pay information:</b> If the patient would like
        more information (i.e. pricing) about continuing care, assign a task to Mantra Care
        Navigators within this patient’s chart. Please set the task category to Continuity of Care,
        and ask them to follow-up with the Patient with more information.
      </>
    ),
    needsReferral && (
      <>
        <b>Needs referral:</b> If this patient needs a referral, please create a task assigned to
        Care Navigation with the referral request. Care Navigation will contact the patient and work
        with them to find a suitable provider during break.
      </>
    ),
    !isEligible && !isSponsoredCare && (
      <>
        <b>Student leaving university:</b> Please let us know if this patient is not planning on
        returning to their university next semester (graduation, transfer, etc)
      </>
    ),
  ]);
