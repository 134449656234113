import React, { ReactNode } from 'react';
import { SessionPageWrapper } from '../../Pages/Session/Shared';
import { Text } from '../../globalStyles';
import { useGeolocationContext } from './GeolocationContext';

export const GeolocationCheck = ({ children }: { children: ReactNode }): JSX.Element => {
  const { isGeolocationBlocked } = useGeolocationContext();

  if (!isGeolocationBlocked) {
    return <>{children}</>;
  }

  return (
    <SessionPageWrapper title="">
      <Text.h2 className="mb4">
        You must be located in the United States to access Mantra products and services.
      </Text.h2>
      <Text.body className="mb4">
        We recognize you are attempting to access this site from a location outside of the United
        States. In order to uphold GDPR compliance, as well as for the protection of your personal
        data, access to Mantra is prohibited in other countries.
      </Text.body>
      <Text.body>
        If you need non-urgent assistance with your account, email us at{' '}
        <Text.externalLink
          href="mailto:hi@mantrahealth.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          hi@mantrahealth.com
        </Text.externalLink>
        .
      </Text.body>
    </SessionPageWrapper>
  );
};
