import { Spinner } from 'baseui/icon';
import { toaster } from 'baseui/toast';
import { get } from 'lodash';
import React from 'react';
import { ColorLookup, colors } from '../../globalStyles';
import { Maybe, Permission, useZendeskLinkLazyQuery } from '../../graphQL';
import { useIsMounted } from '../../Hooks';
import { icons } from '../Icons';
import { InlineSVG } from '../Icons/InlineSVG';
import { useCurrentProvider } from '../Permissions';
import * as Styles from './styles';
import { MantraSvg, MantraSvgKeys } from './SVG';

const presets = {
  university: icons.iconsUniSvg,
  warning: icons.iconsFlairWarningSvg,
} as const;

type TextIconProps = {
  opacity?: number;
  src?: string;
  alt?: string;
  hide?: boolean;
  preset?: keyof typeof presets;
  className?: string;
};

export const TextIcon: React.FC<TextIconProps> = ({
  children,
  src,
  opacity,
  alt = 'Icon',
  hide,
  preset,
  className,
}) => {
  const source = src || (preset && presets[preset]);

  return (
    <Styles.FlairPosition className={className}>
      {!hide && source && <Styles.StyledTextIcon src={source} alt={alt} style={{ opacity }} />}
      {children}
    </Styles.FlairPosition>
  );
};

type SvgTextIconProps = {
  icon: MantraSvgKeys;
  opacity?: number;
  fill?: string;
  kind?: ColorLookup;
};
export const TextIconSvg: React.FC<SvgTextIconProps> = ({
  children,
  icon,
  opacity,
  fill,
  kind,
}) => {
  const fillColor = fill || (kind && get(colors, kind));
  return (
    <Styles.FlairPosition>
      <span style={{ marginRight: '0.5rem', width: '1em', height: '1em', display: 'block' }}>
        <MantraSvg size="1em" icon={icon} opacity={opacity} fill={fillColor} />
      </span>
      {children}
    </Styles.FlairPosition>
  );
};

const riskIcons: Record<string, string | undefined> = {
  high: icons.iconsFlairHighSvg,
  intermediate: icons.iconsFlairIntermediateSvg,
  imminent: icons.iconsFlairRedSvg,
};
type RiskProps = {
  level?: Maybe<string>;
};

export const Risk: React.FC<RiskProps> = ({ children, level }) => {
  const icon = (level && riskIcons[level.toLowerCase()]) || undefined;
  return <TextIcon src={icon}>{children}</TextIcon>;
};

type WarningProps = Omit<TextIconProps, 'src'>;
export const Warning: React.FC<WarningProps> = ({ children, ...props }) => (
  <TextIcon src={icons.iconsFlairWarningSvg} {...props}>
    {children}
  </TextIcon>
);

export const QuickEmailLink: React.FC<{ user: { id: number } }> = ({ user }) => {
  const { hasPermission } = useCurrentProvider();
  const [query, { loading }] = useZendeskLinkLazyQuery({
    onCompleted: data => window.open(data.zendeskLink, '_blank'),
  });

  if (!hasPermission(Permission.MantraAdmin)) return null;

  const handleClick = async () => {
    if (!loading) {
      query({ variables: { userId: user.id } });
    }
  };

  return (
    <span className="flex-auto mt2">
      <button
        onClick={handleClick}
        className="pointer dib o-50"
        style={{ width: 18, height: 18, background: 'none', border: '0', padding: '0' }}
        type="button"
      >
        {loading ? (
          <Spinner size={18} />
        ) : (
          <img className="w-100 h-100" alt="email" src={icons.iconsMailSvg} />
        )}
      </button>
    </span>
  );
};

type CopyToClipboardProps = {
  copyContent?: string;
  successToast?: string;
  size?: number;
};
export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  children,
  copyContent,
  successToast,
  size,
}) => {
  const isMounted = useIsMounted();
  const [copied, setCopied] = React.useState(false);

  const onClick = async () => {
    if (!copyContent) return;
    await navigator.clipboard.writeText(copyContent);
    if (successToast) toaster.positive(successToast, {});
    setCopied(true);
    setTimeout(() => isMounted() && setCopied(false), 2000);
  };

  return (
    <span className="flex items-center gap-2">
      {children}
      <InlineSVG
        icon={copied ? 'check' : 'copy'}
        kind={copied ? 'success' : 'grayText'}
        className="pointer"
        tabIndex={0}
        onClick={onClick}
        title="copy"
        width={size}
        height={size}
      />
    </span>
  );
};
