import { Organization, Provider } from '../../../../graphQL';

export type AvailabilityProvider = Pick<Provider, 'id'> & {
  organizations: (Pick<Organization, 'id' | 'name'> & {
    children: Pick<Organization, 'id' | 'name'>[];
  })[];
};

export const allocationTypes = ['default', 'admin', 'intake', 'checkin', 'timeOff'] as const;
export type AllocationType = typeof allocationTypes[number];

export const scheduleTypes = ['recurring', 'single'] as const;
export type ScheduleType = typeof scheduleTypes[number];
