import React, { useState } from 'react';
import { Modal } from '../../../../Components/Modal/Modal';
import { Text } from '../../styles';
import { FinalButton } from '../../../../Components/FinalButton';
import styled from 'styled-components';
import { useMinorConsentApproveUserMutation } from '../../../../graphQL';

type ApproveMinorModalProps = {
  onClose: () => void;
  userId: number;
  refetchMinorConsent: () => void;
};

export const ApproveMinorModal = ({
  onClose,
  userId,
  refetchMinorConsent,
}: ApproveMinorModalProps) => {
  const [markConsentApproved] = useMinorConsentApproveUserMutation({
    variables: {
      userId,
    },
    onCompleted: () => {
      refetchMinorConsent();
      setMinorApprovalError(false);
      onClose();
    },
    onError: () => {
      setMinorApprovalError(true);
    },
  });

  const [minorApprovalError, setMinorApprovalError] = useState<boolean>(false);

  const handleConfirm = () => {
    void markConsentApproved();
  };

  return (
    <Modal size="tiny" isOpen={true} onClose={onClose}>
      <ModalContent className="pv4">
        <TextContainer>
          <Text.h1Bold>Mark consent status as 'Approved'?</Text.h1Bold>

          <Text.bodyBold>
            IMPORTANT: Manual consent forms MUST be uploaded to the patient chart before continuing.
          </Text.bodyBold>

          <Text.body>
            If you continue, an email will be sent to the student prompting them to continue signing
            up for care.
          </Text.body>
        </TextContainer>

        {minorApprovalError && (
          <Text.bodySmall kind="danger">There was an error approving the consent.</Text.bodySmall>
        )}

        <FinalButton kind="outline_black" onClick={handleConfirm}>
          Yes, consent was collected
        </FinalButton>

        <FinalButton kind="minimal_gray" onClick={onClose}>
          Cancel
        </FinalButton>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 10px;
`;
