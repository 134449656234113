import { isNil } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icons';

type SelectControlOverlayProps = {
  karat?: boolean;
  onClickKarat?: () => void;
  onClear?: () => void;
  disabled?: boolean;
  value?: any;
};
export const SelectControlOverlay = ({
  karat,
  disabled,
  value,
  onClear,
  onClickKarat,
}: SelectControlOverlayProps) => {
  return (
    <StyledSelectControlOverlay>
      {onClear && !disabled && !isNil(value) && (
        <div
          className="pointer"
          onClick={onClear}
          role="button"
          tabIndex={-1}
          onKeyDown={e => e.key === 'Enter' && onClear()}
          title="clear"
        >
          <Icon icon="iconsClearInputSvg" size={12} />
        </div>
      )}
      {karat && (
        <span
          onClick={onClickKarat}
          onKeyDown={e => e.key === 'Enter' && onClickKarat?.()}
          role="button"
          tabIndex={-1}
        >
          <svg viewBox="0 1 24 24" width={16} height={16} className={disabled ? 'o-60' : 'pointer'}>
            <path d="M12.7071 15.2929L17.1464 10.8536C17.4614 10.5386 17.2383 10 16.7929 10L7.20711 10C6.76165 10 6.53857 10.5386 6.85355 10.8536L11.2929 15.2929C11.6834 15.6834 12.3166 15.6834 12.7071 15.2929Z" />
          </svg>
        </span>
      )}
    </StyledSelectControlOverlay>
  );
};

const StyledSelectControlOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.3rem;
  margin-right: 1rem;
`;

type InlineControlProps = {
  disabled?: boolean;
  onClear?: () => void;
  value?: any;
  onClickKarat?: () => void;
};

export const InlineControls = ({ onClear, onClickKarat, disabled, value }: InlineControlProps) => {
  return (
    <StyledInlineControls>
      {onClear && !disabled && !isNil(value) && (
        <div
          className="pointer"
          onClick={onClear}
          role="button"
          tabIndex={-1}
          onKeyDown={e => e.key === 'Enter' && onClear()}
          title="clear"
          style={{ width: 12 }}
        >
          <Icon icon="iconsClearInputSvg" size={12} />
        </div>
      )}
      {onClickKarat && (
        <span
          aria-label="show dropdown"
          onClick={onClickKarat}
          onKeyDown={e => e.key === 'Enter' && onClickKarat()}
          role="button"
          tabIndex={-1}
        >
          <svg
            viewBox="0 -2 24 24"
            width={16}
            height={16}
            className={disabled ? 'o-60' : 'pointer'}
          >
            <path d="M12.7071 15.2929L17.1464 10.8536C17.4614 10.5386 17.2383 10 16.7929 10L7.20711 10C6.76165 10 6.53857 10.5386 6.85355 10.8536L11.2929 15.2929C11.6834 15.6834 12.3166 15.6834 12.7071 15.2929Z" />
          </svg>
        </span>
      )}
    </StyledInlineControls>
  );
};

const StyledInlineControls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;
