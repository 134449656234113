import { NestedOption } from '../../Components/Form/Select/types';
import { TicketCategory, TicketPriority } from '../../graphQL';

const priorityMap: Record<TicketPriority, number> = {
  [TicketPriority.High]: 3,
  [TicketPriority.Medium]: 2,
  [TicketPriority.Low]: 1,
};
export const prioritySorter = (a?: TicketPriority | null, b?: TicketPriority | null) => {
  if (!a) return -Number.MAX_SAFE_INTEGER;
  if (!b) return Number.MAX_SAFE_INTEGER;
  if (b && a) return priorityMap[a] - priorityMap[b];
  return 0;
};

export const ticketCategoryCopy: Record<TicketCategory, string> = {
  [TicketCategory.InformationalContinuityOfCare]: 'Continuity of Care',
  [TicketCategory.InformationalGeneral]: 'General',
  [TicketCategory.TransferOfCareTherapy]: 'Therapy',
  [TicketCategory.TransferOfCarePsychiatry]: 'Psychiatry',
  [TicketCategory.TransferOfCareCoaching]: 'Coaching',
  [TicketCategory.SelfPayTherapy]: 'Therapy',
  [TicketCategory.SelfPayPsychiatry]: 'Psychiatry',
  [TicketCategory.SelfPayBilling]: 'Billing',
  [TicketCategory.ReferralInternalPsychiatry]: 'Internal Psychiatry',
  [TicketCategory.ReferralExternalPsychiatry]: 'External Psychiatry',
  [TicketCategory.ReferralInternalTherapy]: 'Internal Therapy',
  [TicketCategory.ReferralExternalTherapy]: 'External Therapy',
  [TicketCategory.ReferralAdhdEvaluation]: 'ADHD Evaluation',
  [TicketCategory.ReferralIop]: 'IOP',
  [TicketCategory.ReferralInternalCoaching]: 'Internal Coaching',
  [TicketCategory.ReferralOther]: 'Other (please specify in details)',
  [TicketCategory.SchedulingStudentAppointment]: 'Student Appointment',
  [TicketCategory.SchedulingProviderScheduleAdjustmentInPolicy]: 'In Policy',
  [TicketCategory.SchedulingProviderScheduleAdjustmentOutOfPolicy]: 'Out of Policy',
  [TicketCategory.SchedulingProviderDefaultScheduleChangeInPolicy]: 'In Policy',
  [TicketCategory.SchedulingProviderDefaultScheduleChangeOutOfPolicy]: 'Out of Policy',
  [TicketCategory.SchedulingProviderGeneralTimeOffInPolicy]: 'In Policy',
  [TicketCategory.SchedulingProviderGeneralTimeOffOutOfPolicy]: 'Out of Policy',
  [TicketCategory.SchedulingProviderEmergencyTimeOff]: 'Provider Emergency Time Off',
  [TicketCategory.OutreachStudentNoShow]: 'Student No Show',
  [TicketCategory.OutreachStudentDisengagement]: 'Student Disengagement',
  [TicketCategory.OutreachGeneral]: 'General',
  [TicketCategory.Labs]: 'Labs / EKGs / Vitals',
  [TicketCategory.CloseCareCancellation]: 'Cancellation',
  [TicketCategory.CloseCareDischarge]:
    'Discharge (requires clinical leadership review before reactivation',
  [TicketCategory.TechSupportStudentAssistance]: 'Student Assistance',
  [TicketCategory.TechSupportAudioVideoIssue]: 'Video/Audio Issue',
  [TicketCategory.TechSupportEmailPasswordReset]: 'Email Password Reset',
  [TicketCategory.TechSupportOzPasswordReset]: 'Oz Password Reset',
  [TicketCategory.TechSupportSlackPasswordReset]: 'Slack Password Reset',
  [TicketCategory.TechSupportDocumentationIssue]: 'Documentation Issue',
  [TicketCategory.TechSupportGeneral]: 'General',
  [TicketCategory.RxRefill]: 'Rx Refill',
  [TicketCategory.RxSideEffect]: 'Rx Side Effect',
  [TicketCategory.RxFax]: 'Rx Fax',
  [TicketCategory.RxQuestion]: 'Rx Question',
  [TicketCategory.RxPriorAuth]: 'Prior Auth',
  [TicketCategory.ReleaseOfInformationStudent]: 'Student',
  [TicketCategory.ReleaseOfInformationThirdParty]: 'Third Party',
  [TicketCategory.ReleaseOfInformationAccommodationLetter]: 'Accommodation Letter',
  [TicketCategory.ReleaseOfInformationSummaryOfTreatmentLetter]: 'Summary of Treatment Letter',
  [TicketCategory.Other]: 'Other (specify in task details)',
  [TicketCategory.ProviderScheduleAdjustment]: 'Provider Schedule Adjustment',
  [TicketCategory.ProviderDefaultScheduleChange]: 'Provider Default Schedule Change',
  [TicketCategory.ProviderGeneralTimeOff]: 'Provider General Time Off',
  [TicketCategory.ReferralPsychiatry]: 'Psychiatry',
  [TicketCategory.ReferralTherapy]: 'Therapy',
  [TicketCategory.Scheduling]: 'Scheduling',
  [TicketCategory.Outreach]: 'Outreach',
  [TicketCategory.PriorAuth]: 'Prior Auth',
  [TicketCategory.OnHold]: 'On Hold / Terminate',
  [TicketCategory.TechSupport]: 'Tech Support',
  [TicketCategory.Insurance]: 'Insurance',
  [TicketCategory.Coc]: 'External Referral: Case Summary',
  [TicketCategory.DatasharingCoc]: 'External Referral: Case Summary (CoC)',
  [TicketCategory.DatasharingRoi]: 'Consent for external contact (ROI)',
};

export const ticketCategoryOptions: NestedOption[] = [
  {
    id: 'informational',
    label: 'Informational',
    subcategories: [
      {
        id: TicketCategory.InformationalContinuityOfCare,
      },
      {
        id: TicketCategory.InformationalGeneral,
      },
    ],
  },
  {
    id: 'transferOfCare',
    label: 'Transfer of Care',
    subcategories: [
      {
        id: TicketCategory.TransferOfCareTherapy,
      },
      {
        id: TicketCategory.TransferOfCarePsychiatry,
      },
      {
        id: TicketCategory.TransferOfCareCoaching,
      },
    ],
  },
  {
    id: 'selfpay',
    label: 'Self-Pay',
    subcategories: [
      {
        id: TicketCategory.SelfPayTherapy,
      },
      {
        id: TicketCategory.SelfPayPsychiatry,
      },
      {
        id: TicketCategory.SelfPayBilling,
      },
    ],
  },
  {
    id: 'referrals',
    label: 'Referral',
    subcategories: [
      {
        id: TicketCategory.ReferralInternalPsychiatry,
      },
      {
        id: TicketCategory.ReferralExternalPsychiatry,
      },
      {
        id: TicketCategory.ReferralInternalTherapy,
      },
      {
        id: TicketCategory.ReferralExternalTherapy,
      },
      {
        id: TicketCategory.ReferralAdhdEvaluation,
      },
      {
        id: TicketCategory.ReferralIop,
      },
      {
        id: TicketCategory.ReferralInternalCoaching,
      },
      {
        id: TicketCategory.ReferralOther,
      },
    ],
  },
  {
    id: TicketCategory.Scheduling,
    label: 'Scheduling',
    subcategories: [
      {
        id: TicketCategory.SchedulingStudentAppointment,
      },
      {
        id: TicketCategory.ProviderScheduleAdjustment,
        label: 'Provider Schedule Adjustment',
        subcategories: [
          {
            id: TicketCategory.SchedulingProviderScheduleAdjustmentInPolicy,
          },
          {
            id: TicketCategory.SchedulingProviderScheduleAdjustmentOutOfPolicy,
          },
        ],
      },
      {
        id: TicketCategory.ProviderDefaultScheduleChange,
        label: 'Provider Default Schedule Change',
        subcategories: [
          {
            id: TicketCategory.SchedulingProviderDefaultScheduleChangeInPolicy,
          },
          {
            id: TicketCategory.SchedulingProviderDefaultScheduleChangeOutOfPolicy,
          },
        ],
      },
      {
        id: TicketCategory.ProviderGeneralTimeOff,
        label: 'Provider General Time Off',
        subcategories: [
          {
            id: TicketCategory.SchedulingProviderGeneralTimeOffInPolicy,
          },
          {
            id: TicketCategory.SchedulingProviderGeneralTimeOffOutOfPolicy,
          },
        ],
      },
      {
        id: TicketCategory.SchedulingProviderEmergencyTimeOff,
      },
    ],
  },
  {
    id: 'outreach',
    label: 'Outreach',
    subcategories: [
      {
        id: TicketCategory.OutreachStudentNoShow,
      },
      {
        id: TicketCategory.OutreachStudentDisengagement,
      },
      {
        id: TicketCategory.OutreachGeneral,
      },
    ],
  },
  {
    id: TicketCategory.Labs,
  },
  {
    id: 'closeCare',
    label: 'Close Care',
    subcategories: [
      {
        id: TicketCategory.CloseCareCancellation,
      },
      {
        id: TicketCategory.CloseCareDischarge,
      },
    ],
  },
  {
    id: 'techSupport',
    label: 'Tech Support',
    subcategories: [
      {
        id: TicketCategory.TechSupportStudentAssistance,
      },
      {
        id: TicketCategory.TechSupportAudioVideoIssue,
      },
      {
        id: TicketCategory.TechSupportEmailPasswordReset,
      },
      {
        id: TicketCategory.TechSupportOzPasswordReset,
      },
      {
        id: TicketCategory.TechSupportSlackPasswordReset,
      },
      {
        id: TicketCategory.TechSupportDocumentationIssue,
      },
      {
        id: TicketCategory.TechSupportGeneral,
      },
    ],
  },
  {
    id: 'rx',
    label: 'Rx',
    subcategories: [
      {
        id: TicketCategory.RxRefill,
      },
      {
        id: TicketCategory.RxSideEffect,
      },
      {
        id: TicketCategory.RxFax,
      },
      {
        id: TicketCategory.RxQuestion,
      },
      {
        id: TicketCategory.RxPriorAuth,
      },
    ],
  },
  {
    id: 'releaseOfInformation',
    label: 'Release of Information',
    subcategories: [
      {
        id: TicketCategory.ReleaseOfInformationStudent,
      },
      {
        id: TicketCategory.ReleaseOfInformationThirdParty,
      },
      {
        id: TicketCategory.ReleaseOfInformationAccommodationLetter,
      },
      {
        id: TicketCategory.ReleaseOfInformationSummaryOfTreatmentLetter,
      },
    ],
  },
  {
    id: TicketCategory.Other,
  },
].map(({ id, label, subcategories }) => ({
  id,
  label: label ?? ticketCategoryCopy[id as TicketCategory],
  subcategories:
    subcategories && subcategories.map(s => ({ id: s.id, label: ticketCategoryCopy[s.id] })),
}));
