import { ChevronRight } from 'baseui/icon';
import { StyledSpinnerNext } from 'baseui/spinner';
import { compact, lowerCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../globalStyles';
import {
  UserNotificationFragment,
  UserNotificationKey,
  useUserNotificationsQuery,
} from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';
import { Tabkeys } from '../types';

function timestamp(createdAt: Date) {
  return moment(createdAt).fromNow();
}

type NotificationContent = {
  title: string;
  body?: string;
  goTo?: Tabkeys;
};

// prettier-ignore
const notificationContent: Partial<Record<
  UserNotificationKey,
  (n: UserNotificationFragment) => NotificationContent
>> = {
  prescriptionSubmitted: n => ({
    title: `${n.data.providerName} has submitted a prescription.`,
    body: `For updates, contact your pharmacy at ${n.data.pharmacyPhone}.`,
  }),
  unreadMessages: n => ({
    title: `${n.data.count} unread message from ${n.data.providerName}`,
    goTo: 'provider-messages',
  }),
  scheduleAppointmentPsychiatry: n => ({
    title: `Schedule your next medication management appointment`,
    goTo: 'appointments',
  }),
  scheduleAppointmentTherapy: n => ({
    title: `Schedule your next therapy appointment`,
    goTo: 'appointments',
  }),
  monthlyScales: _ => ({
    title: `Complete your monthly assessment`,
    body: 'Before your next visit, let’s check in with how you’re feeling.',
    goTo: 'assessments',
  }),
  completeSafetyPlan: _ => ({
    title: 'Complete your safety plan',
    body: 'A member of your collaboration team has requested that you fill out a safety plan.',
    goTo: 'safety-plan',
  }),
  pendingCareTransition: n => {
    const title = [String(n.data.careType)];
    if (n.data.careEndDate) {
      title.push(`Care ends ${moment(n.data.careEndDate).format('MMMM D')}`);
    }
    const remaining = n.data.sessionLimits?.remainingAppts;
    if (remaining) {
      title.push(`${remaining} sponsored visit${remaining !== 1 ? 's' : ''} remaining`);
    }
    return {
      title: title.join('\n'),
    };
  },
  invalidEmergencyContact: n => {
    const sIfPlural = n.data.names.length === 1 ? '' : 's';
    const subjectIfPlural = n.data.names.length === 1 ? 'it is' : 'they are';
    return {
      title: `The phone number${sIfPlural} for your emergency contact${sIfPlural} could not be verified, please double check that ${subjectIfPlural} properly entered.`,
    };
  },
  dataSharingRequest: _ => ({
    title: 'Share health record with campus collaborator',
    body:
      'A member of your campus collaboration team has requested temporary access to your health record, but it’s up to you if you’d like to share it.',
  }),
  providerFeedback: n => ({
    title: `Complete the ${lowerCase(n.data.careType)} provider feedback survey`,
  }),
  invalidPaymentMethod: _ => ({
    title: 'Update your payment method',
    body:
      'We were unable to process your payment method. Please update the card on file to continue care.',
  }),
  bipolarScreening: _ => ({
    title: 'Complete assigned questionnaire',
    body: 'Your provider sent you a questionnaire for you to complete.',
    goTo: 'assessments',
  }),
};

export const UserNotifications = () => {
  const { user, setActiveTab } = useDrilldownContext();
  const { data, loading } = useUserNotificationsQuery({ variables: { userId: user.id } });
  const notifications = data?.adminUserNotifications ?? [];
  return (
    <NotificationsContainer>
      <Text.h3 className="mb4">User Notifications</Text.h3>
      {loading ? (
        <div className="flex w-100 justify-center">
          <StyledSpinnerNext />
        </div>
      ) : (
        notifications.map((notification, idx) => {
          const fn = notificationContent[notification.key];
          if (!fn) return null;
          const { title, body, goTo } = fn(notification);
          return (
            <div className="ph3 pb3" key={`${idx}-${notification.id}-${notification.key}`}>
              <button
                type="button"
                className={compact([
                  'tl bg-inherit bw0 w-100 flex flex-row items-center justify-between',
                  goTo && 'pointer',
                ]).join(' ')}
                onClick={goTo ? () => setActiveTab(goTo) : undefined}
              >
                <div>
                  <Text.bodyBold>{title}</Text.bodyBold>
                  <Text.bodySmallGrey className="mb2 i di">
                    {timestamp(notification.createdAt)}
                    {notification.isRequired ? ' - ' : ''}
                  </Text.bodySmallGrey>
                  {notification.isRequired && (
                    <Text.bodySmall kind="brandAlt" className="di">
                      Required
                    </Text.bodySmall>
                  )}
                  {body && <Text.bodySmall className="mt2">{body}</Text.bodySmall>}
                </div>
                {goTo && <ChevronRight size={24} />}
              </button>
            </div>
          );
        })
      )}
    </NotificationsContainer>
  );
};

const NotificationsContainer = styled(Styles.widget as any)`
  > div:not(:last-child) {
    border-bottom: 1px solid ${colors.grey.border};
    margin-bottom: 1rem;
  }
`;
