import { Avatar } from 'baseui/avatar';
import { keyBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../../globalStyles';
import { MessageFragment, ProviderFragment } from '../../../graphQL';
import { icons } from '../../Icons';
import { useCurrentProvider } from '../../Permissions';
import { MessageContainer } from '../Styles';

type ViewProps = {
  messages: MessageFragment[];
  providers: ProviderFragment[];
};
export const CareTeamMessageView = ({ messages, providers }: ViewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const providerLookup = keyBy(providers, 'id');

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [ref, messages.length]);

  return (
    <Container ref={ref}>
      {messages.length !== 0 && (
        <div className="w-100 flex justify-center mb3">
          <Text.bodySmallGrey>
            {moment(messages[0].createdAt).format('M/D/YYYY')}
          </Text.bodySmallGrey>
        </div>
      )}
      {messages.map((m, i) => (
        <MessageCard
          message={m}
          key={i}
          provider={m.providerId ? providerLookup[m.providerId] : undefined}
        />
      ))}
    </Container>
  );
};

type MessageCardProps = {
  message: MessageFragment;
  provider?: ProviderFragment;
};
const MessageCard = React.memo(
  ({ message, provider }: MessageCardProps) => {
    const { currentProvider } = useCurrentProvider();
    const { content, createdAt } = message;

    const incoming = !provider || provider.id !== currentProvider.id;
    const justify = incoming ? 'justify-start' : 'justify-end';
    const color = incoming ? 'white' : colors.accent;

    return (
      <div className={`flex ${justify}`}>
        <MessageCardStyle color={color}>
          {provider && (
            <div className="flex justify-start">
              <Avatar
                name={provider.name}
                size="32px"
                src={provider.portrait?.url || icons.assetsDefaultPicturePng}
              />
              <div className="pl2">
                <Text.bodySmall className="mt0">
                  <b>{provider.name}</b>, {provider.classification}
                </Text.bodySmall>
                <Text.bodySmall style={{ opacity: 0.5 }}>
                  {moment(createdAt).format('M/D/YYYY hh:mm az')}
                </Text.bodySmall>
              </div>
            </div>
          )}
          <MessageContainer className="mt3">{content}</MessageContainer>
        </MessageCardStyle>
      </div>
    );
  },
  (a, b) => a.message.id === b.message.id
);

const MessageCardStyle = styled.div`
  background-color: ${({ color }) => color || 'white'};
  margin: 0.25rem 0rem;
  padding: 1rem;
  width: 80%;
  border-radius: 4px;
  border: 1px solid ${colors.grey.border};
`;

const Container = styled.div`
  height: max(calc(100vh - 520px), 50vh);
  overflow-y: scroll;
  padding: 1rem 1rem 2rem;
  border-bottom: 1px solid ${colors.grey.light};
`;
