import { Checkbox } from 'baseui/checkbox';
import { pick } from 'lodash';
import React, { useState } from 'react';
import { ComponentArgs, EzMultirow } from '../../Components/EzForm';
import { FinalButton } from '../../Components/FinalButton';
import { MultiSelect } from '../../Components/Form';
import { Input } from '../../Components/Form/Input';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { Text } from '../../globalStyles';
import {
  CareType,
  OrganizationEligibilityCriteriaInput,
  useOrganizationEligibilityQuery,
  useUpdateEligibilityCriteriaMutation,
} from '../../graphQL';
import { stateOptions } from '../../states';
import { UnexpectedError } from '../Shared';
import { useOrganizationId } from './util';
import styled from 'styled-components';

export function OrganizationEligibility() {
  const organizationId = useOrganizationId();
  const [criteria, setCriteria] = useState<OrganizationEligibilityCriteriaInput[]>([]);
  const query = useOrganizationEligibilityQuery({
    variables: { organizationId },
    onCompleted: data =>
      setCriteria(
        data.organization.eligibilityCriteria.map(crit =>
          pick(crit, 'minimumAge', 'states', 'permittedCareTypes')
        )
      ),
  });
  const [mutate, mutation] = useUpdateEligibilityCriteriaMutation({
    onCompleted: () => query.refetch(),
  });

  const onSave = () => {
    mutate({ variables: { organizationId, criteria } });
  };

  if (query.loading) return <LoadingPage />;
  if (query.error || !query.data) return <UnexpectedError />;

  return (
    <div>
      <h2 className="mb4">Eligibility</h2>

      <Text.body>
        Set the minimum student age allowed for MCP referrals and self-referrals to the Hub (if
        allowed).
      </Text.body>

      <EligibilityDetails>
        <li>
          <b>If not configured, minimum referral age is 18 years old.</b>
        </li>

        <li>
          If configured, state-specific age restrictions will only apply to clinical care referrals.{' '}
          <b>Hub signups cannot be restricted by state.</b>
        </li>

        <li>
          If multiple minimum ages are added, <b>the lowest minimum age configured below</b> will be
          the minimum age requirement for Hub signups.
        </li>
      </EligibilityDetails>

      <Text.body className="mt4">
        <b>Note</b>: Please ensure the{' '}
        <b>Therapy referral minimum age is never older than the Hub signup age.</b> Otherwise,
        ineligible minors will be able to access Therapy through Hub LOCA.
      </Text.body>

      <EzMultirow
        values={criteria}
        blank={{ minimumAge: null, permittedCareTypes: null, states: null }}
        setValues={setCriteria}
        component={Criteria}
      />

      <div className="mt3 flex items-center">
        <FinalButton onClick={onSave} loading={mutation.loading}>
          Save
        </FinalButton>

        {mutation.error && <Text.body className="ml3">An error occurred.</Text.body>}
        {mutation.data?.updateEligibilityCriteria && <Text.body className="ml3">Saved.</Text.body>}
      </div>
    </div>
  );
}

function Criteria({ value, onChange }: ComponentArgs<OrganizationEligibilityCriteriaInput>) {
  return (
    <CriteriaContainer>
      <CriteriaItem>
        <Text.body className="mr2">Minimum age:</Text.body>

        <Input
          className="flex-1"
          value={value.minimumAge?.toString() ?? '0'}
          onChange={e => onChange({ ...value, minimumAge: Number(e.currentTarget.value) })}
        />
      </CriteriaItem>

      <RestrictionsContainer>
        <Text.bodyBold className="mt3 mb4">MCP Referral Restrictions</Text.bodyBold>

        <div className="flex items-center mb3">
          <Checkbox
            checked={!!value.permittedCareTypes}
            onChange={e =>
              onChange({ ...value, permittedCareTypes: e.currentTarget.checked ? [] : null })
            }
          />

          <Text.bodyBold>Apply to ONLY specific care types?</Text.bodyBold>
        </div>
        {!!value.permittedCareTypes && (
          <div className="ml4 mb2">
            <Text.bodySmallBold>
              Min. age restriction should apply to referrals for the selected care type:
            </Text.bodySmallBold>
            <MultiSelect
              options={[
                { id: CareType.Psychiatry, label: 'Psychiatry' },
                { id: CareType.Therapy, label: 'Therapy' },
              ]}
              value={value.permittedCareTypes}
              onChange={careTypes =>
                onChange({ ...value, permittedCareTypes: careTypes as CareType[] })
              }
            />
          </div>
        )}

        <div className="flex items-center mb3 mt4">
          <Checkbox
            checked={!!value.states}
            onChange={e => onChange({ ...value, states: e.currentTarget.checked ? [] : null })}
          />

          <Text.bodyBold>Apply to ONLY specific states?</Text.bodyBold>
        </div>
        {!!value.states && (
          <div className="ml4 mb3">
            <Text.bodySmallBold>
              Min. age restriction should apply to referrals of students from ONLY the following
              states:
            </Text.bodySmallBold>
            <MultiSelect
              options={stateOptions}
              value={value.states}
              onChange={states => onChange({ ...value, states: states as string[] })}
            />
          </div>
        )}
      </RestrictionsContainer>

      <hr className="mt4 mb4" />
    </CriteriaContainer>
  );
}

const EligibilityDetails = styled.ul`
  margin-left: 1rem;
`;

const CriteriaContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  max-width: 800px;
`;

const CriteriaItem = styled.div`
  display: flex;
  align-items: center;
  max-width: 200px;
`;

const RestrictionsContainer = styled.div`
  margin-left: 1rem;
  margin-top: 1rem;
`;
