import { Modal } from 'baseui/modal';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { FinalButton } from '../../Components/FinalButton';
import { CheckboxRHF, MultiSelectRHF, PhoneInputRHF } from '../../Components/Form';
import { Notification } from '../../Components/Notification';
import { useCurrentProvider } from '../../Components/Permissions';
import { colors, Modal as ModalStyle, Text } from '../../globalStyles';
import {
  Organization,
  Permission,
  ProviderProfileQuery,
  useAddProviderToCrisisTeamsMutation,
  useRemoveProviderFromCrisisTeamsMutation,
} from '../../graphQL';
import { stripPhoneNumber } from '../../utils';
import { Headline } from './Common';

const required = 'required';

type CrisisTeamProps = {
  provider: ProviderProfileQuery['adminProvider'];
  refetch: () => Promise<any>;
};

export const McpCrisisTeam = ({ provider, refetch }: CrisisTeamProps) => {
  const { hasPermission } = useCurrentProvider();
  const [showingModal, setShowingModal] = useState(false);

  const providerCrisisAlertsEnabled = !!provider.organizationCrisisTeams.length;

  return (
    <div>
      <Headline
        onEdit={() => setShowingModal(true)}
        canEdit={hasPermission(Permission.CrisisTeamEdit)}
      >
        Crisis Alerts
      </Headline>
      <Text.body>
        {providerCrisisAlertsEnabled
          ? 'Signed up for crisis alerts'
          : 'Not signed up for crisis alerts'}
      </Text.body>
      <Modal
        isOpen={showingModal}
        onClose={() => setShowingModal(false)}
        unstable_ModalBackdropScroll
        autoFocus={false}
      >
        <ModalStyle.body>
          <EditMcpCrisisTeamForm
            provider={provider}
            onSubmit={async () => {
              await refetch();
              setShowingModal(false);
            }}
          />
        </ModalStyle.body>
      </Modal>
    </div>
  );
};

type CrisisTeamEditForm = {
  consented?: boolean;
  phone?: string;
  organizationIds?: number[];
};

type EditCrisisTeamFormProps = {
  provider: ProviderProfileQuery['adminProvider'];
  onSubmit: () => Promise<void>;
};

const EditMcpCrisisTeamForm = ({ provider, onSubmit }: EditCrisisTeamFormProps) => {
  const [loading, setLoading] = useState(false);
  const [removeFromCrisisTeams] = useRemoveProviderFromCrisisTeamsMutation();
  const [addToCrisisTeams, { error }] = useAddProviderToCrisisTeamsMutation();

  const { organizations, organizationCrisisTeams } = provider;
  const { crisisPhone } = organizationCrisisTeams[0] ?? {};
  const providerCrisisAlertsEnabled = !!organizationCrisisTeams.length;

  const form = useForm<CrisisTeamEditForm>({
    defaultValues: {
      phone: crisisPhone,
      consented: providerCrisisAlertsEnabled,
      organizationIds: organizationCrisisTeams.map(r => r.organization.id),
    },
  });

  // selectable orgs are campuses
  const selectableOrgs = organizations.reduce(
    (acc, org) =>
      org.children.length > 0
        ? [...acc, ...org.children.map(c => ({ ...c, isChildOrg: true }))]
        : [...acc, org],
    [] as (Pick<Organization, 'id' | 'name'> & { isChildOrg?: boolean })[]
  );

  const belongsToSuperOrg = selectableOrgs.some(o => o.isChildOrg);
  /*
   * in order to explicitly show that the super org user is not being
   * added to the super org crisis team (which is not yet supported),
   * require multi-select if they belong to a super org. Even if there's
   * only one selectable org.
   */
  const requiresMultiSelect = selectableOrgs.length !== 1 || belongsToSuperOrg;

  const onSave = form.handleSubmit(async args => {
    const organizationIds = requiresMultiSelect
      ? args.organizationIds ?? []
      : [selectableOrgs[0].id];
    try {
      setLoading(true);
      if (consented) {
        await addToCrisisTeams({
          variables: {
            organizationIds,
            phone: stripPhoneNumber(args.phone ?? crisisPhone),
            providerId: provider.id,
          },
        });
      } else {
        await removeFromCrisisTeams({ variables: { providerId: provider.id } });
      }
      await onSubmit();
    } finally {
      setLoading(false);
    }
  });

  const consented = form.watch('consented');

  return (
    <FormProvider {...form}>
      <form onSubmit={onSave}>
        <Text.h3 className="mb3">Crisis Alerts</Text.h3>
        {error && <Notification kind="negative">{error.message}</Notification>}
        <CrisisConsentContainer>
          <CheckboxRHF name="consented" kind="black" className="mt2" />
          <div>
            <Text.bodyBold>Add this provider to a crisis team</Text.bodyBold>
            <Text.body>
              By checking this box, this provider consents to sharing their mobile phone number and:
            </Text.body>
            <ul>
              <li>receiving SMS alerts to crisis situations</li>
              <li>receiving group email alerts about crisis situations</li>
            </ul>
          </div>
        </CrisisConsentContainer>
        {requiresMultiSelect && (
          <MultiSelectRHF
            name="organizationIds"
            options={selectableOrgs.map(o => ({ id: o.id, label: o.name }))}
            controlProps={{ required: consented, label: 'Selected campus(es)' }}
          />
        )}
        <PhoneInputRHF
          name="phone"
          className="mb2"
          rules={{ required: consented ? required : undefined }}
          controlProps={{
            label: (
              <>
                <Text.bodyBold className="di">
                  Personal Phone Number{' '}
                  {consented && <span style={{ color: colors.danger }}>*</span>}
                </Text.bodyBold>
                <Text.caption kind="grayText" className="mb2">
                  Note: this number will only be contacted in the event of crisis situations. This
                  device should be able to receive SMS notifications and be readily accessible
                  during crisis situations.
                </Text.caption>
              </>
            ),
          }}
        />
        <FinalButton onClick={onSave} className="w-100" loading={loading}>
          Save
        </FinalButton>
      </form>
    </FormProvider>
  );
};

const CrisisConsentContainer = styled.div`
  display: flex;
  padding: 1em;
  background-color: #efefef;
  & > div > ul {
    margin: 0.5em 0;
    padding: 0 0 0 1.5em;
  }
  border-radius: 8px;
  margin-bottom: 1.5em;
`;
