import { ApolloError } from '@apollo/client';
import { useCurrentProvider } from '../Components/Permissions';
import { CareType, useOrganizationForStateAndCareTypeValidationQuery } from '../graphQL';
import { states } from '../states';
import { englishList } from '../utils';
import { useMemo } from 'react';

type ValidateStateArgs = {
  state: string;
  selectedCareTypes: CareType[];
  organizationCareFlows: Array<{
    careType: CareType;
    referralRestrictedStates: string[];
    statesAvailable: string[];
  }>;
  checkProviders: boolean;
};

type EmptyCareTypes = {
  emptyCareTypes?: true;
};
type Passed = {
  passed?: true;
};
type OrganizationFailed = {
  organizationFailed?: {
    careTypes: string;
    stateName: string;
    isAnyTypeAvailable: boolean;
  };
};
type ProviderFailed = {
  providerFailed?: {
    careTypes: string;
    stateName: string;
    isAnyTypeAvailable: boolean;
  };
};

export type ValidationResponse = EmptyCareTypes & Passed & OrganizationFailed & ProviderFailed;

/**
 * Validates the given state against both the organization restricted states
 * and the provider available states for the currently selected care types.
 */
const validateStateIsAllowedForCareType = ({
  state,
  selectedCareTypes,
  organizationCareFlows,
  checkProviders = false,
}: ValidateStateArgs): ValidationResponse => {
  if (!selectedCareTypes.length) {
    return {
      emptyCareTypes: true,
    };
  }

  const orgBadCareTypes: string[] = [];
  const orgGoodCareTypes: string[] = [];
  organizationCareFlows.forEach(({ careType, referralRestrictedStates }) => {
    if (!referralRestrictedStates.length || referralRestrictedStates.includes(state)) {
      // Store a list of all the care types that are available for the state at the org level.
      orgGoodCareTypes.push(careType.toLowerCase());
      return;
    }

    if (selectedCareTypes.includes(careType)) {
      // Store a list of all the selected care types that fail for the state at the org level.
      orgBadCareTypes.push(careType.toLowerCase());
    }
  });

  const providerBadCareTypes: string[] = [];
  const providerGoodCareTypes: string[] = [];

  // Don't validate provider availability for DGM organizations.
  if (checkProviders) {
    organizationCareFlows.forEach(({ careType, statesAvailable }) => {
      if (statesAvailable.includes(state)) {
        // Store a list of all the care types that are available for the state at the provider level.
        providerGoodCareTypes.push(careType.toLowerCase());
        return;
      }

      if (selectedCareTypes.includes(careType)) {
        // Store a list of all the selected care types that fail for the state at the provider level.
        providerBadCareTypes.push(careType.toLowerCase());
      }
    }, [] as string[]);
  }

  if (!orgBadCareTypes.length && !providerBadCareTypes.length) {
    return {
      passed: true,
    };
  }

  const stateName = states[state];

  if (orgBadCareTypes.length) {
    const careTypes = englishList(orgBadCareTypes, 'or');
    const isAnyTypeAvailable = orgGoodCareTypes.length > 0;

    return {
      organizationFailed: {
        careTypes,
        stateName,
        isAnyTypeAvailable,
      },
    };
  }

  if (!providerBadCareTypes.length) {
    return {
      passed: true,
    };
  }

  const careTypes = englishList(providerBadCareTypes, 'or');
  const isAnyTypeAvailable = providerGoodCareTypes.length > 0;

  return {
    providerFailed: {
      careTypes,
      stateName,
      isAnyTypeAvailable,
    },
  };
};

export type ValidateCareTypesAndGeoState = (
  careTypes: CareType[]
) => (state: string) => ValidationResponse;

export type ValidateGeoStateAndCareTypes = (
  state: string
) => (careTypes: CareType[]) => ValidationResponse;

type GeoStateAvailability = {
  loading: boolean;
  loadError?: ApolloError;
  validateCareTypesState?: ValidateCareTypesAndGeoState;
  validateStateCareTypes?: ValidateGeoStateAndCareTypes;
};

export const useGeoStateAvailability = (
  organizationId: number,
  { checkProviders = false } = {}
): GeoStateAvailability => {
  const { providerOrgUsesDedicatedGroupModel, providerOrgUsesSessionsModel } = useCurrentProvider();

  const shouldValidateProviders = useMemo(() => {
    return (
      checkProviders && !providerOrgUsesDedicatedGroupModel() && !providerOrgUsesSessionsModel()
    );
  }, [checkProviders, providerOrgUsesDedicatedGroupModel, providerOrgUsesSessionsModel]);

  const {
    data: orgData,
    loading,
    error: loadError,
  } = useOrganizationForStateAndCareTypeValidationQuery({
    variables: {
      id: organizationId,
    },
  });

  if (loading || !orgData?.organization) {
    return {
      loading: true,
    };
  }

  if (loadError) {
    return {
      loading: false,
      loadError,
    };
  }

  const validateCareTypesState = (careTypes: CareType[]) => {
    return (state: string) => {
      return validateStateIsAllowedForCareType({
        state,
        organizationCareFlows: orgData.organization.careFlows,
        selectedCareTypes: careTypes,
        checkProviders: shouldValidateProviders,
      });
    };
  };

  const validateStateCareTypes = (state: string) => {
    return (careTypes: CareType[]) => {
      return validateStateIsAllowedForCareType({
        state,
        organizationCareFlows: orgData.organization.careFlows,
        selectedCareTypes: careTypes,
        checkProviders: shouldValidateProviders,
      });
    };
  };

  return {
    loading: false,
    validateCareTypesState,
    validateStateCareTypes,
  };
};
