import React from 'react';
import { Link as BaseLink, LinkProps, useLocation } from 'react-router-dom';
import { simplifyPathname, useEvents } from './Events/EventsProvider';

export type MantraLinkProps = LinkProps & {
  analyticsName?: string;
};

export const Link: React.FC<MantraLinkProps> = ({
  children,
  onClick,
  analyticsName,
  to,
  ...props
}) => {
  const metrics = useEvents();
  const { pathname } = useLocation();
  return (
    <BaseLink
      tabIndex={0}
      onClick={e => {
        metrics.track(`link.clicked`, {
          analyticsName,
          from: pathname,
          to: to.toString(),
          fromSimple: simplifyPathname(pathname),
          toSimple: simplifyPathname(to.toString()),
        });
        onClick?.(e);
      }}
      to={to}
      {...props}
    >
      {children}
    </BaseLink>
  );
};
