import { chain } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useProviderNetworkContext } from '../Components/ProviderNetwork/ProviderNetworkContext';

export const useMoveToFirstProviderNetworkAvailability = () => {
  const { startDate, jumpTo, dedicatedGroupModelActive, sessionModelActive, providers } =
    useProviderNetworkContext();

  // For DGM or Sessions, automatically move to the first available date
  let firstOverallDgmOrSessionsAvailability = '';
  if (dedicatedGroupModelActive || sessionModelActive) {
    firstOverallDgmOrSessionsAvailability = chain(providers)
      .map(p => p.upcomingAvailabilityV4.availability[0].start)
      .min()
      .value();
  }
  const [movedToFirstAvailability, setMovedToFirstAvailability] = useState(false);
  useEffect(() => {
    if (
      !movedToFirstAvailability &&
      (dedicatedGroupModelActive || sessionModelActive) &&
      firstOverallDgmOrSessionsAvailability &&
      moment(firstOverallDgmOrSessionsAvailability).isAfter(startDate)
    ) {
      setMovedToFirstAvailability(true);
      jumpTo(moment(firstOverallDgmOrSessionsAvailability).startOf('day'));
    }
  }, [
    firstOverallDgmOrSessionsAvailability,
    dedicatedGroupModelActive,
    sessionModelActive,
    startDate,
    movedToFirstAvailability,
  ]);
};
