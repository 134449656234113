import React, { useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import moment from 'moment';
import {
  CareType,
  OrganizationUtilizationWeek,
  useUpdateOrganizationDedicatedGroupModelAllocationsMutation,
  UtilizationHours,
} from '../../graphQL';
import { FinalButton } from '../../Components/FinalButton';
import { UtilizationRow, UtilizationTable } from '../../Components/UtilizationTable';
import { thisWeekColumnCopy, pastColumnCopy, upcomingColumnCopy } from './UtilizationCopy';
import { useOrganizationId } from '../Organizations/util';

type UtilizationDashboardProps = {
  careType: CareType;
  editable?: boolean;
  utilizationRecords: OrganizationUtilizationWeek[];
  refetch: () => void;
  setCarePeriodAllocationsComplete: Function;
};

type OrganizationDedicatedGroupModelAllocationInput = {
  id: number;
  psychiatryMaxHours?: number;
  therapyMaxHours?: number;
  psychiatryMaxIntakeHours?: number;
  therapyMaxIntakeHours?: number;
};

type AllocationKey = keyof OrganizationDedicatedGroupModelAllocationInput;

type UpdateMap = {
  [key: number]: OrganizationDedicatedGroupModelAllocationInput;
};

export const UtilizationDashboard = ({
  careType,
  editable,
  utilizationRecords,
  refetch,
  setCarePeriodAllocationsComplete,
}: UtilizationDashboardProps) => {
  const [isEditable, setIsEditable] = useState(false);
  const [update] = useUpdateOrganizationDedicatedGroupModelAllocationsMutation();
  const [updatedAllocations, setUpdatedAllocations] = useState<UpdateMap>({});
  const [errorCount, setErrorCount] = useState(0);
  const organizationId = useOrganizationId();

  const [completedRows, currentRows, upcomingRows] = useMemo(() => {
    return prepUtilizationRows(utilizationRecords, careType);
  }, [utilizationRecords]);

  const getValueComparator = (
    hourCategoryKey: AllocationKey,
    isIntake: boolean,
    allocationId: number
  ): number | undefined => {
    // Define keys for checking for comparison values in state and db records
    const oppositeStateKey = isIntake
      ? hourCategoryKey.replace('Intake', '')
      : hourCategoryKey.replace('Max', 'MaxIntake');
    const oppositeRecordKey = isIntake ? 'maxHours' : 'maxIntakeHours';

    // First, check if value exists in current state
    if (
      updatedAllocations[allocationId] &&
      Object.keys(updatedAllocations[allocationId]).includes(oppositeStateKey)
    ) {
      return updatedAllocations[allocationId][oppositeStateKey as AllocationKey] as number;
    }

    // if no value in the current form state to compare to, check if the DB record has a value to compare to
    const possibleRecord = utilizationRecords.find(ur => {
      return ur.id === allocationId;
    });
    if (possibleRecord) {
      const relatedHours = possibleRecord[
        `${careType.toLowerCase()}Hours` as keyof OrganizationUtilizationWeek
      ] as UtilizationHours;
      // set Comparator to DB record's maxHours or null
      return Object.keys(relatedHours).includes(oppositeRecordKey)
        ? (relatedHours[oppositeRecordKey] as number)
        : undefined;
    }
    return undefined;
  };

  const handleChange = (
    newValue: string,
    isIntake: boolean,
    allocationId: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const errorElement = e.target.nextSibling;
    if (!errorElement) {
      return;
    }

    // Prep record access key based on careType and intake status
    const hourCategoryKey = `${careType.toLowerCase()}Max${
      isIntake ? 'Intake' : ''
    }Hours` as AllocationKey;

    // Then update state with new value
    const updatedValue: UpdateMap = {};
    updatedValue[allocationId] = updatedAllocations[allocationId]
      ? updatedAllocations[allocationId]
      : { id: allocationId };
    updatedValue[allocationId][hourCategoryKey as AllocationKey] = getNumFromVal(
      newValue,
      isIntake
    );
    setUpdatedAllocations(currentState => ({
      ...currentState,
      ...updatedValue,
    }));

    // Then get value to compare to, and check validity of new value, and get error string if exists
    const comparator: number | undefined = getValueComparator(
      hourCategoryKey,
      isIntake,
      allocationId
    );
    const { isValid, errorString } = validateAllocationValue(newValue, isIntake, comparator);

    // Then update UI based on validity and error message
    if (isValid) {
      if (errorElement.textContent !== '') {
        setErrorCount(errorCount - 1);
        errorElement.textContent = '';
        e.target.classList.remove('error');
        (errorElement as HTMLElement).style.cssText = 'margin:0';
      }
    } else {
      if (errorElement.textContent === '') {
        e.target.className += ' error';
        (errorElement as HTMLElement).style.cssText = 'text-align:center;margin-bottom:10px';
        setErrorCount(errorCount + 1);
      }
      errorElement.textContent = errorString;
    }
  };

  // TODO: Handle errors from api
  const onSave = async () => {
    await update({
      variables: {
        organizationId,
        editedOrganizationDedicatedGroupModelAllocations: Object.values(updatedAllocations),
      },
    });
    await refetch();
    setCarePeriodAllocationsComplete(false);
  };

  // TODO: handle attempts to cancel or change tabs with unsaved changed

  return (
    <div>
      {completedRows.length > 0 && (
        <div className="mb4">
          <UtilizationTable
            heading="Completed"
            rows={completedRows}
            columns={pastColumnCopy(careType)}
            editable={false}
          />
        </div>
      )}
      {currentRows.length > 0 && (
        <div className="mb4">
          <UtilizationTable
            heading="This Week"
            rows={currentRows}
            columns={thisWeekColumnCopy(careType)}
            editable={isEditable}
            // Columns for the current week are (Max Hours - EDITABLE, Hours Utilized, Hours Scheduled, Max Intakes - EDITABLE, Intakes Utilized, Intakes Scheduled)
            editableColumns={[[false], [true, false, false], [true, false, false]]}
            handleChange={handleChange}
          />
        </div>
      )}
      {upcomingRows.length > 0 && (
        <div className="mb4">
          <UtilizationTable
            heading="Upcoming"
            rows={upcomingRows}
            columns={upcomingColumnCopy(careType)}
            editable={isEditable}
            // Columns for the current week are (Max Hours - EDITABLE, Hours Scheduled, Max Intakes - EDITABLE, Intakes Scheduled)
            editableColumns={[[false], [true, false], [true, false]]}
            handleChange={handleChange}
          />
        </div>
      )}
      {editable && (
        <div>
          {!isEditable ? (
            <FinalButton
              kind="outline_black"
              onClick={() => {
                setIsEditable(true);
              }}
            >
              Edit {capitalize(careType)} Hours
            </FinalButton>
          ) : (
            <>
              <FinalButton
                kind="primary"
                className="mr2"
                disabled={errorCount !== 0}
                onClick={() => {
                  setIsEditable(false);
                  onSave();
                }}
              >
                Save Changes
              </FinalButton>
              <FinalButton
                kind="minimal_gray"
                onClick={() => {
                  setIsEditable(false);
                }}
              >
                Cancel
              </FinalButton>
            </>
          )}
        </div>
      )}
    </div>
  );
};

function validateAllocationValue(
  newValue: string,
  isIntake: boolean,
  comparator: number | undefined
) {
  const newValNum = getNumFromVal(newValue, isIntake);

  // Allow users to clear out values
  if (newValue === '') {
    return { isValid: true, errorString: '' };
  }

  if (isIntake) {
    // Validation for Intake Fields
    // First check that it's a whole number
    const intakeRegex = /^\d+$/;
    if (newValue.match(intakeRegex) === null) {
      return { isValid: false, errorString: 'Only positive whole numbers allowed.' };
    }
    // Then, if have a narrator, compare, otherwise valid
    if ((comparator === 0 || comparator) && newValNum > comparator) {
      return {
        isValid: false,
        errorString: 'Number of max intakes cannot exceed max bookable hours.',
      };
    }
  } else {
    // Validation for Hours Fields (Non Intake)
    // First check that it's a whole number or 0.25, .5, .75, .0
    const maxHoursRegex = /^\d+(\.(25|5|75|0)0*)?$/;
    if (newValue.match(maxHoursRegex) === null) {
      return {
        isValid: false,
        errorString: 'Only positive whole numbers and increments of .25, .5, and .75 allowed.',
      };
    }
    // Then, if have a comparator, compare, otherwise valid
    if ((comparator === 0 || comparator) && comparator > newValNum) {
      return {
        isValid: false,
        errorString: 'Number of max bookable hours cannot be less than max intakes',
      };
    }
  }
  return { isValid: true, errorString: '' };
}

function prepUtilizationRows(
  utilizationRecords: OrganizationUtilizationWeek[],
  careType: CareType
): [UtilizationRow[], UtilizationRow[], UtilizationRow[]] {
  let weekCounter = 1;
  return utilizationRecords?.reduce(
    (result, a) => {
      const psychiatryHours = Object.values(a.psychiatryHours);
      const therapyHours = Object.values(a.therapyHours);
      const hours = careType === CareType.Psychiatry ? psychiatryHours : therapyHours;
      const rowWeekText = prepWeekText(a, weekCounter);

      // TODO: refactor this to use object access instead of array access. This
      // array access could be prone to errors if the data changes
      if (moment(a.endDate) < moment().day('Sunday')) {
        result[0].push({
          id: a.id,
          rowData: [[rowWeekText], [hours[0], hours[2]], [hours[1], hours[4]]],
        });
      } else if (moment(a.startDate) > moment().endOf('week')) {
        result[2].push({
          id: a.id,
          rowData: [[rowWeekText], [hours[0], hours[3]], [hours[1], hours[5]]],
        });
      } else {
        result[1].push({
          id: a.id,
          rowData: [[rowWeekText], [hours[0], hours[2], hours[3]], [hours[1], hours[4], hours[5]]],
        });
      }
      weekCounter += 1;
      return result;
    },
    [[], [], []] as [UtilizationRow[], UtilizationRow[], UtilizationRow[]]
  );
}

function prepWeekText(a: OrganizationUtilizationWeek, weekCounter: number) {
  const mmtStart = moment(a.startDate);
  const mmtEnd = moment(a.endDate);
  const diff = mmtEnd.diff(mmtStart, 'days') + 1;

  let rowWeekText = `Week ${weekCounter}: ${moment(a.startDate).format('M/D/YYYY')} - ${moment(
    a.endDate
  ).format('M/D/YYYY')}`;

  if (diff < 7) {
    rowWeekText += ` (${diff} day${diff > 1 ? 's' : ''})`;
  }
  return rowWeekText;
}

const getNumFromVal = (stringValue: string, isIntake: boolean) => {
  return isIntake ? parseInt(stringValue, 10) : parseFloat(stringValue);
};
