import React from 'react';
import { Text } from '../../../globalStyles';
import { cx } from '../../../utils';
import { Icon } from '../../Icons';
import { ProgressProps } from './types';

export function ProgressCrumbs({ labels, stepIdx, onClick }: ProgressProps) {
  return (
    <div className="flex flex-row gap-3 items-center justify-center">
      {labels.map((label, labelIndex) => {
        const canClick = !!onClick && labelIndex < stepIdx;
        const isCurrentStep = labelIndex === stepIdx;
        const clazz = cx({ pointer: canClick });
        const P = isCurrentStep ? Text.bodySmallBold : Text.bodySmall;
        return (
          <React.Fragment key={label}>
            <P
              className={clazz}
              kind={isCurrentStep ? 'text' : 'grayText'}
              onClick={onClick ? () => onClick(labelIndex) : undefined}
            >
              {label}
            </P>
            {labelIndex < labels.length - 1 && <Icon icon="iconsRightChevronSvg" size={12} />}
          </React.Fragment>
        );
      })}
    </div>
  );
}
