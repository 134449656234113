import moment from 'moment';
import { ListChannelFragment } from '../../graphQL';

export const getLastSentText = (lastMessage: ListChannelFragment['lastMessage']) => {
  if (!lastMessage) {
    return 'No Messages';
  }

  const oneMinute = 60;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;

  const createdAt = moment(lastMessage.createdAt);
  const asSeconds = Math.floor(moment().diff(createdAt).valueOf() / 1000);

  // display seconds
  if (asSeconds < oneMinute) {
    return `${asSeconds}s`;
  }
  // display minutes
  if (asSeconds < oneHour) {
    return `${Math.floor(asSeconds / oneMinute)}m`;
  }
  // display hours
  if (asSeconds < oneDay) {
    return `${Math.floor(asSeconds / oneHour)}h`;
  }
  // if greater than a day display date
  return createdAt.format('l');
};
