import { Checkbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { Input, MaskedInput } from 'baseui/input';
import { StatefulSelect } from 'baseui/select';
import { capitalize, chain, difference, isNumber } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CampusTeamSelection } from '../../../Components/CampusTeam/CampusTeamSelection';
import { parseCampusTeamToMemberInput } from '../../../Components/CampusTeam/campusTeamUtils';
import { EditCampusTeamMember } from '../../../Components/CampusTeam/types';
import { MaskedPhoneInput } from '../../../Components/EzForm/MaskedInputs';
import { FinalButton } from '../../../Components/FinalButton';
import { validatePhoneNumber } from '../../../Components/FormValidations/Validators';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { Notification } from '../../../Components/Notification';
import { UniversityLogo } from '../../../Components/Organization/UniversityLogo';
import { McpOnly, ReferralOnly, useCurrentProvider } from '../../../Components/Permissions';
import { OutsideReferralPeriod } from '../../../Components/ReferralCredits/OutsideReferralPeriod';
import { ReferralCreditsInsufficient } from '../../../Components/ReferralCredits/ReferralCreditsInsufficient';
import { ReferralCreditsOverview } from '../../../Components/ReferralCredits/ReferralCreditsOverview';
import { ReferralCreditsUsageInsufficient } from '../../../Components/ReferralCredits/ReferralCreditsUsageInsufficient';
import { ReferralCreditsUsageSufficient } from '../../../Components/ReferralCredits/ReferralCreditsUsageSufficient';
import { getReferralCreditBreakdown } from '../../../Components/ReferralCredits/getReferralCreditBreakdown';
import { When } from '../../../Components/When';
import { useGeoStateAvailability } from '../../../Hooks/useGeoStateAvailability';
import { PaddedPage, Text, colors } from '../../../globalStyles';
import {
  CareType,
  Entitlement,
  OrganizationForEnrollPageQuery,
  SamlConfigurationType,
  StateCodes,
  useAdminCreateUserMutation,
  useSamlConfigurationQuery,
} from '../../../graphQL';
import { stateOptions, states } from '../../../states';
import { canReferCareType, englishList, hasEntitlement, stripPhoneNumber } from '../../../utils';
import { UnexpectedError } from '../../Shared';
import { DuplicateEmailModal } from '../DuplicateEmailModal';
import { ReferralSuccessPage } from '../ReferralSuccessPage';
import { ResentActivationPage } from '../ResentActivationPage';
import * as Styles from '../styles';
import { birthdateValidation } from '../../../Components/Form';
import { getGraphqlFormatErrors } from '../../../utils/graphql';
interface FormFields {
  firstName: string;
  lastName: string;
  birthDate: string;
  careTypes: CareType[];
  email: string;
  confirmEmail: string;
  phone: string;
  geoState: string;
  childOrganizationId: number | undefined;
  preferredName?: string;
  acknowledge?: boolean;
  samlUniqueId?: string;
}
const required = 'This field is required.';

export function DefaultEnrollScreen({ organization }: OrganizationForEnrollPageQuery) {
  const { appView, providerOrgUsesDedicatedGroupModel, providerOrgUsesSessionsModel } =
    useCurrentProvider();
  const params = useParams<{ organizationId: string }>();
  const organizationId = Number(params.organizationId);
  const { data: samlConfigurationQueryData } = useSamlConfigurationQuery({
    variables: {
      organizationId,
      type: SamlConfigurationType.User,
    },
  });
  const [campusTeamError, setCampusTeamError] = useState<string>('');
  const [campusTeam, setCampusTeam] = useState<EditCampusTeamMember[]>([]);

  const {
    loading: validateLoading,
    loadError,
    validateCareTypesState,
  } = useGeoStateAvailability(organizationId);
  const [showPreferredNameField, setShowPreferredNameField] = useState(false);
  const [showDuplicateEmailModal, setShowDuplicateEmailModal] = useState(false);
  const [showResentActivationPage, setShowResentActivationPage] = useState(false);
  const [selectedCareTypes, setSelectedCareTypes] = useState<CareType[]>([]);

  const formRef = useRef<HTMLFormElement>(null);
  const {
    handleSubmit,
    register,
    errors,
    setError,
    setValue,
    watch,
    control,
    getValues,
    trigger,
    formState,
  } = useForm<FormFields>({
    shouldUnregister: false,
  });

  useEffect(() => {
    // Register the select components manually.
    register(
      { name: 'birthDate' },
      {
        required,
        validate: {
          validDate: (value: string) =>
            moment(value, 'MM/DD/YYYY').isValid() || 'Please enter a valid date.',
          format: (value: string) =>
            /^\d{2}\/\d{2}\/\d{4}$/.test(value) || 'Enter date in MM/DD/YYYY format.',
        },
      }
    );
    register(
      { name: 'phone' },
      {
        required,
        validate: {
          format: validatePhoneNumber,
        },
      }
    );
    register(
      { name: 'careTypes' },
      { required, validate: { length: value => value?.length > 0 || required } }
    );
  }, [register, watch, appView]);

  const [createUser, { loading: createUserLoading, error: createUserError, data: createUserData }] =
    useAdminCreateUserMutation({
      onError: err => {
        if (err.message === 'Email already exists.') {
          setShowDuplicateEmailModal(true);
        }
        formRef.current?.scrollIntoView?.();
      },
    });

  const formatErrors = useMemo(() => getGraphqlFormatErrors(createUserError), [createUserError]);

  if (validateLoading) return <LoadingPage />;
  if (loadError) return <UnexpectedError />;

  const isSuperOrg = organization.children.length > 0;

  const makeEmailDomainLowerCase = (email: string) => {
    const splitEmail = email.split('@');
    return `${splitEmail[0]}@${splitEmail[1].toLowerCase()}`;
  };

  const submit = async (values: FormFields) => {
    const birthdayInvalidError = validateBirthdate(values.birthDate);
    if (birthdayInvalidError) {
      setError('birthDate', {
        type: 'invalid',
        message: birthdayInvalidError,
      });
      formRef.current?.scrollIntoView?.();
      return;
    }

    const campusTeamWithRelations = parseCampusTeamToMemberInput(campusTeam, setCampusTeamError);
    if (!campusTeamWithRelations) {
      return;
    }

    setCampusTeamError('');

    createUser({
      variables: {
        input: {
          organizationId: values.childOrganizationId ?? organizationId,
          firstName: values.firstName,
          lastName: values.lastName,
          email: makeEmailDomainLowerCase(values.email),
          phone: stripPhoneNumber(values.phone),
          birthDate: moment(values.birthDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          campusTeam: campusTeamWithRelations,
          preferredName: values.preferredName,
          careTypes: values.careTypes,
          geoState: values.geoState as StateCodes,
          samlUniqueId: values.samlUniqueId,
        },
      },
    });
  };

  const onAddPreferredName = () => {
    setShowPreferredNameField(val => !val);
  };

  /**
   * Validates the given state against both the organization restricted states
   * and the provider available states for the currently selected care types.
   */
  const validateStateIsAllowedForCareType = (state: string): string | boolean => {
    if (!validateCareTypesState) {
      return true;
    }

    const validationResponse = validateCareTypesState(selectedCareTypes)(state);
    if (validationResponse.emptyCareTypes) {
      return 'Select a care type above to validate whether this student can be seen by a contracted provider.';
    }

    if (validationResponse.passed) {
      return true;
    }

    if (validationResponse.organizationFailed) {
      const { careTypes, stateName, isAnyTypeAvailable } = validationResponse.organizationFailed;
      return [
        `Your organization is not contracted to refer students for ${careTypes} services in ${stateName}.`,
        isAnyTypeAvailable
          ? " You may select a different care type above, if applicable, for the student's needs."
          : '',
        ' Email us at hi@mantrahealth.com if you have any questions.',
      ].join('');
    }

    if (validationResponse.providerFailed) {
      const { careTypes, stateName, isAnyTypeAvailable } = validationResponse.providerFailed;
      return [
        `Mantra Health is not contracted to provide ${careTypes} services to students that reside in ${stateName}.`,
        isAnyTypeAvailable
          ? " You may select a different care type above, if applicable, for the student's needs."
          : '',
        ' Email us at hi@mantrahealth.com if you have any questions.',
      ].join('');
    }

    return true;
  };

  if (createUserData && appView === 'mcp') {
    return <Redirect to={`/users/${createUserData.adminCreateUser.id}`} />;
  }
  if (createUserData?.adminCreateUser && appView === 'referral') {
    return (
      <ReferralSuccessPage
        user={{
          ...getValues(),
          id: createUserData.adminCreateUser.id,
        }}
      />
    );
  }
  if (showResentActivationPage) {
    return (
      <ResentActivationPage
        email={watch('email')}
        firstName={watch('firstName')}
        preferredName={watch('preferredName')}
      />
    );
  }

  const wholeCampusCare = hasEntitlement(organization, Entitlement.WholeCampusCare);

  const {
    availableCredits,
    therapyCreditWeight,
    psychiatryCreditWeight,
    referralPeriodEnd,
    referralCreditCost,
  } = getReferralCreditBreakdown({
    wholeCampusCare,
    orgReferralCreditData: organization?.referralCredits,
    careTypes: selectedCareTypes,
  });

  if (wholeCampusCare && (!referralPeriodEnd || !therapyCreditWeight || !psychiatryCreditWeight)) {
    return (
      <Styles.Background>
        <Styles.Wrapper>
          <OutsideReferralPeriod />
        </Styles.Wrapper>
      </Styles.Background>
    );
  }

  return (
    <Styles.Background>
      <form onSubmit={handleSubmit(submit)} ref={formRef}>
        <Styles.Wrapper>
          <Text.label>Enroll New Student</Text.label>
          <UniversityLogo />
          <Text.h1>Create Student Account</Text.h1>
          <NextAvailableIntake
            organization={organization}
            providerOrgUsesDedicatedGroupModel={providerOrgUsesDedicatedGroupModel}
            providerOrgUsesSessionsModel={providerOrgUsesSessionsModel}
          />
          {wholeCampusCare &&
            availableCredits !== undefined &&
            availableCredits >= 0 &&
            referralPeriodEnd && (
              <>
                <Hr className="mt4 mb3" />
                {availableCredits === 0 && <ReferralCreditsInsufficient />}
                {availableCredits > 0 && (
                  <ReferralCreditsOverview
                    periodEnd={moment(referralPeriodEnd).format('MM-DD-YYYY')}
                    availableCredits={availableCredits}
                    therapyCreditWeight={therapyCreditWeight}
                    psychiatryCreditWeight={psychiatryCreditWeight}
                  />
                )}
                <Hr className="mb4" />
              </>
            )}
          {createUserError && (
            <>
              {!createUserError?.message.includes('Email already exists') &&
                (createUserError?.graphQLErrors?.[0]?.extensions?.exception.code ===
                'NOT_ON_ENROLL_LIST' ? (
                  <Notification kind="negative">
                    This student&apos;s email is not on the enrollment list. Please verify the email
                    address is accurate. If you think this is a mistake, email{' '}
                    {organization.director ? (
                      <a href={`mailto:${organization.director.email}`}>
                        {organization.director.email}
                      </a>
                    ) : (
                      'your organization administrator'
                    )}
                    .
                  </Notification>
                ) : (
                  <Notification kind="negative">
                    {createUserError.message.replace('GraphQL error:', '')}
                  </Notification>
                ))}

              {formatErrors.input && (
                <Notification kind="negative">{formatErrors.input.join(' ')}</Notification>
              )}
            </>
          )}
          {showDuplicateEmailModal && (
            <DuplicateEmailModal
              email={watch('email')}
              onClose={() => setShowDuplicateEmailModal(false)}
              onResendActivation={() => setShowResentActivationPage(true)}
            />
          )}
          <Styles.HeaderContainer>
            <Styles.Header>Basics</Styles.Header>
            <Text.body>
              If present, a student’s preferred name will appear on their profile, but we ask for
              the legal name because it’s required for telehealth practices.
            </Text.body>
          </Styles.HeaderContainer>
          <Styles.FormSection>
            <Styles.Field>
              <Styles.FieldName htmlFor="firstName">Legal First Name</Styles.FieldName>
              <FormControl caption={errors.firstName && errors.firstName.message}>
                <Input
                  inputRef={register({ required })}
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  error={!!errors.firstName}
                />
              </FormControl>
              <Styles.AddField onClick={onAddPreferredName}>+ Add Preferred Name</Styles.AddField>
            </Styles.Field>
            <Styles.Field>
              <Styles.FieldName htmlFor="lastName">Legal Last Name</Styles.FieldName>
              <FormControl caption={errors.lastName && errors.lastName.message}>
                <Input
                  inputRef={register({ required })}
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  error={!!errors.lastName}
                />
              </FormControl>
            </Styles.Field>
            {showPreferredNameField && (
              <>
                <Styles.Field>
                  <Styles.FieldName htmlFor="preferredName">
                    What should we call this patient?
                  </Styles.FieldName>
                  <FormControl caption={errors.preferredName && errors.preferredName.message}>
                    <Input
                      inputRef={register({ required })}
                      id="preferredName"
                      name="preferredName"
                      placeholder="Preferred Name"
                      error={!!errors.preferredName}
                    />
                  </FormControl>
                </Styles.Field>
                <Styles.Field>{/* spacer for grid layout */}</Styles.Field>
              </>
            )}
            <Styles.Field>
              <Styles.FieldName htmlFor="birthDate">Date of Birth</Styles.FieldName>
              {/* non-breaking space so this dropdown lines up with the one next to it */}
              <Styles.FieldHint>{'\u00A0'}</Styles.FieldHint>
              <FormControl caption={errors.birthDate && errors.birthDate.message}>
                <MaskedInput
                  name="birthDate"
                  id="birthDate"
                  placeholder="MM/DD/YYYY"
                  error={!!errors.birthDate}
                  mask="99/99/9999"
                  onChange={event => setValue('birthDate', event.currentTarget.value)}
                />
              </FormControl>
            </Styles.Field>
            <Styles.Field>
              <Styles.FieldName>Treatment Type</Styles.FieldName>
              <Styles.FieldHint>
                Type of care this student will receive through Mantra
              </Styles.FieldHint>
              <FormControl caption={errors.careTypes && (errors.careTypes as any).message}>
                <StatefulSelect
                  id="careTypes"
                  multi
                  options={[
                    {
                      label: CareType.Psychiatry,
                      id: CareType.Psychiatry,
                      disabled: !canReferCareType(
                        organization.careFlows,
                        appView,
                        CareType.Psychiatry
                      ),
                    },
                    {
                      label: CareType.Therapy,
                      id: CareType.Therapy,
                      disabled: !canReferCareType(
                        organization.careFlows,
                        appView,
                        CareType.Therapy
                      ),
                    },
                  ].sort((a, _) => (a.disabled ? 0 : -1))}
                  onChange={({ value }) => {
                    setValue('careTypes', value.map(v => v.id) as CareType[]);
                    setSelectedCareTypes(value.map(v => v.id) as CareType[]);
                    // If the form has already been submitted, trigger re-validation
                    // on the geoState field to see if we have providers matching the
                    // state.
                    if (formState.isSubmitted) trigger('geoState');
                  }}
                  clearable={false}
                  error={!!errors.careTypes}
                />
              </FormControl>
            </Styles.Field>
          </Styles.FormSection>
          {isSuperOrg && (
            <Styles.FormSection style={{ marginBottom: '1em' }}>
              <>
                <Styles.FieldName>Campus</Styles.FieldName>
                <FormControl
                  caption={errors.childOrganizationId && errors.childOrganizationId.message}
                >
                  <Controller
                    defaultValue={null}
                    render={({ onChange, ...handler }) => (
                      <StatefulSelect
                        options={organization.children
                          .map(({ id, name }) => ({ id, label: name }))
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        clearable={false}
                        onChange={({ option }) => onChange(option!.id)}
                        placeholder="Select a campus"
                        error={!!errors.childOrganizationId}
                        {...handler}
                      />
                    )}
                    control={control}
                    name="childOrganizationId"
                    rules={{ required }}
                  />
                </FormControl>
              </>
            </Styles.FormSection>
          )}
          <Styles.HeaderContainer>
            <Styles.Header>Student Contact</Styles.Header>
          </Styles.HeaderContainer>
          <Styles.FormSection>
            <>
              <Styles.FieldName htmlFor="email">Student University Email Address</Styles.FieldName>
              <FormControl caption={errors.email && errors.email.message}>
                <Input
                  inputRef={register({
                    required,
                    validate: {
                      at: value => value.includes('@') || 'Please enter a valid email address.',
                    },
                  })}
                  name="email"
                  id="email"
                  placeholder="name@university.edu"
                  type="email"
                  error={!!errors.email}
                />
              </FormControl>
            </>
            <>
              <Styles.FieldName htmlFor="confirmEmail">
                Confirm Student University Email
              </Styles.FieldName>
              <FormControl caption={errors.confirmEmail && errors.confirmEmail.message}>
                <Input
                  inputRef={register({
                    required,
                    validate: {
                      match: value => value === watch('email') || 'Emails do not match.',
                    },
                  })}
                  name="confirmEmail"
                  id="confirmEmail"
                  placeholder="name@university.edu"
                  type="email"
                  error={!!errors.confirmEmail}
                />
              </FormControl>
            </>
            <>
              <Styles.FieldName htmlFor="phone">Student Phone Number</Styles.FieldName>
              <FormControl caption={errors.phone && errors.phone.message}>
                <MaskedPhoneInput
                  name="phone"
                  id="phone"
                  error={!!errors.phone}
                  control={control}
                />
              </FormControl>
            </>
            <>
              <Styles.FieldName htmlFor="geoState">Student’s Residential State</Styles.FieldName>
              <FormControl caption={errors.geoState && errors.geoState.message}>
                <Controller
                  defaultValue={null}
                  render={({ onChange, ...handler }) => (
                    <StatefulSelect
                      id="geoState"
                      options={stateOptions}
                      clearable={false}
                      onChange={({ option }) => onChange(option!.id)}
                      maxDropdownHeight="15rem"
                      error={!!errors.geoState}
                      {...handler}
                    />
                  )}
                  control={control}
                  name="geoState"
                  rules={{
                    required,
                    validate: {
                      unavailableCareType: (value: keyof typeof states) => {
                        return validateStateIsAllowedForCareType(value);
                      },
                    },
                  }}
                />
              </FormControl>
            </>
            {samlConfigurationQueryData?.organization.samlConfiguration
              ?.enableManualUniqueIdEdits && (
              <>
                <Styles.FieldName htmlFor="uniqueId">Unique Id</Styles.FieldName>
                <FormControl>
                  <Input
                    inputRef={register({ required })}
                    id="samlUniqueId"
                    name="samlUniqueId"
                    placeholder="Enter Unique Id"
                  />
                </FormControl>
              </>
            )}
          </Styles.FormSection>
        </Styles.Wrapper>
        {/* Campus Team section */}
        <McpOnly>
          <When isTruthy={isNumber(organizationId)}>
            <PaddedPage style={{ paddingTop: 0, paddingBottom: 0 }} className="mb2">
              <CampusTeamSelection
                campusTeam={campusTeam ?? []}
                setCampusTeam={setCampusTeam}
                showAccessFaq
                organizationId={organizationId}
                relationshipError={campusTeamError}
              />
            </PaddedPage>
          </When>
        </McpOnly>
        {/* Referral Credits usage warning: enough credits */}
        {wholeCampusCare &&
          !!referralCreditCost &&
          !!availableCredits &&
          availableCredits > 0 &&
          availableCredits >= referralCreditCost && (
            <Styles.Wrapper style={{ paddingTop: '1rem' }}>
              <ReferralCreditsUsageSufficient
                referralCost={referralCreditCost}
                therapyCreditWeight={therapyCreditWeight}
                psychiatryCreditWeight={psychiatryCreditWeight}
              />
            </Styles.Wrapper>
          )}
        {/* Referral Credits usage warning: Not enough credits */}
        {wholeCampusCare &&
          !!availableCredits &&
          !!referralCreditCost &&
          (availableCredits === 0 || availableCredits < referralCreditCost) && (
            <Styles.Wrapper style={{ paddingTop: '1rem' }}>
              <ReferralCreditsUsageInsufficient
                referralCost={referralCreditCost}
                availableCredits={availableCredits}
              />
            </Styles.Wrapper>
          )}
        {/* Referral Portal disclaimer */}
        <Styles.Wrapper>
          <ReferralOnly>
            <Controller
              control={control}
              name="acknowledge"
              rules={{ required: 'You must read and acknowledge these terms' }}
              defaultValue={false}
              render={({ value, onChange, onBlur }) => (
                <div className="mb4">
                  <Checkbox
                    isError={!!errors.acknowledge}
                    checked={value}
                    onChange={() => onChange(!value)}
                    onBlur={onBlur}
                  >
                    <Text.body className="mt0 ml2">
                      I acknowledge and understand that Mantra Health &amp; Wellround Provider Group
                      are <strong>not an emergency service</strong> nor do they provide urgent care
                      services. If you have an emergency, if a student is in crisis, or is in
                      imminent risk of harming themselves or others,
                      <strong> call 911 or another local emergency resource.</strong>
                    </Text.body>
                  </Checkbox>
                  {errors.acknowledge && (
                    <Text.body kind="danger">{errors.acknowledge.message}</Text.body>
                  )}
                </div>
              )}
            />
          </ReferralOnly>
          <Styles.FormSection>
            <FinalButton
              type="submit"
              className="w-100"
              kind="primary"
              loading={createUserLoading}
              data-cy="enrollSubmit"
              disabled={
                wholeCampusCare &&
                availableCredits !== undefined &&
                referralCreditCost !== undefined &&
                (availableCredits === 0 || availableCredits < referralCreditCost)
              }
            >
              Submit
            </FinalButton>
          </Styles.FormSection>
        </Styles.Wrapper>
      </form>
    </Styles.Background>
  );
}

const validateBirthdate = (value: string): string | undefined => {
  return Object.entries(birthdateValidation)
    .map(([, validate]) => {
      const itemResult = validate(value);
      return itemResult === true ? '' : itemResult;
    })
    .find(item => item !== '');
};

const NextAvailableIntake = ({
  organization,
  providerOrgUsesDedicatedGroupModel,
  providerOrgUsesSessionsModel,
}: {
  organization: OrganizationForEnrollPageQuery['organization'];
  providerOrgUsesDedicatedGroupModel: () => boolean;
  providerOrgUsesSessionsModel: () => boolean;
}) => {
  if (providerOrgUsesDedicatedGroupModel() || providerOrgUsesSessionsModel()) {
    // Do not show next available appointments if the org uses DGM.
    return null;
  }

  const availableIntakes = organization.nextAvailableIntakeAppts;
  const orgCareTypes = organization.careFlows.map(f => f.careType);

  if (!orgCareTypes.length) return null;

  const careTypesWithoutNext = difference(
    orgCareTypes,
    availableIntakes.map(a => a.careType)
  );

  // group appts with the same time
  const nextAppts = chain(availableIntakes)
    .filter(a => orgCareTypes.includes(a.careType))
    .groupBy(a => moment(a.start).format('dddd, MMMM Do'))
    .mapValues((v, k) => `${k} (${englishList(v.map(a => capitalize(a.careType)))})`)
    .values()
    .value();

  return (
    <>
      {!!nextAppts.length && (
        <Text.body>
          Next available appointment{nextAppts.length > 1 ? 's' : ''}:{' '}
          <span className="b">{nextAppts.join(', ')}</span>.
        </Text.body>
      )}
      {!!careTypesWithoutNext.length && (
        <Text.body>
          There are no appointments available in the next month
          {orgCareTypes.length > 1
            ? ` for ${englishList(careTypesWithoutNext.map(c => capitalize(c)))}.`
            : '.'}
        </Text.body>
      )}
    </>
  );
};
const Hr = styled.div`
  border: 1px solid ${colors.grey.lightBorder};
`;
