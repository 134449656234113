import { Modal } from 'baseui/modal';
import { first } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { FinalButton } from '../../../Components/FinalButton';
import { PermsOnly, useCurrentProvider } from '../../../Components/Permissions';
import { usePopoverTabContext } from '../../../Components/PopoverTabs/PopoverTabContainer';
import { EditSafetyPlan } from '../../../Components/SafetyPlan/EditSafetyPlan';
import { ViewSafetyPlan } from '../../../Components/SafetyPlan/ViewSafetyPlan';
import { Permission, useActivateSafetyPlanMutation } from '../../../graphQL';
import { formatProviderName } from '../../../modelUtils/provider';
import { getFullName, isOnCareTeam } from '../../../modelUtils/users';
import { RecordViewOnly, useDrilldownContext, ViewWarning } from '../helpers';
import { Styles, Text } from '../styles';

export const SafetyPlanTab = () => {
  const { safetyPlan } = useDrilldownContext().user;

  return (
    <div>
      <ViewWarning restricted>
        Due to limited record access, safety plan cannot be accessed.
      </ViewWarning>
      <RecordViewOnly oz full limited>
        {safetyPlan ? <ActivatedPlan /> : <UnActivatedPlan />}
      </RecordViewOnly>
    </div>
  );
};

const UnActivatedPlan = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user, refetch } = useDrilldownContext();
  const { getCopy, appView, hasPermission, currentProvider } = useCurrentProvider();
  const [activatePlan, { loading }] = useActivateSafetyPlanMutation({
    variables: { userId: user.id },
  });

  const onActivatePlan = async () => {
    if (!hasPermission(Permission.SafetyPlanActivate)) return;
    await activatePlan();
    setIsModalOpen(false);
    refetch();
  };

  const { organization: org } = user;
  const steps = [
    `Notify ${getCopy('patient')} of safety plan activation`,
    appView === 'mcp'
      ? 'Notify their Mantra-affiliated provider and any additional campus collaborators'
      : null,
    appView === 'oz' && !!org ? "Notify this student's campus collaborator(s)" : null,
    `Allow ${getCopy('patient')} to view and edit safety plan`,
    hasPermission(Permission.SafetyPlanEdit) ? 'Allow you to view and edit safety plan' : null,
    appView === 'mcp' ? 'Allow their Mantra-affiliated provider to edit safety plan' : null,
    appView === 'mcp' || (appView === 'oz' && !!org)
      ? 'Allow authorized campus collaborators to view and/or edit safety plan'
      : null,
    appView === 'mcp' || (appView === 'oz' && !!org)
      ? `Appear in the university's record of the ${getCopy(
          'patient'
        )} in the Mantra Collaboration Portal`
      : null,
  ];

  return (
    <Styles.widget>
      <ConfirmActivationModal
        isOpen={isModalOpen}
        onConfirm={onActivatePlan}
        onCancel={() => setIsModalOpen(false)}
        loading={loading}
      />
      <div>
        <Text.h2 className="ttc">{getCopy('patient')} Safety Plan</Text.h2>
        <Text.body>Inactive</Text.body>
      </div>
      <PermsOnly allowed={Permission.SafetyPlanActivate}>
        {(hasPermission(Permission.MantraAdmin) || isOnCareTeam(currentProvider, user)) && (
          <>
            <div className="mb4 mt3">
              <Text.bodyBold>Activate Safety Plan</Text.bodyBold>
              <Text.body>
                Please note, activation of this {getCopy('patient')}&apos;s safety plan will:
              </Text.body>
              <ul>{steps.map(s => s && <li key={s}>{s}</li>)}</ul>
            </div>
            <div className="flex justify-start items-center">
              <FinalButton
                kind="outline_danger"
                disabled={loading}
                onClick={() => setIsModalOpen(true)}
              >
                Activate Safety Plan
              </FinalButton>
              <div className="mw5 ml5">
                <Text.caption kind="grayText">
                  <strong>Note:</strong> Activating the safety plan should only be done with the{' '}
                  {getCopy('patient')}
                  &apos;s full knowledge.
                </Text.caption>
              </div>
            </div>
          </>
        )}
      </PermsOnly>
    </Styles.widget>
  );
};

const ActivatedPlan = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getCopy, hasPermission, currentProvider } = useCurrentProvider();
  const { user } = useDrilldownContext();
  const { pushTab } = usePopoverTabContext();
  const { safetyPlan } = user;

  if (!safetyPlan) return null;

  const onEditPlan = () => {
    if (!hasPermission(Permission.SafetyPlanEdit)) return;
    setIsModalOpen(false);
    pushTab({
      tabKey: 'safety-plan-edit',
      title: 'Edit Safety Plan',
      renderBody: props => (
        <EditSafetyPlan userId={user.id} safetyPlan={safetyPlan.data} {...props} />
      ),
    });
  };

  const started = safetyPlan.editHistory.length >= 1;
  const lastEdit = first(safetyPlan.editHistory);

  return (
    <Styles.widget>
      <ConfirmEditModal
        isOpen={isModalOpen}
        onConfirm={onEditPlan}
        onCancel={() => setIsModalOpen(false)}
      />
      <div className="flex justify-between items-center">
        <div>
          <Text.h2 className="ttc">{getCopy('patient')} Safety Plan</Text.h2>
          <Text.body>
            Activated by {formatProviderName(safetyPlan.activatingProvider)} on{' '}
            {moment(safetyPlan.createdAt).format('M/D/YYYY')}
          </Text.body>
          {started ? (
            lastEdit && (
              <Text.bodyGrey>
                Last Edited by{' '}
                {lastEdit.provider ? formatProviderName(lastEdit.provider) : getFullName(user)} on{' '}
                {moment(lastEdit.createdAt).format('M/D/YYYY')}
              </Text.bodyGrey>
            )
          ) : (
            <Text.body kind="danger">Not Started</Text.body>
          )}
        </div>
        <div>
          {started && (
            <PermsOnly allowed={Permission.SafetyPlanEdit}>
              <FinalButton kind="outline_black" pill onClick={() => setIsModalOpen(true)}>
                <span className="ph3">Edit</span>
              </FinalButton>
            </PermsOnly>
          )}
        </div>
      </div>
      {started ? (
        <PermsOnly allowed={Permission.Pii}>
          <br />
          <Styles.widgetHr />
          <ViewSafetyPlan safetyPlan={safetyPlan.data} />
        </PermsOnly>
      ) : (
        <PermsOnly allowed={Permission.SafetyPlanEdit}>
          <div className="mt3 mb4">
            <em>
              <Text.bodyGrey className="mb3">
                If this {getCopy('patient')} has not started their safety plan, consider reaching
                out to them via phone or other means of contact.
              </Text.bodyGrey>
              {isOnCareTeam(currentProvider, user) && (
                <Text.bodyGrey>
                  If you choose to fill out the safety plan while meeting with the{' '}
                  {getCopy('patient')}, use the button below.
                </Text.bodyGrey>
              )}
            </em>
          </div>
          {isOnCareTeam(currentProvider, user) && (
            <FinalButton onClick={onEditPlan}>Start Safety Plan with Student</FinalButton>
          )}
        </PermsOnly>
      )}
    </Styles.widget>
  );
};

type ModalProps = {
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  loading?: boolean;
};
const ConfirmActivationModal = ({ onCancel, onConfirm, isOpen, loading }: ModalProps) => {
  const { getCopy } = useCurrentProvider();

  return (
    <Modal isOpen={isOpen} onClose={onCancel} unstable_ModalBackdropScroll>
      <div style={{ padding: '3rem 4rem' }}>
        <Text.h1 className="mb3">
          Are you sure you want to activate this {getCopy('patient')}&apos;s safety plan?
        </Text.h1>
        <Text.body kind="danger" className="mb4">
          This action cannot be undone and will remain in the {getCopy('patient')}&apos;s chart,
          making it visible to the {getCopy('patient')}, as well as those who have access to their
          chart.
        </Text.body>
        <div className="flex flex-column gap-2">
          <FinalButton kind="outline_danger" loading={loading} onClick={onConfirm}>
            Yes, Activate Safety Plan
          </FinalButton>
          <FinalButton kind="minimal_black" onClick={onCancel}>
            Cancel
          </FinalButton>
        </div>
      </div>
    </Modal>
  );
};
const ConfirmEditModal = ({ onCancel, onConfirm, isOpen }: ModalProps) => {
  const { getCopy } = useCurrentProvider();

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <div className="pv4 ph4">
        <Text.h1 className="mb3">
          Are you sure you want to edit this {getCopy('patient')}&apos;s safety plan?
        </Text.h1>
        <Text.body kind="danger" className="mb4">
          NOTE: Any edits made will change the safety plan for the {getCopy('patient')} and their
          entire collaboration team.
        </Text.body>
        <div className="flex flex-column gap-2">
          <FinalButton kind="outline_black" onClick={onConfirm}>
            Yes, Edit Safety Plan
          </FinalButton>
          <FinalButton kind="minimal_black" onClick={onCancel}>
            Cancel
          </FinalButton>
        </div>
      </div>
    </Modal>
  );
};
