import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { HookType } from '../../graphQL';
import { diagnosisCodes, getLabeledDiagnosisCode } from './diagnosisCodes';
import { EditSection } from './formTypes';

export const useFormHookHandler = ({ hooks }: EditSection) => {
  const formContext = useFormContext();

  const onChangeHook = () => {
    if (hooks)
      for (const hook of hooks) {
        switch (hook.type) {
          // the ClearOnChange hook will clear another questions value when the current one changes
          case HookType.ClearOnChange:
            formContext.setValue(
              hook.targetKey,
              Array.isArray(formContext.getValues(hook.targetKey)) ? [] : ''
            );
            break;
        }
      }
  };

  return { onChangeHook };
};

export const useMultiSelectHandler = (question: EditSection) => {
  const { onChangeHook } = useFormHookHandler(question);
  const options = question.options || [];

  return (controller: { value: string[]; onChange: (arg: string[]) => void }) => {
    const checked = options.map(o => controller.value.includes(o));

    const onChange = (newVal: string[]) => {
      const { isolatedOption } = question;
      let val = newVal;
      // if a specified answer is "isolated" make sure it can not be selected alongside anything else
      // an example of this would be a "None" option, where it would not make sense to have another value selected as well
      if (isolatedOption && newVal.includes(isolatedOption)) {
        if (controller.value.includes(isolatedOption))
          val = newVal.filter(i => i !== isolatedOption);
        else val = [isolatedOption];
      }

      onChangeHook();
      controller.onChange(val);
    };

    const onChangeIndex = (index: number) => {
      const indexOfSelectedValue = controller.value.indexOf(options[index]);
      // if selected option is already selected, unselect it
      const nextValue =
        indexOfSelectedValue === -1
          ? [...controller.value, options[index]]
          : controller.value.filter((_: any, i: number) => i !== indexOfSelectedValue);

      onChange(nextValue);
    };

    return { options, checked, onChange, onChangeIndex };
  };
};

export function useDiagnosisSearch(question: EditSection) {
  const { watch } = useFormContext();
  // A bit of hard-coded logic for our diagnosis questions, which have special
  // search behavior.
  // In theory we could build this into our system in a more generic way, but
  // only diagnosis works like this and it seems unlikely that anything else
  // will, so that didn't seem worthwhile.
  // If you find yourself writing something similar to this for another question,
  // you should probably consider turning them both into something more generic.
  const primaryId = watch('primary-diagnosis') as string | undefined;
  const addlIds = watch('additional-diagnoses') as string[] | undefined;
  const isDiagnosisQuestion =
    question.key === 'primary-diagnosis' || question.key === 'additional-diagnoses';
  const [isSearchingDiagnoses, setIsSearchingDiagnoses] = useState(false);
  const onInputChange = (event: any) => {
    setIsSearchingDiagnoses(isDiagnosisQuestion && !!event.target.value);
  };
  const onInputBlur = () => {
    setIsSearchingDiagnoses(false);
  };
  const optionsSource = isSearchingDiagnoses ? diagnosisCodes : question.options!;
  let options = optionsSource.map(code => ({
    label: getLabeledDiagnosisCode(code),
    id: code,
  }));
  if (question.key === 'primary-diagnosis') {
    options = options.filter(i => !addlIds?.includes(i.id));
  } else if (question.key === 'additional-diagnoses') {
    options = options.filter(i => i.id !== primaryId);
  }
  return { options, onInputChange, onInputBlur };
}

export const useClickCounter = () => {
  const tracker = useRef(0);

  useEffect(() => {
    const clickHandler = () => {
      tracker.current += 1;
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, []);

  const resetClickCount = useCallback(() => {
    const val = tracker.current;
    tracker.current = 0;
    return val;
  }, []);

  const getClickCount = useCallback(() => tracker.current, []);

  return { resetClickCount, getClickCount };
};
