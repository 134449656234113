import { createContext, useContext } from 'react';
import { SubRoutes } from './index';
import { SimpleAdminProviderQueryResult } from '../../graphQL';

export type ProviderTabs = typeof SubRoutes[number]['key'];

export type ProviderContextType = {
  setActiveTab: React.Dispatch<React.SetStateAction<ProviderTabs | number>>;
  provider: NonNullable<SimpleAdminProviderQueryResult['data']>['adminProvider'];
  refetch: () => void;
};

export const ProviderDrilldownContext = createContext<ProviderContextType | null>(null);

export const useProviderDrilldownContext = () => {
  const context = useContext(ProviderDrilldownContext);
  if (!context) throw new Error("can't use provider context outside of Drilldown view");
  return context;
};
