import {
  Chart,
  ChartOptions,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { isNil, mapValues } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FinalButton } from '../../../Components/FinalButton';
import { Reticle } from '../../../Components/Flair';
import { Grid } from '../../../Components/Grid';
import { colors } from '../../../globalStyles';
import { useAssessmentResultsQuery } from '../../../graphQL';
import { formatResult, getTitle } from '../../../modelUtils/assessment';
import { useDrilldownContext } from '../helpers';
import { Styles } from '../styles';

Chart.register(LineController, PointElement, LineElement, Tooltip, TimeScale);

const MAX_HISTORY = 10;

const keys = ['phq', 'gad7', 'asrsv11', 'ymrs'] as const;
type Keys = typeof keys[number];

const keyConfig: Record<Keys, { max: number; stepSize: number; title: string; color: string }> = {
  phq: { max: 24, stepSize: 9, title: 'Depression (PHQ-8)', color: colors.primary },
  gad7: { max: 21, stepSize: 7, title: 'Anxiety (GAD-7)', color: colors.danger },
  asrsv11: { max: 6, stepSize: 3, title: 'ADHD (ASRS v1.1)', color: colors.grey.border },
  ymrs: { max: 60, stepSize: 20, title: 'Mania (YMRS)', color: colors.green },
};

const options = mapValues<typeof keyConfig, ChartOptions<'line'>>(
  keyConfig,
  ({ max, stepSize, color }, key) => ({
    maintainAspectRatio: false,
    scales: {
      y: {
        suggestedMin: 0,
        bounds: 'ticks',
        suggestedMax: max,
        ticks: { stepSize, padding: 12 },
        grid: { drawBorder: false },
      },
      x: {
        type: 'time',
        bounds: 'ticks',
        time: {
          unit: 'month',
          stepSize: 5,
          tooltipFormat: 'LL',
          displayFormats: { month: 'M/YY' },
        },
        grid: { display: false, drawOnChartArea: false },
      },
    },
    elements: {
      point: {
        backgroundColor: 'white',
        radius: 4,
        borderWidth: 2,
        hoverBorderWidth: 4,
        hoverRadius: 6,
      },
      line: { borderColor: color, tension: 1 / 3 },
    },

    plugins: {
      tooltip: {
        padding: { top: 8, bottom: 8, left: 12, right: 12 },
        titleAlign: 'center',
        bodyAlign: 'center',
        displayColors: false,
        callbacks: {
          label: ({ dataIndex, dataset, raw }) => {
            const symptoms = (dataset as any)?.symptoms[dataIndex];
            return [dataset.label!, formatResult({ score: raw as number, symptoms, key })];
          },
        },
      },
    },
  })
);

export const ScalesOverTime = () => {
  const { user } = useDrilldownContext();
  const { data } = useAssessmentResultsQuery({ variables: { userId: user.id } });
  const [chart, setChart] = useState<Keys>('phq');
  const canvas = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart<'line'>>();

  const scoreMap = useMemo(() => {
    if (!data) return null;
    return data.results.reduce((acc, { createdAt, questionnaires }) => {
      for (const { score, key, symptoms } of questionnaires) {
        if (isNil(score)) continue;
        const toPush = { createdAt, score, symptoms: symptoms || null };
        // group PHQ-9 and PHQ-8 together (they are scored the same but one has an unscored question
        // about suicidal intent)
        const groupedKeys: Record<string, Keys> = {
          phq9: 'phq',
          phq8: 'phq',
        };
        const groupedKey = groupedKeys[key] ?? key;
        acc.get(groupedKey)?.push(toPush) ?? acc.set(groupedKey, [toPush]);
      }
      return acc;
    }, new Map<Keys, { createdAt: Date; score: number; symptoms: string | null }[]>());
  }, [data]);

  useEffect(() => {
    if (!canvas.current) return;
    chartRef.current = new Chart(canvas.current, {
      type: 'line',
      data: { labels: [], datasets: [] },
    });

    return () => chartRef.current?.destroy();
  }, [scoreMap]);

  useEffect(() => {
    if (!chartRef.current) return;
    const selected = scoreMap?.get(chart)?.slice(-MAX_HISTORY) ?? [];

    const dataset = {
      label: getTitle(chart),
      borderColor: keyConfig[chart].color,
      data: selected.map(s => s.score),
      symptoms: selected.map(s => s.symptoms),
    };

    chartRef.current.data = { labels: selected.map(s => s.createdAt), datasets: [dataset] };
    chartRef.current.options = options[chart];

    chartRef.current.update();
  }, [chart, scoreMap]);

  const keysWithAssessments = keys.filter(k => scoreMap?.has(k));

  if (!data) return null;
  if (keysWithAssessments.length === 0) return null;
  return (
    <Styles.widget>
      <h3 className="f3">Progress</h3>
      <p className="mb3">Select an assessment to see progress</p>
      <Grid gridTemplateColumns="12rem 1fr" className="h5">
        <div className="flex flex-column justify-center mr2 gap-2">
          {keysWithAssessments.map(k => {
            const { color, title } = keyConfig[k];
            const isActive = chart === k;

            return (
              <FinalButton
                key={k}
                kind={isActive ? 'minimal_black' : 'minimal_gray'}
                onClick={() => setChart(k)}
              >
                <Reticle color={color}>
                  <span className="f6">{title}</span>
                </Reticle>
              </FinalButton>
            );
          })}
        </div>
        <div className="flex-auto relative z-0">
          <div className="aspect-ratio--object">
            <canvas ref={canvas} />
          </div>
        </div>
      </Grid>
    </Styles.widget>
  );
};
