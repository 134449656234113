import moment, { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { buildWeeksGrid } from '../../utils';

interface Props {
  month: Moment;
  startDate?: Moment;
  onChangeStartDate: (val: Moment) => void;
  circledDate?: Moment;
}

const baseColor = '#333333';
const disabledColor = '#9F9F9F';

const Container = styled.div`
  margin: 1em auto;
`;
const MonthName = styled.div`
  color: ${disabledColor};
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 0.5em;
  padding-left: 1em;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  max-width: 260px;
`;
interface CellProps {
  disabled?: boolean;
  faded?: boolean;
  active?: boolean;
  first?: boolean;
  last?: boolean;
}
const Cell = styled.div`
  color: ${(props: CellProps) => (props.faded ? disabledColor : baseColor)};
  padding: 0.65em 0.75em 0.85em 0.75em;
  font-size: 12px;
  background-color: ${props => (props.active ? '#DBECFF' : 'transparent')};
  border-top-left-radius: ${props => (props.first ? '20px' : '0')};
  border-bottom-left-radius: ${props => (props.first ? '20px' : '0')};
  border-top-right-radius: ${props => (props.last ? '20px' : '0')};
  border-bottom-right-radius: ${props => (props.last ? '20px' : '0')};
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  position: relative;
`;
const Circle = styled.div`
  position: absolute;
  border: 2px solid black;
  border-radius: 50%;
  height: 92%;
  width: 92%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  background: white;
  z-index: 1;
`;
const DayNumber = styled.div`
  position: relative;
  z-index: 2;
`;

const DAY_HEADERS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export function MonthDisplay({ month, startDate, onChangeStartDate, circledDate }: Props) {
  const today = moment().startOf('day');
  const weeks = buildWeeksGrid(month);
  return (
    <Container>
      <MonthName>{month.format('MMMM YYYY')}</MonthName>
      <Grid>
        {DAY_HEADERS.map((day, index) => (
          <Cell faded disabled key={index}>
            {day}
          </Cell>
        ))}
        {weeks.map((week: (Moment | undefined)[]) => {
          const first = week.find(day => day !== undefined)!;
          const last = [...week].reverse().find(day => day !== undefined)!;
          const active = startDate && startDate.week() === first.week();
          return (
            <React.Fragment key={first.format()}>
              {week.map((day, dayIndex) => {
                const circled = day && circledDate && day.isSame(circledDate, 'date');
                return (
                  <Cell
                    faded={day && day.isBefore(today)}
                    active={day && active}
                    key={dayIndex}
                    onClick={() => day && onChangeStartDate(day)}
                    first={day === first}
                    last={day === last}
                  >
                    {day && (
                      <>
                        {circled && <Circle />}
                        <DayNumber>{day.format('D')}</DayNumber>
                      </>
                    )}
                  </Cell>
                );
              })}
            </React.Fragment>
          );
        })}
      </Grid>
    </Container>
  );
}
