import styled from 'styled-components';

export const Background = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
`;

export const Wrapper = styled.div`
  padding: 4em 2em;
  max-width: 800px;
  margin: auto;
`;

export const FieldName = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 1em;
`;

const FormSectionBase = styled.div`
  display: grid;
  grid-gap: 1em 3em;
`;

export const FormSectionName = styled(FormSectionBase)`
  grid-template-columns: 2fr 1fr;
`;

export const FormSectionAvailability = styled(FormSectionBase)`
  grid-template-columns: 2fr 2fr 1fr 1fr;
  grid-gap: 1em 2em;
`;

export const FormSectionBasic = styled(FormSectionBase)`
  grid-template-columns: 1fr 1fr;
`;
