import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FinalButton } from '../../Components/FinalButton';
import { TextareaRHF } from '../../Components/Form';
import { HandBookInfo } from './Section/types';

type EditInputProps = {
  handbookRowInfo: string;
  selectedField: keyof HandBookInfo;
  onCancel?: () => void;
  onSave?: (input: string) => Promise<void>;
};

export const EditHandbookInput = ({
  handbookRowInfo,
  selectedField,
  onCancel,
  onSave,
}: EditInputProps) => {
  const form = useForm();
  const saveEdit = form.handleSubmit(async values => {
    await onSave?.(values[selectedField]);
    onCancel?.();
  });

  return (
    <FormProvider {...form}>
      <div>
        <TextareaRHF
          name={`${selectedField}`}
          controlProps={{ required: true }}
          placeholder={handbookRowInfo}
          defaultValue={handbookRowInfo}
          rules={{
            maxLength: {
              value: 10000,
              message:
                'Error: You have exceeded the maximum number of allowed characters for this field (10000 char).',
            },
          }}
        />
        <FinalButton type="submit" onClick={saveEdit}>
          Save
        </FinalButton>
        <FinalButton onClick={onCancel} kind="white">
          Cancel
        </FinalButton>
      </div>
    </FormProvider>
  );
};
