import styled from 'styled-components';
import { WIDGET_PADDING } from '../Widgets/Widget';
import { colors, Text } from '../../globalStyles';

export { Text };

export const Styles = {
  listDiv: styled.div<{ hoverable?: boolean }>`
    display: flex;
    align-items: center;
    padding: 5px ${WIDGET_PADDING};
    margin: 5px -${WIDGET_PADDING};

    &:hover {
      cursor: ${({ hoverable }) => (hoverable ? 'pointer' : 'default')};
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${colors.grey.light};
    }
  `,

  infoDiv: styled.div`
    text-align: start;
    color: ${colors.grey.dark};
    margin-right: 12px;
    & * {
      margin: 0px;
    }
  `,
} as const;
