import { compact } from 'lodash';
import * as qs from 'querystring';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '../../Components/Grid';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { Tabs } from '../../Components/Tabs';
import { Permission, TicketView, useTicketsQuery } from '../../graphQL';
import { TaskPageAside } from './TaskAside';
import { IEditTask, TaskPageContext, useTaskPageContext } from './tasks.context';
import { TaskTable } from './TaskTable';

export const TasksPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [refetchCount, setRefetchCount] = useState(0);
  const { hasPermission } = useCurrentProvider();
  const { openTaskId, tab } = qs.parse(location.search.replace(/^\?/, ''));
  const [editing, setEditing] = useState<IEditTask>(Number(openTaskId) || null);

  const refetch = () => setRefetchCount(i => i + 1);

  return (
    <div className="h-100 flex flex-column">
      <TaskPageContext.Provider value={{ editing, refetchCount, refetch, setEditing }}>
        <div className="bg-white pa4">
          <div className="flex justify-between">
            <h1>Tasks</h1>
          </div>
        </div>

        <div className="flex-1 overflow-hidden">
          <div className="h-100 flex flex-column">
            <Tabs
              onChange={t => {
                setEditing(null);
                history.replace(`${history.location.pathname}?tab=${t}`);
              }}
              initialTab={tab as string | undefined}
              config={{ tabpanel: TabPanelOverride }}
              tabs={compact([
                { title: 'Active', key: 'active', render: ActiveTasks },
                !hasPermission(Permission.TicketViewAll) && {
                  title: 'Assigned to me',
                  key: 'assignedToMe',
                  render: AssignedToMeTasks,
                },
                { title: 'Blocked', key: 'blocked', render: BlockedTasks },
                { title: 'Resolved', key: 'resolved', render: ResolvedTasks },
              ])}
            />
          </div>
        </div>
      </TaskPageContext.Provider>
    </div>
  );
};

const TabPanelOverride: React.FC = ({ children }) => (
  <div className="overflow-y-auto flex-1">{children}</div>
);

const pageFactory = (view: TicketView) => () => {
  const { editing, refetchCount, setEditing } = useTaskPageContext();
  const { data, refetch } = useTicketsQuery({ variables: { view } });

  useEffect(() => void refetch(), [refetch, refetchCount]);

  if (!data) return <LoadingPage className="mt4" />;
  return (
    <Grid className="h-100 overflow-hidden" gridTemplateColumns={editing ? '1fr 26rem' : '1fr'}>
      <div className="pa4 overflow-auto">
        <TaskTable
          data={data}
          onClick={t => setEditing(t.id)}
          analyticsName="tickets-table"
          headRow={editing === 'new' ? <EditHeadRow /> : undefined}
        />
      </div>
      {editing && (
        <div className="overflow-auto bl b--light-gray bw1">
          {/* key is important to re-render properly */}
          <TaskPageAside key={editing} tickets={data.tickets} />
        </div>
      )}
    </Grid>
  );
};

const EditHeadRow = () => (
  <div className="relative">
    <div className="b i pv3 ph4">New Task</div>
    <div className="bg-light-blue o-20 absolute absolute--fill" />
  </div>
);

const ActiveTasks = pageFactory(TicketView.Active);
const BlockedTasks = pageFactory(TicketView.Blocked);
const AssignedToMeTasks = pageFactory(TicketView.AssignedToMe);
const ResolvedTasks = pageFactory(TicketView.Resolved);
