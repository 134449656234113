import { toaster } from 'baseui/toast';
import { noop } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Accordion } from '../../Components/Accordion';
import { FinalButton } from '../../Components/FinalButton';
import { Dot } from '../../Components/Flair';
import { Checkbox } from '../../Components/Form';
import { Input } from '../../Components/Form/Input';
import { Link } from '../../Components/Links';
import { modalFactory } from '../../Components/Modal';
import { Text } from '../../globalStyles';
import { useVerifyUserMutation } from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { borderRadius } from '../../utils';
import { VerifiableUser, verificationColorMap } from './utils';

interface Props {
  onClose: (verified?: boolean) => void;
  user: VerifiableUser;
}

export const VerificationModal = ({ user, onClose }: Props) => {
  const [verify] = useVerifyUserMutation();
  const [nameMatch, setNameMatch] = useState(false);
  const [dobMatch, setDobMatch] = useState(false);

  const { selfie, verificationStatus, identificationCard, birthDate, email, id, customerId } = user;

  const handleClick = async (good: boolean) => {
    await verify({ variables: { id, verify: good } });
    onClose(good);
  };

  const canVerify = nameMatch && dobMatch;

  return (
    <Modal onClose={() => onClose()} isOpen>
      <div className="flex">
        <section className="flex-1">
          <div className="pa3">
            <h2 className="mb4">Verification Needed</h2>
            <Text.label className="mb2">Legal Name</Text.label>
            <Checkbox checked={nameMatch} onChange={setNameMatch}>
              {getFullName(user)}
            </Checkbox>
            <Text.label className="mt3 mb2">Date of Birth</Text.label>
            <Checkbox checked={dobMatch} onChange={setDobMatch}>
              {moment(birthDate ?? undefined).format('MM/DD/YYYY')}
            </Checkbox>
          </div>
          <div className="ph3 pb3 bb b--light-silver">
            <FinalButton
              className="w-100"
              kind={canVerify ? 'primary' : 'outline_black'}
              disabled={!canVerify}
              onClick={() => handleClick(true)}
            >
              Confirm
            </FinalButton>
          </div>

          <div className="ph3">
            <Accordion
              borders
              items={[
                {
                  title: 'Reject Identification',
                  content: (
                    <div>
                      <Input
                        onChange={noop}
                        value={email}
                        id={email}
                        onFocus={e => {
                          e.target.select();
                          document.execCommand('copy');
                          toaster.info('email copied to clipboard', {});
                        }}
                      />
                      <FinalButton
                        className="w-100 mt3"
                        kind="outline_danger"
                        onClick={() => handleClick(false)}
                      >
                        Mark as Rejected
                      </FinalButton>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </section>
        <section className="bl pa3 bg-light-gray flex-1 b--light-silver">
          <div className="mb3">
            <p>
              <strong>Name: </strong>
              <Link target="_blank" to={`/users/${id}`}>
                {getFullName(user)}
              </Link>
            </p>
            <p>
              <strong>Patient ID: </strong> {customerId}
            </p>
            <div className="flex">
              <strong className="mr2">Status: </strong>
              <Dot color={verificationColorMap[verificationStatus]}>{verificationStatus}</Dot>
            </div>
          </div>
          {selfie ? (
            <>
              <img className="mw5" src={selfie.url} alt="selfie" />
              <p>submitted {moment(selfie.createdAt).format('MM/DD/YYYY hh:mma')}</p>
            </>
          ) : (
            <p className="i">No Selfie</p>
          )}
          {identificationCard ? (
            <>
              <img className="mw5" src={identificationCard.url} alt="identification" />
              <p>submitted {moment(identificationCard.createdAt).format('MM/DD/YYYY hh:mma')}</p>
            </>
          ) : (
            <p className="i">No ID</p>
          )}
        </section>
      </div>
    </Modal>
  );
};

const Modal = modalFactory({
  style: { ...borderRadius('4px'), overflow: 'hidden', width: '100%', maxWidth: '50rem' },
});
