import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartConfiguration,
  LinearScale,
  Tooltip as ToolTipElement,
} from 'chart.js';
import React, { useEffect, useRef } from 'react';
import { colors, Text } from '../../../globalStyles';
import { Dot } from '../../../Components/Flair';
import { Tooltip } from '../../../Components/Tooltip';
import { CareType } from '../../../graphQL';

type SessionUtilization = {
  utilized: number;
  scheduled: number;
  remaining: number;
  sessionsLimit: number;
};

interface SessionUtilizationProps {
  careType: CareType;
  utilized: number;
  scheduled: number;
  remaining: number;
  sessionsLimit: number;
}

Chart.register(BarController, CategoryScale, LinearScale, BarElement, ToolTipElement);

export const SessionsUtilizationWidget = ({
  careType,
  utilized,
  remaining,
  scheduled,
  sessionsLimit,
}: SessionUtilizationProps): JSX.Element => {
  const careTypeCopy = careType === CareType.Psychiatry ? 'psychiatric NP' : 'therapy';

  const chartRef = useRef<HTMLCanvasElement>(null);

  const tooltipCopy = {
    utilized:
      'Total number of sessions that have been marked as completed, no-showed, or cancelled/rescheduled within 24 hours of the scheduled start time.',
    scheduled: 'Upcoming or recent sessions that have been booked but not yet completed.',
  };

  useEffect(() => {
    if (!chartRef.current) return;

    const chart = new Chart(
      chartRef.current,
      chartConfig({ data: { utilized, scheduled, remaining, sessionsLimit } })
    );
    return () => {
      chart.destroy();
    };
  }, [utilized, scheduled, remaining]);

  const StatusItem = ({
    color,
    label,
    value,
    toolTipCopy,
  }: {
    color: string;
    label: string;
    value: number;
    toolTipCopy?: string;
  }) => (
    <div className="flex flex-column items-center justify-center">
      <div className="flex items-center">
        <Dot color={color} size="1rem">
          <Text.h2>{label}</Text.h2>
          {toolTipCopy && (
            <Tooltip className="ml2" content={toolTipCopy} iconType="iconsQuestionSvg" size={18} />
          )}
        </Dot>
      </div>
      <Text.h1Bold>{value}</Text.h1Bold>
    </div>
  );

  return (
    <div className="w-50 pl5">
      <div>
        <Text.h3>Session</Text.h3>
        <Text.bodySmallGrey>
          Sessions ({careTypeCopy}) utilized within the current academic year.
        </Text.bodySmallGrey>
      </div>
      <div className="pt5">
        <div className="flex justify-between">
          <StatusItem
            toolTipCopy={tooltipCopy.utilized}
            color={colors.success}
            label="Utilized"
            value={utilized}
          />
          <div className="br bl b--gray" />
          <StatusItem
            toolTipCopy={tooltipCopy.scheduled}
            color={colors.warning}
            label="Scheduled"
            value={scheduled}
          />
          <div className="br bl b--gray" />
          <StatusItem color={colors.grayTextBg} label="Remaining" value={remaining} />
        </div>
        <div>
          <canvas ref={chartRef} />
        </div>
      </div>
    </div>
  );
};

type BorderRadius = number | { [key: string]: number } | undefined;

function getBorderRadius(data: SessionUtilization) {
  let scheduledBorderRadius: BorderRadius;

  if (data.utilized === 0 && data.remaining === 0) {
    scheduledBorderRadius = 100;
  } else if (data.utilized > 0 && data.remaining === 0) {
    scheduledBorderRadius = { topRight: 100, bottomRight: 100 };
  } else if (data.utilized === 0 && data.remaining > 0) {
    scheduledBorderRadius = { topLeft: 100, bottomLeft: 100 };
  } else {
    scheduledBorderRadius = undefined;
  }

  return {
    sessions: {
      borderRadius:
        data.utilized === 0 || data.scheduled !== 0 ? { topLeft: 100, bottomLeft: 100 } : 100,
    },
    scheduled: {
      borderRadius: scheduledBorderRadius,
    },
    remaining: {
      borderRadius:
        data.utilized === 0 && data.scheduled === 0 ? 100 : { topRight: 100, bottomRight: 100 },
    },
  };
}

const chartConfig = ({ data }: { data: SessionUtilization }): ChartConfiguration<'bar'> => {
  const borderRadiusRules = getBorderRadius(data);

  if (!data.remaining && !data.scheduled && !data.utilized) {
    return { type: 'bar', data: { labels: [''], datasets: [] }, options: {} };
  }

  return {
    type: 'bar',
    data: {
      labels: [''],
      datasets: [
        {
          label: 'Sessions',
          data: [data.utilized],
          backgroundColor: colors.success,
          barThickness: 'flex',
          maxBarThickness: 18,
          borderSkipped: false,
          borderRadius: borderRadiusRules.sessions.borderRadius,
        },
        {
          label: 'Scheduled',
          data: [data.scheduled],
          backgroundColor: colors.warning,
          barThickness: 'flex',
          maxBarThickness: 18,
          borderSkipped: false,
          borderRadius: borderRadiusRules.scheduled.borderRadius,
        },
        {
          label: 'Remaining',
          data: [data.remaining],
          backgroundColor: colors.grayTextBg,
          barThickness: 'flex',
          maxBarThickness: 18,
          borderSkipped: false,
          borderRadius: borderRadiusRules.remaining.borderRadius,
        },
      ],
    },
    options: {
      plugins: {
        // @ts-ignore
        footerLabel: {
          label: `${data.sessionsLimit} session limit`,
        },
      },
      indexAxis: 'y',
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          display: false,
          min: 0,
          max: data.sessionsLimit,
        },
        y: {
          stacked: true,
          beginAtZero: true,
          display: false,
        },
      },
      layout: {
        padding: {
          bottom: 0,
        },
      },
    },

    plugins: [footerPlugin],
  };
};

const footerPlugin = {
  id: 'footerLabel',
  afterDraw: (chart: any, args: any, options: any) => {
    const {
      ctx,
      chartArea: { bottom, right },
    } = chart;
    const label = options.label || '';
    ctx.save();
    ctx.textAlign = 'right';
    ctx.textBaseline = 'bottom';
    ctx.font = '12px Arial';
    ctx.fillStyle = 'black';
    ctx.fillText(label, right - 5, bottom - 35);
    ctx.restore();
  },
};
