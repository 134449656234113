import { Modal } from 'baseui/modal';
import { Tag } from 'baseui/tag';
import { capitalize, groupBy, pick } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useEvents } from '../../Components/Events/EventsProvider';
import { FinalButton } from '../../Components/FinalButton';
import { CheckboxListRHF, FormControl, Input, SelectRHF, Textarea } from '../../Components/Form';
import { Icon } from '../../Components/Icons';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { ColorLookup, Modal as ModalStyle, Text } from '../../globalStyles';
import {
  CampusResourcesQuery,
  OrganizationResourceInput,
  ResourceTag,
  ResourceType,
  useCampusResourcesQuery,
  useCreateOrUpdateOrganizationResourceMutation,
  useDeleteOrganizationResourceMutation,
} from '../../graphQL';
import { UnexpectedError } from '../Shared';

type Resource = CampusResourcesQuery['organization']['resources'][number];

interface Props {
  organizationId: number;
}

const TEXT_WIDTH = 468;

export function CampusResources({ organizationId }: Props) {
  const { data, loading, error, refetch } = useCampusResourcesQuery({
    variables: { organizationId },
  });
  const [modalResource, setModalResource] = useState<Resource | 'new' | null>(null);

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const { resources, resourcesUpdatedAt, resourcesUpdatedBy } = data.organization;

  let lastUpdated = null;
  if (resourcesUpdatedAt && resourcesUpdatedBy) {
    lastUpdated = `by ${resourcesUpdatedBy.name} at ${moment(resourcesUpdatedAt).format(
      'M/D/YYYY'
    )}`;
  }
  const emergencyResources = resources.filter(i => i.resourceType === ResourceType.Emergency);
  const otherResourcesByType = groupBy(
    resources.filter(i => i.resourceType !== ResourceType.Emergency),
    resource => resource.resourceType
  );

  const handleEditResource = (id: number) => {
    setModalResource(resources.find(i => i.id === id)!);
  };

  return (
    <div className="mh4 mv4">
      <div className="flex flex-row items-center mb3">
        <Text.h1 className="mr4">Campus Resources</Text.h1>
        <FinalButton pill iconLeft="iconsWhitePlusSvg" onClick={() => setModalResource('new')}>
          Add Campus Resource
        </FinalButton>
      </div>
      <Text.body className="mb3" style={{ width: TEXT_WIDTH }}>
        Add &amp; update campus resources that are displayed to students within their Mantra Patient
        Portal.
      </Text.body>
      {lastUpdated && (
        <Text.bodySmallGrey className="i">Last updated {lastUpdated}</Text.bodySmallGrey>
      )}
      <hr className="mt4 mb4" />
      <div style={{ width: TEXT_WIDTH }}>
        {data.organization.resources.length === 0 && <Text.body>No resources</Text.body>}
        {emergencyResources.length > 0 && (
          <ResourceList
            resources={emergencyResources}
            kind="danger"
            title={resourceTypeNames[ResourceType.Emergency] ?? 'Unknown Title'}
            onEditResource={handleEditResource}
          />
        )}
        {Object.entries(otherResourcesByType).map(([type, items]) => (
          <ResourceList
            resources={items}
            title={resourceTypeNames[type as ResourceType] ?? 'Unknown Resource Type'}
            key={type}
            onEditResource={handleEditResource}
          />
        ))}
      </div>
      {modalResource && (
        <ResourceModal
          organizationId={organizationId}
          resource={modalResource === 'new' ? undefined : modalResource}
          onClose={() => setModalResource(null)}
          refetch={refetch}
        />
      )}
    </div>
  );
}

interface ResourceListProps {
  title: string;
  kind?: ColorLookup;
  resources: Resource[];
  onEditResource: (id: number) => void;
}

type SubtypeTuple = [string | null, Resource[]];

function ResourceList({ resources, kind, title, onEditResource }: ResourceListProps) {
  const bySubtype = groupBy(resources, resource => resource.resourceSubtype);
  const bySubtypeArr: SubtypeTuple[] = Object.entries(bySubtype)
    // test for the string "null" because this was the key of an object
    .map(([subtype, items]): SubtypeTuple => [subtype === 'null' ? null : subtype, items])
    .sort((a, b) => {
      // put nulls first
      if (!a[0]) return -1;
      if (!b[0]) return 1;
      return a[0].localeCompare(b[0]);
    });
  return (
    <div className="mb4">
      <Text.h2 kind={kind} className="mb3">
        {title}
      </Text.h2>
      {bySubtypeArr.map(([subtype, items]) => (
        <div key={String(subtype)}>
          {subtype && <Text.label className="mb3">{subtype}</Text.label>}
          {items.map(resource => (
            <div className="flex flex-row items-start" key={resource.id}>
              <ResourceDisplay resource={resource} />
              <IconButton onClick={() => onEditResource(resource.id)}>
                <Icon icon="iconsPencilSvg" size={16} />
              </IconButton>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function ResourceDisplay({ resource }: { resource: Resource }) {
  const listedKeys: (keyof Resource)[] = [
    'contact',
    'location',
    'phone',
    'email',
    'hours',
    'website',
  ];
  return (
    <div className="mr2 mb4">
      <Text.bodyBold>{resource.name}</Text.bodyBold>
      <div style={{ marginLeft: -5 }}>
        {resource.tags.map(tag => (
          <Tag
            kind="neutral"
            closeable={false}
            key={tag}
            overrides={{ Root: { style: { margin: 0 } } }}
          >
            {tagNames[tag]}
          </Tag>
        ))}
      </div>
      <Text.body className="mb3" style={{ whiteSpace: 'pre-wrap' }}>
        {resource.description}
      </Text.body>
      {listedKeys
        .filter(fieldKey => !!resource[fieldKey])
        .map(fieldKey => (
          <Text.body key={fieldKey}>
            <span className="b">{capitalize(fieldKey)}:</span> {resource[fieldKey]}
          </Text.body>
        ))}
    </div>
  );
}

interface ResourceModalProps {
  organizationId: number;
  resource?: Resource;
  onClose: () => void;
  refetch: () => Promise<unknown>;
}

interface FormFields {
  name: string;
  resourceType?: ResourceType;
  resourceSubtype?: string | null;
  tags: string[];
  contact?: string | null;
  location?: string | null;
  phone?: string | null;
  email?: string | null;
  hours?: string | null;
  website?: string | null;
  description?: string | null;
}

function ResourceModal({ organizationId, resource, onClose, refetch }: ResourceModalProps) {
  const fieldNames = [
    'name',
    'resourceType',
    'resourceSubtype',
    'tags',
    'contact',
    'location',
    'phone',
    'email',
    'hours',
    'website',
    'description',
  ];
  const { track } = useEvents();
  const [mutate] = useCreateOrUpdateOrganizationResourceMutation({
    onCompleted: d => {
      if (resource?.id) {
        track('campus-resource.edited', {
          organizationId,
          resourceId: d.createOrUpdateOrganizationResource.id,
        });
      } else {
        track('campus-resource.added', {
          organizationId,
          resourceId: d.createOrUpdateOrganizationResource.id,
        });
      }
    },
  });
  const [saving, setSaving] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const form = useForm<FormFields>({
    defaultValues: {
      tags: [],
      ...pick(resource, fieldNames),
    },
  });
  const onSubmit = form.handleSubmit(async values => {
    setSaving(true);
    try {
      await mutate({
        variables: {
          organizationId,
          resource: { id: resource?.id, ...pick(values, fieldNames) } as OrganizationResourceInput,
        },
      });
      await refetch();
      onClose();
    } finally {
      setSaving(false);
    }
  });
  const fields = form.watch();
  const getSubtypes = () => {
    if (fields.resourceType) {
      const subtypesArr = subtypes[fields.resourceType];
      if (subtypesArr) {
        return subtypesArr.map(i => ({
          label: i,
          id: i,
        }));
      }
    }
    return [];
  };

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      {confirmDelete && resource && (
        <ConfirmDeleteModal
          resourceId={resource.id}
          organizationId={organizationId}
          onClose={() => setConfirmDelete(false)}
          onDelete={onClose}
          refetch={refetch}
        />
      )}
      <ModalStyle.body>
        <FormProvider {...form}>
          <Text.h2 className="mb4">{resource ? 'Edit' : 'Add'} Campus Resource</Text.h2>
          <FormControl name="name" label="Resource Name" required>
            <Input name="name" ref={form.register({ required: true })} />
          </FormControl>
          <FormControl name="resourceType" label="Category" required>
            <SelectRHF
              name="resourceType"
              options={Object.entries(resourceTypeNames).map(([category, name]) => ({
                id: category,
                label: name,
              }))}
              rules={{ required: true }}
            />
          </FormControl>
          <FormControl name="resourceSubtype" label="Sub-category">
            <SelectRHF clearable name="resourceSubtype" options={getSubtypes()} />
          </FormControl>
          <FormControl name="tags" label="Tags">
            <CheckboxListRHF
              options={Object.entries(tagNames).map(([id, label]) => ({ id, label }))}
              name="tags"
            />
          </FormControl>
          <hr className="mt4 mb4" />
          <Text.h2 className="mb2">Contact &amp; Details</Text.h2>
          <Text.body className="mb3">
            All fields are optional -- fill out any applicable fields below.
          </Text.body>
          <FormControl name="contact" label="Contact Person">
            <Text.bodySmallGrey>
              If there is a specific person to contact in regards to this resource, please add their
              name.
            </Text.bodySmallGrey>
            <Input name="contact" ref={form.register} placeholder="Jane Smith" />
          </FormControl>
          <FormControl name="location" label="Location / Address">
            <Input name="location" ref={form.register} placeholder="Address / campus building" />
          </FormControl>
          <FormControl name="phone" label="Phone">
            <Text.bodySmallGrey>
              If this resource can be contacted by phone, please add the number number below.
            </Text.bodySmallGrey>
            <Input name="phone" ref={form.register} placeholder="603-325-2312" />
          </FormControl>
          <FormControl name="email" label="Email Address">
            <Input name="email" ref={form.register} placeholder="resource@college.edu" />
          </FormControl>
          <FormControl name="hours" label="Hours">
            <Input name="hours" ref={form.register} />
          </FormControl>
          <FormControl name="website" label="Website">
            <Input
              name="website"
              ref={form.register}
              placeholder="https://college.edu/counseling"
            />
          </FormControl>
          <FormControl name="description" label="Additional Details">
            <Text.bodySmallGrey>Short description of offered resource</Text.bodySmallGrey>
            <Textarea
              name="description"
              ref={form.register}
              placeholder="Crisis, consultation, and counseling services"
            />
          </FormControl>
          <hr className="mt4 mb4" />
          <Text.h2 className="mb2">Preview Campus Resource</Text.h2>
          <Text.bodySmallGrey>
            View how this resource will be displayed to students.
          </Text.bodySmallGrey>
          <PreviewBox>
            {fields.name ? (
              <ResourceDisplay resource={fields as Resource} />
            ) : (
              <Text.bodyGrey className="i">
                Fill out the fields above to view how this campus resources will be displayed to
                patients.
              </Text.bodyGrey>
            )}
          </PreviewBox>
          <FinalButton
            className="w-100"
            onClick={onSubmit}
            loading={saving}
            disabled={!fields.resourceType || !fields.name}
          >
            Save
          </FinalButton>
          {resource && (
            <FinalButton
              kind="minimal_danger"
              className="w-100 mt2"
              onClick={() => setConfirmDelete(true)}
            >
              Delete
            </FinalButton>
          )}
        </FormProvider>
      </ModalStyle.body>
    </Modal>
  );
}
interface ConfirmDeleteModalProps {
  onClose: () => void;
  onDelete: () => void;
  refetch: () => Promise<unknown>;
  resourceId: number;
  organizationId: number;
}

function ConfirmDeleteModal({
  onClose,
  resourceId,
  refetch,
  organizationId,
  onDelete,
}: ConfirmDeleteModalProps) {
  const { track } = useEvents();
  const [deleteResource] = useDeleteOrganizationResourceMutation({
    onCompleted: _ => track('campus-resource.deleted', { resourceId, organizationId }),
  });
  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    setDeleting(true);
    try {
      await deleteResource({ variables: { resourceId } });
      await refetch();
      onDelete();
    } finally {
      setDeleting(false);
    }
  };
  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      <ModalStyle.body>
        <Text.h2 className="mb3">Delete this resource?</Text.h2>
        <Text.body className="mb3">
          This resource will be permanently removed and will no longer be visible to students.
        </Text.body>
        <FinalButton
          kind="minimal_danger"
          className="w-100"
          onClick={handleDelete}
          loading={deleting}
        >
          Delete
        </FinalButton>
        <ModalStyle.closeLink onClick={onClose}>Cancel</ModalStyle.closeLink>
      </ModalStyle.body>
    </Modal>
  );
}

const resourceTypeNames: Partial<Record<ResourceType, string>> = {
  [ResourceType.AcademicAndAccessibility]: 'Academic Support & Accessibility',
  [ResourceType.Additional]: 'Additional Resources',
  [ResourceType.Diversity]: 'Diversity & Inclusion',
  [ResourceType.Emergency]: 'Campus Crisis Resources',
  [ResourceType.Health]: 'Health & Wellness',
  [ResourceType.Safety]: 'Safety',
};

const subtypes: Partial<Record<ResourceType, string[]>> = {
  [ResourceType.AcademicAndAccessibility]: [
    'Academic Support',
    'ADHD Evaluation',
    'Disability & Accessibility',
    'Career Services',
  ],
  [ResourceType.Additional]: ['International Students', 'Resident Life'],
  [ResourceType.Diversity]: ['Discrimination & Bias', 'LGBTQ', 'Cultural'],
  [ResourceType.Emergency]: ['On-Campus Resources', 'Crisis Intervention'],
  [ResourceType.Health]: [
    'On-Campus Resources',
    'General Wellness',
    'Mental Health',
    'Dietary Resources',
  ],
  [ResourceType.Safety]: ['Domestic Violence', 'Sexual Misconduct & Assault', 'Substance Abuse'],
};

const tagNames: Record<ResourceTag, string> = {
  [ResourceTag.ByAppointment]: 'By Appointment',
  [ResourceTag.InPerson]: 'In Person',
  [ResourceTag.NonEmergencies]: 'Non-emergencies',
  [ResourceTag.Open247]: 'Available 24/7',
  [ResourceTag.Telehealth]: 'Telehealth',
};

const PreviewBox = styled.div`
  background-color: rgba(208, 225, 253, 1);
  padding: 20px;
  border-radius: 4px;
  white-space: pre-wrap;
  margin: 20px 0px 30px 0px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 6px;
  width: 16px;
  padding: 0;
  flex-shrink: 0;
`;
