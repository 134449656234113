import { findKey } from 'lodash';
import { generatePath, matchPath, useLocation } from 'react-router-dom';

import type { ExtractRouteParams } from 'react-router';

export { Redirect, useLocation, useParams } from 'react-router-dom';

export * from './utils/navigate/useExternalNavigate';
export * from './utils/navigate/useNavigate';
export { useSmartNavigate } from './utils/navigate/useSmartNavigate';

export const routes = {
  patientEntry: '/embed/patient-entry',
  patientBooking: '/embed/patient/:patientId/book',
  patientSkillsManage: '/embed/patient/:patientId/skills-manage',

  legacyHome: '/',
  legacyLogin: '/login',
} as const;

export const publicRoutes = {
  hubReferral: '/public-embed/hub-referral',
} as const;

type RouteKeys = keyof typeof routes;

export type RouteParams<RouteName extends RouteKeys> = ExtractRouteParams<
  typeof routes[RouteName],
  string
>;

export const useCurrentRouteName = (): RouteKeys | 'notFound' => {
  const location = useLocation();
  const searchableRoutes = Object.values(routes).map(routePath => `embed/${routePath}`);

  const matchedRoute = matchPath(location.pathname, searchableRoutes);
  if (!matchedRoute) {
    return 'notFound';
  }

  const matchedRouteName =
    findKey(routes, routePath => routePath === matchedRoute.path) ?? 'notFound';

  return matchedRouteName as RouteKeys | 'notFound';
};

export const useCurrentRouteSearch = (): Record<string, string> => {
  const location = useLocation();

  const search = new URLSearchParams(location.search);

  // Convert the search entries iterator to an object.
  return Object.fromEntries(search.entries());
};

export const getRoute = <RouteName extends RouteKeys>(
  routeName: RouteName,
  paramValues: RouteParams<RouteName>,
  searchParamValues?: Record<string, string>
): string => {
  const routePath = routes[routeName];

  // @ts-expect-error - The type declarations in generatePath() are not quite correct.
  const fullPath = generatePath(routePath, paramValues);

  if (searchParamValues) {
    const searchParams = new URLSearchParams(searchParamValues);
    return `${fullPath}?${searchParams.toString()}`;
  }

  return fullPath;
};
