import { Avatar } from 'baseui/avatar';
import { ModalBody } from 'baseui/modal';
import { flatMap } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { usePossiblyExpanded } from '../../Hooks';
import { Text } from '../../globalStyles';
import { TraitCategory } from '../../graphQL';
import { borderRadius, cx } from '../../utils';
import { Checkbox } from '../Form';
import { InlineSVG, icons } from '../Icons';
import { Tag } from '../Labels';
import { modalFactory } from '../Modal';
import { useProviderNetworkContext } from './ProviderNetworkContext';
import { ProviderNetworkProfile } from './ProviderNetworkProfile';
import * as Styles from './Styles';
import { getDisplayableType } from './providerNetworkUtils';
import { SearchProvider } from './types';
import { Tooltip } from '../Tooltip';

const Modal = modalFactory({ style: { ...borderRadius('4px'), maxWidth: '774px', width: '100%' } });

export type RenderAvailability = (p: { provider: SearchProvider }) => React.ReactElement | null;

type ProviderRowItemProps = {
  provider: SearchProvider;
  providerTopLabel?: string;
  renderAvailability: RenderAvailability;
  gridSmall?: boolean;
  canSelectProviders?: boolean;
};

export const ProviderNetworkRow = ({
  provider,
  providerTopLabel,
  gridSmall,
  canSelectProviders,
  renderAvailability: Availability,
}: ProviderRowItemProps) => {
  const { selectedProviders, dispatchSelectedProviders } = useProviderNetworkContext();

  const [modalOpen, setModalOpen] = useState(false);

  const flatTraits = flatMap(
    provider.traits.filter(t => t.category === TraitCategory.Specialties),
    t => t.values
  );

  const traits = usePossiblyExpanded(flatTraits, 4);

  const onSelect = (checked: boolean) => {
    dispatchSelectedProviders({ type: checked ? 'check' : 'uncheck', provider });
  };

  const selected = selectedProviders.has(provider.id);

  return (
    <>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalBody>
          <ProviderNetworkProfile providerId={provider.id} withSimpleAvailability />
        </ModalBody>
      </Modal>
      <Styles.ProviderRowItem
        borderBottom
        eligibleForSchedulingPromotion={provider.eligibleForSchedulingPromotion}
        key={provider.id}
        className={cx({ selected, gridSmall })}
        onClick={canSelectProviders ? () => onSelect(!selected) : undefined}
      >
        <div className="flex gap-2 flex-row">
          {canSelectProviders && (
            <Checkbox className="mt1" checked={selected} onChange={onSelect} />
          )}
          <Avatar
            size="2.5rem"
            overrides={{ Avatar: { style: { maxWidth: '2.5rem' } } }}
            src={provider.portrait?.url ?? icons.assetsDefaultPicturePng}
            name={provider.name}
          />
          <div className="ml3 flex-1">
            {provider.eligibleForSchedulingPromotion && (
              <div className="flex">
                <Text.label kind="primary" className="nowrap truncate pr2">
                  SUGGESTED
                </Text.label>
                <Tooltip
                  placement="topRight"
                  content={
                    <div style={{ maxWidth: 164 }} className="flex tc">
                      Suggested providers have more consistent availability and faster response
                      times.
                    </div>
                  }
                >
                  <InlineSVG icon="alert-circle" kind="primary" size={18} />
                </Tooltip>
              </div>
            )}
            {providerTopLabel && (
              <LabelContainer className="br-pill mb2">
                <Text.label className="nowrap truncate" kind="primary">
                  {providerTopLabel}
                </Text.label>
              </LabelContainer>
            )}
            <h4>{provider.name}</h4>
            <Text.bodySmall>{getDisplayableType(provider)}</Text.bodySmall>
            <Text.linkButton
              className="b mb3"
              onClick={e => {
                e.stopPropagation();
                setModalOpen(true);
              }}
            >
              View profile
            </Text.linkButton>
            <Text.bodySmall className="mb3">
              {provider.funFact.slice(0, 120)}
              {provider.funFact.length > 120 && '...'}{' '}
            </Text.bodySmall>
            <div className="flex flex-wrap gap-2">
              {traits.current.map(t => (
                <Tag kind="gray" key={t}>
                  <Text.label className="nowrap truncate">{t}</Text.label>
                </Tag>
              ))}
            </div>
          </div>
        </div>
        {/* fill 1fr empty space */}
        <div />
        <Availability provider={provider} />
      </Styles.ProviderRowItem>
    </>
  );
};

const LabelContainer = styled.div`
  background-color: rgba(27, 80, 225, 0.08);
  padding: 0.125rem 0.75rem 0.125rem 0.75rem;
  width: fit-content;
  max-width: 32rem;
`;
