import { Modal } from 'baseui/modal';
import moment from 'moment';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FinalButton } from '../../../Components/FinalButton';
import { InputRHF, SelectRHF } from '../../../Components/Form';
import { Modal as MantraModal, Text } from '../../../globalStyles';
import {
  PatientRelationshipToSubscriber,
  usePayersQuery,
  useSetUserInsuranceMutation,
} from '../../../graphQL';
import { stateOptions } from '../../../states';
import { useDrilldownContext } from '../helpers';

interface Props {
  onClose: () => void;
}

interface FormFields {
  payerId: number;
  memberId: string;
  groupNumber: string;
  state: string;
  patientRelationshipToSubscriber: PatientRelationshipToSubscriber;
  subscriberFirstName: string;
  subscriberLastName: string;
  subscriberDateOfBirth: string;
  subscriberGender: string;
}

const required = 'This field is required.';

export function EditInsuranceDetails({ onClose }: Props) {
  const { user, refetch } = useDrilldownContext();
  const { data } = usePayersQuery();
  const payerOptions = (data?.payers ?? []).map(i => ({ id: i.id, label: i.name }));
  const { insuranceDetails } = user;
  const form = useForm<FormFields>({
    defaultValues: {
      payerId: insuranceDetails?.payer?.id ?? undefined,
      memberId: insuranceDetails?.memberId ?? undefined,
      groupNumber: insuranceDetails?.groupNumber ?? undefined,
      state: insuranceDetails?.state,
      patientRelationshipToSubscriber: insuranceDetails?.patientRelationshipToSubscriber,
      subscriberFirstName: insuranceDetails?.subscriberFirstName ?? undefined,
      subscriberLastName: insuranceDetails?.subscriberLastName ?? undefined,
      subscriberDateOfBirth: insuranceDetails?.subscriberDateOfBirth ?? undefined,
      subscriberGender: insuranceDetails?.subscriberGender ?? undefined,
    },
  });
  const [mutate, mutation] = useSetUserInsuranceMutation();
  if (!data) return null;

  const onSave = form.handleSubmit(async values => {
    const result = await mutate({
      variables: {
        userId: user.id,
        insurance: {
          ...values,
          ...(values.patientRelationshipToSubscriber === PatientRelationshipToSubscriber.Self && {
            subscriberFirstName: user.firstName,
            subscriberLastName: user.lastName,
            subscriberDateOfBirth: moment(user.birthDate!).format('YYYY-MM-DD'),
            subscriberGender: undefined,
          }),
          firstName: user.firstName,
          lastName: user.lastName,
          birthDate: moment(user.birthDate!).format('YYYY-MM-DD'),
        },
      },
    });
    await refetch();
    if (result.data?.setUserInsurance.valid === true) {
      onClose();
    }
  });

  const relationship = form.watch('patientRelationshipToSubscriber');

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      <MantraModal.body>
        <FormProvider {...form}>
          <MantraModal.header>Edit Insurance Details</MantraModal.header>
          <MantraModal.body>
            <SelectRHF
              controlProps={{ label: 'Payer' }}
              name="payerId"
              options={payerOptions}
              placeholder="Select Insurance..."
            />
            <InputRHF
              controlProps={{ label: 'Member ID' }}
              name="memberId"
              rules={{ required }}
              placeholder="Member ID"
            />
            <InputRHF
              controlProps={{ label: 'Group Number' }}
              name="groupNumber"
              placeholder="Group Number"
            />
            <SelectRHF
              controlProps={{ label: 'State' }}
              name="state"
              options={stateOptions}
              placeholder="Select State..."
            />
            <SelectRHF
              controlProps={{ label: 'Patient Relationship to Subscriber' }}
              name="patientRelationshipToSubscriber"
              options={relationshipOptions}
              placeholder="Select Relationship..."
            />
            {relationship && relationship !== PatientRelationshipToSubscriber.Self && (
              <>
                <InputRHF
                  controlProps={{ label: 'Subscriber First Name' }}
                  name="subscriberFirstName"
                  rules={{ required }}
                />
                <InputRHF
                  controlProps={{ label: 'Subscriber Last Name' }}
                  name="subscriberLastName"
                  rules={{ required }}
                />
                <InputRHF
                  controlProps={{ label: 'Subscriber Date of Birth' }}
                  name="subscriberDateOfBirth"
                  rules={{ required }}
                />

                <SelectRHF
                  controlProps={{ label: 'Subscriber Gender' }}
                  name="subscriberGender"
                  options={genderOptions}
                />
              </>
            )}
            {mutation.data?.setUserInsurance.valid === false && (
              <Text.bodyBold kind="danger" className="mb3">
                The patient’s insurance was updated successfully, but the eligibility check failed.
                You should probably recheck the data above and try again.
              </Text.bodyBold>
            )}
            <FinalButton className="w-100" onClick={onSave} loading={mutation.loading}>
              Save
            </FinalButton>
          </MantraModal.body>
        </FormProvider>
      </MantraModal.body>
    </Modal>
  );
}

const relationshipOptions = Object.keys(PatientRelationshipToSubscriber).map(rel => {
  let label = rel[0];
  for (let i = 1; i < rel.length; i += 1) {
    const letter = rel[i];
    if (letter.toUpperCase() === letter) label += ' ';
    label += letter;
  }
  return { id: rel, label };
});

const genderOptions = ['Male', 'Female', 'Other'].map(i => ({ id: i, label: i }));
