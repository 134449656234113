import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ToggleRHF } from '../../../Components/Form';
import { Text } from '../../Users/styles';
import { Flag } from './types';

const setupToggles: Flag[] = [{ key: 'completeProfile', label: 'Complete Profile' }];

export const setupToggleKeys = setupToggles.map(t => t.key);

export const AccountSetup = () => {
  const { watch, setValue } = useFormContext();

  const v = watch(setupToggleKeys);

  React.useEffect(() => {
    ['medicalHistory', 'mockMessages', 'withPharmacy'].forEach(k => setValue(k, false));
    ['numAssessments', 'numAppointments'].forEach(k => setValue(k, 0));
  }, [v, setValue]);

  return (
    <div className="mb3">
      <Text.h2 className="mb3">Account Setup</Text.h2>

      {setupToggles.map(f => (
        <ToggleRHF key={f.key} className="mb3" name={f.key}>
          {f.label}
        </ToggleRHF>
      ))}
    </div>
  );
};
