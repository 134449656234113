import { KIND } from 'baseui/notification';
import { LocationDescriptor } from 'history';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Icon } from '../../Components/Icons';
import { CenteredNotification } from '../../Components/Notification';
import { TwoFactor } from '../../Components/TwoFactor';
import { Text } from '../../globalStyles';
import { useAdminEnterSecondFactorMutation, useResendTwoFactorCodeMutation } from '../../graphQL';
import { setAuthToken, setDeviceToken } from '../../token';
import { isLoggedIn } from '../../utils';
import { AuthError } from './AuthError';
import { LinkWrapper } from './Style';

type TwoFactorProps = {
  firstFactorToken: string;
  phoneLastFour: string;
  nextPage: LocationDescriptor;
  onBack?: () => void;
};

export const TwoFactorAuth = ({
  firstFactorToken,
  phoneLastFour,
  nextPage,
  onBack,
}: TwoFactorProps) => {
  const history = useHistory();
  const [error, setError] = useState<string>();

  const [enterSecondFactor, { error: verifyError }] = useAdminEnterSecondFactorMutation();
  const [resendCode, { error: resendError }] = useResendTwoFactorCodeMutation({
    variables: { firstFactorToken },
  });

  const onVerify = async (code: string) => {
    setError('');
    const { data } = await enterSecondFactor({
      variables: { firstFactorToken, code },
    });
    const { authToken, deviceToken } = data?.adminEnterSecondFactor ?? {};
    if (authToken) {
      setAuthToken(authToken);
      if (deviceToken) setDeviceToken(deviceToken);
      history.push(nextPage);
    } else {
      setError('Invalid code');
    }
  };

  if (isLoggedIn()) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ width: 325 }} className="center">
      {verifyError && <AuthError error={verifyError} />}
      {error && <CenteredNotification kind={KIND.negative}>{error}</CenteredNotification>}
      {resendError && (
        <CenteredNotification kind={KIND.negative}>{resendError}</CenteredNotification>
      )}
      <div className="center tc mt5 mb4">
        <Icon icon="iconsMantraLogoDefaultSvg" alt="Mantra Health logo" />
      </div>
      <TwoFactor phoneLastFour={phoneLastFour} resendCode={resendCode} onVerify={onVerify} />
      <LinkWrapper>
        <Text.link to="/login" onClick={onBack}>
          Back to login
        </Text.link>
      </LinkWrapper>
    </div>
  );
};
