import { InputOverrides } from 'baseui/input';
import styled from 'styled-components';
import { colors } from '../../globalStyles';
import { borderColor, padding } from '../../utils';

export const Form = styled.form`
  max-width: 80vw;
  width: 325px;
`;

export const FormElement = styled.div`
  margin-bottom: 24px;
`;

export const LinkWrapper = styled.div`
  margin: 1.5rem;
  text-align: center;
`;

export const inputOverrides: InputOverrides = {
  Input: { style: { backgroundColor: '#F9F9F9', ...padding('0.5rem 1.25rem') } },
  InputContainer: {
    style: ({ $isFocused }) => ({
      ...borderColor($isFocused ? colors.primary : '#F9F9F9'),
    }),
  },
};
