import moment from 'moment';
import React from 'react';
import { Text } from '../../globalStyles';
import * as assessmentUtil from '../../modelUtils/assessment';
import { getFullName } from '../../modelUtils/users';
import { useDrilldownContext } from '../../Pages/Users/helpers';
import { scoreDisplayMap } from '../Assessments/ScoreDisplay';
import { orNullFactory } from '../OrNull';

const order = [
  ['phq8', 'phq9'],
  ['gad7'],
  ['asrsv11'],
  ['sds'],
  ['mdq'],
  ['ymrs'],
  ['dropout-risk-measurement'],
  ['flourishing-scale'],
] as string[][];

const ScalesAndScreeners = () => {
  const { user } = useDrilldownContext();

  return (
    <div className="bg-white pa3 pb2 ba b--gray br2 mb4">
      <div className="flex justify-between">
        <Text.label>Assessment</Text.label>
        <Text.label>Result</Text.label>
      </div>
      {order.map((orderKeys, i) => {
        const latestResult = user.latestQuestionnaires?.find(q => orderKeys.includes(q.key));

        const ScoreDisplayComponent = scoreDisplayMap.get(orderKeys[0]);
        const displayScore = ScoreDisplayComponent && (
          <ScoreDisplayComponent result={latestResult} />
        );

        const subtitle = (() => {
          if (!latestResult) return null;
          const name = latestResult.assessment.provider?.name ?? getFullName(user);
          const date = moment(latestResult.assessment.createdAt).format('L');
          return `Submitted by ${name} on ${date}`;
        })();

        const rightSideContent =
          displayScore || (latestResult && assessmentUtil.formatResult(latestResult)) || null;

        return (
          <React.Fragment key={orderKeys[0]}>
            {i !== 0 && <div className="bt b--light-gray" />}

            <div className="h3 flex gap-2 justify-between items-center">
              <div>
                <h4 className="b f5">{assessmentUtil.getTitle(orderKeys[0])}</h4>
                <p className="gray f7 f6-ns">{subtitle}</p>
              </div>
              <div className="fx7 f6-ns nowrap">{rightSideContent}</div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const QuestionHeaders = orNullFactory({
  'status-suicidal': ScalesAndScreeners,
});
