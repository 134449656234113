import { Modal } from 'baseui/modal';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal as MantraModal, Text } from '../../globalStyles';
import {
  useAdminCancelAppointmentMutation,
  useAdminEndCallMutation,
  useAdminMarkNoShowMutation,
  useAdminMarkUpcomingMutation,
} from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import * as Styles from '../../Pages/Appointments/styles';
import { PillCheckbox } from '../EzForm';
import { FinalButton } from '../FinalButton';
import { Icon, icons } from '../Icons';
import { AppointmentForLink } from './types';

interface Props {
  appointment: AppointmentForLink;
  onClose: (cancelled: boolean) => void;
  status: EditType;
}

export enum EditType {
  openUnmarked = 'Open / Unmarked',
  noShow = 'No Show',
  cancelled = 'Cancelled',
  outOfPolicyCancelled = 'Out-of-policy Cancellation',
  completed = 'Completed',
}

const editOptions: EditType[] = [
  EditType.openUnmarked,
  EditType.noShow,
  EditType.cancelled,
  EditType.outOfPolicyCancelled,
  EditType.completed,
];

export function EditAppointmentModal({ appointment, onClose, status }: Props) {
  const [selectedOption, setSelectedOption] = useState(status.toString());

  const [upcomingMutation, { loading: upcomingLoading }] = useAdminMarkUpcomingMutation({
    variables: {
      id: appointment.id,
    },
  });

  const [noshowMutation, { loading: noshowLoading }] = useAdminMarkNoShowMutation({
    variables: {
      userId: (appointment.user as any).id,
      appointmentId: appointment.id,
    },
  });

  const [completedMutation, { loading: completedLoading }] = useAdminEndCallMutation({
    variables: {
      userId: (appointment.user as any).id,
      appointmentId: appointment.id,
    },
  });

  const [cancelledMutation, { loading: cancelledLoading }] = useAdminCancelAppointmentMutation();

  const handleConfirm = async () => {
    switch (selectedOption) {
      case EditType.openUnmarked: {
        await upcomingMutation();
        break;
      }
      case EditType.noShow: {
        await noshowMutation();
        break;
      }
      case EditType.completed: {
        await completedMutation();
        break;
      }
      case EditType.cancelled: {
        await cancelledMutation({ variables: { id: appointment.id } });
        break;
      }
      case EditType.outOfPolicyCancelled: {
        await cancelledMutation({ variables: { id: appointment.id, outOfPolicy: true } });
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.error(`Something went wrong, user selected option ${selectedOption}`);
        return;
      }
    }

    onClose(true);
  };

  return (
    <Modal isOpen onClose={() => onClose(false)}>
      <MantraModal.body>
        <MantraModal.header>Mark appointment as...</MantraModal.header>
        <Styles.ModalAppointmentBox>
          <Styles.Thumbnail
            src={appointment.user.selfie?.url ?? icons.assetsDefaultPicturePng}
            alt={getFullName(appointment.user)}
          />
          <Styles.ModalAppointmentText>
            <Text.bodyBold>{getFullName(appointment.user)}</Text.bodyBold>
            <Text.body>{appointment.description}</Text.body>
            <Text.bodyBold>
              {moment(appointment.startTime).format('M/D/YYYY h:mm a')} -{' '}
              {moment(appointment.endTime).format('h:mm a')}
            </Text.bodyBold>
          </Styles.ModalAppointmentText>
        </Styles.ModalAppointmentBox>
        <PillCheckbox
          options={editOptions}
          value={selectedOption}
          onChange={value => {
            setSelectedOption(value);
          }}
          selectedIcon={<Icon icon="iconsBlackCheckSvg" alt="Check Mark" />}
          multi={false}
        />
        {selectedOption === EditType.outOfPolicyCancelled && (
          <Text.body className="mv2">
            <b>Note:</b> Patient cancellation and reschedule requests made within 24 hours of the
            appointment time are considered in violation of our cancellation policy.
          </Text.body>
        )}
        <FinalButton
          kind="primary"
          className="w-100 mt4 mb2"
          onClick={handleConfirm}
          loading={upcomingLoading || noshowLoading || completedLoading || cancelledLoading}
          disabled={selectedOption === status.toString()}
        >
          Update Appointment
        </FinalButton>
        <MantraModal.closeLink onClick={() => onClose(false)}>Close</MantraModal.closeLink>
      </MantraModal.body>
    </Modal>
  );
}
