import { ThemeProvider } from 'baseui';
import { LayersManager } from 'baseui/layer';
import { ToasterContainer } from 'baseui/toast';
import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { MantraTheme } from './globalStyles/baseuiTheme';

const engine = new Styletron({ prefix: 'x' });

export const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <StyletronProvider value={engine}>
    <ThemeProvider theme={MantraTheme}>
      <LayersManager zIndex={5}>{children}</LayersManager>
      <ToasterContainer placement="bottomRight" />
    </ThemeProvider>
  </StyletronProvider>
);
