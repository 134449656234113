import React from 'react';
import { SafetyPlanContactFragment } from '../../graphQL';
import { ComponentArgs } from '../EzForm';
import { Input as EzInput } from '../Form/Input';

export const Distraction = ({
  value,
  onChange,
  index,
}: ComponentArgs<SafetyPlanContactFragment>) => {
  return (
    <div className="w-100 flex">
      <EzInput
        value={value.name}
        onChange={e => onChange({ ...value, name: e.target.value })}
        placeholder={`Distraction #${index + 1}`}
        style={{ marginRight: '0.5rem' }}
      />
      <EzInput
        value={value.contact ?? undefined}
        onChange={e => onChange({ ...value, contact: e.target.value })}
        placeholder="Phone Number (optional)"
      />
    </div>
  );
};

export const CrisisContact = ({
  value,
  onChange,
  index,
}: ComponentArgs<SafetyPlanContactFragment>) => {
  return (
    <div className="w-100 flex">
      <EzInput
        value={value.name}
        onChange={e => onChange({ ...value, name: e.target.value })}
        placeholder={`Person #${index + 1}`}
        style={{ marginRight: '0.5rem' }}
      />
      <EzInput
        value={value.contact ?? undefined}
        onChange={e => onChange({ ...value, contact: e.target.value })}
        placeholder="Phone Number"
      />
    </div>
  );
};

export const ProfessionalContact = ({
  value,
  onChange,
  index,
}: ComponentArgs<SafetyPlanContactFragment>) => {
  return (
    <div className="w-100 flex">
      <EzInput
        value={value.name}
        onChange={e => onChange({ ...value, name: e.target.value })}
        placeholder={`Professional #${index + 1}`}
        style={{ marginRight: '0.5rem' }}
      />
      <EzInput
        value={value.contact ?? undefined}
        onChange={e => onChange({ ...value, contact: e.target.value })}
        placeholder="Phone Number / Email"
      />
    </div>
  );
};

export const CrisisResource = ({
  value,
  onChange,
  index,
}: ComponentArgs<SafetyPlanContactFragment>) => {
  return (
    <div className="w-100 flex">
      <EzInput
        value={value.name}
        onChange={e => onChange({ ...value, name: e.target.value })}
        placeholder={`Crisis Resource #${index + 1}`}
        style={{ marginRight: '0.5rem' }}
      />
      <EzInput
        value={value.contact ?? undefined}
        onChange={e => onChange({ ...value, contact: e.target.value })}
        placeholder="Phone Number / Link"
      />
    </div>
  );
};

export const Facility = ({ value, onChange }: ComponentArgs<SafetyPlanContactFragment>) => {
  return (
    <div className="flex flex-column w-100">
      <div className="flex mb2">
        <EzInput
          value={value.name}
          onChange={e => onChange({ ...value, name: e.target.value })}
          placeholder="Name of Facility"
          style={{ marginRight: '0.5rem' }}
        />
        <EzInput
          value={value.contact ?? undefined}
          onChange={e => onChange({ ...value, contact: e.target.value })}
          placeholder="Phone Number"
        />
      </div>
      <EzInput
        value={value.address ?? undefined}
        onChange={e => onChange({ ...value, address: e.target.value })}
        placeholder="Facility Address"
      />
    </div>
  );
};
