import React from 'react';
import styled from 'styled-components';
import { MantraModalBodyOld } from '../Modal';
import { Text, colors } from '../../globalStyles';
import { RemoveCampusTeamMemberModalProps } from './SearchAndAddMultirow';
import { Modal } from '../Modal/Modal';
import { EditCampusTeamMember } from '../CampusTeam/types';

export const modalButton = styled(Text.linkButton)`
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 16px 0;
`;

export const CloseModalButton = styled(modalButton)`
  color: ${colors.grey.ozGray2};
`;

export const RemoveMemberButton = styled(modalButton)`
  border-radius: 4px;
  background-color: ${colors.danger};
  color: ${colors.white};
`;

export const RemoveMemberModalTitleH2 = styled(Text.h2)`
  padding-bottom: 10px;
`;

export const RemoveMemberModalBodyDiv = styled(MantraModalBodyOld)`
  max-width: 544px;
`;

export const RemoveCampusTeamMemberModal = ({
  campusTeam,
  isOpen,
  onClose,
  removeCampusTeamMember,
  modalOpenIdx,
  currentProviderName,
}: RemoveCampusTeamMemberModalProps<EditCampusTeamMember>) => {
  const removeTeamMember = () => {
    typeof modalOpenIdx === 'number'
      ? removeCampusTeamMember(campusTeam[modalOpenIdx], modalOpenIdx)
      : onClose();
    onClose();
  };

  const removedName =
    typeof modalOpenIdx === 'number' ? campusTeam[modalOpenIdx].name : 'Null User';
  const yourselfOrRemovedName = removedName === currentProviderName ? 'yourself' : removedName;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="campusTeam">
      <RemoveMemberModalBodyDiv>
        <RemoveMemberModalTitleH2>Remove from Campus Team?</RemoveMemberModalTitleH2>
        <div className="mb4 pt1 flex-1">
          <Text.body>Are you sure you want to remove {yourselfOrRemovedName}?</Text.body>
          <br />
          <Text.body>You&apos;ll no longer receive updates about this patient.</Text.body>
          <br />
          <Text.body>User can rejoin a patient&apos;s campus team at any time.</Text.body>
        </div>
        <RemoveMemberButton className="b" onClick={removeTeamMember}>
          Remove
        </RemoveMemberButton>
        <CloseModalButton className="b" onClick={onClose}>
          Close
        </CloseModalButton>
      </RemoveMemberModalBodyDiv>
    </Modal>
  );
};
