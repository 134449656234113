import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { FinalButton } from '../../../../Components/FinalButton';
import { Checkbox, Input, MultiSelect, Select } from '../../../../Components/Form';
import { Option } from '../../../../Components/Form/Select/types';
import { InlineSVG } from '../../../../Components/Icons';
import { Text } from '../../../../globalStyles';
import { Nullable } from '../../../../types';
import { cx, TimeZone } from '../../../../utils';
import { AllocationType } from '../../util';
import * as Styles from '../Styles';
import { allocationTypeLabels, getMsBetween, isWithinRestrictedTimeET } from './availabilityUtils';
import { isCompletedAllocation, LocalAllocation } from './Hooks/types';
import { AllocError, EditAction } from './Hooks/useEditAvailability';
import { hasTimeType, TimeSelect } from './TimeSelect';
import { allocationTypes, AvailabilityProvider } from './types';
import { isMantraAdmin, useCurrentProvider } from '../../../../Components/Permissions';

const ONE_HOUR_MS = 1000 * 60 * 60;

const mantraHealthOrgOption: Readonly<Option> = {
  id: -1,
  label: 'Mantra Health',
};

/** Full list of options for type dropdown */
const typeOptionsList = allocationTypes.map(t => ({ id: t, label: allocationTypeLabels[t] }));

/** Limited set of options for type dropdown for providers */
const typeOptionsListForProviders = typeOptionsList.filter(({ id }) =>
  ['admin', 'default', 'timeOff'].includes(id)
);

type AllocationProps = {
  date: Moment;
  allocation: LocalAllocation;
  timezone: TimeZone;
  provider: AvailabilityProvider;
  dispatch: React.Dispatch<EditAction>;
  error?: Nullable<AllocError>;
  disabled: boolean;
};

export const Allocation = ({
  date,
  allocation,
  timezone,
  provider,
  error,
  dispatch,
  disabled,
}: AllocationProps) => {
  const { id: allocationId, organizationId } = allocation;
  const [hoursExceedsDay, setHoursExceedDay] = useState(false);
  const [isEditingHours, setIsEditingHours] = useState(false);

  const { currentProvider } = useCurrentProvider();
  const isAdmin = isMantraAdmin(currentProvider);

  const msBetween = isCompletedAllocation(allocation)
    ? getMsBetween(allocation.startTime, allocation.endTime)
    : null;

  // "hoursInput" is a string since the user must be able to type decimal numbers into the input element
  const [hoursInput, setHoursInput] = useState(
    msBetween ? (msBetween / ONE_HOUR_MS).toString() : '0.0'
  );

  const canEditHours =
    isAdmin && (hasTimeType(allocation, 'startTime') || hasTimeType(allocation, 'endTime'));
  const hours = Number(hoursInput) || 0;

  // Update hours from changes to time selections
  useEffect(() => {
    if (!msBetween || isEditingHours) return;
    setHoursExceedDay(false);
    setHoursInput(error ? '0.0' : (msBetween / ONE_HOUR_MS).toString());
  }, [error, msBetween, isEditingHours]);

  // Update time selections from changes to hours
  useEffect(() => {
    // To prevent infinite loop
    const noChangeToHours = msBetween && msBetween / ONE_HOUR_MS === hours;

    if (hours <= 0 || !isEditingHours || noChangeToHours) {
      return;
    }

    // Even if the allocation is complete, as long as it has a startTime update
    if (hasTimeType(allocation, 'startTime')) {
      const endExceeds = endTimeExceedsDay(allocation, date, hours);
      if (!endExceeds) {
        dispatch({
          type: 'update',
          args: {
            allocationId,
            field: 'endTime',
            value: allocation.startTime.clone().add(hours * 60 * 60, 'seconds'),
          },
        });
      }
      setHoursExceedDay(endExceeds);
    }
    // Only update if the allocation does not have a startTime but has an endTime
    else if (hasTimeType(allocation, 'endTime')) {
      const startExceeds = startTimeExceedsDay(allocation, date, hours);
      if (!startExceeds) {
        dispatch({
          type: 'update',
          args: {
            allocationId,
            field: 'startTime',
            value: allocation.endTime.clone().subtract(hours * 60 * 60, 'seconds'),
          },
        });
      }
      setHoursExceedDay(startExceeds);
    }
  }, [
    hours,
    allocation,
    msBetween,
    allocationId,
    isEditingHours,
    date,
    dispatch,
    setHoursExceedDay,
  ]);

  const childrenOrgs = provider.organizations.find(i => i.id === organizationId)?.children ?? [];

  // Only oz admins can select orgs for provider allocations
  const adminOrgOptions = isAdmin ? provider.organizations : [];

  // Non-admin mantra providers should not be able to edit type dropdown if this allocation is a intake-only or followup-only.
  // Oz admin can still change them.
  const typeInputDisabled =
    disabled || (!isAdmin && (allocation.type === 'intake' || allocation.type === 'checkin'));

  // Disabled type dropdown can show all options (because oz admin may have set it to that).
  // Enabled dropdown should only show options that the role can choose (providers should not be able to select the 'only' options)
  const typeInputOptions =
    typeInputDisabled || isAdmin ? typeOptionsList : typeOptionsListForProviders;

  // The startTime and endTime inputs should be disabled for providers where they would otherwise be editable
  // if either time is outside of the allowable window of 12a-9a ET
  const startTimeRestricted = startTimeRestrictedForProvider({ isAdmin, allocation });
  const endTimeIsRestricted = endTimeRestrictedForProvider({ isAdmin, allocation });

  return (
    <>
      <Styles.AllocationGridV2>
        <TimeSelect
          className={cx({ error: error && error.field === 'startTime' })}
          disabled={disabled || startTimeRestricted}
          forDate={date}
          timeType="startTime"
          timezone={timezone}
          allocation={allocation}
          onChange={(timeType, updated) => {
            dispatch({
              type: 'update',
              args: { allocationId, field: timeType, value: updated },
            });
          }}
        />
        <Styles.AllocationGridStatic>-</Styles.AllocationGridStatic>
        <TimeSelect
          className={cx({ error: error && error.field === 'endTime' })}
          disabled={disabled || endTimeIsRestricted}
          forDate={date}
          timeType="endTime"
          timezone={timezone}
          allocation={allocation}
          onChange={(timeType, updated) => {
            dispatch({
              type: 'update',
              args: { allocationId, field: timeType, value: updated },
            });
          }}
        />
        <Input
          maxLength={5}
          value={hoursInput}
          disabled={!canEditHours || disabled}
          onChange={e => {
            if (isEditingHours) {
              setHoursInput(e.target.value);
            }
          }}
          onFocus={() => setIsEditingHours(true)}
          onBlur={() => setIsEditingHours(false)}
          style={{ width: 59 }}
        />
        <Select
          options={[
            mantraHealthOrgOption,
            ...adminOrgOptions
              .map(i => ({ id: i.id, label: i.name }))
              .sort((a, b) => a.label.localeCompare(b.label)),
          ]}
          disabled={disabled || !isAdmin}
          value={organizationId ?? (mantraHealthOrgOption.id as number)}
          onChange={orgId =>
            dispatch({
              type: 'update',
              args: {
                allocationId,
                field: 'organizationId',
                value: orgId as number,
              },
            })
          }
          style={{ width: 200 }}
        />
        <Select
          options={typeInputOptions}
          disabled={typeInputDisabled}
          value={typeInputOptions.find(i => i.id === allocation.type)?.id ?? typeInputOptions[0].id}
          onChange={allocType =>
            dispatch({
              type: 'update',
              isAdmin,
              args: {
                allocationId,
                field: 'type',
                value: allocType as AllocationType,
              },
            })
          }
          style={{ width: 170 }}
          clearable={false}
        />
        <Checkbox
          className="items-center justify-center"
          checked={allocation.isFeeForServiceTime ?? false}
          onChange={() =>
            dispatch({
              type: 'update',
              args: {
                allocationId,
                field: 'isFeeForServiceTime',
                value: !allocation.isFeeForServiceTime,
              },
            })
          }
          // This should be disabled for all non-admins regardless of FFS status or other conditions
          disabled={allocation.type === 'admin' || allocation.type === 'timeOff' || !isAdmin}
        />
        <div className="flex justify-center items-center">
          <FinalButton
            className="db"
            disabled={disabled}
            size="tiny"
            kind="minimal_black"
            onClick={() => dispatch({ type: 'delete', args: { allocationId } })}
          >
            <InlineSVG icon="x" size={20} />
          </FinalButton>
        </div>
      </Styles.AllocationGridV2>
      {(childrenOrgs.length > 0 || hoursExceedsDay) && (
        <Styles.AllocationGridV2>
          {/* spacing to put it in the right part of the grid */}
          <div />
          <div />
          <div />
          {hoursExceedsDay ? <Text.caption kind="danger">Hours exceed day</Text.caption> : <div />}
          {childrenOrgs.length > 0 ? (
            <MultiSelect
              options={childrenOrgs
                .map(i => ({ id: i.id, label: i.name }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              value={allocation.childOrganizationIds ?? []}
              onChange={childOrgIds =>
                dispatch({
                  type: 'update',
                  args: {
                    allocationId,
                    field: 'childOrganizationIds',
                    value: childOrgIds as number[],
                  },
                })
              }
              style={{ width: 200 }}
              placeholder="All campuses"
            />
          ) : (
            <div />
          )}
        </Styles.AllocationGridV2>
      )}
    </>
  );
};

const endTimeExceedsDay = (allocation: LocalAllocation, date: Moment, hours: number) => {
  const hasStart = hasTimeType(allocation, 'startTime');
  return (
    hasStart &&
    allocation.startTime
      .clone()
      .add(hours * 60 * 60, 'seconds')
      .isAfter(date.clone().endOf('day'))
  );
};

const startTimeExceedsDay = (allocation: LocalAllocation, date: Moment, hours: number) => {
  const hasEnd = hasTimeType(allocation, 'endTime');
  return (
    hasEnd &&
    allocation.endTime
      .clone()
      .subtract(hours * 60 * 60, 'seconds')
      .isBefore(date.clone().startOf('day'))
  );
};

const startTimeRestrictedForProvider = ({
  isAdmin,
  allocation,
}: {
  isAdmin: boolean;
  allocation: LocalAllocation;
}): boolean => {
  // Admins can always edit
  if (isAdmin) {
    return false;
  }

  // If provider is editing an allocation with a starttime, check if it should be restricted
  return hasTimeType(allocation, 'startTime') && isWithinRestrictedTimeET(allocation.startTime);
};

const endTimeRestrictedForProvider = ({
  isAdmin,
  allocation,
}: {
  isAdmin: boolean;
  allocation: LocalAllocation;
}): boolean => {
  // Admins can always edit
  if (isAdmin) {
    return false;
  }

  // If provider is editing an allocation with a endtime, check if it should be restricted
  return hasTimeType(allocation, 'endTime') && isWithinRestrictedTimeET(allocation.endTime);
};
