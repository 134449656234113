import { publicUrl } from './utils';

const Camera = publicUrl('icons/camera.svg');
const CameraOff = publicUrl('icons/camera_off.svg');
const Microphone = publicUrl('icons/microphone.svg');
const MicrophoneOff = publicUrl('icons/microphone_off.svg');

export default {
  VideoCall: {
    camera: Camera,
    cameraOff: CameraOff,
    micOn: Microphone,
    micOff: MicrophoneOff,
  },
  Black: {
    more: publicUrl('icons/black/More-Vertical.svg'),
    message: publicUrl('icons/black/Message.svg'),
    appointment: publicUrl('icons/black/Appt.svg'),
    noShow: publicUrl('icons/black/NoShow.svg'),
    reschedule: publicUrl('icons/black/Reschedule.svg'),
    edit: publicUrl('icons/black/Edit.svg'),
    safety: publicUrl('icons/black/Safety.svg'),
    check: publicUrl('icons/black/Check.svg'),
    plusCircle: publicUrl('icons/black/Plus-Circle.svg'),
  },
  RedX: publicUrl('icons/RedX.svg'),
  Check: publicUrl('icons/check.svg'),
} as const;
