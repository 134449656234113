import { Checkbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { Input, InputProps } from 'baseui/input';
import { StatefulSelect } from 'baseui/select';
import React from 'react';
import { Control, Controller, ValidationRules } from 'react-hook-form';
import { FileUploader, UploaderProps } from '../../../Components/FileUploader';
import { overrides, Text } from '../../../globalStyles';
import { UploadType } from '../../../graphQL';
import { RequireJust } from '../../../types';
import * as Styles from './styles';

type BaseProps<T = {}> = {
  errorCaption?: string;
  name: string;
  optional?: boolean;
  control: Control<any>;
  rules?: ValidationRules;
} & T;

type UploadProps = Pick<UploaderProps, 'accept' | 'errorMessage' | 'onRetry' | 'maxSize'> & {
  onUpload: (files: File[]) => string | undefined;
  onRejected: (files: File[]) => void;
};

export const Upload = ({
  errorCaption,
  name,
  control,
  optional,
  onUpload,
  onRejected,
  rules,
  ...props
}: BaseProps<UploadProps>) => (
  <>
    <Styles.FieldName className="mb2" htmlFor={name}>
      Attach file {optional ? '(optional)' : <RequiredField />}
    </Styles.FieldName>
    <FormControl caption={errorCaption}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue=""
        render={({ onChange }) => (
          <FileUploader
            onDropAccepted={files => {
              const fileName = onUpload(files);
              onChange(fileName);
            }}
            onDropRejected={files => {
              onChange(undefined);
              onRejected(files);
            }}
            {...props}
          />
        )}
      />
    </FormControl>
  </>
);

type TypeProps = BaseProps<{ defaultValue?: UploadType; options: any; display?: boolean }>;

export const UploadTypeSelect = ({
  options,
  optional,
  name,
  control,
  rules,
  errorCaption,
  defaultValue,
  display,
}: TypeProps) => {
  if (!display) {
    return null;
  }
  return (
    <>
      <Styles.FieldName className="mb2" htmlFor={name}>
        Upload type {optional ? '(optional)' : <RequiredField />}
      </Styles.FieldName>
      <FormControl caption={errorCaption}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue ?? ''}
          render={({ onChange, ...handler }) => (
            <StatefulSelect
              multi={false}
              clearable={false}
              creatable={false}
              searchable={false}
              options={options}
              onChange={({ option }) => onChange(option?.id)}
              placeholder="Select"
              initialState={{
                value: defaultValue
                  ? [{ id: defaultValue, label: uploadTypeLabels[defaultValue] }]
                  : [],
              }}
              {...handler}
            />
          )}
        />
      </FormControl>
    </>
  );
};

type CheckProps = {
  display?: boolean;
  checked?: boolean;
};

export const ShareableCheck = ({
  display,
  checked,
  name,
  errorCaption,
  control,
}: BaseProps<CheckProps>) => {
  if (!display) {
    return null;
  }
  return (
    <div className="mv4 flex flex-row align-start">
      <div className="mr1 mt1" style={{ flexShrink: 1 }}>
        <FormControl caption={errorCaption}>
          <Controller
            name={name}
            control={control}
            defaultValue={!!checked}
            render={({ onChange, value, ...handler }) => (
              <Checkbox
                checked={value}
                overrides={overrides.checkbox}
                onChange={({ currentTarget }) => onChange(currentTarget.checked)}
                {...handler}
              />
            )}
          />
        </FormControl>
      </div>
      <div>
        <Styles.FieldName className="mt1" htmlFor={name}>
          Share with campus collaboration team?
        </Styles.FieldName>
        <Text.bodySmall style={{ lineHeight: 'normal' }}>
          If checked, this upload will be visible to University admin and collaborators from this
          patient&apos;s organization.
        </Text.bodySmall>
      </div>
    </div>
  );
};

type NameProps = RequireJust<Omit<InputProps, 'placeholder'>, 'name' | 'inputRef'> & {
  errorCaption?: string;
  titleOverride?: string;
  optional?: boolean;
};

export const UploadName = ({
  errorCaption,
  optional,
  titleOverride,
  name,
  ...props
}: NameProps) => {
  return (
    <div className="mb4">
      <Styles.FieldName className="mb1" htmlFor={name}>
        {titleOverride || 'Name this upload'} {optional ? '(optional)' : <RequiredField />}
      </Styles.FieldName>
      <Styles.Caption className="mb3">
        Choose a name that will help other collaborators identify the contents of the upload. If you
        choose to leave this blank, we&apos;ll use the original name of the uploaded file.
      </Styles.Caption>
      <FormControl caption={errorCaption}>
        <Input name={name} placeholder="Example: Patient Referral Note" {...props} />
      </FormControl>
    </div>
  );
};

const RequiredField = () => <Styles.Required>*</Styles.Required>;

export const uploadTypeLabels: Record<UploadType, string> = {
  [UploadType.InsuranceDetails]: 'Insurance Details',
  [UploadType.LabResults]: 'Lab Results',
  [UploadType.PatientResource]: 'Patient Resource',
  [UploadType.ReferralNote]: 'Referral Note',
  [UploadType.Other]: 'Other',
};

export const uploadTypeCopy: Record<UploadType, string> = {
  [UploadType.InsuranceDetails]:
    'Insurance Details are only visible to Mantra admin and relevant Wellround providers.',
  [UploadType.LabResults]:
    'Lab Results are only visible to Mantra admin and relevant Wellround providers.',
  [UploadType.PatientResource]: '',
  [UploadType.ReferralNote]:
    "Uploading a referral note to this patient’s profile will remove this patient from your 'Incomplete Referrals' list and will be visible to the patient’s Mantra-affiliated provider.",
  [UploadType.Other]:
    'Files uploaded to this patient’s profile will be viewable by their collaboration team and Mantra-affiliated provider.',
};
