import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ConfigProvider } from './Components/ConfigContext';
import { EventsProvider } from './Components/Events/EventsProvider';
import { NavLayout } from './Components/NavLayout';
import { AuthProvider } from './Components/Permissions/AuthWrapper';
import { TimeoutManager } from './Components/TimeoutManager';
import { VideoProvider } from './Components/Video/VideoProvider';
import { useRefreshTokenBeforeExpiration, useVersionChecker } from './Hooks';
import { VideoCall } from './Pages/VideoCall';
import { VideoCallDismissed } from './Pages/VideoCall/JoinCallBlocked';
import { JoinVideoCall } from './Pages/VideoCall/JoinVideoCall';
import { NoShow } from './Pages/VideoCall/NoShowPage';
import { Providers } from './Providers';
import { AuthenticatedRoutes, PublicRoutes } from './Routes';
import { isLoggedIn } from './utils';
import { GeolocationCheck } from './Components/Geolocation/GeolocationCheck';
import { GeolocationProvider } from './Components/Geolocation/GeolocationContext';

const Centered = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  min-height: 100vh;
`;

const Inner = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: white;
  min-height: 100vh;
  max-width: 1600px;
  width: 100%;
`;

export const AppDashboard: React.FC = () => {
  useVersionChecker();

  return (
    <Providers>
      <Centered>
        <Inner>
          <GeolocationProvider>
            <GeolocationCheck>
              <EventsProvider>
                <ConfigProvider>
                  <PublicRoutes>
                    <AuthenticatedApp />
                  </PublicRoutes>
                </ConfigProvider>
              </EventsProvider>
            </GeolocationCheck>
          </GeolocationProvider>
        </Inner>
      </Centered>
    </Providers>
  );
};

const AuthenticatedApp: React.FC = () => {
  const location = useLocation();

  useRefreshTokenBeforeExpiration(1000 * 60 * 6);

  if (!isLoggedIn()) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  return (
    <AuthProvider>
      <VideoProvider>
        <TimeoutManager />
        <Switch>
          <Route path="/users/:id/video/:appointmentId" component={VideoCall} />
          <Route path="/join-call/:id" component={JoinVideoCall} />
          <Route path="/call-dismissed" component={VideoCallDismissed} />
          <Route path="/users/:id/noShow" component={NoShow} />
          <NavLayout>
            <AuthenticatedRoutes />
          </NavLayout>
        </Switch>
      </VideoProvider>
    </AuthProvider>
  );
};
