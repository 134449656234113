import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../../Components/Modal/Modal';
import { Text } from '../../globalStyles';
import {
  useClearProviderLockoutMutation,
  useRegenerateProviderPasswordMutation,
} from '../../graphQL';
import { useProviderDrilldownContext } from './context';
import { FinalButton } from '../../Components/FinalButton';

export function SecurityTab() {
  return (
    <div className="mh5 mv3" style={{ maxWidth: 800 }}>
      <ClearLockout />
      <hr className="mv3" />
      <RegeneratePassword />
    </div>
  );
}

export function ClearLockout() {
  const { provider } = useProviderDrilldownContext();
  const [clearLockout, clearLockoutMutation] = useClearProviderLockoutMutation({
    variables: { providerId: provider.id },
  });
  return (
    <div>
      <Text.body>
        For security reasons, provider accounts are locked when more than 5 unsuccessful login
        attempts occur within 15 minutes. To reset this lockout period for this provider (e.g.
        because they contacted you asking for help), click below:
      </Text.body>
      <div className="flex flex-row mt3">
        <Text.linkButton onClick={() => clearLockout()}>Clear provider lockout</Text.linkButton>
        {clearLockoutMutation.data?.clearProviderLockout && (
          <Text.body className="ml4">Done.</Text.body>
        )}
      </div>
    </div>
  );
}

export function RegeneratePassword() {
  const { provider } = useProviderDrilldownContext();
  const [showConfirm, setShowConfirm] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [copied, setCopied] = useState(false);
  const [regenerate, regenerateMutation] = useRegenerateProviderPasswordMutation({
    variables: { providerId: provider.id },
    onCompleted: res => {
      setShowConfirm(false);
      setNewPassword(res.regenerateProviderPassword);
    },
  });

  const onCopy = async () => {
    await navigator.clipboard.writeText(newPassword);
    setCopied(true);
  };
  const onClose = () => {
    setNewPassword('');
    setCopied(false);
  };

  return (
    <div>
      <Text.body className="mb3">
        You can generate a temporary new password for an MCP user. This will destroy their existing
        password, which cannot be recovered. Upon login, they will be prompted to enter a new
        password.
      </Text.body>
      <Text.linkButton onClick={() => setShowConfirm(true)}>Generate new password</Text.linkButton>
      <Modal isOpen={showConfirm} onClose={() => setShowConfirm(false)} size="small">
        <Text.h3 className="mb2">Generate new password?</Text.h3>
        <Text.body className="mb3">
          Are you sure you want to generate a temporary new password for {provider.name}? They will
          no longer be able to log in with their old password.
        </Text.body>
        <div className="flex items-center" style={{ gap: 15 }}>
          <FinalButton
            kind="primary"
            className="flex-1"
            onClick={() => regenerate()}
            loading={regenerateMutation.loading}
            disabled={regenerateMutation.loading}
          >
            Generate new password
          </FinalButton>
          <FinalButton
            kind="outline_black"
            className="flex-1"
            onClick={() => setShowConfirm(false)}
            disabled={regenerateMutation.loading}
          >
            Cancel
          </FinalButton>
        </div>
      </Modal>
      <Modal isOpen={!!newPassword} onClose={onClose} size="small">
        <Text.h3 className="mb3">New password generated</Text.h3>
        <Text.body className="mb3">
          Here is the new password. It will only appear here, once.{' '}
          <b>After closing this modal, you will never be able to retrieve this password again.</b>
        </Text.body>
        <Text.body>
          The provider will not receive an automated email with this password; you will need to send
          it to them.
        </Text.body>
        <div className="flex items-center mt3" style={{ gap: 15 }}>
          <PasswordContainer>{newPassword}</PasswordContainer>
          <FinalButton onClick={onCopy} kind="primary" style={{ height: 40 }}>
            Copy to clipboard
          </FinalButton>
          {copied && <Text.bodySmall>Copied.</Text.bodySmall>}
        </div>
      </Modal>
    </div>
  );
}

const PasswordContainer = styled.div`
  padding: 10px;
  font-family: monospace;
  background-color: #f5f5f5;
  margin: 10px 0px;
  display: inline-block;
  font-size: 16px;
`;
