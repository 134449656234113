import React from 'react';
import styled from 'styled-components';
import { colors } from '../../globalStyles';

interface BaseProps {
  options: string[];
  row?: boolean;
  selectedIcon?: React.ReactNode;
  unselectedIcon?: React.ReactNode;
}
interface SingleProps extends BaseProps {
  multi: false;
  value?: string;
  onChange: (arg0: string) => void;
}
interface MultiProps extends BaseProps {
  multi: true;
  value: string[];
  onChange: (arg0: string[]) => void;
}
type Props = SingleProps | MultiProps;

export const PillCheckbox = ({
  options,
  value,
  onChange,
  row = false,
  selectedIcon,
  unselectedIcon,
  multi,
}: Props) => {
  const onSelect = (option: string) => {
    if (multi && Array.isArray(value)) {
      if (value.includes(option)) {
        const idx = value.indexOf(option);
        onChange([...value.slice(0, idx), ...value.slice(idx + 1)] as any);
      } else {
        onChange([...value, option] as any);
      }
    } else {
      onChange(option as any);
    }
  };

  return (
    <div className={`flex ${row ? 'flex-row' : 'flex-column'} items-start justify-start`}>
      {options.map(option => {
        const isSelected = typeof value === 'string' ? value === option : value?.includes(option);

        return (
          <Option
            role="checkbox"
            aria-checked={isSelected}
            checked={isSelected}
            multi={multi}
            anyChecked={!!value}
            onClick={() => onSelect(option)}
            key={option}
            row={row}
          >
            <div className="flex">
              <div className="flex items-center" style={{ width: '30px' }}>
                {isSelected ? selectedIcon : unselectedIcon}
              </div>
              <div>{option}</div>
            </div>
          </Option>
        );
      })}
    </div>
  );
};

type StyledOptionProps = {
  checked?: boolean;
  anyChecked: boolean;
  multi?: boolean;
  row?: boolean;
};
const Option = styled.div<StyledOptionProps>`
  padding: 11px 19px;
  border-radius: 22px;
  border: 1px solid ${({ checked }) => (checked ? colors.primary : colors.grey.border)};
  background-color: ${({ checked }) => (checked ? colors.accent : 'white')};
  width: 100%;
  cursor: pointer;
  margin-bottom: ${({ row }) => (row ? '0px' : '.75rem')};
  opacity: ${({ multi, checked, anyChecked }) => (anyChecked && !multi && !checked ? '0.5' : '1')};

  &:not(:last-of-type) {
    margin-right: ${({ row }) => (row ? '.5rem' : '0px')};
  }
`;
