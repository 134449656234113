import React from 'react';
import { isMantraAdmin } from '.';
import { AppView, Entitlement, OrganizationEntitlement, Permission } from '../../graphQL';
import { hasEntitlement } from '../../utils';
import { useCurrentProvider } from './AuthWrapper';

type ViewProps = {
  fallback?: React.ReactNode;
};

export const AppsOnly: React.FC<ViewProps & { allowed: AppView[] }> = ({
  allowed,
  children,
  fallback,
}) => {
  const { appView } = useCurrentProvider();
  if (!allowed.includes(appView)) return fallback ? <>{fallback}</> : null;
  return <>{children}</>;
};

export const McpOnly: React.FC<ViewProps> = ({ children, fallback }) => {
  return (
    <AppsOnly allowed={[AppView.Mcp]} fallback={fallback}>
      {children}
    </AppsOnly>
  );
};

export const McpOrAdminOnly: React.FC<ViewProps> = ({ children, fallback }) => {
  const { currentProvider } = useCurrentProvider();
  if (isMantraAdmin(currentProvider)) return <>{children}</>;
  return (
    <AppsOnly allowed={[AppView.Mcp]} fallback={fallback}>
      {children}
    </AppsOnly>
  );
};

export const MantraAdminOnly: React.FC<ViewProps> = ({ children, fallback }) => {
  const { currentProvider } = useCurrentProvider();
  if (isMantraAdmin(currentProvider)) return <>{children}</>;
  return fallback ? <>{fallback}</> : null;
};

export const OzOnly: React.FC<ViewProps> = ({ children, fallback }) => {
  return (
    <AppsOnly allowed={[AppView.Oz]} fallback={fallback}>
      {children}
    </AppsOnly>
  );
};

export const ReferralOnly: React.FC<ViewProps> = ({ children, fallback }) => {
  return (
    <AppsOnly allowed={[AppView.Referral]} fallback={fallback}>
      {children}
    </AppsOnly>
  );
};

export const NonReferralOnly: React.FC<ViewProps> = ({ children, fallback }) => {
  const { currentProvider } = useCurrentProvider();
  if (isMantraAdmin(currentProvider)) return <>{children}</>;
  return (
    <AppsOnly allowed={[AppView.Mcp, AppView.Oz]} fallback={fallback}>
      {children}
    </AppsOnly>
  );
};

type PermsProps = {
  allowed: Permission;
  fallback?: React.ReactNode;
};

export const PermsOnly: React.FC<PermsProps> = ({ children, allowed, fallback }) => {
  const { hasPermission } = useCurrentProvider();

  if (!hasPermission(allowed)) return fallback ? <>{fallback}</> : null;
  return <>{children}</>;
};

type FeatureFlaggedProps = {
  flag: string;
  lazyFallback?: () => JSX.Element;
};
export const FeatureFlagged: React.FC<FeatureFlaggedProps> = ({
  flag,
  children,
  lazyFallback: F,
}) => {
  const { featureFlags } = useCurrentProvider();
  if (!featureFlags.includes(flag)) return F ? <F /> : null;
  return <>{children}</>;
};

type HasEntitlementProps = {
  entitlement: Entitlement;
  organization: { entitlements: Pick<OrganizationEntitlement, 'key'>[] };
};

export const HasEntitlement: React.FC<HasEntitlementProps> = ({
  organization,
  entitlement,
  children,
}) => {
  return hasEntitlement(organization, entitlement) ? <>{children}</> : null;
};
