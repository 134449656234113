import {
  textVariantCaption,
  textVariantLabel,
  textVariantParagraph,
  textVariantParagraphLarge,
  textVariantParagraphSmall,
} from './text';

export const linkStyles = {
  Link: {
    baseStyle: {
      _text: {
        _light: {
          color: 'blue.600',
        },
        _dark: {
          color: 'blue.600',
        },
      },
    },
    defaultProps: {
      isUnderlined: false,
    },
    variants: {
      caption: () => textVariantCaption,
      label: () => textVariantLabel,
      paragraph: () => textVariantParagraph,
      paragraphSmall: () => textVariantParagraphSmall,
      paragraphLarge: () => textVariantParagraphLarge,
    },
  },
};
