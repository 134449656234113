import { isNil } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';

type RadioProps = {
  checked: boolean;
  onChange?: (isChecked: boolean) => void;
  label?: string;
  optionId?: string | number | null | undefined;
  name?: string;
};

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ label, checked, onChange }, ref) => (
    <div className="flex items-start justify-start">
      <RadioContainer>
        <StyledRadio
          ref={ref}
          type="radio"
          onChange={onChange ? event => onChange(event.currentTarget.checked) : undefined}
          checked={checked}
        />
        <div className="radio-outer">
          <div className="radio-inner" />
        </div>
      </RadioContainer>
      {label && <Text.bodySmall>{label}</Text.bodySmall>}
    </div>
  )
);

export const RadioPill = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ label, checked, onChange, optionId, name }, ref) => (
    <div className="flex items-start justify-start">
      <RadioPillContainer>
        <StyledRadioPill
          ref={ref}
          type="radio"
          onChange={onChange ? event => onChange(event.currentTarget.checked) : undefined}
          checked={checked}
          id={!isNil(optionId) ? `${name}-${optionId}` : undefined}
        />
        {label && !isNil(optionId) && (
          <RadioPillLabel htmlFor={!isNil(optionId) ? `${name}-${optionId}` : undefined}>
            <Text.bodySmall>{label}</Text.bodySmall>
          </RadioPillLabel>
        )}
      </RadioPillContainer>
    </div>
  )
);

const RadioContainer = styled.div`
  position: relative;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const StyledRadio = styled.input`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;

  label {
    float: left;
    line-height: 3em;
    width: 8em;
    height: 3em;
  }

  & ~ .radio-outer {
    border-radius: 50%;
    background-color: white;
    border: 0.125rem solid ${colors.grey.border};
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.6rem;

    > .radio-inner {
      border-radius: 50%;
      background-color: white;
      border: 0.125rem solid white;
      width: 100%;
      height: 100%;
    }
  }

  &:checked ~ .radio-outer {
    border-color: rgb(1, 120, 255);

    > .radio-inner {
      background-color: ${colors.primary};
    }
  }

  &.error:not(:focus) ~ .radio-outer {
    border-color: rgb(255, 239, 237);

    > .radio-inner {
      background-color: rgb(255, 239, 237);
    }
  }
`;

const RadioPillContainer = styled.div`
  position: relative;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const StyledRadioPill = styled.input`
  // visually hide the radio circle
  position: absolute;
  top: 0;
  left: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);

  &:checked ~ label {
    background-color: ${colors.accent};
    border: 1px solid ${colors.primary};
  }

  &:focus-visible ~ label {
    outline: 1px solid ${colors.black};
    border-color: ${colors.black};
  }
`;

const RadioPillLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${colors.primary};
  padding: 12px 20px;
  border-radius: 40px;
  border: 1px solid ${colors.grey.border};
  width: auto;
  transition: background-color 0.15s ease-in-out;
`;
