import { useReducer } from 'react';

type SeenAssignments = {
  [slideNumber: number]: boolean;
};
type SeenAction = {
  assignmentId: number;
  hasSeen: boolean;
};

type SeenAssignmentsResponse = {
  isAssignmentSeen: (assignmentId: number) => boolean;
  markAssignmentSeen: (assignmentId: number) => void;
};

export const useSeenAssignments = (): SeenAssignmentsResponse => {
  const [seenAssignments, dispatch] = useReducer(
    (state: SeenAssignments, action: SeenAction) => ({
      ...state,
      [action.assignmentId]: action.hasSeen,
    }),
    {}
  );

  const isAssignmentSeen = (assignmentId: number): boolean => {
    return seenAssignments[assignmentId];
  };

  const markAssignmentSeen = (assignmentId: number): void => {
    dispatch({
      assignmentId,
      hasSeen: true,
    });
  };

  return {
    isAssignmentSeen,
    markAssignmentSeen,
  };
};
