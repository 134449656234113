import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colorMap } from '../../../../globalStyles';
import { FinalButton } from '../../../../Components/FinalButton';
import { NextSteps, useCreateCareTransitionMutation } from '../../../../graphQL';
import { useDrilldownContext } from '../../helpers';
import { Text } from '../../styles';
import { MantraSvg } from '../../../../Components/Flair';
import { COCPathProps } from './types';

export function SelfPay({ onClose, careType }: COCPathProps) {
  const { user, refetch } = useDrilldownContext();
  const [mutate, { error }] = useCreateCareTransitionMutation({
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    const transition = {
      careType,
      userId: user.id,
      nextSteps: NextSteps.ActiveSelfPay,
    };
    mutate({ variables: { transition } });
  }, [careType, user, mutate]);

  return (
    <div>
      {!error ? (
        <div>
          <Text.h2 className="mt3 mb4">Confirmed! Next steps set to Self-Pay.</Text.h2>
          <ActionNeededContainer>
            <div className="flex items-center mb3">
              <MantraSvg fill={colorMap.danger[1]} icon="alert" width={20} />
              <Text.label className="ml2" style={{ color: colorMap.danger[1] }}>
                Patient action needed
              </Text.label>
            </div>
            <Text.bodyBold>
              Patient must now complete the &quot;Confirm Continued Care&quot; task in order to
              continue with Self-Pay, otherwise they will lose access to care.
            </Text.bodyBold>
          </ActionNeededContainer>
          <div className="tc">
            <FinalButton kind="minimal_gray" onClick={onClose} className="mt1 mb3">
              Close
            </FinalButton>
          </div>
        </div>
      ) : (
        <Text.h2 className="mb3">
          There was an error while trying to set the patient&apos;s account to Self-Pay.
        </Text.h2>
      )}
    </div>
  );
}

const ActionNeededContainer = styled.div`
  background: #edf1f7;
  border-radius: 4px;
  border: 1px solid #c5cee0;
  padding: 20px;
  margin-bottom: 20px;
`;
