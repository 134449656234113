import React, { HTMLProps } from 'react';

interface OrNullProps extends HTMLProps<HTMLDivElement> {
  lookupKey: string;
}

export function orNullFactory(lookup: Record<string, React.FC>) {
  return ({ lookupKey, ...props }: OrNullProps) => {
    const E = lookup[lookupKey];
    if (!E) return null;
    return (
      <div {...props}>
        <E />
      </div>
    );
  };
}
