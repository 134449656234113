import { HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { colors, Text } from '../../globalStyles';

export const DateHeading = styled(Text.label)`
  margin-bottom: 0.5rem;
`;

export const SummaryHeading = styled(DateHeading)`
  width: 200px;
`;

export const Wrapper = styled.div`
  margin: 1em auto;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 1120px;
  background: #fff;
  position: relative;
`;
export const Header = styled.div`
  padding: 1em 2em 1.5em 2em;
  display: flex;
  align-items: center;
`;
export const MonthName = styled.h3`
  margin-left: 1em;
`;
export const TzPickerWrapper = styled.div`
  margin-left: auto;
  width: 240px;
`;
export const ChangeMonthButton = styled.button`
  border-radius: 2px;
  background: transparent;
  height: 50px;
  width: 50px;
  border-color: ${props => (props.disabled ? '#f2f2f2' : '#dddddd')};
`;

type GridProps = HTMLProps<HTMLDivElement> & { showSummaryCell?: boolean };

export const Grid = styled.div<GridProps>`
  display: grid;
  ${({ showSummaryCell }) => css`
    grid-template-columns: repeat(${showSummaryCell ? '8' : '7'}, 1fr);
  `}
`;
export const DayNames = styled(Grid)`
  text-align: center;
`;
export const DayCells = styled(Grid)`
  grid-auto-rows: 200px;
  background: ${colors.grey.hr};
  border-top: 8px solid ${colors.grey.hr};
  grid-gap: 1px;
  grid-row-gap: 8px;
`;
export const DayCell = styled.div`
  background: #fff;
`;
export const SummaryCell = styled.div`
  width: 200px;
`;
