import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';

export const SectionContainer = styled.div`
  margin-bottom: 2em;
  background: white;
  padding-top: 1.5em;
  padding-bottom: 2em;
  padding-right: 2em;
  padding-left: 2em;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;

export const AllocationRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.25em 0px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.grey.light};
  }

  &:last-of-type {
    margin-bottom: 1.5em;
  }
`;

export const NoShowLabel = styled(Text.label)`
  & img {
    padding: 0.2em;
    border-radius: 50%;
    background-color: ${colors.grey.light};
  }
`;

export const NotificationContainer = styled(SectionContainer)`
  width: 100%;
  padding: 1rem 1.5rem 1rem 1.5rem;
`;
