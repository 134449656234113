import { styled } from 'baseui';
import { Check } from 'baseui/icon';
import React, { createRef, RefObject, useEffect, useRef, useState } from 'react';
import { margin } from '../../../utils';
import { ProgressProps } from './types';

const Line = styled('div', {
  height: '3px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});
const LabelContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  ...margin('1em 0.25em'),
  fontSize: '13px',
  position: 'relative',
});
const CheckWrapper = styled('div', {
  borderRadius: '50%',
  height: '20px',
  width: '20px',
  background: '#333',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export function ProgressBar({ labels, stepIdx, complete }: ProgressProps) {
  const itemRefs = useRef<RefObject<HTMLElement>[]>(labels.map(() => createRef()));
  const [midpoints, setMidpoints] = useState(labels.map(() => 0));

  const updateMidpoints = () => {
    setMidpoints(value => {
      const nextMidpoints: number[] = [...value];
      for (let i = 0; i < itemRefs.current.length; i += 1) {
        const { current } = itemRefs.current[i];
        if (current) {
          nextMidpoints[i] = current.offsetLeft + current.offsetWidth / 2;
        }
      }
      return nextMidpoints;
    });
  };

  useEffect(updateMidpoints, [stepIdx, itemRefs, setMidpoints]);
  useEffect(() => {
    window.addEventListener('resize', updateMidpoints);
    return () => window.removeEventListener('resize', updateMidpoints);
  });

  return (
    <div>
      <Line
        style={{
          background: complete
            ? '#333'
            : `linear-gradient(to right, #333 ${midpoints[stepIdx]}px, #EEE ${midpoints[stepIdx]}px)`,
        }}
      >
        {labels.map((label, labelIndex) => {
          return (
            (stepIdx > labelIndex || complete) && (
              <CheckWrapper
                key={label}
                style={{ position: 'absolute', left: midpoints[labelIndex] - 5 }}
              >
                <Check size={20} />
              </CheckWrapper>
            )
          );
        })}
      </Line>
      <LabelContainer>
        {labels.map((label, labelIndex) => (
          <span
            style={{ fontWeight: labelIndex <= stepIdx ? 'bold' : 'normal' }}
            ref={itemRefs.current[labelIndex]}
            key={label}
          >
            {label}
          </span>
        ))}
      </LabelContainer>
    </div>
  );
}
