import _ from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dot } from '../../Components/Flair';
import { ListView, ListViewProps } from '../../Components/ListView';
import { useCurrentProvider } from '../../Components/Permissions';
import { colors, Text } from '../../globalStyles';
import { Permission, TicketsQuery, TicketStatus } from '../../graphQL';
import { compareLastNames, getFullName, getFullNameReversed } from '../../modelUtils/users';
import { padLeft } from '../../utils';
import { prioritySorter, ticketCategoryCopy, ticketCategoryOptions } from './helpers';

type Ticket = TicketsQuery['tickets'][number];

type TaskTableProps = Pick<ListViewProps<Ticket>, 'onClick' | 'headRow' | 'analyticsName'> & {
  hideUser?: boolean;
  noFilters?: boolean;
  data: TicketsQuery;
  showBlockedPrefix?: boolean;
};
export const TaskTable = ({
  data,
  hideUser,
  noFilters,
  onClick,
  showBlockedPrefix,
  ...props
}: TaskTableProps) => {
  const { currentProvider, hasPermission } = useCurrentProvider();
  const [newTicketIds, setNewTicketIds] = useState(data.newTickets.map(t => t.id));
  const history = useHistory();

  const userOptions = _(data.tickets)
    .uniqBy(d => d.user?.id)
    .map(({ user }) => ({
      id: user?.id ?? -1,
      label: user ? getFullName(user, { noPreferred: true }) : 'None',
    }))
    .value();

  const requestedByOptions = _(data.tickets)
    .uniqBy(d => d.requester?.id)
    .map(({ requester }) => {
      const id = requester?.id ?? -1;
      const label = id === currentProvider.id ? 'Me' : requester?.name ?? 'None';
      return { id, label };
    })
    .value();

  const assignedToOptions = _(data.tickets)
    .uniqBy(d => d.assignee?.id)
    .map(({ assignee }) => {
      const id = assignee?.id ?? -1;
      const label = id === currentProvider.id ? 'Me' : assignee?.name ?? 'None';
      return { id, label };
    })
    .value();

  const onListClick = onClick
    ? (t: Ticket) => {
        setNewTicketIds(ids => ids.filter(i => i !== t.id));
        onClick(t);
      }
    : undefined;

  return (
    <ListView
      {...props}
      onClick={onListClick}
      paginate={false}
      getKey={t => t.id}
      data={data.tickets}
      initialSortColumn="priority"
      initialSortDirection="desc"
      empty="No tasks"
      filters={[
        {
          options: userOptions,
          key: 'user',
          placeholder: 'Patient',
          test: (i, v: any) => (v === -1 ? !i.user : !!i.user && i.user.id === v),
          hidden: hideUser || noFilters,
        },
        {
          options: assignedToOptions,
          key: 'assignedTo',
          placeholder: 'Assigned to',
          test: (i, v: any) => (v === -1 ? !i.assignee : !!i.assignee && i.assignee.id === v),
          hidden: !hasPermission(Permission.TicketViewAll) || noFilters,
        },
        {
          options: requestedByOptions,
          key: 'requestedBy',
          placeholder: 'Requested by',
          test: (i, v: any) => (v === -1 ? !i.requester : !!i.requester && i.requester.id === v),
          hidden: noFilters,
        },
        {
          options: ticketCategoryOptions,
          key: 'nested-ticketCategories',
          placeholder: 'Category',
          test: (i, v: any) => i.category === v,
        },
      ]}
      columns={[
        {
          title: '#',
          key: 'num',
          width: '5.5rem',
          render: t => (
            <div>
              <Dot color={newTicketIds.includes(t.id) ? colors.primary : 'white'}>
                {padLeft(t.id, 4)}
              </Dot>
            </div>
          ),
          sort: (a, b) => b.id - a.id,
        },
        {
          title: 'Priority',
          key: 'priority',
          render: p => p.priority ?? '',
          sort: (a, b) => prioritySorter(a.priority, b.priority),
        },
        {
          title: 'Patient',
          key: 'user',
          hidden: hideUser,
          render: t => (
            <Text.linkButton
              onClick={() => history.push(`/users/${t.user?.id}`)}
              className="truncate b"
            >
              {t.user ? getFullNameReversed(t.user, { noPreferred: true }) : ''}
            </Text.linkButton>
          ),
          sort: (a, b) => (a.user && b.user && compareLastNames(a.user, b.user)) || 0,
        },
        {
          title: 'Objective',
          width: '3fr',
          key: 'title',
          render: p => {
            if (p.status === TicketStatus.Blocked && showBlockedPrefix) {
              return `[Blocked] ${p.title}`;
            }
            return p.title;
          },
        },
        {
          title: 'Requested by',
          key: 'requester',
          width: '2fr',
          render: ({ requester }) => {
            if (!requester) return '';
            if (requester.id === currentProvider.id) return 'Me';
            return requester.name;
          },
        },
        {
          title: 'Category',
          key: 'category',
          width: '1.5fr',
          render: ({ category }) => ticketCategoryCopy[category],
        },
      ]}
    />
  );
};
