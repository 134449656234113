import { ModalBody, ModalHeader } from 'baseui/modal';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Text } from '../globalStyles';
import { PageTitle } from '../Pages/Shared';
import { borderRadius } from '../utils';
import { FinalButton } from './FinalButton';
import { InputRHF } from './Form';
import { modalFactory } from './Modal';

type Props<A, B> = {
  phoneLastFour: string;
  resendCode: () => Promise<A>;
  onVerify: (code: string) => Promise<B>;
};

export function TwoFactor<A, B>({ phoneLastFour, resendCode, onVerify }: Props<A, B>) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<{ code: string }>();

  const submit = form.handleSubmit(async ({ code }) => {
    try {
      setLoading(true);
      await onVerify(code);
    } finally {
      setLoading(false);
    }
  });

  const TwoFactorModal = modalFactory({ style: { ...borderRadius('4px'), maxWidth: '350px' } });

  return (
    <>
      <TwoFactorModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalHeader>
          <Text.bodyBold>
            We&apos;ve sent you a new verification code to (***)***-{phoneLastFour}.
          </Text.bodyBold>
        </ModalHeader>
        <ModalBody>
          <Text.body className="mb3">
            If you didn’t receive a code, contact us at{' '}
            <Text.externalLink href="mailto:hi@mantrahealth.com" target="_blank">
              hi@mantrahealth.com{' '}
            </Text.externalLink>
            or <span className="b">(800) 464-2083</span> for assistance.
            <Text.linkButton
              onClick={() => setModalOpen(false)}
              kind="basic"
              className="ma4 db center"
            >
              Close
            </Text.linkButton>
          </Text.body>
        </ModalBody>
      </TwoFactorModal>
      <PageTitle className="mb3">Enter your verification code</PageTitle>
      <Text.bodySmall className="mb3">
        A text message with a verification code has been sent to (***)***-
        {phoneLastFour}
      </Text.bodySmall>
      <Text.bodySmall className="mb3">
        Didn&apos;t receive a code?{' '}
        <Text.linkButton
          onClick={async () => {
            await resendCode();
            setModalOpen(true);
          }}
        >
          Resend code
        </Text.linkButton>
      </Text.bodySmall>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <InputRHF
            name="code"
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
          />
          <FinalButton
            type="submit"
            className="w-100"
            loading={loading}
            kind="primary"
            data-cy="2factor-submit"
          >
            Verify
          </FinalButton>
        </form>
      </FormProvider>
    </>
  );
}
