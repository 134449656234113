import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { AdminFindApptsAtRiskQuery, useAdminFindApptsAtRiskQuery } from '../../../../../graphQL';
import { TimeZone } from '../../../../../utils';
import { formatAllocation } from '../availabilityUtils';
import { isCompletedAllocation, LocalAllocation } from './types';

const DEFAUT_CONFLICT_LIMIT = 5;

type HookArgs = {
  variables: {
    date: Moment;
    providerId: number;
    timezone: TimeZone;
    weekly: boolean;
    allocations: LocalAllocation[];
  };
  skip?: boolean;
  limit?: number;
};

export const useConflictWarnings = ({ variables, skip, limit }: HookArgs) => {
  // The fallback is always the latest data, otherwise data can be undefined
  const [apptsAtRisk, setApptsAtRisk] = useState<AdminFindApptsAtRiskQuery['adminFindApptsAtRisk']>(
    []
  );

  const { date, providerId, weekly, timezone, allocations } = variables;
  const { data } = useAdminFindApptsAtRiskQuery({
    variables: {
      date: date.format('YYYY-MM-DD'),
      providerId,
      timezone,
      weekly,
      allocations: allocations.filter(isCompletedAllocation).map(formatAllocation),
    },
    skip,
  });

  // The fallback is always the latest data, otherwise data can be undefined causing a glitchy UI
  useEffect(() => {
    if (data?.adminFindApptsAtRisk) {
      setApptsAtRisk(data.adminFindApptsAtRisk);
    }
  }, [data?.adminFindApptsAtRisk]);

  const apptAtRiskCount = apptsAtRisk.length;
  const conflictLimit = limit ?? DEFAUT_CONFLICT_LIMIT;

  const sortedConflicts = useMemo(() => {
    return [...apptsAtRisk]
      .sort((a, b) => moment(a.startTime).diff(b.startTime))
      .slice(0, conflictLimit);
  }, [apptsAtRisk, conflictLimit]);

  return {
    firstNConflicts: sortedConflicts,
    remainingConflictCount: Math.max(0, apptAtRiskCount - conflictLimit),
    hasConflicts: Boolean(apptAtRiskCount),
  };
};
