import { useHistory } from 'react-router-dom';

export type To = string;
export type NavigateOptions = {
  replace?: boolean;
};

type UseNavigateReturn = (to: To, toOptions?: NavigateOptions) => void;

export const useNavigate = (): UseNavigateReturn => {
  const history = useHistory();

  return (to: To, toOptions?: NavigateOptions) => {
    if (toOptions?.replace === true) {
      history.replace(to);
    } else {
      history.push(to);
    }
  };
};
