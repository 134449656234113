import styled from 'styled-components';
import { colors } from './colors';

export const body = styled.div`
  padding: 2em;
`;
export const header = styled.h2`
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 0.25em;
`;
export const closeLink = styled.div`
  color: #ababab;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
`;

export const dangerLink = styled.div`
  color: ${colors.danger};
  text-align: center;
  cursor: pointer;
`;
