import moment from 'moment';
import { UsersVerificationListQuery } from '../../graphQL';
import { colors } from '../../globalStyles';

export type VerifiableUser = UsersVerificationListQuery['users'][number];
export type VerificationStatus = 'rejected' | 'unverified' | 'verified' | 'incomplete';

export const sortByStatus = (a: VerifiableUser, _b: VerifiableUser) => {
  const aStatus = a.verificationStatus;
  if (aStatus === 'unverified') return 3;
  if (aStatus === 'rejected') return 2;
  if (aStatus === 'verified') return 1;
  return 0;
};

export const sortBySubmission = (a: VerifiableUser, b: VerifiableUser) => {
  const aCard = a.identificationCard;
  const bCard = b.identificationCard;
  if (aCard && !bCard) {
    return 1;
  }
  if (!aCard && bCard) {
    return -1;
  }
  if (!aCard && !bCard) {
    return 0;
  }
  return moment(bCard?.createdAt).diff(aCard?.createdAt);
};

export const verificationColorMap: Record<string, string> = {
  rejected: colors.danger,
  unverified: colors.warning,
  verified: colors.success,
  incomplete: colors.grey.dark,
};
