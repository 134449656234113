import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { KIND } from 'baseui/notification';
import { Location, LocationDescriptor } from 'history';
import qs from 'querystring';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { CenteredNotification } from '../../Components/Notification';
import { Text } from '../../globalStyles';
import {
  AuthMethod,
  FirstFactorResult,
  useAdminAuthenticateMutation,
  useAuthInstructionsForEmailLazyQuery,
  useProviderIsLoggedInLazyQuery,
} from '../../graphQL';
import { getDeviceToken, setAuthToken } from '../../token';
import { AuthError } from './AuthError';
import { SessionPageWrapper } from './Shared';
import { Form, inputOverrides, LinkWrapper } from './Style';
import { TwoFactorAuth } from './TwoFactorAuth';

type LoginDetails = {
  email: string;
  password: string;
};

export const Login = () => {
  const history = useHistory();
  const location = useLocation<{ from?: Location }>();
  const [providerIsLoggedIn] = useProviderIsLoggedInLazyQuery({
    onCompleted: ({ providerIsLoggedIn: { isLoggedIn, authToken } }) => {
      if (isLoggedIn && authToken) {
        setAuthToken(authToken);
        history.push('/');
      }
    },
  });
  const [firstFactorResult, setFirstFactorResult] = useState<Pick<
    FirstFactorResult,
    'firstFactorToken' | 'phoneLastFour'
  > | null>(null);
  const [getInstructions, instructionsQuery] = useAuthInstructionsForEmailLazyQuery({
    onCompleted: res => {
      if (res.authInstructionsForEmail.authMethod === AuthMethod.Saml) {
        window.location.href = res.authInstructionsForEmail.samlEntryPoint!;
      }
    },
  });
  const [authenticate, authenticateMutation] = useAdminAuthenticateMutation({ errorPolicy: 'all' });
  const queryString = qs.parse(location.search.replace(/^\?/, ''));
  const loggedOutForInactivity = (queryString.loggedOutForInactivity as string) || undefined;
  const ssoError = !!queryString.ssoError;
  const next: LocationDescriptor = location.state?.from ?? '/';

  useEffect(() => {
    providerIsLoggedIn();
  }, [providerIsLoggedIn]);

  useEffect(() => {
    if (loggedOutForInactivity) {
      // eslint-disable-next-line no-alert
      setTimeout(() => alert('You have been logged out for inactivity.'));
    }
  }, [loggedOutForInactivity]);

  const { handleSubmit, register, errors } = useForm<LoginDetails>();

  const onSubmit = async ({ email, password }: LoginDetails) => {
    if (password) {
      await loginWithPassword(email, password);
    } else {
      getInstructions({ variables: { email } });
    }
  };

  const loginWithPassword = async (email: string, password: string) => {
    const { data } = await authenticate({
      variables: { email, password, deviceToken: getDeviceToken() },
    });
    const auth = data?.adminAuthenticate;

    if (auth?.authToken) {
      setAuthToken(auth.authToken);
      history.push(next);
    } else if (auth?.twoFactorNeeded && auth?.firstFactorResult) {
      setFirstFactorResult(auth.firstFactorResult);
    }
  };

  if (firstFactorResult)
    return (
      <TwoFactorAuth
        {...firstFactorResult}
        nextPage={next}
        onBack={() => setFirstFactorResult(null)}
      />
    );

  return (
    <SessionPageWrapper title="Log In">
      {(authenticateMutation.error || instructionsQuery.error) && (
        <AuthError
          error={authenticateMutation.error || instructionsQuery.error!}
          contents={authenticateMutation?.error?.message}
        />
      )}
      {ssoError && (
        <AuthError contents="We couldn't find an account associated with this email. Please contact your organization administrator or hi@mantrahealth.com for help." />
      )}
      {authenticateMutation.data?.adminAuthenticate.passwordCorrect === false && (
        <CenteredNotification kind={KIND.negative}>
          Incorrect email or password.
        </CenteredNotification>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormControl caption={errors.email && errors.email.message}>
          <Input
            inputRef={register({ required: 'This field is required' })}
            name="email"
            type="email"
            error={!!errors.email}
            size="large"
            placeholder="Email"
            overrides={inputOverrides}
          />
        </FormControl>
        {instructionsQuery.data?.authInstructionsForEmail.authMethod === AuthMethod.Password && (
          <FormControl caption={errors.password && errors.password.message}>
            <Input
              inputRef={register({ required: 'This field is required' })}
              type="password"
              name="password"
              error={!!errors.password}
              size="large"
              placeholder="Password"
              overrides={inputOverrides}
            />
          </FormControl>
        )}
        <FinalButton
          type="submit"
          kind="primary"
          className="w-100"
          data-cy="login-submit"
          loading={
            authenticateMutation.loading ||
            instructionsQuery.loading ||
            instructionsQuery.data?.authInstructionsForEmail.authMethod === AuthMethod.Saml
          }
        >
          Submit
        </FinalButton>
        <LinkWrapper>
          <Text.link to="/forgotpassword">Forgot password?</Text.link>
        </LinkWrapper>
      </Form>
    </SessionPageWrapper>
  );
};
