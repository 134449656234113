import { compact, omit } from 'lodash';
import { useMemo } from 'react';
import { TraitCategory as BaseTraitCategory, useTraitCategoriesQuery } from '../../../graphQL';
import { useQueryParams } from '../../../Hooks';
import { Nullable } from '../../../types';

export type TraitCategory = BaseTraitCategory | 'gender';

export type AddTraitFn = (c: TraitCategory, o: string) => void;
export type ToggleTraitFn = (c: TraitCategory, o: Nullable<string>) => void;

export const useTraitFilters = () => {
  const { data: traitCategoryData } = useTraitCategoriesQuery({
    fetchPolicy: 'cache-first',
  });

  const [parsedSearch, updateQueryParams] = useQueryParams();
  const allProviderTraits = traitCategoryData?.traitCategories ?? [];

  const traitFilterMap = useMemo(() => {
    const { gender } = parsedSearch;
    return allProviderTraits.reduce(
      (acc, { category, values }) => {
        const selected = parsedSearch[category];
        const traits = selected ? values.filter(v => selected === v || selected.includes(v)) : [];
        return { ...acc, [category]: traits };
      },
      { gender: compact(Array.isArray(gender) ? gender : [gender]) } as Record<
        TraitCategory,
        string[]
      >
    );
  }, [allProviderTraits, parsedSearch]);

  const addMultiTrait: AddTraitFn = (category, opt) => {
    const { [category]: val, ...q } = parsedSearch;
    const arr = compact(Array.isArray(val) ? val : [val]);
    const newVal = arr.includes(opt) ? arr.filter(v => v !== opt) : [...arr, opt];
    updateQueryParams({ ...q, [category]: newVal });
  };

  // used on gender single select
  const addSingleTrait: ToggleTraitFn = (category, opt) => {
    const { [category]: val, ...q } = parsedSearch;
    const hasValue = Array.isArray(val) ? val.includes(opt) : val === opt;
    updateQueryParams({ ...q, [category]: hasValue ? undefined : opt });
  };

  const clearTraitFilters = () => {
    const catagories = allProviderTraits.map(c => String(c.category)).concat('gender');
    const cleared = omit(parsedSearch, catagories);
    updateQueryParams(cleared);
  };

  return {
    addMultiTrait,
    addSingleTrait,
    clearTraitFilters,
    updateQueryParams,
    allProviderTraits,
    traitFilterMap,
    parsedSearch,
  };
};
