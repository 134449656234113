import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { FinalButton } from '../../../Components/FinalButton';
import { CopyToClipboard, TextIcon } from '../../../Components/Flair';
import { Grid } from '../../../Components/Grid';
import { useCurrentProvider } from '../../../Components/Permissions';
import { useVideoContextV2 } from '../../../Components/Video/VideoProvider';
import { colors, Text } from '../../../globalStyles';
import { VideoCallDataFragment, VideoCallSidebarQuery } from '../../../graphQL';
import { getFullName } from '../../../modelUtils/users';
import { formatPhoneNumber } from '../../../utils';
import { AgeAndPronouns } from '../../Users/Profile';
import { ConfirmLikenessDialogV2 } from '../ConfirmLikenessDialogV2';
import { formatUserAddress } from '../VideoCallSidebar';

type VideoCallSidebarStudentProps = {
  data: VideoCallSidebarQuery;
  appointment: VideoCallDataFragment;
};
export const StudentTab = ({ data, appointment }: VideoCallSidebarStudentProps) => {
  const { currentProvider } = useCurrentProvider();
  const context = useVideoContextV2();
  const { openPathWithPopover, isLikenessConfirmed, isUserConnected } = context;

  const { user } = data;

  const fallBack = 'unspecified';
  const emergContact = user.emergencyContacts.find(contact => contact.primary);
  const displayInfo = [
    {
      label: 'dob',
      value: user.birthDate ? moment(user.birthDate).format('MM/DD/YYYY') : fallBack,
    },
    {
      label: 'address',
      value: user.primaryAddress ? formatUserAddress(user.primaryAddress) : fallBack,
    },
    { label: 'phone', value: user.phone ? formatPhoneNumber(user.phone) : fallBack },
    {
      label: 'Emerg. Contact',
      value: emergContact ? `${emergContact.name} ${emergContact.phoneNumber}` : fallBack,
    },
  ];

  const bookPath = `/book?userId=${user.id}&providerId=${currentProvider.id}`;

  return (
    <div className="mt4">
      <section>
        <Text.h2 className="mv0">{getFullName(user)}</Text.h2>
        {user.customerId && (
          <CopyToClipboard copyContent={user.customerId} size={20}>
            <Text.body kind="grayText">{user.customerId}</Text.body>
          </CopyToClipboard>
        )}
        <AgeAndPronouns user={user} />
        {user.organization && (
          <Text.body kind="grayText">
            <TextIcon opacity={0.6} preset="university">
              {user.organization.name}
            </TextIcon>
          </Text.body>
        )}
      </section>
      <section>
        <Grid gridTemplateColumns="4.25rem 1fr" gap=".35rem 1rem" className="mb2">
          {displayInfo.map(({ label, value }) => (
            <React.Fragment key={label}>
              <Text.label>{label}</Text.label>
              <Text.body className="mt0" style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {value}
              </Text.body>
            </React.Fragment>
          ))}
        </Grid>
      </section>
      <section>
        <ButtonSection>
          <FinalButton
            className="w-100 tl"
            iconLeft={{ icon: 'iconsUserSvg', size: 20 }}
            kind="minimal_black"
            onClick={() => openPathWithPopover(`/users/${user.id}`)}
          >
            View Full Patient Chart
          </FinalButton>
        </ButtonSection>
        <ButtonSection>
          <FinalButton
            className="w-100 tl"
            iconLeft={{ icon: 'iconsBlackApptSvg', size: 20 }}
            kind="minimal_black"
            onClick={() => openPathWithPopover(bookPath)}
          >
            Schedule Visit
          </FinalButton>
        </ButtonSection>
      </section>
      <section>
        {!isLikenessConfirmed && (
          <ConfirmLikenessDialogV2
            disabled={!isUserConnected}
            setConfirmed={() => context.setState({ isLikenessConfirmed: true })}
            name={getFullName(appointment.user!)}
            selfieUrl={appointment.user.selfie?.url}
          />
        )}
      </section>
    </div>
  );
};

const ButtonSection = styled.div`
  border-top: 1px solid ${colors.grey.lightBorder};

  &:last-child {
    border-bottom: 1px solid ${colors.grey.lightBorder};
  }
`;
