import { ChevronLeft, ChevronRight } from 'baseui/icon';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Text } from '../../globalStyles';
import { Entitlement, useOrganizationEntitlementsQuery } from '../../graphQL';
import { cx } from '../../utils';
import { Bordered } from '../Bordered';
import { BookableHoursTag } from '../DedicatedGroupModel/BookableHoursTag';
import { getSpanned } from '../GridSpan';
import { InlineSVG } from '../Icons';
import { MantraSpinner } from '../LoadingOverlay';
import { useCurrentProvider } from '../Permissions';
import { Tooltip } from '../Tooltip';
import { SelectedProvidersBanner } from './Banner';
import { useProviderNetworkContext } from './ProviderNetworkContext';
import { ProviderNetworkOptions } from './ProviderNetworkOptions';
import * as Styles from './Styles';
import { ProviderTraitsFilter, TraitsFilterDisplay } from './TraitFilters';

export type ProviderNetworkShellProps = {
  withSearch?: boolean;
  selectedProvidersButtonText?: string;
  onSelectProviders?: (p: number[]) => void;
  header?: React.ReactElement;
  banner?: React.ReactElement;
};

export const ProviderNetworkShell: React.FC<ProviderNetworkShellProps> = ({
  withSearch,
  children,
  header,
  selectedProvidersButtonText,
  onSelectProviders,
  banner,
}) => {
  const {
    loading,
    providers,
    selectedProviders,
    hasRemainingHours,
    appointmentType,
    organizationId,
    dedicatedGroupModelActive,
  } = useProviderNetworkContext();

  const { appView } = useCurrentProvider();

  const { data: orgEntData, loading: orgEntLoading } = useOrganizationEntitlementsQuery({
    variables: { id: organizationId! },
    skip: !organizationId,
  });

  if (orgEntLoading) {
    return <MantraSpinner />;
  }

  const orgWholeCampusCare = !!orgEntData?.organization.entitlements.some(
    ent => ent.key === Entitlement.WholeCampusCare
  );

  // WCC + DGM MCP users should not see references to bookable hours (but their orgs still use them)
  // Sessions does not show these
  const showDgmBookableHours =
    dedicatedGroupModelActive && (appView === 'oz' || !orgWholeCampusCare);

  const providerCount = providers.length;
  return (
    <div className="h-100 bg-white">
      {header && <header className="ph4 pt4 mb3">{header}</header>}
      <section className={cx('bg-white ph4 pb3', !header && 'pt4')}>
        {withSearch && (
          <div className="flex gap-3 justify-between mb3">
            <ProviderNetworkOptions />
          </div>
        )}
        <TraitsFilterDisplay />
      </section>
      {banner && <>{banner}</>}
      {onSelectProviders && (
        <SelectedProvidersBanner
          count={selectedProviders.size}
          buttonText={selectedProvidersButtonText ?? 'Continue >'}
          onSelect={() => onSelectProviders(selectedProviders.toArray())}
        />
      )}
      <section className="h-100">
        <Styles.SpacingGrid borderTop borderSize="0.25rem">
          <Bordered borderRight>
            <ProviderTraitsFilter />
          </Bordered>
          <Styles.ProviderList>
            {
              // div below is for safari browser edgecase
            }
            <div>
              <Styles.ProviderRowItem borderBottom className="header">
                <Text.bodySmall className="self-center">
                  <span className="b">
                    {providerCount} available provider
                    {providerCount === 1 ? '' : 's'}
                  </span>{' '}
                  based on your search
                </Text.bodySmall>
                {!hasRemainingHours && <div />}
                {hasRemainingHours && (
                  <div className="align-to-end">
                    {showDgmBookableHours && (
                      <>
                        <Text.body className="dib mv0 mr1">
                          {appointmentType === 'intake' ? 'Intakes' : 'Follow-ups'} Bookable
                        </Text.body>
                        <Tooltip
                          className="dib"
                          content={
                            appointmentType === 'intake'
                              ? "Intakes Bookable: Your organization's remaining balance of intakes that can be booked for students in the given week"
                              : "Follow-ups Bookable: Your organization's remaining balance of follow-ups that can booked for students in the given week."
                          }
                        >
                          <InlineSVG style={{ marginBottom: -3 }} icon="alert-circle" size={17} />
                        </Tooltip>
                      </>
                    )}
                  </div>
                )}
                <DateOptions showBookableHours={showDgmBookableHours} />
              </Styles.ProviderRowItem>
              {loading && <MantraSpinner className="mt5 center" />}
              {!loading && <>{children}</>}
            </div>
          </Styles.ProviderList>
        </Styles.SpacingGrid>
      </section>
    </div>
  );
};

type DateProps = {
  displayPassedDays?: boolean;
  showBookableHours?: boolean;
};

const DateOptions = ({ displayPassedDays, showBookableHours = true }: DateProps) => {
  const { days, jumpTo } = useProviderNetworkContext();

  if (!days) {
    return null;
  }

  const disableBackButton =
    !displayPassedDays && days[0].clone().startOf('day').isSameOrBefore(moment());

  const backButtonClass = cx(
    { pointer: !disableBackButton, dim: !disableBackButton, 'o-50': disableBackButton },
    'flex items-center justify-center bg-white bw0 pa0'
  );

  return (
    <div className="flex flex-row" style={{ alignItems: 'self-end' }}>
      <div className="flex-1">
        <Styles.DayGrid dayColumns={days.length}>
          <button
            type="button"
            className={backButtonClass}
            onClick={
              disableBackButton
                ? undefined
                : () => jumpTo(days[0].clone().subtract(days.length, 'day'))
            }
          >
            <ChevronLeft size={40} />
          </button>
          <div className="days">
            {days.map((d, i) => (
              <Text.body className="tc" key={i}>
                {d.format('ddd')}
                <br />
                <strong>{d.format('MMM Do')}</strong>
              </Text.body>
            ))}
            {showBookableHours && <BookableHours />}
          </div>
          <button
            type="button"
            className="flex items-center justify-center bg-white dim bw0 pointer pa0"
            onClick={() => jumpTo(days[days.length - 1].clone().add(1, 'day'))}
          >
            <ChevronRight size={40} />
          </button>
        </Styles.DayGrid>
      </div>
    </div>
  );
};

const BookableHours = () => {
  const { remainingHours: hoursBreakdown, days } = useProviderNetworkContext();

  if (!days || !hoursBreakdown || !hoursBreakdown.length) {
    return null;
  }

  const hoursCountLookup = new Map(
    hoursBreakdown.map(b => [moment(b.date).format('YYYY-MM-DD'), b.remainingHours])
  );

  return (
    <>
      {getSpanned({
        endIdx: days.length - 1,
        getVal: i => hoursCountLookup.get(days[i].format('YYYY-MM-DD')) || 0,
      }).map((x, i) => {
        return (
          <GridSpan key={`${i}-${x.val}`} start={x.spanStart} span={x.span}>
            <BookableHoursTag count={x.val} />
          </GridSpan>
        );
      })}
    </>
  );
};

const GridSpan = styled.div<{ start: number; span: number }>`
  grid-column-start: ${({ start }) => start};
  grid-column-end: span ${({ span }) => span};
`;
