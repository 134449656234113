import { last } from 'lodash';
import { Organization } from '../../graphQL';

export const phoneNumberPattern = /^\(\d{3}\)\s\d{3}-\d{4}( Ext\. [0-9]{3,5}){0,1}$/;
export const emailPattern = /.+@.+/;

export const validatePhoneNumber = (value: string) =>
  phoneNumberPattern.test(value) || 'Please enter a valid phone-number.';

export const getEmailValidator = (org?: Pick<Organization, 'emailDomains' | 'name'> | null) => {
  const validators: Record<string, (v: string) => true | string> = {
    at: v => emailPattern.test(v) || 'Please enter a valid email address',
  };
  if (org) {
    validators.org = (v: string) => {
      const domain = last(v.split('@'));
      if (!domain) return 'Please include a domain';
      if (!org.emailDomains.includes(domain)) return `Please enter a valid domain for ${org.name}`;
      return true;
    };
  }
  return validators;
};
