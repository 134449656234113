import React from 'react';
import { Layout, Spinner } from '../../core';

type PageLoadingProps = {
  pageName: string;
};

export const PageLoading = ({ pageName }: PageLoadingProps): JSX.Element => {
  return (
    <Layout.Center height="100%" width="100%" testID="page-loading">
      <Spinner accessibilityLabel={`Loading ${pageName}`} />
    </Layout.Center>
  );
};
