import { Button, IButtonProps } from 'native-base';
import React, { FC } from 'react';

export type ButtonProps = IButtonProps;
export type ButtonComponent = FC<
  ButtonProps & {
    testID: string;
  }
>;

export { Button } from 'native-base';

const buttonFactory =
  (styles: IButtonProps): ButtonComponent =>
  ({ children, ...props }) => {
    return (
      <Button {...styles} {...props}>
        {children}
      </Button>
    );
  };

export const primarySmall = buttonFactory({
  size: 'small',
  variant: 'solid',
});

export const primaryMedium = buttonFactory({
  size: 'medium',
  variant: 'solid',
});

export const primaryLarge = buttonFactory({
  size: 'large',
  variant: 'solid',
});

export const secondarySmall = buttonFactory({
  size: 'small',
  variant: 'subtle',
});

export const secondaryMedium = buttonFactory({
  size: 'medium',
  variant: 'subtle',
});

export const secondaryLarge = buttonFactory({
  size: 'large',
  variant: 'subtle',
});

export const tertiarySmall = buttonFactory({
  size: 'small',
  variant: 'link',
});

export const tertiaryMedium = buttonFactory({
  size: 'medium',
  variant: 'link',
});

export const tertiaryLarge = buttonFactory({
  size: 'large',
  variant: 'link',
});

export const dangerSmall = buttonFactory({
  size: 'small',
  variant: 'danger',
});

export const dangerMedium = buttonFactory({
  size: 'medium',
  variant: 'danger',
});

export const dangerLarge = buttonFactory({
  size: 'large',
  variant: 'danger',
});

export const successSmall = buttonFactory({
  size: 'small',
  variant: 'success',
});

export const successMedium = buttonFactory({
  size: 'medium',
  variant: 'success',
});

export const successLarge = buttonFactory({
  size: 'large',
  variant: 'success',
});

export const skillSmall = buttonFactory({
  size: 'small',
  variant: 'skill',
});

export const skillMedium = buttonFactory({
  size: 'medium',
  variant: 'skill',
});

export const skillLarge = buttonFactory({
  size: 'large',
  variant: 'skill',
});
