import styled from 'styled-components';

export const PopoverContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
`;

// [height: 0] this makes it so that the height is set entirely by the flex-grow prop
export const PopoverBody = styled.div`
  padding: 2rem 4rem;
  height: 0;
  flex-grow: 1;
  overflow-y: scroll;

  ${/* Important for Edge */ ''}
  position: relative;
`;
