import { Set as ImSet } from 'immutable';
import { castArray, chain, clamp, times } from 'lodash';
import moment, { Moment } from 'moment';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQueryParams } from '../../Hooks';
import { SelectEntityAction, useSelectedEntities } from '../../Hooks/useSelectedEntities';
import {
  CareType,
  Entitlement,
  Organization,
  ProviderGenderSearch,
  ProviderNetworkSearchQuery,
  ProviderNetworkSearchQueryVariables,
  RemainingHoursForRangeQuery,
  StateCodes,
  TraitCategoriesQuery,
  User,
  useOrganizationEntitlementsQuery,
  useProviderNetworkSearchQuery,
} from '../../graphQL';
import { Nullable, RequireJust, XOR } from '../../types';
import { useOrganizationDGMConstraints } from '../DedicatedGroupModel/useOrganizationDGMConstraints';
import { events, useEvents } from '../Events/EventsProvider';
import { useCurrentProvider } from '../Permissions';
import { useContractLimits } from '../Scheduling/Hooks/useContractLimits';
import { AddTraitFn, ToggleTraitFn, TraitCategory, useTraitFilters } from './hooks/useTraits';
import { isSelfPayReferral } from './providerNetworkUtils';
import { SearchProvider } from './types';

// number of days displayed at a time
const NUMBER_OF_DAYS_DISPLAYED = 3;

type SearchByUser = Pick<User, 'id'> & {
  organization?: Nullable<Pick<Organization, 'id'>>;
  primaryAddressState?: Nullable<string>;
};

export type NetworkProviders = ProviderNetworkSearchQuery['providerNetworkSearch']['providers'];

type ProviderNetworkContextProviderProps = {
  withDateRange?: boolean;
  existingSelections?: number[];
  searchBy: XOR<{ organizationId?: number }, { user: SearchByUser }> & {
    careType?: CareType;
    appointmentType?: string;
  };
};

type IProviderNetworkContext = {
  allProviderTraits: TraitCategoriesQuery['traitCategories'];
  hasActiveTraits: boolean;
  selectedProviders: ImSet<number>;
  dispatchSelectedProviders: React.Dispatch<SelectEntityAction<number>>;
  providers: NetworkProviders;
  getSelectedProviders: () => NetworkProviders;
  outOfNetworkCount: number;
  stateNotAllowed?: StateCodes;
  searchVariables: ProviderNetworkSearchQueryVariables;
  loading: boolean;
  isSelfPayFlow: boolean;
  days: Nullable<Moment[]>;
  startDate: Moment;
  jumpDays: () => void;
  jumpTo: (m: Moment) => void;
  organizationId?: Nullable<number>;
  organizationName?: Nullable<string>;
  remainingHours?: Nullable<RemainingHoursForRangeQuery['remainingHoursForRange']>;
  nextAvailableDGMDate?: Nullable<Date>;
  hasRemainingHours: boolean;
  dedicatedGroupModelActive?: boolean;
  traitFilterMap: Record<TraitCategory, string[]>;
  addTrait: AddTraitFn;
  toggleTrait: ToggleTraitFn;
  clearTraitFilters: () => void;
  appointmentType: string;
  contractStudentCapacityReached: boolean;
  contractSessionCapacityReached: boolean;
  sessionModelActive: boolean;
};

const ProviderNetworkContext = createContext<IProviderNetworkContext | null>(null);

export const ProviderNetworkContextProvider = ({
  children,
  withDateRange: withDateRangeC,
  existingSelections,
  searchBy,
}: PropsWithChildren<ProviderNetworkContextProviderProps>) => {
  const { currentProvider } = useCurrentProvider();
  const { track: trackEvent } = useEvents();
  const {
    traitFilterMap,
    allProviderTraits,
    addMultiTrait: addTrait,
    addSingleTrait: toggleTrait,
    clearTraitFilters,
  } = useTraitFilters();

  // used in booking flows
  const [startDate, setStartDate] = useState(moment().startOf('d'));

  // from the browser's url
  const [{ payerId, name, state: queryState, careType: careTypeParam, gender, endTime }] =
    useQueryParams<'payerId' | 'name' | 'state' | 'careType' | 'gender' | 'endTime'>();

  const state = Object.values(StateCodes).includes(queryState)
    ? (queryState as StateCodes)
    : undefined || (searchBy.user?.primaryAddressState as StateCodes | undefined) || undefined;

  // "searchBy" is an XOR type so never expect both values to exist on "searchBy"
  const organizationId = searchBy.organizationId || searchBy.user?.organization?.id;
  const userId = searchBy?.user?.id;

  // if careType is not specified try using one that might be in the query params
  const careType: CareType = searchBy.careType || careTypeParam;

  const appointmentType = searchBy.appointmentType ?? 'intake';

  const isSelfPayFlow = careType && isSelfPayReferral(currentProvider, careType);

  const withDateRange = withDateRangeC && !isSelfPayFlow;

  const days = useMemo(() => {
    return withDateRange
      ? times(NUMBER_OF_DAYS_DISPLAYED, i => startDate.clone().add(i, 'day'))
      : null;
  }, [startDate, isSelfPayFlow, withDateRange]);

  const { data: entitlementData } = useOrganizationEntitlementsQuery({
    variables: { id: organizationId ?? 0 },
    skip: !organizationId,
  });
  const sessionModelActive = !!entitlementData?.organization.entitlements.some(
    ent => ent.key === Entitlement.SessionsModel
  );

  const {
    remainingHours,
    nextAvailableAppointmentDate,
    dedicatedGroupModelActive,
    organizationName,
  } = useOrganizationDGMConstraints({
    variables: {
      organizationId: organizationId!,
      userId: searchBy.user?.id,
      careType,
      appointmentType,
      start: days?.[0].toISOString()!,
      end: days?.[days.length - 1].toISOString()!,
      isOrganizationCareFlow: !isSelfPayFlow,
    },
    skip: !organizationId || !days,
  });

  // used in views like "suggested providers"
  const [selectedProviders, dispatchSelectedProviders] = useSelectedEntities({
    existingSelections,
  });

  const activeTraits = useMemo(() => {
    return Object.entries(traitFilterMap)
      .filter(([_, values]) => !!values?.length)
      .map(([category, values]) => ({ category: category as TraitCategory, values }));
  }, [traitFilterMap]);

  const parseGenderValue = (
    value: string | string[] | null | undefined
  ): ProviderGenderSearch[] | undefined => {
    if (!value) {
      return undefined;
    }

    const filtered = castArray(value).filter((genderValue): genderValue is ProviderGenderSearch => {
      return genderValue in ProviderGenderSearch;
    });

    return filtered.length > 0 ? filtered : undefined;
  };

  const searchVariables = {
    traits: activeTraits.length
      ? activeTraits.filter(
          (t): t is typeof t & { category: Exclude<typeof t['category'], 'gender'> } =>
            t.category !== 'gender'
        )
      : undefined,
    name: typeof name === 'string' ? name : undefined,
    state: typeof state === 'string' ? state : undefined,
    gender: parseGenderValue(gender),
    endTime: typeof endTime === 'string' ? moment(endTime).clone().toDate() : undefined,
    organizationId: !isSelfPayFlow ? organizationId : undefined,
    careType,
    payerId,
    userId,
    apptType: appointmentType,
  } as const;

  // searchVariables takes appointmentType to determine which availability is shown
  const { data, loading } = useProviderNetworkSearchQuery({
    variables: searchVariables,
    nextFetchPolicy: 'cache-first',
    skip: !careType,
  });

  // memoize so as not to preform unneeded merge sorts
  const [providers, outOfNetworkCount, stateNotAllowed] = useMemo(() => {
    const outOfNetworkProviderCount = data?.providerNetworkSearch.outOfNetworkCount ?? 0;
    const promotionWindowInDays = data?.promotionWindowInDays ?? 14;

    const soonestAvailability = data
      ? getFirstAvailability(data?.providerNetworkSearch.providers)
      : new Date();

    const promotionWindowThreshold = calculatePromotionWindowThreshold(
      promotionWindowInDays,
      soonestAvailability
    );

    const networkProviders = data?.providerNetworkSearch.providers ?? [];
    const providersWithAvailability = networkProviders
      .filter(p => p.upcomingAvailabilityV4.availability.length > 0)
      .sort(sortProviders(promotionWindowThreshold));
    const providersWithoutAvailability = networkProviders.length - providersWithAvailability.length;

    return [
      providersWithAvailability,
      clamp(outOfNetworkProviderCount - providersWithoutAvailability, 0, outOfNetworkProviderCount),
      data?.providerNetworkSearch.stateNotAllowed || undefined,
    ];
  }, [data]);

  const {
    contractStudentCapacityReached,
    contractSessionCapacityReached,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    contractWeeklyLimitReached, // TODO: This should be used, will be addressed in fast follow tickets
  } = useContractLimits(
    data?.providerNetworkSearch.providers[0]?.upcomingAvailabilityV4.globalBlockers
  );

  // inside a useEffect, because if not, it emits twice
  useEffect(() => {
    if (loading) {
      return;
    }

    const searchResults = providers.map(({ id, upcomingAvailabilityV4 }) => ({
      providerId: id,
      earliestAvailability: upcomingAvailabilityV4.availability[0]?.start,
    }));

    try {
      // send a front-end event that search results were obtained
      trackEvent(events.search.providerSearchResults, {
        searchResults,
        searchVariables,
        providerId: currentProvider.id,
      });
    } catch (trackError) {
      // Do nothing with the error.
    }
  }, [data]);

  const getSelectedProviders = useCallback(() => {
    return providers.filter(p => selectedProviders.has(p.id));
  }, [providers, selectedProviders]);

  const jumpDays = useCallback(() => {
    setStartDate(s => s.clone().add(NUMBER_OF_DAYS_DISPLAYED, 'days'));
  }, [setStartDate]);

  const ctx = {
    allProviderTraits,
    selectedProviders,
    loading,
    getSelectedProviders,
    dispatchSelectedProviders,
    providers,
    searchVariables,
    outOfNetworkCount,
    stateNotAllowed,
    hasActiveTraits: activeTraits.length > 0 || !!searchVariables.gender,
    isSelfPayFlow,
    days,
    startDate,
    setStartDate,
    jumpDays,
    jumpTo: (m: Moment) => setStartDate(m.startOf('d')),
    organizationId,
    organizationName,
    remainingHours,
    nextAvailableDGMDate: nextAvailableAppointmentDate,
    traitFilterMap,
    hasRemainingHours: Boolean(days && remainingHours && remainingHours.length > 0),
    addTrait,
    toggleTrait,
    clearTraitFilters,
    dedicatedGroupModelActive,
    appointmentType,
    contractStudentCapacityReached,
    contractSessionCapacityReached,
    sessionModelActive,
  };

  return <ProviderNetworkContext.Provider value={ctx}>{children}</ProviderNetworkContext.Provider>;
};

const sortProviders = (promotionWindowThreshold: Date) => {
  return function sortByUpcomingAvailabilityAndGuaranteedPayPromotionStatus<
    T extends RequireJust<
      SearchProvider,
      'upcomingAvailabilityV4' | 'eligibleForSchedulingPromotion'
    >
  >(provider1: T, provider2: T, order: 'ASC' | 'DESC' = 'DESC') {
    const direction: Record<typeof order, number> = { ASC: -1, DESC: 1 };

    const provider1FirstAvailability = provider1.upcomingAvailabilityV4.availability[0];
    const provider2FirstAvailability = provider2.upcomingAvailabilityV4.availability[0];

    // Check if provider is guaranteedPay and if their first availability is within the promotion window
    const provider1ShouldBePromoted = providerShouldBePromoted({
      providerEligibleForPromotion: provider1.eligibleForSchedulingPromotion,
      providerFirstAvailabilityStart: provider1FirstAvailability.start,
      promotionWindowThreshold,
    });

    const provider2ShouldBePromoted = providerShouldBePromoted({
      providerEligibleForPromotion: provider2.eligibleForSchedulingPromotion,
      providerFirstAvailabilityStart: provider2FirstAvailability.start,
      promotionWindowThreshold,
    });

    // Compare using calculated guaranteed pay status and promotion window
    if (provider1ShouldBePromoted !== provider2ShouldBePromoted) {
      return provider1ShouldBePromoted ? -1 * direction[order] : direction[order];
    }

    if (!provider1FirstAvailability) return direction[order];
    if (!provider2FirstAvailability) return -1 * direction[order];
    return moment(provider1FirstAvailability.start).isAfter(provider2FirstAvailability.start)
      ? direction[order] * 1
      : direction[order] * -1;
  };
};

const calculatePromotionWindowThreshold = (
  promotionWindowInDays: number,
  soonestAvailability?: Date
): Date => {
  const threshold = soonestAvailability ? new Date(soonestAvailability) : new Date();
  threshold.setDate(threshold.getDate() + promotionWindowInDays);
  return threshold;
};

const providerShouldBePromoted = ({
  providerEligibleForPromotion,
  providerFirstAvailabilityStart,
  promotionWindowThreshold,
}: {
  providerEligibleForPromotion: boolean;
  providerFirstAvailabilityStart: string;
  promotionWindowThreshold: Date;
}): boolean => {
  return (
    providerEligibleForPromotion &&
    !!providerFirstAvailabilityStart &&
    new Date(providerFirstAvailabilityStart).valueOf() < promotionWindowThreshold.valueOf()
  );
};

const getFirstAvailability = (providers: NetworkProviders | undefined): Date | undefined => {
  if (!providers || providers.length === 0) {
    return undefined;
  }
  return new Date(
    chain(providers)
      .map(provider => provider?.upcomingAvailabilityV4?.availability[0]?.start)
      .min()
      .value()
  );
};

export const useProviderNetworkContext = () => {
  const ctx = useContext(ProviderNetworkContext);
  if (!ctx) {
    throw new Error('useProviderNetworkContext called outside of context provider!');
  }
  return ctx;
};
