import { DatePicker } from 'baseui/datepicker';
import { Modal } from 'baseui/modal';
import { pick, range } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ToggleSubscribed } from '../../../Components/Emails/EmailTable';
import { FinalButton } from '../../../Components/FinalButton';
import { FormControl, MultiSelectRHF, Select, SelectRHF } from '../../../Components/Form';
import { Input, Textarea } from '../../../Components/Form/Input';
import { ListView } from '../../../Components/ListView';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { PermsOnly, useCurrentProvider } from '../../../Components/Permissions';
import { Modal as ModalStyle, Text } from '../../../globalStyles';
import {
  OutreachAttemptInput,
  Permission,
  useDeleteOutreachAttemptMutation,
  useOutreachQuery,
  useSetUserSubscribedMutation,
  useUpdateOutreachAttemptMutation,
} from '../../../graphQL';
import { UnexpectedError } from '../../Shared';
import { useDrilldownContext } from '../helpers';

interface NormalizedOutreachItem {
  id: number;
  date: Date | string;
  subject: string;
  outcome: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  attemptNumber?: number | null;
  methods: string[];
  baseType: 'email' | 'sms' | 'manual' | 'zendesk';
  createdBy: {
    id: string | number;
    name: string;
  };
  createdByCustom?: string | null;
}

export function OutreachTab() {
  const { user } = useDrilldownContext();
  const { data, loading, error, refetch } = useOutreachQuery({
    variables: { userId: user.id, userIdFloat: user.id },
  });
  const [editingOutreach, setEditingOutreach] = useState<
    NormalizedOutreachItem | 'new' | undefined
  >();

  const [setSubscribed] = useSetUserSubscribedMutation();

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const normalizedData: NormalizedOutreachItem[] = [
    ...data.userEmails.map(i => ({
      id: i.id,
      date: i.sentAt,
      subject: i.subject,
      outcome: (() => {
        if (i.outcome) return i.outcome;
        if (i.bounced) return 'Bounced';
        if (i.delivered) return 'Delivered';
        if (i.sent) return 'Sent';
        return 'Unknown';
      })(),
      attemptNumber: 1,
      methods: ['Email'],
      baseType: 'email' as const,
      createdBy: {
        id: 'Automated',
        name: 'Automated',
      },
    })),
    ...data.userSmses.map(i => ({
      id: i.id,
      date: i.createdAt,
      subject: i.description,
      outcome: 'Sent',
      attemptNumber: 1,
      methods: ['SMS'],
      baseType: 'sms' as const,
      createdBy: {
        id: 'Automated',
        name: 'Automated',
      },
    })),
    ...data.userOutreachAttempts.map(i => ({
      ...i,
      date: i?.updatedAt ?? i.createdAt,
      outcome: i.outcome,
      baseType: i?.createdByCustom ? ('zendesk' as const) : ('manual' as const),
      createdBy: i.createdBy ?? {
        id: i?.createdByCustom || 'Unknown (Legacy)',
        name: i?.createdByCustom || 'Unknown (Legacy)',
      },
    })),
  ];

  const onSetSubscribed = async (value: boolean) => {
    await setSubscribed({ variables: { userId: user.id, subscribed: value } });
    refetch();
  };

  return (
    <div>
      {editingOutreach && (
        <OutreachModal
          onClose={() => setEditingOutreach(undefined)}
          refetch={refetch}
          item={editingOutreach}
        />
      )}
      <PermsOnly allowed={Permission.MantraAdmin}>
        <ToggleSubscribed
          unsubscribedAt={data.adminUser.unsubscribedAt}
          onSetSubscribed={onSetSubscribed}
        />
      </PermsOnly>
      <ListView
        data={normalizedData}
        getKey={item => item.id}
        initialSortColumn="date"
        initialSortDirection="desc"
        onClick={item => setEditingOutreach(item)}
        additionalControls={
          <PermsOnly allowed={Permission.MantraAdmin}>
            <div style={{ height: 50 }}>
              <FinalButton onClick={() => setEditingOutreach('new')}>
                Add Outreach Attempt
              </FinalButton>
            </div>
          </PermsOnly>
        }
        columns={[
          {
            key: 'date',
            title: 'Date',
            render: item =>
              moment(item.date).format(item.baseType === 'manual' ? 'M/D/YY' : 'M/D/YY h:mma'),
            sort: (a, b) => moment(a.date).diff(moment(b.date)),
          },
          {
            key: 'subject',
            title: 'Subject',
            render: item => item.subject,
            sort: (a, b) => a.subject.localeCompare(b.subject),
          },
          {
            key: 'createdBy',
            title: 'Mantra Contact',
            render: item => (item.createdBy ? item.createdBy?.name : item.createdByCustom),
            sort: (a, b) => a.createdBy.name.localeCompare(b.createdBy.name),
          },
          {
            key: 'outcome',
            title: 'Outcome',
            render: item => item.outcome,
            sort: (a, b) => a.outcome.localeCompare(b.outcome),
          },
          {
            key: 'attempt',
            title: 'Attempt',
            render: item => item.attemptNumber ?? '',
          },
          {
            key: 'methods',
            title: 'Outreach Method',
            render: item => item.methods.join(', '),
          },
        ]}
        filters={[
          {
            key: 'outcome',
            placeholder: 'Outcome',
            options: outcomeOptions.map(i => ({ label: i, id: i })),
            test: (item, value) => !!value && item.outcome === value,
          },
          {
            key: 'createdBy',
            placeholder: 'Mantra Contact',
            options: [
              { id: 'Automated', label: 'Automated' },
              ...data.admins
                .map(i => ({ id: i.id, label: i.name }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            ],
            test: (item, value) => item.createdBy?.id === value,
          },
        ]}
      />
    </div>
  );
}

interface ModalProps {
  onClose: () => void;
  refetch: () => Promise<any>;
  item: NormalizedOutreachItem | 'new';
}

interface FormFields {
  methods: string[];
  date: Date;
  subject: string;
  outcome: string;
  attemptNumber?: number;
  description?: string;
}

const emptyFields: FormFields = {
  methods: [],
  date: moment().startOf('day').toDate(),
  subject: '',
  outcome: '',
};

const outcomeOptions = [
  'Bounced',
  'Connected',
  'Delivered',
  'Did not connect',
  'Email bounced',
  'Sent',
  'Number unavailable',
  'Automated',
];

function OutreachModal({ item, refetch, onClose }: ModalProps) {
  const defaultValues =
    item === 'new'
      ? emptyFields
      : ({
          date: moment(item.date).toDate(),
          outcome: item.baseType === 'zendesk' ? 'Automated' : item.outcome,
          ...pick(item, 'methods', 'subject', 'attemptNumber', 'description'),
        } as FormFields);
  const isAutomated =
    item !== 'new' &&
    (item.baseType === 'email' || item.baseType === 'sms' || item.baseType === 'zendesk');

  const isNew = item === 'new';
  // Automatically added Zendesk items should have an editable description
  // Manual items should be fully editable
  const isEditableAndNotNew = !isNew && (item.baseType === 'manual' || item.baseType === 'zendesk');

  const { user } = useDrilldownContext();
  const form = useForm<FormFields>({ defaultValues });
  const [updateOutreachAttempt] = useUpdateOutreachAttemptMutation();
  const [saving, setSaving] = useState(false);
  const [showingConfirmDelete, setShowingConfirmDelete] = useState(false);
  const { currentProvider } = useCurrentProvider();
  const outreachCreator = item !== 'new' ? item.createdBy.name : currentProvider.name;

  const handleSubmit = form.handleSubmit(async (values: OutreachAttemptInput) => {
    setSaving(true);
    if (item === 'new' || item.baseType === 'manual' || item.baseType === 'zendesk') {
      await updateOutreachAttempt({
        variables: {
          attempt: {
            ...values,
            subject: item === 'new' ? values.subject : values.subject || item.subject,
            id: item === 'new' ? undefined : item.id,
            userId: user.id,
            date: moment(values.date).toISOString(),
          },
        },
      });
    }
    await refetch();
    setSaving(false);
    onClose();
  });

  if (showingConfirmDelete && item !== 'new') {
    return (
      <ConfirmDeleteOutreach
        onConfirm={onClose}
        onCancel={() => setShowingConfirmDelete(false)}
        refetch={refetch}
        id={item.id}
      />
    );
  }

  const modalTitle = (() => {
    if (isNew) return 'New Outreach Attempt';
    if (isEditableAndNotNew) return 'Edit Outreach Attempt';
    return 'Outreach Attempt';
  })();

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose} autoFocus={false}>
      <ModalStyle.body>
        <Text.h2 className="mb3">{modalTitle}</Text.h2>
        <FormProvider {...form}>
          <FormControl name="methods" label="Outreach Method" required>
            <MultiSelectRHF
              name="methods"
              rules={{ required: !isAutomated }}
              options={['Email', 'Text', 'Phone Call', 'Zendesk'].map(i => ({ id: i, label: i }))}
              disabled={isAutomated}
            />
          </FormControl>
          <FormControl name="date" label="Date" required>
            <Controller
              name="date"
              render={({ onChange, value }) => (
                <DatePicker
                  value={value}
                  onChange={({ date }) => onChange(date)}
                  disabled={isAutomated}
                />
              )}
            />
          </FormControl>
          <FormControl name="subject" label="Subject" required>
            <Input
              name="subject"
              ref={form.register({ required: !isAutomated })}
              disabled={isAutomated}
            />
          </FormControl>
          <FormControl name="outcome" label="Outcome" required>
            <SelectRHF
              name="outcome"
              rules={{ required: true }}
              options={outcomeOptions.map(i => ({ id: i, label: i }))}
              disabled={isAutomated}
            />
          </FormControl>
          <FormControl name="createdBy" label="Mantra Contact">
            {/* This is only for show. It is never editable and doesn't do anything. */}
            <Select
              options={[{ id: 0, label: isAutomated ? 'Automated' : outreachCreator }]}
              value={0}
              onChange={() => {}}
              disabled
            />
          </FormControl>
          <FormControl name="attemptNumber" label="Attempt (optional)">
            <SelectRHF
              name="attemptNumber"
              options={range(1, 10).map(i => ({ id: i, label: i.toString() }))}
              disabled={isAutomated}
            />
          </FormControl>
          <FormControl name="description" label="Description (optional)">
            <Textarea
              name="description"
              ref={form.register()}
              disabled={isAutomated && item.baseType !== 'zendesk'}
            />
          </FormControl>
          <div className="flex flex-column gap-3">
            {(isNew || isEditableAndNotNew) && (
              <FinalButton onClick={handleSubmit} loading={saving}>
                {item === 'new' ? 'Add to Outreach' : 'Edit'}
              </FinalButton>
            )}
            {isEditableAndNotNew && (
              <FinalButton kind="outline_danger" onClick={() => setShowingConfirmDelete(true)}>
                Delete
              </FinalButton>
            )}
          </div>
        </FormProvider>
      </ModalStyle.body>
    </Modal>
  );
}

interface ConfirmDeleteProps {
  id: number;
  onConfirm: () => void;
  onCancel: () => void;
  refetch: () => Promise<any>;
}

function ConfirmDeleteOutreach({ id, onConfirm, onCancel, refetch }: ConfirmDeleteProps) {
  const [deleteOutreachAttempt] = useDeleteOutreachAttemptMutation();
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    await deleteOutreachAttempt({ variables: { id } });
    await refetch();
    setLoading(false);
    onConfirm();
  };

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onCancel} autoFocus={false}>
      <ModalStyle.body>
        <Text.h2>Delete this attempt?</Text.h2>
        <Text.body className="mt2">
          Deleting this outreach attempt is an irreversible action.
        </Text.body>
        <div className="flex flex-column gap-2 mt4">
          <FinalButton kind="outline_danger" onClick={handleDelete} loading={loading}>
            Delete
          </FinalButton>
          <FinalButton kind="minimal_black" onClick={onCancel}>
            Close
          </FinalButton>
        </div>
      </ModalStyle.body>
    </Modal>
  );
}
