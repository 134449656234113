import React, { useState } from 'react';
import styled from 'styled-components';
import { FileUploader } from './FileUploader';
import { FinalButton } from './FinalButton';

interface Props {
  initialUrl?: string | null;
  onSelect: (file: File | null) => void;
  accepts?: string[];
  onDropRejected: (files: File[]) => void;
  errorMessage?: string;
  onRetry?: () => void;
}

const PreviewContainer = styled.div`
  margin: auto;
  text-align: center;
`;
const PreviewImage = styled.img`
  max-height: 100px;
  display: block;
  margin: 0.5em auto;
`;

export function ImageUploadSelector({
  initialUrl,
  onSelect,
  accepts,
  onDropRejected,
  errorMessage,
  onRetry,
}: Props) {
  const [imageUrl, setImageUrl] = useState(initialUrl);

  const onDropAccepted = (files: File[]) => {
    const [file] = files;
    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      setImageUrl(fileReader.result?.toString());
      onSelect(file);
    });
    fileReader.readAsDataURL(file);
  };

  const onReplace = () => {
    setImageUrl(null);
    onSelect(null);
  };

  if (!imageUrl) {
    return (
      <>
        <FileUploader
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          errorMessage={errorMessage}
          onRetry={onRetry}
          accept={accepts}
        />
      </>
    );
  }

  return (
    <PreviewContainer>
      <PreviewImage src={imageUrl} />
      <FinalButton kind="minimal_black" onClick={onReplace}>
        Replace
      </FinalButton>
    </PreviewContainer>
  );
}
