const ID_KEY = 'mantra_token';
const DEVICE_KEY = 'mantra_device_token';

export const getAuthToken = () => localStorage.getItem(ID_KEY);
export const setAuthToken = (token: string) => localStorage.setItem(ID_KEY, token);

export const getDeviceToken = () => localStorage.getItem(DEVICE_KEY);
export const setDeviceToken = (token: string) => localStorage.setItem(DEVICE_KEY, token);

export const clearAuthToken = () => localStorage.removeItem(ID_KEY);
