import React from 'react';
import { getStylesheet } from '../../../styles';
import { Heading, Layout } from '../../core';
import { PageContent } from '../pageCore';

export const NotFoundPage = (): JSX.Element => {
  return (
    <PageContent {...styles.page} testID="not-found-page">
      <Layout.VStack {...styles.stack} space={8}>
        <Layout.VStack space={4}>
          <Heading.h6 center>Oops...</Heading.h6>

          <Heading.h2 center>We’re having trouble finding that page.</Heading.h2>
        </Layout.VStack>
      </Layout.VStack>
    </PageContent>
  );
};

const styles = getStylesheet({
  page: {
    justifyContent: 'center',
  },

  stack: {
    alignItems: 'center',
    display: 'flex',
  },
});
