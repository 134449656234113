import styled from 'styled-components';
import { colors } from '../../globalStyles';

export const FieldName = styled.div`
  color: #666666;
  text-transform: uppercase;
  font-size: 0.85em;
  margin-bottom: 0.5em;
  font-weight: bold;
`;

export const FieldValue = styled.div`
  font-weight: bold;
`;

export const Divider = styled.hr`
  border-color: #e9e9e9;
  border-top-width: 0px;
  border-bottom-width: 1px;
  margin: 1.25em 0;
`;

export const PaddedDivider = styled(Divider)`
  margin: 2em;
`;

export const Box = styled.div`
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid ${colors.grey.border};
  box-sizing: border-box;
  padding: 20px;
  & p {
    margin: 0 0 0.5em 0;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
