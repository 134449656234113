import React from 'react';
import styled from 'styled-components';
import { colorMap, colors } from '../../globalStyles';
import { HELVETICA } from '../../globalStyles/text';
import { Icon } from '../Icons';
import { Link } from '../Links';

export const HeadCell = styled.div`
  font-family: ${HELVETICA};
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  background-color: ${colorMap.background[0]};
  color: ${colors.grayText};
  font-weight: bold;
  font-size: 0.875rem;
  box-shadow: none;
  border-bottom: 1px solid ${colors.grey.widgetBorder};
  user-select: none;
`;

export const BodyCell = styled.div`
  padding: 0.5rem 1rem;
  border-top: 1px solid #f0f1f3;
  color: ${colors.text};
  box-sizing: border-box;
  height: 100%;
  min-height: 4rem;
  display: flex;
  align-items: center;
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')}
`;

export const TableControlWrapper = styled.div<{ fullWidth?: boolean }>`
  margin-right: 1em;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '200px')};
  margin-bottom: 1em;
`;

const IconCell = styled(BodyCell)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkCell = styled(Link)`
  cursor: pointer;
  padding: 0;
  background: none;
  outline: none;
  color: inherit;
  text-decoration: unset;
`;

export interface ExternalLinkCellProps {
  to: string;
}
export const ExternalLinkCell = ({ to }: ExternalLinkCellProps) => (
  <IconCell>
    <a href={to} target="_blank" rel="noopener noreferrer">
      <Icon icon="iconsExternalLinkSvg" alt="Open" />
    </a>
  </IconCell>
);
