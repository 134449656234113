import styled from 'styled-components';

export const ItemContainer = styled.div`
  margin-bottom: 24px;

  &[data-read='true'] {
    & div {
      opacity: 95%;
    }
    & img {
      opacity: 80%;
    }
  }
`;

export const ListContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 530px;
`;

export const Hr = styled.div`
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #cccccc;
  margin-bottom: 24px;
`;
