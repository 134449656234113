import { createTheme, lightThemePrimitives } from 'baseui';
import { HELVETICA } from './text';

export const MantraTheme = createTheme(
  { ...lightThemePrimitives, primaryFontFamily: HELVETICA },
  {
    colors: {
      buttonPrimaryFill: '#0179FF',
      buttonPrimaryHover: '#0179FF',
      buttonPrimaryActive: '#0179FF',
      notificationNegativeText: '#F32921',
      notificationNegativeBackground: '#FCE2DD',
      buttonSecondaryFill: '#FFFFFF',
      buttonSecondaryHover: '#FFFFFF',
      buttonSecondaryActive: '#FFFFFF',
      buttonTertiaryHover: '#FFFFFF44',
    },
    borders: {
      buttonBorderRadius: '3px',
      inputBorderRadius: '3px',
    },
  }
);
