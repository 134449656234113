import React from 'react';
import { colors } from '../globalStyles';
import { Icon } from './Icons';

interface Props {
  children: React.ReactNode;
}

export function Warning({ children }: Props) {
  return (
    <div className="ph4 pv3 mv3" style={{ backgroundColor: colors.warningBg, color: '#666666' }}>
      {children}
    </div>
  );
}

export function WarningWithIcon({ children }: Props) {
  return (
    <div
      className="ph3 pv3 ma3 flex"
      style={{ backgroundColor: colors.warningBg, color: colors.text }}
    >
      <Icon icon="iconsAlertSvg" size={20} className="ph2 pt1" />
      <div className="">{children}</div>
    </div>
  );
}
