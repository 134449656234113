import { PLACEMENT, StatefulTooltip } from 'baseui/tooltip';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { colors } from '../globalStyles';
import { borderRadius, cx } from '../utils';
import { Icon } from './Icons';

export type TooltipProps = {
  content: React.ReactNode;
  children?: React.ReactNode;
  size?: number;
  style?: CSSProperties;
  className?: string;
  iconType?: 'iconsQuestionSvg' | 'iconsInfoSvg';
  fontSize?: 'f5' | 'f6' | 'f7';
  showArrow?: boolean;
  placement?: PLACEMENT[keyof PLACEMENT];
};

export const Tooltip = ({
  children,
  content,
  size = 18,
  style,
  className,
  iconType = 'iconsQuestionSvg',
  fontSize = 'f5',
  showArrow = true,
  placement = PLACEMENT.auto,
}: TooltipProps) => (
  <StatefulTooltip
    showArrow={showArrow}
    placement={placement}
    overrides={{
      Body: { style: { ...borderRadius('0.5rem') } },
      Inner: { style: { maxWidth: 200, ...borderRadius('0.5rem'), background: colors.navBg } },
      Arrow: { style: { background: colors.navBg } },
    }}
    content={() => <div className={`mw6 pa2 ${fontSize}`}>{content}</div>}
  >
    <div className={cx('pointer', className)} style={{ width: size, height: size, ...style }}>
      {children ? <>{children}</> : <Icon icon={iconType} size={size} alt="tooltip" />}
    </div>
  </StatefulTooltip>
);
