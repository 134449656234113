import moment from 'moment';
import React from 'react';
import { NonReferralOnly } from '../../Components/Permissions';
import { WizardWrapper } from '../../Components/Wizard';
import { FinalBackButton } from '../../Components/Wizard/BackButton';
import { Text } from '../../globalStyles';
import { PaymentSource, Provider, User } from '../../graphQL';
import { getFullName } from '../../modelUtils/users';
import { Nullable } from '../../types';
import { cx } from '../../utils';
import { paymentSourceCopy } from '../Users/copy';

export const WizardContentWithBack: React.FC<{
  onBack?: () => void;
  className?: string;
  style?: React.HTMLAttributes<HTMLDivElement>['style'];
}> = React.memo(({ children, onBack, className, style }) => (
  <div className={cx('ph6 pb4', className)} style={style}>
    {onBack && <FinalBackButton onClick={onBack} />}
    <WizardWrapper className="pv4">{children}</WizardWrapper>
  </div>
));

type AppointmentOverviewProps = {
  apptUser: Pick<User, 'firstName' | 'lastName' | 'preferredName' | 'customerId'>;
  description: string;
  duration: number;
  apptProvider?: Nullable<Pick<Provider, 'name'>>;
  reschedule?: Nullable<{
    startTime: Date;
    endTime: Date;
  }>;
  paymentSource?: Nullable<PaymentSource>;
  insuranceDetails?: Nullable<{
    payerName: string;
    memberId: string;
  }>;
};

export const AppointmentOverview = (props: AppointmentOverviewProps) => {
  const {
    apptUser,
    description,
    duration,
    reschedule,
    paymentSource,
    insuranceDetails,
    apptProvider,
  } = props;
  return (
    <>
      <Text.h2 kind="grayText">Select date &amp; time</Text.h2>
      <Text.body>
        Type: <strong>{description}</strong>
      </Text.body>
      <Text.body>
        Duration: <strong>{duration} min</strong>
      </Text.body>
      <Text.body>
        Patient:{' '}
        <strong>
          {getFullName(apptUser)}{' '}
          <NonReferralOnly>{apptUser.customerId ? `(${apptUser.customerId})` : ''}</NonReferralOnly>
        </strong>
      </Text.body>
      {apptProvider && (
        <Text.body>
          Provider: <strong>{apptProvider.name}</strong>
        </Text.body>
      )}
      {paymentSource && (
        <Text.body>
          Payment Source: <strong>{paymentSourceCopy[paymentSource]}</strong>
          {insuranceDetails ? ` (${insuranceDetails.payerName}) ${insuranceDetails.memberId}` : ''}
        </Text.body>
      )}
      {reschedule && (
        <Text.body kind="primary">
          Former Time:{' '}
          <strong>
            {moment(reschedule.startTime).format('M/D/YYYY h:mm a')} -{' '}
            {moment(reschedule.endTime).format('h:mm a')}
          </strong>
        </Text.body>
      )}
    </>
  );
};
