import React from 'react';
import { createIcon, Path } from './index';

export const IconPartyPopper = createIcon({
  title: 'A party popper.',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M5.8 11.3L2 22L12.7 18.21"
      stroke="#567B61"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    <Path
      d="M4 3H4.01"
      stroke="#567B61"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    <Path
      d="M22 8H22.01"
      stroke="#567B61"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    <Path
      d="M15 2H15.01"
      stroke="#35405F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    <Path
      d="M22 20H22.01"
      stroke="#35405F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    <Path
      d="M22 2L19.76 2.75C19.1224 2.96239 18.5783 3.38964 18.2208 3.95872C17.8633 4.52781 17.7146 5.20339 17.8 5.87V5.87C17.9 6.73 17.23 7.5 16.35 7.5H15.97C15.11 7.5 14.37 8.1 14.21 8.94L14 10"
      stroke="#35405F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    <Path
      d="M22 13.0001L21.18 12.6701C20.32 12.3301 19.36 12.8701 19.2 13.7801C19.09 14.4801 18.48 15.0001 17.77 15.0001H17"
      stroke="#35405F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    <Path
      d="M11 2L11.33 2.82C11.67 3.68 11.13 4.64 10.22 4.8C9.52 4.9 9 5.52 9 6.23V7"
      stroke="#35405F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    <Path
      d="M11 13C12.93 14.93 13.83 17.17 13 18C12.17 18.83 9.93002 17.93 8.00002 16C6.07002 14.07 5.17002 11.83 6.00002 11C6.83002 10.17 9.07002 11.07 11 13Z"
      stroke="#35405F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
  ],
});
