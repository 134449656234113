import { Box, Flex, IFlexProps, useBreakpointValue } from 'native-base';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import React, { ReactNode } from 'react';

export const View = Box;

export { Box, Center, Flex, HStack, ScrollView, Stack, VStack } from 'native-base';

export type { IStackProps } from 'native-base';
export type { IHStackProps };

type ShiftProps = IFlexProps & {
  alignMobile?: IFlexProps['align'];
  alignNonMobile?: IFlexProps['align'];
  children: ReactNode;
  flip?: boolean;
  justifyMobile?: IFlexProps['justify'];
  justifyNonMobile?: IFlexProps['justify'];
};

const flipDirection = (shouldFlip: boolean, direction: 'column' | 'row'): 'column' | 'row' => {
  if (!shouldFlip) {
    return direction;
  }

  return direction === 'row' ? 'column' : 'row';
};

export const Shift = ({
  alignMobile,
  alignNonMobile,
  children,
  flip = false,
  justifyMobile,
  justifyNonMobile,
  ...flexProps
}: ShiftProps): JSX.Element => {
  const [flexDirection, align, justify] = useBreakpointValue({
    base: ['column', alignMobile, justifyMobile], // mobile
    sm: ['row', alignNonMobile, justifyNonMobile], // tablet+
  });

  const finalDirection = flipDirection(flip, flexDirection);

  return (
    <Flex align={align} justify={justify} {...flexProps} direction={finalDirection}>
      {children}
    </Flex>
  );
};
