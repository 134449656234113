import { isMantraAdmin, useCurrentProvider } from '../Components/Permissions';
import { useOrganizationsSimpleQuery } from '../graphQL';

export const useOrganizationOptions = () => {
  const { currentProvider } = useCurrentProvider();
  const mantraAdmin = isMantraAdmin(currentProvider);
  const { data, loading } = useOrganizationsSimpleQuery({
    fetchPolicy: 'cache-first',
    skip: !mantraAdmin,
  });

  let orgOptions;
  if (mantraAdmin) {
    orgOptions = data?.organizations ?? [];
  } else if (
    // Parent org staff should be able to see all child orgs
    currentProvider.organizations[0].children.length > 0
  ) {
    orgOptions = currentProvider.organizations[0].children;
  } else {
    orgOptions = currentProvider.organizations;
  }

  return {
    loading,
    orgOpts: orgOptions.map(o => ({
      id: o.id,
      label: o.name,
    })),
  };
};
