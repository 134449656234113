/**
 * Heading to Font Sizes (defaults)
 *
 * The <Heading> sizes are different from the default font sizes, the following is the DEFAULT
 * translation from the heading size to the font size in native base.
 *
 *   HeadingSize: FontSize
 *   4xl: { fontSize: { mobile: "6xl", desktop: "7xl" }, letterSpacing: "xl" },
 *   3xl: { fontSize: { mobile: "5xl", desktop: "6xl" }, letterSpacing: "xl" },
 *   2xl: { fontSize: { mobile: "4xl", desktop: "5xl" } },
 *   xl:  { fontSize: { mobile: "3xl", desktop: "4xl" } },
 *   lg:  { fontSize: { mobile: "2xl", desktop: "3xl" } }, ** Overridden below **
 *   md:  { fontSize: "xl" },
 *   sm:  { fontSize: "md" },
 *   xs:  { fontSize: "sm" }
 */

export const headerStyles = {
  Heading: {
    baseStyle: {
      color: 'secondary.900',
      lineHeight: 'xs',

      _dark: {
        color: 'secondary.200',
      },
    },
    defaultProps: {
      letterSpacing: 'sm',
    },
    variants: {
      // Variants do not work for headings.
    },
    sizes: {
      // See comment at top of file for defaults.
      lg: {
        // Override the default for the lg size to not be responsive.
        fontSize: '2xl',
      },
    },
  },
};

export const heading1Xl = {
  fontFamily: 'serif',
  lineHeight: '2xs',
  size: '3xl',
} as const;

export const heading1 = {
  fontFamily: 'serif',
  lineHeight: '2xs',
  size: '2xl',
} as const;

export const heading2 = {
  size: 'xl',
} as const;

export const heading3 = {
  size: 'lg',
} as const;

export const heading4 = {
  size: 'md',
} as const;

export const heading5 = {
  size: 'sm',
} as const;

export const heading6 = {
  size: 'xs',
} as const;

export const overline = {
  letterSpacings: 'xl',
  lineHeight: '2xs',
  size: 'xs',
  textTransform: 'uppercase',
} as const;

export const overlineLarge = {
  letterSpacings: 'xl',
  lineHeight: '2xs',
  size: 'sm',
  textTransform: 'uppercase',
} as const;
