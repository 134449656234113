import { Select } from 'baseui/select';
import { uniq } from 'lodash';
import React, { useState } from 'react';
import { ComponentArgs, EzMultirow } from '../../Components/EzForm';
import { FinalButton } from '../../Components/FinalButton';
import { LoadingPage } from '../../Components/LoadingOverlay';
import {
  Organization,
  useOrganizationsListQuery,
  useUpdateProviderOrganizationsMutation,
} from '../../graphQL';
import { UnexpectedError } from '../Shared';
import { useProviderDrilldownContext } from './context';

export function OrganizationsTab() {
  const { provider, refetch } = useProviderDrilldownContext();
  const { loading, error } = useOrganizationsListQuery({ fetchPolicy: 'cache-first' });
  const [orgs, setOrgs] = useState<Pick<Organization, 'id' | 'name'>[]>(provider.organizations);
  const [updateOrgs, updateMutatation] = useUpdateProviderOrganizationsMutation();

  if (loading) return <LoadingPage />;
  if (error) return <UnexpectedError />;

  const onSave = async () => {
    await updateOrgs({
      variables: {
        providerId: provider.id,
        organizationIds: uniq(orgs.map(i => i.id).filter(i => i !== -1)),
      },
    });
    refetch();
  };

  return (
    <div className="mh5 mv3" style={{ maxWidth: '400px' }}>
      <EzMultirow
        values={orgs}
        blank={{ id: -1, name: '' }}
        setValues={setOrgs}
        component={OrganizationItem}
      />
      <div className="mt3 flex flex-row items-center">
        <FinalButton onClick={onSave} loading={updateMutatation.loading}>
          Save
        </FinalButton>
        {updateMutatation.data && <div className="ml3">Saved.</div>}
      </div>
    </div>
  );
}

function OrganizationItem({ value, onChange }: ComponentArgs<Pick<Organization, 'id' | 'name'>>) {
  const { data } = useOrganizationsListQuery({ fetchPolicy: 'cache-first' });
  if (!data) return <></>;
  const { organizations } = data;
  return (
    <Select
      maxDropdownHeight="16rem"
      options={organizations}
      onChange={e => onChange(e.option! as any)}
      value={[{ id: value.id, label: value.name }]}
      clearable={false}
      valueKey="id"
      labelKey="name"
    />
  );
}
