import { Accordion, Panel } from 'baseui/accordion';
import { StyledSpinnerNext } from 'baseui/spinner';
import moment from 'moment';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useExportNote } from '../../../Components/AsyncJobs/useExportMedicalNote';
import {
  CopyBlock,
  formCopy,
  resultsCopy,
} from '../../../Components/ContinuityOfCare/EligibilityCopy';
import { useSessionsEnding } from '../../../Components/ContinuityOfCare/hooks/useSessionsEnding';
import { useEvents } from '../../../Components/Events/EventsProvider';
import { Icon } from '../../../Components/Icons';
import { getLabeledDiagnosisCode } from '../../../Components/MedicalNote/diagnosisCodes';
import { MedicalNotePopover } from '../../../Components/MedicalNote/PopoverNote';
import {
  appointmentLabel,
  formatQuestionsForView,
  formatSingleValue,
} from '../../../Components/MedicalNote/util';
import { CopyContent } from '../../../Components/NoteCopy';
import { isMantraAdmin, PermsOnly, useCurrentProvider } from '../../../Components/Permissions';
import { usePopoverTabContext } from '../../../Components/PopoverTabs/PopoverTabContainer';
import { colors, Text } from '../../../globalStyles';
import {
  GetAllNotesAndFormSubmissionsForUserQuery,
  GetAllNotesForUserQuery,
  PaymentType,
  Permission,
  SectionFormat,
  useDeleteMedicalNoteMutation,
  useDeleteUserCareEligibilitySubmissionMutation,
  useGetAllNotesAndFormSubmissionsForUserQuery,
  useGetEligibilityCarePeriodValuesQuery,
  useGetEligibilityResultQuery,
  useResubmitClaimMutation,
} from '../../../graphQL';
import { locations } from '../../../locations';
import {
  formatDemographicsAsNote,
  formatMedicalHistoryAsNote,
  formatTriageInfoAsNote,
} from '../../../modelUtils/assessment';
import { ArrayElement } from '../../../types';
import { getNodeText, visitNotes } from '../../../utils';
import { Overrides } from '../baseuiOverrides';
import { useDrilldownContext, ViewWarning } from '../helpers';
import { InTextSpinner, Styles } from '../styles';
import { ExportableRecords } from '../Widgets/ExportableRecords';
import { LoadingWidget } from '../Widgets/Loading';

type Note = GetAllNotesForUserQuery['adminUser']['notes'][number];
type NoteAndForm = GetAllNotesAndFormSubmissionsForUserQuery['adminUser']['notes'][number];

export const NotesTab = () => {
  return (
    <div>
      <ViewWarning limited restricted>
        Due to limited record access, some medical notes may not be visible.
      </ViewWarning>
      <AllNotes />
      <PermsOnly allowed={Permission.MantraAdmin}>
        <ExportableRecords />
      </PermsOnly>
    </div>
  );
};

const AllNotes = () => {
  const { user, refetchCount } = useDrilldownContext();
  const [deleteNote] = useDeleteMedicalNoteMutation();
  const [deleteEligibilityNote] = useDeleteUserCareEligibilitySubmissionMutation();
  const { data, loading, refetch } = useGetAllNotesAndFormSubmissionsForUserQuery({
    variables: { id: user.id },
  });

  useEffect(() => {
    refetch();
  }, [refetch, refetchCount]);

  const onDeleteMedicalNote = (uuid: string) => async () => {
    await deleteNote({ variables: { uuid } });
    refetch();
  };

  const onDeleteEligibilityNote = (id: number) => async () => {
    await deleteEligibilityNote({ variables: { id, userId: user.id } });
    refetch();
  };

  if (loading) return <LoadingWidget />;
  if (!data || !data.adminUser) return null;

  const { triageInfo } = data.adminUser;
  const notes: Array<Note | NoteAndForm> = [...data.adminUser.notes];
  if (user.latestMedicalHistoryResult) {
    notes.push(formatMedicalHistoryAsNote(user.latestMedicalHistoryResult));
  }
  if (triageInfo) {
    triageInfo.forEach(info => {
      notes.push(formatTriageInfoAsNote(info));
    });
  }
  const demographics = data.adminUserDemographics;
  if (demographics) {
    notes.push(formatDemographicsAsNote(demographics));
  }

  const eligibilityNotes = data.adminUser.continuityOfCareEligibility || [];

  const allNotes: Array<Note | NoteAndForm | CocEligibilityNote> = [
    ...notes,
    ...eligibilityNotes,
  ].sort((a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt));

  return (
    <Styles.widget style={{ paddingBottom: notes.length === 0 ? undefined : 0 }}>
      <Text.h2>All Notes</Text.h2>

      {allNotes.length === 0 && (
        <Text.body className="mt3">
          <em>No provider notes</em>
        </Text.body>
      )}
      {allNotes.map((note, i) => {
        if ('noteType' in note && note?.noteType === 'ContinuityOfCareEligibilityModel') {
          return (
            <EligibilityNoteSection
              note={note}
              key={`eligibility-note-${i}`}
              onDelete={onDeleteEligibilityNote(note?.id)}
            />
          );
        }

        if (!note?.template) {
          return null;
        }

        return (
          <NoteSection
            onDelete={onDeleteMedicalNote(note?.template?.uuid)}
            note={note}
            key={note?.template?.uuid ?? i}
          />
        );
      })}
    </Styles.widget>
  );
};

const QuestionResponseText = styled.div`
  white-space: pre-wrap;
  color: ${colors.grayText};
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 24px;
`;

type NoteSectionProps = {
  note: NoteAndForm | Note;
  onDelete?: () => void;
};
const NoteSection = ({ note, onDelete }: NoteSectionProps) => {
  const metrics = useEvents();
  const { user } = useDrilldownContext();
  const { template, updatedAt, submittedAt } = note;
  const { pushTab } = usePopoverTabContext();
  const parsedTime = moment(updatedAt);

  // if note is an appointment; use that title
  // if user has inputted their own title; use that
  const title = note.appointment
    ? appointmentLabel(note.appointment)
    : formatSingleValue(template?.questions.find(e => e.key === 'title')?.value) || template?.title;

  const onClickEdit = () => {
    if (submittedAt) return;
    pushTab({
      tabKey: note.key,
      title: template?.title,
      renderBody: props => <MedicalNotePopover noteKey={note.key} {...props} />,
    });
  };

  const Title = (
    <div className="w-100 flex justify-between items-center">
      <div className="flex items-center">
        <Styles.infoDiv style={{ width: '200px' }}>
          <Text.captionGrey>
            {parsedTime.format('MM/DD/YYYY')} <br />
            {parsedTime.format('hh:mm a')} <br />
            {note.provider.name}
          </Text.captionGrey>
        </Styles.infoDiv>
        <Icon icon="iconsBlackNoteSvg" alt="Medical Note" className="ma2" />
        <Text.noteTitle>{title}</Text.noteTitle>
      </div>
      {!submittedAt && (
        <Text.label kind="danger" className="mr3" style={{ cursor: 'pointer' }}>
          unsubmitted
        </Text.label>
      )}
    </div>
  );

  return (
    <Accordion
      key={template?.uuid}
      onChange={e => {
        const openClose = e.expanded.length ? 'opened' : 'closed';
        return !note.id
          ? metrics.track(`note-tab.medical-history.${openClose}`, {
              userId: user.id,
            })
          : metrics.track(`note-tab.accordion.${openClose}`, {
              userId: user.id,
              noteId: note.id,
              uuid: note.template?.uuid,
            });
      }}
    >
      <Panel overrides={Overrides.AccordionPanel} title={Title}>
        <div>
          {!submittedAt && <Text.linkButton onClick={onClickEdit}>Edit Note</Text.linkButton>}
        </div>
        {submittedAt && note.provider.name && (
          <Text.body className="nl3">
            Created by {note.provider.name} on {moment(note.createdAt).format('L')}
          </Text.body>
        )}
        {/* we need to check for ID since Medical History shows up as a note */}
        {!!note.id && submittedAt && (
          <PermsOnly allowed={Permission.NoteExport}>
            <ExportNote noteId={note.id} />
          </PermsOnly>
        )}
        <ExpandedNote note={note} />

        {!submittedAt && onDelete && (
          <Text.linkButton className="mt4" onClick={onDelete} kind="danger">
            Delete Note
          </Text.linkButton>
        )}
        <ResubmitClaim note={note} />
      </Panel>
    </Accordion>
  );
};

type CocEligibilityNote = ArrayElement<
  GetAllNotesAndFormSubmissionsForUserQuery['adminUser']['continuityOfCareEligibility']
>;
const EligibilityNoteSection = ({
  note,
  onDelete,
}: {
  note?: CocEligibilityNote;
  onDelete: () => void;
}) => {
  const metrics = useEvents();
  const { user } = useDrilldownContext();
  const { currentProvider } = useCurrentProvider();
  const {
    id,
    createdAt,
    updatedAt,
    residentialLocationDuringBreak,
    enrollment,
    additionalNotes,
    interestedInContinuingCare,
    careType,
    completedBy,
    paymentType,
    remainingSessions,
  } = note as CocEligibilityNote;

  const { data: carePeriodValuesData } = useGetEligibilityCarePeriodValuesQuery({
    variables: { submissionId: id },
  });
  const { data: resultData } = useGetEligibilityResultQuery({ variables: { submissionId: id } });
  const areSessionsCurrentlyEnding = useSessionsEnding({ careType });

  // Since remainingSessions may be null for `submission`, the fallback value will be checking the # of sessions that currently exist
  const sessionsEnding =
    typeof remainingSessions === 'number' ? remainingSessions < 3 : areSessionsCurrentlyEnding;

  const result = resultData?.getEligibilityResult!;
  const isEligible = result?.isEligible;
  const needsReferral = result?.needsReferral;
  const providerInState = result?.providerInState;

  const carePeriodsValues = carePeriodValuesData?.getEligibilityCarePeriodValues;
  const endingDueToBreak = carePeriodsValues?.isEndingDueToBreak;
  const firstDayOfBreak = carePeriodsValues?.firstDayOfBreak;
  const lastDayOfBreak = carePeriodsValues?.lastDayOfBreak!;
  const lastDayOfTerm = carePeriodsValues?.lastDayOfTerm!;

  const parsedTime = moment(updatedAt);
  const title = `Continuity of Care: ${careType} Eligibility Form`;
  const copy = paymentType === 'selfPay' ? formCopy.selfPay : formCopy.sponsored;
  const questionsAndAnswers = [
    {
      question: copy.residentialLocationDuringBreak,
      answer: locations[residentialLocationDuringBreak],
    },
    {
      question: {
        ...copy.enrollment,
        label: copy.enrollment.label.replace(
          '[ORGANIZATION]',
          user?.organization?.name || 'this organization'
        ),
      },
      answer: enrollment,
    },
    {
      question: {
        ...copy.interestedInContinuingCare,
        label: copy.interestedInContinuingCare.label,
      },
      answer: interestedInContinuingCare,
    },
    {
      question: copy.additionalNotes,
      answer: additionalNotes,
    },
  ];

  const results = resultsCopy({
    isEligible,
    interestedInContinuingCare,
    sessionsEnding,
    needsReferral,
    isSponsoredCare: paymentType === PaymentType.Sponsored,
    endingDueToBreak,
    firstDayOfBreak,
    lastDayOfBreak,
    lastDayOfTerm,
    providerInState,
  }).blocks.filter((c: CopyBlock) => c.condition);

  const eligibilityCopy = results.filter(c => c.block === 'eligibility');
  const nextStepsCopy = results.filter(c => c.block === 'next-steps');
  const careNavigationCopy = results.filter(c => c.block === 'care-navigation');
  const schedulingVisitsCopy = results.filter(c => c.block === 'scheduling-visits');

  const resultsBlocks = [
    {
      title: 'Eligibility',
      description: eligibilityCopy?.length ? eligibilityCopy[0].title : null,
    },
    {
      title: 'Select Next Steps for care',
      description: () => (
        <List>
          {nextStepsCopy.map((c, i) => (
            <li key={`${c.block}-${i}`}>
              {c.title}: {c.description}
            </li>
          ))}
        </List>
      ),
    },
    {
      title: 'Create Task for Mantra Care Navigator (optional)',
      description: () =>
        careNavigationCopy.length && typeof careNavigationCopy[0]?.description === 'function' ? (
          <List>
            {careNavigationCopy[0].description().map((c, i: number) => (
              <li key={`cn-bullet-${i}`}>{getNodeText(c)}</li>
            ))}
          </List>
        ) : null,
    },
    {
      title: 'Scheduling visits',
      description: () => (
        <List>
          {schedulingVisitsCopy.map((c, i: number) => (
            <li key={`${c.block}-${i}`}>{getNodeText(c.list).replace('.', '. ')}</li>
          ))}
        </List>
      ),
    },
  ];

  const Title = (
    <div className="w-100 flex justify-between items-center">
      <div className="flex items-center">
        <Styles.infoDiv style={{ width: '200px' }}>
          <Text.captionGrey>
            {parsedTime.format('MM/DD/YYYY')} <br />
            {parsedTime.format('hh:mm a')} <br />
            {completedBy.name}
          </Text.captionGrey>
        </Styles.infoDiv>
        <Icon icon="iconsBlackNoteSvg" alt="Medical Note" className="ma2" />
        <Text.noteTitle>{title}</Text.noteTitle>
      </div>
    </div>
  );

  return (
    <Accordion
      key={`eligibility-note-${id}`}
      onChange={e => {
        const openClose = e.expanded.length ? 'opened' : 'closed';
        return !note?.id
          ? metrics.track(`note-tab.coc-eligibility-note.${openClose}`, {
              userId: user.id,
            })
          : metrics.track(`note-tab.coc-eligibility-note.accordion.${openClose}`, {
              userId: user.id,
              noteId: note.id,
            });
      }}
    >
      <Panel overrides={Overrides.AccordionPanel} title={Title}>
        {createdAt && completedBy && (
          <Text.body className="nl3">
            Created by {completedBy.name} on {moment(updatedAt).format('L')}
          </Text.body>
        )}
        {questionsAndAnswers.map((qa, index: number) => {
          return (
            <div key={`eligibility-question-${index}`} className="mt3">
              <Text.bodyBold
                kind={'required' in qa.question && qa.question.required ? 'black' : 'grayText'}
              >
                {typeof qa.question.label === 'string'
                  ? qa.question.label
                  : getNodeText(qa.question.label()).replace('*', '')}
              </Text.bodyBold>
              {typeof qa.answer === 'boolean' || (typeof qa.answer === 'string' && !!qa.answer) ? (
                <QuestionResponseText>
                  {typeof qa.answer === 'string' && qa.answer}
                  {typeof qa.answer === 'boolean' && (qa.answer ? 'Yes' : 'No')}
                </QuestionResponseText>
              ) : (
                <QuestionResponseText>
                  <em>Not answered</em>
                </QuestionResponseText>
              )}
            </div>
          );
        })}
        <Text.h3 className="mt4 mb2">Results</Text.h3>
        {resultsBlocks.map((r, index: number) => {
          if (r.description === null || r.description === undefined) {
            return null;
          }

          return (
            <div key={`eligibility-question-${index}`} className="mt3">
              <Text.bodyBold kind="black">{r.title}</Text.bodyBold>
              <QuestionResponseText>
                {typeof r.description === 'string' && r.description}
                {typeof r.description === 'function' && r.description()}
              </QuestionResponseText>
            </div>
          );
        })}
        {(currentProvider.careTypes.includes(careType) || isMantraAdmin(currentProvider)) && (
          <Text.linkButton className="mt4" onClick={onDelete} kind="danger">
            Delete Note
          </Text.linkButton>
        )}
      </Panel>
    </Accordion>
  );
};

const ExportNote = ({ noteId }: { noteId: number }) => {
  const { beginExport, inProgress } = useExportNote({ noteId });

  return (
    <Text.linkButton disabled={inProgress} className="nl3 flex items-center" onClick={beginExport}>
      Export Note {inProgress && <InTextSpinner />}
    </Text.linkButton>
  );
};

const ExpandedNote = ({ note }: { note: NoteAndForm | Note }) => {
  const sections = formatQuestionsForView(note.template?.questions);

  return (
    <>
      {sections.map(section => (
        <DisplaySection key={section.key} providerName={note.provider.name} section={section} />
      ))}
    </>
  );
};

type DisplaySectionProps = {
  indent?: number;
  providerName: string;
  section: ReturnType<typeof formatQuestionsForView>[number];
};
const DisplaySection = ({ section, providerName, indent = 0 }: DisplaySectionProps) => {
  const number = section.number ? `${section.number}.` : '';
  const titleText = section.title || section.placeholder;
  const title = titleText ? `${number} ${titleText}` : null;
  const content = FormatValue(section.value, section.format, providerName);

  const children = section.children
    ?.map(c => ({ ...c, content: FormatValue(c.value, c.format, providerName) }))
    .filter(c => (Array.isArray(c.content) ? !!c.content.length : !!c.content));

  const hasContent = Boolean(section.value || children?.length);
  return (
    <div key={section.key}>
      {section.sectionTitle && <Text.h3 className="mt3 nl3">{section.sectionTitle}</Text.h3>}
      <div>
        {title && (
          <Text.bodyBold className="mt3" kind={hasContent ? 'text' : 'grayText'}>
            {title}
          </Text.bodyBold>
        )}
        {section.beforeCopy?.length && (
          <div className="mt3">
            {section.beforeCopy
              .filter(c => c.showInView)
              .map((c, i) => (
                <CopyContent key={i} copy={c} />
              ))}
          </div>
        )}
        <QuestionResponseText className="ml3 mt2">
          {Array.isArray(content) ? content.join('\n') : content}
          {!content && title && !children?.length && <em>Not answered</em>}
        </QuestionResponseText>
        {!!children &&
          children.map(c => (
            <DisplaySection
              key={c.key}
              indent={indent + 1}
              providerName={providerName}
              section={c}
            />
          ))}
      </div>
    </div>
  );
};

const ResubmitClaim = ({ note }: { note: NoteAndForm | Note }) => {
  const [resubmitClaim, resubmitClaimMutation] = useResubmitClaimMutation({
    variables: { noteId: note.id },
  });

  return (
    <PermsOnly allowed={Permission.MantraAdmin}>
      {note.submittedAt && visitNotes.includes(note.key) && (
        <div className="flex flex-row items-center mt3">
          <Text.linkButton onClick={() => resubmitClaim().catch()} className="mr3">
            Resubmit insurance claim
          </Text.linkButton>
          {resubmitClaimMutation.loading && <StyledSpinnerNext size={10} />}
          {resubmitClaimMutation.data?.resubmitClaim === true && <div>Claim resubmitted.</div>}
          {resubmitClaimMutation.data?.resubmitClaim === false && (
            <div>
              A claim can’t be submitted for this note. Check the patient’s payment source and
              insurance details.
            </div>
          )}
          {resubmitClaimMutation.error && <div>{resubmitClaimMutation.error.message}</div>}
        </div>
      )}
    </PermsOnly>
  );
};

const FormatValue = (value: any, format: SectionFormat, name: string) => {
  switch (format) {
    case SectionFormat.Date:
      return value ? moment(value).format('L') : null;
    case 'sign':
      return value ? `${moment(value).format('llll')} by ${name}` : null;
    case SectionFormat.Multitext:
    case SectionFormat.Multiselect:
      return Array.isArray(value) ? value.join(', ') : null;
    case SectionFormat.Rx:
      return Array.isArray(value)
        ? value.map(v => `${v.group}, ${v.dose}${v.note ? `\n\t${v.note}` : ''}`)
        : null;
    case SectionFormat.Diagnosis:
      return getLabeledDiagnosisCode(value);
    case SectionFormat.Multidiagnosis:
      return Array.isArray(value)
        ? value.map(v => getLabeledDiagnosisCode(v)).join('\n')
        : 'Invalid data';
    default:
      if (!value) return null;
      return Array.isArray(value) ? value.join(', ') : String(value);
  }
};
