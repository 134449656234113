import React from 'react';
import * as Styles from './styles';
import { Maybe } from '../../graphQL';
import { colors } from '../../globalStyles';

type Props = {
  color?: Maybe<string>;
  size?: string;
};

export const Dot: React.FC<Props> = ({ children, color, size }) => {
  const dotColor = color || colors.grey.border;

  return (
    <Styles.FlairPosition>
      <Styles.Dot color={dotColor} size={size} />
      {children}
    </Styles.FlairPosition>
  );
};

export const PatientStatus: React.FC<{ status: string }> = ({ status, children }) => {
  const map: Record<string, null | string> = {
    active: colors.success,
    enrolled: colors.success,
    'ongoing care': colors.success,
    screenedOut: colors.danger,
    discharged: colors.danger,
  };
  return <Dot color={map[status.toLowerCase()] || colors.grey.border}>{children || status}</Dot>;
};

export const Reticle: React.FC<Props> = ({ children, color }) => {
  const dotColor = color || colors.grey.border;

  return (
    <Styles.FlairPosition>
      <Styles.Reticle color={dotColor} />
      {children}
    </Styles.FlairPosition>
  );
};
