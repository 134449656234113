import { reduce } from 'lodash';
import { useToast } from 'native-base';
import React, { useMemo, useState } from 'react';
import {
  CoachingStatus,
  useAssignCoachToEmbedPatientMutation,
  useAvailableCoachesForEmbedPatientQuery,
  useEmbedPatientCoachingStatusQuery,
} from '../../../../graphQL';
import { getStylesheet } from '../../../../styles';
import { Button, DividerSoft, Layout, Select, Text } from '../../../core';
import { CoachingMarkInactiveModal } from './CoachingMarkInactiveModal';

type ChangeCoachProps = {
  patientId: number;
};

export const ChangeCoach = ({ patientId }: ChangeCoachProps): JSX.Element => {
  const [selectedCoachId, setSelectedCoachId] = useState<string | undefined>(undefined);
  const [showAssignCoachView, setShowAssignCoachView] = useState<boolean>(false);
  const [showMarkInactive, setShowMarkInactive] = useState<boolean>(false);

  const toast = useToast();

  const {
    data: availableCoachesData,
    loading: availableCoachesLoading,
    refetch: availableCoachesRefresh,
  } = useAvailableCoachesForEmbedPatientQuery({
    variables: {
      userId: patientId,
    },
  });

  const {
    data: coachingStatusData,
    loading: coachingStatusLoading,
    refetch: coachingStatusRefetch,
  } = useEmbedPatientCoachingStatusQuery({
    variables: {
      userId: patientId,
    },
  });

  const [assignCoach] = useAssignCoachToEmbedPatientMutation({
    onError: () => {
      toast.show({
        description: 'Coach could not be assigned.',
        duration: 3000,
      });
    },
    onCompleted: ({ assignCoachToEmbedPatient }) => {
      void availableCoachesRefresh();

      if (!assignCoachToEmbedPatient) {
        toast.show({
          description: 'Coach could not be assigned.',
          duration: 3000,
        });
        return;
      }

      toast.show({
        description: 'Coach assigned successfully.',
        duration: 3000,
      });

      setShowAssignCoachView(false);
      setSelectedCoachId(undefined);
    },
  });

  const coachingStatus = coachingStatusData?.embedPatientCoachingStatus ?? undefined;

  const { currentCoach, coachOptions, hasCoachOptions } = useMemo(() => {
    const availableCoaches = availableCoachesData?.availableCoachesForEmbedPatient ?? [];
    const matchedCurrentCoach = availableCoaches.find(({ isCurrentCoach }) => isCurrentCoach);
    const otherCoaches = availableCoaches.filter(({ isCurrentCoach }) => !isCurrentCoach);

    const accumulator: Record<string, string> = {};
    const foundCoachOptions = reduce(
      otherCoaches,
      (list, coach) => {
        const id = coach.coachId;
        list[id] = `${coach.name}${coach.isCurrentCoach ? ' (current)' : ''}`;
        return list;
      },
      accumulator
    );

    return {
      currentCoach: matchedCurrentCoach,
      coachOptions: foundCoachOptions,
      hasCoachOptions: Object.values(foundCoachOptions).length > 0,
    };
  }, [availableCoachesData]);

  const onCoachSelected = (newCoachId: string): void => {
    setSelectedCoachId(newCoachId);
  };

  const onAssignPress = (): void => {
    if (selectedCoachId === undefined) {
      return;
    }

    void assignCoach({
      variables: {
        userId: patientId,
        coachId: selectedCoachId,
      },
    });
  };

  const onCancelPress = (): void => {
    setShowAssignCoachView(false);
    setSelectedCoachId(undefined);
  };

  const onMarkInactiveComplete = (): void => {
    void coachingStatusRefetch();

    setShowMarkInactive(false);
  };

  return (
    <Layout.Flex {...styles.topWrapper}>
      {showMarkInactive && (
        <CoachingMarkInactiveModal
          onClose={() => setShowMarkInactive(false)}
          onCompleted={onMarkInactiveComplete}
          patientId={patientId}
        />
      )}

      <Layout.HStack space={3}>
        <Button.secondarySmall
          isDisabled={coachingStatus !== CoachingStatus.Active || !hasCoachOptions}
          isLoading={availableCoachesLoading || coachingStatusLoading}
          onPress={() => setShowAssignCoachView(true)}
          testID="button-coach-assignment-open"
        >
          Change Coach
        </Button.secondarySmall>

        <Button.dangerSmall
          isDisabled={coachingStatus !== CoachingStatus.Active}
          isLoading={coachingStatusLoading}
          onPress={() => setShowMarkInactive(true)}
          testID="button-coach-mark-inactive"
        >
          Mark Inactive
        </Button.dangerSmall>
      </Layout.HStack>

      {showAssignCoachView && hasCoachOptions && (
        <>
          <DividerSoft />

          <Layout.HStack {...styles.assignCoachHeader}>
            <Text.para>Select a New Coach</Text.para>

            <Layout.HStack space={2}>
              <Select
                options={coachOptions}
                selectedValue={selectedCoachId}
                defaultValue={currentCoach?.coachId ?? ''}
                placeholder={currentCoach ? `${currentCoach.name} (Current)` : 'Select a Coach'}
                onValueChange={onCoachSelected}
              />

              <Button.primarySmall
                isDisabled={selectedCoachId === undefined}
                testID="button-coach-assignment-assign-new-coach"
                onPress={onAssignPress}
              >
                Confirm
              </Button.primarySmall>

              <Button.tertiarySmall testID="button-coach-assignment-cancel" onPress={onCancelPress}>
                Cancel
              </Button.tertiarySmall>

              <DividerSoft />
            </Layout.HStack>
          </Layout.HStack>
        </>
      )}

      <DividerSoft />
    </Layout.Flex>
  );
};

const styles = getStylesheet({
  topWrapper: {
    gap: 4,
  },

  assignCoachHeader: {
    alignItems: 'center',
    gap: 1,
    justifyContent: 'space-between',
  },
});
