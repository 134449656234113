import { isNil, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FancyRadio } from '../../../Components/FancyRadio';
import { FinalButton } from '../../../Components/FinalButton';
import { isMantraProvider, useCurrentProvider } from '../../../Components/Permissions';
import { Warning } from '../../../Components/Warning';
import { Text } from '../../../globalStyles';
import { CareType } from '../../../graphQL';
import { getFullName } from '../../../modelUtils/users';
import { WizardContentWithBack } from '../Common';
import { BookingWizardProps } from '../types';

export function SelectAppointmentType({
  data: wizardData,
  prevStep,
  nextStep,
}: BookingWizardProps) {
  const { currentProvider } = useCurrentProvider();
  const isCaregiver = isMantraProvider(currentProvider);
  const currentProviderCareTypes = new Set(currentProvider.careTypes);

  const { appointmentTemplates, patient, appointment } = wizardData;
  const { careFlows } = patient;
  const hasCompletedOrUpcomingPsych = patient.psychUpcoming || patient.psychComplete;
  const hasCompletedOrUpcomingTherapy = patient.therapyComplete || patient.therapyUpcoming;
  const [warningMessage, setWarningMessage] = useState('');

  const [activeTemplateIdx, setActiveTemplateIdx] = useState<number | null>(
    !isNil(appointment.templateIndex) && appointmentTemplates[appointment.templateIndex]
      ? appointment.templateIndex
      : null
  );

  const activeTemplate = isNil(activeTemplateIdx) ? null : appointmentTemplates[activeTemplateIdx];

  const careEnabled = careFlows.some(
    f => f.canBookAppointment && (isNil(activeTemplate) || activeTemplate.careType === f.careType)
  );

  const hasState = Boolean(patient.primaryAddressState);
  const canBook = careEnabled && hasState;

  const nameAndId = `${getFullName(patient)} (${patient.customerId})`;

  const appointmentTypeOptions = appointmentTemplates.map((t, templateIdx) => ({
    id: templateIdx,
    label: `${t.description} (${t.duration} min)`,
    hidden: isCaregiver && !currentProviderCareTypes.has(t.careType),
  }));

  // Set a default selection based on various criteria
  useEffect(() => {
    // If a patient has multiple care types, there is no default selection
    if (patient.careTypes.length > 1) return;

    const hasTherapy = patient.careTypes.some(t => t === CareType.Therapy);
    const hasPsych = patient.careTypes.some(t => t === CareType.Psychiatry);

    // If a patient has already completed an initial appointment or has an appointment scheduled
    if (hasCompletedOrUpcomingPsych && hasPsych) {
      const psychIdx = appointmentTemplates.findIndex(
        template =>
          template.appointmentType === 'checkin' && template.careType === CareType.Psychiatry
      );
      setActiveTemplateIdx(psychIdx);
    }
    // if completed / upcoming is therapy
    else if (hasCompletedOrUpcomingTherapy && hasTherapy) {
      const therapyIdx = appointmentTemplates.findIndex(
        template => template.appointmentType === 'checkin' && template.careType === CareType.Therapy
      );
      setActiveTemplateIdx(therapyIdx);
    }
    // default to first intake
    else {
      const anyIntake = appointmentTemplates.findIndex(
        template => template.appointmentType === 'intake'
      );
      setActiveTemplateIdx(anyIntake);
    }
  }, [
    patient.careTypes,
    setActiveTemplateIdx,
    appointmentTemplates,
    hasCompletedOrUpcomingPsych,
    hasCompletedOrUpcomingTherapy,
  ]);

  // Set warning message for various conditions depending on patient having an intake scheduled or completed
  useEffect(() => {
    setWarningMessage('');

    if (isNil(activeTemplateIdx) || isNil(appointmentTemplates[activeTemplateIdx])) {
      return;
    }

    const template = appointmentTemplates[activeTemplateIdx];
    const isPsych = template.careType === 'Psychiatry';

    if (template.appointmentType === 'intake') {
      if (isPsych && hasCompletedOrUpcomingPsych) {
        setWarningMessage(
          `This patient already has a completed or upcoming Initial Psychiatric Evaluation.`
        );
      } else if (!isPsych && hasCompletedOrUpcomingTherapy) {
        setWarningMessage(
          `This patient already has a completed or upcoming Initial Therapy Evaluation.`
        );
      }
    } else if (template.appointmentType === 'checkin') {
      if (isPsych && !hasCompletedOrUpcomingPsych) {
        setWarningMessage(
          `This patient has not yet had an Initial Psychiatric Evaluation completed or scheduled.`
        );
      } else if (!isPsych && !hasCompletedOrUpcomingTherapy) {
        setWarningMessage(
          `This patient has not yet had an Initial Therapy Evaluation completed or scheduled.`
        );
      }
    }
  }, [
    activeTemplateIdx,
    hasCompletedOrUpcomingTherapy,
    hasCompletedOrUpcomingPsych,
    appointmentTemplates,
  ]);

  return (
    <WizardContentWithBack onBack={() => prevStep()}>
      <div className="mb3">
        <Text.h2>What type of appointment?</Text.h2>
        {canBook && (
          <Text.body className="mt2">
            You&apos;re booking an appointment for
            <strong> {nameAndId}</strong>
          </Text.body>
        )}
        {!hasState && (
          <Text.body className="mt2">
            Unable to book because <strong>{nameAndId}</strong> has no state address. To resolve
            this, have the patient complete onboarding, or{' '}
            <a href="mailto:hi@mantrahealth.com">contact us</a> to update their state.
          </Text.body>
        )}
        {!careEnabled && (
          <Text.body className="mt2">
            Unable to book. Please activate care for <strong>{nameAndId}</strong> before proceeding.
          </Text.body>
        )}
      </div>
      <FancyRadio
        value={isNil(activeTemplateIdx) ? undefined : activeTemplateIdx}
        onChange={setActiveTemplateIdx}
        options={appointmentTypeOptions.filter(t => !t.hidden)}
      />
      {warningMessage && (
        <Warning>
          <b>Note:</b> {warningMessage}
        </Warning>
      )}
      <FinalButton
        kind="primary"
        disabled={isUndefined(activeTemplateIdx) || !careEnabled}
        className="w-100 mt4"
        onClick={() =>
          nextStep({
            ...wizardData,
            appointment: {
              ...wizardData.appointment,
              templateIndex: activeTemplateIdx ?? undefined,
              duration: activeTemplate?.duration,
              careType: activeTemplate?.careType,
              appointmentType: activeTemplate?.appointmentType,
            },
          })
        }
      >
        Next: Select Date &amp; Time
      </FinalButton>
    </WizardContentWithBack>
  );
}
