import { StyledSpinnerNext } from 'baseui/spinner';
import styled, { css } from 'styled-components';
import { withStyleDeep } from 'styletron-react';
import { WIDGET_PADDING } from '../../Components/Widgets/Widget';
import { colors, Modal, Text } from '../../globalStyles';
import { margin } from '../../utils';
import { GUTTER, TOP_PADDING } from './baseuiOverrides';

export { Text, Modal };

export const StyledSidebar = styled.aside`
  background-color: white;
  padding: 1.5rem 2rem;
  border-left: 1px solid ${colors.grey.hr};
  overflow-x: hidden;

  &[data-hidden='true'] {
    display: none;
  }
`;

export const StyledNav = styled.nav`
  background-color: white;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-bottom: 1px solid ${colors.grey.hr};
  height: 4rem;
  padding: 0.25rem 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 1;
`;

export const Styles = {
  dropdown: styled.div`
    position: absolute;
    left: 0px;
    width: 150%;
    top: 110%;
    z-index: 2;
    border-radius: 0.25rem;
    box-shadow: 5px 5px 10px #aaaaaa;
  `,

  listDiv: styled.div<{ hoverable?: boolean; vPadding?: 'narrow' | 'wide' }>`
    display: flex;
    align-items: center;
    padding: ${({ vPadding = 'wide' }) => (vPadding === 'narrow' ? '0.5rem' : '1.5rem')} 0;

    &:hover {
      cursor: ${({ hoverable }) => (hoverable ? 'pointer' : 'default')};
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${colors.grey.light};
    }
  `,

  verticalSlash: styled.div`
    margin-left: 49px;
    margin-right: 44px;
    border-left: 1px solid ${colors.grey.hr};
    height: 100%;
  `,

  fileDetailsContainer: styled.div`
    margin-bottom: 16px;
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 100px 3fr;
  `,

  spaceBetween: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  `,

  sidebarTable: styled.table`
    width: 100%;
    & th {
      text-align: left;
      padding: 0.25rem 0.5rem 0.5rem 0rem;
    }

    & td {
      padding-bottom: 0.5rem;
    }
  `,

  undoVerticalMargin: styled.div`
    & * {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  `,

  sidebarSectionHeader: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid ${colors.grey.hr};
    margin-bottom: 0.75rem;

    h1,
    h2,
    h3 {
      margin-bottom: 0.5rem;
    }
  `,

  sidebarSection: styled.div`
    width: 100%;
    margin-bottom: 2rem;
  `,

  infoDiv: styled.div`
    text-align: start;
    color: ${colors.grey.dark};
    margin-right: 12px;
    & * {
      margin: 0px;
    }
  `,

  providerMessagingTab: styled.div`
    margin-top: -${TOP_PADDING};
    margin-right: -${GUTTER};
    margin-left: -${GUTTER};
    padding-left: ${GUTTER};
    background-color: ${colors.grey.lightBackground};
    border-bottom: 1px solid ${colors.grey.border};
  `,

  providerMessagingViewV1: styled.div`
    background-color: white;
    border-left: 1px solid ${colors.grey.border};
  `,

  providerMessagingViewV2: styled.div`
    background-color: white;
  `,

  widgetHr: styled.hr`
    margin-left: -${WIDGET_PADDING};
    margin-right: -${WIDGET_PADDING};
  `,

  widget: styled.div<{ flatBottom?: boolean }>`
    border: 1px solid ${colors.grey.border};
    border-radius: 4px;
    background-color: white;
    padding: 24px ${WIDGET_PADDING};
    overflow: hidden;
    margin-bottom: ${TOP_PADDING};
    border: 1px solid #e5e5e5;

    ${({ flatBottom }) =>
      flatBottom
        ? css`
            padding-bottom: 0;
          `
        : ''}

    ${({ color }) =>
      color
        ? css`
            border-left: 8px solid ${color};
          `
        : ''}
  `,

  PlanContainer: styled.div`
    border-left: 4px solid #c4c4c4;
    padding-left: 12px;
    margin: 17px 0px;
  `,
  appointmentItem: styled.div`
    margin: 1.5rem 0;
    &:not(:last-of-type) {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #d8d8d8;
    }
    color: ${({ faded }: { faded?: boolean }) => (faded ? '#9F9F9F' : 'black')};
  `,
  boldText: styled.div`
    font-weight: bold;
    display: inline;
  `,
  announcementContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;
    margin: 1em 0 1em 0;
    background-color: ${colors.friendlyWarningBg};
    border: 1px solid ${colors.friendlyWarning};
    border-radius: 0.25em;
  `,
  profileLabelContainer: styled.div`
    ${({ color }) => `background-color: ${color}`};
    border-radius: 50px;
    display: flex;
    padding: 0.1em 0.75em;
    margin-left: 1em;
    align-items: center;
  `,
} as const;

export const InTextSpinner = withStyleDeep(StyledSpinnerNext, {
  width: '1em',
  height: '1em',
  ...margin('0 0 0 0.5em'),
  padding: '0px',
});
