import { ModalBody, ModalHeader } from 'baseui/modal';
import { Notification } from 'baseui/notification';
import moment, { Moment } from 'moment';
import React, { useMemo, useState } from 'react';
import { ComponentArgs, EzMultirow } from '../../Components/EzForm';
import { FinalButton } from '../../Components/FinalButton';
import { DatePicker, Select } from '../../Components/Form';
import { Grid } from '../../Components/Grid';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { GenericSmallModal } from '../../Components/Modal';
import { PermsOnly } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import {
  EditOrganizationCarePeriod,
  Permission,
  useAdminTransitionForCarePeriodMutation,
  useOrganizationCarePeriodsQuery,
  useUpdateOrganizationCarePeriodsMutation,
  PeriodType,
} from '../../graphQL';
import { Nullable } from '../../types';
import { compareJsDates } from '../../utils';
import { UnexpectedError } from '../Shared';
import { useOrganizationId } from './util';

export const carePeriodTypeCopy: Record<PeriodType, string> = {
  [PeriodType.NonSponsoredBreak]: 'Non-Sponsored Break',
  [PeriodType.NonSponsoredTerm]: 'Non-Sponsored Term',
  [PeriodType.SponsoredTerm]: 'Sponsored Term',
  [PeriodType.SponsoredBreak]: 'Sponsored Break',
};

type EditableCarePeriod = {
  id?: number;
  startDate: Date;
  endDate: Date;
  periodType: PeriodType;
};

export function OrganizationCarePeriods() {
  const organizationId = useOrganizationId();

  const [periods, setPeriods] = useState<EditableCarePeriod[]>([]);
  const [dateFormat, setDateFormat] = useState('YYYY/MM/DD');
  const { loading, error, data, refetch } = useOrganizationCarePeriodsQuery({
    variables: { organizationId },
    notifyOnNetworkStatusChange: true,
    onCompleted: d => {
      setPeriods(
        d.organization.carePeriods
          .sort((a, b) => compareJsDates({ a: a.startDate, b: b.startDate }))
          .map(({ id, startDate, endDate, periodType }) => ({
            id,
            startDate: moment(startDate, 'YYYY-MM-DD').toDate(),
            endDate: moment(endDate, 'YYYY-MM-DD').toDate(),
            periodType,
          }))
      );
    },
  });

  const [update, updateMutation] = useUpdateOrganizationCarePeriodsMutation();

  const periodsSorted = useMemo(
    () => [...periods].sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf()),
    [periods]
  );

  if (loading) return <LoadingPage />;
  if (error) return <UnexpectedError />;

  const onSave = async () => {
    const formatPeriod = (period: EditableCarePeriod) => ({
      ...period,
      startDate: moment(period.startDate).format('YYYY-MM-DD'),
      endDate: moment(period.endDate).format('YYYY-MM-DD'),
    });
    await update({
      variables: {
        organizationId,
        newPeriods: periods.filter(i => !i.id).map(formatPeriod),
        editedPeriods: periods
          .filter(i => !!i.id)
          .map(formatPeriod) as EditOrganizationCarePeriod[],
      },
    });
    await refetch();
  };

  const warningMessage = getWarningMessage(periods);
  const readOnly = !!data?.organization.parent;

  const lastPeriodEnd = periods
    .map(p => p.endDate)
    .reduce((match, end) => {
      const endM = moment(end);
      return !match || endM.isAfter(match) ? endM : match;
    }, null as Nullable<Moment>);

  const blankStart = lastPeriodEnd ? lastPeriodEnd.clone().add(1, 'day') : moment();

  return (
    <div>
      <div className="mb4">
        <h2>Care Periods</h2>
        {data?.organization.parent && (
          <p>
            Inherits care periods from{' '}
            <a href={`/organizations/${data.organization.parent.id}/admin?tab=carePeriods`}>
              {data.organization.parent.name ?? 'Parent'}
            </a>
          </p>
        )}
      </div>
      {readOnly ? (
        <div className="mb5">
          <Grid gridTemplateColumns="1fr 1fr 1fr" maxWidth="38rem">
            {periodsSorted.map((p, i) => (
              <React.Fragment key={i}>
                <div className="mb3">
                  <span className="f6">Start:</span>
                  <span className="b ml3">{moment(p.startDate).format(dateFormat)}</span>
                </div>
                <div className="mb3">
                  <span className="f6">End:</span>
                  <span className="b ml3">{moment(p.endDate).format(dateFormat)}</span>
                </div>
                <div className="mb3">
                  <span className="f6">Type:</span>
                  <span className="b ml3">{carePeriodTypeCopy[p.periodType]}</span>
                </div>
              </React.Fragment>
            ))}
          </Grid>
          <div className="mt4">
            <Text.label className="mb2">Date Format</Text.label>
            <div className="flex gap-3 ">
              {['YYYY/MM/DD', 'MM/DD/YYYY', 'LL'].map(f => (
                <FinalButton
                  kind="outline_black"
                  size="tiny"
                  key={f}
                  onClick={() => setDateFormat(f)}
                >
                  {f}
                </FinalButton>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <EzMultirow
          values={periodsSorted}
          component={CarePeriod}
          blank={{
            startDate: blankStart.toDate(),
            endDate: blankStart.clone().add(1, 'month').toDate(),
            periodType: 'sponsoredBreak',
          }}
          setValues={setPeriods}
          // can remove if a blank (no id)
          canRemoveIf={v => !v.id}
        />
      )}
      {warningMessage && <Text.body kind="danger">{warningMessage}</Text.body>}
      <div className="flex flex-row items-center mt3">
        {!readOnly && (
          <FinalButton
            kind="primary"
            loading={updateMutation.loading}
            onClick={onSave}
            disabled={!!warningMessage}
          >
            Save
          </FinalButton>
        )}
        {updateMutation.data && <Text.body className="ml3">Saved.</Text.body>}
        {updateMutation.error && (
          <Text.body className="ml3" kind="danger">
            An error occurred. Please try again.
          </Text.body>
        )}
      </div>
    </div>
  );
}

const CarePeriod = ({ value, onChange }: ComponentArgs<EditableCarePeriod>) => {
  const [requestedTransition, setRequestedTransition] = useState(false);
  const [transition, { error, loading }] = useAdminTransitionForCarePeriodMutation({
    onCompleted: () => setRequestedTransition(false),
  });

  return (
    <>
      <GenericSmallModal isOpen={requestedTransition} onClose={() => setRequestedTransition(false)}>
        <ModalHeader>
          <Text.h2 className="tc">Are you sure?</Text.h2>
        </ModalHeader>
        <ModalBody>
          {error && <Notification kind="negative">{error}</Notification>}
          <Text.body className="mb3">
            Transitioning a care period will cause users without active transitions to be placed
            On-Hold
          </Text.body>
          <FinalButton
            kind="outline_danger"
            loading={loading}
            className="w-100 mt3"
            onClick={() => transition({ variables: { carePeriodId: value.id! } })}
          >
            Transition Care Period
          </FinalButton>
        </ModalBody>
      </GenericSmallModal>
      <div className="flex items-center">
        <div className="mr2">Start:</div>
        <DatePicker
          value={value.startDate}
          onChange={date => onChange({ ...value, startDate: date as Date })}
        />
        <div className="ml4 mr2">End:</div>
        <DatePicker
          value={value.endDate}
          onChange={date => onChange({ ...value, endDate: date as Date })}
        />
        <div className="ml4 mr2">Type:</div>
        <Select
          options={[
            PeriodType.SponsoredTerm,
            PeriodType.NonSponsoredTerm,
            PeriodType.SponsoredBreak,
            PeriodType.NonSponsoredBreak,
          ].map(t => ({ id: t, label: carePeriodTypeCopy[t] }))}
          value={value.periodType}
          onChange={v => onChange({ ...value, periodType: v as PeriodType })}
        />
        {value.id && (
          <PermsOnly allowed={Permission.MantraAdmin}>
            <div className="w-100">
              <Text.linkButton
                style={{ flex: 1 }}
                className="ml4"
                onClick={() => setRequestedTransition(true)}
              >
                Transition care period
              </Text.linkButton>
            </div>
          </PermsOnly>
        )}
      </div>
    </>
  );
};

const doCarePeriodsOverlap = (periods: EditableCarePeriod[]) => {
  for (const a of periods) {
    for (const b of periods) {
      if (
        a !== b &&
        a.startDate.valueOf() >= b.startDate.valueOf() &&
        a.startDate.valueOf() < b.endDate.valueOf()
      ) {
        return true;
      }
    }
  }
  return false;
};

const hasDuplicateCarePeriods = (periods: EditableCarePeriod[]) => {
  const rangeLookup = new Set<string>();
  for (const period of periods) {
    const rangeKey = `${period.startDate.valueOf()}-${period.endDate.valueOf()}`;
    if (rangeLookup.has(rangeKey)) return true;
  }
  return false;
};

const endDateSameOfBeforeStart = (periods: EditableCarePeriod[]) => {
  return periods.some(p => p.endDate.valueOf() <= p.startDate.valueOf());
};

const getWarningMessage = (periods: EditableCarePeriod[]) => {
  if (endDateSameOfBeforeStart(periods)) {
    return 'Period end is before or equal to start.';
  }
  if (hasDuplicateCarePeriods(periods)) {
    return 'Duplicate care period added.';
  }
  if (doCarePeriodsOverlap(periods)) {
    return 'Periods are overlapping.';
  }
};
