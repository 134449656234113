import styled from 'styled-components';
import { colors } from '../globalStyles';

export const RoundIconButton = styled.button<{ toggled: boolean }>`
  background-color: rgba(47, 68, 126, 0.4);
  border-color: white;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  width: 60px;
  height: 60px;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease;
  outline: none;
  background: ${({ toggled }) => (toggled ? 'rgba(255,63,63,1)' : 'rgba(255,255,255,0.2)')};

  &:hover {
    background: ${({ toggled }) => (toggled ? 'rgba(255,63,63,0.5)' : 'rgba(255,255,255,0.5)')};
  }
`;

export const SolidRoundIconButton = styled(RoundIconButton)`
  background: white;
  &:hover {
    background: white;
  }
`;

export const PillButton = styled.button`
  border-radius: 50vmin;
  border: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
`;

export const ShadowedPillButton = styled(PillButton)`
  letter-spacing: 2px;
  text-transform: capitalize;
  padding-top: 22px;
  padding-right: 36px;
  padding-bottom: 22px;
  padding-left: 36px;
  font-size: 11px;
  outline: none;
  background: ${colors.primary};
  color: white;

  &:disabled {
    box-shadow: 0px 3px 3px 0px #99999955;
    background: ${colors.grey.disabledBackground};
    color: rgba(0, 0, 0, 0.25);
  }
`;

export const DemiboldP = styled.p`
  color: #192e62;
  font-weight: 600;
`;

interface ColorfulProps {
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  borderColor?: string;
  color?: string;
}

export const ColorfulPillButton = styled(PillButton)<ColorfulProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-color: ${({ backgroundColor, borderColor }) => borderColor ?? backgroundColor};
  border-width: ${({ borderColor }) => (borderColor ? '1px' : '0')};
  border-style: solid;
  color: ${({ color }) => color ?? colors.white};

  display: flex;
  justify-content: space-around;
  align-items: center;

  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;

  padding: 22px 36px 22px 36px;

  transition: background 300ms ease;

  &:hover {
    background-color: ${({ hoverBackgroundColor, backgroundColor }) =>
      hoverBackgroundColor ?? backgroundColor};
    opacity: ${({ hoverBackgroundColor }) => (hoverBackgroundColor ? '100%' : '80%')};
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
`;

export const UnderlineButton = styled.button<ColorfulProps>`
  font-weight: 600;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
  color: ${({ color }) => color ?? colors.white};
`;
