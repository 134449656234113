import { Delete } from 'baseui/icon';
import React from 'react';
import { keyDownHandler } from '../../utils';
import * as Styles from './styles';

type Props<T> = {
  component: (props: ComponentArgs<T>) => JSX.Element;
  setValues: (arg0: T[]) => void;
  values?: T[];
  blank: T;
  max?: number;
  noRemove?: boolean;
  canRemoveIf?: (v: T) => boolean;
  testIdPrefix?: string;
};

export type ComponentArgs<T> = {
  value: T;
  index: number;
  onChange: (arg0: T) => void;
  addRow: () => void;
  removeRow: () => void;
};

export function EzMultirow<T>({
  values = [],
  component: C,
  blank,
  setValues,
  max,
  noRemove,
  canRemoveIf,
  testIdPrefix = '',
}: Props<T>) {
  const addValue = () => {
    if (max && values.length >= max) return;
    setValues([...values, blank]);
  };

  const removeValue = (index: number) => () => {
    setValues(values.filter((_, i) => i !== index));
  };

  const onEditIndex = (index: number) => (updatedValue: T) => {
    setValues(values.map((v, i) => (i !== index ? v : updatedValue)));
  };

  return (
    <div>
      {values.map((value, index) => (
        <div className="flex mb3" key={index}>
          {!noRemove && (!canRemoveIf || canRemoveIf(value)) && (
            <Styles.DeleteButton
              className="left"
              onClick={removeValue(index)}
              onKeyDown={keyDownHandler({ Enter: removeValue(index) })}
              data-testid={`${testIdPrefix}-delete-item-button-${index}`}
              tabIndex={0}
            >
              <Delete size={24} color="red" />
            </Styles.DeleteButton>
          )}
          <C
            key={index}
            value={value}
            index={index}
            addRow={addValue}
            onChange={onEditIndex(index)}
            removeRow={removeValue(index)}
          />
        </div>
      ))}
      <Styles.AddButton
        onClick={addValue}
        onKeyDown={keyDownHandler({ Enter: addValue })}
        data-testid={`${testIdPrefix}-add-item-button`}
        tabIndex={0}
      >
        + Add
      </Styles.AddButton>
    </div>
  );
}
