import React, { useState } from 'react';
import styled from 'styled-components';
import { Select } from '../../Components/Form';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useSidebarQuery } from '../../Components/NavLayout/Counts';
import { useCurrentProvider } from '../../Components/Permissions';
import { PaddedPage } from '../../globalStyles';
import {
  Permission,
  useMarkCareTeamChannelsMutation,
  useMarkPrivateChannelsMutation,
  useMessageUsersQuery,
  useProvidersQuery,
} from '../../graphQL';
import { PageTitle } from '../Shared';
import { ChannelList, ChannelType } from './ChannelList';

export const PageContainer = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  grid-gap: 2em 2em;
  margin-bottom: 4rem;
`;

export const MessagesPage = () => {
  const { hasPermission } = useCurrentProvider();
  const isAdmin = hasPermission(Permission.MantraAdmin);
  const [providerId, setProviderId] = useState<number>();
  const [refetchSidebar] = useSidebarQuery();
  const [readCareTeam] = useMarkCareTeamChannelsMutation({ refetchQueries: [refetchSidebar] });
  const [readPrivate] = useMarkPrivateChannelsMutation({ refetchQueries: [refetchSidebar] });
  const { data: providerData } = useProvidersQuery({ skip: !isAdmin });
  const { data, refetch, loading } = useMessageUsersQuery({
    variables: {
      privateChannels: hasPermission(Permission.PatientMessage),
      careTeamChannels: hasPermission(Permission.ProviderMessage),
      providerId,
    },
  });

  if (!data) return <LoadingPage />;

  const onReadCareTeam = async (channels: number[]) => {
    await readCareTeam({ variables: { channels } });
    refetch();
  };

  const onReadPrivate = async (channels: number[]) => {
    await readPrivate({ variables: { channels } });
    refetch();
  };

  return (
    <PaddedPage style={{ maxWidth: 1200 }}>
      <div className="mb4 flex justify-between">
        <PageTitle>Messages</PageTitle>
        {isAdmin && (
          <div className="w5">
            <Select
              placeholder="Select a Provider"
              clearable
              options={
                providerData?.providers
                  .filter(p => p.role === 'provider')
                  .map(p => ({ id: p.id, label: p.name })) ?? []
              }
              value={providerId}
              onChange={e => setProviderId(e as number | undefined)}
            />
          </div>
        )}
      </div>
      {loading ? (
        <LoadingPage />
      ) : (
        <PageContainer>
          {hasPermission(Permission.PatientMessage) && (
            <ChannelList
              users={data.privateChannels!.filter(c => !!c.channel.lastMessage)}
              channelType={isAdmin ? ChannelType.AdminPrivate : ChannelType.Private}
              title="Patient Messages"
              onClickMarkAsRead={onReadPrivate}
            />
          )}
          {hasPermission(Permission.ProviderMessage) && (
            <ChannelList
              users={data.careTeamChannels!.filter(c => !!c.channel.lastMessage)}
              channelType={ChannelType.CareTeam}
              title="Collaboration Team"
              onClickMarkAsRead={onReadCareTeam}
            />
          )}
        </PageContainer>
      )}
    </PaddedPage>
  );
};
