import { Set as ImSet } from 'immutable';
import { Reducer, useReducer } from 'react';
import { Hashable, HasId } from '../types';

export type ActionKind = 'check' | 'uncheck';

export type SelectEntityAction<IdType extends Hashable> = {
  type: ActionKind;
  provider: HasId<IdType>;
};

const selectEntityReducer = <IdType extends Hashable>(
  state: ImSet<IdType>,
  action: SelectEntityAction<IdType>
) => {
  switch (action.type) {
    case 'check':
      return state.add(action.provider.id);
    case 'uncheck':
      return state.remove(action.provider.id);
    default:
      return state;
  }
};

type HookArgs<IdType extends Hashable> = {
  existingSelections?: IdType[];
};

export const useSelectedEntities = <IdType extends Hashable>({
  existingSelections,
}: HookArgs<IdType> = {}) => {
  const [selectedProviders, dispatch] = useReducer<
    Reducer<ImSet<IdType>, SelectEntityAction<IdType>>
  >(selectEntityReducer, ImSet<IdType>(existingSelections ?? []));
  return [selectedProviders, dispatch] as const;
};
