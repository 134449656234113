import styled from 'styled-components';

interface Props {
  size: string;
}

export const Avatar = styled.img<Props>`
  border-radius: 50%;
  ${({ size }) => `width: ${size};`}
  ${({ size }) => `height: ${size};`}
`;
