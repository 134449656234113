import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors } from '../../globalStyles';
import { cx, keyDownHandler } from '../../utils';
import { Icon } from '../Icons';

export interface ToggleProps {
  toggled?: boolean;
  onChange: (toggled: boolean) => void;
  children?: React.ReactNode;
  style?: CSSProperties;
  className?: string;
}

export function Toggle({ toggled, onChange, children, style, className }: ToggleProps) {
  return (
    <div className={cx('flex items-center', className)} style={style}>
      <Outer
        tabIndex={0}
        className={cx(toggled && 'active')}
        onKeyDown={keyDownHandler({ Enter: () => onChange(!toggled) })}
        onClick={() => onChange(!toggled)}
      >
        <Inner className={cx(toggled && 'active')}>
          <Icon icon="iconsCheckGreenSvg" height={12} width={12} />
        </Inner>
      </Outer>
      <div className="flex-1 ml3">{children}</div>
    </div>
  );
}

const Outer = styled.div`
  border-radius: 50vmin;
  width: 54px;
  height: 34px;
  border: 1px solid #c5cee0;
  background: rgba(143, 155, 179, 0.16);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  user-select: none;
  outline: none;
  position: relative;

  &:focus-visible {
    :after {
      content: '';
      display: block;
      position: absolute;
      inset: -0.15rem;
      border-radius: 50vmin;
      border: 0.15rem solid black;
    }
  }

  &.active {
    background: ${colors.success};
  }
`;

const Inner = styled.div`
  background: #ffffff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin: 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.15s ease-out 0s;

  &.active {
    transform: translateX(20px);
  }

  &:not(.active) * {
    display: none;
  }
`;
