import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { useToast } from 'native-base';
import React from 'react';
import { useUnassignSkillFromUserMutation } from '../../../../graphQL';
import { getStylesheet } from '../../../../styles';
import { Button, Layout, Text } from '../../../core';
import { SkillAssignments } from '../skillAssignmentTypes';
import { GrayBox } from './GrayBox';

type NewSkillsProps = {
  patientName: string;
  patientId: number;
  refetchAssignments: () => void;
  skillAssignments: SkillAssignments;
};

export const NewSkills = ({
  patientId,
  patientName,
  refetchAssignments,
  skillAssignments,
}: NewSkillsProps): JSX.Element => {
  const toast = useToast();
  const [unassignSkill] = useUnassignSkillFromUserMutation({
    onError: () => {
      toast.show({
        description: 'Skill could not be un-assigned.',
        duration: 3000,
      });
    },
    onCompleted: () => {
      void refetchAssignments();

      toast.show({
        description: 'Skill un-assigned successfully.',
        duration: 3000,
      });
    },
  });

  if (skillAssignments.length < 1) {
    return (
      <GrayBox>
        <Text.para>Select a skill from the dropdown to assign it to {patientName}.</Text.para>
      </GrayBox>
    );
  }

  const onUnassignPress = (skillAssignmentId: number) => (): void => {
    void unassignSkill({
      variables: {
        skillAssignmentId,
        userId: patientId,
      },
    });
  };

  return (
    <Layout.VStack space={2}>
      {skillAssignments.map(assignedSkill => {
        const assignedAt =
          assignedSkill.createdAt != null
            ? format(parseISO(assignedSkill.createdAt), 'EEEE, M/dd/yy @ h:mm aaa')
            : 'Unknown date';
        const startedAt =
          assignedSkill.startedAt != null
            ? format(parseISO(assignedSkill.startedAt), 'EEEE, M/dd/yy @ h:mm aaa')
            : '';

        return (
          <Layout.Flex {...styles.assignedSkillBox} key={assignedSkill.id}>
            <Layout.VStack space={1}>
              <Text.para bold>{assignedSkill.skillTitle}</Text.para>
              <Text.paraSmall>Assigned: {assignedAt}</Text.paraSmall>
              {startedAt && <Text.paraSmall>Started: {startedAt}</Text.paraSmall>}
            </Layout.VStack>

            <Button.tertiarySmall
              testID="button-skill-assignments-unassign"
              onPress={onUnassignPress(assignedSkill.id)}
            >
              Unassign
            </Button.tertiarySmall>
          </Layout.Flex>
        );
      })}
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  assignedSkillBox: {
    alignItems: 'center',
    backgroundColor: 'secondary.200',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 4,
  },
});
