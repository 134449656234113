import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as BaseApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { ErrorLink as ApolloErrorLink, ErrorResponse } from '@apollo/link-error';
import { noop } from 'lodash';
import React from 'react';
import { getAuthToken } from './token';
import { isTokenExpired, logout } from './utils';
import { isGeolocationBlocked } from './utils/graphql';

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'include',
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getAuthToken();
  if (isTokenExpired(token)) logout();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation);
});

const errorHandler = ({ graphQLErrors }: ErrorResponse, errorLink: AppErrorLink): void => {
  if (graphQLErrors && isGeolocationBlocked({ graphQLErrors })) {
    errorLink.onGeolocationBlocked();
    return;
  }

  graphQLErrors?.forEach(gqlError => {
    const statusCode = gqlError?.extensions?.exception?.httpStatus;
    if (statusCode === 401) {
      logout();
      window.location.reload();
    }
  });
};

/**
 * Allows us to set the geolocation blocked handler after the
 * geolocation context loads.
 */
class AppErrorLink extends ApolloErrorLink {
  protected geolocationBlockedHandler: () => void;

  constructor() {
    super(apolloError => {
      errorHandler(apolloError, this);
    });

    this.geolocationBlockedHandler = noop;
  }

  onGeolocationBlocked(): void {
    this.geolocationBlockedHandler?.();
  }

  setGeolocationBlockedHandler(onBlocked: () => void): void {
    this.geolocationBlockedHandler = onBlocked;
  }
}

export const errorLink = new AppErrorLink();

export const apolloClient = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  cache,
  // @ts-ignore
  link: authMiddleware.concat(errorLink).concat(httpLink),
  defaultOptions: {
    watchQuery: { fetchPolicy: 'no-cache' },
    query: { fetchPolicy: 'no-cache' },
  },
});

export const ApolloProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <BaseApolloProvider client={apolloClient}>{children}</BaseApolloProvider>
);
