import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FinalButton } from '../../Components/FinalButton';
import { LoadingPage } from '../../Components/LoadingOverlay';
import {
  isMantraAdmin,
  isMantraProvider,
  isMcpOrReferralUser,
  useCurrentProvider,
} from '../../Components/Permissions';
import { PaddedPage, Text } from '../../globalStyles';
import { Permission, useProvidersQuery } from '../../graphQL';
import { McpList, OzList } from './Lists';

enum Page {
  providers,
  staff,
}

type PageStuff = { title: string; link: string };
const pageStuffs: Record<Page, PageStuff> = {
  [Page.providers]: { title: 'Providers', link: '/providers' },
  [Page.staff]: { title: 'University Staff', link: '/providers/uni' },
};

export const ProviderList = () => <ProviderListPage page={Page.providers} />;
export const UniStaffList = () => <ProviderListPage page={Page.staff} />;

type ProviderListPageProps = { page: Page };
function ProviderListPage({ page }: ProviderListPageProps) {
  const { appView, hasPermission } = useCurrentProvider();
  const { data, loading } = useProvidersQuery();
  const history = useHistory();

  if (loading) return <LoadingPage />;
  const providers = data?.providers ?? [];

  const list = (() => {
    switch (page) {
      case Page.staff:
        return <McpList data={providers.filter(isMcpOrReferralUser)} />;
      case Page.providers:
        return <OzList data={providers.filter(p => isMantraProvider(p) || isMantraAdmin(p))} />;
      default:
        throw new Error();
    }
  })();

  if (appView !== 'oz' || !hasPermission(Permission.ProviderList)) return null;
  return (
    <PaddedPage>
      <div className="flex mb3 items-center">
        {Object.values(pageStuffs).map((v, i) => (
          <Link to={v.link} key={i} className="link mr4">
            <Text.h2 role="link" className={`pointer ${page === i ? '' : 'o-50'}`}>
              {v.title}
            </Text.h2>
          </Link>
        ))}
        {page === Page.providers && hasPermission(Permission.MantraAdmin) && (
          <FinalButton
            size="tiny"
            iconLeft="iconsWhitePlusSvg"
            pill
            onClick={() => history.push('/providers/new')}
          >
            New Provider
          </FinalButton>
        )}
        {page === Page.staff && hasPermission(Permission.MantraAdmin) && (
          <FinalButton
            size="tiny"
            pill
            iconLeft="iconsWhitePlusSvg"
            onClick={() => history.push('/organizations/staff/add')}
          >
            New Staff
          </FinalButton>
        )}
      </div>
      {list}
    </PaddedPage>
  );
}
