import { AccommodationsSection, titleAccommodations } from './Section/AccommodationsSection';
import { CampusResourcesSection, titleCampusResources } from './Section/CampusResourcesSection';
import {
  CareAndCollaborationSection,
  titleCareAndCollaboration,
} from './Section/CareAndCollaborationSection';
import { ContinuityOfCareSection, titleContinuityOfCare } from './Section/ContinuityOfCareSection';
import {
  EmergencyProtocolSection,
  titleEmergencyProtocol,
} from './Section/EmergencyProtocolSection';
import { MedicalSection, titleMedical } from './Section/MedicalSection';
import { EditableSectionRowInfo } from './Section/types';

type PanelsType = {
  title: string;
  Component: (props: any) => JSX.Element;
  editableSectionRowInfo: EditableSectionRowInfo[];
};

export const HANDBOOK_PANELS: Readonly<PanelsType>[] = [
  {
    title: titleCareAndCollaboration,
    Component: CareAndCollaborationSection,
    editableSectionRowInfo: [
      {
        label: 'Session Limits Renew Each Academic Year?',
        editInputKey: 'sessionLimitsRenew',
        displayKey: 'sessionLimitsRenew',
      },
      {
        label: 'No Shows and Late Cancellations count toward session limit?',
        info: 'Do patient no-shows and late cancellations (<24hr before appt) result in a deduction of a session?',
        editInputKey: 'noShowsCountForSessionLimits',
        displayKey: 'noShowsCountForSessionLimits',
      },
      {
        label: 'Default Therapy Cadence',
        editInputKey: 'defaultTherapyCadence',
        displayKey: 'defaultTherapyCadence',
      },
      {
        label: 'Co-pay charged by partner for Mantra services?',
        info: 'Does the partner charge a co-pay for Mantra services?',
        editInputKey: 'coPayChargedByPartner',
        displayKey: 'coPayChargedByPartner',
      },
    ],
  },
  {
    title: titleCampusResources,
    Component: CampusResourcesSection,
    editableSectionRowInfo: [],
  },
  {
    title: titleAccommodations,
    Component: AccommodationsSection,
    editableSectionRowInfo: [
      {
        label: 'Requesting academic accommodations',
        editInputKey: 'requestingAcademicAccomodations',
        displayKey: 'requestingAcademicAccomodations',
      },
      {
        label: 'Full ADHD evaluation',
        editInputKey: 'fullADHDEvaluation',
        displayKey: 'fullADHDEvaluation',
      },
      {
        label: 'Protocol for obtaining Medical Leave documentation',
        editInputKey: 'protocolForMedicalLeaveDoc',
        displayKey: 'protocolForMedicalLeaveDoc',
      },
    ],
  },
  {
    title: titleContinuityOfCare,
    Component: ContinuityOfCareSection,
    editableSectionRowInfo: [
      {
        label: 'Summer Sponsored Break: Who is eligible?',
        editInputKey: 'summerBreakEligibility',
        displayKey: 'summerBreakEligibility',
      },
      {
        label: 'Winter Sponsored Break: Who is eligible?',
        editInputKey: 'winterBreakEligibility',
        displayKey: 'winterBreakEligibility',
      },
    ],
  },
  {
    title: titleMedical,
    Component: MedicalSection,
    editableSectionRowInfo: [
      {
        label: 'Labs',
        editInputKey: 'labsNearCampus',
        displayKey: 'labsNearCampus',
      },
      {
        label: 'EKGs',
        editInputKey: 'ekgsNearCampus',
        displayKey: 'ekgsNearCampus',
      },
      {
        label: 'Vitals',
        editInputKey: 'vitalsNearCampus',
        displayKey: 'vitalsNearCampus',
      },
    ],
  },
  {
    title: titleEmergencyProtocol,
    Component: EmergencyProtocolSection,
    editableSectionRowInfo: [
      {
        label: 'High level protocol for managing emergencies',
        editInputKey: 'highLevelProtocolManagingEmergency',
        displayKey: 'highLevelProtocolManagingEmergency',
      },
      {
        label: 'Campus safety/police number',
        editInputKey: 'campusSafetyPoliceNumber',
        displayKey: 'campusSafetyPoliceNumber',
      },
      {
        label: 'Supporting Clinician',
        editInputKey: 'supportingClinician',
        displayKey: 'supportingClinician',
      },
      {
        label: 'Hospitalization',
        editInputKey: 'hospitalization',
        displayKey: 'hospitalization',
      },
      {
        label: 'Main point of contact to inform about the crisis',
        editInputKey: 'mainPointOfContactCrisis',
        displayKey: 'mainPointOfContactCrisis',
      },
      {
        label: 'On-Campus Crisis Team',
        editInputKey: 'campusCrisisTeamInProduct',
        displayKey: 'campusCrisisTeamInProduct',
      },
    ],
  },
];
