import { Moment } from 'moment';
import { WizardRenderProps } from '../../Components/Wizard';
import {
  AppointmentTemplateFragment,
  AppointmentUserV2Fragment,
  Availability,
  CareType,
  Provider,
  ReschedulingAppointmentFragment,
} from '../../graphQL';
import { Nullable } from '../../types';

export type AppointmentType = 'checkin' | 'adhoc' | 'intake';

export type ProviderWithUpcomingAvailability = Pick<Provider, 'id' | 'name' | 'careTypes'> & {
  upcomingAvailabilityV4?: { availability: Nullable<Pick<Availability, 'start'>>[] };
};

export enum BookingBlocker {
  InvalidInsurance = 'invalid-insurance',
  MissingPaymentMethod = 'missing-payment-method',
}

export type AppointmentDetails = {
  provider?: Pick<Provider, 'id' | 'name' | 'careTypes'>;
  duration?: number;
  time?: Moment;
  templateIndex?: number;
  careType?: CareType;
  appointmentType?: string;
  organizationId?: number | null;
  isFeeForServiceTime?: boolean | null;
};

export type BookingWizardData = {
  initialStepIndex: number;
  patient: AppointmentUserV2Fragment;
  appointmentTemplates: AppointmentTemplateFragment[];
  reschedulingAppointment?: ReschedulingAppointmentFragment & { outOfPolicy?: boolean };
  appointment: AppointmentDetails;
  didEncounterTimeConflict: boolean;
  usesProviderNetwork?: boolean;
  blockers?: BookingBlocker[];
  isFeeForServiceTime?: boolean | null;
};

export type BookingWizardProps = WizardRenderProps<BookingWizardData>;
