import React, { useState } from 'react';
import styled from 'styled-components';
import { FinalButton } from '../../Components/FinalButton';
import { Modal } from '../../Components/Modal/Modal';
import { colors, Text } from '../../globalStyles';
import { useAdminResetUserPasswordMutation } from '../../graphQL';
import { useDrilldownContext } from '../Users/helpers';

export const GeneratePassword = () => {
  const { user } = useDrilldownContext();
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [resetSuccessModalOpen, setResetSuccessModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [resetUserPassword] = useAdminResetUserPasswordMutation();
  const onResetPassword = async () => {
    const { data } = await resetUserPassword({ variables: { userId: user.id } });

    if (!data) {
      return null;
    }

    const password = data.resetUserPassword;
    setResetPasswordModalOpen(false);
    setResetSuccessModalOpen(true);
    setNewPassword(password);
  };

  const closeResetSuccessModal = () => {
    setResetSuccessModalOpen(false);
    setNewPassword('');
  };

  return (
    <>
      <Text.body>
        You can generate a temporary new password for a student account. This will destroy their
        existing password, which cannot be recovered. Upon login, they will be prompted to enter a
        new password. Generate new password
      </Text.body>
      <Text.linkButton onClick={() => setResetPasswordModalOpen(true)}>
        Generate new password
      </Text.linkButton>
      <ResetPasswordModal
        isOpen={resetPasswordModalOpen}
        onClose={() => setResetPasswordModalOpen(false)}
        onResetPassword={onResetPassword}
      />
      <ResetPasswordSuccessModal
        isOpen={resetSuccessModalOpen}
        onClose={closeResetSuccessModal}
        password={newPassword}
      />
    </>
  );
};

type ResetPasswordModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onResetPassword: () => void;
};

const ResetPasswordModal = ({ isOpen, onClose, onResetPassword }: ResetPasswordModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="tiny">
      <Text.h2 className="mv3 mh4">Generate new password?</Text.h2>
      <Text.body className="mv4 mh4">
        Are you sure you want to generate a temporary new password for ChildOrg1 Admin? They will no
        longer be able to log in with their old password.
      </Text.body>
      <div className="flex justify-between">
        <FinalButton className="mh4" onClick={onResetPassword}>
          Generate new password
        </FinalButton>
        <FinalButton className="flex-1 mr4" kind="outline_black" onClick={onClose}>
          Cancel
        </FinalButton>
      </div>
    </Modal>
  );
};

type ResetPasswordSuccessModalProps = {
  isOpen: boolean;
  onClose: () => void;
  password: string;
};

const ResetPasswordSuccessModal = ({
  isOpen,
  onClose,
  password,
}: ResetPasswordSuccessModalProps) => {
  const [copied, setCopied] = useState('');
  const copyPassword = async (newPassword: string) => {
    await navigator.clipboard.writeText(newPassword);
    setCopied('Copied.');
  };

  const onCloseModal = () => {
    setCopied('');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size="small">
      <Text.h2 className="mv3 mh3">New password generated</Text.h2>
      <Text.body className="mv4 mh3">
        Here is the new password. It will only appear here, once.
        <Text.bold>
          {' '}
          After closing this modal, you will never be able to retrieve this password again.
        </Text.bold>
        {'\n\n'}
        The provider will not received an automated email with this password; you will need to send
        it to them.
      </Text.body>
      <div className="flex">
        <div className=" mh3 ph3 pv2" style={{ backgroundColor: colors.background }}>
          <PasswordContainer>{password}</PasswordContainer>
        </div>
        <FinalButton className="mr4" onClick={() => copyPassword(password)}>
          Copy to clipboard
        </FinalButton>
        <Text.body className="pv2">{copied}</Text.body>
      </div>
    </Modal>
  );
};

const PasswordContainer = styled.div`
  font-family: monospace;
  background-color: #f5f5f5;
  margin: 10px 0px;
  display: inline-block;
  font-size: 16px;
`;
