import { useCallback, useMemo, useState } from 'react';
import { Stack, stackPeak, stackPop, stackPush } from '../../../dataStructures';
import { NestedOption, Option, Value } from './types';

type HookArgs = {
  options: NestedOption[];
  onSelect: (id: Value) => void;
};

type StackValue = {
  title?: string;
  options: Option<{ hasMore: boolean }>[];
};

const useNestedOptions = ({ options, onSelect }: HookArgs) => {
  const idxLowerBound = -1;
  const [currIdx, setCurrIdx] = useState<number>(idxLowerBound);
  const [optionStack, setOptionStack] = useState<Stack<StackValue>>([optionsToStackValue(options)]);
  const topOfStack = useMemo(() => stackPeak(optionStack), [optionStack]);

  const reset = useCallback(() => {
    setOptionStack([optionsToStackValue(options)]);
    setCurrIdx(idxLowerBound);
  }, [setOptionStack, setCurrIdx, idxLowerBound, options]);

  const selectOne = useCallback(
    (opt: Option<{ hasMore: boolean }>) => {
      const { hasMore } = opt.data;
      if (!hasMore) return onSelect(opt.id);
      /* if has more push the next options on the stack */
      const subOptions = options.find(o => o.id === opt.id)?.subcategories ?? [];
      const nextStackValue = optionsToStackValue(subOptions, opt.label);
      setOptionStack(prevStack => stackPush(prevStack, nextStackValue));
      setCurrIdx(idxLowerBound);
    },
    [setOptionStack, options, idxLowerBound, onSelect]
  );

  const goToPrevCategory = useCallback(() => {
    setOptionStack(prevStack => {
      if (prevStack.length <= 1) return prevStack;
      const [poppedStack] = stackPop(prevStack);
      return poppedStack;
    });
    setCurrIdx(idxLowerBound);
  }, [setOptionStack, setCurrIdx, idxLowerBound]);

  const canGoToPrevCategory = optionStack.length > 1;

  /* top of stack options logic */
  const moveOptionIdx = useCallback(
    (direction: -1 | 1) => {
      const idxUpperBound = (topOfStack?.options ?? []).length - 1;
      // clamp
      const nextIdx = Math.max(idxLowerBound, Math.min(direction + currIdx, idxUpperBound));
      setCurrIdx(nextIdx);
    },
    [setCurrIdx, currIdx, topOfStack, idxLowerBound]
  );

  return {
    currentOptions: topOfStack?.options ?? [],
    title: topOfStack?.title,
    canGoToPrevCategory,
    currIdx,
    selectOne,
    goToPrevCategory,
    moveOptionIdx,
    reset,
  };
};

const optionsToStackValue = (options: NestedOption[], title?: string): StackValue => {
  return {
    title,
    options: options.map(o => ({
      id: o.id,
      label: o.label,
      data: { hasMore: !!o.subcategories },
    })),
  };
};

export default useNestedOptions;
