import moment from 'moment';
import React, { useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { Icon } from '../../../Components/Icons';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { UniversityLogo } from '../../../Components/Organization/UniversityLogo';
import {
  CurrentProviderQuery,
  OrganizationAnnouncement,
  OrganizationAnnouncementsByDaysQuery,
  useOrganizationAnnouncementsByDaysQuery,
} from '../../../graphQL';
import {
  isMantraAdmin,
  isMantraProvider,
  isMcpUser,
  useCurrentProvider,
} from '../../../Components/Permissions';
import { Divider, EmptyContentDiv } from '../../CampusAnnouncements/styles';
import { UnexpectedError } from '../../Shared';
import { useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';
import { Link } from '../../../Components/Links';
import { CampusAnnouncementModal } from '../../CampusAnnouncements/CampusAnnouncementModal';

type AnnouncementProp = {
  announcement: OrganizationAnnouncement;
  organizationId: number;
  refetch: () => Promise<ApolloQueryResult<OrganizationAnnouncementsByDaysQuery>>;
};

type CampusUpdatesProps = {
  organizationId: number;
  currentProvider: CurrentProviderQuery['currentProvider'];
};

const DATE_TIME_FORMAT = 'M/D/YY h:mma z';

export const CampusAnnouncementSection = () => {
  const {
    user: { organization },
  } = useDrilldownContext();
  const { currentProvider } = useCurrentProvider();

  if (!organization) {
    return null;
  }

  const linkToHandbook = getLinkToTab(currentProvider, organization.id);

  return (
    <div className="ba b--moon-gray ph3 pv2 mb4">
      <div className="flex justify-between">
        <Link to={linkToHandbook} className="link pointer">
          <Text.h3>{organization.name}</Text.h3>
        </Link>
        <UniversityLogo
          organizationId={organization.id}
          linkTo={linkToHandbook}
          className="relative"
          right="0"
        />
      </div>
      <div className="flex">
        <Icon icon="iconsBlackHandbookSvg" alt="Handbook Icon" />
        <Link to={linkToHandbook} className="link pointer">
          <Text.bodySmallBold className="ml2">View Handbook</Text.bodySmallBold>
        </Link>
      </div>
      <Divider />
      <CampusUpdates organizationId={organization.id} currentProvider={currentProvider} />
    </div>
  );
};

const CampusUpdates = ({ organizationId, currentProvider }: CampusUpdatesProps) => {
  const { data, error, loading, refetch } = useOrganizationAnnouncementsByDaysQuery({
    variables: { organizationId },
  });
  if (loading) return <LoadingPage />;
  if (!data || error) return <UnexpectedError />;
  const announcements = data.organizationAnnouncementsByDays;
  const linkToUpdates = getLinkToTab(currentProvider, organizationId, 'updates');

  return (
    <>
      <div className="flex justify-between items-center">
        <Text.label>CAMPUS UPDATES</Text.label>
        <Link to={linkToUpdates} className="link">
          <Text.bodySmallBold kind="grayText">See all</Text.bodySmallBold>
        </Link>
      </div>
      {announcements.length ? (
        announcements.map(announcement => (
          <Announcement
            announcement={announcement}
            organizationId={organizationId}
            refetch={refetch}
          />
        ))
      ) : (
        <EmptyContentDiv>
          <Text.bodySmall className="i">No updates within last 30 days</Text.bodySmall>
        </EmptyContentDiv>
      )}
    </>
  );
};

const Announcement = ({ announcement, organizationId, refetch }: AnnouncementProp) => {
  const { id, title, details, createdAt } = announcement;
  const formattedDateTime = moment(createdAt).tz(moment.tz.guess()).format(DATE_TIME_FORMAT);
  const [showingModal, setShowingModal] = useState(false);
  const handleViewDetails = () => {
    setShowingModal(true);
  };

  return (
    <Styles.announcementContainer key={id}>
      <Text.bodySmall>{`${formattedDateTime}`}</Text.bodySmall>
      <Text.bodySmallBold>{title}</Text.bodySmallBold>
      <Text.bodySmallBold className="pointer" kind="link" onClick={handleViewDetails}>
        View details
      </Text.bodySmallBold>
      <CampusAnnouncementModal
        rowInfo={{ title, details, id }}
        showModal={showingModal}
        setShowModal={setShowingModal}
        organizationId={organizationId}
        refetch={refetch}
      />
    </Styles.announcementContainer>
  );
};

type CampusAnnouncementTabNameType = 'handbook' | 'updates';

const getLinkToTab = (
  currentProvider: CurrentProviderQuery['currentProvider'],
  organizationId: number,
  tabName: CampusAnnouncementTabNameType = 'handbook'
) => {
  let linkToTab = `/organizations/${organizationId}/`;
  if (isMantraAdmin(currentProvider)) {
    linkToTab = linkToTab.concat(`admin?tab=${tabName}`);
  } else if (isMantraProvider(currentProvider)) {
    linkToTab = linkToTab.concat(`provider?tab=${tabName}`);
  } else if (isMcpUser(currentProvider)) {
    linkToTab = linkToTab.concat(`${tabName}`);
  } else {
    throw new Error('Cannot create handbook link for unknown provider permission');
  }

  return linkToTab;
};
