/**
 * These are the old diagnosis codes that we use for legacy purposes. Please use the diagnosisCodes.ts file from now on.
 *
 * This data comes from the ICD-10-CM list. We've taken all the mental health
 * codes (they start with F) and added a few relevant other codes around things
 * like sleep, etc. This only includes codes which are valid for submission
 * (i.e. not section headers) (i.e. all codes here have a "V" in the "H" column
 * in the spreadsheet they come from).
 */

export const descriptionsByDiagnosisCode = {
  'E66.9': 'Obesity, unspecified',
  'F01.50': 'Vascular dementia without behavioral disturbance',
  'F01.51': 'Vascular dementia with behavioral disturbance',
  'F02.80': 'Dementia in other diseases classified elsewhere without behavioral disturbance',
  'F02.81': 'Dementia in other diseases classified elsewhere with behavioral disturbance',
  'F03.90': 'Unspecified dementia without behavioral disturbance',
  'F03.91': 'Unspecified dementia with behavioral disturbance',
  F04: 'Amnestic disorder due to known physiological condition',
  F05: 'Delirium due to known physiological condition',
  'F06.0': 'Psychotic disorder with hallucinations due to known physiological condition',
  'F06.1': 'Catatonic disorder due to known physiological condition',
  'F06.2': 'Psychotic disorder with delusions due to known physiological condition',
  'F06.30': 'Mood disorder due to known physiological condition, unspecified',
  'F06.31': 'Mood disorder due to known physiological condition with depressive features',
  'F06.32': 'Mood disorder due to known physiological condition with major depressive-like episode',
  'F06.33': 'Mood disorder due to known physiological condition with manic features',
  'F06.34': 'Mood disorder due to known physiological condition with mixed features',
  'F06.4': 'Anxiety disorder due to known physiological condition',
  'F06.8': 'Other specified mental disorders due to known physiological condition',
  'F07.0': 'Personality change due to known physiological condition',
  'F07.81': 'Postconcussional syndrome',
  'F07.89': 'Other personality and behavioral disorders due to known physiological condition',
  'F07.9': 'Unspecified personality and behavioral disorder due to known physiological condition',
  F09: 'Unspecified mental disorder due to known physiological condition',
  'F10.10': 'Alcohol abuse, uncomplicated',
  'F10.11': 'Alcohol abuse, in remission',
  'F10.120': 'Alcohol abuse with intoxication, uncomplicated',
  'F10.121': 'Alcohol abuse with intoxication delirium',
  'F10.129': 'Alcohol abuse with intoxication, unspecified',
  'F10.130': 'Alcohol abuse with withdrawal, uncomplicated',
  'F10.131': 'Alcohol abuse with withdrawal delirium',
  'F10.132': 'Alcohol abuse with withdrawal with perceptual disturbance',
  'F10.139': 'Alcohol abuse with withdrawal, unspecified',
  'F10.14': 'Alcohol abuse with alcohol-induced mood disorder',
  'F10.150': 'Alcohol abuse with alcohol-induced psychotic disorder with delusions',
  'F10.151': 'Alcohol abuse with alcohol-induced psychotic disorder with hallucinations',
  'F10.159': 'Alcohol abuse with alcohol-induced psychotic disorder, unspecified',
  'F10.180': 'Alcohol abuse with alcohol-induced anxiety disorder',
  'F10.181': 'Alcohol abuse with alcohol-induced sexual dysfunction',
  'F10.182': 'Alcohol abuse with alcohol-induced sleep disorder',
  'F10.188': 'Alcohol abuse with other alcohol-induced disorder',
  'F10.19': 'Alcohol abuse with unspecified alcohol-induced disorder',
  'F10.20': 'Alcohol dependence, uncomplicated',
  'F10.21': 'Alcohol dependence, in remission',
  'F10.220': 'Alcohol dependence with intoxication, uncomplicated',
  'F10.221': 'Alcohol dependence with intoxication delirium',
  'F10.229': 'Alcohol dependence with intoxication, unspecified',
  'F10.230': 'Alcohol dependence with withdrawal, uncomplicated',
  'F10.231': 'Alcohol dependence with withdrawal delirium',
  'F10.232': 'Alcohol dependence with withdrawal with perceptual disturbance',
  'F10.239': 'Alcohol dependence with withdrawal, unspecified',
  'F10.24': 'Alcohol dependence with alcohol-induced mood disorder',
  'F10.250': 'Alcohol dependence with alcohol-induced psychotic disorder with delusions',
  'F10.251': 'Alcohol dependence with alcohol-induced psychotic disorder with hallucinations',
  'F10.259': 'Alcohol dependence with alcohol-induced psychotic disorder, unspecified',
  'F10.26': 'Alcohol dependence with alcohol-induced persisting amnestic disorder',
  'F10.27': 'Alcohol dependence with alcohol-induced persisting dementia',
  'F10.280': 'Alcohol dependence with alcohol-induced anxiety disorder',
  'F10.281': 'Alcohol dependence with alcohol-induced sexual dysfunction',
  'F10.282': 'Alcohol dependence with alcohol-induced sleep disorder',
  'F10.288': 'Alcohol dependence with other alcohol-induced disorder',
  'F10.29': 'Alcohol dependence with unspecified alcohol-induced disorder',
  'F10.920': 'Alcohol use, unspecified with intoxication, uncomplicated',
  'F10.921': 'Alcohol use, unspecified with intoxication delirium',
  'F10.929': 'Alcohol use, unspecified with intoxication, unspecified',
  'F10.930': 'Alcohol use, unspecified with withdrawal, uncomplicated',
  'F10.931': 'Alcohol use, unspecified with withdrawal delirium',
  'F10.932': 'Alcohol use, unspecified with withdrawal with perceptual disturbance',
  'F10.939': 'Alcohol use, unspecified with withdrawal, unspecified',
  'F10.94': 'Alcohol use, unspecified with alcohol-induced mood disorder',
  'F10.950': 'Alcohol use, unspecified with alcohol-induced psychotic disorder with delusions',
  'F10.951': 'Alcohol use, unspecified with alcohol-induced psychotic disorder with hallucinations',
  'F10.959': 'Alcohol use, unspecified with alcohol-induced psychotic disorder, unspecified',
  'F10.96': 'Alcohol use, unspecified with alcohol-induced persisting amnestic disorder',
  'F10.97': 'Alcohol use, unspecified with alcohol-induced persisting dementia',
  'F10.980': 'Alcohol use, unspecified with alcohol-induced anxiety disorder',
  'F10.981': 'Alcohol use, unspecified with alcohol-induced sexual dysfunction',
  'F10.982': 'Alcohol use, unspecified with alcohol-induced sleep disorder',
  'F10.988': 'Alcohol use, unspecified with other alcohol-induced disorder',
  'F10.99': 'Alcohol use, unspecified with unspecified alcohol-induced disorder',
  'F11.10': 'Opioid abuse, uncomplicated',
  'F11.11': 'Opioid abuse, in remission',
  'F11.120': 'Opioid abuse with intoxication, uncomplicated',
  'F11.121': 'Opioid abuse with intoxication delirium',
  'F11.122': 'Opioid abuse with intoxication with perceptual disturbance',
  'F11.129': 'Opioid abuse with intoxication, unspecified',
  'F11.13': 'Opioid abuse with withdrawal',
  'F11.14': 'Opioid abuse with opioid-induced mood disorder',
  'F11.150': 'Opioid abuse with opioid-induced psychotic disorder with delusions',
  'F11.151': 'Opioid abuse with opioid-induced psychotic disorder with hallucinations',
  'F11.159': 'Opioid abuse with opioid-induced psychotic disorder, unspecified',
  'F11.181': 'Opioid abuse with opioid-induced sexual dysfunction',
  'F11.182': 'Opioid abuse with opioid-induced sleep disorder',
  'F11.188': 'Opioid abuse with other opioid-induced disorder',
  'F11.19': 'Opioid abuse with unspecified opioid-induced disorder',
  'F11.20': 'Opioid dependence, uncomplicated',
  'F11.21': 'Opioid dependence, in remission',
  'F11.220': 'Opioid dependence with intoxication, uncomplicated',
  'F11.221': 'Opioid dependence with intoxication delirium',
  'F11.222': 'Opioid dependence with intoxication with perceptual disturbance',
  'F11.229': 'Opioid dependence with intoxication, unspecified',
  'F11.23': 'Opioid dependence with withdrawal',
  'F11.24': 'Opioid dependence with opioid-induced mood disorder',
  'F11.250': 'Opioid dependence with opioid-induced psychotic disorder with delusions',
  'F11.251': 'Opioid dependence with opioid-induced psychotic disorder with hallucinations',
  'F11.259': 'Opioid dependence with opioid-induced psychotic disorder, unspecified',
  'F11.281': 'Opioid dependence with opioid-induced sexual dysfunction',
  'F11.282': 'Opioid dependence with opioid-induced sleep disorder',
  'F11.288': 'Opioid dependence with other opioid-induced disorder',
  'F11.29': 'Opioid dependence with unspecified opioid-induced disorder',
  'F11.90': 'Opioid use, unspecified, uncomplicated',
  'F11.920': 'Opioid use, unspecified with intoxication, uncomplicated',
  'F11.921': 'Opioid use, unspecified with intoxication delirium',
  'F11.922': 'Opioid use, unspecified with intoxication with perceptual disturbance',
  'F11.929': 'Opioid use, unspecified with intoxication, unspecified',
  'F11.93': 'Opioid use, unspecified with withdrawal',
  'F11.94': 'Opioid use, unspecified with opioid-induced mood disorder',
  'F11.950': 'Opioid use, unspecified with opioid-induced psychotic disorder with delusions',
  'F11.951': 'Opioid use, unspecified with opioid-induced psychotic disorder with hallucinations',
  'F11.959': 'Opioid use, unspecified with opioid-induced psychotic disorder, unspecified',
  'F11.981': 'Opioid use, unspecified with opioid-induced sexual dysfunction',
  'F11.982': 'Opioid use, unspecified with opioid-induced sleep disorder',
  'F11.988': 'Opioid use, unspecified with other opioid-induced disorder',
  'F11.99': 'Opioid use, unspecified with unspecified opioid-induced disorder',
  'F12.10': 'Cannabis abuse, uncomplicated',
  'F12.11': 'Cannabis abuse, in remission',
  'F12.120': 'Cannabis abuse with intoxication, uncomplicated',
  'F12.121': 'Cannabis abuse with intoxication delirium',
  'F12.122': 'Cannabis abuse with intoxication with perceptual disturbance',
  'F12.129': 'Cannabis abuse with intoxication, unspecified',
  'F12.13': 'Cannabis abuse with withdrawal',
  'F12.150': 'Cannabis abuse with psychotic disorder with delusions',
  'F12.151': 'Cannabis abuse with psychotic disorder with hallucinations',
  'F12.159': 'Cannabis abuse with psychotic disorder, unspecified',
  'F12.180': 'Cannabis abuse with cannabis-induced anxiety disorder',
  'F12.188': 'Cannabis abuse with other cannabis-induced disorder',
  'F12.19': 'Cannabis abuse with unspecified cannabis-induced disorder',
  'F12.20': 'Cannabis dependence, uncomplicated',
  'F12.21': 'Cannabis dependence, in remission',
  'F12.220': 'Cannabis dependence with intoxication, uncomplicated',
  'F12.221': 'Cannabis dependence with intoxication delirium',
  'F12.222': 'Cannabis dependence with intoxication with perceptual disturbance',
  'F12.229': 'Cannabis dependence with intoxication, unspecified',
  'F12.23': 'Cannabis dependence with withdrawal',
  'F12.250': 'Cannabis dependence with psychotic disorder with delusions',
  'F12.251': 'Cannabis dependence with psychotic disorder with hallucinations',
  'F12.259': 'Cannabis dependence with psychotic disorder, unspecified',
  'F12.280': 'Cannabis dependence with cannabis-induced anxiety disorder',
  'F12.288': 'Cannabis dependence with other cannabis-induced disorder',
  'F12.29': 'Cannabis dependence with unspecified cannabis-induced disorder',
  'F12.90': 'Cannabis use, unspecified, uncomplicated',
  'F12.920': 'Cannabis use, unspecified with intoxication, uncomplicated',
  'F12.921': 'Cannabis use, unspecified with intoxication delirium',
  'F12.922': 'Cannabis use, unspecified with intoxication with perceptual disturbance',
  'F12.929': 'Cannabis use, unspecified with intoxication, unspecified',
  'F12.93': 'Cannabis use, unspecified with withdrawal',
  'F12.950': 'Cannabis use, unspecified with psychotic disorder with delusions',
  'F12.951': 'Cannabis use, unspecified with psychotic disorder with hallucinations',
  'F12.959': 'Cannabis use, unspecified with psychotic disorder, unspecified',
  'F12.980': 'Cannabis use, unspecified with anxiety disorder',
  'F12.988': 'Cannabis use, unspecified with other cannabis-induced disorder',
  'F12.99': 'Cannabis use, unspecified with unspecified cannabis-induced disorder',
  'F13.10': 'Sedative, hypnotic or anxiolytic abuse, uncomplicated',
  'F13.11': 'Sedative, hypnotic or anxiolytic abuse, in remission',
  'F13.120': 'Sedative, hypnotic or anxiolytic abuse with intoxication, uncomplicated',
  'F13.121': 'Sedative, hypnotic or anxiolytic abuse with intoxication delirium',
  'F13.129': 'Sedative, hypnotic or anxiolytic abuse with intoxication, unspecified',
  'F13.130': 'Sedative, hypnotic or anxiolytic abuse with withdrawal, uncomplicated',
  'F13.131': 'Sedative, hypnotic or anxiolytic abuse with withdrawal delirium',
  'F13.132': 'Sedative, hypnotic or anxiolytic abuse with withdrawal with perceptual disturbance',
  'F13.139': 'Sedative, hypnotic or anxiolytic abuse with withdrawal, unspecified',
  'F13.14':
    'Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced mood disorder',
  'F13.150':
    'Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions',
  'F13.151':
    'Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations',
  'F13.159':
    'Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified',
  'F13.180':
    'Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced anxiety disorder',
  'F13.181':
    'Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sexual dysfunction',
  'F13.182':
    'Sedative, hypnotic or anxiolytic abuse with sedative, hypnotic or anxiolytic-induced sleep disorder',
  'F13.188':
    'Sedative, hypnotic or anxiolytic abuse with other sedative, hypnotic or anxiolytic-induced disorder',
  'F13.19':
    'Sedative, hypnotic or anxiolytic abuse with unspecified sedative, hypnotic or anxiolytic-induced disorder',
  'F13.20': 'Sedative, hypnotic or anxiolytic dependence, uncomplicated',
  'F13.21': 'Sedative, hypnotic or anxiolytic dependence, in remission',
  'F13.220': 'Sedative, hypnotic or anxiolytic dependence with intoxication, uncomplicated',
  'F13.221': 'Sedative, hypnotic or anxiolytic dependence with intoxication delirium',
  'F13.229': 'Sedative, hypnotic or anxiolytic dependence with intoxication, unspecified',
  'F13.230': 'Sedative, hypnotic or anxiolytic dependence with withdrawal, uncomplicated',
  'F13.231': 'Sedative, hypnotic or anxiolytic dependence with withdrawal delirium',
  'F13.232':
    'Sedative, hypnotic or anxiolytic dependence with withdrawal with perceptual disturbance',
  'F13.239': 'Sedative, hypnotic or anxiolytic dependence with withdrawal, unspecified',
  'F13.24':
    'Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced mood disorder',
  'F13.250':
    'Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions',
  'F13.251':
    'Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations',
  'F13.259':
    'Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified',
  'F13.26':
    'Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder',
  'F13.27':
    'Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting dementia',
  'F13.280':
    'Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced anxiety disorder',
  'F13.281':
    'Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sexual dysfunction',
  'F13.282':
    'Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced sleep disorder',
  'F13.288':
    'Sedative, hypnotic or anxiolytic dependence with other sedative, hypnotic or anxiolytic-induced disorder',
  'F13.29':
    'Sedative, hypnotic or anxiolytic dependence with unspecified sedative, hypnotic or anxiolytic-induced disorder',
  'F13.90': 'Sedative, hypnotic, or anxiolytic use, unspecified, uncomplicated',
  'F13.920': 'Sedative, hypnotic or anxiolytic use, unspecified with intoxication, uncomplicated',
  'F13.921': 'Sedative, hypnotic or anxiolytic use, unspecified with intoxication delirium',
  'F13.929': 'Sedative, hypnotic or anxiolytic use, unspecified with intoxication, unspecified',
  'F13.930': 'Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, uncomplicated',
  'F13.931': 'Sedative, hypnotic or anxiolytic use, unspecified with withdrawal delirium',
  'F13.932':
    'Sedative, hypnotic or anxiolytic use, unspecified with withdrawal with perceptual disturbances',
  'F13.939': 'Sedative, hypnotic or anxiolytic use, unspecified with withdrawal, unspecified',
  'F13.94':
    'Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced mood disorder',
  'F13.950':
    'Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with delusions',
  'F13.951':
    'Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder with hallucinations',
  'F13.959':
    'Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced psychotic disorder, unspecified',
  'F13.96':
    'Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder',
  'F13.97':
    'Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting dementia',
  'F13.980':
    'Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced anxiety disorder',
  'F13.981':
    'Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sexual dysfunction',
  'F13.982':
    'Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced sleep disorder',
  'F13.988':
    'Sedative, hypnotic or anxiolytic use, unspecified with other sedative, hypnotic or anxiolytic-induced disorder',
  'F13.99':
    'Sedative, hypnotic or anxiolytic use, unspecified with unspecified sedative, hypnotic or anxiolytic-induced disorder',
  'F14.10': 'Cocaine abuse, uncomplicated',
  'F14.11': 'Cocaine abuse, in remission',
  'F14.120': 'Cocaine abuse with intoxication, uncomplicated',
  'F14.121': 'Cocaine abuse with intoxication with delirium',
  'F14.122': 'Cocaine abuse with intoxication with perceptual disturbance',
  'F14.129': 'Cocaine abuse with intoxication, unspecified',
  'F14.13': 'Cocaine abuse, unspecified with withdrawal',
  'F14.14': 'Cocaine abuse with cocaine-induced mood disorder',
  'F14.150': 'Cocaine abuse with cocaine-induced psychotic disorder with delusions',
  'F14.151': 'Cocaine abuse with cocaine-induced psychotic disorder with hallucinations',
  'F14.159': 'Cocaine abuse with cocaine-induced psychotic disorder, unspecified',
  'F14.180': 'Cocaine abuse with cocaine-induced anxiety disorder',
  'F14.181': 'Cocaine abuse with cocaine-induced sexual dysfunction',
  'F14.182': 'Cocaine abuse with cocaine-induced sleep disorder',
  'F14.188': 'Cocaine abuse with other cocaine-induced disorder',
  'F14.19': 'Cocaine abuse with unspecified cocaine-induced disorder',
  'F14.20': 'Cocaine dependence, uncomplicated',
  'F14.21': 'Cocaine dependence, in remission',
  'F14.220': 'Cocaine dependence with intoxication, uncomplicated',
  'F14.221': 'Cocaine dependence with intoxication delirium',
  'F14.222': 'Cocaine dependence with intoxication with perceptual disturbance',
  'F14.229': 'Cocaine dependence with intoxication, unspecified',
  'F14.23': 'Cocaine dependence with withdrawal',
  'F14.24': 'Cocaine dependence with cocaine-induced mood disorder',
  'F14.250': 'Cocaine dependence with cocaine-induced psychotic disorder with delusions',
  'F14.251': 'Cocaine dependence with cocaine-induced psychotic disorder with hallucinations',
  'F14.259': 'Cocaine dependence with cocaine-induced psychotic disorder, unspecified',
  'F14.280': 'Cocaine dependence with cocaine-induced anxiety disorder',
  'F14.281': 'Cocaine dependence with cocaine-induced sexual dysfunction',
  'F14.282': 'Cocaine dependence with cocaine-induced sleep disorder',
  'F14.288': 'Cocaine dependence with other cocaine-induced disorder',
  'F14.29': 'Cocaine dependence with unspecified cocaine-induced disorder',
  'F14.90': 'Cocaine use, unspecified, uncomplicated',
  'F14.920': 'Cocaine use, unspecified with intoxication, uncomplicated',
  'F14.921': 'Cocaine use, unspecified with intoxication delirium',
  'F14.922': 'Cocaine use, unspecified with intoxication with perceptual disturbance',
  'F14.929': 'Cocaine use, unspecified with intoxication, unspecified',
  'F14.93': 'Cocaine use, unspecified with withdrawal',
  'F14.94': 'Cocaine use, unspecified with cocaine-induced mood disorder',
  'F14.950': 'Cocaine use, unspecified with cocaine-induced psychotic disorder with delusions',
  'F14.951': 'Cocaine use, unspecified with cocaine-induced psychotic disorder with hallucinations',
  'F14.959': 'Cocaine use, unspecified with cocaine-induced psychotic disorder, unspecified',
  'F14.980': 'Cocaine use, unspecified with cocaine-induced anxiety disorder',
  'F14.981': 'Cocaine use, unspecified with cocaine-induced sexual dysfunction',
  'F14.982': 'Cocaine use, unspecified with cocaine-induced sleep disorder',
  'F14.988': 'Cocaine use, unspecified with other cocaine-induced disorder',
  'F14.99': 'Cocaine use, unspecified with unspecified cocaine-induced disorder',
  'F15.10': 'Other stimulant abuse, uncomplicated',
  'F15.11': 'Other stimulant abuse, in remission',
  'F15.120': 'Other stimulant abuse with intoxication, uncomplicated',
  'F15.121': 'Other stimulant abuse with intoxication delirium',
  'F15.122': 'Other stimulant abuse with intoxication with perceptual disturbance',
  'F15.129': 'Other stimulant abuse with intoxication, unspecified',
  'F15.13': 'Other stimulant abuse with withdrawal',
  'F15.14': 'Other stimulant abuse with stimulant-induced mood disorder',
  'F15.150': 'Other stimulant abuse with stimulant-induced psychotic disorder with delusions',
  'F15.151': 'Other stimulant abuse with stimulant-induced psychotic disorder with hallucinations',
  'F15.159': 'Other stimulant abuse with stimulant-induced psychotic disorder, unspecified',
  'F15.180': 'Other stimulant abuse with stimulant-induced anxiety disorder',
  'F15.181': 'Other stimulant abuse with stimulant-induced sexual dysfunction',
  'F15.182': 'Other stimulant abuse with stimulant-induced sleep disorder',
  'F15.188': 'Other stimulant abuse with other stimulant-induced disorder',
  'F15.19': 'Other stimulant abuse with unspecified stimulant-induced disorder',
  'F15.20': 'Other stimulant dependence, uncomplicated',
  'F15.21': 'Other stimulant dependence, in remission',
  'F15.220': 'Other stimulant dependence with intoxication, uncomplicated',
  'F15.221': 'Other stimulant dependence with intoxication delirium',
  'F15.222': 'Other stimulant dependence with intoxication with perceptual disturbance',
  'F15.229': 'Other stimulant dependence with intoxication, unspecified',
  'F15.23': 'Other stimulant dependence with withdrawal',
  'F15.24': 'Other stimulant dependence with stimulant-induced mood disorder',
  'F15.250': 'Other stimulant dependence with stimulant-induced psychotic disorder with delusions',
  'F15.251':
    'Other stimulant dependence with stimulant-induced psychotic disorder with hallucinations',
  'F15.259': 'Other stimulant dependence with stimulant-induced psychotic disorder, unspecified',
  'F15.280': 'Other stimulant dependence with stimulant-induced anxiety disorder',
  'F15.281': 'Other stimulant dependence with stimulant-induced sexual dysfunction',
  'F15.282': 'Other stimulant dependence with stimulant-induced sleep disorder',
  'F15.288': 'Other stimulant dependence with other stimulant-induced disorder',
  'F15.29': 'Other stimulant dependence with unspecified stimulant-induced disorder',
  'F15.90': 'Other stimulant use, unspecified, uncomplicated',
  'F15.920': 'Other stimulant use, unspecified with intoxication, uncomplicated',
  'F15.921': 'Other stimulant use, unspecified with intoxication delirium',
  'F15.922': 'Other stimulant use, unspecified with intoxication with perceptual disturbance',
  'F15.929': 'Other stimulant use, unspecified with intoxication, unspecified',
  'F15.93': 'Other stimulant use, unspecified with withdrawal',
  'F15.94': 'Other stimulant use, unspecified with stimulant-induced mood disorder',
  'F15.950':
    'Other stimulant use, unspecified with stimulant-induced psychotic disorder with delusions',
  'F15.951':
    'Other stimulant use, unspecified with stimulant-induced psychotic disorder with hallucinations',
  'F15.959':
    'Other stimulant use, unspecified with stimulant-induced psychotic disorder, unspecified',
  'F15.980': 'Other stimulant use, unspecified with stimulant-induced anxiety disorder',
  'F15.981': 'Other stimulant use, unspecified with stimulant-induced sexual dysfunction',
  'F15.982': 'Other stimulant use, unspecified with stimulant-induced sleep disorder',
  'F15.988': 'Other stimulant use, unspecified with other stimulant-induced disorder',
  'F15.99': 'Other stimulant use, unspecified with unspecified stimulant-induced disorder',
  'F16.10': 'Hallucinogen abuse, uncomplicated',
  'F16.11': 'Hallucinogen abuse, in remission',
  'F16.120': 'Hallucinogen abuse with intoxication, uncomplicated',
  'F16.121': 'Hallucinogen abuse with intoxication with delirium',
  'F16.122': 'Hallucinogen abuse with intoxication with perceptual disturbance',
  'F16.129': 'Hallucinogen abuse with intoxication, unspecified',
  'F16.14': 'Hallucinogen abuse with hallucinogen-induced mood disorder',
  'F16.150': 'Hallucinogen abuse with hallucinogen-induced psychotic disorder with delusions',
  'F16.151': 'Hallucinogen abuse with hallucinogen-induced psychotic disorder with hallucinations',
  'F16.159': 'Hallucinogen abuse with hallucinogen-induced psychotic disorder, unspecified',
  'F16.180': 'Hallucinogen abuse with hallucinogen-induced anxiety disorder',
  'F16.183': 'Hallucinogen abuse with hallucinogen persisting perception disorder (flashbacks)',
  'F16.188': 'Hallucinogen abuse with other hallucinogen-induced disorder',
  'F16.19': 'Hallucinogen abuse with unspecified hallucinogen-induced disorder',
  'F16.20': 'Hallucinogen dependence, uncomplicated',
  'F16.21': 'Hallucinogen dependence, in remission',
  'F16.220': 'Hallucinogen dependence with intoxication, uncomplicated',
  'F16.221': 'Hallucinogen dependence with intoxication with delirium',
  'F16.229': 'Hallucinogen dependence with intoxication, unspecified',
  'F16.24': 'Hallucinogen dependence with hallucinogen-induced mood disorder',
  'F16.250': 'Hallucinogen dependence with hallucinogen-induced psychotic disorder with delusions',
  'F16.251':
    'Hallucinogen dependence with hallucinogen-induced psychotic disorder with hallucinations',
  'F16.259': 'Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified',
  'F16.280': 'Hallucinogen dependence with hallucinogen-induced anxiety disorder',
  'F16.283':
    'Hallucinogen dependence with hallucinogen persisting perception disorder (flashbacks)',
  'F16.288': 'Hallucinogen dependence with other hallucinogen-induced disorder',
  'F16.29': 'Hallucinogen dependence with unspecified hallucinogen-induced disorder',
  'F16.90': 'Hallucinogen use, unspecified, uncomplicated',
  'F16.920': 'Hallucinogen use, unspecified with intoxication, uncomplicated',
  'F16.921': 'Hallucinogen use, unspecified with intoxication with delirium',
  'F16.929': 'Hallucinogen use, unspecified with intoxication, unspecified',
  'F16.94': 'Hallucinogen use, unspecified with hallucinogen-induced mood disorder',
  'F16.950':
    'Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with delusions',
  'F16.951':
    'Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder with hallucinations',
  'F16.959':
    'Hallucinogen use, unspecified with hallucinogen-induced psychotic disorder, unspecified',
  'F16.980': 'Hallucinogen use, unspecified with hallucinogen-induced anxiety disorder',
  'F16.983':
    'Hallucinogen use, unspecified with hallucinogen persisting perception disorder (flashbacks)',
  'F16.988': 'Hallucinogen use, unspecified with other hallucinogen-induced disorder',
  'F16.99': 'Hallucinogen use, unspecified with unspecified hallucinogen-induced disorder',
  'F17.200': 'Nicotine dependence, unspecified, uncomplicated',
  'F17.201': 'Nicotine dependence, unspecified, in remission',
  'F17.203': 'Nicotine dependence unspecified, with withdrawal',
  'F17.208': 'Nicotine dependence, unspecified, with other nicotine-induced disorders',
  'F17.209': 'Nicotine dependence, unspecified, with unspecified nicotine-induced disorders',
  'F17.210': 'Nicotine dependence, cigarettes, uncomplicated',
  'F17.211': 'Nicotine dependence, cigarettes, in remission',
  'F17.213': 'Nicotine dependence, cigarettes, with withdrawal',
  'F17.218': 'Nicotine dependence, cigarettes, with other nicotine-induced disorders',
  'F17.219': 'Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders',
  'F17.220': 'Nicotine dependence, chewing tobacco, uncomplicated',
  'F17.221': 'Nicotine dependence, chewing tobacco, in remission',
  'F17.223': 'Nicotine dependence, chewing tobacco, with withdrawal',
  'F17.228': 'Nicotine dependence, chewing tobacco, with other nicotine-induced disorders',
  'F17.229': 'Nicotine dependence, chewing tobacco, with unspecified nicotine-induced disorders',
  'F17.290': 'Nicotine dependence, other tobacco product, uncomplicated',
  'F17.291': 'Nicotine dependence, other tobacco product, in remission',
  'F17.293': 'Nicotine dependence, other tobacco product, with withdrawal',
  'F17.298': 'Nicotine dependence, other tobacco product, with other nicotine-induced disorders',
  'F17.299':
    'Nicotine dependence, other tobacco product, with unspecified nicotine-induced disorders',
  'F18.10': 'Inhalant abuse, uncomplicated',
  'F18.11': 'Inhalant abuse, in remission',
  'F18.120': 'Inhalant abuse with intoxication, uncomplicated',
  'F18.121': 'Inhalant abuse with intoxication delirium',
  'F18.129': 'Inhalant abuse with intoxication, unspecified',
  'F18.14': 'Inhalant abuse with inhalant-induced mood disorder',
  'F18.150': 'Inhalant abuse with inhalant-induced psychotic disorder with delusions',
  'F18.151': 'Inhalant abuse with inhalant-induced psychotic disorder with hallucinations',
  'F18.159': 'Inhalant abuse with inhalant-induced psychotic disorder, unspecified',
  'F18.17': 'Inhalant abuse with inhalant-induced dementia',
  'F18.180': 'Inhalant abuse with inhalant-induced anxiety disorder',
  'F18.188': 'Inhalant abuse with other inhalant-induced disorder',
  'F18.19': 'Inhalant abuse with unspecified inhalant-induced disorder',
  'F18.20': 'Inhalant dependence, uncomplicated',
  'F18.21': 'Inhalant dependence, in remission',
  'F18.220': 'Inhalant dependence with intoxication, uncomplicated',
  'F18.221': 'Inhalant dependence with intoxication delirium',
  'F18.229': 'Inhalant dependence with intoxication, unspecified',
  'F18.24': 'Inhalant dependence with inhalant-induced mood disorder',
  'F18.250': 'Inhalant dependence with inhalant-induced psychotic disorder with delusions',
  'F18.251': 'Inhalant dependence with inhalant-induced psychotic disorder with hallucinations',
  'F18.259': 'Inhalant dependence with inhalant-induced psychotic disorder, unspecified',
  'F18.27': 'Inhalant dependence with inhalant-induced dementia',
  'F18.280': 'Inhalant dependence with inhalant-induced anxiety disorder',
  'F18.288': 'Inhalant dependence with other inhalant-induced disorder',
  'F18.29': 'Inhalant dependence with unspecified inhalant-induced disorder',
  'F18.90': 'Inhalant use, unspecified, uncomplicated',
  'F18.920': 'Inhalant use, unspecified with intoxication, uncomplicated',
  'F18.921': 'Inhalant use, unspecified with intoxication with delirium',
  'F18.929': 'Inhalant use, unspecified with intoxication, unspecified',
  'F18.94': 'Inhalant use, unspecified with inhalant-induced mood disorder',
  'F18.950': 'Inhalant use, unspecified with inhalant-induced psychotic disorder with delusions',
  'F18.951':
    'Inhalant use, unspecified with inhalant-induced psychotic disorder with hallucinations',
  'F18.959': 'Inhalant use, unspecified with inhalant-induced psychotic disorder, unspecified',
  'F18.97': 'Inhalant use, unspecified with inhalant-induced persisting dementia',
  'F18.980': 'Inhalant use, unspecified with inhalant-induced anxiety disorder',
  'F18.988': 'Inhalant use, unspecified with other inhalant-induced disorder',
  'F18.99': 'Inhalant use, unspecified with unspecified inhalant-induced disorder',
  'F19.10': 'Other psychoactive substance abuse, uncomplicated',
  'F19.11': 'Other psychoactive substance abuse, in remission',
  'F19.120': 'Other psychoactive substance abuse with intoxication, uncomplicated',
  'F19.121': 'Other psychoactive substance abuse with intoxication delirium',
  'F19.122': 'Other psychoactive substance abuse with intoxication with perceptual disturbances',
  'F19.129': 'Other psychoactive substance abuse with intoxication, unspecified',
  'F19.130': 'Other psychoactive substance abuse with withdrawal, uncomplicated',
  'F19.131': 'Other psychoactive substance abuse with withdrawal delirium',
  'F19.132': 'Other psychoactive substance abuse with withdrawal with perceptual disturbance',
  'F19.139': 'Other psychoactive substance abuse with withdrawal, unspecified',
  'F19.14': 'Other psychoactive substance abuse with psychoactive substance-induced mood disorder',
  'F19.150':
    'Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with delusions',
  'F19.151':
    'Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder with hallucinations',
  'F19.159':
    'Other psychoactive substance abuse with psychoactive substance-induced psychotic disorder, unspecified',
  'F19.16':
    'Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder',
  'F19.17':
    'Other psychoactive substance abuse with psychoactive substance-induced persisting dementia',
  'F19.180':
    'Other psychoactive substance abuse with psychoactive substance-induced anxiety disorder',
  'F19.181':
    'Other psychoactive substance abuse with psychoactive substance-induced sexual dysfunction',
  'F19.182':
    'Other psychoactive substance abuse with psychoactive substance-induced sleep disorder',
  'F19.188':
    'Other psychoactive substance abuse with other psychoactive substance-induced disorder',
  'F19.19':
    'Other psychoactive substance abuse with unspecified psychoactive substance-induced disorder',
  'F19.20': 'Other psychoactive substance dependence, uncomplicated',
  'F19.21': 'Other psychoactive substance dependence, in remission',
  'F19.220': 'Other psychoactive substance dependence with intoxication, uncomplicated',
  'F19.221': 'Other psychoactive substance dependence with intoxication delirium',
  'F19.222':
    'Other psychoactive substance dependence with intoxication with perceptual disturbance',
  'F19.229': 'Other psychoactive substance dependence with intoxication, unspecified',
  'F19.230': 'Other psychoactive substance dependence with withdrawal, uncomplicated',
  'F19.231': 'Other psychoactive substance dependence with withdrawal delirium',
  'F19.232': 'Other psychoactive substance dependence with withdrawal with perceptual disturbance',
  'F19.239': 'Other psychoactive substance dependence with withdrawal, unspecified',
  'F19.24':
    'Other psychoactive substance dependence with psychoactive substance-induced mood disorder',
  'F19.250':
    'Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with delusions',
  'F19.251':
    'Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder with hallucinations',
  'F19.259':
    'Other psychoactive substance dependence with psychoactive substance-induced psychotic disorder, unspecified',
  'F19.26':
    'Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder',
  'F19.27':
    'Other psychoactive substance dependence with psychoactive substance-induced persisting dementia',
  'F19.280':
    'Other psychoactive substance dependence with psychoactive substance-induced anxiety disorder',
  'F19.281':
    'Other psychoactive substance dependence with psychoactive substance-induced sexual dysfunction',
  'F19.282':
    'Other psychoactive substance dependence with psychoactive substance-induced sleep disorder',
  'F19.288':
    'Other psychoactive substance dependence with other psychoactive substance-induced disorder',
  'F19.29':
    'Other psychoactive substance dependence with unspecified psychoactive substance-induced disorder',
  'F19.90': 'Other psychoactive substance use, unspecified, uncomplicated',
  'F19.920': 'Other psychoactive substance use, unspecified with intoxication, uncomplicated',
  'F19.921': 'Other psychoactive substance use, unspecified with intoxication with delirium',
  'F19.922':
    'Other psychoactive substance use, unspecified with intoxication with perceptual disturbance',
  'F19.929': 'Other psychoactive substance use, unspecified with intoxication, unspecified',
  'F19.930': 'Other psychoactive substance use, unspecified with withdrawal, uncomplicated',
  'F19.931': 'Other psychoactive substance use, unspecified with withdrawal delirium',
  'F19.932':
    'Other psychoactive substance use, unspecified with withdrawal with perceptual disturbance',
  'F19.939': 'Other psychoactive substance use, unspecified with withdrawal, unspecified',
  'F19.94':
    'Other psychoactive substance use, unspecified with psychoactive substance-induced mood disorder',
  'F19.950':
    'Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with delusions',
  'F19.951':
    'Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder with hallucinations',
  'F19.959':
    'Other psychoactive substance use, unspecified with psychoactive substance-induced psychotic disorder, unspecified',
  'F19.96':
    'Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder',
  'F19.97':
    'Other psychoactive substance use, unspecified with psychoactive substance-induced persisting dementia',
  'F19.980':
    'Other psychoactive substance use, unspecified with psychoactive substance-induced anxiety disorder',
  'F19.981':
    'Other psychoactive substance use, unspecified with psychoactive substance-induced sexual dysfunction',
  'F19.982':
    'Other psychoactive substance use, unspecified with psychoactive substance-induced sleep disorder',
  'F19.988':
    'Other psychoactive substance use, unspecified with other psychoactive substance-induced disorder',
  'F19.99':
    'Other psychoactive substance use, unspecified with unspecified psychoactive substance-induced disorder',
  'F20.0': 'Paranoid schizophrenia',
  'F20.1': 'Disorganized schizophrenia',
  'F20.2': 'Catatonic schizophrenia',
  'F20.3': 'Undifferentiated schizophrenia',
  'F20.5': 'Residual schizophrenia',
  'F20.81': 'Schizophreniform disorder',
  'F20.89': 'Other schizophrenia',
  'F20.9': 'Schizophrenia, unspecified',
  F21: 'Schizotypal disorder',
  F22: 'Delusional disorders',
  F23: 'Brief psychotic disorder',
  F24: 'Shared psychotic disorder',
  'F25.0': 'Schizoaffective disorder, bipolar type',
  'F25.1': 'Schizoaffective disorder, depressive type',
  'F25.8': 'Other schizoaffective disorders',
  'F25.9': 'Schizoaffective disorder, unspecified',
  F28: 'Other psychotic disorder not due to a substance or known physiological condition',
  F29: 'Unspecified psychosis not due to a substance or known physiological condition',
  'F30.10': 'Manic episode without psychotic symptoms, unspecified',
  'F30.11': 'Manic episode without psychotic symptoms, mild',
  'F30.12': 'Manic episode without psychotic symptoms, moderate',
  'F30.13': 'Manic episode, severe, without psychotic symptoms',
  'F30.2': 'Manic episode, severe with psychotic symptoms',
  'F30.3': 'Manic episode in partial remission',
  'F30.4': 'Manic episode in full remission',
  'F30.8': 'Other manic episodes',
  'F30.9': 'Manic episode, unspecified',
  'F31.0': 'Bipolar disorder, current episode hypomanic',
  'F31.10': 'Bipolar disorder, current episode manic without psychotic features, unspecified',
  'F31.11': 'Bipolar disorder, current episode manic without psychotic features, mild',
  'F31.12': 'Bipolar disorder, current episode manic without psychotic features, moderate',
  'F31.13': 'Bipolar disorder, current episode manic without psychotic features, severe',
  'F31.2': 'Bipolar disorder, current episode manic severe with psychotic features',
  'F31.30': 'Bipolar disorder, current episode depressed, mild or moderate severity, unspecified',
  'F31.31': 'Bipolar disorder, current episode depressed, mild',
  'F31.32': 'Bipolar disorder, current episode depressed, moderate',
  'F31.4': 'Bipolar disorder, current episode depressed, severe, without psychotic features',
  'F31.5': 'Bipolar disorder, current episode depressed, severe, with psychotic features',
  'F31.60': 'Bipolar disorder, current episode mixed, unspecified',
  'F31.61': 'Bipolar disorder, current episode mixed, mild',
  'F31.62': 'Bipolar disorder, current episode mixed, moderate',
  'F31.63': 'Bipolar disorder, current episode mixed, severe, without psychotic features',
  'F31.64': 'Bipolar disorder, current episode mixed, severe, with psychotic features',
  'F31.70': 'Bipolar disorder, currently in remission, most recent episode unspecified',
  'F31.71': 'Bipolar disorder, in partial remission, most recent episode hypomanic',
  'F31.72': 'Bipolar disorder, in full remission, most recent episode hypomanic',
  'F31.73': 'Bipolar disorder, in partial remission, most recent episode manic',
  'F31.74': 'Bipolar disorder, in full remission, most recent episode manic',
  'F31.75': 'Bipolar disorder, in partial remission, most recent episode depressed',
  'F31.76': 'Bipolar disorder, in full remission, most recent episode depressed',
  'F31.77': 'Bipolar disorder, in partial remission, most recent episode mixed',
  'F31.78': 'Bipolar disorder, in full remission, most recent episode mixed',
  'F31.81': 'Bipolar II disorder',
  'F31.89': 'Other bipolar disorder',
  'F31.9': 'Bipolar disorder, unspecified',
  'F32.0': 'Major depressive disorder, single episode, mild',
  'F32.1': 'Major depressive disorder, single episode, moderate',
  'F32.2': 'Major depressive disorder, single episode, severe without psychotic features',
  'F32.3': 'Major depressive disorder, single episode, severe with psychotic features',
  'F32.4': 'Major depressive disorder, single episode, in partial remission',
  'F32.5': 'Major depressive disorder, single episode, in full remission',
  'F32.81': 'Premenstrual dysphoric disorder',
  'F32.89': 'Other specified depressive episodes',
  'F32.9': 'Major depressive disorder, single episode, unspecified',
  'F33.0': 'Major depressive disorder, recurrent, mild',
  'F33.1': 'Major depressive disorder, recurrent, moderate',
  'F33.2': 'Major depressive disorder, recurrent severe without psychotic features',
  'F33.3': 'Major depressive disorder, recurrent, severe with psychotic symptoms',
  'F33.40': 'Major depressive disorder, recurrent, in remission, unspecified',
  'F33.41': 'Major depressive disorder, recurrent, in partial remission',
  'F33.42': 'Major depressive disorder, recurrent, in full remission',
  'F33.8': 'Other recurrent depressive disorders',
  'F33.9': 'Major depressive disorder, recurrent, unspecified',
  'F34.0': 'Cyclothymic disorder',
  'F34.1': 'Dysthymic disorder',
  'F34.81': 'Disruptive mood dysregulation disorder',
  'F34.89': 'Other specified persistent mood disorders',
  'F34.9': 'Persistent mood [affective] disorder, unspecified',
  F39: 'Unspecified mood [affective] disorder',
  'F40.00': 'Agoraphobia, unspecified',
  'F40.01': 'Agoraphobia with panic disorder',
  'F40.02': 'Agoraphobia without panic disorder',
  'F40.10': 'Social phobia, unspecified',
  'F40.11': 'Social phobia, generalized',
  'F40.210': 'Arachnophobia',
  'F40.218': 'Other animal type phobia',
  'F40.220': 'Fear of thunderstorms',
  'F40.228': 'Other natural environment type phobia',
  'F40.230': 'Fear of blood',
  'F40.231': 'Fear of injections and transfusions',
  'F40.232': 'Fear of other medical care',
  'F40.233': 'Fear of injury',
  'F40.240': 'Claustrophobia',
  'F40.241': 'Acrophobia',
  'F40.242': 'Fear of bridges',
  'F40.243': 'Fear of flying',
  'F40.248': 'Other situational type phobia',
  'F40.290': 'Androphobia',
  'F40.291': 'Gynephobia',
  'F40.298': 'Other specified phobia',
  'F40.8': 'Other phobic anxiety disorders',
  'F40.9': 'Phobic anxiety disorder, unspecified',
  'F41.0': 'Panic disorder [episodic paroxysmal anxiety]',
  'F41.1': 'Generalized anxiety disorder',
  'F41.3': 'Other mixed anxiety disorders',
  'F41.8': 'Other specified anxiety disorders',
  'F41.9': 'Anxiety disorder, unspecified',
  'F42.2': 'Mixed obsessional thoughts and acts',
  'F42.3': 'Hoarding disorder',
  'F42.4': 'Excoriation (skin-picking) disorder',
  'F42.8': 'Other obsessive-compulsive disorder',
  'F42.9': 'Obsessive-compulsive disorder, unspecified',
  'F43.0': 'Acute stress reaction',
  'F43.10': 'Post-traumatic stress disorder, unspecified',
  'F43.11': 'Post-traumatic stress disorder, acute',
  'F43.12': 'Post-traumatic stress disorder, chronic',
  'F43.20': 'Adjustment disorder, unspecified',
  'F43.21': 'Adjustment disorder with depressed mood',
  'F43.22': 'Adjustment disorder with anxiety',
  'F43.23': 'Adjustment disorder with mixed anxiety and depressed mood',
  'F43.24': 'Adjustment disorder with disturbance of conduct',
  'F43.25': 'Adjustment disorder with mixed disturbance of emotions and conduct',
  'F43.29': 'Adjustment disorder with other symptoms',
  'F43.8': 'Other reactions to severe stress',
  'F43.9': 'Reaction to severe stress, unspecified',
  'F44.0': 'Dissociative amnesia',
  'F44.1': 'Dissociative fugue',
  'F44.2': 'Dissociative stupor',
  'F44.4': 'Conversion disorder with motor symptom or deficit',
  'F44.5': 'Conversion disorder with seizures or convulsions',
  'F44.6': 'Conversion disorder with sensory symptom or deficit',
  'F44.7': 'Conversion disorder with mixed symptom presentation',
  'F44.81': 'Dissociative identity disorder',
  'F44.89': 'Other dissociative and conversion disorders',
  'F44.9': 'Dissociative and conversion disorder, unspecified',
  'F45.0': 'Somatization disorder',
  'F45.1': 'Undifferentiated somatoform disorder',
  'F45.20': 'Hypochondriacal disorder, unspecified',
  'F45.21': 'Hypochondriasis',
  'F45.22': 'Body dysmorphic disorder',
  'F45.29': 'Other hypochondriacal disorders',
  'F45.41': 'Pain disorder exclusively related to psychological factors',
  'F45.42': 'Pain disorder with related psychological factors',
  'F45.8': 'Other somatoform disorders',
  'F45.9': 'Somatoform disorder, unspecified',
  'F48.1': 'Depersonalization-derealization syndrome',
  'F48.2': 'Pseudobulbar affect',
  'F48.8': 'Other specified nonpsychotic mental disorders',
  'F48.9': 'Nonpsychotic mental disorder, unspecified',
  'F50.00': 'Anorexia nervosa, unspecified',
  'F50.01': 'Anorexia nervosa, restricting type',
  'F50.02': 'Anorexia nervosa, binge eating/purging type',
  'F50.2': 'Bulimia nervosa',
  'F50.81': 'Binge eating disorder',
  'F50.82': 'Avoidant/restrictive food intake disorder',
  'F50.89': 'Other specified eating disorder',
  'F50.9': 'Eating disorder, unspecified',
  'F51.01': 'Primary insomnia',
  'F51.02': 'Adjustment insomnia',
  'F51.03': 'Paradoxical insomnia',
  'F51.04': 'Psychophysiologic insomnia',
  'F51.05': 'Insomnia due to other mental disorder',
  'F51.09': 'Other insomnia not due to a substance or known physiological condition',
  'F51.11': 'Primary hypersomnia',
  'F51.12': 'Insufficient sleep syndrome',
  'F51.13': 'Hypersomnia due to other mental disorder',
  'F51.19': 'Other hypersomnia not due to a substance or known physiological condition',
  'F51.3': 'Sleepwalking [somnambulism]',
  'F51.4': 'Sleep terrors [night terrors]',
  'F51.5': 'Nightmare disorder',
  'F51.8': 'Other sleep disorders not due to a substance or known physiological condition',
  'F51.9': 'Sleep disorder not due to a substance or known physiological condition, unspecified',
  'F52.0': 'Hypoactive sexual desire disorder',
  'F52.1': 'Sexual aversion disorder',
  'F52.21': 'Male erectile disorder',
  'F52.22': 'Female sexual arousal disorder',
  'F52.31': 'Female orgasmic disorder',
  'F52.32': 'Male orgasmic disorder',
  'F52.4': 'Premature ejaculation',
  'F52.5': 'Vaginismus not due to a substance or known physiological condition',
  'F52.6': 'Dyspareunia not due to a substance or known physiological condition',
  'F52.8': 'Other sexual dysfunction not due to a substance or known physiological condition',
  'F52.9': 'Unspecified sexual dysfunction not due to a substance or known physiological condition',
  'F53.0': 'Postpartum depression',
  'F53.1': 'Puerperal psychosis',
  F54: 'Psychological and behavioral factors associated with disorders or diseases classified elsewhere',
  'F55.0': 'Abuse of antacids',
  'F55.1': 'Abuse of herbal or folk remedies',
  'F55.2': 'Abuse of laxatives',
  'F55.3': 'Abuse of steroids or hormones',
  'F55.4': 'Abuse of vitamins',
  'F55.8': 'Abuse of other non-psychoactive substances',
  F59: 'Unspecified behavioral syndromes associated with physiological disturbances and physical factors',
  'F60.0': 'Paranoid personality disorder',
  'F60.1': 'Schizoid personality disorder',
  'F60.2': 'Antisocial personality disorder',
  'F60.3': 'Borderline personality disorder',
  'F60.4': 'Histrionic personality disorder',
  'F60.5': 'Obsessive-compulsive personality disorder',
  'F60.6': 'Avoidant personality disorder',
  'F60.7': 'Dependent personality disorder',
  'F60.81': 'Narcissistic personality disorder',
  'F60.89': 'Other specific personality disorders',
  'F60.9': 'Personality disorder, unspecified',
  'F63.0': 'Pathological gambling',
  'F63.1': 'Pyromania',
  'F63.2': 'Kleptomania',
  'F63.3': 'Trichotillomania',
  'F63.81': 'Intermittent explosive disorder',
  'F63.89': 'Other impulse disorders',
  'F63.9': 'Impulse disorder, unspecified',
  'F64.0': 'Transsexualism',
  'F64.1': 'Dual role transvestism',
  'F64.2': 'Gender identity disorder of childhood',
  'F64.8': 'Other gender identity disorders',
  'F64.9': 'Gender identity disorder, unspecified',
  'F65.0': 'Fetishism',
  'F65.1': 'Transvestic fetishism',
  'F65.2': 'Exhibitionism',
  'F65.3': 'Voyeurism',
  'F65.4': 'Pedophilia',
  'F65.50': 'Sadomasochism, unspecified',
  'F65.51': 'Sexual masochism',
  'F65.52': 'Sexual sadism',
  'F65.81': 'Frotteurism',
  'F65.89': 'Other paraphilias',
  'F65.9': 'Paraphilia, unspecified',
  F66: 'Other sexual disorders',
  'F68.10': 'Factitious disorder imposed on self, unspecified',
  'F68.11':
    'Factitious disorder imposed on self, with predominantly psychological signs and symptoms',
  'F68.12': 'Factitious disorder imposed on self, with predominantly physical signs and symptoms',
  'F68.13':
    'Factitious disorder imposed on self, with combined psychological and physical signs and symptoms',
  'F68.A': 'Factitious disorder imposed on another',
  'F68.8': 'Other specified disorders of adult personality and behavior',
  F69: 'Unspecified disorder of adult personality and behavior',
  F70: 'Mild intellectual disabilities',
  F71: 'Moderate intellectual disabilities',
  F72: 'Severe intellectual disabilities',
  F73: 'Profound intellectual disabilities',
  F78: 'Other intellectual disabilities',
  F79: 'Unspecified intellectual disabilities',
  'F80.0': 'Phonological disorder',
  'F80.1': 'Expressive language disorder',
  'F80.2': 'Mixed receptive-expressive language disorder',
  'F80.4': 'Speech and language development delay due to hearing loss',
  'F80.81': 'Childhood onset fluency disorder',
  'F80.82': 'Social pragmatic communication disorder',
  'F80.89': 'Other developmental disorders of speech and language',
  'F80.9': 'Developmental disorder of speech and language, unspecified',
  'F81.0': 'Specific reading disorder',
  'F81.2': 'Mathematics disorder',
  'F81.81': 'Disorder of written expression',
  'F81.89': 'Other developmental disorders of scholastic skills',
  'F81.9': 'Developmental disorder of scholastic skills, unspecified',
  F82: 'Specific developmental disorder of motor function',
  'F84.0': 'Autistic disorder',
  'F84.2': "Rett's syndrome",
  'F84.3': 'Other childhood disintegrative disorder',
  'F84.5': "Asperger's syndrome",
  'F84.8': 'Other pervasive developmental disorders',
  'F84.9': 'Pervasive developmental disorder, unspecified',
  F88: 'Other disorders of psychological development',
  F89: 'Unspecified disorder of psychological development',
  'F90.0': 'Attention-deficit hyperactivity disorder, predominantly inattentive type',
  'F90.1': 'Attention-deficit hyperactivity disorder, predominantly hyperactive type',
  'F90.2': 'Attention-deficit hyperactivity disorder, combined type',
  'F90.8': 'Attention-deficit hyperactivity disorder, other type',
  'F90.9': 'Attention-deficit hyperactivity disorder, unspecified type',
  'F91.0': 'Conduct disorder confined to family context',
  'F91.1': 'Conduct disorder, childhood-onset type',
  'F91.2': 'Conduct disorder, adolescent-onset type',
  'F91.3': 'Oppositional defiant disorder',
  'F91.8': 'Other conduct disorders',
  'F91.9': 'Conduct disorder, unspecified',
  'F93.0': 'Separation anxiety disorder of childhood',
  'F93.8': 'Other childhood emotional disorders',
  'F93.9': 'Childhood emotional disorder, unspecified',
  'F94.0': 'Selective mutism',
  'F94.1': 'Reactive attachment disorder of childhood',
  'F94.2': 'Disinhibited attachment disorder of childhood',
  'F94.8': 'Other childhood disorders of social functioning',
  'F94.9': 'Childhood disorder of social functioning, unspecified',
  'F95.0': 'Transient tic disorder',
  'F95.1': 'Chronic motor or vocal tic disorder',
  'F95.2': "Tourette's disorder",
  'F95.8': 'Other tic disorders',
  'F95.9': 'Tic disorder, unspecified',
  'F98.0': 'Enuresis not due to a substance or known physiological condition',
  'F98.1': 'Encopresis not due to a substance or known physiological condition',
  'F98.21': 'Rumination disorder of infancy',
  'F98.29': 'Other feeding disorders of infancy and early childhood',
  'F98.3': 'Pica of infancy and childhood',
  'F98.4': 'Stereotyped movement disorders',
  'F98.5': 'Adult onset fluency disorder',
  'F98.8':
    'Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence',
  'F98.9':
    'Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolescence',
  F99: 'Mental disorder, not otherwise specified',
  'G47.00': 'Insomnia, unspecified',
  'G47.01': 'Insomnia due to medical condition',
  'G47.09': 'Other insomnia',
  'G47.10': 'Hypersomnia, unspecified',
  'G47.11': 'Idiopathic hypersomnia with long sleep time',
  'G47.12': 'Idiopathic hypersomnia without long sleep time',
  'G47.13': 'Recurrent hypersomnia',
  'G47.14': 'Hypersomnia due to medical condition',
  'G47.19': 'Other hypersomnia',
  'G47.20': 'Circadian rhythm sleep disorder, unspecified type',
  'G47.21': 'Circadian rhythm sleep disorder, delayed sleep phase type',
  'G47.22': 'Circadian rhythm sleep disorder, advanced sleep phase type',
  'G47.23': 'Circadian rhythm sleep disorder, irregular sleep wake type',
  'G47.24': 'Circadian rhythm sleep disorder, free running type',
  'G47.25': 'Circadian rhythm sleep disorder, jet lag type',
  'G47.26': 'Circadian rhythm sleep disorder, shift work type',
  'G47.27': 'Circadian rhythm sleep disorder in conditions classified elsewhere',
  'G47.29': 'Other circadian rhythm sleep disorder',
  'G47.30': 'Sleep apnea, unspecified',
  'G47.31': 'Primary central sleep apnea',
  'G47.32': 'High altitude periodic breathing',
  'G47.33': 'Obstructive sleep apnea (adult) (pediatric)',
  'G47.34': 'Idiopathic sleep related nonobstructive alveolar hypoventilation',
  'G47.35': 'Congenital central alveolar hypoventilation syndrome',
  'G47.36': 'Sleep related hypoventilation in conditions classified elsewhere',
  'G47.37': 'Central sleep apnea in conditions classified elsewhere',
  'G47.39': 'Other sleep apnea',
  'G47.411': 'Narcolepsy with cataplexy',
  'G47.419': 'Narcolepsy without cataplexy',
  'G47.421': 'Narcolepsy in conditions classified elsewhere with cataplexy',
  'G47.429': 'Narcolepsy in conditions classified elsewhere without cataplexy',
  'G47.50': 'Parasomnia, unspecified',
  'G47.51': 'Confusional arousals',
  'G47.52': 'REM sleep behavior disorder',
  'G47.53': 'Recurrent isolated sleep paralysis',
  'G47.54': 'Parasomnia in conditions classified elsewhere',
  'G47.59': 'Other parasomnia',
  'G47.61': 'Periodic limb movement disorder',
  'G47.62': 'Sleep related leg cramps',
  'G47.63': 'Sleep related bruxism',
  'G47.69': 'Other sleep related movement disorders',
  'G47.8': 'Other sleep disorders',
  'G47.9': 'Sleep disorder, unspecified',
  'G89.29': 'Other chronic pain',
};
