import { NetworkStatus } from '@apollo/client';
import keyBy from 'lodash/keyBy';
import moment, { Moment } from 'moment-timezone';
import React, { useState } from 'react';
import { Calendar } from '../../../Components/Calendar';
import { FinalButton } from '../../../Components/FinalButton';
import { Icon } from '../../../Components/Icons';
import { isMantraAdmin, useCurrentProvider } from '../../../Components/Permissions';
import { Permission, Provider, StateCodes, useAllocationsTabQuery } from '../../../graphQL';
import { TimeZone } from '../../../utils';
import { useProviderDrilldownContext } from '../context';
import { Container } from '../Styles';
import * as util from '../util';
import { SetAvailabilityModal } from './Modal/SetAvailabilityModal';
import { SetProviderStateAvailabilityModal } from './ProviderStateModal/SetProviderStateAvailabilityModal';
import * as Styles from './Styles';
import { WeekSummaryCell } from './WeekSummaryCell/WeekSummaryCell';

const HOUR_COUNT_PRECISION = 2;
const ONE_HR_MIN = 60;

type AvailabilityProps = {
  provider: Pick<Provider, 'id'>;
};

export type UIProviderState = {
  id: number;
  state: StateCodes;
};

export type WeekStateAllocationModalState = {
  date: Moment;
  timezone: TimeZone;
  maxWeekHours: number;
  maxWeekIntakeHours: number;
};

export const AvailabilityTab = () => {
  const { provider } = useProviderDrilldownContext();
  return <Availability provider={provider} />;
};

export function Availability({ provider }: AvailabilityProps) {
  const { data, refetch, loading, networkStatus } = useAllocationsTabQuery({
    variables: { providerId: provider.id },
    notifyOnNetworkStatusChange: true,
  });

  const [activeModalDate, setActiveModalDate] = useState<{
    date: Moment;
    timezone: TimeZone;
  } | null>(null);

  const [activeWeekStateAllocationModalData, setWeekStateAllocationModalData] =
    useState<WeekStateAllocationModalState | null>(null);

  const { hasPermission, currentProvider } = useCurrentProvider();

  const isAdmin = isMantraAdmin(currentProvider);

  const orgsById = data
    ? keyBy(
        data.adminGetAllocations.map(i => i.organization),
        'id'
      )
    : {};

  const allAllocations = data ? data.adminGetAllocations.map(util.toFriendlyAllocation) : [];
  const allStateAllocations = data ? data.adminGetStateAllocations : [];
  const providerStates: UIProviderState[] = data ? data?.adminProvider.providerStates : [];

  const handleSuccess = () => {
    setActiveModalDate(null);
    setWeekStateAllocationModalData(null);
    refetch();
  };

  const formLink = `https://docs.google.com/forms/d/e/1FAIpQLSddXLYjr2f5n-NfHhpsjJuKDSIBPg454pLD9EJxOQ_YX3C17g/viewform`;

  return (
    <Container>
      {!hasPermission(Permission.MantraAdmin) && (
        <div className="w-100 flex justify-end">
          {/* eslint-disable-next-line no-restricted-globals */}
          <FinalButton onClick={() => location.assign(formLink)}>Request Change</FinalButton>
        </div>
      )}
      <Calendar
        loading={loading || networkStatus === NetworkStatus.refetch}
        showLeadingAndTrailingDates
        renderWeekSummary={(week, tz) => {
          const weekAllocations = util.getAllocationsForWeek(week, allAllocations);
          const weekStateAllocations = util.getStateAllocationsForWeek(
            week,
            allStateAllocations,
            tz
          );
          return (
            <WeekSummaryCell
              week={week}
              tz={tz}
              weekAllocations={weekAllocations}
              weekStateAllocations={weekStateAllocations}
              geoStates={providerStates.map(ps => ps.state)}
              setWeekModalDate={setWeekStateAllocationModalData}
            />
          );
        }}
        renderCell={(date, tz, isInActiveMonth) => {
          const isPast = moment().tz(tz).startOf('day').isAfter(date);
          const editable = isAdmin || !isPast;
          const dateAllocations = util.getAllocationsForBlock(date, allAllocations);
          return (
            <Styles.AvailabilityCell
              past={isPast}
              editable={editable}
              onClick={() => editable && setActiveModalDate({ date, timezone: tz })}
            >
              <Styles.DayNumber isInActiveMonth={isInActiveMonth}>
                {date.format(`${date.date() === 1 ? 'MMM ' : ''} D`)}
              </Styles.DayNumber>
              <Styles.HoursCounter>
                {dateAllocations.length > 0 && (
                  <>
                    {dateAllocations.some(day => day.weekly) && (
                      <Icon icon="iconsRecurringSvg" alt="Recurring" className="mr1" width={13} />
                    )}
                    {dateAllocations
                      .filter(a => ['intake', 'checkin', 'default', 'admin'].includes(a.type))
                      .reduce(
                        (curr, next) =>
                          curr + next.endTime.diff(next.startTime, 'minutes') / ONE_HR_MIN,
                        0
                      )
                      .toFixed(HOUR_COUNT_PRECISION)}
                    h
                  </>
                )}
              </Styles.HoursCounter>
              <Styles.Times>
                {dateAllocations.map((allocation, idx) => (
                  <div key={allocation.id}>
                    {/* Only show the name if it's different from the previous one, so consecutive
                        allocations for the same org appear to be grouped together. */}
                    {(idx === 0 ||
                      dateAllocations[idx - 1].organizationId !== allocation.organizationId) && (
                      <Styles.OrganizationName spaced={idx > 0}>
                        {allocation.organizationId && (
                          <Icon icon="iconsEducationSvg" className="mr1" />
                        )}
                        {allocation.organizationId
                          ? orgsById[allocation.organizationId]!.name
                          : 'Mantra Health'}
                        {allocation.childOrganizationIds &&
                          allocation.childOrganizationIds.length > 0 &&
                          ` (${allocation.childOrganizationIds.length}/${allocation.childrenCount})`}
                      </Styles.OrganizationName>
                    )}
                    <Styles.Time key={allocation.id} admin={allocation.type === 'admin'}>
                      {formatTime({ ...allocation, timezone: tz })}
                      {allocation.isFeeForServiceTime && <Styles.FFSBox>FFS</Styles.FFSBox>}
                    </Styles.Time>
                  </div>
                ))}
              </Styles.Times>
              {editable && (
                <Styles.EditText>
                  {dateAllocations.length < 1 ? (
                    <FinalButton>Add</FinalButton>
                  ) : (
                    <FinalButton kind="white" style={{ borderColor: 'black' }}>
                      Edit
                    </FinalButton>
                  )}
                </Styles.EditText>
              )}
            </Styles.AvailabilityCell>
          );
        }}
        initialMonth={moment().startOf('day')}
      />
      {activeModalDate && (
        <SetAvailabilityModal
          onCancel={() => setActiveModalDate(null)}
          allocations={allAllocations}
          provider={data!.adminProvider}
          onSuccess={handleSuccess}
          {...activeModalDate}
        />
      )}
      {activeWeekStateAllocationModalData && (
        <SetProviderStateAvailabilityModal
          onCancel={() => setWeekStateAllocationModalData(null)}
          providerStates={providerStates}
          onSuccess={handleSuccess}
          providerStateAllocations={allStateAllocations}
          {...activeWeekStateAllocationModalData}
        />
      )}
    </Container>
  );
}

function formatTime({ startTime, endTime, type, timezone }: util.FriendlyAllocation) {
  const timezonedStart = startTime.clone().tz(timezone);
  const timezonedEnd = endTime.clone().tz(timezone);
  const start = timezonedStart.format('h:mm') + (timezonedStart.hours() < 12 ? 'am' : 'pm');
  const end = timezonedEnd.format('h:mm') + (timezonedEnd.hours() < 12 ? 'am' : 'pm');
  const timespan = `${start} - ${end}`;
  switch (type) {
    case 'admin':
      return `(${timespan})`;
    case 'intake':
      return `[IT] ${timespan}`;
    case 'checkin':
      return `[FU] ${timespan}`;
    case 'timeOff':
      return `[OFF] ${timespan}`;
    default:
      return timespan;
  }
}
