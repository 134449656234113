/* eslint-disable */
/* THIS IS AN AUTO-GENERATED FILE */
/*
    ____            .    _  .
   /# /_\_          |\_|/__/|
  |  |/o\o\        / / \/ \  \
  |  \\_/_/       /__|O||O|__ \
 / |_   |        |/_ \_/\_/ _\ |
|  ||\_ ~|       | | (____) | ||
|  ||| \/        \/\___/\__/  //
|  |||_          (_/         ||
 \//  |           |          ||
  ||  |           |          ||\
  ||_  \           \        //_/
  \_|  o|           \______//
  /\___/          __ || __||
 /  ||||__       (____(____)
    (___)_)
run 'yarn codegen' to create a new one
*/
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  JSON: { [key: string]: any };
  LocalDate: string;
  LooseLocalDate: string;
  UserBirthdate: string;
};

export interface Address {
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<StateCodes>;
  updatedAt: Scalars['DateTime'];
  zip: Scalars['String'];
}

export interface AddressInput {
  city?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<StateCodes>;
  zip?: InputMaybe<Scalars['String']>;
}

export interface AdminAlert {
  careType?: Maybe<CareType>;
  message: Scalars['String'];
  resolveBy?: Maybe<Scalars['String']>;
  type: AdminAlertType;
}

export const AdminAlertType = {
  ConfirmPatientReactivation: 'ConfirmPatientReactivation',
  MissingPharmacy: 'MissingPharmacy',
  ReactivatePatient: 'ReactivatePatient',
  SponsoredCareExpiring: 'SponsoredCareExpiring',
  UnfinishedNote: 'UnfinishedNote',
} as const;

export type AdminAlertType = typeof AdminAlertType[keyof typeof AdminAlertType];
export interface AdminEditUserResult {
  email: Scalars['String'];
  id: Scalars['Int'];
}

export interface Allocation {
  appointmentTypes?: Maybe<Array<Scalars['String']>>;
  childOrganizations?: Maybe<Array<Organization>>;
  endTime: Scalars['DateTime'];
  id: Scalars['Float'];
  isFeeForServiceTime?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  provider: Provider;
  providerId: Scalars['Float'];
  purpose: TimeAllocationPurpose;
  repeatsUntil?: Maybe<Scalars['DateTime']>;
  startTime: Scalars['DateTime'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
}

/** The different kinds of alternative care */
export const AlternativeCare = {
  CampusCrisisLine: 'campusCrisisLine',
  CollegeCounselor: 'collegeCounselor',
  EmergencyRoom: 'emergencyRoom',
  MantraTherapy: 'mantraTherapy',
  NoSupport: 'noSupport',
  OffCampusTherapy: 'offCampusTherapy',
  Other: 'other',
  OtherOr998: 'otherOr998',
  Unsure: 'unsure',
} as const;

export type AlternativeCare = typeof AlternativeCare[keyof typeof AlternativeCare];
export interface AnonymousNavigateFromPatientPortalInput {
  target: AnonymousNavigateFromPatientPortalTarget;
}

export interface AnonymousNavigateFromPatientPortalResult {
  url: Scalars['String'];
}

export const AnonymousNavigateFromPatientPortalTarget = {
  Login: 'Login',
  LoginActivationResend: 'LoginActivationResend',
  ResetPassword: 'ResetPassword',
  Signup: 'Signup',
} as const;

export type AnonymousNavigateFromPatientPortalTarget =
  typeof AnonymousNavigateFromPatientPortalTarget[keyof typeof AnonymousNavigateFromPatientPortalTarget];
export interface Answer {
  answer?: Maybe<Scalars['String']>;
  answerKey?: Maybe<Scalars['JSON']>;
  key: Scalars['String'];
  legend?: Maybe<Array<Scalars['String']>>;
  multiText?: Maybe<Array<Scalars['String']>>;
  score?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
  textForProviders?: Maybe<Scalars['String']>;
  value: Scalars['JSON'];
}

export interface AnswerSubmission {
  answerKey?: InputMaybe<Scalars['JSON']>;
  key: Scalars['String'];
  multiText?: InputMaybe<Array<Scalars['String']>>;
  score?: InputMaybe<Scalars['Float']>;
  text?: InputMaybe<Scalars['String']>;
}

export interface AppointmentCharge {
  amountInCents: Scalars['Float'];
  capturedAt: Scalars['DateTime'];
  chargeType: AppointmentChargeType;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
}

export const AppointmentChargeType = {
  Copay: 'Copay',
  LateCancellationFee: 'LateCancellationFee',
  NoShowFee: 'NoShowFee',
  PatientResponsibility: 'PatientResponsibility',
  SelfPay: 'SelfPay',
} as const;

export type AppointmentChargeType =
  typeof AppointmentChargeType[keyof typeof AppointmentChargeType];
export interface AppointmentFeedbackInput {
  audioQualityDown?: InputMaybe<CallQuality>;
  audioQualityUp?: InputMaybe<CallQuality>;
  comments?: InputMaybe<Scalars['String']>;
  issues?: InputMaybe<Array<Scalars['String']>>;
  overallRating?: InputMaybe<Scalars['Float']>;
  userDevice?: InputMaybe<Scalars['JSON']>;
  videoQualityDown?: InputMaybe<CallQuality>;
  videoQualityUp?: InputMaybe<CallQuality>;
  videoRating?: InputMaybe<Scalars['Float']>;
}

export interface AppointmentTemplate {
  appointmentType: Scalars['String'];
  careType: CareType;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['Float'];
  organization: Organization;
}

export interface AppointmentTemplateUpdate {
  appointmentType: Scalars['String'];
  careType: Scalars['String'];
  duration: Scalars['Int'];
}

export const AppointmentType = {
  Adhoc: 'adhoc',
  Checkin: 'checkin',
  Intake: 'intake',
} as const;

export type AppointmentType = typeof AppointmentType[keyof typeof AppointmentType];
export const AppView = {
  Mcp: 'mcp',
  Oz: 'oz',
  Referral: 'referral',
} as const;

export type AppView = typeof AppView[keyof typeof AppView];
export interface Assessment {
  id: Scalars['Float'];
  key: AssessmentKey;
  questionnaires: Array<Questionnaire>;
}

export const AssessmentKey = {
  AdjustedIntake: 'AdjustedIntake',
  Bipolar1Assessment: 'Bipolar1Assessment',
  Bipolar1Screener: 'Bipolar1Screener',
  CoachingExecutiveFunctioningKickoff: 'CoachingExecutiveFunctioningKickoff',
  CoachingExecutiveFunctioningWellnessAssessment: 'CoachingExecutiveFunctioningWellnessAssessment',
  CoachingKickOff: 'CoachingKickOff',
  CoachingWellnessAssessment: 'CoachingWellnessAssessment',
  FindCare: 'FindCare',
  Intake: 'Intake',
  MedicalHistory: 'MedicalHistory',
  Scales: 'Scales',
  SelfRefer1Screener: 'SelfRefer1Screener',
} as const;

export type AssessmentKey = typeof AssessmentKey[keyof typeof AssessmentKey];
export interface AssessmentModel {
  asrsv11: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  gad7: Scalars['Float'];
  id: Scalars['Float'];
  phq9: Scalars['Float'];
  result: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
}

export interface AssessmentRequestModel {
  complete: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  key: AssessmentKey;
}

export interface AssessmentResponse {
  createdAt: Scalars['DateTime'];
  followUpRequest?: Maybe<AssessmentRequestModel>;
  id: Scalars['Float'];
  provider?: Maybe<Provider>;
  questionnaires: Array<QuestionnaireResult>;
  specialConditions?: Maybe<SpecialConditions>;
}

export interface AssignableSkill {
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
  title: Scalars['String'];
}

export interface AssignmentPagination {
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export interface AssignSkillToUserResponse {
  isDuplicate: Scalars['Boolean'];
  wasAssigned: Scalars['Boolean'];
}

export interface AsynchronousJob {
  id: Scalars['Float'];
  status: Scalars['String'];
  url?: Maybe<Scalars['String']>;
}

export interface AuthInstructions {
  authMethod: AuthMethod;
  hasPreviousSSOLogin?: Maybe<Scalars['Boolean']>;
  organizationAbbreviation?: Maybe<Scalars['String']>;
  samlEntryPoint?: Maybe<Scalars['String']>;
}

export const AuthMethod = {
  Password: 'Password',
  Saml: 'SAML',
} as const;

export type AuthMethod = typeof AuthMethod[keyof typeof AuthMethod];
export interface Availability {
  allocationId: Scalars['Float'];
  blockers: Array<AvailabilityBlockedReason>;
  careType: CareType;
  end: Scalars['String'];
  isFeeForServiceTime?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['Float']>;
  provider: Provider;
  providerId: Scalars['Float'];
  start: Scalars['String'];
}

export const AvailabilityBlockedReason = {
  BackToBackIntake: 'BackToBackIntake',
  ContractSessions: 'ContractSessions',
  DedicatedHoursMaxedForWeek: 'DedicatedHoursMaxedForWeek',
  FfsAllocationBlockedForSameDay: 'FFSAllocationBlockedForSameDay',
  IntakesMaxedForDay: 'IntakesMaxedForDay',
  NoDgmHoursLeftForState: 'NoDGMHoursLeftForState',
  NoDgmIntakesLeftForState: 'NoDGMIntakesLeftForState',
  SessionsModelWeeklyIntakes: 'SessionsModelWeeklyIntakes',
  SessionsModelWeeklySessions: 'SessionsModelWeeklySessions',
  StudentCapacity: 'StudentCapacity',
} as const;

export type AvailabilityBlockedReason =
  typeof AvailabilityBlockedReason[keyof typeof AvailabilityBlockedReason];
export interface AvailabilityResult {
  data: Array<Availability>;
  globalBlockers: Array<SimpleGlobalAvailabilityBlocker>;
  interval: Scalars['Float'];
}

export interface AvailabilityV4 {
  availability: Array<Availability>;
  globalBlockers: Array<SimpleGlobalAvailabilityBlocker>;
}

/** Calendar events show events related to a patient. */
export interface CalendarEvent {
  adminCrisisProtocolEngagedAt?: Maybe<Scalars['DateTime']>;
  adminCrisisTeamNotifiedAt?: Maybe<Scalars['DateTime']>;
  adminHostCrisisId?: Maybe<Scalars['String']>;
  appointmentType: Scalars['String'];
  cancelledOrRescheduleWithin: Scalars['Boolean'];
  careType: CareType;
  changedOutOfPolicy: Scalars['Boolean'];
  charges: Array<AppointmentCharge>;
  checkedInAt?: Maybe<Scalars['DateTime']>;
  complete: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  finalizedInsuranceClaim?: Maybe<FinalizedInsuranceClaim>;
  id: Scalars['Float'];
  /** Is the appointment blocked by patient tasks */
  isBlocked: Scalars['Boolean'];
  medicalNote?: Maybe<MedicalNote>;
  provider: Provider;
  providerId: Scalars['Float'];
  providerIncident?: Maybe<ProviderIncident>;
  requiresProviderFeedback: Scalars['Boolean'];
  startTime: Scalars['DateTime'];
  status: Scalars['String'];
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  statusChanges?: Maybe<Array<CalendarStatusChange>>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Float'];
  usesDedicatedGroupModelHours?: Maybe<Scalars['Boolean']>;
  videoSessionId: Scalars['String'];
  videoToken: Scalars['String'];
}

/** Calendar events show events related to a patient. */
export type CalendarEventCancelledOrRescheduleWithinArgs = {
  diffHours: Scalars['Float'];
};

/** Calendar events show events related to a patient. */
export type CalendarEventRequiresProviderFeedbackArgs = {
  includeCurrentAppointment?: InputMaybe<Scalars['Boolean']>;
};

/** Calendar events show events related to a patient. */
export type CalendarEventStatusChangedAtArgs = {
  toStatuses: Array<Scalars['String']>;
};

export interface CalendarStatusChange {
  actingProvider?: Maybe<Provider>;
  createdAt: Scalars['DateTime'];
  fromStatus: Scalars['String'];
  id: Scalars['Float'];
  toStatus: Scalars['String'];
}

export interface CallQuality {
  bitrate: Scalars['Float'];
  packetLossRatio: Scalars['Float'];
}

export interface CampusOrgMappingInput {
  campus: Scalars['String'];
  organizationId: Scalars['Int'];
}

export interface CampusOrgMappingsInput {
  campus: Scalars['String'];
  organizationId: Scalars['Int'];
}

export interface CampusTeamMemberInput {
  providerId: Scalars['Int'];
  relationshipType: UniversityRelationship;
}

export interface CampusTeamRelationship {
  provider: Provider;
  relationshipType: UniversityRelationship;
  user: User;
}

export interface CampusTeamRelationshipEvents {
  events: Array<Event>;
  relationship: CampusTeamRelationship;
}

export interface CapacityUsage {
  endTime: Scalars['DateTime'];
  entries: Array<CareTypeUsage>;
  startTime: Scalars['DateTime'];
}

export interface CareEligibility {
  isUserEligible?: Maybe<Scalars['Boolean']>;
  isUserUnderage: Scalars['Boolean'];
  minimumAge: Scalars['Float'];
  minorConsentNeeded: Scalars['Boolean'];
  permittedCareTypes: Array<CareType>;
}

export interface CareRecommendation {
  recommendation?: Maybe<Scalars['String']>;
}

export interface CareRecommendationResponse {
  careRecommendation?: Maybe<RecommendationOptions>;
  connectNowEnabled: Scalars['Boolean'];
  isUserDeactivated?: Maybe<Scalars['Boolean']>;
  onDemandEnabled: Scalars['Boolean'];
  togetherAllEnabled: Scalars['Boolean'];
}

export interface CareSessionInfo {
  careType: CareType;
  completedAfter?: Maybe<Scalars['DateTime']>;
  completedAppts: Scalars['Float'];
  flowLimit?: Maybe<Scalars['Float']>;
  remainingAppts?: Maybe<Scalars['Float']>;
  unlimitedAppts: Scalars['Boolean'];
  user: User;
}

export const CareStatus = {
  Active: 'Active',
  Cancelled: 'Cancelled',
  Deleted: 'Deleted',
  Discharged: 'Discharged',
  New: 'New',
  OnHold: 'OnHold',
  ScreenedOut: 'ScreenedOut',
} as const;

export type CareStatus = typeof CareStatus[keyof typeof CareStatus];
export interface CareTeamChannel {
  channel: Channel;
  id: Scalars['Float'];
  user: User;
}

export interface CareTeamPreference {
  key: Scalars['String'];
  value: Scalars['JSON'];
}

export interface CareTeamReferral {
  activePatients: Scalars['Float'];
  activeReferrals: Scalars['Float'];
  provider: Provider;
  referralCompletionRate: Scalars['Float'];
  referralsMade: Scalars['Float'];
}

export const CareType = {
  Psychiatry: 'Psychiatry',
  Therapy: 'Therapy',
} as const;

export type CareType = typeof CareType[keyof typeof CareType];
export interface CareTypeUsage {
  careType: CareType;
  minutesAvailable: Scalars['Int'];
  minutesUsed: Scalars['Int'];
}

export interface Channel {
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  lastMessage?: Maybe<MessageV2>;
  messages: Array<MessageV2>;
  /** all providers who have sent messages in the channel */
  uniqProviders: Array<Provider>;
  unreadMessageCount: Scalars['Float'];
  unreadMessages: Array<MessageV2>;
}

export const ClaimAction = {
  Note: 'Note',
  StatusChange: 'StatusChange',
} as const;

export type ClaimAction = typeof ClaimAction[keyof typeof ClaimAction];
export interface CoachChatMessage {
  author: CoachChatMessageAuthor;
  content: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['String'];
}

export const CoachChatMessageAuthor = {
  Coach: 'coach',
  Patient: 'patient',
} as const;

export type CoachChatMessageAuthor =
  typeof CoachChatMessageAuthor[keyof typeof CoachChatMessageAuthor];
export interface CoachChatUpdate {
  action: Scalars['String'];
  message?: Maybe<CoachChatMessage>;
  messageId: Scalars['String'];
}

export interface CoachingAssessmentProgress {
  completed: Scalars['Boolean'];
  key: CoachingAssessments;
}

export const CoachingAssessments = {
  Goals: 'Goals',
  GoalsSuccess: 'GoalsSuccess',
  Wellness: 'Wellness',
  WellnessSuccess: 'WellnessSuccess',
} as const;

export type CoachingAssessments = typeof CoachingAssessments[keyof typeof CoachingAssessments];
export interface CoachingIntakeProgress {
  setGoalsCompleted: Scalars['Boolean'];
  wellnessAssessmentCompleted: Scalars['Boolean'];
}

export interface CoachingIntakeProgressV2 {
  coachingAssessments: Array<CoachingAssessmentProgress>;
}

export const CoachingProgramType = {
  EmotionalWellness: 'EmotionalWellness',
  ExecutiveFunctioning: 'ExecutiveFunctioning',
} as const;

export type CoachingProgramType = typeof CoachingProgramType[keyof typeof CoachingProgramType];
export const CoachingStatus = {
  Active: 'active',
  Inactive: 'inactive',
  Onboarding: 'onboarding',
} as const;

export type CoachingStatus = typeof CoachingStatus[keyof typeof CoachingStatus];
export interface CoachOverview {
  coachAvatarUrl?: Maybe<Scalars['String']>;
  coachingEndDate?: Maybe<Scalars['String']>;
  coachingStartDate?: Maybe<Scalars['String']>;
  coachName: Scalars['String'];
  hasUnreadMessages: Scalars['Boolean'];
}

export interface ConfigurationOption {
  description: Scalars['String'];
  key: Scalars['String'];
  subText?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  value: Scalars['JSON'];
}

/** The different kinds of alternative care */
export const ConnectNowAlternativeCare = {
  CampusCrisisLine: 'campusCrisisLine',
  CollegeCounselor: 'collegeCounselor',
  EmergencyRoom: 'emergencyRoom',
  MantraTherapy: 'mantraTherapy',
  NoSupport: 'noSupport',
  OffCampusTherapy: 'offCampusTherapy',
  Other: 'other',
  OtherOr998: 'otherOr998',
  Unsure: 'unsure',
} as const;

export type ConnectNowAlternativeCare =
  typeof ConnectNowAlternativeCare[keyof typeof ConnectNowAlternativeCare];
/** The main concerns of a user */
export const ConnectNowMainConcerns = {
  Academics: 'Academics',
  AnxietyStress: 'AnxietyStress',
  CareerPlanning: 'CareerPlanning',
  ConcernForSomeoneElse: 'ConcernForSomeoneElse',
  Depression: 'Depression',
  Family: 'Family',
  FeelingDown: 'FeelingDown',
  Finances: 'Finances',
  FriendsRoommates: 'FriendsRoommates',
  Grief: 'Grief',
  HealthConcerns: 'HealthConcerns',
  Lgbtqia: 'LGBTQIA',
  LifeChanges: 'LifeChanges',
  Loneliness: 'Loneliness',
  Other: 'Other',
  Relationship: 'Relationship',
  SelfInjury: 'SelfInjury',
  SubstanceAbuse: 'SubstanceAbuse',
  SuicidalThoughts: 'SuicidalThoughts',
  Work: 'Work',
} as const;

export type ConnectNowMainConcerns =
  typeof ConnectNowMainConcerns[keyof typeof ConnectNowMainConcerns];
export interface ConnectNowPreEncounterInput {
  alternativeCare: ConnectNowAlternativeCare;
  currentLocation: Scalars['String'];
  distressLevel: Scalars['Int'];
  mainConcern: ConnectNowMainConcerns;
  otherAlternativeCare?: InputMaybe<Scalars['String']>;
  otherMainConcern?: InputMaybe<Scalars['String']>;
  preferredPhoneNumber: Scalars['String'];
  suicidalThoughts: ConnectNowSuicidalThoughts;
}

export interface ConnectNowReportsInput {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
}

export interface ConnectNowSession {
  callerFirstName: Scalars['String'];
  callerLastName: Scalars['String'];
  callerPreferredName?: Maybe<Scalars['String']>;
  callStartTime: Scalars['DateTime'];
  callType: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  healthieNoteId: Scalars['String'];
  id: Scalars['Float'];
  organization?: Maybe<ConnectNowSessionOrganization>;
  userId?: Maybe<Scalars['Float']>;
}

export interface ConnectNowSessionDocument {
  expiringUrl: Scalars['String'];
}

export interface ConnectNowSessionDocumentOutput {
  document?: Maybe<ConnectNowSessionDocument>;
}

export interface ConnectNowSessionOrganization {
  id: Scalars['Float'];
  name: Scalars['String'];
}

/** The different kinds of suicidal thoughts */
export const ConnectNowSuicidalThoughts = {
  DuringMyLifeTime: 'DuringMyLifeTime',
  Never: 'Never',
  WithinThePastMonth: 'WithinThePastMonth',
  WithinThePastYear: 'WithinThePastYear',
} as const;

export type ConnectNowSuicidalThoughts =
  typeof ConnectNowSuicidalThoughts[keyof typeof ConnectNowSuicidalThoughts];
export interface Contact {
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneValidated?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['Boolean']>;
  relationship?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId: Scalars['Int'];
}

export interface ContactUpsertInput {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  relationship?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
}

export interface ContinuityOfCareEligibilityCarePeriodValues {
  currentPeriod: OrganizationCarePeriodModel;
  firstDayOfBreak?: Maybe<Scalars['String']>;
  isCarePeriodEnding?: Maybe<Scalars['Boolean']>;
  isEndingDueToBreak?: Maybe<Scalars['Boolean']>;
  lastDayOfBreak?: Maybe<Scalars['String']>;
  lastDayOfTerm?: Maybe<Scalars['String']>;
  nextPeriod?: Maybe<OrganizationCarePeriodModel>;
}

export interface ContinuityOfCareEligibilityInput {
  additionalNotes?: InputMaybe<Scalars['String']>;
  careType: CareType;
  enrollment: Scalars['Boolean'];
  interestedInContinuingCare: Scalars['Boolean'];
  paymentType: Scalars['String'];
  providerId?: InputMaybe<Scalars['Float']>;
  remainingSessions?: InputMaybe<Scalars['Float']>;
  residentialLocationDuringBreak: Scalars['String'];
  userId: Scalars['Float'];
}

export interface ContinuityOfCareEligibilityModel {
  additionalNotes?: Maybe<Scalars['String']>;
  careType: CareType;
  completedBy: Provider;
  completedById: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  enrollment: Scalars['Boolean'];
  id: Scalars['Int'];
  interestedInContinuingCare: Scalars['Boolean'];
  paymentType: Scalars['String'];
  provider?: Maybe<Provider>;
  providerId: Scalars['Float'];
  remainingSessions?: Maybe<Scalars['Float']>;
  residentialLocationDuringBreak: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['Float'];
}

export interface ContinuityOfCareEligibilityResult {
  canContinueCare: Scalars['Boolean'];
  enrollment: Scalars['Boolean'];
  interestedInContinuingCare: Scalars['Boolean'];
  isEligible: Scalars['Boolean'];
  needsReferral: Scalars['Boolean'];
  paymentType: PaymentType;
  providerInState: Scalars['Boolean'];
}

export interface ContinuityOfCareEligibilityValidation {
  careType: CareType;
  paymentType: PaymentType;
}

export interface ContinuityOfCareModel {
  careType: CareType;
  completedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  nextSteps: NextSteps;
  pendingUser?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  requestedCareOutOfState?: Maybe<Scalars['Boolean']>;
  requiresReferral?: Maybe<Scalars['Boolean']>;
  secondaryState?: Maybe<StateCodes>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
}

export interface Contract {
  id: Scalars['Int'];
  psychiatryWeeks: ContractWeeks;
  scope: ContractScope;
  therapyWeeks: ContractWeeks;
  utilization: ContractUtilization;
}

export interface ContractCareTypeUtilization {
  sessionsRemaining: Scalars['Float'];
  sessionsScheduled: Scalars['Float'];
  sessionsUtilized: Scalars['Float'];
  studentCapacityRemaining: Scalars['Float'];
  studentCapacityUtilized: Scalars['Float'];
}

export interface ContractScope {
  endDate: Scalars['LocalDate'];
  psychiatryPatients: Scalars['Int'];
  psychiatrySessions: Scalars['Int'];
  startDate: Scalars['LocalDate'];
  therapyPatients: Scalars['Int'];
  therapySessions: Scalars['Int'];
}

export interface ContractUtilization {
  psychiatryUsage: ContractCareTypeUtilization;
  therapyUsage: ContractCareTypeUtilization;
}

export interface ContractWeek {
  endDate: Scalars['LocalDate'];
  id: Scalars['Int'];
  intakesMax: Scalars['Int'];
  intakesScheduled: Scalars['Int'];
  intakesUtilized: Scalars['Int'];
  sessionsMax: Scalars['Int'];
  sessionsScheduled: Scalars['Int'];
  sessionsUtilized: Scalars['Int'];
  startDate: Scalars['LocalDate'];
}

export interface ContractWeeks {
  current?: Maybe<ContractWeek>;
  past: Array<ContractWeek>;
  upcoming: Array<ContractWeek>;
}

export interface CreateAllocationInput {
  appointmentTypes?: InputMaybe<Array<Scalars['String']>>;
  childOrganizationIds?: InputMaybe<Array<Scalars['Float']>>;
  endTime: Scalars['DateTime'];
  existingId?: InputMaybe<Scalars['Float']>;
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  purpose: TimeAllocationPurpose;
  repeatsUntil?: InputMaybe<Scalars['DateTime']>;
  startTime: Scalars['DateTime'];
}

export interface CreateCareTransition {
  careType: CareType;
  nextSteps: NextSteps;
  reason?: InputMaybe<Scalars['String']>;
  requiresReferral?: InputMaybe<Scalars['Boolean']>;
  secondaryState?: InputMaybe<StateCodes>;
  userId: Scalars['Int'];
}

export interface CreateContractError {
  errorCode: CreateContractErrorCode;
  message: Scalars['String'];
}

export const CreateContractErrorCode = {
  InvalidDateRange: 'InvalidDateRange',
  MainOrgOnly: 'MainOrgOnly',
} as const;

export type CreateContractErrorCode =
  typeof CreateContractErrorCode[keyof typeof CreateContractErrorCode];
export interface CreateContractInput {
  endDate: Scalars['LooseLocalDate'];
  organizationId: Scalars['Float'];
  psychiatryPatients?: InputMaybe<Scalars['Int']>;
  psychiatrySessions?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['LooseLocalDate'];
  therapyPatients?: InputMaybe<Scalars['Int']>;
  therapySessions?: InputMaybe<Scalars['Int']>;
}

export type CreateContractResult = CreateContractError | CreateContractSuccess;

export interface CreateContractSuccess {
  id: Scalars['Int'];
}

export interface CreateFileUpload {
  globallyShared: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
  type: UploadType;
  userId?: InputMaybe<Scalars['Float']>;
}

export interface CreateIntake {
  endTime: Scalars['DateTime'];
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId: Scalars['Float'];
  startTime: Scalars['DateTime'];
}

export interface CreateOrganization {
  abbreviation: Scalars['String'];
  careTypes: Array<CareType>;
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
  emailDomains: Array<Scalars['String']>;
  entitlements: Array<OrganizationEntitlementInput>;
  name: Scalars['String'];
  type: OrganizationType;
}

export interface CreateOrganizationAnnouncementInput {
  details?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Float'];
  title: Scalars['String'];
}

export interface CreateProviderStateAllocationsModelInput {
  endDate: Scalars['String'];
  maxHours: Scalars['Float'];
  maxIntakeHours: Scalars['Float'];
  providerStateId: Scalars['Int'];
  startDate: Scalars['String'];
}

export interface CreateStaff {
  classification: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  npi?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['Float'];
  phone: Scalars['String'];
  postNominalTitles: Scalars['String'];
  role: CreateStaffRole;
}

export const CreateStaffRole = {
  UniversityAdmin: 'universityAdmin',
  UniversityReferrer: 'universityReferrer',
  UniversityStaff: 'universityStaff',
  UniversitySupportingClinician: 'universitySupportingClinician',
} as const;

export type CreateStaffRole = typeof CreateStaffRole[keyof typeof CreateStaffRole];
export interface CreateTestUser {
  careTypes: Array<CareType>;
  completeCheckout?: InputMaybe<Scalars['Boolean']>;
  completeProfile?: InputMaybe<Scalars['Boolean']>;
  defaultPaymentSource?: InputMaybe<PaymentSource>;
  includeAppointmentFeedback?: InputMaybe<Scalars['Boolean']>;
  medicalHistory?: InputMaybe<Scalars['Boolean']>;
  mockMessages?: InputMaybe<Scalars['Boolean']>;
  numAppointments?: InputMaybe<Scalars['Float']>;
  numAssessments?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  psychiatristId?: InputMaybe<Scalars['Float']>;
  referralSource: ReferralSource;
  therapistId?: InputMaybe<Scalars['Float']>;
  withPharmacy?: InputMaybe<Scalars['Boolean']>;
}

export interface CreateTicketInput {
  assigneeId?: InputMaybe<Scalars['Float']>;
  category: TicketCategory;
  description?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<TicketPriority>;
  resolveIn24Hrs?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
}

export interface CreateUser {
  birthDate: Scalars['UserBirthdate'];
  campusTeam?: InputMaybe<Array<CampusTeamMemberInput>>;
  careTypes: Array<CareType>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  geoState: StateCodes;
  lastName: Scalars['String'];
  organizationId: Scalars['Float'];
  phone: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
  samlUniqueId?: InputMaybe<Scalars['String']>;
  suggestedProviders?: InputMaybe<Array<Scalars['Float']>>;
}

export interface CreateUserDemographicsInput {
  academicProgram: Scalars['String'];
  ethnicity: Scalars['String'];
  genderIdentity: Scalars['String'];
  isInternationalStudent: Scalars['Boolean'];
  sexualOrientation: Scalars['String'];
  yearInProgram: Scalars['String'];
}

export interface CrisisTeamHours {
  dayOfWeek: DayOfWeek;
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
}

export interface CrisisTeamHoursInput {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
}

export interface CrisisTeamRelationship {
  crisisPhone: Scalars['String'];
  organization: Organization;
  provider: Provider;
}

export interface CustomClinicalRecConfig {
  /** The url for the call to action button on the custom clinical care recommendation card. */
  customClinicalRecButtonLink?: Maybe<Scalars['String']>;
  /** The text for the call to action button on the custom clinical care recommendation card. */
  customClinicalRecButtonText?: Maybe<Scalars['String']>;
  customClinicalRecDescription: Scalars['String'];
  customClinicalRecTitle: Scalars['String'];
}

export interface DataSharingConsent {
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  revokedAt?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
}

export interface DataSharingRequest {
  canProviderSendReminder: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  lastReminderSentDate?: Maybe<Scalars['DateTime']>;
  pending: Scalars['Boolean'];
  provider: Provider;
}

export const DayOfWeek = {
  Friday: 'Friday',
  Monday: 'Monday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  Thursday: 'Thursday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
} as const;

export type DayOfWeek = typeof DayOfWeek[keyof typeof DayOfWeek];
export interface DdhFileReport {
  callId: Scalars['String'];
  callStart: Scalars['DateTime'];
  ddhFileSuccessId: Scalars['Float'];
  id: Scalars['Float'];
}

export interface DdhFileSuccess {
  ddhFileEntityId: Scalars['Float'];
  id: Scalars['Float'];
  organizationId: Scalars['Float'];
  reportDate: Scalars['String'];
  reports?: Maybe<Array<DdhFileReport>>;
  zipLink: Scalars['String'];
}

export interface DebugUserCareFlow {
  careStatus: CareStatus;
  careType: CareType;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  paymentSource: PaymentSource;
  taskStatus: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  validEnd?: Maybe<Scalars['DateTime']>;
  validStart: Scalars['DateTime'];
}

export interface Dependency {
  foreignKey?: Maybe<Scalars['String']>;
  optionText?: Maybe<Scalars['String']>;
}

export interface DependentQuestions {
  /** The question is required if <target> question is blank. */
  requiredIfEmpty?: Maybe<Scalars['Boolean']>;
  requiredIfValues?: Maybe<Array<Scalars['String']>>;
  targetKey: Scalars['String'];
}

export const DiscountType = {
  PercentDiscount: 'PercentDiscount',
  ReplacePrice: 'ReplacePrice',
} as const;

export type DiscountType = typeof DiscountType[keyof typeof DiscountType];
export const EditableUserFields = {
  Address: 'Address',
  CareFlows: 'CareFlows',
  CareTeam: 'CareTeam',
  DateOfBirth: 'DateOfBirth',
  Email: 'Email',
  LegalName: 'LegalName',
  Organization: 'Organization',
  OrgCareTeam: 'OrgCareTeam',
  Phone: 'Phone',
  PreferredName: 'PreferredName',
  Providers: 'Providers',
  SecondaryState: 'SecondaryState',
  UniqueId: 'UniqueId',
} as const;

export type EditableUserFields = typeof EditableUserFields[keyof typeof EditableUserFields];
export interface EditContractScopeError {
  errorCode: EditContractScopeErrorCode;
  message: Scalars['String'];
}

export const EditContractScopeErrorCode = {
  ContractRangeTooLong: 'ContractRangeTooLong',
  InvalidDateRange: 'InvalidDateRange',
  OverlappingContracts: 'OverlappingContracts',
} as const;

export type EditContractScopeErrorCode =
  typeof EditContractScopeErrorCode[keyof typeof EditContractScopeErrorCode];
export interface EditContractScopeInput {
  endDate?: InputMaybe<Scalars['LooseLocalDate']>;
  id: Scalars['Int'];
  psychiatryPatients?: InputMaybe<Scalars['Int']>;
  psychiatrySessions?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['LooseLocalDate']>;
  therapyPatients?: InputMaybe<Scalars['Int']>;
  therapySessions?: InputMaybe<Scalars['Int']>;
}

export type EditContractScopeResult = EditContractScopeError | EditContractScopeSuccess;

export interface EditContractScopeSuccess {
  id: Scalars['Int'];
}

export const EditContractWeekErrorCode = {
  IntakeGreaterThanSessions: 'IntakeGreaterThanSessions',
  WeekNotFound: 'WeekNotFound',
} as const;

export type EditContractWeekErrorCode =
  typeof EditContractWeekErrorCode[keyof typeof EditContractWeekErrorCode];
export interface EditContractWeekIntakesInput {
  id: Scalars['Int'];
  intakes: Scalars['Int'];
}

export interface EditContractWeeksAggregateError {
  aggregateErrors: Array<EditContractWeeksError>;
}

export interface EditContractWeeksError {
  errorCode: EditContractWeekErrorCode;
  weekId: Scalars['Int'];
}

export interface EditContractWeekSessionsInput {
  id: Scalars['Int'];
  sessions: Scalars['Int'];
}

export interface EditContractWeeksInput {
  psychiatryWeekIntakes?: InputMaybe<Array<EditContractWeekIntakesInput>>;
  psychiatryWeekSessions?: InputMaybe<Array<EditContractWeekSessionsInput>>;
  therapyWeekIntakes?: InputMaybe<Array<EditContractWeekIntakesInput>>;
  therapyWeekSessions?: InputMaybe<Array<EditContractWeekSessionsInput>>;
}

export type EditContractWeeksResult = EditContractWeeksAggregateError | EditContractWeeksSuccess;

export interface EditContractWeeksSuccess {
  successMessage: Scalars['String'];
}

export interface EditOrganizationAnnouncementInput {
  details?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
}

export interface EditOrganizationCarePeriod {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  periodType: PeriodType;
  startDate: Scalars['String'];
}

export interface EditOrganizationHandbookInput {
  campusCrisisTeamInProduct?: InputMaybe<Scalars['String']>;
  campusSafetyPoliceNumber?: InputMaybe<Scalars['String']>;
  coPayChargedByPartner?: InputMaybe<Scalars['String']>;
  defaultTherapyCadence?: InputMaybe<Scalars['String']>;
  ekgsNearCampus?: InputMaybe<Scalars['String']>;
  fullADHDEvaluation?: InputMaybe<Scalars['String']>;
  highLevelProtocolManagingEmergency?: InputMaybe<Scalars['String']>;
  hospitalization?: InputMaybe<Scalars['String']>;
  labsNearCampus?: InputMaybe<Scalars['String']>;
  mainPointOfContactCrisis?: InputMaybe<Scalars['String']>;
  noShowsCountForSessionLimits?: InputMaybe<Scalars['String']>;
  protocolForMedicalLeaveDoc?: InputMaybe<Scalars['String']>;
  requestingAcademicAccomodations?: InputMaybe<Scalars['String']>;
  sessionLimitsRenew?: InputMaybe<Scalars['String']>;
  summerBreakEligibility?: InputMaybe<Scalars['String']>;
  supportingClinician?: InputMaybe<Scalars['String']>;
  vitalsNearCampus?: InputMaybe<Scalars['String']>;
  winterBreakEligibility?: InputMaybe<Scalars['String']>;
}

export interface EditTicketInput {
  assigneeId?: InputMaybe<Scalars['Float']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<TicketCategory>;
  description?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<TicketPriority>;
  resolveIn24Hrs?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<TicketStatus>;
  title?: InputMaybe<Scalars['String']>;
}

export interface EditUser {
  authToken?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['String']>;
  campusTeam?: InputMaybe<Array<CampusTeamMemberInput>>;
  careFlows?: InputMaybe<Array<UserCareFlowInput>>;
  dtc?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  mantraAdminCareTeam?: InputMaybe<Array<Scalars['Float']>>;
  organizationId?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<AddressInput>;
  providerId?: InputMaybe<Scalars['Int']>;
  samlUniqueId?: InputMaybe<Scalars['String']>;
  secondaryState?: InputMaybe<StateCodes>;
  therapistId?: InputMaybe<Scalars['Int']>;
}

export interface EmbedCoach {
  coachId: Scalars['String'];
  isCurrentCoach: Scalars['Boolean'];
  name: Scalars['String'];
}

export interface EmbedOrganization {
  name: Scalars['String'];
}

export interface EmbedPatient {
  name: Scalars['String'];
  organization?: Maybe<EmbedOrganization>;
}

export const EngagementTag = {
  Disengaged: 'Disengaged',
  IncompleteTasks: 'IncompleteTasks',
  LimitedSessions: 'LimitedSessions',
  NeedsInsuranceDetails: 'NeedsInsuranceDetails',
  NoShow: 'NoShow',
  Unverified: 'Unverified',
} as const;

export type EngagementTag = typeof EngagementTag[keyof typeof EngagementTag];
export const Entitlement = {
  AllowCharlieHealthIopReferralsViaMcp: 'AllowCharlieHealthIOPReferralsViaMCP',
  AllowMultipleReferralTypes: 'AllowMultipleReferralTypes',
  AllowTogetherAllReferralsViaMcp: 'AllowTogetherAllReferralsViaMCP',
  CampusResources: 'CampusResources',
  CanReactivatePatients: 'CanReactivatePatients',
  Coaching: 'Coaching',
  CollateEmail: 'CollateEmail',
  ConnectNow: 'ConnectNow',
  CrisisTeam: 'CrisisTeam',
  CustomAvailabilityInterval: 'CustomAvailabilityInterval',
  CustomHubDashboardModule: 'CustomHubDashboardModule',
  CustomMinAge: 'CustomMinAge',
  DdhSync: 'DDHSync',
  DedicatedGroupModel: 'DedicatedGroupModel',
  DisableCampusResources: 'DisableCampusResources',
  DisablePatientPortal: 'DisablePatientPortal',
  ExecutiveFunctioning: 'ExecutiveFunctioning',
  HideReportingDashboard: 'HideReportingDashboard',
  Hub: 'Hub',
  HubTogetherallEnable: 'HubTogetherallEnable',
  LevelOfCareAssessment: 'LevelOfCareAssessment',
  ManualSponsoredCareReactivation: 'ManualSponsoredCareReactivation',
  OnDemand: 'OnDemand',
  OrgIdentification: 'OrgIdentification',
  ReferrerRole: 'ReferrerRole',
  RequireConsentForMinors: 'RequireConsentForMinors',
  RequireSelfReferEligibilityCheck: 'RequireSelfReferEligibilityCheck',
  RestrictedPatientProfiles: 'RestrictedPatientProfiles',
  ScreenSelfReferStudents: 'ScreenSelfReferStudents',
  SelfReferral: 'SelfReferral',
  SelfReferralCollaborativeModelScreen: 'SelfReferralCollaborativeModelScreen',
  SessionsModel: 'SessionsModel',
  ShowUtilizationTabToMcpAdmin: 'ShowUtilizationTabToMcpAdmin',
  Sso: 'SSO',
  SupportingClinicianRole: 'SupportingClinicianRole',
  UnlimitedNoShows: 'UnlimitedNoShows',
  WellnessLevelOfCareAssessment: 'WellnessLevelOfCareAssessment',
  WholeCampusCare: 'WholeCampusCare',
} as const;

export type Entitlement = typeof Entitlement[keyof typeof Entitlement];
export interface EntitlementDefinition {
  config: Array<EntitlementDefinitionConfig>;
  description: Scalars['String'];
  key: Entitlement;
  name: Scalars['String'];
}

export interface EntitlementDefinitionConfig {
  key: Scalars['String'];
  maxLength?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  placeholder?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  size?: Maybe<EntitlementDefinitionConfigSize>;
  smallText?: Maybe<Scalars['String']>;
  type: Scalars['String'];
}

export const EntitlementDefinitionConfigSize = {
  Large: 'large',
  Small: 'small',
} as const;

export type EntitlementDefinitionConfigSize =
  typeof EntitlementDefinitionConfigSize[keyof typeof EntitlementDefinitionConfigSize];
export interface Event {
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['Float'];
  provider?: Maybe<Provider>;
  tag: Scalars['String'];
  user?: Maybe<User>;
}

export type FacultyHubReferralResult = FacultyHubReferralSuccess | ReferralError;

export interface FacultyHubReferralSuccess {
  successMessage: Scalars['String'];
}

export interface FaQuestion {
  answer: Scalars['String'];
  examples?: Maybe<Array<Scalars['String']>>;
  question: Scalars['String'];
}

export interface FeedbackAnswer {
  key: Scalars['String'];
  /** JSON stringified value */
  value: Scalars['String'];
}

export const FeedbackType = {
  Followup: 'Followup',
  Initial: 'Initial',
} as const;

export type FeedbackType = typeof FeedbackType[keyof typeof FeedbackType];
export interface FinalizedInsuranceClaim {
  insurancePaidInCents: Scalars['Float'];
  patientResponsibilityInCents: Scalars['Float'];
}

export interface FindCareStartOutput {
  connectNowEnabled: Scalars['Boolean'];
  onDemandEnabled: Scalars['Boolean'];
  questionnaire: Questionnaire;
}

export interface FinishSkillReflectionResponse {
  deleted?: Maybe<Scalars['Boolean']>;
  saved?: Maybe<Scalars['Boolean']>;
}

export interface FirstFactorResult {
  /** Token to be included with the second factor mutation when using 2FA */
  firstFactorToken: Scalars['String'];
  /** Last 4 digits of phone number used in two factor auth */
  phoneLastFour: Scalars['String'];
}

export interface FormSection {
  /** Copy after the main question */
  afterCopy?: Maybe<Array<SectionCopy>>;
  background?: Maybe<SectionColor>;
  /** Copy before the main question */
  beforeCopy?: Maybe<Array<SectionCopy>>;
  border?: Maybe<SectionColor>;
  /** Allows for sub-sections */
  children?: Maybe<Array<FormSection>>;
  closeable?: Maybe<Scalars['Boolean']>;
  dependsOn?: Maybe<SectionDependency>;
  format: SectionFormat;
  hooks?: Maybe<Array<SectionHook>>;
  isolatedOption?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  maxSelections?: Maybe<Scalars['Float']>;
  /** Options if selectable */
  options?: Maybe<Array<Scalars['String']>>;
  placeholder?: Maybe<Scalars['String']>;
  /** Prevent submission with outstanding required questions */
  required?: Maybe<Scalars['Boolean']>;
  /** Rules to determine if a field is required or not */
  requiredIf?: Maybe<RequiredIf>;
  sectionTitle?: Maybe<Scalars['String']>;
  selectDependentValues?: Maybe<Array<SelectDependentValue>>;
  startClosed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  /** Hide number when rendering */
  uncounted?: Maybe<Scalars['Boolean']>;
  /** JSON stringified value */
  value?: Maybe<Scalars['String']>;
  warning?: Maybe<TriggeredCopy>;
}

export interface GenericCollegeModalModel {
  description: Scalars['String'];
  id: Scalars['Float'];
  linkText: Scalars['String'];
  organizationId: Scalars['Float'];
  pictureType?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
}

export interface GetContractError {
  errorCode: GetContractErrorCode;
  message: Scalars['String'];
}

export const GetContractErrorCode = {
  ContractNotFound: 'ContractNotFound',
} as const;

export type GetContractErrorCode = typeof GetContractErrorCode[keyof typeof GetContractErrorCode];
export type GetContractResult = Contract | GetContractError;

export interface GetUpNextSkillForModuleResult {
  moduleIsComplete: Scalars['Boolean'];
  upNextSkill?: Maybe<Scalars['JSON']>;
  upNextSkillIsComplete: Scalars['Boolean'];
}

export interface GetUpNextSkillResult {
  moduleWillBeComplete: Scalars['Boolean'];
  upNextSkill?: Maybe<Scalars['JSON']>;
  upNextSkillIsComplete: Scalars['Boolean'];
}

export const GlobalAvailabilityBlockerType = {
  ContractSessions: 'ContractSessions',
  NoContract: 'NoContract',
  ProviderStateBookableHours: 'ProviderStateBookableHours',
  ProviderStateWeeklyIntakes: 'ProviderStateWeeklyIntakes',
  StudentCapacity: 'StudentCapacity',
  WeeklyIntakes: 'WeeklyIntakes',
  WeeklySessions: 'WeeklySessions',
} as const;

export type GlobalAvailabilityBlockerType =
  typeof GlobalAvailabilityBlockerType[keyof typeof GlobalAvailabilityBlockerType];
export const HookType = {
  ClearOnChange: 'clearOnChange',
} as const;

export type HookType = typeof HookType[keyof typeof HookType];
export interface HubAnswerSubmission {
  answerKeys?: InputMaybe<Array<Scalars['String']>>;
  manualInput?: InputMaybe<Scalars['String']>;
  questionKey: Scalars['String'];
}

export interface HubAppointment {
  appointmentTypeId: Scalars['String'];
  appointmentTypeName: Scalars['String'];
  id: Scalars['String'];
  length: Scalars['Float'];
  rescheduleLink?: Maybe<Scalars['String']>;
  /** The ISO datetime for when the appointment starts. */
  start: Scalars['DateTime'];
  zoomJoinUrl: Scalars['String'];
}

export interface HubAssessmentSubmission {
  answers: Array<HubAnswerSubmission>;
  questionnaireKey: Scalars['String'];
}

export interface HubConnectNowData {
  crisisCallPhoneNumber: Scalars['String'];
  crisisSMS?: Maybe<Scalars['String']>;
  crisisWebChat?: Maybe<Scalars['String']>;
}

export interface HubConnectNowOutput {
  connectNowData?: Maybe<HubConnectNowData>;
  connectNowEnabled: Scalars['Boolean'];
}

export interface HubCustomModuleConfig {
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface HubCustomModuleData {
  data?: Maybe<HubCustomModuleConfig>;
  isEnabled: Scalars['Boolean'];
}

/** An appointment external to the hub. */
export interface HubExternalAppointment {
  /** The ISO datetime for when the appointment ends. */
  end: Scalars['DateTime'];
  /** The id of the appointment. */
  id: Scalars['Float'];
  /** The mantra resolution name for the appointment. */
  mrn: Scalars['String'];
  /** The ISO datetime for when the appointment starts. */
  start: Scalars['DateTime'];
}

export interface HubOnDemandData {
  crisisCallPhoneNumber: Scalars['String'];
  crisisSMS?: Maybe<Scalars['String']>;
  crisisWebChat?: Maybe<Scalars['String']>;
}

export interface HubOnDemandOutput {
  onDemandData?: Maybe<HubOnDemandData>;
  onDemandEnabled: Scalars['Boolean'];
}

export interface HubOrganizationResource {
  contact?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  showAsCrisisResource: Scalars['Boolean'];
  website?: Maybe<Scalars['String']>;
}

export interface HubResendActivationError {
  errorCode: HubResendActivationErrorCode;
  message: Scalars['String'];
}

export const HubResendActivationErrorCode = {
  InvalidEmail: 'InvalidEmail',
} as const;

export type HubResendActivationErrorCode =
  typeof HubResendActivationErrorCode[keyof typeof HubResendActivationErrorCode];
export type HubResendActivationResult = HubResendActivationError | HubResendActivationSuccess;

export interface HubResendActivationSuccess {
  success: Scalars['Boolean'];
}

export interface HubStatusForUser {
  hasHubOnboarded: Scalars['Boolean'];
  isHubEnabled: Scalars['Boolean'];
}

export interface HubUser {
  birthDate?: Maybe<Scalars['Date']>;
  canRedirect: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  hasHubOnboarded: Scalars['Boolean'];
  impersonatingId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<HubUserOrganization>;
  organizationCanAccessCampusResources: Scalars['Boolean'];
  organizationCanAccessCare: Scalars['Boolean'];
  organizationCanAccessTherapy: Scalars['Boolean'];
  organizationCanAccessTogetherAll: Scalars['Boolean'];
  organizationCounselingCenter?: Maybe<HubUserOrgCounselingCenter>;
  organizationHasCoaching: Scalars['Boolean'];
  organizationHasSelfReferrals: Scalars['Boolean'];
  organizationLogo?: Maybe<HubUserOrganizationLogo>;
  organizationMinimumHubAge: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<Address>;
  pronouns?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
  upcomingAppointment?: Maybe<HubUserHealthieAppointmentData>;
}

export interface HubUserHealthieAppointmentData {
  appointmentTypeId: Scalars['String'];
  appointmentTypeName: Scalars['String'];
  id: Scalars['String'];
  length: Scalars['Float'];
  start: Scalars['String'];
  zoomJoinUrl: Scalars['String'];
}

export interface HubUserOnboardError {
  errorCode: HubUserOnboardErrorCode;
  message: Scalars['String'];
}

export const HubUserOnboardErrorCode = {
  AgeBlocked: 'AgeBlocked',
  AgeConsentIncomplete: 'AgeConsentIncomplete',
  AgeConsentRequired: 'AgeConsentRequired',
} as const;

export type HubUserOnboardErrorCode =
  typeof HubUserOnboardErrorCode[keyof typeof HubUserOnboardErrorCode];
export type HubUserOnboardResult = HubUserOnboardError | HubUserOnboardSuccess;

export interface HubUserOnboardSuccess {
  successMessage: Scalars['String'];
}

export interface HubUserOrganization {
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface HubUserOrganizationLogo {
  contentType: Scalars['String'];
  url: Scalars['String'];
}

export interface HubUserOrgCounselingCenter {
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
}

export interface HubUserUpdateInput {
  birthDate?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<AddressInput>;
  pronouns?: InputMaybe<Scalars['String']>;
}

export interface Hyperlink {
  displayText: Scalars['String'];
  url: Scalars['String'];
}

export interface InAppNotification {
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  id: Scalars['Float'];
  isDismissible: Scalars['Boolean'];
  isRequired?: Maybe<Scalars['Boolean']>;
  key: UserNotificationKey;
}

export interface InsuranceClaim {
  balanceCents: Scalars['Float'];
  events: Array<InsuranceClaimEvent>;
  externalStatus: Scalars['String'];
  geoState: Scalars['String'];
  id: Scalars['Float'];
  internalStatus: InternalStatus;
  payer: Payer;
  provider: Provider;
  sentDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  visitDate: Scalars['DateTime'];
}

export interface InsuranceClaimEvent {
  action: ClaimAction;
  actor?: Maybe<Provider>;
  codes: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  details: Scalars['String'];
  id: Scalars['Float'];
  status: InternalStatus;
  updatedAt: Scalars['DateTime'];
}

export interface InsuranceDetails {
  deductibleRemainingCents?: Maybe<Scalars['Float']>;
  eligibilityVerified: Scalars['Boolean'];
  groupNumber?: Maybe<Scalars['String']>;
  inNetwork: Scalars['Boolean'];
  lastChecked?: Maybe<Scalars['DateTime']>;
  memberId?: Maybe<Scalars['String']>;
  noMentalHealthBenefits?: Maybe<Scalars['Boolean']>;
  patientRelationshipToSubscriber: PatientRelationshipToSubscriber;
  payer?: Maybe<Payer>;
  state: Scalars['String'];
  subscriberDateOfBirth?: Maybe<Scalars['String']>;
  subscriberFirstName?: Maybe<Scalars['String']>;
  subscriberGender?: Maybe<Scalars['String']>;
  subscriberLastName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
}

export interface InsuranceDetailsInput {
  birthDate?: InputMaybe<Scalars['String']>;
  enteredByProviderId?: InputMaybe<Scalars['Float']>;
  firstName?: InputMaybe<Scalars['String']>;
  groupNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
  patientRelationshipToSubscriber: PatientRelationshipToSubscriber;
  payerId?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  subscriberDateOfBirth?: InputMaybe<Scalars['String']>;
  subscriberFirstName?: InputMaybe<Scalars['String']>;
  subscriberGender?: InputMaybe<Scalars['String']>;
  subscriberLastName?: InputMaybe<Scalars['String']>;
}

export interface InsuranceEligibility {
  inNetwork: Scalars['Boolean'];
  noMentalHealthBenefits?: Maybe<Scalars['Boolean']>;
  valid: Scalars['Boolean'];
}

export interface IntakeAvailabilityResult {
  data: Array<Availability>;
  duration: Scalars['Float'];
  globalBlockers: Array<SimpleGlobalAvailabilityBlocker>;
  interval: Scalars['Float'];
}

export const InternalStatus = {
  Accepted: 'Accepted',
  Cancelled: 'Cancelled',
  Closed: 'Closed',
  Created: 'Created',
  Denied: 'Denied',
  FollowUp: 'FollowUp',
  Rejected: 'Rejected',
  Sent: 'Sent',
} as const;

export type InternalStatus = typeof InternalStatus[keyof typeof InternalStatus];
export interface Invoice {
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  paid: Scalars['Boolean'];
}

export interface IopReferralModel {
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  organizationId?: Maybe<Scalars['Float']>;
  organizationName: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  referralDateTime: Scalars['DateTime'];
  status: Scalars['String'];
  userId?: Maybe<Scalars['Float']>;
}

export interface JumpBackInSkillData {
  module?: Maybe<Scalars['JSON']>;
  skill?: Maybe<Scalars['JSON']>;
}

export interface ListContractsItemModel {
  endDate: Scalars['LocalDate'];
  id: Scalars['Int'];
  startDate: Scalars['LocalDate'];
}

export interface ListContractsModel {
  contracts: Array<ListContractsItemModel>;
}

export interface ListCopy {
  bullets: Array<Scalars['String']>;
  showInView?: Maybe<Scalars['Boolean']>;
  style?: Maybe<TextStyle>;
}

export const LoginHubError = {
  InvalidToken: 'invalid_token',
  InvalidTokenId: 'invalid_token_id',
  InvalidUser: 'invalid_user',
} as const;

export type LoginHubError = typeof LoginHubError[keyof typeof LoginHubError];
export interface LoginHubResult {
  error?: Maybe<LoginHubError>;
  hasHubOnboarded?: Maybe<Scalars['Boolean']>;
  success?: Maybe<LoginHubSuccess>;
}

export interface LoginHubSuccess {
  impersonated: Scalars['Boolean'];
  jwt?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
}

export interface LoginToHubError {
  errorCode: LoginToHubErrorCode;
  message: Scalars['String'];
}

export const LoginToHubErrorCode = {
  InvalidToken: 'InvalidToken',
  InvalidTokenId: 'InvalidTokenId',
  InvalidUser: 'InvalidUser',
} as const;

export type LoginToHubErrorCode = typeof LoginToHubErrorCode[keyof typeof LoginToHubErrorCode];
export type LoginToHubResult = LoginToHubError | LoginToHubSuccess;

export interface LoginToHubSuccess {
  hasHubOnboarded?: Maybe<Scalars['Boolean']>;
  impersonated: Scalars['Boolean'];
  jwt: Scalars['String'];
  userId: Scalars['Int'];
}

/** The main concerns of a user */
export const MainConcerns = {
  Academics: 'ACADEMICS',
  AnxietyStress: 'ANXIETY_STRESS',
  CareerPlanning: 'CAREER_PLANNING',
  ConcernForSomeoneElse: 'CONCERN_FOR_SOMEONE_ELSE',
  Depression: 'DEPRESSION',
  Family: 'FAMILY',
  FeelingDown: 'FEELING_DOWN',
  Finances: 'FINANCES',
  FriendsRoommates: 'FRIENDS_ROOMMATES',
  Grief: 'GRIEF',
  HealthConcerns: 'HEALTH_CONCERNS',
  Lgbtqia: 'LGBTQIA',
  LifeChanges: 'LIFE_CHANGES',
  Loneliness: 'LONELINESS',
  Other: 'OTHER',
  Relationship: 'RELATIONSHIP',
  SelfInjury: 'SELF_INJURY',
  SubstanceAbuse: 'SUBSTANCE_ABUSE',
  SuicidalThoughts: 'SUICIDAL_THOUGHTS',
  Work: 'WORK',
} as const;

export type MainConcerns = typeof MainConcerns[keyof typeof MainConcerns];
export interface ManualInput {
  label?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
}

export interface MaybeBlockedCalendarEvents {
  blockedReason?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  data?: Maybe<CalendarEvent>;
}

export interface MaybeBlockedProviders {
  blockedReason?: Maybe<ProviderServiceBlockedReason>;
  data?: Maybe<Array<Provider>>;
}

export interface McpCurrentContractCareTypeUtilization {
  sessionsRemaining: Scalars['Float'];
  sessionsScheduled: Scalars['Float'];
  sessionsUtilized: Scalars['Float'];
  studentCapacityRemaining: Scalars['Float'];
  studentCapacityUtilized: Scalars['Float'];
}

export interface McpCurrentContractScope {
  endDate: Scalars['LocalDate'];
  psychiatryPatients: Scalars['Int'];
  psychiatrySessions: Scalars['Int'];
  startDate: Scalars['LocalDate'];
  therapyPatients: Scalars['Int'];
  therapySessions: Scalars['Int'];
}

export interface McpCurrentContractUtilization {
  psychiatryUsage: McpCurrentContractCareTypeUtilization;
  scope: McpCurrentContractScope;
  therapyUsage: McpCurrentContractCareTypeUtilization;
}

export interface McpCurrentContractUtilizationResult {
  utilization?: Maybe<McpCurrentContractUtilization>;
}

export interface MedicalHistory {
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  questionnaires: Array<MedicalHistoryQuestionnaire>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Float'];
  uuid: Scalars['String'];
}

export interface MedicalHistoryQuestion {
  caption?: Maybe<Scalars['String']>;
  dependency?: Maybe<Dependency>;
  disclaimer?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Scalars['String']>>;
  isOptional?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  legend?: Maybe<Array<Scalars['String']>>;
  manualInput?: Maybe<ManualInput>;
  options?: Maybe<Array<Option>>;
  overrideDescription?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  subText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
  visible?: Maybe<Scalars['Boolean']>;
}

export interface MedicalHistoryQuestionnaire {
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  preScreen?: Maybe<QuestionnairePreScreen>;
  questions: Array<MedicalHistoryQuestion>;
  title: Scalars['String'];
}

export interface MedicalNote {
  appointment?: Maybe<CalendarEvent>;
  claimSubmitted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  key: Scalars['String'];
  provider: Provider;
  submittedAt?: Maybe<Scalars['DateTime']>;
  template: MedicalNoteTemplate;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  valueForKey?: Maybe<Scalars['String']>;
}

export type MedicalNoteValueForKeyArgs = {
  key: Scalars['String'];
};

export interface MedicalNoteSectionInput {
  key: Scalars['String'];
  /** JSON stringified value */
  value?: InputMaybe<Scalars['String']>;
}

export interface MedicalNoteTemplate {
  careTypes?: Maybe<Array<CareType>>;
  key: Scalars['String'];
  patientDetails?: Maybe<Array<PatientDetail>>;
  questions: Array<FormSection>;
  title: Scalars['String'];
  titleTag?: Maybe<TextTag>;
  uuid: Scalars['String'];
}

export interface MedicationInfo {
  brandNames: Array<Scalars['String']>;
  faq: Array<FaQuestion>;
  fdaApprovedTreatmentOf: Array<Scalars['String']>;
  medicationGuidelines: Array<Hyperlink>;
  name: Scalars['String'];
  safetyInformation: SafetyInfo;
  whatToExpect: Scalars['String'];
}

export const MedicationStatus = {
  Active: 'Active',
  CancelDenied: 'CancelDenied',
  Cancelled: 'Cancelled',
  CancelPending: 'CancelPending',
  CancelRequested: 'CancelRequested',
  Changed: 'Changed',
  Completed: 'Completed',
  Deleted: 'Deleted',
  Discontinued: 'Discontinued',
  FullFill: 'FullFill',
  Inactive: 'Inactive',
  NoFill: 'NoFill',
  PartialFill: 'PartialFill',
  Unknown: 'Unknown',
} as const;

export type MedicationStatus = typeof MedicationStatus[keyof typeof MedicationStatus];
export interface MessageV2 {
  attachments: Array<Upload>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** Do not use this with queries that return arrays of messages */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
}

export const MinorConsentStatus = {
  Approved: 'Approved',
  Incomplete: 'Incomplete',
} as const;

export type MinorConsentStatus = typeof MinorConsentStatus[keyof typeof MinorConsentStatus];
export interface MinorConsentStatusModel {
  status?: Maybe<MinorConsentStatus>;
}

export interface Mutation {
  activateCrisisMode: Scalars['String'];
  activateSafetyPlan: SafetyPlan;
  addProviderToCrisisTeams: Scalars['Boolean'];
  addSelfReferralEmails: Scalars['Boolean'];
  addUserCareEligibilitySubmission: ContinuityOfCareEligibilityResult;
  adminAcceptTermsOfService: Scalars['Boolean'];
  adminAuthenticate: PasswordAuthResult;
  adminBeginUpload: SignedS3Upload;
  adminCancelAppointment: CalendarEvent;
  adminCompleteOrganizationPhotoUpload: Scalars['Boolean'];
  adminCompleteProviderPhotoUpload: Provider;
  adminCompleteUpload: Upload;
  adminContinuityOfCareExport: AsynchronousJob;
  adminCreateCareTransition: ContinuityOfCareModel;
  adminCreateNoteExport: AsynchronousJob;
  adminCreateStaff: Provider;
  adminCreateTestUser: User;
  adminCreateUser: User;
  adminDeleteUpload: Scalars['Boolean'];
  adminDisableTwoFactor: Scalars['Boolean'];
  adminEditSafetyPlan: SafetyPlan;
  adminEditUser: AdminEditUserResult;
  adminEndCall: Scalars['Boolean'];
  adminEnterSecondFactor: TwoFactorAuthResult;
  adminMarkNoShow: Scalars['Boolean'];
  adminMarkTaskDone: Scalars['Boolean'];
  adminMarkUpcoming: Scalars['Boolean'];
  adminModifyAppointment: CalendarEvent;
  adminPasswordlessLogin: Scalars['String'];
  adminRescheduleAppointment: CalendarEvent;
  adminResetProviderPassword: Scalars['Boolean'];
  adminScheduleAppointment: CalendarEvent;
  /** Send a two factor code for the authenticated provider (use for verification) */
  adminSendTwoFactorCode: Scalars['Boolean'];
  adminSetPharmacyOnPatient: Scalars['Boolean'];
  adminSetTwoFactorConfig: Scalars['Boolean'];
  adminSubmitAssessment: AssessmentResponse;
  adminTransitionForCarePeriod: Scalars['Boolean'];
  adminTriggerProviderPasswordlessFlow: Scalars['Boolean'];
  adminUndoTaskDone: Scalars['Boolean'];
  adminUpdateAllocationsForDate: Scalars['Boolean'];
  adminUpdateProvider: Provider;
  adminUpdateScreenUserResult: Scalars['Boolean'];
  adminUpdateStateAllocationsForDate: Scalars['Boolean'];
  adminUpdateUpload: Scalars['Boolean'];
  adminVerifyTwoFactorPhone: Scalars['String'];
  answerSkillReflectionQuestion: Scalars['Boolean'];
  applyCoupon?: Maybe<ServiceCoupon>;
  assignCoachToEmbedPatient: Scalars['Boolean'];
  assignSkillToUser: AssignSkillToUserResponse;
  autoApplyCoupons: Array<ServiceCoupon>;
  beginEmailVerification: Scalars['Boolean'];
  beginSponsoredCareReenrollment: Scalars['Boolean'];
  beginUpload: SignedS3Upload;
  cancelAppointment: Scalars['Boolean'];
  cancelHealthieAppointment: Scalars['Boolean'];
  cancelSponsoredCareReenrollment: Scalars['Boolean'];
  changeProviderPassword: Scalars['Boolean'];
  changeStatusOfClaim: InsuranceClaimEvent;
  checkIsBlurry: Scalars['Boolean'];
  clearProviderLockout: Scalars['Boolean'];
  completeAppointmentCheckin: CalendarEvent;
  completePendingTransition: Scalars['Boolean'];
  completeUpload: Upload;
  consumeCoachingRecommendation: Scalars['Boolean'];
  consumeTherapyRecommendation: Scalars['Boolean'];
  convertPatientCare: User;
  createConnectNowPreEncounter: Scalars['Boolean'];
  createContract: CreateContractResult;
  createDataSharingRequest: DataSharingRequest;
  createHubFeedback: Scalars['Boolean'];
  createNoteForClaim: InsuranceClaimEvent;
  createOnDemandEncounter: Scalars['Boolean'];
  createOrganization: Organization;
  createOrganizationAnnouncement: OrganizationAnnouncement;
  createOrUpdateOrganizationResource: OrganizationResource;
  createOrUpdatePayer: Payer;
  createPartnerReferral: Scalars['Boolean'];
  createProvider: Provider;
  createTestVideoSession: TestVideoSession;
  createTicket: Ticket;
  createUserFirstStep: User;
  createZendeskTicket: Scalars['Boolean'];
  debugImpersonateAsUser: Scalars['String'];
  debugLoginAsUser: Scalars['String'];
  /** Internal debug tooling */
  debugModifyDataSharingConsent: DataSharingConsent;
  /** Internal debug tooling */
  debugModifyDataSharingRequest: DataSharingRequest;
  decoupleOrgEntitlementsFromParent: Scalars['Boolean'];
  deleteEmergencyContact: Contact;
  deleteInsuranceCredential: Scalars['Boolean'];
  deleteMedicalNote: Scalars['Boolean'];
  deleteOrganizationResource: Scalars['Boolean'];
  deleteOutreachAttempt: Scalars['Boolean'];
  deleteUserCareEligibilitySubmission: Scalars['Boolean'];
  deleteUserContact: Scalars['Boolean'];
  deleteZendeskUpload: Scalars['Boolean'];
  disableDevice: Scalars['Boolean'];
  dismissNotification: Scalars['Boolean'];
  dropInsurance: Scalars['Boolean'];
  editContractScope: EditContractScopeResult;
  editContractWeeks: EditContractWeeksResult;
  editOrganizationAnnouncement: Scalars['Boolean'];
  editOrganizationHandbook: Scalars['Boolean'];
  editSafetyPlan: SafetyPlan;
  /** null if current provider gives up permission to view ticket */
  editTicket?: Maybe<Ticket>;
  emailUnsubscribe: Scalars['Boolean'];
  facultyHubReferral: FacultyHubReferralResult;
  findCareFinish: Scalars['Boolean'];
  finishProviderPasswordReset: Scalars['Boolean'];
  /** @deprecated Legacy reset password flow. */
  finishResetPassword: Scalars['Boolean'];
  finishSkillReflection: FinishSkillReflectionResponse;
  /** @deprecated use pushAssessment instead */
  forceNextScalesToNow: Scalars['Boolean'];
  forgotProviderPassword: Scalars['Boolean'];
  /** returns unsubmitted note of key for user or creates a new one */
  getOrCreateNote: MedicalNote;
  hubUserAcceptTerms: Scalars['Boolean'];
  hubUserOnboard: HubUserOnboardResult;
  impersonate: Scalars['String'];
  initializeStudent: User;
  /** @deprecated Replaced with loginToHub mutation. */
  loginHub: LoginHubResult;
  loginToHub: LoginToHubResult;
  loginUserWithPassword: PasswordAuthResult;
  logoutHub: Scalars['Boolean'];
  logoutProvider: Scalars['Boolean'];
  logoutUser: Scalars['Boolean'];
  markCareTeamChannelsAsRead: Scalars['Boolean'];
  markEmbedPatientCoachingInactive: Scalars['Boolean'];
  markHealthieAppointmentForCancellation: Scalars['Boolean'];
  /** @deprecated Use hubUserOnboard resolver instead. */
  markHubUserOnboarded: Scalars['Boolean'];
  markReferralComplete: Scalars['Boolean'];
  markSkillAssignmentSeenByAssigner: Scalars['Boolean'];
  markSkillComplete: Scalars['Boolean'];
  markSkillStarted: Scalars['Boolean'];
  minorConsentApproveUser: Scalars['Boolean'];
  minorConsentOverrideUser: Scalars['Boolean'];
  networkSearchAccommodationRequest: Scalars['Boolean'];
  notifyOrganizationCrisisTeam: Scalars['Boolean'];
  passwordlessLogin: PasswordlessLoginResult;
  patientMarkAsRead: Scalars['Boolean'];
  patientMessageProvider: MessageV2;
  preLoginOrPasswordLogin: PreLoginOrPasswordLoginResult;
  preSignUp: PreSignUpResult;
  providerMarkPrivateChannelsAsRead: Scalars['Boolean'];
  providerMessagePatient: MessageV2;
  pushAssessment: Scalars['Boolean'];
  reactivateSelfForSponsoredCare: Scalars['Boolean'];
  reactivateUserCareFlow: UserCareFlow;
  reactivateUserForSponsoredCare: Scalars['Boolean'];
  recheckInsuranceEligibility: Scalars['Boolean'];
  recoupleOrgEntitlementsToParent: Scalars['Boolean'];
  refreshProviderLogin: Scalars['String'];
  regenerateProviderPassword: Scalars['String'];
  registerDevice: Scalars['Boolean'];
  removeProviderFromCrisisTeams: Scalars['Boolean'];
  resendActivationEmail: HubResendActivationResult;
  /** Send a two factor code when attempting to authenticate */
  resendTwoFactorCode: Scalars['Boolean'];
  resetBadgeCount: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  resetSessionsForFlow: OrganizationCareFlow;
  resetSessionStartForUser: Scalars['Boolean'];
  resetUserPassword: Scalars['String'];
  resubmitClaim: Scalars['Boolean'];
  /** should be called from the onBlur callback for each question */
  saveNoteSections: Scalars['Boolean'];
  saveUserDemographics: Scalars['Boolean'];
  selectCareFlow: User;
  sendCareTeamMessage: MessageV2;
  sendCoachChatMessage: SendCoachChatMessageResult;
  sendDataSharingRequestReminder: Scalars['Boolean'];
  sendPasswordResetEmail: Scalars['Boolean'];
  setDataSharingConsent: Scalars['Boolean'];
  setFlaggedForOutreach: Scalars['Boolean'];
  setHubUserPreferredName: Scalars['Boolean'];
  setInsurance: InsuranceEligibility;
  setPasswordWithToken: SetPasswordWithTokenResult;
  setPharmacyOnPatient: Scalars['Boolean'];
  setProviderSubscribed: Scalars['Boolean'];
  setSafeOperatingHandbookUrl: Scalars['Boolean'];
  setSuggestedPharmacyId: Scalars['Boolean'];
  setUserInsurance: InsuranceEligibility;
  setUserSubscribed: Scalars['Boolean'];
  setUserVerified: Scalars['Boolean'];
  signUp: SignUpResult;
  skillPathwaySubmit: Scalars['Boolean'];
  skillReflectionDelete: Scalars['Boolean'];
  skillReflectionEditAnswer: Scalars['Boolean'];
  skipPharmacySelection: Scalars['Boolean'];
  softDeleteUser: Scalars['Boolean'];
  studentHubReferral: StudentHubReferralResult;
  submitAppointmentFeedback: Scalars['Boolean'];
  submitAppointmentIncident: Scalars['Boolean'];
  submitCoachingAssessment: Scalars['Boolean'];
  submitCoachingAssessmentV2: Scalars['Boolean'];
  submitIntakeAssessment: AssessmentResponse;
  submitMedicalHistory: Scalars['Boolean'];
  submitNote: MedicalNote;
  submitProviderFeedback: Scalars['Boolean'];
  submitRequestedAssessment: Scalars['Boolean'];
  submitSelfRefer1Screener: AssessmentResponse;
  swapPrimaryEmergencyContact: Contact;
  syncHealthieProvider: Scalars['Boolean'];
  toggleOrganizationOnHold: Organization;
  /** Track events from the client */
  track: Scalars['Boolean'];
  trackPushOpen: Scalars['Boolean'];
  triggerPasswordlessAuthFlow: Scalars['Boolean'];
  unassignSkillFromUser: Scalars['Boolean'];
  updateAppointmentTemplates: Scalars['Boolean'];
  updateCareFlowForUser: Scalars['Boolean'];
  updateCertifications: Array<ProviderCertification>;
  updateCoupon: ServiceCoupon;
  updateCrisisTeamHours: Scalars['Boolean'];
  updateEducation: Array<ProviderEducation>;
  updateEligibilityCriteria: Scalars['Boolean'];
  updateEntitlements: Scalars['Boolean'];
  /** @deprecated Does not return state errors - use updateHubUser2() instead. */
  updateHubUser: Scalars['Boolean'];
  updateHubUser2: UpdateHubUserResult;
  updateNotificationOutcome: Scalars['Boolean'];
  updateOrganization: Organization;
  updateOrganizationCareFlows: Array<OrganizationCareFlow>;
  updateOrganizationCarePeriods: Array<OrganizationCarePeriodModel>;
  updateOrganizationDedicatedGroupModelAllocations: Scalars['Boolean'];
  updateOrganizationDefaults: Scalars['Boolean'];
  updateOrganizations: Provider;
  updateOutreachAttempt: Scalars['Boolean'];
  updatePaymentMethod: PaymentMethod;
  updatePreferences: Scalars['Boolean'];
  updateProviderTraits: Scalars['Boolean'];
  updateQualifications: Array<ProviderQualification>;
  updateSamlConfiguration: SamlConfiguration;
  updateScreenUserResult: Scalars['Boolean'];
  updateSponsoredCareReenrollment: Scalars['Boolean'];
  updateUser2: UpdateUser2Result;
  upsertEmergencyContact: Contact;
  upsertInsuranceCredential: ProviderInsuranceCredential;
  upsertSuggestedProviders: Scalars['Boolean'];
  upsertUserContact: Contact;
  upsertUserSkillFeedback: Scalars['Boolean'];
  userCreateCareTransition: Scalars['Boolean'];
  userScheduleAppointment: CalendarEvent;
}

export type MutationActivateCrisisModeArgs = {
  appointmentId: Scalars['Float'];
};

export type MutationActivateSafetyPlanArgs = {
  userId: Scalars['Float'];
};

export type MutationAddProviderToCrisisTeamsArgs = {
  organizationIds: Array<Scalars['Float']>;
  phone: Scalars['String'];
  providerId: Scalars['Float'];
};

export type MutationAddSelfReferralEmailsArgs = {
  emails: Array<Scalars['String']>;
  organizationId: Scalars['Float'];
};

export type MutationAddUserCareEligibilitySubmissionArgs = {
  submission: ContinuityOfCareEligibilityInput;
};

export type MutationAdminAuthenticateArgs = {
  deviceToken?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationAdminBeginUploadArgs = {
  contentType: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
};

export type MutationAdminCancelAppointmentArgs = {
  id: Scalars['Float'];
  outOfPolicy?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdminCompleteOrganizationPhotoUploadArgs = {
  key: Scalars['String'];
  organizationId: Scalars['Float'];
};

export type MutationAdminCompleteProviderPhotoUploadArgs = {
  key?: InputMaybe<Scalars['String']>;
  providerId: Scalars['Float'];
};

export type MutationAdminCompleteUploadArgs = {
  input: CreateFileUpload;
};

export type MutationAdminContinuityOfCareExportArgs = {
  userId: Scalars['Float'];
};

export type MutationAdminCreateCareTransitionArgs = {
  createTransition: CreateCareTransition;
};

export type MutationAdminCreateNoteExportArgs = {
  noteId: Scalars['Float'];
};

export type MutationAdminCreateStaffArgs = {
  input: CreateStaff;
};

export type MutationAdminCreateTestUserArgs = {
  args: CreateTestUser;
};

export type MutationAdminCreateUserArgs = {
  input: CreateUser;
  intakeAppointment?: InputMaybe<CreateIntake>;
};

export type MutationAdminDeleteUploadArgs = {
  uploadId: Scalars['Float'];
};

export type MutationAdminEditSafetyPlanArgs = {
  plan: SafetyPlanInput;
  userId: Scalars['Float'];
};

export type MutationAdminEditUserArgs = {
  editUser: EditUser;
};

export type MutationAdminEndCallArgs = {
  appointmentId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationAdminEnterSecondFactorArgs = {
  code: Scalars['String'];
  firstFactorToken: Scalars['String'];
};

export type MutationAdminMarkNoShowArgs = {
  appointmentId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationAdminMarkTaskDoneArgs = {
  taskId: Scalars['Float'];
};

export type MutationAdminMarkUpcomingArgs = {
  id: Scalars['Float'];
};

export type MutationAdminModifyAppointmentArgs = {
  end: Scalars['DateTime'];
  id: Scalars['Float'];
  start: Scalars['DateTime'];
};

export type MutationAdminPasswordlessLoginArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type MutationAdminRescheduleAppointmentArgs = {
  end: Scalars['DateTime'];
  id: Scalars['Float'];
  outOfPolicy?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['DateTime'];
};

export type MutationAdminResetProviderPasswordArgs = {
  id: Scalars['Float'];
};

export type MutationAdminScheduleAppointmentArgs = {
  appointmentType: Scalars['String'];
  end: Scalars['DateTime'];
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId: Scalars['Float'];
  start: Scalars['DateTime'];
  userId: Scalars['Float'];
};

export type MutationAdminSetPharmacyOnPatientArgs = {
  pharmacyId?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type MutationAdminSetTwoFactorConfigArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  phone: Scalars['String'];
  providerId?: InputMaybe<Scalars['Float']>;
};

export type MutationAdminSubmitAssessmentArgs = {
  assessment: AssessmentKey;
  questionnaires: Array<QuestionnaireSubmission>;
  userId: Scalars['Float'];
};

export type MutationAdminTransitionForCarePeriodArgs = {
  carePeriodId: Scalars['Float'];
};

export type MutationAdminTriggerProviderPasswordlessFlowArgs = {
  email: Scalars['String'];
};

export type MutationAdminUndoTaskDoneArgs = {
  taskId: Scalars['Float'];
};

export type MutationAdminUpdateAllocationsForDateArgs = {
  allocations: Array<CreateAllocationInput>;
  date: Scalars['String'];
  providerId: Scalars['Float'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
};

export type MutationAdminUpdateProviderArgs = {
  input: UpdateProvider;
};

export type MutationAdminUpdateScreenUserResultArgs = {
  input: ScreenUserResultInput;
  userId: Scalars['Float'];
};

export type MutationAdminUpdateStateAllocationsForDateArgs = {
  allocationsToCreate: Array<CreateProviderStateAllocationsModelInput>;
  allocationsToUpdate: Array<UpdateProviderStateAllocationsModelInput>;
};

export type MutationAdminUpdateUploadArgs = {
  input: UpdateFileUpload;
};

export type MutationAdminVerifyTwoFactorPhoneArgs = {
  code: Scalars['String'];
  phone: Scalars['String'];
};

export type MutationAnswerSkillReflectionQuestionArgs = {
  answer: Scalars['String'];
  question: Scalars['String'];
  questionKey: Scalars['String'];
  skillId: Scalars['String'];
};

export type MutationApplyCouponArgs = {
  code: Scalars['String'];
};

export type MutationAssignCoachToEmbedPatientArgs = {
  coachId: Scalars['String'];
  userId: Scalars['Int'];
};

export type MutationAssignSkillToUserArgs = {
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationAutoApplyCouponsArgs = {
  careType: CareType;
};

export type MutationBeginSponsoredCareReenrollmentArgs = {
  userId: Scalars['Float'];
};

export type MutationBeginUploadArgs = {
  contentType: Scalars['String'];
};

export type MutationCancelAppointmentArgs = {
  appointmentId: Scalars['Int'];
  reason?: InputMaybe<Scalars['String']>;
  reasonDetails?: InputMaybe<Scalars['String']>;
};

export type MutationCancelHealthieAppointmentArgs = {
  appointmentId: Scalars['String'];
};

export type MutationCancelSponsoredCareReenrollmentArgs = {
  userId: Scalars['Float'];
};

export type MutationChangeProviderPasswordArgs = {
  password: Scalars['String'];
};

export type MutationChangeStatusOfClaimArgs = {
  claimId: Scalars['Float'];
  details: Scalars['String'];
  status: InternalStatus;
};

export type MutationCheckIsBlurryArgs = {
  key: Scalars['String'];
  purpose: Scalars['String'];
};

export type MutationClearProviderLockoutArgs = {
  providerId: Scalars['Int'];
};

export type MutationCompleteAppointmentCheckinArgs = {
  appointmentId: Scalars['Int'];
};

export type MutationCompletePendingTransitionArgs = {
  careType: CareType;
};

export type MutationCompleteUploadArgs = {
  key: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  purpose: Scalars['String'];
};

export type MutationConsumeCoachingRecommendationArgs = {
  coachingProgramType?: InputMaybe<CoachingProgramType>;
};

export type MutationConvertPatientCareArgs = {
  careFlows: Array<UserCareFlowInput>;
  userId: Scalars['Float'];
};

export type MutationCreateConnectNowPreEncounterArgs = {
  data: ConnectNowPreEncounterInput;
};

export type MutationCreateContractArgs = {
  input: CreateContractInput;
};

export type MutationCreateDataSharingRequestArgs = {
  userId: Scalars['Float'];
};

export type MutationCreateHubFeedbackArgs = {
  feedback: Scalars['String'];
  url: Scalars['String'];
};

export type MutationCreateNoteForClaimArgs = {
  claimId: Scalars['Float'];
  note: Scalars['String'];
};

export type MutationCreateOnDemandEncounterArgs = {
  data: OnDemandEncounterInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganization;
};

export type MutationCreateOrganizationAnnouncementArgs = {
  input: CreateOrganizationAnnouncementInput;
};

export type MutationCreateOrUpdateOrganizationResourceArgs = {
  organizationId: Scalars['Int'];
  resource: OrganizationResourceInput;
};

export type MutationCreateOrUpdatePayerArgs = {
  params: UpdatePayerParams;
};

export type MutationCreatePartnerReferralArgs = {
  data: PartnerReferralInput;
};

export type MutationCreateProviderArgs = {
  careTypes: Array<CareType>;
  classification: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  funFact?: InputMaybe<Scalars['String']>;
  geoStates?: InputMaybe<Array<Scalars['String']>>;
  lastName: Scalars['String'];
  npi: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postNominalTitles?: InputMaybe<Scalars['String']>;
  withTwoFactor?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateTicketArgs = {
  ticket: CreateTicketInput;
};

export type MutationCreateUserFirstStepArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  newsletter?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MutationCreateZendeskTicketArgs = {
  attachmentTokens?: InputMaybe<Array<Scalars['String']>>;
  body: Scalars['String'];
  subject: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type MutationDebugImpersonateAsUserArgs = {
  reason: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationDebugLoginAsUserArgs = {
  userId: Scalars['Float'];
};

export type MutationDebugModifyDataSharingConsentArgs = {
  endDate: Scalars['DateTime'];
  id: Scalars['Float'];
  revokedAt?: InputMaybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type MutationDebugModifyDataSharingRequestArgs = {
  date: Scalars['DateTime'];
  id: Scalars['Float'];
  pending: Scalars['Boolean'];
};

export type MutationDecoupleOrgEntitlementsFromParentArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteEmergencyContactArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteInsuranceCredentialArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteMedicalNoteArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteOrganizationResourceArgs = {
  resourceId: Scalars['Int'];
};

export type MutationDeleteOutreachAttemptArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteUserCareEligibilitySubmissionArgs = {
  id: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationDeleteUserContactArgs = {
  id: Scalars['Float'];
};

export type MutationDeleteZendeskUploadArgs = {
  token: Scalars['String'];
};

export type MutationDisableDeviceArgs = {
  firebaseToken: Scalars['String'];
};

export type MutationDismissNotificationArgs = {
  id: Scalars['Float'];
};

export type MutationEditContractScopeArgs = {
  input: EditContractScopeInput;
};

export type MutationEditContractWeeksArgs = {
  input: EditContractWeeksInput;
};

export type MutationEditOrganizationAnnouncementArgs = {
  id: Scalars['Float'];
  input: EditOrganizationAnnouncementInput;
};

export type MutationEditOrganizationHandbookArgs = {
  input: EditOrganizationHandbookInput;
  organizationId: Scalars['Float'];
};

export type MutationEditSafetyPlanArgs = {
  plan: SafetyPlanInput;
};

export type MutationEditTicketArgs = {
  id: Scalars['Float'];
  ticket: EditTicketInput;
};

export type MutationEmailUnsubscribeArgs = {
  token: Scalars['String'];
};

export type MutationFacultyHubReferralArgs = {
  email: Scalars['String'];
};

export type MutationFindCareFinishArgs = {
  submissions: Array<HubAssessmentSubmission>;
};

export type MutationFinishProviderPasswordResetArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationFinishResetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationFinishSkillReflectionArgs = {
  discardReflection: Scalars['Boolean'];
  skillId: Scalars['String'];
};

export type MutationForceNextScalesToNowArgs = {
  userId: Scalars['Float'];
};

export type MutationForgotProviderPasswordArgs = {
  email: Scalars['String'];
};

export type MutationGetOrCreateNoteArgs = {
  key: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationHubUserOnboardArgs = {
  birthDate: Scalars['String'];
  preferredName?: InputMaybe<Scalars['String']>;
};

export type MutationImpersonateArgs = {
  providerToImpersonateId: Scalars['Float'];
  reason: Scalars['String'];
};

export type MutationInitializeStudentArgs = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  state: StateCodes;
  zip: Scalars['String'];
};

export type MutationLoginHubArgs = {
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type MutationLoginToHubArgs = {
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type MutationLoginUserWithPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationMarkCareTeamChannelsAsReadArgs = {
  channels: Array<Scalars['Float']>;
};

export type MutationMarkEmbedPatientCoachingInactiveArgs = {
  userId: Scalars['Int'];
};

export type MutationMarkHealthieAppointmentForCancellationArgs = {
  appointmentId: Scalars['String'];
  appointmentTypeId: Scalars['String'];
};

export type MutationMarkReferralCompleteArgs = {
  userId: Scalars['Float'];
};

export type MutationMarkSkillAssignmentSeenByAssignerArgs = {
  skillAssignmentId: Scalars['Float'];
};

export type MutationMarkSkillCompleteArgs = {
  input: SkillCompletionInputModel;
};

export type MutationMarkSkillStartedArgs = {
  input: SkillCompletionInputModel;
};

export type MutationMinorConsentApproveUserArgs = {
  userId: Scalars['Int'];
};

export type MutationMinorConsentOverrideUserArgs = {
  birthDate: Scalars['UserBirthdate'];
  userId: Scalars['Int'];
};

export type MutationNetworkSearchAccommodationRequestArgs = {
  accommodationRequestDetails: Scalars['String'];
};

export type MutationNotifyOrganizationCrisisTeamArgs = {
  crisisId: Scalars['String'];
};

export type MutationPasswordlessLoginArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type MutationPatientMarkAsReadArgs = {
  providerId: Scalars['Float'];
};

export type MutationPatientMessageProviderArgs = {
  attachmentIds?: InputMaybe<Array<Scalars['Float']>>;
  content: Scalars['String'];
  providerId: Scalars['Float'];
};

export type MutationPreLoginOrPasswordLoginArgs = {
  app: SamlUserApp;
  email: Scalars['String'];
  mrn?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type MutationPreSignUpArgs = {
  app: SamlUserApp;
  email: Scalars['String'];
};

export type MutationProviderMarkPrivateChannelsAsReadArgs = {
  channels: Array<Scalars['Float']>;
};

export type MutationProviderMessagePatientArgs = {
  attachmentIds?: InputMaybe<Array<Scalars['Float']>>;
  content: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationPushAssessmentArgs = {
  assessment: AssessmentKey;
  userId: Scalars['Float'];
};

export type MutationReactivateSelfForSponsoredCareArgs = {
  careType: CareType;
};

export type MutationReactivateUserCareFlowArgs = {
  careType: CareType;
  paymentSource?: InputMaybe<PaymentSource>;
  userId: Scalars['Float'];
};

export type MutationReactivateUserForSponsoredCareArgs = {
  careType: CareType;
  userId: Scalars['Float'];
};

export type MutationRecheckInsuranceEligibilityArgs = {
  userId: Scalars['Int'];
};

export type MutationRecoupleOrgEntitlementsToParentArgs = {
  id: Scalars['Float'];
};

export type MutationRegenerateProviderPasswordArgs = {
  providerId: Scalars['Int'];
};

export type MutationRegisterDeviceArgs = {
  applicationName: Scalars['String'];
  firebaseToken: Scalars['String'];
  platform: Scalars['String'];
};

export type MutationRemoveProviderFromCrisisTeamsArgs = {
  providerId: Scalars['Float'];
};

export type MutationResendActivationEmailArgs = {
  email: Scalars['String'];
};

export type MutationResendTwoFactorCodeArgs = {
  firstFactorToken: Scalars['String'];
};

export type MutationResetBadgeCountArgs = {
  counterName: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationResetSessionsForFlowArgs = {
  appointmentsAfter: Scalars['String'];
  careFlowId: Scalars['Int'];
};

export type MutationResetSessionStartForUserArgs = {
  appointmentsAfter?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['Float'];
};

export type MutationResetUserPasswordArgs = {
  userId: Scalars['Float'];
};

export type MutationResubmitClaimArgs = {
  noteId: Scalars['Int'];
};

export type MutationSaveNoteSectionsArgs = {
  sections: Array<MedicalNoteSectionInput>;
  uuid: Scalars['String'];
};

export type MutationSaveUserDemographicsArgs = {
  input: CreateUserDemographicsInput;
};

export type MutationSelectCareFlowArgs = {
  careFlows: Array<UserCareFlowInput>;
};

export type MutationSendCareTeamMessageArgs = {
  content: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationSendCoachChatMessageArgs = {
  coachingProgramId?: InputMaybe<Scalars['Float']>;
  content: Scalars['String'];
};

export type MutationSendDataSharingRequestReminderArgs = {
  userId: Scalars['Float'];
};

export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};

export type MutationSetDataSharingConsentArgs = {
  consent: Scalars['Boolean'];
  initials?: InputMaybe<Scalars['String']>;
};

export type MutationSetFlaggedForOutreachArgs = {
  userId: Scalars['Int'];
  value: Scalars['Boolean'];
};

export type MutationSetHubUserPreferredNameArgs = {
  preferredName: Scalars['String'];
};

export type MutationSetInsuranceArgs = {
  insurance: InsuranceDetailsInput;
};

export type MutationSetPasswordWithTokenArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  tokenId: Scalars['Float'];
};

export type MutationSetPharmacyOnPatientArgs = {
  pharmacyId?: InputMaybe<Scalars['Float']>;
};

export type MutationSetProviderSubscribedArgs = {
  providerId: Scalars['Int'];
  subscribed: Scalars['Boolean'];
};

export type MutationSetSafeOperatingHandbookUrlArgs = {
  organizationId: Scalars['Int'];
  url?: InputMaybe<Scalars['String']>;
};

export type MutationSetSuggestedPharmacyIdArgs = {
  organizationId: Scalars['Int'];
  pharmacyId?: InputMaybe<Scalars['Int']>;
};

export type MutationSetUserInsuranceArgs = {
  insurance: InsuranceDetailsInput;
  userId: Scalars['Int'];
};

export type MutationSetUserSubscribedArgs = {
  subscribed: Scalars['Boolean'];
  userId: Scalars['Int'];
};

export type MutationSetUserVerifiedArgs = {
  id: Scalars['Float'];
  verify: Scalars['Boolean'];
};

export type MutationSignUpArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  tokenId: Scalars['String'];
};

export type MutationSkillPathwaySubmitArgs = {
  answers: Array<SkillPathwayAnswer>;
};

export type MutationSkillReflectionDeleteArgs = {
  reflectionId: Scalars['Float'];
};

export type MutationSkillReflectionEditAnswerArgs = {
  answer: Scalars['String'];
  questionKey: Scalars['String'];
  reflectionId: Scalars['Float'];
};

export type MutationSoftDeleteUserArgs = {
  userId: Scalars['Float'];
};

export type MutationStudentHubReferralArgs = {
  email: Scalars['String'];
};

export type MutationSubmitAppointmentFeedbackArgs = {
  appointmentId: Scalars['Float'];
  feedback: AppointmentFeedbackInput;
};

export type MutationSubmitAppointmentIncidentArgs = {
  appointmentId: Scalars['Float'];
  incident: Scalars['String'];
};

export type MutationSubmitCoachingAssessmentArgs = {
  assessmentType: Scalars['String'];
  submissions: Array<HubAssessmentSubmission>;
};

export type MutationSubmitCoachingAssessmentV2Args = {
  assessmentType: CoachingAssessments;
  submissions: Array<HubAssessmentSubmission>;
};

export type MutationSubmitIntakeAssessmentArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
};

export type MutationSubmitMedicalHistoryArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
};

export type MutationSubmitNoteArgs = {
  sections: Array<MedicalNoteSectionInput>;
  uuid: Scalars['String'];
};

export type MutationSubmitProviderFeedbackArgs = {
  answers?: InputMaybe<Array<FeedbackAnswer>>;
  careType: CareType;
  feedbackType: FeedbackType;
  optedOut?: InputMaybe<Scalars['Boolean']>;
  providerId: Scalars['Int'];
};

export type MutationSubmitRequestedAssessmentArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
  requestId: Scalars['Float'];
};

export type MutationSubmitSelfRefer1ScreenerArgs = {
  questionnaires: Array<QuestionnaireSubmission>;
};

export type MutationSwapPrimaryEmergencyContactArgs = {
  id: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationSyncHealthieProviderArgs = {
  id: Scalars['Float'];
};

export type MutationToggleOrganizationOnHoldArgs = {
  isOnHold: Scalars['Boolean'];
  organizationId: Scalars['Float'];
};

export type MutationTrackArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  tag: Scalars['String'];
};

export type MutationTrackPushOpenArgs = {
  createdAt?: InputMaybe<Scalars['Float']>;
  messageId: Scalars['String'];
  templateId?: InputMaybe<Scalars['Float']>;
};

export type MutationTriggerPasswordlessAuthFlowArgs = {
  email: Scalars['String'];
};

export type MutationUnassignSkillFromUserArgs = {
  skillAssignmentId: Scalars['Float'];
  userId: Scalars['Float'];
};

export type MutationUpdateAppointmentTemplatesArgs = {
  organizationId: Scalars['Int'];
  templates: Array<AppointmentTemplateUpdate>;
};

export type MutationUpdateCareFlowForUserArgs = {
  careFlow: UserCareFlowInput;
  userId: Scalars['Float'];
};

export type MutationUpdateCertificationsArgs = {
  certifications: Array<ProviderCertificationInput>;
  providerId: Scalars['Float'];
};

export type MutationUpdateCouponArgs = {
  updateCoupon: UpdateCoupon;
};

export type MutationUpdateCrisisTeamHoursArgs = {
  crisisTeamHours: Array<CrisisTeamHoursInput>;
  organizationId: Scalars['Float'];
};

export type MutationUpdateEducationArgs = {
  education: Array<ProviderEducationInput>;
  providerId: Scalars['Float'];
};

export type MutationUpdateEligibilityCriteriaArgs = {
  criteria: Array<OrganizationEligibilityCriteriaInput>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateEntitlementsArgs = {
  entitlements: Array<UpdateOrganizationEntitlementInput>;
  organizationId: Scalars['Float'];
};

export type MutationUpdateHubUserArgs = {
  updates: HubUserUpdateInput;
};

export type MutationUpdateHubUser2Args = {
  updates: HubUserUpdateInput;
};

export type MutationUpdateNotificationOutcomeArgs = {
  emailId?: InputMaybe<Scalars['Int']>;
  outcome: Scalars['String'];
  smsId?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateOrganizationArgs = {
  update: UpdateOrganization;
};

export type MutationUpdateOrganizationCareFlowsArgs = {
  careFlows: Array<OrganizationCareFlowInput>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateOrganizationCarePeriodsArgs = {
  editedPeriods: Array<EditOrganizationCarePeriod>;
  newPeriods: Array<NewOrganizationCarePeriod>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateOrganizationDedicatedGroupModelAllocationsArgs = {
  editedOrganizationDedicatedGroupModelAllocations: Array<OrganizationDedicatedGroupModelAllocationInput>;
  organizationId: Scalars['Int'];
};

export type MutationUpdateOrganizationDefaultsArgs = {
  overrides: Array<OrganizationOverrides>;
};

export type MutationUpdateOrganizationsArgs = {
  organizationIds: Array<Scalars['Float']>;
  providerId: Scalars['Float'];
};

export type MutationUpdateOutreachAttemptArgs = {
  attempt: OutreachAttemptInput;
};

export type MutationUpdatePaymentMethodArgs = {
  pmToken: Scalars['String'];
  pmType: Scalars['String'];
};

export type MutationUpdatePreferencesArgs = {
  preferences: Array<CareTeamPreference>;
};

export type MutationUpdateProviderTraitsArgs = {
  providerId: Scalars['Int'];
  traits: Array<ProviderTraitInput>;
};

export type MutationUpdateQualificationsArgs = {
  providerId: Scalars['Float'];
  qualifications: Array<ProviderQualificationInput>;
};

export type MutationUpdateSamlConfigurationArgs = {
  organizationId: Scalars['Int'];
  type: SamlConfigurationType;
  update: UpdateSamlConfiguration;
};

export type MutationUpdateScreenUserResultArgs = {
  input: ScreenUserResultInput;
};

export type MutationUpdateSponsoredCareReenrollmentArgs = {
  status: ReenrollmentStatus;
};

export type MutationUpdateUser2Args = {
  updates: UpdateUserInput;
};

export type MutationUpsertEmergencyContactArgs = {
  id?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  primary?: InputMaybe<Scalars['Boolean']>;
  relationship: Scalars['String'];
  userId: Scalars['Float'];
};

export type MutationUpsertInsuranceCredentialArgs = {
  credential: UpsertProviderInsuranceCredential;
};

export type MutationUpsertSuggestedProvidersArgs = {
  providerIds: Array<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type MutationUpsertUserContactArgs = {
  input: ContactUpsertInput;
};

export type MutationUpsertUserSkillFeedbackArgs = {
  additionalFeedback: Scalars['String'];
  questions: Array<SkillFeedbackQuestionModelInput>;
  rating: Scalars['Float'];
  skillId: Scalars['String'];
};

export type MutationUserCreateCareTransitionArgs = {
  careType: CareType;
  nextSteps: NextSteps;
};

export type MutationUserScheduleAppointmentArgs = {
  checkin: Scalars['Boolean'];
  end: Scalars['DateTime'];
  isFeeForServiceTime?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId: Scalars['Float'];
  reason?: InputMaybe<Scalars['String']>;
  reasonDetails?: InputMaybe<Scalars['String']>;
  rescheduleId?: InputMaybe<Scalars['Float']>;
  start: Scalars['DateTime'];
};

export interface NavigateFromHubToPatientPortalError {
  errorCode: NavigateFromHubToPatientPortalErrorCode;
  message: Scalars['String'];
}

export const NavigateFromHubToPatientPortalErrorCode = {
  VerifyEligibility: 'VerifyEligibility',
} as const;

export type NavigateFromHubToPatientPortalErrorCode =
  typeof NavigateFromHubToPatientPortalErrorCode[keyof typeof NavigateFromHubToPatientPortalErrorCode];
export type NavigateFromHubToPatientPortalResult =
  | NavigateFromHubToPatientPortalError
  | NavigateFromHubToPatientPortalSuccess;

export interface NavigateFromHubToPatientPortalSuccess {
  loginUrl: Scalars['String'];
}

export interface NewOrganizationCarePeriod {
  endDate: Scalars['String'];
  periodType: PeriodType;
  startDate: Scalars['String'];
}

export const NextSteps = {
  ActiveSelfPay: 'activeSelfPay',
  ActiveSponsored: 'activeSponsored',
  Cancel: 'cancel',
  OnHold: 'onHold',
} as const;

export type NextSteps = typeof NextSteps[keyof typeof NextSteps];
export const NextStepsFilter = {
  ActiveSponsored: 'ActiveSponsored',
  Cancelled: 'Cancelled',
  CompletedSelfPay: 'CompletedSelfPay',
  Na: 'NA',
  OnHold: 'OnHold',
  PendingSelfPay: 'PendingSelfPay',
} as const;

export type NextStepsFilter = typeof NextStepsFilter[keyof typeof NextStepsFilter];
export interface NumericKeyValue {
  key: Scalars['ID'];
  value: Scalars['Float'];
}

export interface OnDemandEncounterInput {
  alternativeCare: AlternativeCare;
  currentLocation: Scalars['String'];
  distressLevel: Scalars['Int'];
  mainConcern: MainConcerns;
  otherAlternativeCare?: InputMaybe<Scalars['String']>;
  otherMainConcern?: InputMaybe<Scalars['String']>;
  preferredPhoneNumber: Scalars['String'];
  suicidalThoughts: SuicidalThoughts;
}

export interface OnDemandReportsInput {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
}

export interface OnDemandSession {
  callStartTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  user: OnDemandSessionUser;
}

export interface OnDemandSessionOrganization {
  id: Scalars['Float'];
  name: Scalars['String'];
}

export interface OnDemandSessionUser {
  customerId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  organization: OnDemandSessionOrganization;
}

export interface Option {
  key?: Maybe<Scalars['String']>;
  requiresManualInput?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
}

export interface Organization {
  abbreviation: Scalars['String'];
  activityStatus: Scalars['String'];
  appointmentTemplate: AppointmentTemplate;
  appointmentTemplates: Array<AppointmentTemplate>;
  careFlows: Array<OrganizationCareFlow>;
  carePeriods: Array<OrganizationCarePeriodModel>;
  children: Array<Organization>;
  childrenCount: Scalars['Int'];
  crisisHours: Array<CrisisTeamHours>;
  crisisHoursActive: Scalars['Boolean'];
  crisisTeamProviders: Array<Provider>;
  currentCarePeriodUtilization?: Maybe<UtilizationVirtualModel>;
  currentCrisisHours?: Maybe<CrisisTeamHours>;
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
  director?: Maybe<Provider>;
  eligibilityCriteria: Array<OrganizationEligibilityCriteriaModel>;
  emailDomains: Array<Scalars['String']>;
  emergencyResources: Array<OrganizationResource>;
  entitlements: Array<OrganizationEntitlement>;
  highRiskUsers: Array<User>;
  id: Scalars['Int'];
  latestAssessments: Array<AssessmentModel>;
  latestCarePeriod?: Maybe<OrganizationCarePeriodModel>;
  latestNotes: Array<MedicalNote>;
  logo?: Maybe<Upload>;
  missedAppointments: Array<CalendarEvent>;
  name: Scalars['String'];
  nextAvailableTimeSlots: Array<Availability>;
  organizationUtilization?: Maybe<OrganizationUtilizationVirtualModel>;
  parent?: Maybe<Organization>;
  providers: Array<Provider>;
  providersWithParent: Array<Provider>;
  referralCredits?: Maybe<OrganizationReferralCreditOverview>;
  reporting: OrganizationReport;
  resources: Array<OrganizationResource>;
  resourcesUpdatedAt?: Maybe<Scalars['DateTime']>;
  resourcesUpdatedBy?: Maybe<Provider>;
  safeOperatingHandbook: OrganizationHandbook;
  safeOperatingHandbookUrl?: Maybe<Scalars['String']>;
  safetyPlans: Array<SafetyPlan>;
  samlConfiguration?: Maybe<SamlConfiguration>;
  suggestedPharmacy?: Maybe<Pharmacy>;
  suicidalRiskUsers: Array<User>;
  type: Scalars['String'];
  usersWithOnlyIncompleteReferrals: Array<User>;
  usersWithoutReferrals: Array<User>;
  usesDedicatedGroupModel: Scalars['Boolean'];
}

export type OrganizationAppointmentTemplateArgs = {
  careType: CareType;
};

export type OrganizationLatestAssessmentsArgs = {
  limit: Scalars['Float'];
};

export type OrganizationLatestNotesArgs = {
  key?: InputMaybe<Scalars['String']>;
  limit: Scalars['Float'];
};

export type OrganizationNextAvailableTimeSlotsArgs = {
  appointmentType: Scalars['String'];
  endTime?: InputMaybe<Scalars['String']>;
};

export type OrganizationProvidersArgs = {
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type OrganizationProvidersWithParentArgs = {
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type OrganizationReportingArgs = {
  carePeriodId?: InputMaybe<Scalars['Float']>;
  referralSource?: InputMaybe<ReferralSource>;
};

export type OrganizationSafetyPlansArgs = {
  complete?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationSamlConfigurationArgs = {
  type: SamlConfigurationType;
};

export interface OrganizationAnnouncement {
  createdAt: Scalars['DateTime'];
  createdByProviderName: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  inCurrentCarePeriod: Scalars['Boolean'];
  title: Scalars['String'];
}

export interface OrganizationCareFlow {
  canMcpUsersRefer: Scalars['Boolean'];
  canReferrerUsersRefer: Scalars['Boolean'];
  canSelfRefer: Scalars['Boolean'];
  careType: CareType;
  defaultPaymentSource: PaymentSource;
  hideSessionCount: Scalars['Boolean'];
  id: Scalars['Int'];
  referralRestrictedStates: Array<StateCodes>;
  sponsoredSessions?: Maybe<Scalars['Float']>;
  sponsoredSessionsLimitType: SponsoredSessionsLimitType;
  statesAvailable: Array<StateCodes>;
}

export interface OrganizationCareFlowInput {
  canMcpUsersRefer: Scalars['Boolean'];
  canReferrerUsersRefer: Scalars['Boolean'];
  canSelfRefer: Scalars['Boolean'];
  careType: CareType;
  defaultPaymentSource: PaymentSource;
  hideSessionCount: Scalars['Boolean'];
  referralRestrictedStates: Array<StateCodes>;
  sponsoredSessions?: InputMaybe<Scalars['Float']>;
  sponsoredSessionsLimitType: SponsoredSessionsLimitType;
}

export interface OrganizationCarePeriodModel {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  organizationId: Scalars['Int'];
  periodType: PeriodType;
  startDate: Scalars['String'];
}

export interface OrganizationDedicatedGroupModelAllocationInput {
  id: Scalars['Int'];
  psychiatryMaxHours?: InputMaybe<Scalars['Float']>;
  psychiatryMaxIntakeHours?: InputMaybe<Scalars['Int']>;
  therapyMaxHours?: InputMaybe<Scalars['Float']>;
  therapyMaxIntakeHours?: InputMaybe<Scalars['Int']>;
}

export interface OrganizationEligibilityCriteriaInput {
  minimumAge?: InputMaybe<Scalars['Float']>;
  permittedCareTypes?: InputMaybe<Array<CareType>>;
  states?: InputMaybe<Array<Scalars['String']>>;
}

export interface OrganizationEligibilityCriteriaModel {
  id: Scalars['Int'];
  minimumAge?: Maybe<Scalars['Float']>;
  permittedCareTypes?: Maybe<Array<CareType>>;
  states?: Maybe<Array<Scalars['String']>>;
}

export interface OrganizationEntitlement {
  config: Scalars['JSON'];
  id: Scalars['Int'];
  key: Entitlement;
}

export interface OrganizationEntitlementInput {
  config?: InputMaybe<Scalars['JSON']>;
  key: Entitlement;
}

export interface OrganizationHandbook {
  campusCrisisTeamInProduct: Scalars['String'];
  campusSafetyPoliceNumber: Scalars['String'];
  coPayChargedByPartner: Scalars['String'];
  defaultTherapyCadence: Scalars['String'];
  ekgsNearCampus: Scalars['String'];
  fullADHDEvaluation: Scalars['String'];
  hasManualReactivationEntitlement: Scalars['Boolean'];
  highLevelProtocolManagingEmergency: Scalars['String'];
  hospitalization: Scalars['String'];
  labsNearCampus: Scalars['String'];
  mainPointOfContactCrisis: Scalars['String'];
  noShowsCountForSessionLimits: Scalars['String'];
  organizationId: Scalars['Int'];
  protocolForMedicalLeaveDoc: Scalars['String'];
  requestingAcademicAccomodations: Scalars['String'];
  sessionLimitsRenew: Scalars['String'];
  summerBreakEligibility: Scalars['String'];
  supportingClinician: Scalars['String'];
  vitalsNearCampus: Scalars['String'];
  winterBreakEligibility: Scalars['String'];
}

export interface OrganizationOption {
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface OrganizationOverrides {
  key: Scalars['String'];
  organizationId: Scalars['Float'];
  value: Scalars['JSON'];
}

export interface OrganizationReferralCreditOverview {
  availableCredits: Scalars['Int'];
  psychiatryCreditWeight: Scalars['Int'];
  referralPeriodEnd?: Maybe<Scalars['DateTime']>;
  therapyCreditWeight: Scalars['Int'];
}

export interface OrganizationReport {
  activeStudentsCount: Scalars['Float'];
  /** Average Time from Referral to First Visit (Seconds) */
  averageReferralToVisitTime?: Maybe<Scalars['Float']>;
  careTeamReferrals: Array<CareTeamReferral>;
  diagnosesBreakdown: Array<UserDiagnosis>;
  patientMonitoring: Array<User>;
  referralCompletionRate?: Maybe<Scalars['Float']>;
  scope: ReportScope;
  visitAttendance: VisitAttendance;
  visitSatisfactionRatings: SatisfactionRating;
}

export interface OrganizationResource {
  contact?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  resourceSubtype?: Maybe<Scalars['String']>;
  resourceType: ResourceType;
  showAsCrisisResource: Scalars['Boolean'];
  tags: Array<ResourceTag>;
  website?: Maybe<Scalars['String']>;
}

export interface OrganizationResourceInput {
  contact?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  resourceSubtype?: InputMaybe<Scalars['String']>;
  resourceType: ResourceType;
  showAsCrisisResource?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<ResourceTag>>;
  website?: InputMaybe<Scalars['String']>;
}

/** All possible organization types */
export const OrganizationType = {
  University: 'University',
} as const;

export type OrganizationType = typeof OrganizationType[keyof typeof OrganizationType];
export interface OrganizationUtilizationVirtualModel {
  carePeriodUtilizations: Array<UtilizationVirtualModel>;
}

export interface OrganizationUtilizationWeek {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  organizationId: Scalars['Int'];
  psychiatryHours: UtilizationHours;
  startDate: Scalars['String'];
  therapyHours: UtilizationHours;
}

export interface OutreachAttemptInput {
  attemptNumber?: InputMaybe<Scalars['Int']>;
  createdByCustom?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  methods: Array<Scalars['String']>;
  outcome: Scalars['String'];
  subject: Scalars['String'];
  userId: Scalars['Int'];
  zendeskTicketId?: InputMaybe<Scalars['Int']>;
}

export interface OutreachAttemptModel {
  attemptNumber?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Provider>;
  createdByCustom?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  methods: Array<Scalars['String']>;
  outcome: Scalars['String'];
  subject: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  zendeskTicketId?: Maybe<Scalars['Int']>;
}

export interface PaginatedConnectNowSession {
  count?: Maybe<Scalars['Float']>;
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<ConnectNowSession>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PaginatedIopReferralInput {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  partner: ReferralPartners;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
}

export interface PaginatedIopReferrals {
  count: Scalars['Float'];
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<IopReferralModel>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PaginatedOnDemandSession {
  count?: Maybe<Scalars['Float']>;
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<OnDemandSession>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PaginatedUsers {
  count: Scalars['Float'];
  cursor: Scalars['String'];
  hasMore: Scalars['Boolean'];
  items: Array<User>;
  limitCountExceeded?: Maybe<Scalars['Boolean']>;
}

export interface PartnerReferralInput {
  birthDate: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organizationId?: InputMaybe<Scalars['Float']>;
  partner: ReferralPartners;
  preferredName?: InputMaybe<Scalars['String']>;
  providerId: Scalars['Float'];
  referralEmail?: InputMaybe<Scalars['String']>;
  referralSource: ReferralSource;
  userId?: InputMaybe<Scalars['Float']>;
}

export interface PasswordAuthResult {
  authToken?: Maybe<Scalars['String']>;
  blockedReason?: Maybe<Scalars['String']>;
  firstFactorResult?: Maybe<FirstFactorResult>;
  passwordCorrect: Scalars['Boolean'];
  twoFactorNeeded: Scalars['Boolean'];
  useOtherApp?: Maybe<UseOtherApp>;
}

export interface PasswordlessLoginResult {
  activationLinkExpired?: Maybe<Scalars['Boolean']>;
  authToken?: Maybe<Scalars['String']>;
  canLogin?: Maybe<Scalars['Boolean']>;
}

export interface PatientChannel {
  channel: Channel;
  id: Scalars['Float'];
  user: User;
}

export interface PatientDetail {
  breakLine?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}

export const PatientRelationshipToSubscriber = {
  CadaverDonor: 'CadaverDonor',
  Child: 'Child',
  ChildWhereInsuredHasNoFinancialResponsibility: 'ChildWhereInsuredHasNoFinancialResponsibility',
  EmancipatedMinor: 'EmancipatedMinor',
  Employee: 'Employee',
  Father: 'Father',
  FosterChild: 'FosterChild',
  Grandchild: 'Grandchild',
  Grandparent: 'Grandparent',
  HandicappedDependent: 'HandicappedDependent',
  InjuredPlaintiff: 'InjuredPlaintiff',
  LifePartner: 'LifePartner',
  Mother: 'Mother',
  NieceOrNephew: 'NieceOrNephew',
  OrganDonor: 'OrganDonor',
  Other: 'Other',
  Self: 'Self',
  SignificantOther: 'SignificantOther',
  SponsoredDependent: 'SponsoredDependent',
  Spouse: 'Spouse',
  StepsonOrStepdaughter: 'StepsonOrStepdaughter',
  Unknown: 'Unknown',
  WardOfTheCourt: 'WardOfTheCourt',
} as const;

export type PatientRelationshipToSubscriber =
  typeof PatientRelationshipToSubscriber[keyof typeof PatientRelationshipToSubscriber];
export interface Payer {
  billToProviderName: Scalars['Boolean'];
  id: Scalars['Float'];
  inNetworkStates: Array<Scalars['String']>;
  name: Scalars['String'];
  standardPayerId: Scalars['String'];
  stateAddressOverrides: Array<PayerStateAddressOverride>;
  tradingPartnerId: Scalars['String'];
  useAddressForAllStates?: Maybe<Scalars['String']>;
  visible: Scalars['Boolean'];
}

export interface PayerStateAddressOverride {
  id: Scalars['Int'];
  overrideForState: StateCodes;
  stateToUseInstead: StateCodes;
}

export interface PayerStateAddressOverrideInput {
  overrideForState: StateCodes;
  stateToUseInstead: StateCodes;
}

export interface PaymentMethod {
  id: Scalars['String'];
}

export interface PaymentProfile {
  cardType: Scalars['String'];
  expirationDate: Scalars['String'];
  id: Scalars['String'];
  lastFour: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
}

/** Supported Payment Sources */
export const PaymentSource = {
  Insurance: 'Insurance',
  OonInsurance: 'OONInsurance',
  Organization: 'Organization',
  SelfPay: 'SelfPay',
} as const;

export type PaymentSource = typeof PaymentSource[keyof typeof PaymentSource];
export interface PaymentSubscription {
  id: Scalars['String'];
  nextInvoice: Scalars['Float'];
}

export const PaymentType = {
  SelfPay: 'selfPay',
  Sponsored: 'sponsored',
} as const;

export type PaymentType = typeof PaymentType[keyof typeof PaymentType];
export const PeriodType = {
  NonSponsoredBreak: 'nonSponsoredBreak',
  NonSponsoredTerm: 'nonSponsoredTerm',
  SponsoredBreak: 'sponsoredBreak',
  SponsoredTerm: 'sponsoredTerm',
} as const;

export type PeriodType = typeof PeriodType[keyof typeof PeriodType];
export const Permission = {
  AppointmentCancel: 'appointmentCancel',
  AppointmentCancelAll: 'appointmentCancelAll',
  AppointmentEdit: 'appointmentEdit',
  AppointmentView: 'appointmentView',
  AssessmentPush: 'assessmentPush',
  CampusTeamEdit: 'campusTeamEdit',
  CareDashboards: 'careDashboards',
  ContractCreate: 'contractCreate',
  ContractEdit: 'contractEdit',
  ContractView: 'contractView',
  CrisisTeamEdit: 'crisisTeamEdit',
  CrisisTeamEngage: 'crisisTeamEngage',
  DedicatedGroupModelAllocationEdit: 'dedicatedGroupModelAllocationEdit',
  Dev: 'dev',
  GeneratePassword: 'generatePassword',
  Impersonate: 'impersonate',
  MantraAdmin: 'mantraAdmin',
  NoteCreate: 'noteCreate',
  NoteCreateReferral: 'noteCreateReferral',
  NoteCreateText: 'noteCreateText',
  NoteExport: 'noteExport',
  OrderLabs: 'orderLabs',
  OrganizationResourcesEdit: 'organizationResourcesEdit',
  OrganizationViewAll: 'organizationViewAll',
  PatientCreate: 'patientCreate',
  PatientEdit: 'patientEdit',
  PatientEditCareFlows: 'patientEditCareFlows',
  PatientEditProviders: 'patientEditProviders',
  PatientList: 'patientList',
  PatientMessage: 'patientMessage',
  PatientVideo: 'patientVideo',
  Phi: 'phi',
  Pii: 'pii',
  Prescribe: 'prescribe',
  ProviderAvailabilityEdit: 'providerAvailabilityEdit',
  ProviderCreate: 'providerCreate',
  ProviderDelete: 'providerDelete',
  ProviderEdit: 'providerEdit',
  ProviderList: 'providerList',
  ProviderMessage: 'providerMessage',
  ProviderMessageViewAll: 'providerMessageViewAll',
  ProviderPasswordReset: 'providerPasswordReset',
  ProviderView: 'providerView',
  ProviderViewEmail: 'providerViewEmail',
  ProviderViewPhone: 'providerViewPhone',
  ReferralsShowOrg: 'referralsShowOrg',
  ReferralsView: 'referralsView',
  Reporting: 'reporting',
  SafetyPlanActivate: 'safetyPlanActivate',
  SafetyPlanEdit: 'safetyPlanEdit',
  SponsoredCareReenrollment: 'sponsoredCareReenrollment',
  TicketCreate: 'ticketCreate',
  TicketView: 'ticketView',
  TicketViewAll: 'ticketViewAll',
  UtilizationView: 'utilizationView',
} as const;

export type Permission = typeof Permission[keyof typeof Permission];
/** Pharmacy data model from Dosespot. */
export interface Pharmacy {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  default?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  pharmacyId: Scalars['Float'];
  primary?: Maybe<Scalars['Boolean']>;
  primaryFax?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  serviceLevel?: Maybe<Scalars['Float']>;
  state: Scalars['String'];
  storeName: Scalars['String'];
  zipCode: Scalars['String'];
}

export const PortalKey = {
  Coaching: 'coaching',
  ConnectNow: 'connectNow',
  ExecutiveFunctioning: 'executiveFunctioning',
  Hub: 'hub',
  Loca: 'loca',
  LocaWellness: 'locaWellness',
  NonLocaTherapyGateway: 'nonLocaTherapyGateway',
  Odes: 'odes',
  Psychiatry: 'psychiatry',
  Skills: 'skills',
  Therapy: 'therapy',
  Togetherall: 'togetherall',
} as const;

export type PortalKey = typeof PortalKey[keyof typeof PortalKey];
export interface PreLoginOrPasswordLoginError {
  errorCode: PreLoginOrPasswordLoginErrorCode;
  message: Scalars['String'];
}

export const PreLoginOrPasswordLoginErrorCode = {
  CouldNotLogin: 'CouldNotLogin',
  Deactivated: 'Deactivated',
  PasswordRequired: 'PasswordRequired',
} as const;

export type PreLoginOrPasswordLoginErrorCode =
  typeof PreLoginOrPasswordLoginErrorCode[keyof typeof PreLoginOrPasswordLoginErrorCode];
export interface PreLoginOrPasswordLoginPassword {
  passwordRequired: Scalars['Boolean'];
}

export interface PreLoginOrPasswordLoginRedirect {
  externalRedirect: Scalars['String'];
}

export type PreLoginOrPasswordLoginResult =
  | PreLoginOrPasswordLoginError
  | PreLoginOrPasswordLoginPassword
  | PreLoginOrPasswordLoginRedirect
  | PreLoginOrPasswordLoginSaml
  | PreLoginOrPasswordLoginSuccess;

export interface PreLoginOrPasswordLoginSaml {
  samlEntryPoint: Scalars['String'];
}

export interface PreLoginOrPasswordLoginSuccess {
  /** Mobile only */
  hasHubOnboarded?: Maybe<Scalars['Boolean']>;
  /** Mobile only */
  jwt?: Maybe<Scalars['String']>;
  loginSuccess: Scalars['Boolean'];
}

export interface Prescription {
  daysSupply?: Maybe<Scalars['Float']>;
  directions: Scalars['String'];
  dispenseUnitDescription: Scalars['String'];
  displayName: Scalars['String'];
  doseForm: Scalars['String'];
  genericProductName: Scalars['String'];
  info?: Maybe<MedicationInfo>;
  medicationStatus: MedicationStatus;
  prescriptionId: Scalars['Float'];
  quantity: Scalars['String'];
  rawName?: Maybe<Scalars['String']>;
  status: Scalars['Float'];
  strength: Scalars['String'];
  writtenDate: Scalars['String'];
}

export interface PreSignUpError {
  errorCode: PreSignUpErrorCode;
  message: Scalars['String'];
}

export const PreSignUpErrorCode = {
  CampusReferralRequired: 'CampusReferralRequired',
  EligibilityError: 'EligibilityError',
  UnknownPartner: 'UnknownPartner',
} as const;

export type PreSignUpErrorCode = typeof PreSignUpErrorCode[keyof typeof PreSignUpErrorCode];
export type PreSignUpResult = PreSignUpError | PreSignUpSsoRedirect | PreSignUpSuccess;

export interface PreSignUpSsoRedirect {
  samlEntryPoint: Scalars['String'];
}

export interface PreSignUpSuccess {
  successMessage: Scalars['String'];
}

export interface Prices {
  lateCancellationFee: Scalars['Int'];
  noShowFee: Scalars['Int'];
  psychiatryFollowUp: Scalars['Int'];
  psychiatryIntake: Scalars['Int'];
  therapyFollowUp: Scalars['Int'];
  therapyIntake: Scalars['Int'];
}

export interface PricesInCents {
  lateCancellationFeeInCents: Scalars['Int'];
  noShowFeeInCents: Scalars['Int'];
  psychiatryFollowUpPriceInCents: Scalars['Int'];
  psychiatryIntakePriceInCents: Scalars['Int'];
  therapyFollowUpPriceInCents: Scalars['Int'];
  therapyIntakePriceInCents: Scalars['Int'];
}

export interface Provider {
  /** don't use this in lists, it doesn't use a dataloader yet */
  acceptingNewPatients: Scalars['Boolean'];
  accountSecurityStatus: Scalars['String'];
  activityStatus?: Maybe<Scalars['String']>;
  /** oz, mcp, or referral */
  appView: AppView;
  careTypes: Array<CareType>;
  certifications: Array<ProviderCertification>;
  classification: Scalars['String'];
  degrees: Array<ProviderEducation>;
  dosespotId?: Maybe<Scalars['Float']>;
  eligibleForSchedulingPromotion: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  funFact: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  geoStates: Array<StateCodes>;
  guaranteedPay: Scalars['Boolean'];
  hasDosespot: Scalars['Boolean'];
  hasSso?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  insuranceCredentials: Array<ProviderInsuranceCredential>;
  lastName?: Maybe<Scalars['String']>;
  /** virtualized firstName + lastName */
  name: Scalars['String'];
  npi?: Maybe<Scalars['String']>;
  organizationCrisisTeams: Array<CrisisTeamRelationship>;
  organizations: Array<Organization>;
  patients: Array<User>;
  permissions: Array<Permission>;
  personalTitle?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  portrait?: Maybe<Upload>;
  postNominalTitles?: Maybe<Scalars['String']>;
  privateChannel: Channel;
  pronouns?: Maybe<Scalars['String']>;
  providerStates: Array<ProviderStateRelationShipModel>;
  providerType?: Maybe<Scalars['String']>;
  qualifications: Array<Scalars['String']>;
  qualifications2: Array<ProviderQualification>;
  role: Role;
  traits: Array<ProviderTrait>;
  twoFactorConfig?: Maybe<TwoFactorConfig>;
  unreadProviderMessagesCount: Scalars['Float'];
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  upcomingAvailability: Array<Availability>;
  upcomingAvailabilityV4: AvailabilityV4;
}

export type ProviderAcceptingNewPatientsArgs = {
  organizationId?: InputMaybe<Scalars['Float']>;
};

export type ProviderInsuranceCredentialsArgs = {
  forState?: InputMaybe<Scalars['String']>;
};

export type ProviderUpcomingAvailabilityArgs = {
  apptType: Scalars['String'];
  careType?: InputMaybe<CareType>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  patientState?: InputMaybe<StateCodes>;
};

export type ProviderUpcomingAvailabilityV4Args = {
  apptType: Scalars['String'];
  careType?: InputMaybe<CareType>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  patientState?: InputMaybe<StateCodes>;
};

export interface ProviderCertification {
  abbreviation: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
}

export interface ProviderCertificationInput {
  abbreviation: Scalars['String'];
  name: Scalars['String'];
}

export interface ProviderEducation {
  abbreviation: Scalars['String'];
  degree: Scalars['String'];
  id: Scalars['Float'];
  university: Scalars['String'];
}

export interface ProviderEducationInput {
  abbreviation: Scalars['String'];
  degree: Scalars['String'];
  university: Scalars['String'];
}

export interface ProviderFeedback {
  careType: CareType;
  createdAt: Scalars['DateTime'];
  optedOut?: Maybe<Scalars['Boolean']>;
  providerId: Scalars['Int'];
  questions?: Maybe<Array<FormSection>>;
  userId: Scalars['Int'];
}

export const ProviderGenderSearch = {
  Man: 'Man',
  NonBinary: 'NonBinary',
  TransgenderMan: 'TransgenderMan',
  TransgenderWoman: 'TransgenderWoman',
  Woman: 'Woman',
} as const;

export type ProviderGenderSearch = typeof ProviderGenderSearch[keyof typeof ProviderGenderSearch];
export interface ProviderIncident {
  appointment: CalendarEvent;
  createdAt: Scalars['DateTime'];
  incident: Scalars['String'];
}

export interface ProviderInOfficeAvailability {
  isOutOfOffice: Scalars['Boolean'];
  nextAvailableDate?: Maybe<Scalars['LocalDate']>;
}

export interface ProviderInsuranceCredential {
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  payer: Payer;
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface ProviderIsLoggedIn {
  authToken?: Maybe<Scalars['String']>;
  isLoggedIn: Scalars['Boolean'];
}

export interface ProviderNetworkResult {
  /** Number of providers out of payer network */
  outOfNetworkCount: Scalars['Float'];
  providers: Array<Provider>;
  /** The state code if the state is not allowed for the organization and care type. */
  stateNotAllowed?: Maybe<StateCodes>;
}

export interface ProviderQualification {
  abbreviation: Scalars['String'];
  id: Scalars['Float'];
  licences: Array<Scalars['String']>;
  name: Scalars['String'];
  states: Array<Scalars['String']>;
}

export interface ProviderQualificationInput {
  abbreviation: Scalars['String'];
  licences: Array<Scalars['String']>;
  name: Scalars['String'];
  states: Array<Scalars['String']>;
}

export const ProviderServiceBlockedReason = {
  PatientMissingGeoState: 'PatientMissingGeoState',
} as const;

export type ProviderServiceBlockedReason =
  typeof ProviderServiceBlockedReason[keyof typeof ProviderServiceBlockedReason];
export interface ProviderStateAllocationsModel {
  endDate: Scalars['String'];
  id: Scalars['Int'];
  maxHours: Scalars['Float'];
  maxIntakeHours: Scalars['Float'];
  providerState: ProviderStateRelationShipModel;
  providerStateId: Scalars['Int'];
  startDate: Scalars['String'];
}

export interface ProviderStateRelationShipModel {
  id: Scalars['Int'];
  provider: Provider;
  providerId: Scalars['Int'];
  state: StateCodes;
}

export interface ProviderTrait {
  category: TraitCategory;
  values: Array<Scalars['String']>;
}

export interface ProviderTraitInput {
  category: TraitCategory;
  values: Array<Scalars['String']>;
}

export interface Query {
  activeSubscriptions: Array<PaymentSubscription>;
  adminAppointment: CalendarEvent;
  adminAppointmentByCrisisId: MaybeBlockedCalendarEvents;
  adminAppointmentByUser: CalendarEvent;
  adminAppointments: Array<CalendarEvent>;
  adminAppointmentsToday: Array<CalendarEvent>;
  adminAssessment: Assessment;
  adminAssessmentResults: Array<AssessmentResponse>;
  adminAvailability: AvailabilityResult;
  adminDosespotLink?: Maybe<Scalars['String']>;
  adminExistingCalendarEventsInRange: Array<CalendarEvent>;
  /** Find appointments at risk when introducing a new provider availability */
  adminFindApptsAtRisk: Array<CalendarEvent>;
  adminGetAllocations: Array<Allocation>;
  adminGetJob: AsynchronousJob;
  adminGetStateAllocations: Array<ProviderStateAllocationsModel>;
  adminNeedsPatientOutreach: Array<User>;
  adminOrgIntakeAvailability: IntakeAvailabilityResult;
  adminOverviewReport: OrganizationReport;
  adminPatientMonitoring: Array<User>;
  adminProvider: Provider;
  adminScreenUserResult?: Maybe<ScreenUserResult>;
  adminSearchPharmacy: Array<Pharmacy>;
  adminTimeAllocationsToday: Array<Allocation>;
  adminUrgentActionUsers: Array<User>;
  adminUser: User;
  adminUserDemographics?: Maybe<UserDemographics>;
  adminUserNotifications: Array<InAppNotification>;
  adminUserPharmacies: Array<Pharmacy>;
  adminUsersNeedingBooking: Array<User>;
  allEvents: Array<Event>;
  allPayers: Array<Payer>;
  anonymousNavigateFromPatientPortal: AnonymousNavigateFromPatientPortalResult;
  appointment?: Maybe<CalendarEvent>;
  appointments: Array<CalendarEvent>;
  appointmentTemplate: AppointmentTemplate;
  assessmentByKey: Assessment;
  assessmentForRequest: Assessment;
  assessments: Array<AssessmentResponse>;
  authInstructionsForEmail: AuthInstructions;
  availability: AvailabilityResult;
  availableCoachesForEmbedPatient: Array<EmbedCoach>;
  careEligibility: CareEligibility;
  careNavigators: Array<Provider>;
  careRecommendationGetForUser: CareRecommendationResponse;
  careTeamChannels: Array<CareTeamChannel>;
  checkRegistrationValidity: RegistrationValidity;
  coachingExecutiveFunctioningGoalsAssessment: Assessment;
  coachingExecutiveFunctioningWellnessAssessment: Assessment;
  coachingGoalsAssessment: Assessment;
  coachingIntakeProgress: CoachingIntakeProgress;
  coachingIntakeProgressV2: CoachingIntakeProgressV2;
  coachingKickoffLink: Scalars['String'];
  coachingWellnessAssessment: Assessment;
  coachOverview: CoachOverview;
  configs: Array<ConfigurationOption>;
  connectNowOrgSessions?: Maybe<PaginatedConnectNowSession>;
  connectNowSessionReport?: Maybe<ConnectNowSessionDocumentOutput>;
  coupons: Array<ServiceCoupon>;
  crisisReportsForOrg: Array<DdhFileSuccess>;
  crisisReportsZip: DdhFileSuccess;
  /** @deprecated Use onboardingHubUser or onboardedHubUser instead. */
  currentHubUser: HubUser;
  currentProvider: Provider;
  currentUser: User;
  customClinicalRecConfigGetForUser: CustomClinicalRecConfig;
  /** Internal debug tooling */
  debugCareFlowsForUser: Array<DebugUserCareFlow>;
  /** Internal debug tooling */
  debugDataSharingConsentsForUser: Array<DataSharingConsent>;
  /** Internal debug tooling */
  debugDataSharingRequestsForUser: Array<DataSharingRequest>;
  doesProviderNeedToReactivateUserCareType?: Maybe<ReactivationType>;
  doesUserNeedToReactivateCareType?: Maybe<UserSponsoredReactivation>;
  /** states in which psychiatry is available for dtc */
  dtcPsychiatryStates: Array<Scalars['String']>;
  /** states in which therapy is available for dtc */
  dtcTherapyStates: Array<Scalars['String']>;
  embedPatient?: Maybe<EmbedPatient>;
  embedPatientCoachingStatus?: Maybe<CoachingStatus>;
  embedPatientIdFromHealthieId?: Maybe<Scalars['Float']>;
  entitlementDefinitions: Array<EntitlementDefinition>;
  events: Array<Event>;
  /** environment specific configuration */
  featureFlags: Array<Scalars['String']>;
  fetchProviderFeedbackTemplate: ProviderFeedback;
  findCareStart: FindCareStartOutput;
  findCareUserHasCompleted: Scalars['Boolean'];
  genericCollegeModal: Array<GenericCollegeModalModel>;
  getAllCompletedSkillsForModule: Array<Scalars['String']>;
  getAllNotesAndFormSubmissionsForUser: Array<MedicalNote>;
  getAllNotesForUser: Array<MedicalNote>;
  getAssessmentLink: Scalars['String'];
  getAssignableSkills: Array<AssignableSkill>;
  getBadgeCount: Scalars['Float'];
  getCareRecommendationStatusForUser: RecommendationStatusForUserResponse;
  getClaim: InsuranceClaim;
  getClaims: Array<InsuranceClaim>;
  getCoachChatMessages: Array<CoachChatMessage>;
  getContract: GetContractResult;
  getEligibilityCarePeriodValues: ContinuityOfCareEligibilityCarePeriodValues;
  getEligibilityResult: ContinuityOfCareEligibilityResult;
  getHubStatusForUser: HubStatusForUser;
  getJumpBackInSkills: Array<JumpBackInSkillData>;
  getMySkillsAssignments: UserSkillAssignments;
  getNoteByUuid: MedicalNote;
  getPortals: Array<PortalKey>;
  getPossibleAssignees: Array<Provider>;
  getSkillAssignmentCountsForUser: SkillAssignmentCountsResponse;
  getSkillsAssignedToUser: UserSkillAssignments;
  getUpNextSkill: GetUpNextSkillResult;
  getUpNextSkillForModule: GetUpNextSkillForModuleResult;
  hasCompletedAnySkill: Scalars['Boolean'];
  hasSkillBeenStarted: Scalars['Boolean'];
  healthieProviderSynced: Scalars['Boolean'];
  hubAppointmentById: HubAppointment;
  hubGetAssessmentLink: Scalars['String'];
  hubOrganizationResources: Array<HubOrganizationResource>;
  /** @deprecated Deprecated use "appointment" with the appt id */
  intakeCall: CalendarEvent;
  isConnectNowServiceAvailable: Scalars['Boolean'];
  isLoggedInHub: Scalars['Boolean'];
  isOnDemandServiceAvailable: Scalars['Boolean'];
  isSkillComplete: Scalars['Boolean'];
  latestAssessment: AssessmentResponse;
  listContracts: ListContractsModel;
  loadUnfinishedSkillReflection?: Maybe<UnfinishedSkillReflection>;
  mantraAdmins: Array<Provider>;
  mcpCurrentContractUtilization: McpCurrentContractUtilizationResult;
  medicationsForUser: Array<Prescription>;
  minorConsentStatusForUser: MinorConsentStatusModel;
  navigateFromHubMobileToWeb: Scalars['String'];
  /** @deprecated Replaced by navigateFromHubToPatientPortal2 */
  navigateFromHubToPatientPortal: Scalars['String'];
  navigateFromHubToPatientPortal2: NavigateFromHubToPatientPortalResult;
  navigateFromPatientPortalToHub: Scalars['String'];
  newClaimsCount: Scalars['Float'];
  nextAppointment?: Maybe<CalendarEvent>;
  nextAssessment?: Maybe<Scalars['DateTime']>;
  nextAvailableAppointment?: Maybe<Scalars['DateTime']>;
  nextHubExternalAppointment?: Maybe<HubExternalAppointment>;
  notifications: Array<InAppNotification>;
  onboardedHubUser: HubUser;
  onboardingHubUser: HubUser;
  onDemandOrgSessions?: Maybe<PaginatedOnDemandSession>;
  organization: Organization;
  organizationAnnouncementsByDays: Array<OrganizationAnnouncement>;
  organizationAnnouncementsByOrgId: Array<OrganizationAnnouncement>;
  organizationCampusTeam?: Maybe<Array<CampusTeamRelationship>>;
  organizationConnectNow?: Maybe<HubConnectNowOutput>;
  organizationCustomHubModule: HubCustomModuleData;
  organizationHandbook: OrganizationHandbook;
  organizationOnDemand?: Maybe<HubOnDemandOutput>;
  organizations: Array<Organization>;
  paginatedIopReferrals: PaginatedIopReferrals;
  paginatedUsers: PaginatedUsers;
  patients: Array<User>;
  patientsMonitoredCount?: Maybe<Scalars['Float']>;
  payers: Array<Payer>;
  permissibleUploadTypes: Array<UploadType>;
  pharmacies: Array<Pharmacy>;
  portalCareRecommendationForUser: CareRecommendation;
  prescriptions: Array<Prescription>;
  /** @deprecated Deprecated use "pricesInCents" resolver */
  prices: Prices;
  pricesInCents: PricesInCents;
  privateChannels: Array<PatientChannel>;
  promotionWindowInDays: Scalars['Float'];
  providerById: Provider;
  /** Current provider's campus team events */
  providerCampusTeamEvents?: Maybe<Array<CampusTeamRelationshipEvents>>;
  providerEmails: Array<SentEmail>;
  providerIsLoggedIn: ProviderIsLoggedIn;
  providerNetworkSearch: ProviderNetworkResult;
  providerOfficeAvailability: ProviderInOfficeAvailability;
  providers: Array<Provider>;
  providerSafetyPlans: Array<SafetyPlan>;
  providersById: Array<Provider>;
  providersForUser: MaybeBlockedProviders;
  referrals: Array<User>;
  remainingHoursForRange: Array<RemainingHours>;
  safetyPlans: Array<SafetyPlan>;
  searchPharmacy: Array<Pharmacy>;
  searchPharmacyByAddress: Array<Pharmacy>;
  searchUsers: Array<User>;
  skillPathwayGetForUser?: Maybe<Array<SkillPathwayStep>>;
  skillPathwayNextSkill: SkillPathwayNextSkill;
  skillReflectionGetAllForUser: UserSkillReflections;
  skillReflectionGetUserAnswers?: Maybe<SkillReflectionAnswers>;
  ticket: Ticket;
  tickets: Array<Ticket>;
  traitCategories: Array<ProviderTrait>;
  unreadCareTeamMessages: Scalars['Float'];
  unreadHubExternalMessages?: Maybe<Array<UnreadHubExternalMessage>>;
  unreadPatientMessages: Scalars['Float'];
  unsubmittedMedicalNotes: Array<MedicalNote>;
  /** number of users who need ID verification */
  unverifiedUserCount: Scalars['Float'];
  upcomingHubAppointment?: Maybe<HubAppointment>;
  userCoachingHealthieStatus: UserCoachingHealthieStatus;
  userCoachingStatus: UserCoachingStatus;
  userDemographics?: Maybe<UserDemographics>;
  userEmails: Array<SentEmail>;
  userHealthieStatus: UserHealthieStatus;
  userIsLoggedIn: UserIsLoggedIn;
  userOutreachAttempts: Array<OutreachAttemptModel>;
  userReferralStatus: User;
  users: Array<User>;
  userSkillFeedback?: Maybe<SkillFeedbackModel>;
  userSmses: Array<SentSmsModel>;
  usersOrganizations: Array<OrganizationOption>;
  weeklyCapacityUsage: Array<CapacityUsage>;
  zendeskLink: Scalars['String'];
}

export type QueryAdminAppointmentArgs = {
  id: Scalars['Float'];
};

export type QueryAdminAppointmentByCrisisIdArgs = {
  crisisId: Scalars['String'];
};

export type QueryAdminAppointmentByUserArgs = {
  appointmentId?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type QueryAdminAppointmentsArgs = {
  allProviders?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  providerId?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryAdminAssessmentArgs = {
  assessment: AssessmentKey;
  userId: Scalars['Float'];
};

export type QueryAdminAssessmentResultsArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminAvailabilityArgs = {
  appointmentType: Scalars['String'];
  careType: CareType;
  duration: Scalars['Float'];
  end?: InputMaybe<Scalars['String']>;
  patientState?: InputMaybe<StateCodes>;
  providerIds: Array<Scalars['Float']>;
  rescheduleId?: InputMaybe<Scalars['Float']>;
  start: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  userId: Scalars['Float'];
};

export type QueryAdminDosespotLinkArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminExistingCalendarEventsInRangeArgs = {
  apptTypes?: InputMaybe<Array<Scalars['String']>>;
  careTypes?: InputMaybe<Array<CareType>>;
  end?: InputMaybe<Scalars['String']>;
  providerId: Scalars['Float'];
  start: Scalars['String'];
};

export type QueryAdminFindApptsAtRiskArgs = {
  allocations: Array<CreateAllocationInput>;
  date: Scalars['String'];
  providerId: Scalars['Float'];
  timezone: Scalars['String'];
  weekly: Scalars['Boolean'];
};

export type QueryAdminGetAllocationsArgs = {
  providerId: Scalars['Float'];
};

export type QueryAdminGetJobArgs = {
  jobId: Scalars['Float'];
};

export type QueryAdminGetStateAllocationsArgs = {
  providerId: Scalars['Float'];
};

export type QueryAdminOrgIntakeAvailabilityArgs = {
  careType: CareType;
  end?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  patientState?: InputMaybe<StateCodes>;
  providerIds: Array<Scalars['Float']>;
  start: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type QueryAdminOverviewReportArgs = {
  scope: ReportScope;
};

export type QueryAdminProviderArgs = {
  providerId: Scalars['Float'];
};

export type QueryAdminScreenUserResultArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminSearchPharmacyArgs = {
  query: Scalars['String'];
};

export type QueryAdminUserArgs = {
  id: Scalars['Float'];
};

export type QueryAdminUserDemographicsArgs = {
  id: Scalars['Float'];
};

export type QueryAdminUserNotificationsArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminUserPharmaciesArgs = {
  userId: Scalars['Float'];
};

export type QueryAdminUsersNeedingBookingArgs = {
  careType: CareType;
};

export type QueryAllEventsArgs = {
  before?: InputMaybe<Scalars['DateTime']>;
};

export type QueryAnonymousNavigateFromPatientPortalArgs = {
  input: AnonymousNavigateFromPatientPortalInput;
};

export type QueryAppointmentArgs = {
  id: Scalars['Float'];
};

export type QueryAppointmentTemplateArgs = {
  appointmentType: Scalars['String'];
  careType: Scalars['String'];
};

export type QueryAssessmentByKeyArgs = {
  assessmentKey: AssessmentKey;
};

export type QueryAssessmentForRequestArgs = {
  requestId: Scalars['Float'];
};

export type QueryAuthInstructionsForEmailArgs = {
  app?: InputMaybe<SamlUserApp>;
  email: Scalars['String'];
  mrn?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SamlConfigurationType>;
};

export type QueryAvailabilityArgs = {
  careType?: InputMaybe<Scalars['String']>;
  checkin?: InputMaybe<Scalars['Boolean']>;
  end?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
};

export type QueryAvailableCoachesForEmbedPatientArgs = {
  userId: Scalars['Int'];
};

export type QueryCareTeamChannelsArgs = {
  providerId?: InputMaybe<Scalars['Float']>;
};

export type QueryCheckRegistrationValidityArgs = {
  email: Scalars['String'];
};

export type QueryConnectNowOrgSessionsArgs = {
  params: ConnectNowReportsInput;
};

export type QueryConnectNowSessionReportArgs = {
  id: Scalars['String'];
};

export type QueryCrisisReportsZipArgs = {
  ddhFileSuccessId: Scalars['Int'];
};

export type QueryDebugCareFlowsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryDebugDataSharingConsentsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryDebugDataSharingRequestsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryDoesProviderNeedToReactivateUserCareTypeArgs = {
  careType: CareType;
  userId: Scalars['Float'];
};

export type QueryEmbedPatientArgs = {
  patientId: Scalars['Float'];
};

export type QueryEmbedPatientCoachingStatusArgs = {
  userId: Scalars['Int'];
};

export type QueryEmbedPatientIdFromHealthieIdArgs = {
  healthieId: Scalars['String'];
};

export type QueryEventsArgs = {
  userId: Scalars['Float'];
};

export type QueryFetchProviderFeedbackTemplateArgs = {
  careType: Scalars['String'];
  feedbackType: FeedbackType;
  providerId: Scalars['Int'];
};

export type QueryFindCareStartArgs = {
  questionnaireKey: Scalars['String'];
};

export type QueryGenericCollegeModalArgs = {
  orgId: Scalars['Float'];
};

export type QueryGetAllCompletedSkillsForModuleArgs = {
  moduleId: Scalars['String'];
};

export type QueryGetAllNotesAndFormSubmissionsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryGetAllNotesForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryGetAssignableSkillsArgs = {
  userId: Scalars['Float'];
};

export type QueryGetClaimArgs = {
  id: Scalars['Float'];
};

export type QueryGetClaimsArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type QueryGetCoachChatMessagesArgs = {
  coachingProgramId?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type QueryGetContractArgs = {
  id: Scalars['Float'];
};

export type QueryGetEligibilityCarePeriodValuesArgs = {
  submissionId: Scalars['Float'];
};

export type QueryGetEligibilityResultArgs = {
  submissionId: Scalars['Float'];
};

export type QueryGetMySkillsAssignmentsArgs = {
  page: Scalars['Float'];
  perPage: Scalars['Float'];
};

export type QueryGetNoteByUuidArgs = {
  uuid: Scalars['String'];
};

export type QueryGetPossibleAssigneesArgs = {
  ticketId?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type QueryGetSkillsAssignedToUserArgs = {
  page: Scalars['Float'];
  perPage: Scalars['Float'];
  userId: Scalars['Float'];
};

export type QueryGetUpNextSkillArgs = {
  isPathway: Scalars['Boolean'];
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
};

export type QueryGetUpNextSkillForModuleArgs = {
  moduleId: Scalars['String'];
};

export type QueryHasSkillBeenStartedArgs = {
  input: SkillCompletionInputModel;
};

export type QueryHealthieProviderSyncedArgs = {
  id: Scalars['Float'];
};

export type QueryHubAppointmentByIdArgs = {
  appointmentId: Scalars['String'];
};

export type QueryIsSkillCompleteArgs = {
  input: SkillCompletionInputModel;
};

export type QueryLatestAssessmentArgs = {
  key: AssessmentKey;
};

export type QueryListContractsArgs = {
  organizationId: Scalars['Float'];
};

export type QueryLoadUnfinishedSkillReflectionArgs = {
  skillId: Scalars['String'];
};

export type QueryMedicationsForUserArgs = {
  userId: Scalars['Float'];
};

export type QueryMinorConsentStatusForUserArgs = {
  userId: Scalars['Int'];
};

export type QueryNavigateFromHubMobileToWebArgs = {
  source?: InputMaybe<Scalars['String']>;
};

export type QueryNavigateFromHubToPatientPortalArgs = {
  mrn?: InputMaybe<Scalars['String']>;
};

export type QueryNavigateFromHubToPatientPortal2Args = {
  mrn?: InputMaybe<Scalars['String']>;
};

export type QueryNextAvailableAppointmentArgs = {
  appointmentType: Scalars['String'];
  careType: CareType;
  organizationId: Scalars['Float'];
  startDate: Scalars['String'];
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryOnDemandOrgSessionsArgs = {
  params: OnDemandReportsInput;
};

export type QueryOrganizationArgs = {
  id: Scalars['Float'];
};

export type QueryOrganizationAnnouncementsByDaysArgs = {
  days?: InputMaybe<Scalars['Float']>;
  organizationId: Scalars['Float'];
};

export type QueryOrganizationAnnouncementsByOrgIdArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  organizationId: Scalars['Float'];
};

export type QueryOrganizationCampusTeamArgs = {
  organizationId: Scalars['Float'];
};

export type QueryOrganizationHandbookArgs = {
  organizationId: Scalars['Float'];
};

export type QueryPaginatedIopReferralsArgs = {
  params: PaginatedIopReferralInput;
};

export type QueryPaginatedUsersArgs = {
  params: UserListParams;
};

export type QueryPatientsArgs = {
  providerId: Scalars['Float'];
};

export type QueryPrivateChannelsArgs = {
  providerId?: InputMaybe<Scalars['Float']>;
};

export type QueryProviderByIdArgs = {
  id: Scalars['Float'];
};

export type QueryProviderEmailsArgs = {
  providerId: Scalars['Float'];
};

export type QueryProviderNetworkSearchArgs = {
  careType: CareType;
  doesOrgUseDGMOrSessions?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Array<ProviderGenderSearch>>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  payerId?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<StateCodes>;
  traits?: InputMaybe<Array<ProviderTraitInput>>;
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryProviderOfficeAvailabilityArgs = {
  providerId: Scalars['Int'];
};

export type QueryProvidersArgs = {
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryProviderSafetyPlansArgs = {
  incomplete?: InputMaybe<Scalars['Boolean']>;
};

export type QueryProvidersByIdArgs = {
  ids: Array<Scalars['Int']>;
};

export type QueryProvidersForUserArgs = {
  userId?: InputMaybe<Scalars['Float']>;
};

export type QueryRemainingHoursForRangeArgs = {
  appointmentType: Scalars['String'];
  careType: CareType;
  end: Scalars['String'];
  organizationId: Scalars['Float'];
  start: Scalars['String'];
};

export type QuerySafetyPlansArgs = {
  complete?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySearchPharmacyArgs = {
  query: Scalars['String'];
};

export type QuerySearchPharmacyByAddressArgs = {
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type QuerySearchUsersArgs = {
  search: SearchUserInput;
};

export type QuerySkillReflectionGetAllForUserArgs = {
  page: Scalars['Float'];
  perPage: Scalars['Float'];
};

export type QuerySkillReflectionGetUserAnswersArgs = {
  reflectionId: Scalars['Float'];
};

export type QueryTicketArgs = {
  id: Scalars['Float'];
};

export type QueryTicketsArgs = {
  userId?: InputMaybe<Scalars['Float']>;
  view: TicketView;
};

export type QueryUpcomingHubAppointmentArgs = {
  delay?: InputMaybe<Scalars['Float']>;
};

export type QueryUserCoachingHealthieStatusArgs = {
  userId: Scalars['Float'];
};

export type QueryUserEmailsArgs = {
  userId: Scalars['Int'];
};

export type QueryUserHealthieStatusArgs = {
  userId: Scalars['Float'];
};

export type QueryUserOutreachAttemptsArgs = {
  userId: Scalars['Int'];
};

export type QueryUserReferralStatusArgs = {
  userId: Scalars['Float'];
};

export type QueryUsersArgs = {
  includeNewPatients?: InputMaybe<Scalars['Boolean']>;
  organizationId?: InputMaybe<Scalars['Float']>;
};

export type QueryUserSkillFeedbackArgs = {
  skillId: Scalars['String'];
};

export type QueryUserSmsesArgs = {
  userId: Scalars['Int'];
};

export type QueryUsersOrganizationsArgs = {
  params: UserListParams;
};

export type QueryWeeklyCapacityUsageArgs = {
  organizationId?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  weeks: Scalars['Int'];
};

export type QueryZendeskLinkArgs = {
  userId: Scalars['Float'];
};

export interface Question {
  caption?: Maybe<Scalars['String']>;
  dependency?: Maybe<Dependency>;
  disclaimer?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Scalars['String']>>;
  isOptional?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  legend?: Maybe<Array<Scalars['String']>>;
  manualInput?: Maybe<ManualInput>;
  options?: Maybe<Array<Option>>;
  overrideDescription?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  preText?: Maybe<Scalars['String']>;
  subText?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
}

export interface Questionnaire {
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  preScreen?: Maybe<QuestionnairePreScreen>;
  questions: Array<Question>;
  title: Scalars['String'];
}

export interface QuestionnairePreScreen {
  asset?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<SectionCopy>;
  title?: Maybe<Scalars['String']>;
}

export interface QuestionnaireResult {
  answers: Array<Answer>;
  assessment: AssessmentResponse;
  description: Scalars['String'];
  key: Scalars['String'];
  maximumScore?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  symptoms?: Maybe<Scalars['String']>;
}

export interface QuestionnaireSubmission {
  answers: Array<AnswerSubmission>;
  key: Scalars['String'];
}

export const ReactivationType = {
  Confirm: 'confirm',
  Reactivate: 'reactivate',
} as const;

export type ReactivationType = typeof ReactivationType[keyof typeof ReactivationType];
export const RecommendationOptions = {
  AssessmentVisit: 'assessmentVisit',
  Coaching: 'coaching',
  CustomClinicalRec: 'customClinicalRec',
  Therapy: 'therapy',
} as const;

export type RecommendationOptions =
  typeof RecommendationOptions[keyof typeof RecommendationOptions];
export interface RecommendationStatusForUserResponse {
  activeRecommendation: Scalars['Boolean'];
  expiration?: Maybe<Scalars['DateTime']>;
  isActiveOrOnboardingInCare: Scalars['Boolean'];
  isActiveOrOnboardingInTherapy: Scalars['Boolean'];
}

export const ReenrollmentStatus = {
  Cancelled: 'Cancelled',
  Confirmed: 'Confirmed',
  Declined: 'Declined',
  Pending: 'Pending',
} as const;

export type ReenrollmentStatus = typeof ReenrollmentStatus[keyof typeof ReenrollmentStatus];
export interface ReferralError {
  errorCode: ReferralErrorCode;
  message: Scalars['String'];
}

export const ReferralErrorCode = {
  InvalidEmail: 'InvalidEmail',
  InvalidOrgEmail: 'InvalidOrgEmail',
} as const;

export type ReferralErrorCode = typeof ReferralErrorCode[keyof typeof ReferralErrorCode];
export const ReferralPartners = {
  CharlieHealth: 'charlie_health',
  Togetherall: 'togetherall',
} as const;

export type ReferralPartners = typeof ReferralPartners[keyof typeof ReferralPartners];
/** App where the user originated */
export const ReferralSource = {
  Mcp: 'MCP',
  Oz: 'OZ',
  Portal: 'PORTAL',
} as const;

export type ReferralSource = typeof ReferralSource[keyof typeof ReferralSource];
export interface ReflectionPagination {
  page: Scalars['Float'];
  totalPages: Scalars['Float'];
}

export interface RegistrationValidity {
  emailAlreadyExists: Scalars['Boolean'];
  emailIsEligibleForOrganization: Scalars['Boolean'];
  emailMatchesOrganization: Scalars['Boolean'];
  organizationAllowsSelfReferral: Scalars['Boolean'];
  patientPortalInitialized: Scalars['Boolean'];
}

export interface RemainingHours {
  date: Scalars['String'];
  dgmAllocationId?: Maybe<Scalars['Int']>;
  remainingHours: Scalars['Float'];
}

/** Scope of report generated */
export const ReportScope = {
  All: 'ALL',
  Dtc: 'DTC',
  Org: 'ORG',
} as const;

export type ReportScope = typeof ReportScope[keyof typeof ReportScope];
export interface RequiredIf {
  dependentQuestions: Array<DependentQuestions>;
}

export const ResourceTag = {
  ByAppointment: 'ByAppointment',
  InPerson: 'InPerson',
  NonEmergencies: 'NonEmergencies',
  Open247: 'Open247',
  Telehealth: 'Telehealth',
} as const;

export type ResourceTag = typeof ResourceTag[keyof typeof ResourceTag];
export const ResourceType = {
  AcademicAndAccessibility: 'AcademicAndAccessibility',
  Additional: 'Additional',
  DisabilityAccessibilityCenter: 'DisabilityAccessibilityCenter',
  Diversity: 'Diversity',
  Emergency: 'Emergency',
  Health: 'Health',
  HealthServices: 'HealthServices',
  LgbtqiaCenter: 'LgbtqiaCenter',
  LocalHospitalCommunityProvider: 'LocalHospitalCommunityProvider',
  Other: 'Other',
  PreferredCampusPharmacy: 'PreferredCampusPharmacy',
  PublicSafetyCampusPolice: 'PublicSafetyCampusPolice',
  ResidenceLife: 'ResidenceLife',
  Safety: 'Safety',
  UrgentCareCenter: 'UrgentCareCenter',
} as const;

export type ResourceType = typeof ResourceType[keyof typeof ResourceType];
export const Role = {
  Admin: 'admin',
  Patient: 'patient',
  ProductAdmin: 'productAdmin',
  Provider: 'provider',
  Supreme: 'supreme',
  UniversityAdmin: 'universityAdmin',
  UniversityReferrer: 'universityReferrer',
  UniversityStaff: 'universityStaff',
  UniversitySupportingClinician: 'universitySupportingClinician',
} as const;

export type Role = typeof Role[keyof typeof Role];
export interface SafetyInfo {
  commonSideEffects: Array<SideEffect>;
  faq: Array<FaQuestion>;
  potentiallySeriousSideEffects: Array<SideEffect>;
  warning: Scalars['String'];
}

export interface SafetyPlan {
  activatingProvider: Provider;
  complete: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  data: SafetyPlanData;
  editHistory: Array<Event>;
  id: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user: User;
}

export interface SafetyPlanContact {
  address?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  name: Scalars['String'];
}

export interface SafetyPlanData {
  copingStrategies: Array<Scalars['String']>;
  crisisContacts: Array<SafetyPlanContact>;
  crisisFacilities: Array<SafetyPlanContact>;
  crisisResources: Array<SafetyPlanContact>;
  dangerousMeans?: Maybe<Scalars['Boolean']>;
  dangerousMeansList?: Maybe<Scalars['String']>;
  dangerousMeansPlan?: Maybe<Scalars['String']>;
  distractions: Array<SafetyPlanContact>;
  firearmAccess?: Maybe<Scalars['Boolean']>;
  firearmAccessPlan?: Maybe<Scalars['String']>;
  professionalContacts: Array<SafetyPlanContact>;
  reasonToLive?: Maybe<Scalars['String']>;
  warningSigns: Array<Scalars['String']>;
}

export interface SafetyPlanInput {
  copingStrategies: Array<Scalars['String']>;
  crisisContacts: Array<SafetyPlanQuestionInput>;
  crisisFacilities: Array<SafetyPlanQuestionInput>;
  crisisResources: Array<SafetyPlanQuestionInput>;
  dangerousMeans?: InputMaybe<Scalars['Boolean']>;
  dangerousMeansList?: InputMaybe<Scalars['String']>;
  dangerousMeansPlan?: InputMaybe<Scalars['String']>;
  distractions: Array<SafetyPlanQuestionInput>;
  firearmAccess?: InputMaybe<Scalars['Boolean']>;
  firearmAccessPlan?: InputMaybe<Scalars['String']>;
  professionalContacts: Array<SafetyPlanQuestionInput>;
  reasonToLive?: InputMaybe<Scalars['String']>;
  warningSigns: Array<Scalars['String']>;
}

export interface SafetyPlanQuestionInput {
  address?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
}

export interface SamlConfiguration {
  authnRequestBinding?: Maybe<Scalars['String']>;
  automaticallyUpdateEmail: Scalars['Boolean'];
  campusOrgMappings?: Maybe<Array<CampusOrgMappingsInput>>;
  certificates: Array<Scalars['String']>;
  disableRequestedAuthnContext: Scalars['Boolean'];
  enableManualUniqueIdEdits: Scalars['Boolean'];
  entryPoint: Scalars['String'];
  id: Scalars['Int'];
  issuer?: Maybe<Scalars['String']>;
  metadata: Scalars['String'];
  profileCampusField?: Maybe<Scalars['String']>;
  profileEmailField: Scalars['String'];
  profileMatchingField: Scalars['String'];
  profileUniqueIdField?: Maybe<Scalars['String']>;
}

export const SamlConfigurationType = {
  Provider: 'Provider',
  User: 'User',
} as const;

export type SamlConfigurationType =
  typeof SamlConfigurationType[keyof typeof SamlConfigurationType];
export const SamlUserApp = {
  Hub: 'Hub',
  HubAndroid: 'HubAndroid',
  HubiOs: 'HubiOS',
} as const;

export type SamlUserApp = typeof SamlUserApp[keyof typeof SamlUserApp];
export interface SatisfactionRating {
  averageRating?: Maybe<Scalars['Float']>;
  numberOfRatings: Scalars['Float'];
}

export interface ScreenUserResult {
  screenedOut: Scalars['Boolean'];
  screenedOutFromHub: Scalars['Boolean'];
}

export interface ScreenUserResultInput {
  screenedOut: Scalars['Boolean'];
  screenedOutFromHub?: InputMaybe<Scalars['Boolean']>;
}

export interface SearchUserInput {
  careStatus?: InputMaybe<CareStatus>;
  createdAfter?: InputMaybe<Scalars['DateTime']>;
  createdBefore?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<Scalars['String']>;
}

export const SectionColor = {
  BrandAltBg: 'brandAltBg',
  DangerBg: 'dangerBg',
  GreyBg: 'greyBg',
} as const;

export type SectionColor = typeof SectionColor[keyof typeof SectionColor];
export type SectionCopy = ListCopy | TextCopy;

/** Change behavior based on another section */
export interface SectionDependency {
  key: Scalars['String'];
  /** only show if question has values */
  showValues?: Maybe<Array<Scalars['String']>>;
}

export const SectionFormat = {
  Appointment: 'appointment',
  Checkbox: 'checkbox',
  Date: 'date',
  Diagnosis: 'diagnosis',
  Formtexttitle: 'formtexttitle',
  Multicheckbox: 'multicheckbox',
  Multidiagnosis: 'multidiagnosis',
  Multiselect: 'multiselect',
  Multitext: 'multitext',
  Radio: 'radio',
  Rx: 'rx',
  Scale: 'scale',
  Select: 'select',
  Sign: 'sign',
  Singlelineinput: 'singlelineinput',
  Textarea: 'textarea',
  Title: 'title',
} as const;

export type SectionFormat = typeof SectionFormat[keyof typeof SectionFormat];
export interface SectionHook {
  targetKey: Scalars['String'];
  type: HookType;
}

/** Auto-select a value based on another value */
export interface SelectDependentValue {
  /** trigger selection if one of these values */
  dependsValues?: Maybe<Array<Scalars['String']>>;
  targetKey: Scalars['String'];
  /** the value to be selected */
  targetSelectValue?: Maybe<Scalars['String']>;
}

export interface SendCoachChatMessageResult {
  id?: Maybe<Scalars['String']>;
}

export interface SentEmail {
  bounced: Scalars['Boolean'];
  clicked: Scalars['Boolean'];
  delivered: Scalars['Boolean'];
  id: Scalars['Float'];
  label: Scalars['String'];
  opened: Scalars['Boolean'];
  outcome?: Maybe<Scalars['String']>;
  sent: Scalars['Boolean'];
  sentAt: Scalars['DateTime'];
  subject: Scalars['String'];
  template: Scalars['String'];
  unsubscribed: Scalars['Boolean'];
}

export interface SentSmsModel {
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  template?: Maybe<Scalars['String']>;
}

export interface ServiceCoupon {
  active: Scalars['Boolean'];
  autoApplyOnConversion: Scalars['Boolean'];
  careTypes: Array<CareType>;
  code: Scalars['String'];
  discountType: DiscountType;
  id: Scalars['Float'];
  maxUses: Scalars['Float'];
  organization?: Maybe<Organization>;
  percentDiscount?: Maybe<Scalars['Float']>;
  replacePriceCents?: Maybe<Scalars['Int']>;
}

export interface SetPasswordWithTokenError {
  errorCode: SetPasswordWithTokenErrorCode;
  message: Scalars['String'];
}

export const SetPasswordWithTokenErrorCode = {
  InvalidPasswordFormat: 'InvalidPasswordFormat',
  InvalidToken: 'InvalidToken',
  TokenExpired: 'TokenExpired',
} as const;

export type SetPasswordWithTokenErrorCode =
  typeof SetPasswordWithTokenErrorCode[keyof typeof SetPasswordWithTokenErrorCode];
export type SetPasswordWithTokenResult = SetPasswordWithTokenError | SetPasswordWithTokenSuccess;

export interface SetPasswordWithTokenSuccess {
  success: Scalars['Boolean'];
}

export interface SideEffect {
  explanation: Scalars['String'];
  title: Scalars['String'];
}

export interface SignedS3Upload {
  fields: Array<Array<Scalars['String']>>;
  key: Scalars['String'];
  url: Scalars['String'];
}

export interface SignUpError {
  errorCode: SignUpErrorCode;
  message: Scalars['String'];
}

export const SignUpErrorCode = {
  CampusReferralRequired: 'CampusReferralRequired',
  EligibilityError: 'EligibilityError',
  InvalidPassword: 'InvalidPassword',
  InvalidToken: 'InvalidToken',
  TokenExpired: 'TokenExpired',
  UnknownPartner: 'UnknownPartner',
} as const;

export type SignUpErrorCode = typeof SignUpErrorCode[keyof typeof SignUpErrorCode];
export type SignUpResult = SignUpError | SignUpSuccess;

export interface SignUpSuccess {
  successMessage: Scalars['String'];
}

export interface SimpleGlobalAvailabilityBlocker {
  date: Scalars['LocalDate'];
  providerId: Scalars['Float'];
  type: GlobalAvailabilityBlockerType;
}

export interface SkillAssignmentCountsResponse {
  notCompleted: Scalars['Int'];
  notSeen: Scalars['Int'];
}

export interface SkillAssignmentModel {
  assignedById: Scalars['Int'];
  assignerHasSeen: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  moduleId: Scalars['String'];
  skillId: Scalars['String'];
  skillReflection?: Maybe<SkillReflectionAnswers>;
  skillTitle: Scalars['String'];
  startedAt?: Maybe<Scalars['DateTime']>;
  userHasSeen: Scalars['Boolean'];
  userId: Scalars['Int'];
}

export interface SkillCompletionInputModel {
  moduleId: Scalars['String'];
  sectionId?: InputMaybe<Scalars['String']>;
  skillId: Scalars['String'];
}

export interface SkillFeedbackModel {
  additionalFeedback?: Maybe<Scalars['String']>;
  rating: Scalars['Float'];
}

export interface SkillFeedbackQuestionModelInput {
  questionSlug: Scalars['String'];
  response: Scalars['Float'];
}

export interface SkillPathwayAnswer {
  moduleId: Scalars['String'];
  nudgeIds: Array<Scalars['String']>;
  score: Scalars['Float'];
  sectionId: Scalars['String'];
}

export interface SkillPathwayNextSkill {
  hasPathway: Scalars['Boolean'];
  moduleId?: Maybe<Scalars['String']>;
  nextSkillId?: Maybe<Scalars['String']>;
  sectionId?: Maybe<Scalars['String']>;
}

export interface SkillPathwayNudge {
  id: Scalars['String'];
  name: Scalars['String'];
}

export interface SkillPathwayStep {
  moduleId: Scalars['String'];
  nudges: Array<SkillPathwayNudge>;
  score: Scalars['Float'];
  sectionId: Scalars['String'];
}

export interface SkillReflection {
  id: Scalars['Float'];
  isComplete: Scalars['Boolean'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface SkillReflectionAnswers {
  id: Scalars['Float'];
  questions: Array<SkillReflectionQuestion>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface SkillReflectionQuestion {
  answer: Scalars['String'];
  question: Scalars['String'];
  questionKey: Scalars['String'];
}

export const SortDirection = {
  Ascending: 'Ascending',
  Descending: 'Descending',
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
export interface SpecialConditions {
  bipolarSchizophrenia: Scalars['Boolean'];
  suicidal: Scalars['Boolean'];
}

export const SponsoredSessionsLimitType = {
  ShortTermCapped: 'shortTermCapped',
  ShortTermUncapped: 'shortTermUncapped',
  StudentAssistanceProgram: 'studentAssistanceProgram',
  Unrestricted: 'unrestricted',
} as const;

export type SponsoredSessionsLimitType =
  typeof SponsoredSessionsLimitType[keyof typeof SponsoredSessionsLimitType];
export const StateCodes = {
  Ak: 'AK',
  Al: 'AL',
  Ar: 'AR',
  As: 'AS',
  Az: 'AZ',
  Ca: 'CA',
  Co: 'CO',
  Ct: 'CT',
  Dc: 'DC',
  De: 'DE',
  Fl: 'FL',
  Fm: 'FM',
  Ga: 'GA',
  Gu: 'GU',
  Hi: 'HI',
  Ia: 'IA',
  Id: 'ID',
  Il: 'IL',
  In: 'IN',
  Ks: 'KS',
  Ky: 'KY',
  La: 'LA',
  Ma: 'MA',
  Md: 'MD',
  Me: 'ME',
  Mh: 'MH',
  Mi: 'MI',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Ms: 'MS',
  Mt: 'MT',
  Nc: 'NC',
  Nd: 'ND',
  Ne: 'NE',
  Nh: 'NH',
  Nj: 'NJ',
  Nm: 'NM',
  Nv: 'NV',
  Ny: 'NY',
  Oh: 'OH',
  Ok: 'OK',
  Or: 'OR',
  Pa: 'PA',
  Pr: 'PR',
  Pw: 'PW',
  Ri: 'RI',
  Sc: 'SC',
  Sd: 'SD',
  Tn: 'TN',
  Tx: 'TX',
  Ut: 'UT',
  Va: 'VA',
  Vi: 'VI',
  Vt: 'VT',
  Wa: 'WA',
  Wi: 'WI',
  Wv: 'WV',
  Wy: 'WY',
} as const;

export type StateCodes = typeof StateCodes[keyof typeof StateCodes];
export type StudentHubReferralResult = ReferralError | StudentHubReferralSuccess;

export interface StudentHubReferralSuccess {
  successMessage: Scalars['String'];
}

export interface Subscription {
  coachChatUpdates: CoachChatUpdate;
  portalUpdates: Array<PortalKey>;
}

export type SubscriptionCoachChatUpdatesArgs = {
  coachingProgramId?: InputMaybe<Scalars['Float']>;
};

/** The different kinds of suicidal thoughts */
export const SuicidalThoughts = {
  DuringMyLifetime: 'DURING_MY_LIFETIME',
  Never: 'NEVER',
  WithinThePastMonth: 'WITHIN_THE_PAST_MONTH',
  WithinThePastYear: 'WITHIN_THE_PAST_YEAR',
} as const;

export type SuicidalThoughts = typeof SuicidalThoughts[keyof typeof SuicidalThoughts];
export interface Task {
  blueprintId: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  done: Scalars['Boolean'];
  id: Scalars['Int'];
  ref: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Float'];
}

export const TaskStatusFilter = {
  Cancelled: 'Cancelled',
  Discharged: 'Discharged',
  Intake: 'Intake',
  OngoingCare: 'OngoingCare',
  OnHold: 'OnHold',
  ScreenedOut: 'ScreenedOut',
} as const;

export type TaskStatusFilter = typeof TaskStatusFilter[keyof typeof TaskStatusFilter];
export interface TestVideoSession {
  videoSessionId: Scalars['String'];
  videoToken: Scalars['String'];
}

export interface TextCopy {
  showInView?: Maybe<Scalars['Boolean']>;
  style?: Maybe<TextStyle>;
  text: Scalars['String'];
}

export const TextKind = {
  Danger: 'danger',
} as const;

export type TextKind = typeof TextKind[keyof typeof TextKind];
export interface TextStyle {
  italic?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<TextKind>;
  tag?: Maybe<TextTag>;
}

export const TextTag = {
  Body: 'body',
  BodyBold: 'bodyBold',
  BodyGrey: 'bodyGrey',
  Bodysmall: 'bodysmall',
  BodySmallBold: 'bodySmallBold',
  Caption: 'caption',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  Label: 'label',
} as const;

export type TextTag = typeof TextTag[keyof typeof TextTag];
export interface Ticket {
  assignee?: Maybe<Provider>;
  assignOptions: TicketAssignOption;
  blockedReason?: Maybe<Scalars['String']>;
  canEdit: Scalars['Boolean'];
  canResolve: Scalars['Boolean'];
  category: TicketCategory;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  events: Array<Event>;
  id: Scalars['Int'];
  priority?: Maybe<TicketPriority>;
  requester?: Maybe<Provider>;
  resolveIn24Hrs?: Maybe<Scalars['Boolean']>;
  status: TicketStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
}

export const TicketAssignOption = {
  Any: 'Any',
  None: 'None',
  Self: 'Self',
} as const;

export type TicketAssignOption = typeof TicketAssignOption[keyof typeof TicketAssignOption];
export const TicketCategory = {
  CloseCareCancellation: 'closeCareCancellation',
  CloseCareDischarge: 'closeCareDischarge',
  Coc: 'coc',
  DatasharingCoc: 'datasharingCoc',
  DatasharingRoi: 'datasharingRoi',
  InformationalContinuityOfCare: 'informationalContinuityOfCare',
  InformationalGeneral: 'informationalGeneral',
  Insurance: 'insurance',
  Labs: 'labs',
  OnHold: 'onHold',
  Other: 'other',
  Outreach: 'outreach',
  OutreachGeneral: 'outreachGeneral',
  OutreachStudentDisengagement: 'outreachStudentDisengagement',
  OutreachStudentNoShow: 'outreachStudentNoShow',
  PriorAuth: 'priorAuth',
  ProviderDefaultScheduleChange: 'providerDefaultScheduleChange',
  ProviderGeneralTimeOff: 'providerGeneralTimeOff',
  ProviderScheduleAdjustment: 'providerScheduleAdjustment',
  ReferralAdhdEvaluation: 'referralADHDEvaluation',
  ReferralExternalPsychiatry: 'referralExternalPsychiatry',
  ReferralExternalTherapy: 'referralExternalTherapy',
  ReferralInternalCoaching: 'referralInternalCoaching',
  ReferralInternalPsychiatry: 'referralInternalPsychiatry',
  ReferralInternalTherapy: 'referralInternalTherapy',
  ReferralIop: 'referralIOP',
  ReferralOther: 'referralOther',
  ReferralPsychiatry: 'referralPsychiatry',
  ReferralTherapy: 'referralTherapy',
  ReleaseOfInformationAccommodationLetter: 'releaseOfInformationAccommodationLetter',
  ReleaseOfInformationStudent: 'releaseOfInformationStudent',
  ReleaseOfInformationSummaryOfTreatmentLetter: 'releaseOfInformationSummaryOfTreatmentLetter',
  ReleaseOfInformationThirdParty: 'releaseOfInformationThirdParty',
  RxFax: 'rxFax',
  RxPriorAuth: 'rxPriorAuth',
  RxQuestion: 'rxQuestion',
  RxRefill: 'rxRefill',
  RxSideEffect: 'rxSideEffect',
  Scheduling: 'scheduling',
  SchedulingProviderDefaultScheduleChangeInPolicy:
    'schedulingProviderDefaultScheduleChangeInPolicy',
  SchedulingProviderDefaultScheduleChangeOutOfPolicy:
    'schedulingProviderDefaultScheduleChangeOutOfPolicy',
  SchedulingProviderEmergencyTimeOff: 'schedulingProviderEmergencyTimeOff',
  SchedulingProviderGeneralTimeOffInPolicy: 'schedulingProviderGeneralTimeOffInPolicy',
  SchedulingProviderGeneralTimeOffOutOfPolicy: 'schedulingProviderGeneralTimeOffOutOfPolicy',
  SchedulingProviderScheduleAdjustmentInPolicy: 'schedulingProviderScheduleAdjustmentInPolicy',
  SchedulingProviderScheduleAdjustmentOutOfPolicy:
    'schedulingProviderScheduleAdjustmentOutOfPolicy',
  SchedulingStudentAppointment: 'schedulingStudentAppointment',
  SelfPayBilling: 'selfPayBilling',
  SelfPayPsychiatry: 'selfPayPsychiatry',
  SelfPayTherapy: 'selfPayTherapy',
  TechSupport: 'techSupport',
  TechSupportAudioVideoIssue: 'techSupportAudioVideoIssue',
  TechSupportDocumentationIssue: 'techSupportDocumentationIssue',
  TechSupportEmailPasswordReset: 'techSupportEmailPasswordReset',
  TechSupportGeneral: 'techSupportGeneral',
  TechSupportOzPasswordReset: 'techSupportOzPasswordReset',
  TechSupportSlackPasswordReset: 'techSupportSlackPasswordReset',
  TechSupportStudentAssistance: 'techSupportStudentAssistance',
  TransferOfCareCoaching: 'transferOfCareCoaching',
  TransferOfCarePsychiatry: 'transferOfCarePsychiatry',
  TransferOfCareTherapy: 'transferOfCareTherapy',
} as const;

export type TicketCategory = typeof TicketCategory[keyof typeof TicketCategory];
export const TicketPriority = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type TicketPriority = typeof TicketPriority[keyof typeof TicketPriority];
export const TicketStatus = {
  Active: 'Active',
  Blocked: 'Blocked',
  Complete: 'Complete',
  Deleted: 'Deleted',
} as const;

export type TicketStatus = typeof TicketStatus[keyof typeof TicketStatus];
export const TicketView = {
  Active: 'Active',
  AssignedToMe: 'AssignedToMe',
  Blocked: 'Blocked',
  New: 'New',
  Resolved: 'Resolved',
  Unresolved: 'Unresolved',
} as const;

export type TicketView = typeof TicketView[keyof typeof TicketView];
export const TimeAllocationPurpose = {
  Admin: 'Admin',
  Availability: 'Availability',
  TimeOff: 'TimeOff',
} as const;

export type TimeAllocationPurpose =
  typeof TimeAllocationPurpose[keyof typeof TimeAllocationPurpose];
export const TraitCategory = {
  Ethnicity: 'Ethnicity',
  Faith: 'Faith',
  Languages: 'Languages',
  Sexuality: 'Sexuality',
  Specialties: 'Specialties',
  TherapyTypes: 'TherapyTypes',
} as const;

export type TraitCategory = typeof TraitCategory[keyof typeof TraitCategory];
export interface TriageInfo {
  answers: Array<Answer>;
  assessmentId: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
}

/** Copy triggered on specific answers */
export interface TriggeredCopy {
  background?: Maybe<SectionColor>;
  border?: Maybe<SectionColor>;
  copy: Array<SectionCopy>;
  /** If depends on another key */
  dependsKey?: Maybe<Scalars['String']>;
  triggers: Array<Scalars['String']>;
}

export interface TwoFactorAuthResult {
  authToken?: Maybe<Scalars['String']>;
  codeValid: Scalars['Boolean'];
  deviceToken?: Maybe<Scalars['String']>;
}

export interface TwoFactorConfig {
  enabled: Scalars['Boolean'];
  id: Scalars['Float'];
  phone: Scalars['String'];
  phoneVerified: Scalars['Boolean'];
}

export interface UnfinishedSkillReflection {
  isComplete: Scalars['Boolean'];
  questions: Array<SkillReflectionQuestion>;
}

export const UniversityRelationship = {
  UniversityCollaborator: 'universityCollaborator',
  UniversityMonitor: 'universityMonitor',
} as const;

export type UniversityRelationship =
  typeof UniversityRelationship[keyof typeof UniversityRelationship];
export interface UnreadHubExternalMessage {
  mrn: Scalars['String'];
  totalCount: Scalars['Float'];
}

export interface UpdateCoupon {
  active: Scalars['Boolean'];
  autoApplyOnConversion: Scalars['Boolean'];
  careTypes: Array<CareType>;
  code: Scalars['String'];
  discountType: DiscountType;
  id?: InputMaybe<Scalars['Int']>;
  maxUses: Scalars['Float'];
  organizationId?: InputMaybe<Scalars['Float']>;
  percentDiscount?: InputMaybe<Scalars['Float']>;
  replacePriceCents?: InputMaybe<Scalars['Int']>;
}

export interface UpdateFileUpload {
  globallyShared: Scalars['Boolean'];
  name: Scalars['String'];
  type: UploadType;
  uploadId: Scalars['Float'];
}

export interface UpdateHubUserError {
  errorCode: UpdateHubUserErrorCode;
  message: Scalars['String'];
}

export const UpdateHubUserErrorCode = {
  DuplicatePhone: 'DuplicatePhone',
  InvalidEmail: 'InvalidEmail',
  InvalidPhone: 'InvalidPhone',
  InvalidState: 'InvalidState',
  InvalidZip: 'InvalidZip',
} as const;

export type UpdateHubUserErrorCode =
  typeof UpdateHubUserErrorCode[keyof typeof UpdateHubUserErrorCode];
export type UpdateHubUserResult = UpdateHubUserError | UpdateHubUserSuccess;

export interface UpdateHubUserSuccess {
  successMessage: Scalars['String'];
}

export interface UpdateOrganization {
  abbreviation?: InputMaybe<Scalars['String']>;
  departmentAddress?: InputMaybe<Scalars['String']>;
  departmentHours?: InputMaybe<Scalars['String']>;
  departmentName?: InputMaybe<Scalars['String']>;
  directorId?: InputMaybe<Scalars['Int']>;
  emailDomains?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}

export interface UpdateOrganizationEntitlementInput {
  config?: InputMaybe<Scalars['JSON']>;
  enabled: Scalars['Boolean'];
  key: Entitlement;
}

export interface UpdatePayerParams {
  billToProviderName: Scalars['Boolean'];
  id?: InputMaybe<Scalars['Float']>;
  inNetworkStates: Array<Scalars['String']>;
  name: Scalars['String'];
  standardPayerId: Scalars['String'];
  stateAddressOverrides: Array<PayerStateAddressOverrideInput>;
  tradingPartnerId: Scalars['String'];
  useAddressForAllStates?: InputMaybe<Scalars['String']>;
  visible: Scalars['Boolean'];
}

export interface UpdateProvider {
  activityStatus?: InputMaybe<Scalars['String']>;
  classification?: InputMaybe<Scalars['String']>;
  dosespotId?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  funFact?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  geoStates?: InputMaybe<Array<Scalars['String']>>;
  guaranteedPay?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  lastName?: InputMaybe<Scalars['String']>;
  npi?: InputMaybe<Scalars['String']>;
  personalTitle?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postNominalTitles?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  providerType?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UpdateStaffRole>;
}

export interface UpdateProviderStateAllocationsModelInput {
  id: Scalars['Int'];
  maxHours: Scalars['Float'];
  maxIntakeHours: Scalars['Float'];
}

export interface UpdateSamlConfiguration {
  authnRequestBinding?: InputMaybe<Scalars['String']>;
  automaticallyUpdateEmail: Scalars['Boolean'];
  campusOrgMappings?: InputMaybe<Array<CampusOrgMappingInput>>;
  certificates: Array<Scalars['String']>;
  disableRequestedAuthnContext: Scalars['Boolean'];
  enableManualUniqueIdEdits: Scalars['Boolean'];
  entryPoint: Scalars['String'];
  issuer?: InputMaybe<Scalars['String']>;
  profileCampusField?: InputMaybe<Scalars['String']>;
  profileEmailField: Scalars['String'];
  profileMatchingField?: InputMaybe<Scalars['String']>;
  profileUniqueIdField: Scalars['String'];
}

export const UpdateStaffRole = {
  UniversityAdmin: 'universityAdmin',
  UniversityReferrer: 'universityReferrer',
  UniversityStaff: 'universityStaff',
  UniversitySupportingClinician: 'universitySupportingClinician',
} as const;

export type UpdateStaffRole = typeof UpdateStaffRole[keyof typeof UpdateStaffRole];
export interface UpdateUser2Result {
  id: Scalars['Int'];
}

export interface UpdateUserInput {
  authToken?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['String']>;
  cardToken?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationIdentification?: InputMaybe<Scalars['String']>;
  pharmacyId?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  preferredName?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<AddressInput>;
  pronouns?: InputMaybe<Scalars['String']>;
  secondaryState?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
}

export interface Upload {
  canEdit?: Maybe<Scalars['Boolean']>;
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdByProvider?: Maybe<Provider>;
  globallyShared?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  type: UploadType;
  url: Scalars['String'];
}

export const UploadType = {
  InsuranceDetails: 'InsuranceDetails',
  LabResults: 'LabResults',
  Other: 'Other',
  PatientResource: 'PatientResource',
  ReferralNote: 'ReferralNote',
} as const;

export type UploadType = typeof UploadType[keyof typeof UploadType];
export interface UpsertProviderInsuranceCredential {
  active: Scalars['Boolean'];
  payerId: Scalars['Float'];
  providerId: Scalars['Float'];
  state: Scalars['String'];
}

export interface UseOtherApp {
  name: Scalars['String'];
  roleDescription: Scalars['String'];
  url: Scalars['String'];
}

export interface User {
  adminAlerts: Array<AdminAlert>;
  appointmentTemplates: Array<AppointmentTemplate>;
  assessmentInfo: UserAssessmentInfo;
  authToken?: Maybe<Scalars['String']>;
  awaitingMinorConsent: Scalars['Boolean'];
  /** get first of each questionnaire taken by the user */
  baselineQuestionnaires: Array<QuestionnaireResult>;
  billingAddress?: Maybe<Address>;
  birthDate?: Maybe<Scalars['Date']>;
  campusTeam?: Maybe<Array<CampusTeamRelationship>>;
  careFlows: Array<UserCareFlow>;
  careStatus: CareStatus;
  careTeam: Array<Provider>;
  careTeamChannel: Channel;
  careTransitions: Array<ContinuityOfCareModel>;
  careTypes: Array<CareType>;
  cocEligibility: Array<ContinuityOfCareEligibilityValidation>;
  /** Care eligibility form responses for continuity of care */
  continuityOfCareEligibility: Array<ContinuityOfCareEligibilityModel>;
  continuityOfCareTransitions: Array<ContinuityOfCareModel>;
  counselingCenter?: Maybe<UserOrgCounselingCenter>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  dataSharingConsent?: Maybe<DataSharingConsent>;
  dataSharingRequest?: Maybe<DataSharingRequest>;
  editableFields: Array<EditableUserFields>;
  email: Scalars['String'];
  /** Returns email if the requester has permission to see it, and null otherwise. Helpful for user lists. */
  emailIfVisible?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  emergencyContacts: Array<Contact>;
  engagementTags: Array<EngagementTag>;
  firstName: Scalars['String'];
  flaggedForOutreachAt?: Maybe<Scalars['DateTime']>;
  hasADHD: Scalars['Boolean'];
  hasAnxiety: Scalars['Boolean'];
  hasDepression: Scalars['Boolean'];
  hasDosespot: Scalars['Boolean'];
  /** Get insurance eligibility without restricted check */
  hasEligibleInsurance: Scalars['Boolean'];
  hasOutstandingPrescription: Scalars['Boolean'];
  hasPaymentMethod: Scalars['Boolean'];
  hasProvider: Scalars['Boolean'];
  hasSafetyPlan: Scalars['Boolean'];
  hasSso?: Maybe<Scalars['Boolean']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  hasTherapist: Scalars['Boolean'];
  hubOnboardingStatus?: Maybe<HubStatusForUser>;
  id: Scalars['Int'];
  identificationCard?: Maybe<Upload>;
  identityVerified: Scalars['Boolean'];
  insuranceDetails?: Maybe<InsuranceDetails>;
  insuranceDetailsIfBookingForSelfPayPatient?: Maybe<InsuranceDetails>;
  insuranceDetailsIfVisible?: Maybe<InsuranceDetails>;
  intakeComplete: Scalars['Boolean'];
  /** makes stripe request */
  invoices: Array<Invoice>;
  isPaymentMethodValid: Scalars['Boolean'];
  isTherapyOnboarding: Scalars['Boolean'];
  lastAppointment?: Maybe<CalendarEvent>;
  lastName: Scalars['String'];
  /** Last manual outreach made for this user */
  lastOutreachAttempt?: Maybe<Scalars['DateTime']>;
  lastPrescriptionWrittenDate?: Maybe<Scalars['DateTime']>;
  latestMedicalHistoryResult?: Maybe<MedicalHistory>;
  /** get latest of each questionnaire taken by the user */
  latestQuestionnaires: Array<QuestionnaireResult>;
  mantraAdminCareTeam: Array<Provider>;
  needsScales: Scalars['Boolean'];
  nextAppointment?: Maybe<CalendarEvent>;
  nextAppointmentTime?: Maybe<Scalars['DateTime']>;
  nextScales?: Maybe<Scalars['DateTime']>;
  notes: Array<MedicalNote>;
  onHoldOrActiveCareTypes: Array<CareType>;
  organization?: Maybe<Organization>;
  organizationIdentification?: Maybe<Scalars['String']>;
  origin: UserOrigin;
  /** makes stripe request */
  paymentInfo?: Maybe<PaymentProfile>;
  pendingSponsoredCareReenrollment: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  primaryAddress?: Maybe<Address>;
  /** state from primaryAddress without restricted check */
  primaryAddressState?: Maybe<Scalars['String']>;
  primaryEmergencyContact?: Maybe<Contact>;
  privateChannel: Channel;
  privateChannels?: Maybe<Array<Channel>>;
  privateChannelWith?: Maybe<Channel>;
  pronouns?: Maybe<Scalars['String']>;
  provider?: Maybe<Provider>;
  providerForCareType?: Maybe<Provider>;
  providerIfVisible?: Maybe<Provider>;
  recordViewStatus: UserRecordViewStatus;
  referralComplete?: Maybe<Scalars['Boolean']>;
  referralSource: ReferralSource;
  referredByUniversityReferrer: Scalars['Boolean'];
  referringProvider?: Maybe<Provider>;
  safetyPlan?: Maybe<SafetyPlan>;
  samlUniqueId?: Maybe<Scalars['String']>;
  screenedOut?: Maybe<Scalars['Boolean']>;
  secondaryState?: Maybe<StateCodes>;
  selfie?: Maybe<Upload>;
  suggestedProviders: Array<Provider>;
  tasks?: Maybe<Array<Task>>;
  taskStatus: Scalars['String'];
  taskStatusIfVisible?: Maybe<Scalars['String']>;
  therapist?: Maybe<Provider>;
  therapistIfVisible?: Maybe<Provider>;
  timeZone?: Maybe<Scalars['String']>;
  triageInfo?: Maybe<Array<TriageInfo>>;
  uid: Scalars['String'];
  unansweredMessagesCount: Scalars['Int'];
  universityCareTeam: Array<Provider>;
  universityCareTeamPrimary?: Maybe<Provider>;
  unsubscribedAt?: Maybe<Scalars['DateTime']>;
  uploads: Array<Upload>;
  verificationStatus: Scalars['String'];
}

export type UserLastAppointmentArgs = {
  appointmentType?: InputMaybe<AppointmentType>;
  careType: CareType;
  status?: InputMaybe<Scalars['String']>;
};

export type UserNextAppointmentArgs = {
  appointmentType?: InputMaybe<AppointmentType>;
  careType?: InputMaybe<CareType>;
  status?: InputMaybe<Scalars['String']>;
};

export type UserPrivateChannelArgs = {
  providerId?: InputMaybe<Scalars['Float']>;
};

export type UserPrivateChannelWithArgs = {
  kind: Scalars['String'];
};

export type UserProviderForCareTypeArgs = {
  careType: CareType;
};

export interface UserAssessmentInfo {
  additionalDiagnoses?: Maybe<Array<Scalars['String']>>;
  additionalProviderContact?: Maybe<Scalars['String']>;
  additionalProviderName?: Maybe<Scalars['String']>;
  allergies?: Maybe<Array<Scalars['String']>>;
  diagnoses?: Maybe<Array<Scalars['String']>>;
  medication?: Maybe<Array<Prescription>>;
  otherMeds?: Maybe<Array<Scalars['String']>>;
  primaryDiagnosis?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  psychiatricHistory?: Maybe<Array<Scalars['String']>>;
  riskLevel?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  suicidal?: Maybe<Array<Scalars['String']>>;
  wellnessGoals?: Maybe<Array<Scalars['String']>>;
}

export interface UserCareFlow {
  /** Broad activity check without restricted permission */
  canBookAppointment: Scalars['Boolean'];
  careSessionInfo?: Maybe<CareSessionInfo>;
  careStatus: CareStatus;
  careType: CareType;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  organizationCareFlow?: Maybe<OrganizationCareFlow>;
  paymentSource: PaymentSource;
  pendingTransition?: Maybe<ContinuityOfCareModel>;
  providerStatesAvailable: Array<Scalars['String']>;
  taskStatus: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface UserCareFlowInput {
  careStatus?: InputMaybe<CareStatus>;
  careType: CareType;
  paymentSource: PaymentSource;
  sessionLimitOverride?: InputMaybe<Scalars['Int']>;
}

export interface UserCoachingHealthieStatus {
  userCoachingHealthieStatus?: Maybe<CoachingStatus>;
}

export interface UserCoachingStatus {
  userCoachingStatus?: Maybe<CoachingStatus>;
}

export interface UserDemographics {
  academicProgram: Scalars['String'];
  createdAt: Scalars['DateTime'];
  ethnicity: Scalars['String'];
  genderIdentity: Scalars['String'];
  id: Scalars['Float'];
  isInternationalStudent: Scalars['Boolean'];
  sexualOrientation: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  yearInProgram: Scalars['String'];
}

export interface UserDiagnosis {
  category: Scalars['String'];
  code: Scalars['String'];
  key: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  userId: Scalars['Float'];
}

export interface UserHealthieStatus {
  userIsInitialized: Scalars['Boolean'];
}

export interface UserIsLoggedIn {
  authToken?: Maybe<Scalars['String']>;
  isLoggedIn: Scalars['Boolean'];
}

export interface UserListParams {
  careStatus?: InputMaybe<CareStatus>;
  careTeamMemberId?: InputMaybe<Scalars['Float']>;
  cursor?: InputMaybe<Scalars['String']>;
  dtc?: InputMaybe<Scalars['Boolean']>;
  excludeMyPatients?: InputMaybe<Scalars['Boolean']>;
  geoState?: InputMaybe<Scalars['String']>;
  includeAllPatients?: InputMaybe<Scalars['Boolean']>;
  includeNewPatients?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Float']>;
  nextSteps?: InputMaybe<NextStepsFilter>;
  organizationId?: InputMaybe<Scalars['Float']>;
  providerId?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']>;
  taskStatus?: InputMaybe<TaskStatusFilter>;
}

export const UserNotificationKey = {
  BipolarScreening: 'bipolarScreening',
  CompleteSafetyPlan: 'completeSafetyPlan',
  DataSharingRequest: 'dataSharingRequest',
  InvalidEmergencyContact: 'invalidEmergencyContact',
  InvalidPaymentMethod: 'invalidPaymentMethod',
  MonthlyScales: 'monthlyScales',
  PendingCareTransition: 'pendingCareTransition',
  PrescriptionSubmitted: 'prescriptionSubmitted',
  ProviderFeedback: 'providerFeedback',
  ReactivateSponsoredPsychiatry: 'reactivateSponsoredPsychiatry',
  ReactivateSponsoredTherapy: 'reactivateSponsoredTherapy',
  ScheduleAppointmentPsychiatry: 'scheduleAppointmentPsychiatry',
  ScheduleAppointmentTherapy: 'scheduleAppointmentTherapy',
  UnreadMessages: 'unreadMessages',
} as const;

export type UserNotificationKey = typeof UserNotificationKey[keyof typeof UserNotificationKey];
export interface UserOrgCounselingCenter {
  departmentAddress: Scalars['String'];
  departmentHours: Scalars['String'];
  departmentName: Scalars['String'];
}

export const UserOrigin = {
  Dtc: 'Dtc',
  Student: 'Student',
} as const;

export type UserOrigin = typeof UserOrigin[keyof typeof UserOrigin];
export const UserRecordViewStatus = {
  Full: 'Full',
  Limited: 'Limited',
  Restricted: 'Restricted',
} as const;

export type UserRecordViewStatus = typeof UserRecordViewStatus[keyof typeof UserRecordViewStatus];
export interface UserSkillAssignments {
  pagination: AssignmentPagination;
  skillAssignments: Array<SkillAssignmentModel>;
}

export interface UserSkillReflections {
  pagination: ReflectionPagination;
  reflections: Array<SkillReflection>;
}

export interface UserSponsoredReactivation {
  careTypes: Array<CareType>;
  isSelfReactivationAllowed: Scalars['Boolean'];
  reactivationCopy?: Maybe<Scalars['String']>;
  userHasRemainingAppts: Scalars['Boolean'];
}

export interface UtilizationHours {
  hoursScheduled?: Maybe<Scalars['Float']>;
  hoursUtilized?: Maybe<Scalars['Float']>;
  intakesScheduled?: Maybe<Scalars['Float']>;
  intakesUtilized?: Maybe<Scalars['Float']>;
  maxHours?: Maybe<Scalars['Float']>;
  maxIntakeHours?: Maybe<Scalars['Int']>;
}

export interface UtilizationVirtualModel {
  carePeriod: OrganizationCarePeriodModel;
  weeklyUtilizationRecords: Array<OrganizationUtilizationWeek>;
}

export interface VisitAttendance {
  attendance: Array<NumericKeyValue>;
  carePeriod?: Maybe<OrganizationCarePeriodModel>;
}

export type AvailableCoachesForEmbedPatientQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type AvailableCoachesForEmbedPatientQuery = {
  availableCoachesForEmbedPatient: Array<{
    coachId: string;
    name: string;
    isCurrentCoach: boolean;
  }>;
};

export type EmbedPatientCoachingStatusQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type EmbedPatientCoachingStatusQuery = {
  embedPatientCoachingStatus?: CoachingStatus | null;
};

export type AssignCoachToEmbedPatientMutationVariables = Exact<{
  userId: Scalars['Int'];
  coachId: Scalars['String'];
}>;

export type AssignCoachToEmbedPatientMutation = { assignCoachToEmbedPatient: boolean };

export type MarkEmbedPatientCoachingInactiveMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type MarkEmbedPatientCoachingInactiveMutation = {
  markEmbedPatientCoachingInactive: boolean;
};

export type EmbedPatientIdFromHealthieIdQueryVariables = Exact<{
  healthieId: Scalars['String'];
}>;

export type EmbedPatientIdFromHealthieIdQuery = { embedPatientIdFromHealthieId?: number | null };

export type EmbedPatientQueryVariables = Exact<{
  patientId: Scalars['Float'];
}>;

export type EmbedPatientQuery = {
  embedPatient?: { name: string; organization?: { name: string } | null } | null;
};

export type FacultyHubReferralMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type FacultyHubReferralMutation = {
  facultyHubReferral:
    | { __typename: 'FacultyHubReferralSuccess'; successMessage: string }
    | { __typename: 'ReferralError'; errorCode: ReferralErrorCode };
};

export type GetAssignableSkillsQueryVariables = Exact<{
  patientId: Scalars['Float'];
}>;

export type GetAssignableSkillsQuery = {
  getAssignableSkills: Array<{ moduleId: string; skillId: string; title: string }>;
};

export type GetSkillsAssignedToUserQueryVariables = Exact<{
  userId: Scalars['Float'];
  page: Scalars['Float'];
  perPage: Scalars['Float'];
}>;

export type GetSkillsAssignedToUserQuery = {
  getSkillsAssignedToUser: {
    pagination: { page: number; totalPages: number };
    skillAssignments: Array<{
      id: number;
      userId: number;
      assignedById: number;
      skillId: string;
      moduleId: string;
      userHasSeen: boolean;
      assignerHasSeen: boolean;
      completedAt?: string | null;
      startedAt?: string | null;
      createdAt: string;
      skillTitle: string;
      skillReflection?: {
        title: string;
        questions: Array<{ question: string; answer: string }>;
      } | null;
    }>;
  };
};

export type AssignSkillToUserMutationVariables = Exact<{
  skillId: Scalars['String'];
  moduleId: Scalars['String'];
  userId: Scalars['Float'];
}>;

export type AssignSkillToUserMutation = {
  assignSkillToUser: { isDuplicate: boolean; wasAssigned: boolean };
};

export type MarkSkillAssignmentSeenByAssignerMutationVariables = Exact<{
  skillAssignmentId: Scalars['Float'];
}>;

export type MarkSkillAssignmentSeenByAssignerMutation = {
  markSkillAssignmentSeenByAssigner: boolean;
};

export type UnassignSkillFromUserMutationVariables = Exact<{
  skillAssignmentId: Scalars['Float'];
  userId: Scalars['Float'];
}>;

export type UnassignSkillFromUserMutation = { unassignSkillFromUser: boolean };

export const AvailableCoachesForEmbedPatientDocument = gql`
  query availableCoachesForEmbedPatient($userId: Int!) {
    availableCoachesForEmbedPatient(userId: $userId) {
      coachId
      name
      isCurrentCoach
    }
  }
`;

/**
 * __useAvailableCoachesForEmbedPatientQuery__
 *
 * To run a query within a React component, call `useAvailableCoachesForEmbedPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoachesForEmbedPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoachesForEmbedPatientQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAvailableCoachesForEmbedPatientQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AvailableCoachesForEmbedPatientQuery,
    AvailableCoachesForEmbedPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AvailableCoachesForEmbedPatientQuery,
    AvailableCoachesForEmbedPatientQueryVariables
  >(AvailableCoachesForEmbedPatientDocument, options);
}
export function useAvailableCoachesForEmbedPatientLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AvailableCoachesForEmbedPatientQuery,
    AvailableCoachesForEmbedPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AvailableCoachesForEmbedPatientQuery,
    AvailableCoachesForEmbedPatientQueryVariables
  >(AvailableCoachesForEmbedPatientDocument, options);
}
export type AvailableCoachesForEmbedPatientQueryHookResult = ReturnType<
  typeof useAvailableCoachesForEmbedPatientQuery
>;
export type AvailableCoachesForEmbedPatientLazyQueryHookResult = ReturnType<
  typeof useAvailableCoachesForEmbedPatientLazyQuery
>;
export type AvailableCoachesForEmbedPatientQueryResult = ApolloReactCommon.QueryResult<
  AvailableCoachesForEmbedPatientQuery,
  AvailableCoachesForEmbedPatientQueryVariables
>;
export const EmbedPatientCoachingStatusDocument = gql`
  query embedPatientCoachingStatus($userId: Int!) {
    embedPatientCoachingStatus(userId: $userId)
  }
`;

/**
 * __useEmbedPatientCoachingStatusQuery__
 *
 * To run a query within a React component, call `useEmbedPatientCoachingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmbedPatientCoachingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmbedPatientCoachingStatusQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEmbedPatientCoachingStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EmbedPatientCoachingStatusQuery,
    EmbedPatientCoachingStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EmbedPatientCoachingStatusQuery,
    EmbedPatientCoachingStatusQueryVariables
  >(EmbedPatientCoachingStatusDocument, options);
}
export function useEmbedPatientCoachingStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EmbedPatientCoachingStatusQuery,
    EmbedPatientCoachingStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EmbedPatientCoachingStatusQuery,
    EmbedPatientCoachingStatusQueryVariables
  >(EmbedPatientCoachingStatusDocument, options);
}
export type EmbedPatientCoachingStatusQueryHookResult = ReturnType<
  typeof useEmbedPatientCoachingStatusQuery
>;
export type EmbedPatientCoachingStatusLazyQueryHookResult = ReturnType<
  typeof useEmbedPatientCoachingStatusLazyQuery
>;
export type EmbedPatientCoachingStatusQueryResult = ApolloReactCommon.QueryResult<
  EmbedPatientCoachingStatusQuery,
  EmbedPatientCoachingStatusQueryVariables
>;
export const AssignCoachToEmbedPatientDocument = gql`
  mutation assignCoachToEmbedPatient($userId: Int!, $coachId: String!) {
    assignCoachToEmbedPatient(userId: $userId, coachId: $coachId)
  }
`;
export type AssignCoachToEmbedPatientMutationFn = ApolloReactCommon.MutationFunction<
  AssignCoachToEmbedPatientMutation,
  AssignCoachToEmbedPatientMutationVariables
>;

/**
 * __useAssignCoachToEmbedPatientMutation__
 *
 * To run a mutation, you first call `useAssignCoachToEmbedPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCoachToEmbedPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCoachToEmbedPatientMutation, { data, loading, error }] = useAssignCoachToEmbedPatientMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      coachId: // value for 'coachId'
 *   },
 * });
 */
export function useAssignCoachToEmbedPatientMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssignCoachToEmbedPatientMutation,
    AssignCoachToEmbedPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssignCoachToEmbedPatientMutation,
    AssignCoachToEmbedPatientMutationVariables
  >(AssignCoachToEmbedPatientDocument, options);
}
export type AssignCoachToEmbedPatientMutationHookResult = ReturnType<
  typeof useAssignCoachToEmbedPatientMutation
>;
export type AssignCoachToEmbedPatientMutationResult =
  ApolloReactCommon.MutationResult<AssignCoachToEmbedPatientMutation>;
export type AssignCoachToEmbedPatientMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AssignCoachToEmbedPatientMutation,
  AssignCoachToEmbedPatientMutationVariables
>;
export const MarkEmbedPatientCoachingInactiveDocument = gql`
  mutation markEmbedPatientCoachingInactive($userId: Int!) {
    markEmbedPatientCoachingInactive(userId: $userId)
  }
`;
export type MarkEmbedPatientCoachingInactiveMutationFn = ApolloReactCommon.MutationFunction<
  MarkEmbedPatientCoachingInactiveMutation,
  MarkEmbedPatientCoachingInactiveMutationVariables
>;

/**
 * __useMarkEmbedPatientCoachingInactiveMutation__
 *
 * To run a mutation, you first call `useMarkEmbedPatientCoachingInactiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkEmbedPatientCoachingInactiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markEmbedPatientCoachingInactiveMutation, { data, loading, error }] = useMarkEmbedPatientCoachingInactiveMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMarkEmbedPatientCoachingInactiveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkEmbedPatientCoachingInactiveMutation,
    MarkEmbedPatientCoachingInactiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkEmbedPatientCoachingInactiveMutation,
    MarkEmbedPatientCoachingInactiveMutationVariables
  >(MarkEmbedPatientCoachingInactiveDocument, options);
}
export type MarkEmbedPatientCoachingInactiveMutationHookResult = ReturnType<
  typeof useMarkEmbedPatientCoachingInactiveMutation
>;
export type MarkEmbedPatientCoachingInactiveMutationResult =
  ApolloReactCommon.MutationResult<MarkEmbedPatientCoachingInactiveMutation>;
export type MarkEmbedPatientCoachingInactiveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkEmbedPatientCoachingInactiveMutation,
  MarkEmbedPatientCoachingInactiveMutationVariables
>;
export const EmbedPatientIdFromHealthieIdDocument = gql`
  query embedPatientIdFromHealthieId($healthieId: String!) {
    embedPatientIdFromHealthieId(healthieId: $healthieId)
  }
`;

/**
 * __useEmbedPatientIdFromHealthieIdQuery__
 *
 * To run a query within a React component, call `useEmbedPatientIdFromHealthieIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmbedPatientIdFromHealthieIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmbedPatientIdFromHealthieIdQuery({
 *   variables: {
 *      healthieId: // value for 'healthieId'
 *   },
 * });
 */
export function useEmbedPatientIdFromHealthieIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EmbedPatientIdFromHealthieIdQuery,
    EmbedPatientIdFromHealthieIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EmbedPatientIdFromHealthieIdQuery,
    EmbedPatientIdFromHealthieIdQueryVariables
  >(EmbedPatientIdFromHealthieIdDocument, options);
}
export function useEmbedPatientIdFromHealthieIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EmbedPatientIdFromHealthieIdQuery,
    EmbedPatientIdFromHealthieIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EmbedPatientIdFromHealthieIdQuery,
    EmbedPatientIdFromHealthieIdQueryVariables
  >(EmbedPatientIdFromHealthieIdDocument, options);
}
export type EmbedPatientIdFromHealthieIdQueryHookResult = ReturnType<
  typeof useEmbedPatientIdFromHealthieIdQuery
>;
export type EmbedPatientIdFromHealthieIdLazyQueryHookResult = ReturnType<
  typeof useEmbedPatientIdFromHealthieIdLazyQuery
>;
export type EmbedPatientIdFromHealthieIdQueryResult = ApolloReactCommon.QueryResult<
  EmbedPatientIdFromHealthieIdQuery,
  EmbedPatientIdFromHealthieIdQueryVariables
>;
export const EmbedPatientDocument = gql`
  query embedPatient($patientId: Float!) {
    embedPatient(patientId: $patientId) {
      name
      organization {
        name
      }
    }
  }
`;

/**
 * __useEmbedPatientQuery__
 *
 * To run a query within a React component, call `useEmbedPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmbedPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmbedPatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useEmbedPatientQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EmbedPatientQuery, EmbedPatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EmbedPatientQuery, EmbedPatientQueryVariables>(
    EmbedPatientDocument,
    options
  );
}
export function useEmbedPatientLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmbedPatientQuery, EmbedPatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EmbedPatientQuery, EmbedPatientQueryVariables>(
    EmbedPatientDocument,
    options
  );
}
export type EmbedPatientQueryHookResult = ReturnType<typeof useEmbedPatientQuery>;
export type EmbedPatientLazyQueryHookResult = ReturnType<typeof useEmbedPatientLazyQuery>;
export type EmbedPatientQueryResult = ApolloReactCommon.QueryResult<
  EmbedPatientQuery,
  EmbedPatientQueryVariables
>;
export const FacultyHubReferralDocument = gql`
  mutation facultyHubReferral($email: String!) {
    facultyHubReferral(email: $email) {
      __typename
      ... on FacultyHubReferralSuccess {
        successMessage
      }
      ... on ReferralError {
        errorCode
      }
    }
  }
`;
export type FacultyHubReferralMutationFn = ApolloReactCommon.MutationFunction<
  FacultyHubReferralMutation,
  FacultyHubReferralMutationVariables
>;

/**
 * __useFacultyHubReferralMutation__
 *
 * To run a mutation, you first call `useFacultyHubReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacultyHubReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facultyHubReferralMutation, { data, loading, error }] = useFacultyHubReferralMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFacultyHubReferralMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FacultyHubReferralMutation,
    FacultyHubReferralMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FacultyHubReferralMutation,
    FacultyHubReferralMutationVariables
  >(FacultyHubReferralDocument, options);
}
export type FacultyHubReferralMutationHookResult = ReturnType<typeof useFacultyHubReferralMutation>;
export type FacultyHubReferralMutationResult =
  ApolloReactCommon.MutationResult<FacultyHubReferralMutation>;
export type FacultyHubReferralMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FacultyHubReferralMutation,
  FacultyHubReferralMutationVariables
>;
export const GetAssignableSkillsDocument = gql`
  query getAssignableSkills($patientId: Float!) {
    getAssignableSkills(userId: $patientId) {
      moduleId
      skillId
      title
    }
  }
`;

/**
 * __useGetAssignableSkillsQuery__
 *
 * To run a query within a React component, call `useGetAssignableSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignableSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignableSkillsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetAssignableSkillsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAssignableSkillsQuery,
    GetAssignableSkillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetAssignableSkillsQuery, GetAssignableSkillsQueryVariables>(
    GetAssignableSkillsDocument,
    options
  );
}
export function useGetAssignableSkillsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAssignableSkillsQuery,
    GetAssignableSkillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetAssignableSkillsQuery, GetAssignableSkillsQueryVariables>(
    GetAssignableSkillsDocument,
    options
  );
}
export type GetAssignableSkillsQueryHookResult = ReturnType<typeof useGetAssignableSkillsQuery>;
export type GetAssignableSkillsLazyQueryHookResult = ReturnType<
  typeof useGetAssignableSkillsLazyQuery
>;
export type GetAssignableSkillsQueryResult = ApolloReactCommon.QueryResult<
  GetAssignableSkillsQuery,
  GetAssignableSkillsQueryVariables
>;
export const GetSkillsAssignedToUserDocument = gql`
  query getSkillsAssignedToUser($userId: Float!, $page: Float!, $perPage: Float!) {
    getSkillsAssignedToUser(userId: $userId, page: $page, perPage: $perPage) {
      pagination {
        page
        totalPages
      }
      skillAssignments {
        id
        userId
        assignedById
        skillId
        moduleId
        userHasSeen
        assignerHasSeen
        completedAt
        startedAt
        createdAt
        skillTitle
        skillReflection {
          title
          questions {
            question
            answer
          }
        }
      }
    }
  }
`;

/**
 * __useGetSkillsAssignedToUserQuery__
 *
 * To run a query within a React component, call `useGetSkillsAssignedToUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkillsAssignedToUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkillsAssignedToUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetSkillsAssignedToUserQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSkillsAssignedToUserQuery,
    GetSkillsAssignedToUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSkillsAssignedToUserQuery,
    GetSkillsAssignedToUserQueryVariables
  >(GetSkillsAssignedToUserDocument, options);
}
export function useGetSkillsAssignedToUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSkillsAssignedToUserQuery,
    GetSkillsAssignedToUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSkillsAssignedToUserQuery,
    GetSkillsAssignedToUserQueryVariables
  >(GetSkillsAssignedToUserDocument, options);
}
export type GetSkillsAssignedToUserQueryHookResult = ReturnType<
  typeof useGetSkillsAssignedToUserQuery
>;
export type GetSkillsAssignedToUserLazyQueryHookResult = ReturnType<
  typeof useGetSkillsAssignedToUserLazyQuery
>;
export type GetSkillsAssignedToUserQueryResult = ApolloReactCommon.QueryResult<
  GetSkillsAssignedToUserQuery,
  GetSkillsAssignedToUserQueryVariables
>;
export const AssignSkillToUserDocument = gql`
  mutation assignSkillToUser($skillId: String!, $moduleId: String!, $userId: Float!) {
    assignSkillToUser(skillId: $skillId, moduleId: $moduleId, userId: $userId) {
      isDuplicate
      wasAssigned
    }
  }
`;
export type AssignSkillToUserMutationFn = ApolloReactCommon.MutationFunction<
  AssignSkillToUserMutation,
  AssignSkillToUserMutationVariables
>;

/**
 * __useAssignSkillToUserMutation__
 *
 * To run a mutation, you first call `useAssignSkillToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSkillToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSkillToUserMutation, { data, loading, error }] = useAssignSkillToUserMutation({
 *   variables: {
 *      skillId: // value for 'skillId'
 *      moduleId: // value for 'moduleId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssignSkillToUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssignSkillToUserMutation,
    AssignSkillToUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AssignSkillToUserMutation,
    AssignSkillToUserMutationVariables
  >(AssignSkillToUserDocument, options);
}
export type AssignSkillToUserMutationHookResult = ReturnType<typeof useAssignSkillToUserMutation>;
export type AssignSkillToUserMutationResult =
  ApolloReactCommon.MutationResult<AssignSkillToUserMutation>;
export type AssignSkillToUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AssignSkillToUserMutation,
  AssignSkillToUserMutationVariables
>;
export const MarkSkillAssignmentSeenByAssignerDocument = gql`
  mutation markSkillAssignmentSeenByAssigner($skillAssignmentId: Float!) {
    markSkillAssignmentSeenByAssigner(skillAssignmentId: $skillAssignmentId)
  }
`;
export type MarkSkillAssignmentSeenByAssignerMutationFn = ApolloReactCommon.MutationFunction<
  MarkSkillAssignmentSeenByAssignerMutation,
  MarkSkillAssignmentSeenByAssignerMutationVariables
>;

/**
 * __useMarkSkillAssignmentSeenByAssignerMutation__
 *
 * To run a mutation, you first call `useMarkSkillAssignmentSeenByAssignerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSkillAssignmentSeenByAssignerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSkillAssignmentSeenByAssignerMutation, { data, loading, error }] = useMarkSkillAssignmentSeenByAssignerMutation({
 *   variables: {
 *      skillAssignmentId: // value for 'skillAssignmentId'
 *   },
 * });
 */
export function useMarkSkillAssignmentSeenByAssignerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkSkillAssignmentSeenByAssignerMutation,
    MarkSkillAssignmentSeenByAssignerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkSkillAssignmentSeenByAssignerMutation,
    MarkSkillAssignmentSeenByAssignerMutationVariables
  >(MarkSkillAssignmentSeenByAssignerDocument, options);
}
export type MarkSkillAssignmentSeenByAssignerMutationHookResult = ReturnType<
  typeof useMarkSkillAssignmentSeenByAssignerMutation
>;
export type MarkSkillAssignmentSeenByAssignerMutationResult =
  ApolloReactCommon.MutationResult<MarkSkillAssignmentSeenByAssignerMutation>;
export type MarkSkillAssignmentSeenByAssignerMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    MarkSkillAssignmentSeenByAssignerMutation,
    MarkSkillAssignmentSeenByAssignerMutationVariables
  >;
export const UnassignSkillFromUserDocument = gql`
  mutation unassignSkillFromUser($skillAssignmentId: Float!, $userId: Float!) {
    unassignSkillFromUser(skillAssignmentId: $skillAssignmentId, userId: $userId)
  }
`;
export type UnassignSkillFromUserMutationFn = ApolloReactCommon.MutationFunction<
  UnassignSkillFromUserMutation,
  UnassignSkillFromUserMutationVariables
>;

/**
 * __useUnassignSkillFromUserMutation__
 *
 * To run a mutation, you first call `useUnassignSkillFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignSkillFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignSkillFromUserMutation, { data, loading, error }] = useUnassignSkillFromUserMutation({
 *   variables: {
 *      skillAssignmentId: // value for 'skillAssignmentId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnassignSkillFromUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnassignSkillFromUserMutation,
    UnassignSkillFromUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnassignSkillFromUserMutation,
    UnassignSkillFromUserMutationVariables
  >(UnassignSkillFromUserDocument, options);
}
export type UnassignSkillFromUserMutationHookResult = ReturnType<
  typeof useUnassignSkillFromUserMutation
>;
export type UnassignSkillFromUserMutationResult =
  ApolloReactCommon.MutationResult<UnassignSkillFromUserMutation>;
export type UnassignSkillFromUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnassignSkillFromUserMutation,
  UnassignSkillFromUserMutationVariables
>;
