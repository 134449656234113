import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { GenericSmallModal } from '../../../../Components/Modal';
import { FinalButton } from '../../../../Components/FinalButton';
import { CareType, PeriodType, useOrganizationCarePeriodsQuery } from '../../../../graphQL';
import { useDrilldownContext } from '../../helpers';
import { Text } from '../../styles';
import { Cancel } from './Cancel';
import { SelfPay } from './SelfPay';
import { Sponsored } from './Sponsored';
import { COCPathProps } from './types';

interface Props {
  careEnd?: Moment;
  onClose: () => void;
  careType: CareType;
}

type Path = 'self-pay' | 'sponsored' | 'cancel';

// Shows Next Steps for Continuity of Care
export function COCModal({ careEnd, onClose, careType }: Props) {
  const [path, setPath] = useState<Path | undefined>();
  const pathProps: COCPathProps = {
    careType,
    careEnd,
    onClose,
    onGoBack: () => setPath(undefined),
  };
  return (
    <GenericSmallModal isOpen onClose={onClose}>
      {path === 'self-pay' && <SelfPay {...pathProps} />}
      {path === 'sponsored' && <Sponsored {...pathProps} />}
      {path === 'cancel' && <Cancel {...pathProps} />}
      {!path && <SelectPath onSetPath={setPath} onClose={onClose} careEnd={careEnd} />}
    </GenericSmallModal>
  );
}

interface SelectPathProps {
  onSetPath: (path: Path) => void;
  careEnd?: Moment;
  onClose: () => void;
}

function SelectPath({ onSetPath, onClose }: SelectPathProps) {
  const { user } = useDrilldownContext();
  const { data } = useOrganizationCarePeriodsQuery({
    variables: {
      organizationId: user?.organization?.id!,
    },
  });
  const today = moment();
  const nextCarePeriod = data?.organization.carePeriods?.length
    ? data?.organization.carePeriods?.find(c => moment(c.startDate).isAfter(today, 'days'))
    : null;
  const nextCarePeriodIsSponsored =
    nextCarePeriod?.periodType === PeriodType.SponsoredBreak ||
    nextCarePeriod?.periodType === PeriodType.SponsoredTerm;

  return (
    <>
      <Text.h2 className="mb4">Choose next steps for patient&apos;s care</Text.h2>
      <Option
        title="Self-Pay"
        description="Patient would like to continue care by paying for services through insurance/out of pocket."
        onClick={() => onSetPath('self-pay')}
      />
      {nextCarePeriodIsSponsored && (
        <Option
          title="Continue Sponsored Care"
          description="Allow patient to use available sponsored hours."
          onClick={() => onSetPath('sponsored')}
        />
      )}
      <Option
        title="Cancel"
        description="Patient will not continue Mantra care after completing sponsored visits and is not expected to return this academic year."
        onClick={() => onSetPath('cancel')}
      />
      <div className="tc">
        <FinalButton kind="minimal_gray" onClick={onClose} className="mt1 mb3">
          Close
        </FinalButton>
      </div>
    </>
  );
}

interface OptionProps {
  title: string;
  description: string;
  onClick: () => void;
}

function Option({ title, description, onClick }: OptionProps) {
  return (
    <OptionContainer onClick={onClick}>
      <div className="flex flex-row items-start">
        <div className="flex-1">
          <Text.label kind="black" className="mb2">
            {title}
          </Text.label>
          <Text.bodyCaption>{description}</Text.bodyCaption>
        </div>
      </div>
    </OptionContainer>
  );
}

const OptionContainer = styled.div`
  padding: 20px 40px 20px 20px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
`;
