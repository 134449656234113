import { flatten } from 'lodash';
import { Moment } from 'moment';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../../../globalStyles';
import { FriendlyAllocation, getAllocationLookupForBlocks } from '../../util';
import { AllocationType } from './types';

const hourTypes = ['bookable', 'admin'] as const;
type HourType = typeof hourTypes[number];

type HoursCountersProps = {
  date: Moment;
  allAllocations: FriendlyAllocation[];
  blockAllocations: FriendlyAllocation[];
};

export const HoursCounters = ({ date, allAllocations, blockAllocations }: HoursCountersProps) => {
  const { week, day } = useMemo(
    () => getTotalBreakdown(date, allAllocations, blockAllocations),
    [allAllocations, blockAllocations, date]
  );
  return (
    <CountersContainer className="flex-shrink-0">
      <HoursCounter {...week} title="This Week" />
      <HoursCounter {...day} title="Today" />
    </CountersContainer>
  );
};

type HoursCounterProps = {
  total: number;
  byTypeTotal: Record<HourType, number>;
  title: string;
};

const HoursCounter = ({ total, byTypeTotal, title }: HoursCounterProps) => (
  <CounterContainer>
    <Text.bodyBold className="m0">{title}</Text.bodyBold>
    <TotalHoursContainer className="mb2">
      <Text.h2>{total.toFixed(2)}</Text.h2>
      <Text.label className="b">Hours Total</Text.label>
    </TotalHoursContainer>
    {Object.entries(byTypeTotal).map(([type, count]) => (
      <div key={`${type}-${title}`} className="flex flex-row gap-3 mb1">
        <Text.caption>{count.toFixed(2)}</Text.caption>
        <Text.caption>{type}</Text.caption>
        <Text.caption>{total > 0 ? ((count / total) * 100).toFixed(2) : 0}%</Text.caption>
      </div>
    ))}
  </CounterContainer>
);

const TotalHoursContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  // we want to better align w the content below
  margin-left: -0.5px;
  border-bottom: 1px solid ${colors.grey.lightBorder};
`;

const CountersContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CounterContainer = styled.div`
  flex: 1;
  padding: 0 3rem;
  &:not(:last-child) {
    border-right: 1px solid ${colors.grey.lightBorder};
  }
`;

const hourTypeToAllocationTypes: Record<HourType, AllocationType[]> = {
  bookable: ['intake', 'checkin', 'default'],
  admin: ['admin'],
};

const getTotalBreakdown = (
  date: Moment,
  allAllocations: FriendlyAllocation[],
  blockAllocations: FriendlyAllocation[]
) => {
  const startOfDay = date.clone().startOf('day');
  const startOfWeek = startOfDay.clone().startOf('week');
  const endOfWeek = startOfDay.clone().endOf('week');

  const blockDates: Moment[] = [];
  const runnerDate = startOfWeek.clone();

  while (runnerDate.isSameOrBefore(endOfWeek)) {
    blockDates.push(runnerDate.clone());
    runnerDate.add(1, 'day');
  }

  const allocationsLookup = getAllocationLookupForBlocks(blockDates, allAllocations, d => d.day());
  // overwrite w the local completed allocations
  allocationsLookup.set(date.day(), blockAllocations);

  return {
    week: getBreakdown(flatten([...allocationsLookup.values()])),
    day: getBreakdown(blockAllocations),
  };
};

const getBreakdown = (blockAllocations: FriendlyAllocation[]) => {
  const byTypeTotal = blockAllocations.reduce((acc, alloc) => {
    // find first matching hour type
    const [hourType] = Object.entries(hourTypeToAllocationTypes).find(([, v]) =>
      v.includes(alloc.type)
    ) ?? [null];

    if (!hourType) {
      return acc;
    }

    const existingCount = acc[hourType as HourType] ?? 0;
    const newCount = existingCount + alloc.endTime.diff(alloc.startTime, 'minutes') / 60;
    return { ...acc, [hourType]: newCount };
  }, {} as Record<HourType, number>);

  return {
    total: Object.values(byTypeTotal).reduce((total, count) => total + count, 0),
    byTypeTotal,
  };
};
