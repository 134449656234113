import { compact, lowerCase, pick } from 'lodash';
import React, { useState } from 'react';
import { useQueryParams } from '../../Hooks';
import { Hr } from '../../Pages/Messages/Styles';
import { Text } from '../../globalStyles';
import { states } from '../../states';
import { useConfigContext } from '../ConfigContext';
import { FinalButton } from '../FinalButton';
import { InlineSVG } from '../Icons';
import { MantraModalBodyOld, MantraModalHeaderOld } from '../Modal';
import { Modal } from '../Modal/Modal';
import { useCurrentProvider } from '../Permissions';
import { ContractNoAvailability } from './ContractNoAvailability';
import { useProviderNetworkContext } from './ProviderNetworkContext';
import { ProvidersSoonerAvailability } from './ProvidersSoonerAvailability';
import { ReferralAccommodationModal } from './ReferralAccommodationModal';
import * as Styles from './Styles';
import { isSelfPayReferral } from './providerNetworkUtils';

type Props = {
  onBack?: () => void;
  clearGeoState?: boolean;
  nextAvailableProviderDate?: Date;
  providerAvailabilityHelp: 'email' | 'modal';
};

export const ProvidersNotFound = ({
  onBack,
  clearGeoState,
  nextAvailableProviderDate,
  providerAvailabilityHelp,
}: Props) => {
  const {
    outOfNetworkCount,
    hasActiveTraits,
    organizationId,
    hasRemainingHours,
    dedicatedGroupModelActive,
    stateNotAllowed,
    sessionModelActive,
    searchVariables: { state, careType },
    jumpTo,
  } = useProviderNetworkContext();

  const { currentProvider } = useCurrentProvider();
  const [parsedSearch, updateQueryParams] = useQueryParams();
  const { stateHasCare } = useConfigContext();
  const [soonerAvailabilityModal, setSoonerAvailabilityModal] = useState(false);
  const [outOfNetworkConfirm, setOutOfNetworkConfirm] = useState(false);

  const [showAccommodationModal, setShowAccommodationModal] = useState(false);

  if (stateNotAllowed) {
    const stateName = states[stateNotAllowed];

    return (
      <Styles.CenteredMaxWidth>
        <InlineSVG kind="greyIcon" icon="search" size={42} />
        <Text.h3 className="mt2">
          Your organization is not contracted to refer students for {lowerCase(careType)} in{' '}
          {stateName}.
        </Text.h3>
      </Styles.CenteredMaxWidth>
    );
  }

  if (sessionModelActive) {
    return <ContractNoAvailability />;
  }

  if (careType && state) {
    const isSelfPayOrg = isSelfPayReferral(currentProvider, careType);
    const organization = currentProvider.organizations[0];
    if (!stateHasCare(careType, state, isSelfPayOrg ? null : organization)) {
      return (
        <Styles.CenteredMaxWidth>
          <InlineSVG kind="greyIcon" icon="alert-circle" size={42} />
          <Text.h3 className="mt2 mb4">Provider availability limited</Text.h3>
          {providerAvailabilityHelp === 'email' && (
            <>
              <Text.bodyGrey className="mt2 mb2">
                Provider availability is currently limited in the student&apos;s state.
              </Text.bodyGrey>
              <Text.bodyGrey className="mt2">
                If you&apos;d like Mantra Health to coordinate care for this student, please email
                <br />
                <Text.externalLink
                  href="mailto:partnersuccess@mantrahealth.com"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="b"
                >
                  partnersuccess@mantrahealth.com
                </Text.externalLink>
              </Text.bodyGrey>
            </>
          )}
          {providerAvailabilityHelp === 'modal' && (
            <>
              <Text.bodyGrey className="mt2 mb2">
                Provider availability is currently limited in the state selected
              </Text.bodyGrey>
              <Text.bodyGrey className="mt2">
                If you&apos;d like Mantra Health to coordinate care for a student in this state,
                then please send the patient referral details to Mantra Partner Success.
              </Text.bodyGrey>
              <FinalButton
                kind="primary"
                className="mt3"
                onClick={() => setShowAccommodationModal(true)}
              >
                Send Request to Partner Success
              </FinalButton>
            </>
          )}
          {providerAvailabilityHelp === 'modal' && showAccommodationModal && (
            <ReferralAccommodationModal
              showAccommodationModal
              setShowAccommodationModal={setShowAccommodationModal}
            />
          )}
        </Styles.CenteredMaxWidth>
      );
    }
  }

  if (outOfNetworkCount > 1) {
    return (
      <div>
        <Modal
          size="tiny"
          isOpen={outOfNetworkConfirm}
          onClose={() => setOutOfNetworkConfirm(false)}
        >
          <MantraModalHeaderOld>Explore out-of-network providers?</MantraModalHeaderOld>
          <MantraModalBodyOld>
            <Text.body className="mb4 nt2">
              The providers shown will not be in-network with the originally selected insurance
              plan, and we’ll remove the insurer from your search criteria.
            </Text.body>
            <FinalButton
              kind="outline_black"
              className="w-100 mb4"
              onClick={() => {
                setOutOfNetworkConfirm(false);
                updateQueryParams({ ...parsedSearch, payerId: null });
              }}
            >
              Continue
            </FinalButton>
            <Text.linkButton
              kind="grayText"
              className="b tc w-100"
              onClick={() => setOutOfNetworkConfirm(false)}
            >
              Cancel
            </Text.linkButton>
          </MantraModalBodyOld>
        </Modal>
        <Styles.CenteredMaxWidth>
          <InlineSVG kind="greyIcon" icon="search" size={42} />
          <Text.h3 className="mt2">
            We currently don&apos;t have any providers who are in-network with the selected
            insurance plan
          </Text.h3>
        </Styles.CenteredMaxWidth>
        <Hr />
        <Styles.CenteredMaxWidth>
          <Text.h3 className="mb3" style={{ fontWeight: 'normal' }}>
            Good news! Mantra has {outOfNetworkCount} out-of-network providers available
          </Text.h3>
          <FinalButton
            kind="outline_black"
            className="w-100"
            onClick={() => setOutOfNetworkConfirm(true)}
          >
            View out-of-network providers
          </FinalButton>
        </Styles.CenteredMaxWidth>
      </div>
    );
  }

  if (!hasActiveTraits && organizationId && hasRemainingHours && dedicatedGroupModelActive) {
    return (
      <ProvidersSoonerAvailability
        soonerAvailabilityModalState={soonerAvailabilityModal}
        setSoonerAvailabilityModalState={setSoonerAvailabilityModal}
        nextAvailabilityButtonClick={jumpTo}
        nextAvailableProviderDate={nextAvailableProviderDate}
      />
    );
  }

  const canGoBack = !hasActiveTraits && onBack;

  return (
    <Styles.CenteredMaxWidth>
      <InlineSVG kind="greyIcon" icon="search" size={42} />
      <Text.h3 className="mv2">
        We currently don&apos;t have any providers who meet your search criteria
      </Text.h3>
      <Text.body className="mb3">Adjust your search filters to see available providers</Text.body>
      <FinalButton
        kind="outline_black"
        className="w-100"
        onClick={() => {
          updateQueryParams(
            pick(
              parsedSearch,
              compact(['careType', 'payerId', 'organizationId', clearGeoState && 'state'])
            )
          );
          if (canGoBack) onBack();
        }}
      >
        {hasActiveTraits ? 'Clear Filters' : 'Go back'}
      </FinalButton>
    </Styles.CenteredMaxWidth>
  );
};
