import { PanelOverrides } from 'baseui/accordion';
import { CheckboxOverrides } from 'baseui/checkbox';
import { ChevronDown } from 'baseui/icon';
import { MenuProps } from 'baseui/menu';
import { TabOverrides, TabsProps } from 'baseui/tabs';
import { merge } from 'lodash';
import React from 'react';
import { WIDGET_PADDING } from '../../Components/Widgets/Widget';
import { colors } from '../../globalStyles';
import { borderRadius, margin, padding } from '../../utils';

const Panel = { Content: { style: { backgroundColor: 'transparent' } } };

export const GUTTER = '32px';
export const TOP_PADDING = '24px';

const DropDownMenu: MenuProps['overrides'] = {
  List: {
    style: { ...borderRadius('4px'), outlineWidth: '0px' },
  },
  Option: {
    style: { textAlign: 'left' },
  },
};

const Tabs: TabsProps['overrides'] = {
  TabBar: {
    style: {
      backgroundColor: colors.white,
      paddingLeft: GUTTER,
      borderBottomWidth: '1px',
      borderBottomColor: colors.grey.light,
      borderBottomStyle: 'solid',
      width: '100%',
    },
  },
  Tab: {
    style: {
      marginLeft: '4px',
      marginRight: '4px',
      paddingTop: '12px',
      paddingBottom: '12px',
    },
  },
  TabContent: {
    style: {
      paddingTop: TOP_PADDING,
      paddingLeft: GUTTER,
      paddingRight: GUTTER,
      backgroundColor: colors.grey.lightBackground,
    },
  },
};
const Tab: TabOverrides<any> = {
  Tab: {
    style: ({ $active }) => ({
      color: $active ? colors.black : colors.grey.dark,
      outline: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipses',
    }),
  },
};

const AccordionPanel: PanelOverrides<any> = {
  ToggleIcon: {
    style: () => ({
      width: '20px',
      height: '20px',
    }),
  },
  Header: {
    style: () => ({
      outline: 'none',
      marginLeft: `-${WIDGET_PADDING}`,
      marginRight: `-${WIDGET_PADDING}`,
      paddingLeft: WIDGET_PADDING,
      paddingRight: WIDGET_PADDING,
      paddingTop: '10px',
      paddingBottom: '10px',
    }),
  },
  Content: {
    style: () => ({
      backgroundColor: colors.white,
    }),
  },
};

type AccordionOverrideProps = {
  ToggleIcon: React.FC<{ $expanded?: boolean }>;
  isLastRow?: boolean;
};

export const simpleAccordionOverride = ({
  ToggleIcon,
  isLastRow,
}: AccordionOverrideProps): PanelOverrides<any> => ({
  Content: {
    style: {
      backgroundColor: colors.white,
      ...margin('0px'),
      paddingLeft: '0px',
      paddingRight: '0px',
      ...(isLastRow ? { borderBottomColor: 'transparent' } : {}),
    },
  },
  Header: {
    style: {
      outline: 'none',
      alignItems: 'start',
      fontWeight: 'normal',
      ...margin('0px'),
      ...padding('0px'),
      ...(isLastRow ? { borderBottomColor: 'transparent' } : {}),
    },
  },
  ToggleIcon,
});

export const fileAccordionFactory = ({
  isLastRow,
}: Pick<AccordionOverrideProps, 'isLastRow'>): PanelOverrides<any> => {
  // this is not the cleanest approach but there isn't too much to work with for baseui
  const { Content, Header, ...rest } = simpleAccordionOverride({
    isLastRow,
    ToggleIcon: () => <ChevronDown size={32} color={colors.grey.border} />,
  });

  return {
    Content: merge(Content, {
      style: {
        paddingLeft: '32px',
        paddingRight: '32px',
      },
    }),
    Header: merge(Header, {
      style: {
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingTop: '25px',
        paddingBottom: '25px',
      },
    }),
    ...rest,
  };
};

export const toggleOverrideFactory = ({ done }: { done: boolean }): CheckboxOverrides => ({
  Toggle: {
    style: () => {
      return {
        backgroundColor: 'white',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        height: '24px',
        width: '24px',
      };
    },
  },
  ToggleTrack: {
    style: () => {
      return {
        backgroundColor: done ? 'rgb(68, 208, 96)' : 'grey',
        height: '24px',
        width: '44px',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
      };
    },
  },
  ToggleInner: {
    style: () => {
      return {
        outline: 'white',
        backgroundColor: 'white',
      };
    },
  },
});

export const Overrides = {
  Tabs,
  Tab,
  Panel,
  AccordionPanel,
  DropDownMenu,
};
