import { styled } from 'baseui';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { TimeZone } from '../../utils';
import { FinalButton } from '../FinalButton';
import { TimezonePicker } from '../TimezonePicker';
import { MonthDisplay } from './MonthDisplay';

interface Props {
  startDate: Moment;
  timeZone?: TimeZone;
  onChangeStartDate: (val: Moment) => void;
  onChangeTimeZone?: (val: TimeZone) => void;
  showTimeZone?: boolean;
  monthButtonAlignment?: 'left' | 'center';
  children?: React.ReactElement;
  circledDate?: Moment;
  leftMonthOffset?: number;
}

const Grid = styled('div', {
  gridTemplateColumns: 'repeat(3, 1fr)',
  display: 'grid',
  textAlign: 'center',
  marginLeft: '2em',
  marginRight: '2em',
});
const GridCell = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export function CalendarRangePicker({
  startDate,
  timeZone,
  onChangeStartDate,
  onChangeTimeZone,
  showTimeZone,
  monthButtonAlignment = 'center',
  circledDate,
  children,
  leftMonthOffset: startingMonthOffset = 0,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const handleChangeStartDate = (value: Moment) => onChangeStartDate(value.clone().startOf('week'));

  const monthButton = (
    <FinalButton
      kind="minimal_black"
      iconRight={{ icon: expanded ? 'iconsUpChevronSvg' : 'iconsDownChevronSvg', size: 16 }}
      onClick={() => setExpanded(!expanded)}
    >
      {startDate.format('MMMM YYYY')}
    </FinalButton>
  );

  return (
    <Grid>
      <GridCell>{monthButtonAlignment === 'left' ? monthButton : <>{children}</>}</GridCell>
      <GridCell>{monthButtonAlignment === 'center' ? monthButton : <>{children}</>}</GridCell>
      <GridCell>
        {showTimeZone && timeZone && (
          <TimezonePicker
            value={timeZone}
            onChange={value => onChangeTimeZone && onChangeTimeZone(value)}
          />
        )}
      </GridCell>
      {expanded && (
        <>
          <MonthDisplay
            month={startDate.clone().add(startingMonthOffset, 'month')}
            startDate={startDate}
            onChangeStartDate={handleChangeStartDate}
            circledDate={circledDate}
          />
          <MonthDisplay
            month={startDate.clone().add(startingMonthOffset + 1, 'month')}
            startDate={startDate}
            onChangeStartDate={handleChangeStartDate}
            circledDate={circledDate}
          />
          <MonthDisplay
            month={startDate.clone().add(startingMonthOffset + 2, 'months')}
            startDate={startDate}
            onChangeStartDate={handleChangeStartDate}
            circledDate={circledDate}
          />
        </>
      )}
    </Grid>
  );
}
