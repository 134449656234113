import React from 'react';
import { Login } from '../../../../Pages/Session';
import { PageContent } from '../pageCore';

export const LegacyLoginPage = (): JSX.Element => {
  return (
    <PageContent testID="legacy-login-page">
      <Login />
    </PageContent>
  );
};
