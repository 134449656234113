import React, { ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PatientProvider } from './components/contexts/patientContext';
import { EmbedLayout, LegacyLayout, LegacyLayoutPublic } from './components/layouts';
import { HubReferralPage } from './components/pages/hubReferral/HubReferralPage';
import { LegacyHomePage } from './components/pages/legacyHome/LegacyHomePage';
import { LegacyLoginPage } from './components/pages/legacyLogin/LegacyLoginPage';
import { NotFoundPage } from './components/pages/notFound/NotFoundPage';
import { PatientBookingPage } from './components/pages/patientBooking/PatientBookingPage';
import { PatientEntryPage } from './components/pages/patientEntry/PatientEntryPage';
import { PatientSkillsManagePage } from './components/pages/patientSkillsManage/PatientSkillsManagePage';
import { publicRoutes, routes } from './routes';

export const AppRoutes = (): JSX.Element => (
  <Switch>
    {/* Auth Pages */}
    {addRoute(routes.legacyHome, LegacyHomePage, EmbedLayout, { exact: true })}
    {addRoute(routes.legacyLogin, LegacyLoginPage, LegacyLayoutPublic, { exact: true })}

    {/* Patient Pages */}
    {addRoute(routes.patientEntry, PatientEntryPage, EmbedLayout)}
    {addPatientRoute(routes.patientBooking, PatientBookingPage, LegacyLayout)}
    {addPatientRoute(routes.patientSkillsManage, PatientSkillsManagePage, EmbedLayout)}

    {/* Not Found */}
    {addRoute('*', NotFoundPage, EmbedLayout)}
  </Switch>
);

export const AppPublicRoutes = (): JSX.Element => (
  <Switch>
    {/* FacultyStaff Widget */}
    {addRoute(publicRoutes.hubReferral, HubReferralPage, EmbedLayout, {
      exact: true,
    })}

    {/* Not Found */}
    {addRoute('*', NotFoundPage, EmbedLayout)}
  </Switch>
);

type RouteOptions = {
  exact?: boolean;
};

const addPatientRoute = (
  path: string,
  Element: () => JSX.Element,
  Layout: ({ children }: { children: ReactNode }) => JSX.Element,
  routeOptions: RouteOptions = {}
): JSX.Element => {
  return addRoute(
    path,
    () => (
      <PatientProvider>
        <Element />
      </PatientProvider>
    ),
    Layout,
    routeOptions
  );
};

const addRoute = (
  path: string,
  Element: () => JSX.Element,
  Layout: ({ children }: { children: ReactNode }) => JSX.Element,
  { exact = false }: RouteOptions = {}
): JSX.Element => {
  return (
    <Route exact={exact} path={path}>
      <Layout>
        <Element />
      </Layout>
    </Route>
  );
};
