import React from 'react';
import { Notification as BaseNotification } from 'baseui/notification';
import { ToastProps as NotificationProps } from 'baseui/toast';

export const Notification = ({
  children,
  style,
  ...props
}: NotificationProps & { style?: any }) => {
  const overrides = {
    Body: {
      style: {
        boxSizing: 'border-box' as const,
        width: '100%',
        ...(style || {}),
      },
    },
  };
  return (
    <BaseNotification {...props} overrides={overrides}>
      {children}
    </BaseNotification>
  );
};

export const CenteredNotification = ({ children, ...props }: NotificationProps) => {
  return (
    <Notification style={{ display: 'flex', justifyContent: 'center' }} {...props}>
      {children}
    </Notification>
  );
};
