import { states } from './states';

export const locations: Record<string, string> = {
  ...states,
  DC: 'District of Columbia',
  OOS: 'Other/Outside of U.S.',
};

export const locationOptions = Object.entries(locations).map(([abbr, name]) => ({
  id: abbr,
  label: name,
}));
