import styled from 'styled-components';
import { Text, colors } from '../../globalStyles';
import { UnstyledLink } from '../Shared';

export const NotesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
`;
export const SectionContainer = styled.div`
  margin-bottom: 2em;
  background: white;
  padding-top: 1.5em;
  padding-bottom: 2em;
  padding-right: 2em;
  padding-left: 2em;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;
export const Divider = styled.hr`
  border-top: 0;
  border-bottom: 1px solid #ccc;
  margin: 1.25em 0;
`;
export const OneColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5em;
`;
export const EmptyStateText = styled(Text.body)`
  font-style: italic;
`;
export const LinkedNoShowRow = styled(UnstyledLink)`
  display: flex;
  &:not(:last-of-type) {
    margin-bottom: 1em;
  }
`;

export const WidgetContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey.hr}};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 32px;
  margin: 2em 2em 0 0;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;
