import { Organization, Provider, Role, User } from '../../graphQL';
import { Nullable } from '../../types';

type WithIdAndSimpleOrgs<P extends Provider> = Pick<P, 'id'> & {
  organizations: Pick<Organization, 'id'>[];
};

export const MCP_ROLES: Role[] = [
  Role.UniversityAdmin,
  Role.UniversityStaff,
  Role.UniversitySupportingClinician,
];

export function isMcpRole(role: string | null | undefined) {
  return Boolean(role && MCP_ROLES.includes(role as Role));
}

export function isMcpUser(provider: Pick<Provider, 'role'>) {
  return isMcpRole(provider.role);
}

export function isMcpOrReferralUser(provider: Pick<Provider, 'role'>) {
  return isMcpUser(provider) || provider.role === Role.UniversityReferrer;
}

export function isMantraProvider(provider: Pick<Provider, 'role'>) {
  return provider.role === Role.Provider;
}

export function isUserTherapist(
  user: { therapist?: Nullable<WithIdAndSimpleOrgs<NonNullable<User['therapist']>>> },
  provider: Pick<Provider, 'role' | 'id'>
) {
  return user.therapist?.id === provider.id;
}

export function isUserPsychiatrist(
  user: { provider?: Nullable<WithIdAndSimpleOrgs<NonNullable<User['provider']>>> },
  provider: Pick<Provider, 'role' | 'id'>
) {
  return user.provider?.id === provider.id;
}

export function isMantraEmployee(provider: Pick<Provider, 'role'>) {
  return isMantraAdmin(provider) || isMantraProvider(provider);
}

export function isMantraAdmin(provider: Pick<Provider, 'role'>) {
  return provider.role === Role.Admin || provider.role === Role.ProductAdmin;
}

export function isMcpAdmin(provider: Pick<Provider, 'role'>) {
  return provider.role === Role.UniversityAdmin;
}

export function isMantraOrMcpAdmin(provider: Pick<Provider, 'role'>) {
  return isMantraAdmin(provider) || isMcpAdmin(provider);
}

export function isSupportingClinician(provider: Pick<Provider, 'role'>) {
  return provider.role === Role.UniversitySupportingClinician;
}

export function isUniversityReferrer(provider: Pick<Provider, 'role'>) {
  return provider.role === Role.UniversityReferrer;
}

export function isUniversityStaff(provider: Pick<Provider, 'role'>) {
  return provider.role === Role.UniversityStaff;
}

export function hasOrganizations(provider: WithIdAndSimpleOrgs<Provider>) {
  return Boolean(provider.organizations.length);
}

export function belongsToOrganization(
  provider: WithIdAndSimpleOrgs<Provider>,
  organizationId: number
) {
  return provider.organizations.some(o => o.id === organizationId);
}
