import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { parseCampusTeamToMemberInput } from '../../../Components/CampusTeam/campusTeamUtils';
import { EditCampusTeamMember } from '../../../Components/CampusTeam/types';
import { ModalLink } from '../../../Components/DedicatedGroupModel/BookableHoursModal';
import { useEvents } from '../../../Components/Events/EventsProvider';
import { FinalButton } from '../../../Components/FinalButton';
import { Modal } from '../../../Components/Modal/Modal';
import { Notification } from '../../../Components/Notification';
import { useCurrentProvider } from '../../../Components/Permissions';
import { RemoveCampusTeamMemberModal } from '../../../Components/SearchAndAdd/RemoveCampusTeamMemberModal';
import { SearchAndAdd } from '../../../Components/SearchAndAdd/SearchAndAdd';
import { UnsavedCampusTeamMemberModal } from '../../../Components/SearchAndAdd/UnsavedCampusTeamMemberModal';
import { Text } from '../../../globalStyles';
import { useAdminEditUserMutation, useCampusTeamOptionsForUserQuery } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { CampusTeamMember } from './CampusTeamMember';

interface EditPatientInfoProps {
  closeModal: () => void;
}

export const EditCareTeamInfo = ({ closeModal }: EditPatientInfoProps) => {
  const { track } = useEvents();
  const { user, refetch } = useDrilldownContext();
  const { currentProvider } = useCurrentProvider();
  const userOrgId = user.organization?.id;
  const providerOrgId = currentProvider.organizations[0]?.id;
  const organizationId = userOrgId ?? providerOrgId;
  const { data } = useCampusTeamOptionsForUserQuery({
    variables: { organizationId, userId: user.id },
  });

  const currentProviderName = currentProvider.name;

  const existingCampusTeam: EditCampusTeamMember[] = useMemo(
    () =>
      (data?.adminUser.campusTeam ?? []).map(c => ({
        ...c.provider,
        relationshipType: c.relationshipType,
      })),
    [data]
  );

  const searchOptions: EditCampusTeamMember[] = useMemo(
    () =>
      (data?.organization.campusTeamOptions ?? []).filter(
        member => !existingCampusTeam.some(existingMember => existingMember.id === member.id)
      ),
    [data, existingCampusTeam]
  );

  const [campusTeam, setCampusTeam] = useState<EditCampusTeamMember[]>([]);

  useEffect(() => {
    setCampusTeam(existingCampusTeam);
  }, [existingCampusTeam, setCampusTeam]);

  const [error, setError] = useState<string>();
  const [modalOpenIdx, setModalOpenIdx] = useState<number | null>(null);
  const [unsavedModalOpen, setUnsavedModalOpen] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const undefinedRolesExist = campusTeam.some(teamMember => !teamMember.relationshipType);
  const campusTeamDifference = campusTeam !== existingCampusTeam;

  useEffect(() => {
    if (!campusTeamDifference || undefinedRolesExist) {
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }
  }, [campusTeamDifference, undefinedRolesExist, setDisabledSave]);

  const [editUser] = useAdminEditUserMutation({
    onCompleted: editUserData =>
      track('user.info.edited', { userId: editUserData.adminEditUser.id, careTeamInfo: true }),
  });

  const submit = async () => {
    const campusTeamWithRelations = parseCampusTeamToMemberInput(campusTeam, setError);

    if (!campusTeamWithRelations) {
      return;
    }

    setError('');
    try {
      await editUser({
        variables: {
          editUser: {
            id: user.id,
            campusTeam: campusTeamWithRelations,
          },
        },
      });
      closeModal();
      refetch();
    } catch (e) {
      setError('Internal error');
    }
    closeModal();
  };

  const closeModalButton = () => {
    // check differece between existingCareTeam and people in campusTeamState
    // check if any person doesn't have a relationshipType
    if (campusTeamDifference || undefinedRolesExist) {
      setUnsavedModalOpen(true);
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      isOpen
      onClose={closeModalButton}
      size="campusTeam"
      // overflow:'visible' allows dropdown content to appear outside the modal
      style={{ content: { overflow: 'visible' } }}
    >
      {error && <Notification kind="negative">{error}</Notification>}
      <ModalContainer>
        <Text.h2 className="mb4">
          Edit Campus Team for {user.firstName} {user.lastName}
        </Text.h2>
        <SearchAndAdd
          selectedValues={campusTeam}
          searchOptions={searchOptions.map(option => ({
            data: { ...option },
            label: option.name,
          }))}
          onAddValue={newValue => setCampusTeam(existing => [...existing, newValue])}
          onEditValue={editedProvider =>
            setCampusTeam(existing =>
              existing.map(existingProvider =>
                existingProvider.id === editedProvider.id ? editedProvider : existingProvider
              )
            )
          }
          onRemoveValue={removeProvider =>
            setCampusTeam(prevTeam =>
              prevTeam.filter(existingProvider => existingProvider.id !== removeProvider.id)
            )
          }
          component={CampusTeamMember}
        />
        <RemoveCampusTeamMemberModal
          campusTeam={campusTeam}
          modalOpenIdx={modalOpenIdx}
          isOpen={typeof modalOpenIdx === 'number'}
          onClose={() => setModalOpenIdx(null)}
          removeCampusTeamMember={(_, idx) =>
            setCampusTeam(existing => existing.filter((__, i) => i !== idx))
          }
          currentProviderName={currentProviderName}
        />
        {unsavedModalOpen && (
          <UnsavedCampusTeamMemberModal
            onClose={() => setUnsavedModalOpen(false)}
            isOpen={unsavedModalOpen}
            removeUnsavedMembers={() => {
              setCampusTeam(existingCampusTeam);
              setUnsavedModalOpen(false);
            }}
          />
        )}
        <FinalButton
          type="submit"
          className="w-100 mt3"
          kind="primary"
          onClick={submit}
          disabled={disabledSave}
        >
          Save Changes
        </FinalButton>
        <ModalClose className="b" onClick={closeModalButton}>
          Close
        </ModalClose>
      </ModalContainer>
    </Modal>
  );
};

const ModalClose = styled(ModalLink)`
  padding-top: 20px;
`;

const ModalContainer = styled.div`
  width: 100%;
  position: relative;
`;
