import React from 'react';
import styled from 'styled-components';
import { colors } from '../../globalStyles';
import { useLogout } from '../../Hooks/useLogout';
import { UnderlineButton } from '../Buttons';

interface ImpersonationBannerProps {
  providerName: string;
}

// use this context to scroll to the top of the page
export const ImpersonationBanner = ({ providerName }: ImpersonationBannerProps) => {
  const { logout } = useLogout();
  const stopImpersonating = () => {
    logout();
  };

  return (
    <>
      <ImpersonationBannerWrapper>
        You are now impersonating {providerName}.
        <span className="ml2">
          <UnderlineButton color={colors.white} onClick={stopImpersonating}>
            Stop impersonating
          </UnderlineButton>
        </span>
      </ImpersonationBannerWrapper>
    </>
  );
};

const ImpersonationBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: ${colors.dangerBgInternal};
  position: sticky;
  right: 0;
  top: 0;
  z-index: 2;
  color: ${colors.white};
`;
