import React from 'react';
import styled from 'styled-components';
import { FinalButton } from '../../Components/FinalButton';
import { MantraModalBodyOld, MantraModalHeaderOld } from '../../Components/Modal';
import { Modal, ModalProps } from '../../Components/Modal/Modal';
import { Notification } from '../../Components/Notification';
import { colors, Text } from '../../globalStyles';
import { useActivateCrisisModeMutation, VideoCallDataFragment } from '../../graphQL';
import { Nullable } from '../../types';
import { areCrisisHoursActive } from './videoCallUtils';

type UserOrganization = NonNullable<VideoCallDataFragment['user']['organization']>;
type OrganizationCrisisProviders = UserOrganization['crisisTeamProviders'][number];
type OrganizationCrisisHours = UserOrganization['currentCrisisHours'];

type CrisisProtocolModalProps = Pick<ModalProps, 'onClose' | 'isOpen'> & {
  onSuccess: (crisisId: string) => void;
  appointmentId: number;
  organizationCrisisProviders: OrganizationCrisisProviders[];
  crisisHours?: OrganizationCrisisHours;
};

export const CrisisProtcolModal = ({
  isOpen,
  onClose,
  onSuccess,
  appointmentId,
  organizationCrisisProviders,
  crisisHours,
}: CrisisProtocolModalProps) => {
  const [activateCrisisMode, { error, loading }] = useActivateCrisisModeMutation({
    variables: { appointmentId },
    onCompleted: v => onSuccess(v.activateCrisisMode),
  });

  let noCampusTeamWarning: Nullable<string> = null;

  if (!organizationCrisisProviders.length) {
    noCampusTeamWarning = 'There is no on-campus crisis team set up to join this call.';
  } else if (!crisisHours || !areCrisisHoursActive(crisisHours)) {
    noCampusTeamWarning = "It is outside operating hours for this school's on-campus crisis team.";
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="tiny">
      <MantraModalHeaderOld className="tc">
        You are about to engage Crisis Protocol
      </MantraModalHeaderOld>
      {error && <Notification kind="negative">{error}</Notification>}
      <MantraModalBodyOld>
        <Text.body className="mb3">
          Doing so will alert Mantra Health to stand by to a crisis situation. They will be able to
          join this call.{' '}
          {!noCampusTeamWarning && (
            <strong>You will also be able to alert the campus crisis team.</strong>
          )}
        </Text.body>
        {noCampusTeamWarning && (
          <WarningContainer className="mb3">
            <Text.bodyBold className="mb2">{noCampusTeamWarning}</Text.bodyBold>
            <Text.body>
              You will be able to refer to the safe operating handbook or coordinate with Mantra if
              you need additional support.
            </Text.body>
          </WarningContainer>
        )}
        <Text.body className="mb3">Are you sure you would like to continue?</Text.body>
        <FinalButton className="w-100 mb4" onClick={() => activateCrisisMode()} loading={loading}>
          Yes, continue
        </FinalButton>
        <Text.linkButton className="b db center" kind="grayText" onClick={onClose}>
          Cancel
        </Text.linkButton>
      </MantraModalBodyOld>
    </Modal>
  );
};

const WarningContainer = styled.div`
  padding: 1.25rem;
  border-radius: 5px;
  background-color: ${colors.dangerBg};
`;
