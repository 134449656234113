import { Chart, ChartConfiguration, PieController } from 'chart.js';

import React, { useEffect, useRef } from 'react';
import { Text, colors } from '../../../globalStyles';
import { CareType } from '../../../graphQL';
import { Dot } from '../../../Components/Flair';
import { lowerCase } from 'lodash';

Chart.register(PieController);

type StudentCapacityParams = {
  max?: number;
  remaining: number;
  utilized: number;
  careType?: CareType;
};

const colorMap: Record<string, string> = {
  utilizedStudents: colors.primary,
  remainingCapacity: colors.background,
};

export const StudentCapacityWidget = ({
  max,
  remaining,
  utilized,
  careType,
}: StudentCapacityParams): JSX.Element | null => {
  const CapacityChart = () => {
    const ref = useRef<HTMLCanvasElement>(null);
    const data = {
      utilized,
      remaining,
      max,
    };

    useEffect(() => {
      if (!ref.current) {
        return;
      }
      const chart = new Chart(ref.current, getConfig({ data }));
      return () => {
        chart.destroy();
      };
    }, []);
    return (
      <div className="flex justify-center items-center pt4">
        <div className="w-50 flex flex-column items-center justify-center mr4">
          <div className="relative flex-auto mb4" style={{ maxWidth: 200 }}>
            <canvas ref={ref} />
          </div>
          <Text.bodySmallGrey>Max. Capacity: {max} students</Text.bodySmallGrey>
        </div>
        <div className="mv4">
          <div className="flex items-center">
            <Dot color={colors.grayTextBg} size="1rem">
              <Text.h4>Remaining Capacity</Text.h4>
            </Dot>
          </div>
          <div className="flex items-baseline">
            <div className="br-100 w1 h1 mr2" />
            <Text.h3 className="dib v-btm mb0">{remaining}</Text.h3>
            <Text.bodyCaption className="dib v-btm mb0 ml2" style={{ marginBottom: 1 }}>
              students
            </Text.bodyCaption>
          </div>
          <hr className="mv4" />
          <div className="flex items-center">
            <Dot color={colors.primary} size="1rem">
              <Text.h4>Utilized Capacity</Text.h4>
            </Dot>
          </div>
          <div className="flex items-baseline">
            <div className="br-100 w1 h1 mr2" />
            <Text.h3 className="dib v-btm mb0">{utilized}</Text.h3>
            <Text.bodyCaption className="dib v-btm mb0 ml2" style={{ marginBottom: 1 }}>
              students
            </Text.bodyCaption>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-50 pr5">
      <Text.h3>Student Capacity</Text.h3>
      <Text.bodySmallGrey>
        New and returning students that have completed at least one {lowerCase(careType)} visit
        during this academic year.
      </Text.bodySmallGrey>

      <CapacityChart />
    </div>
  );
};

const getConfig = ({ data }: { data: StudentCapacityParams }): ChartConfiguration<'pie'> => {
  const { utilized, remaining } = data;

  return {
    type: 'pie',
    data: {
      labels: ['Remaining Capacity', 'Utilized Students'],
      datasets: [
        {
          data: [remaining, utilized],
          borderWidth: 0,
          backgroundColor: [colorMap.remainingCapacity, colorMap.utilizedStudents],
        },
      ],
    },
  };
};
