import React from 'react';
import { AssessmentKey, Permission, usePushAssessmentMutation } from '../../graphQL';
import { formatResult } from '../../modelUtils/assessment';
import { useDrilldownContext } from '../../Pages/Users/helpers';
import { FinalButton } from '../FinalButton';
import { PermsOnly, useCurrentProvider } from '../Permissions';
import { usePopoverTabContext } from '../PopoverTabs/PopoverTabContainer';
import { AssessmentPopover } from './PopoverAssessment';

type ScoreDisplayProps = {
  result?: Parameters<typeof formatResult>[0];
};

export const MdqScoreDisplay = ({ result }: ScoreDisplayProps) => {
  const { user, refetch } = useDrilldownContext();
  const [mutate, { data }] = usePushAssessmentMutation({
    variables: { assessment: AssessmentKey.Bipolar1Screener, userId: user.id },
    onCompleted: () => refetch?.(),
  });

  /* this will cause issues if we decide to make a standalone assessments page  */
  const { pushTab } = usePopoverTabContext();

  if (result) return <p>{formatResult(result)}</p>;
  return (
    <PermsOnly allowed={Permission.AssessmentPush}>
      <div className="flex gap-3 items-center">
        {data ? (
          <p className="ph3 normal green">Sent to Patient</p>
        ) : (
          <FinalButton
            size="tiny"
            kind="outline_black"
            pill
            disabled={!!data}
            onClick={() => mutate()}
          >
            Send to Patient
          </FinalButton>
        )}
        <FinalButton
          size="tiny"
          pill
          onClick={() =>
            pushTab({
              title: 'MDQ',
              tabKey: 'assessment-mdq',
              renderBody: ({ tab }) => (
                <AssessmentPopover assessmentKey={AssessmentKey.Bipolar1Screener} tab={tab} />
              ),
            })
          }
          kind="primary"
        >
          Start
        </FinalButton>
      </div>
    </PermsOnly>
  );
};

export const YmrsScoreDisplay = ({ result }: ScoreDisplayProps) => {
  const { hasPermission } = useCurrentProvider();
  const { pushTab } = usePopoverTabContext();

  const btn = (
    <FinalButton
      size="tiny"
      pill
      kind="primary"
      disabled={hasPermission(Permission.MantraAdmin)}
      onClick={() =>
        pushTab({
          title: 'YMRS',
          tabKey: 'assessment-ymrs',
          renderBody: ({ tab }) => (
            <AssessmentPopover assessmentKey={AssessmentKey.Bipolar1Assessment} tab={tab} />
          ),
        })
      }
    >
      Start
    </FinalButton>
  );

  if (result)
    return (
      <div className="flex gap-3">
        {btn}
        <PermsOnly allowed={Permission.AssessmentPush}>
          <p>{formatResult(result)}</p>
        </PermsOnly>
      </div>
    );
  return (
    <PermsOnly allowed={Permission.AssessmentPush}>
      <div className="flex">{btn}</div>
    </PermsOnly>
  );
};

// display scores even without results in Assessments tab and Medical Notes
export const scoreDisplayMap = new Map<string, (props: ScoreDisplayProps) => JSX.Element | null>([
  ['mdq', MdqScoreDisplay],
  ['ymrs', YmrsScoreDisplay],
]);
