import { ApolloQueryResult } from '@apollo/client';
import { capitalize, lowerCase } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FinalButton } from '../../../Components/FinalButton';
import { Icon } from '../../../Components/Icons';
import { modalFactory } from '../../../Components/Modal';
import { isMantraAdmin, isMcpUser, useCurrentProvider } from '../../../Components/Permissions';
import { colors, Modal as MantraModal, Text } from '../../../globalStyles';
import {
  AdminAlertsQuery,
  AdminAlertType,
  CareType,
  useAdminAlertsQuery,
  useReactivateUserForSponsoredCareMutation,
} from '../../../graphQL';
import { isPsychiatrist } from '../../Provider/util';
import { UnexpectedError } from '../../Shared';
import { useDrilldownContext } from '../helpers';
import { Styles } from '../styles';

type AlertProps = Pick<
  AdminAlertsQuery['adminUser']['adminAlerts'][number],
  'message' | 'careType'
> & { refetchAlerts: () => Promise<ApolloQueryResult<AdminAlertsQuery>> };

const alertMap: Record<AdminAlertType, ((message: AlertProps) => JSX.Element) | null> = {
  [AdminAlertType.MissingPharmacy]: p => <PharmacySelectionMissing {...p} />,
  [AdminAlertType.SponsoredCareExpiring]: null, // Displayed elsewhere
  [AdminAlertType.ReactivatePatient]: p => <ReactivatePatientAlert {...p} />,
  [AdminAlertType.ConfirmPatientReactivation]: p => <ConfirmPatientReactivationAlert {...p} />,
  [AdminAlertType.UnfinishedNote]: p => <MissingNotes {...p} />,
};

const PharmacySelectionMissing = ({ message }: AlertProps) => {
  const { currentProvider } = useCurrentProvider();
  if (!isPsychiatrist(currentProvider) && !isMantraAdmin(currentProvider)) {
    return null;
  }
  return (
    <Styles.widget color={colors.danger}>
      <div className="nv1 flex items-center" style={{ marginLeft: -11 }}>
        <Icon icon="iconsAlertSvg" alt="Alert" className="mr3" />
        <Text.body>{message}</Text.body>
      </div>
    </Styles.widget>
  );
};

const MissingNotes = ({ message }: AlertProps) => {
  const { setActiveTab } = useDrilldownContext();
  return (
    <Styles.widget color={colors.danger}>
      <div className="flex items-center">
        <Icon icon="iconsAlertSvg" className="mr3" />
        <p className="mr3">{message}</p>
        <Text.linkButton onClick={() => setActiveTab('notes')}>View Notes</Text.linkButton>
      </div>
    </Styles.widget>
  );
};

const ReactivatePatientAlert = ({ message, careType, refetchAlerts }: AlertProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { currentProvider } = useCurrentProvider();

  if (!isMcpUser(currentProvider) && !isMantraAdmin(currentProvider)) {
    return null;
  }
  return (
    <Styles.widget color={colors.danger}>
      {modalOpen && (
        <ReactivateUserModal
          // Note: careType should never actually be undefined for this alert
          careType={careType!}
          onClose={() => setModalOpen(false)}
          refetch={refetchAlerts}
        />
      )}
      <div className="nv1 flex items-center" style={{ marginLeft: -11 }}>
        <Icon icon="iconsAlertSvg" className="mr3" />
        <Text.body>
          {message}{' '}
          <Text.linkButton onClick={() => setModalOpen(true)}>
            Reactivate Sponsored {careType}
          </Text.linkButton>
        </Text.body>
      </div>
    </Styles.widget>
  );
};

interface ReactivateModalProps {
  careType: CareType;
  onClose: () => void;
  refetch: () => Promise<any>;
}

const Modal = modalFactory({ style: { maxWidth: '400px' } });

const ReactivateUserModal = ({ onClose, refetch, careType }: ReactivateModalProps) => {
  const [success, setSuccess] = useState(false);
  const { user, refetch: refetchUser } = useDrilldownContext();
  const history = useHistory();

  const [reactivateUser, { loading, error, data }] = useReactivateUserForSponsoredCareMutation({
    variables: {
      userId: user.id,
      careType,
    },
  });

  const closeAndRefetch = async () => {
    try {
      await refetchUser();
      await refetch();
    } finally {
      onClose();
    }
  };

  if (error || data?.reactivateUserForSponsoredCare === false) {
    return <ErrorModal closeAndRefetch={closeAndRefetch} message={error?.message} />;
  }

  return (
    <>
      <Modal isOpen={!success} onClose={onClose}>
        <MantraModal.body>
          <MantraModal.header>
            {`Reactivate this patient's ${lowerCase(careType)}?`}
          </MantraModal.header>
          <Text.body className="mt3 mb4">
            {`Reactivation allows a patient to continue their university-sponsored ${lowerCase(
              careType
            )}. Once the
            patient is active, they’ll regain access to their Mantra Patient Portal.`}
          </Text.body>
          <div className="flex flex-column gap-2">
            <FinalButton
              loading={loading}
              onClick={() => reactivateUser().then(() => setSuccess(true))}
            >
              Reactivate
            </FinalButton>
            <FinalButton kind="minimal_gray" onClick={onClose}>
              Close
            </FinalButton>
          </div>
        </MantraModal.body>
      </Modal>
      <Modal isOpen={success} onClose={closeAndRefetch}>
        <MantraModal.body>
          <Icon
            icon="iconsCircleCheckSvg"
            alt="Success"
            className="center db mb2"
            width={26}
            height={26}
          />
          <MantraModal.header className="tc">
            {`${capitalize(careType)} reactivated`}
          </MantraModal.header>
          <Text.body className="mt3 mb4">
            {`You may now book a ${lowerCase(careType)} visit for this patient, if desired.`}
            <p className="mt3">
              <ProviderChangeInfo>
                To initiate a change in provider for this student, please contact{' '}
                <Text.externalLink
                  href="mailto:hi@mantrahealth.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  hi@mantrahealth.com
                </Text.externalLink>{' '}
                with the request.{' '}
              </ProviderChangeInfo>
              Please include the student’s Mantra ID number, which is listed below their name in
              their patient profile.
            </p>
          </Text.body>
          <div className="flex flex-column gap-2">
            <FinalButton
              kind="outline_black"
              iconLeft="iconsBlackApptSvg"
              onClick={() => history.push(`/book?userId=${user.id}`)}
            >
              Schedule an appointment
            </FinalButton>
            <FinalButton kind="minimal_gray" onClick={closeAndRefetch}>
              Close
            </FinalButton>
          </div>
        </MantraModal.body>
      </Modal>
    </>
  );
};

const ConfirmPatientReactivationAlert = ({ message, careType, refetchAlerts }: AlertProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { currentProvider } = useCurrentProvider();

  if (!isMcpUser(currentProvider) && !isMantraAdmin(currentProvider)) {
    return null;
  }
  return (
    <Styles.widget color={colors.danger}>
      {modalOpen && (
        <ConfirmUserModal
          // Note: careType should never actually be undefined for this alert
          careType={careType!}
          onClose={() => setModalOpen(false)}
          refetch={refetchAlerts}
        />
      )}
      <div className="nv1 flex items-center" style={{ marginLeft: -11 }}>
        <Icon icon="iconsAlertSvg" className="mr3" />
        <Text.body>
          <Text.h4>Upcoming Sponsored Term</Text.h4>
          {message}{' '}
          <Text.linkButton onClick={() => setModalOpen(true)}>
            <br />
            Confirm Eligibility to continue sponsored {careType}
          </Text.linkButton>
        </Text.body>
      </div>
    </Styles.widget>
  );
};

const ConfirmUserModal = ({ onClose, refetch, careType }: ReactivateModalProps) => {
  const [success, setSuccess] = useState(false);
  const { user, refetch: refetchUser } = useDrilldownContext();

  const [reactivateUser, { loading, error, data }] = useReactivateUserForSponsoredCareMutation({
    variables: {
      userId: user.id,
      careType,
    },
  });

  const closeAndRefetch = async () => {
    try {
      await refetchUser();
      await refetch();
    } finally {
      onClose();
    }
  };

  if (error || data?.reactivateUserForSponsoredCare === false) {
    return <ErrorModal closeAndRefetch={closeAndRefetch} message={error?.message} />;
  }

  return (
    <>
      <Modal isOpen={!success} onClose={onClose}>
        <MantraModal.body>
          <MantraModal.header>
            {`Confirm continued sponsored ${lowerCase(careType)} for the upcoming term?`}
          </MantraModal.header>
          <Text.body className="mt3 mb4">
            If confirmed, this patient will be able to continue sponsored care into the upcoming
            term.
          </Text.body>
          <div className="flex flex-column gap-2">
            <FinalButton
              loading={loading}
              onClick={() => reactivateUser().then(() => setSuccess(true))}
            >
              Confirm
            </FinalButton>
            <FinalButton kind="minimal_gray" onClick={onClose}>
              Close
            </FinalButton>
          </div>
        </MantraModal.body>
      </Modal>
      <Modal isOpen={success} onClose={closeAndRefetch}>
        <MantraModal.body>
          <Icon
            icon="iconsCircleCheckSvg"
            alt="Success"
            className="center db mb2"
            width={26}
            height={26}
          />
          <MantraModal.header className="tc">
            {`Sponsored ${capitalize(careType)} confirmed for the upcoming term`}
          </MantraModal.header>
          <div className="flex flex-column gap-2">
            <FinalButton kind="minimal_gray" onClick={closeAndRefetch}>
              Close
            </FinalButton>
          </div>
        </MantraModal.body>
      </Modal>
    </>
  );
};

export const PatientAlertsWidgets = () => {
  const { user } = useDrilldownContext();
  const { data, refetch } = useAdminAlertsQuery({
    variables: { userId: user.id },
  });
  const adminAlerts = data?.adminUser?.adminAlerts ?? [];

  const alerts = adminAlerts.reduce((acc, a, i) => {
    const Alert = alertMap[a.type];
    if (!Alert) return acc;
    return [
      ...acc,
      <Alert key={i} message={a.message} careType={a.careType} refetchAlerts={refetch} />,
    ];
  }, [] as JSX.Element[]);

  return alerts.length ? <>{alerts}</> : null;
};

const ProviderChangeInfo = styled.span`
  font-weight: bold;
`;

type ErrorProps = {
  closeAndRefetch: () => Promise<void>;
  message?: string;
};

const ErrorModal = ({ closeAndRefetch, message }: ErrorProps) => {
  return (
    <Modal isOpen onClose={closeAndRefetch}>
      <MantraModal.body>
        <UnexpectedError message={message} />
        <div className="flex flex-column gap-2">
          <FinalButton kind="minimal_gray" onClick={closeAndRefetch}>
            Close
          </FinalButton>
        </div>
      </MantraModal.body>
    </Modal>
  );
};
