export type ExternalToOptions = {
  sameTab?: boolean;
};

export const useExternalNavigate = () => {
  return (to: string, externalToOptions?: ExternalToOptions): void => {
    if (externalToOptions?.sameTab === true) {
      window.location.assign(to);
      return;
    }

    // Open link in new tab.
    window.open(to, '_blank');
  };
};
