import { IBoxProps } from 'native-base';
import React, { ReactNode } from 'react';
import { getStylesheet } from '../../../styles';
import { Layout } from '../../core';
import { EMBED_MAX_WIDTHS } from '../../layouts/sizes';

type PageContentProps = IBoxProps & {
  children: ReactNode;
  pageSize?: keyof typeof EMBED_MAX_WIDTHS;
};

export const PageContent = ({
  children,
  pageSize = 'largest',
  ...contentStyles
}: PageContentProps): JSX.Element => {
  const maxWidthPx = EMBED_MAX_WIDTHS[pageSize];

  return (
    <Layout.View
      maxWidth={`${maxWidthPx}px`}
      {...styles.content}
      testID="page-content"
      {...contentStyles}
    >
      {children}
    </Layout.View>
  );
};

const styles = getStylesheet({
  content: {
    alignSelf: 'center',
    height: '100%',
    width: '100%',
  },
});
