import React from 'react';
import { CareTypeEditModal, useDrilldownContext } from '../helpers';
import { Styles, Text } from '../styles';
import { UserCareFlow } from './UserCareFlow';

export const CareFlowsSection = () => {
  const { user } = useDrilldownContext();
  const orgCareFlows = user.organization?.careFlows;

  return (
    <Styles.sidebarSection>
      <Styles.sidebarSectionHeader>
        <Text.h3>Care Types</Text.h3>
        <CareTypeEditModal text="Add/Edit" />
      </Styles.sidebarSectionHeader>
      <Styles.spaceBetween>
        <div>
          <Text.label>CURRENT {user.careFlows.length === 1 ? 'PLAN' : 'PLANS'}</Text.label>
          {user.careFlows.map(flow => {
            const orgCareFlow = orgCareFlows?.find(orgFlow => orgFlow.careType === flow.careType);
            return <UserCareFlow key={flow.careType} careFlow={flow} orgCareFlow={orgCareFlow} />;
          })}
          {user.careFlows.length === 0 && <Text.body className="mt3">None</Text.body>}
        </div>
      </Styles.spaceBetween>
    </Styles.sidebarSection>
  );
};
