import React from 'react';
import styled from 'styled-components';
import { Text } from '../../globalStyles';
import { ColorLookup, colors } from '../../globalStyles/colors';

type DGMTagKinds = Extract<ColorLookup, 'success' | 'warning' | 'grayText'>;

const getKindFromCount = (count: number): DGMTagKinds => {
  if (count > 2) return 'success';
  // 1 <= count <= 2
  if (count >= 1) return 'warning';
  return 'grayText';
};

type BookableHoursTagProps = {
  count: number;
  kind?: DGMTagKinds;
  className?: string;
};

export const BookableHoursTag = ({
  className,
  kind: defaultKind,
  count,
}: BookableHoursTagProps) => {
  const kind = defaultKind ?? getKindFromCount(count);
  return (
    <BookableHoursContainer kind={kind} className={className}>
      <Text.bodySmallBold className="tc" kind={kind}>
        {count} bookable
      </Text.bodySmallBold>
    </BookableHoursContainer>
  );
};

const BookableHoursContainer = styled.div<{ kind: DGMTagKinds }>`
  border-radius: 50vh;
  padding: 0.25rem 0.5rem;
  background: ${({ kind }) => colors[`${kind}Bg` as const]};
`;
