import React from 'react';
import { Text } from '../../globalStyles';

export const Headline: React.FC<{ canEdit?: boolean; onEdit?: () => void; subhead?: string }> = ({
  children,
  canEdit,
  onEdit,
  subhead,
}) => {
  return (
    <div className="mb3">
      <div className="flex flex-row items-center">
        <Text.h3>{children}</Text.h3>
        {canEdit && onEdit && (
          <Text.linkButton onClick={onEdit} className="ml4 b">
            Edit
          </Text.linkButton>
        )}
      </div>
      {subhead && <Text.bodySmallGrey className="mt1">{subhead}</Text.bodySmallGrey>}
    </div>
  );
};
