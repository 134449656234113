import { Button, ButtonProps } from 'baseui/button';
import { ChevronLeft } from 'baseui/icon';
import React from 'react';

export const BackButton = (props: ButtonProps) => {
  return (
    <Button
      kind="minimal"
      size="compact"
      startEnhancer={() => <ChevronLeft size={18} color="#0179FF" />}
      overrides={{ BaseButton: { style: { color: '#0179FF' } } }}
      {...props}
    >
      Back
    </Button>
  );
};

export const FinalBackButton = BackButton;
