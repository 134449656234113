import React from 'react';
import styled from 'styled-components';
import { Text } from '../../globalStyles';
import { AdminSubmitAssessmentMutation } from '../../graphQL';

type Result = AdminSubmitAssessmentMutation['result'];

type AssessmentResultsProps = {
  results: Result;
};
export const AssessmentResults = ({ results }: AssessmentResultsProps) => {
  return (
    <div>
      {results.questionnaires.map(q => {
        const Component = resultMap.get(q.key);
        if (!Component) return null;
        return (
          <div key={q.key} className="mb3">
            <Component result={q} />
          </div>
        );
      })}
    </div>
  );
};

type IndividualResultProps = {
  result: Result['questionnaires'][number];
};

const Ymrs = ({ result }: IndividualResultProps) => (
  <StyledResult>
    <Text.body>
      <span className="b">YMRS Score: </span> {result.score ?? 0}
    </Text.body>
  </StyledResult>
);

const Mdq = ({ result }: IndividualResultProps) => (
  <StyledResult>
    <Text.body>
      <span className="b">Screening Result: </span>
      {result.symptoms ?? 'unknown'}
    </Text.body>
    {result.score === 1 && (
      <Text.bodySmall kind="danger">
        Consider comprehensive assessment to rule out bipolar mood disorder
      </Text.bodySmall>
    )}
    <Text.body>A positive score indicates: </Text.body>
    <ul>
      <li>7 or more of the events in Question 1 were answered “Yes”; and</li>
      <li>Question 2 was answered “Yes”; and</li>
      <li>Question 3 was answered “Moderate or “Serious”</li>
    </ul>
  </StyledResult>
);

const resultMap = new Map<string, (props: IndividualResultProps) => JSX.Element>([
  ['ymrs', Ymrs],
  ['mdq', Mdq],
]);

const StyledResult = styled.div`
  background-color: rgba(27, 80, 225, 0.08);
  padding: 1.5rem;
  border-radius: 0.25rem;
`;
