import React from 'react';
import { getStylesheet } from '../../../styles';
import { Heading } from '../../core';
import { PageContent } from '../pageCore';

export const LegacyHomePage = (): JSX.Element => {
  return (
    <PageContent {...styles.page} testID="legacy-home-page">
      <Heading.h2>You are logged in.</Heading.h2>
    </PageContent>
  );
};

const styles = getStylesheet({
  page: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
