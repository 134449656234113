import { uniq } from 'lodash';
import React, { createContext, useCallback, useContext } from 'react';
import { CareType, useConfigQuery } from '../graphQL';
import { Nullable } from '../types';

type HasCareOrg = {
  careFlows: {
    careType: CareType;
    referralRestrictedStates: string[];
    statesAvailable: string[];
  }[];
};

export const ConfigProvider: React.FC = ({ children }) => {
  const { data } = useConfigQuery({ fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first' });

  const stateHasCare = useCallback(
    (careType: CareType, state: string, organization?: Nullable<HasCareOrg>) => {
      const dtcStates: Record<CareType, Nullable<string[]>> = {
        [CareType.Psychiatry]: data?.dtcPsychiatryStates,
        [CareType.Therapy]: data?.dtcTherapyStates,
      };
      if (organization) {
        return organization.careFlows
          .filter(careFlow => careFlow.careType === careType)
          .flatMap(careFlow => {
            if (careFlow.referralRestrictedStates.length) return careFlow.referralRestrictedStates;
            return careFlow.statesAvailable;
          })
          .some(s => s.toUpperCase() === state.toUpperCase());
      }
      return !!dtcStates[careType]?.some(s => s.toUpperCase() === state.toUpperCase());
    },
    [data]
  );

  if (!data) return null;

  const { dtcPsychiatryStates, dtcTherapyStates } = data;

  return (
    <Context.Provider
      value={{
        featureFlags: data.featureFlags,
        stateHasCare,
        dtcPsychiatryStates,
        dtcTherapyStates,
        allDtcStates: uniq([...dtcPsychiatryStates, ...dtcTherapyStates]).sort(),
      }}
    >
      {children}
    </Context.Provider>
  );
};

type ConfigContext = {
  stateHasCare: (careType: CareType, state: string, organization?: Nullable<HasCareOrg>) => boolean;
  dtcTherapyStates: string[];
  dtcPsychiatryStates: string[];
  allDtcStates: string[];
  featureFlags: string[];
};

const Context = createContext<ConfigContext | null>(null);

export const useConfigContext = () => {
  const context = useContext(Context);
  if (!context) throw new Error('hook called outside of config context');
  return context;
};
