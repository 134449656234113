import React from 'react';
import * as Styles from './Styles';
import { Text } from '../../globalStyles';

type SelectedProvidersBannerProps = {
  count: number;
  buttonText: string;
  onSelect: () => void;
};

export const SelectedProvidersBanner = ({
  count,
  buttonText,
  onSelect,
}: SelectedProvidersBannerProps) => {
  if (count <= 0) return null;
  return (
    <Styles.SelectedProviderContainer>
      <Styles.BannerItems>
        <Text.bodySmall className="b">
          {count} provider{count === 1 ? '' : 's'} selected
        </Text.bodySmall>
        <Text.linkButtonSmall className="b" onClick={() => onSelect()}>
          {buttonText}
        </Text.linkButtonSmall>
      </Styles.BannerItems>
    </Styles.SelectedProviderContainer>
  );
};
