/* eslint-disable no-case-declarations */
import { compact, last } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../globalStyles';
import { cx, keyDownHandler } from '../../../utils';
import { Icon } from '../../Icons';
import { InvisibleInput } from '../Input';
import { InlineControls } from '../Shared';
import { Dropdown, StyledSelectDiv } from './MantraSelect';

type Option = {
  id: string | number;
  label: string;
};

export type MultiSelectProps = {
  options: Option[];
  disabled?: boolean;
  placeholder?: string;
  value?: (number | string)[];
  onBlur?: () => void;
  onChange: (val: (number | string)[]) => void;
  error?: boolean;
  name?: string;
  id?: string;
  style?: React.CSSProperties;
};
export const MultiSelect = ({
  options,
  placeholder = 'Select...',
  value = [],
  onBlur,
  onChange,
  disabled,
  error,
  name,
  id,
  style,
}: MultiSelectProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [focus, setFocus] = useState(false);
  const [localValue, setLocalValue] = useState<string>('');

  // we do this instead of filter options to preserve order of selection
  const selected = useMemo(
    () => (value ? compact(value.map(v => options.find(o => o.id === v))) : []),
    [options, value]
  );

  const filteredOptions = localValue
    ? options.filter(o => o.label?.toLowerCase().includes(localValue.toLowerCase()))
    : options;

  const onFocus = () => {
    if (focus || disabled) return;
    if (!localValue) setLocalValue('');
    inputRef.current?.focus();
    setFocus(true);
  };

  const handleBlur = (e: React.FocusEvent<any>) => {
    const isListItem = (e.relatedTarget as Node)?.parentNode === listRef.current;
    const isInput = e.relatedTarget === inputRef.current;
    if (isListItem || isInput) return;
    setLocalValue('');
    onBlur?.();
    setFocus(false);
  };

  const onSelect = (selectedId: number | string) => {
    if (!value) return onChange([selectedId]);
    const exists = value.includes(selectedId);
    const nextValue = exists ? value.filter((v: any) => v !== selectedId) : [...value, selectedId];
    onChange(nextValue);
  };

  return (
    <StyledSelectDiv
      onClick={() => {
        if (disabled) return;
        inputRef.current?.focus();
      }}
      className={cx({ focus, disabled, error })}
      style={style}
    >
      <div className="flex-auto flex flex-wrap gap-1">
        {selected.length === 0 && !focus && !localValue && (
          <span className="gray">{placeholder}</span>
        )}
        {selected.map(s => (
          <SelectedTag
            key={s.id}
            tabIndex={0}
            onKeyDown={keyDownHandler({ Backspace: () => onSelect(s.id) })}
          >
            <span className="truncate">{s.label}</span>{' '}
            {!disabled && (
              <Icon
                className={`ml1 ${!disabled && 'pointer'}`}
                icon="iconsXWhiteSvg"
                size={14}
                onClick={e => {
                  e.stopPropagation();
                  onSelect(s.id);
                }}
              />
            )}
          </SelectedTag>
        ))}
        <SelectInput
          name={name}
          id={id}
          data-baseweb="select" // for now since tests use it
          aria-haspopup
          onFocus={onFocus}
          disabled={disabled}
          ref={inputRef}
          value={localValue}
          onChange={e => setLocalValue(e.target.value)}
          className={cx(focus && 'focus')}
          onBlur={handleBlur}
          onKeyDown={(e: any) => {
            switch (e.key) {
              case 'Backspace':
                if (localValue?.length === 0) {
                  const toRemove = last(selected);
                  if (toRemove) onSelect(toRemove.id);
                }
                break;
              case 'Enter':
              case 'ArrowDown':
                // @ts-ignore
                listRef.current?.firstChild?.focus?.();
                e.preventDefault();
                break;
              default:
                break;
            }
          }}
        />
      </div>
      <InlineControls onClickKarat={() => inputRef.current?.focus()} disabled={disabled} />
      {focus && (
        <MultiSelectDropdown>
          {filteredOptions.length === 0 ? (
            <p className="pa3 tc o-50">No Results</p>
          ) : (
            <ul role="listbox" ref={listRef}>
              {filteredOptions.map(opt => {
                const isSelected = selected.some(s => s.id === opt.id);
                return (
                  <li
                    className={isSelected ? 'selected' : undefined}
                    key={String(opt.id)}
                    role="option"
                    aria-disabled={false}
                    aria-selected={isSelected}
                    tabIndex={-1}
                    onKeyDown={e => {
                      const { key } = e;
                      switch (key) {
                        case 'Enter':
                          e.preventDefault();
                          return onSelect(opt.id);
                        case 'ArrowDown':
                          e.preventDefault();
                          // @ts-ignore
                          return e.currentTarget.nextSibling?.focus?.();
                        case 'ArrowUp':
                          e.preventDefault();
                          const sibling = e.currentTarget.previousSibling;
                          // @ts-ignore
                          return sibling ? sibling.focus?.() : inputRef.current?.focus();
                        case 'Backspace':
                          e.preventDefault();
                          inputRef.current?.focus();
                          return setLocalValue('');
                        default:
                          if (key.length === 1) {
                            e.preventDefault();
                            inputRef.current?.focus();
                            return setLocalValue(v => `${v}${key}`);
                          }
                          break;
                      }
                    }}
                    onBlur={handleBlur}
                    onClick={() => onSelect(opt.id)}
                  >
                    <span className="flex items-center">
                      <div className="list-check-box">
                        <Icon
                          className={isSelected ? 'db' : 'dn'}
                          size={14}
                          icon="iconsCheckWhiteSvg"
                        />
                      </div>
                      {opt.label}
                    </span>
                  </li>
                );
              })}
            </ul>
          )}
        </MultiSelectDropdown>
      )}
    </StyledSelectDiv>
  );
};

const SelectInput = styled(InvisibleInput)`
  max-width: 0px;
  flex: 1;

  &.focus,
  &:focus {
    max-width: unset;
  }
  &.disabled,
  &:disabled {
    cursor: default;
  }
`;

const SelectedTag = styled.span`
  color: white;
  font-size: 0.875rem;
  background-color: black;
  height: 1.5rem;
  border-radius: 50vmin;
  padding: 0rem 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 10rem;

  &:focus {
    outline: 2px solid ${colors.primary};
  }
`;

const MultiSelectDropdown = styled(Dropdown)`
  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & li {
    padding: 0.4rem 0.75rem;
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus {
      background-color: rgb(238, 238, 238);
    }

    & .list-check-box {
      border: 1px solid black;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 25%;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.selected .list-check-box {
      background-color: ${colors.primary};
      border-color: ${colors.primary};
    }
  }
`;
