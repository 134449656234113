import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { MantraAccordion, MantraAccordionPanel } from '../../Components/Accordion';
import { Icon } from '../../Components/Icons';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import {
  AppView,
  DdhFileSuccess,
  useCrisisReportsForOrgQuery,
  useCrisisReportsZipLazyQuery,
  Entitlement,
} from '../../graphQL';
import { OrganizationSmallGrayDisplay } from '../Organizations/OrganizationTopDisplay';
import { UnexpectedError } from '../Shared';
import { hasEntitlement } from '../../utils';
import { events, useEvents } from '../../Components/Events/EventsProvider';

type ReportMonth = ReportDay[];

type ReportDay = {
  id: number;
  date: string;
  reports: CrisisReport[];
};

type CrisisReport = {
  crisisReportId: number;
  callId: string;
  time: Date;
};
type DdhCrisisQueryResult = Omit<DdhFileSuccess, 'zipLink'>;
type ResultsByMonth = { [T: string]: DdhCrisisQueryResult[] } | undefined;

/**
 * Ensure our list of reportDate keys are in descending order e.g.  [20221222,20230112] -> [20230112,20221222]
 */
const sortMonthEntriesByDateDesc = (
  monthEntryA: [string, DdhCrisisQueryResult[]],
  monthEntryB: [string, DdhCrisisQueryResult[]]
) => {
  const [monthStringA] = monthEntryA;
  const [monthStringB] = monthEntryB;
  const momentA = moment(monthStringA);
  const momentB = moment(monthStringB);
  return sortMomentDesc(momentA, momentB);
};

/**
 * Sort the reports in a ReportDay by time descending (most recent report on top)
 */
const sortReportsByTimeDesc = (report1: CrisisReport, report2: CrisisReport) => {
  const momentA = moment(report1.time);
  const momentB = moment(report2.time);
  return sortMomentDesc(momentA, momentB);
};

/**
 * Sort the days in a ReportMonth by date descending (most recent day at top)
 */
const sortDaysByDateDesc = (day1: ReportDay, day2: ReportDay) => {
  const momentA = moment(day1.date);
  const momentB = moment(day2.date);
  return sortMomentDesc(momentA, momentB);
};

const sortMomentDesc = (momentA: moment.Moment, momentB: moment.Moment) => {
  if (momentA.isBefore(momentB)) return 1;
  if (momentA.isAfter(momentB)) return -1;
  return 0;
};

const transformQueryResultToDay = ({
  reports,
  reportDate,
  id,
}: DdhCrisisQueryResult): ReportDay => {
  const dayReports = reports
    ?.map(({ id: crisisReportId, callId, callStart }) => {
      return {
        crisisReportId,
        callId,
        time: callStart,
      } as CrisisReport;
    })
    .sort(sortReportsByTimeDesc);
  return { date: reportDate, reports: dayReports, id } as ReportDay;
};

/**
 * TransformResultsForDisplay goes {['YYYY-MM']: Files[]} -> {'YYYY-MM': Files[]}[] -> {'YYYY-MM': ReportDay[]}[] -> ReportDay[][] === ReportMonth[]
 */
const transformResultsForDisplay = (resultsByMonth: ResultsByMonth): ReportMonth[] => {
  if (!resultsByMonth) return [];
  return Object.entries(resultsByMonth)
    .sort(sortMonthEntriesByDateDesc)
    .map(([, monthFiles]) => {
      return monthFiles.map(transformQueryResultToDay).sort(sortDaysByDateDesc);
    }) as ReportMonth[];
};

export function DdhCrisisReport() {
  const { currentProvider, appView } = useCurrentProvider();
  const history = useHistory();

  // Ensure only MCP providers continue, although API will check as well
  if (appView !== AppView.Mcp) {
    history.push('/');
  }

  if (!hasEntitlement(currentProvider.organizations[0], Entitlement.DdhSync)) {
    history.push('/');
  }

  // Currently this page is set to the DDH-default TZ of PT
  const timezone = 'America/Los_Angeles';

  const { data, error } = useCrisisReportsForOrgQuery();

  // resultsByMonth goes Files[] -> {['YYYY-MM']: Files[]}
  const resultsByMonth = data
    ? _.groupBy(data.crisisReportsForOrg, file =>
        moment(file.reportDate ?? moment()).format('YYYY-MM')
      )
    : undefined;

  // memoize the expensive transformResultsForDisplay
  const displayObjectsByMonth = useMemo(
    () => transformResultsForDisplay(resultsByMonth),
    [resultsByMonth]
  );

  const showOdesCrisisReports = hasEntitlement(
    currentProvider.organizations[0],
    Entitlement.OnDemand
  );

  const showConnectNowCrisisReports = hasEntitlement(
    currentProvider.organizations[0],
    Entitlement.ConnectNow
  );

  if (error) {
    return <UnexpectedError />;
  }
  if (!data) {
    return <LoadingPage />;
  }

  return (
    <div className="mw5 mw8-ns center pa2 pa4-ns">
      <DdhHeader organization={currentProvider.organizations[0]} />
      {showOdesCrisisReports && (
        <div className="flex align-items mb4 mt2">
          <Text.link to="/odes-reports" kind="danger" className="b">
            Mantra On Demand Call Reports
          </Text.link>
          <Icon icon="iconsExternalLinkRedSvg" size={16} className="ml2 mt1" />
        </div>
      )}
      {showConnectNowCrisisReports && (
        <div className="flex align-items mb4 mt2">
          <Text.link to="/connectnow-reports" kind="danger" className="b">
            Mantra ConnectNow Call Reports
          </Text.link>
          <Icon icon="iconsExternalLinkRedSvg" size={16} className="ml2 mt1" />
        </div>
      )}
      {displayObjectsByMonth.length === 0 && (
        <div className="mt4">
          <Text.h2>
            We do not have any crisis report data for this organization at this time
          </Text.h2>
        </div>
      )}
      <MantraAccordion multi>
        {displayObjectsByMonth.map((monthData: ReportMonth) => {
          const reportCount = monthData.reduce((accumulator, day) => {
            return accumulator + day.reports.length;
          }, 0);
          const isCurrentMonth = moment(monthData[0].date).isSame(new Date(), 'month');
          const formattedMonth = moment(monthData[0].date).format('MMMM YYYY');
          return (
            <div className="bg-white mv3 pa3 ba br3 b--light-gray">
              <MantraAccordionPanel
                startOpen={isCurrentMonth}
                title={
                  <div className="flex">
                    <Text.h3 className="pr3 flex">
                      <div className="mr3">{formattedMonth}</div>
                      <div className="f6">
                        {reportCount} report{reportCount > 1 ? 's' : ''}
                      </div>
                    </Text.h3>
                  </div>
                }
              >
                <ReportMonthSection month={monthData} timezone={timezone} key={formattedMonth} />
              </MantraAccordionPanel>
            </div>
          );
        })}
      </MantraAccordion>
    </div>
  );
}

type ReportMonthSectionProps = {
  month: ReportDay[];
  timezone: string;
};
const ReportMonthSection = ({ month, timezone }: ReportMonthSectionProps) => {
  return (
    <div className="">
      {month.map(dayData => {
        const componentKey = `${dayData.date}-${dayData.id}`;
        return (
          <>
            <GrayHr key={`${componentKey}-hr`} />
            <ReportDayRow day={dayData} timezone={timezone} key={`${componentKey}-row`} />
          </>
        );
      })}
    </div>
  );
};

const GrayHr = () => {
  return (
    <hr
      className="mh1 mb3"
      style={{
        color: '#eee',
        backgroundColor: '#eee',
        border: 0,
        height: 1,
      }}
    />
  );
};

type ReportDayRowProps = { day: ReportDay; timezone: string };
const ReportDayRow = ({ day, timezone }: ReportDayRowProps) => {
  const [query] = useCrisisReportsZipLazyQuery({
    onCompleted: ({ crisisReportsZip }) => {
      if (crisisReportsZip.zipLink !== '') {
        window.open(crisisReportsZip.zipLink, '', 'noopener');
      }
    },
  });
  const { track: trackEvent } = useEvents();
  const formattedDay = moment(day.date).format('ddd, MMM. D, YYYY'); // Don't TZ adjust this date string
  return (
    <div className="flex flex-row">
      <div className="fl w-25 ma2 b">{formattedDay}</div>
      <div className="fl w-50 ma2">
        {day.reports.map(report => {
          return <Report report={report} timezone={timezone} key={`${report.callId}-${day.id}`} />;
        })}
      </div>
      <div className="fl w-25 ma2">
        <Text.linkButton
          className="b"
          onClick={() => {
            trackEvent(events.ddh.mcpUserDownloadedReport, {
              ddhFileId: day.id,
              date: day.date,
              count: day.reports.length,
            });
            query({ variables: { ddhFileSuccessId: day.id } });
          }}
        >
          Download reports ({day.reports.length})
        </Text.linkButton>
      </div>
    </div>
  );
};

type DdhHeaderProps = {
  organization: {
    name: string;
    abbreviation: string;
  };
};
const DdhHeader = ({ organization }: DdhHeaderProps) => {
  return (
    <>
      <div className="mw3 center mb4">
        <Icon icon="iconsMantraLogoDefaultSvg" size={40} />
      </div>
      <div className="pb4">
        <Text.link className="b" to="/">
          {'<'} Return to Mantra Collaboration Portal
        </Text.link>
      </div>
      <OrganizationSmallGrayDisplay organization={organization} />
      <Text.h1 className="fw4">Crisis Reports</Text.h1>
      <Text.bodySmallGrey>
        Provided by Didi Hirsch. Updated daily at 9:00 am ET.{' '}
      </Text.bodySmallGrey>
      <br />
      <Text.bold>
        Reminder: Crisis reports are not visible to Mantra Health or its providers. For questions
        pertaining to individual crisis reports, contact your Didi Hirsch representative.
      </Text.bold>
    </>
  );
};

const Report = ({ report, timezone }: { report: CrisisReport; timezone: string }) => {
  return (
    <div className="flex mb2 v-mid">
      <Icon icon="iconsFileSvg" className="mh1" />
      <div className="pt1 fw7 ph1">Report #{report.callId}</div>
      <div className="pt1 fw4 ph1">
        {moment(report.time).tz(timezone).format('MM/DD/YY h:mma z')}
      </div>
    </div>
  );
};
