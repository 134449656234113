import { Accordion as BaseAccordion, Panel, PanelOverrides } from 'baseui/accordion';
import { memoize, uniq } from 'lodash';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text } from '../globalStyles';
import { borderWidth, cx, uuid } from '../utils';
import { InlineSVG } from './Icons';

export type AccordionItem = {
  key?: string | number;
  title: string | JSX.Element;
  content?: JSX.Element;
  hideIcon?: boolean;
};

export type AccordionProps = {
  items: AccordionItem[];
  hideIcons?: boolean;
  borders?: boolean;
};

// A wrapper for the baseui accordion for now but eventually will be our own
export const Accordion = ({ items, borders, hideIcons }: AccordionProps) => {
  return (
    <BaseAccordion>
      {items.map((item, i) => (
        <Panel
          key={item.key || i}
          title={item.title}
          overrides={overrideFactory({
            isLastRow: i === items.length - 1,
            borders,
            hideIcon: !item.content || hideIcons,
            noContent: !item.content,
          })}
        >
          {item.content}
        </Panel>
      ))}
    </BaseAccordion>
  );
};

type OverrideArgs = {
  isLastRow: boolean;
  borders?: boolean;
  hideIcon?: boolean;
  noContent?: boolean;
};
const overrideFactory = memoize(
  ({ isLastRow, borders, hideIcon, noContent }: OverrideArgs): PanelOverrides<any> => ({
    ToggleIcon: {
      style: hideIcon ? { display: 'none' } : undefined,
    },
    Header: {
      style: {
        fontWeight: 'bold',
        paddingLeft: 0,
        paddingRight: 0,
        cursor: noContent ? 'default' : 'pointer',
        ...(borders ? {} : borderWidth('0')),
        ...(isLastRow ? { borderBottomColor: 'transparent' } : {}),
      },
    },
    Content: {
      style: {
        backgroundColor: 'white',
        display: noContent ? 'none' : undefined,
        ...(borders ? {} : borderWidth('0')),
        ...(isLastRow ? { borderBottomColor: 'transparent' } : {}),
      },
    },
  })
);

// BELOW IS THE IN-HOUSE MANTRA ACCORDION -- REPLACE THE BASEUI ACCORDION WITH THIS ONE

type IMantraAccordionCtx = {
  isPanelOpen: (panelId: string) => boolean;
  onClickPanel: (clickedPanelId: string) => void;
  onOpenPanel: (panelId: string) => void;
};

const MantraAccordionCtx = createContext<IMantraAccordionCtx | null>(null);
const useMantraAccordion = () => {
  const ctx = useContext(MantraAccordionCtx);
  if (!ctx) throw new Error('useMantraAccordion called outside of context');
  return ctx;
};

type MantraAccordionProps = {
  className?: string;
  multi?: boolean;
  style?: React.CSSProperties;
};

export const MantraAccordion: React.FC<MantraAccordionProps> = ({
  children,
  className,
  multi,
  style,
}) => {
  // The "open state" is a list of each panel id that is open.
  const [openPanelIds, setOpenPanelIds] = useState<String[]>([]);

  const isPanelOpen = (panelId: string) => openPanelIds.includes(panelId);
  const onClickPanel = (clickedPanelId: string) => {
    const panelIsOpen = isPanelOpen(clickedPanelId);

    if (panelIsOpen) {
      // The panel is already open, so close it.
      // To do this, filter the panel id out of the open panel ids array.
      setOpenPanelIds(currentOpenPanelIds =>
        currentOpenPanelIds.filter(openPanelId => openPanelId !== clickedPanelId)
      );
    } else if (!multi) {
      // The panel is closed, so open it -- multi disabled.
      // Since only one can be open at a time, just set the
      // open panel ids to just this panel id.
      setOpenPanelIds([clickedPanelId]);
    } else {
      // The panel is closed, so open it -- multi enabled.
      // Since more than one panel can be open at a time, add this
      // panel id to the array of open panel ids.
      setOpenPanelIds(currentOpenPanelIds => [...currentOpenPanelIds, clickedPanelId]);
    }
  };
  const onOpenPanel = (panelId: string) => {
    setOpenPanelIds(currentOpenPanelIds => {
      return uniq([...currentOpenPanelIds, panelId]);
    });
  };

  return (
    <MantraAccordionCtx.Provider value={{ isPanelOpen, onClickPanel, onOpenPanel }}>
      <div className={cx('mantra-accordion flex flex-column gap-3', className)} style={style}>
        {children}
      </div>
    </MantraAccordionCtx.Provider>
  );
};

type MantraAccordionPanelProps = {
  children: React.ReactNode;
  labelClassName?: string;
  startOpen?: boolean;
  title: string | JSX.Element;
  withBottomBorder?: boolean;
  className?: string;
};

export const MantraAccordionPanel = ({
  children,
  className,
  labelClassName,
  startOpen,
  title,
  withBottomBorder,
}: MantraAccordionPanelProps) => {
  const panelId = useRef(uuid());
  const { isPanelOpen, onClickPanel, onOpenPanel } = useMantraAccordion();
  const panelIsOpen = isPanelOpen(panelId.current);

  useEffect(() => {
    if (startOpen) {
      onOpenPanel(panelId.current);
    }
  }, [panelId.current]);

  return (
    <PanelContainer className={cx(className, 'mantra-accordion-panel', { withBottomBorder })}>
      <PanelLabel
        className={cx('mantra-accordion-panel-label', labelClassName)}
        onClick={() => onClickPanel(panelId.current)}
      >
        <InlineSVG icon={panelIsOpen ? 'chevron-down' : 'chevron-right'} size={15} />
        {typeof title === 'string' ? (
          <Text.bodySmallBold>{title}</Text.bodySmallBold>
        ) : (
          <div>{title}</div>
        )}
      </PanelLabel>
      <HiddenPanelContent
        className={cx('mantra-accordion-panel-content', panelIsOpen && 'panel-open')}
      >
        {children}
      </HiddenPanelContent>
    </PanelContainer>
  );
};

const PanelContainer = styled.div`
  overflow: hidden;

  &.withBottomBorder {
    &:not(:last-of-type) {
      border-bottom: 1px solid #d8d8d8;
    }
  }
`;

const PanelLabel = styled.div`
  user-select: text;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const HiddenPanelContent = styled.div`
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition-timing-function: ease-in;
  transition-duration: 0.1s;
  transition-property: max-height, height, padding;
  padding: 0 1.5em;

  &.panel-open {
    transition-timing-function: ease-out;
    height: auto;
    max-height: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
  }
`;
