import React from 'react';
import styled from 'styled-components';
import { colors } from '../globalStyles';

type MantraSpinnerProps = {
  size?: number;
  simple?: boolean;
  className?: string;
};
export const MantraSpinner = ({ size = 64, simple, className }: MantraSpinnerProps) => {
  return (
    <StyledSpinnerContainer className={className} style={{ width: size, height: size }}>
      <div className="outer-1" />
      <div className="outer-spinner-mask">
        <div className="outer-spinner" />
      </div>
      {!simple && (
        <>
          <div className="spinner">
            <div className="dot" />
          </div>
          <div className="still">
            <svg
              width="100%"
              viewBox="0 0 34 27"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path
                d="M3.10598847,26.1492666 L-2.36847579e-13,23.0544369 L7.34257541,15.738614 C8.40151624,14.6838444 10.075999,14.5982264 11.2376217,15.539477 L16.6998016,19.9648045 L22.0840091,15.6130592 C23.2461808,14.6748176 24.9190164,14.7612562 25.9765844,15.8146581 L33.2431094,23.0544369 L30.1365718,26.1492666 L23.8145002,19.8501913 L18.5324254,24.1187804 C17.4693662,24.9787897 15.9255695,24.9785161 14.8619613,24.116592 L9.50328692,19.7755149 L3.10598847,26.1492666 Z"
                fill="black"
              />
            </svg>
          </div>
        </>
      )}
    </StyledSpinnerContainer>
  );
};

interface Props {
  spinnerSize?: number;
}

export const LoadingWrapper = styled.div`
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export function LoadingOverlay({ spinnerSize = 125 }: Props) {
  return (
    <LoadingWrapper>
      <MantraSpinner size={spinnerSize} />
    </LoadingWrapper>
  );
}

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const LoadingPage = ({ className }: { className?: string }) => {
  return (
    <LoadingSpinnerContainer className={className}>
      <MantraSpinner />
    </LoadingSpinnerContainer>
  );
};

const StyledSpinnerContainer = styled.div`
  position: relative;
  animation-iteration-count: 1;

  & svg {
    animation-iteration-count: 1;
    animation: 3s open;
  }

  & .outer-1 {
    position: absolute;
    border-radius: 50%;
    border: 3px solid whitesmoke;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  & .outer-spinner-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    clip-path: inset(0% 50% 0% 0%);
    mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    animation: 1.5s rotate infinite linear;
  }

  & .outer-spinner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid ${colors.grey.border};
  }

  & .spinner {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 20% 20%;
    transform-origin: bottom center;
    animation: 3s dot-rotate infinite;
    animation-delay: -1.5s;
    opacity: 0;
  }

  & .dot {
    width: 30%;
    height: 30%;
    border-radius: 100%;
    background-color: ${colors.grey.border};
  }

  & .still {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 15%;
    display: flex;
    justify-content: center;
  }

  @keyframes open {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dot-rotate {
    0% {
      transform: rotate(-90deg);
    }
    15% {
      opacity: 0;
    }
    35% {
      transform: rotate(0deg);
      opacity: 1;
    }
    65% {
      transform: rotate(0deg);
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      transform: rotate(90deg);
    }
  }
`;
