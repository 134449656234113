import { isEmpty, isArray, memoize } from 'lodash';
import moment from 'moment-timezone';
import React, { ReactNode, ReactElement } from 'react';

/** 18 years in days */
const DEFAULT_18_YEARS_IN_DAYS = 6574;
/** 16 years in days */
const DEFAULT_16_YEARS_IN_DAYS = 5844;

export const findFirstTextChild = memoize((root: ReactNode) => {
  const isDiscoverable = (v: any): v is string | number | ReactElement | any[] => {
    return typeof v === 'string' || typeof v === 'number' || React.isValidElement(v) || isArray(v);
  };

  let asArray = React.Children.toArray(root).filter(isDiscoverable);
  // bfs
  while (!isEmpty(asArray)) {
    const [node, ...rest] = asArray;
    asArray = rest;

    if (typeof node === 'string') {
      return node;
    }
    if (typeof node === 'number') {
      return String(node);
    }
    if (isDiscoverable(node)) {
      const children = (isArray(node) ? node : [node.props?.children]).filter(isDiscoverable);
      asArray.push(...children);
    }
  }
});

export function checkIfOver18(birthDate: string) {
  if (moment(birthDate, 'MM/DD/YYYY').isValid()) {
    if (moment().diff(moment(birthDate), 'days') >= DEFAULT_18_YEARS_IN_DAYS) {
      return true;
    }
  }

  return false;
}

export function checkIfOver16(birthDate: string) {
  if (moment(birthDate, 'MM/DD/YYYY').isValid()) {
    if (moment().diff(moment(birthDate), 'days') >= DEFAULT_16_YEARS_IN_DAYS) {
      return true;
    }
  }

  return false;
}

export const isValidEmail = (email: string) => {
  const rfc5322EmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return rfc5322EmailRegex.test(email);
};
