import { useState } from 'react';
import { Slot } from '../types';

export const useDisplayableSlots = (slots: Slot[]) => {
  const slotBatch = 5;

  const availableSlots = slots.filter(s => s.available);
  const lastAvailableIdx = availableSlots.length - 1;

  const [endIdx, setEndIdx] = useState(slotBatch);
  let displayableSlots = availableSlots.slice(0, endIdx);

  const hasLessOrSameAsBatch = availableSlots.length <= slotBatch;
  const canExpand = availableSlots.length > slotBatch && endIdx <= lastAvailableIdx;

  const fillSize = Math.max(endIdx, 0) - displayableSlots.length;

  if (fillSize) {
    displayableSlots = [...displayableSlots, ...Array(fillSize).fill({ available: false } as any)];
  }

  const expand = () => {
    if (!canExpand) return;
    setEndIdx(idx => Math.min(idx + slotBatch, lastAvailableIdx + 1));
  };

  const close = () => {
    setEndIdx(slotBatch);
  };

  return {
    displayableSlots,
    canExpand,
    hasLessOrSameAsBatch,
    expand,
    close,
  };
};

export default useDisplayableSlots;
