import React from 'react';
import { GeolocationCheck } from '../Components/Geolocation/GeolocationCheck';
import { GeolocationProvider } from '../Components/Geolocation/GeolocationContext';
import { AppRoutes as EmbedRoutes, AppPublicRoutes as PublicEmbedRoutes } from './AppRoutes';
import { NativeBaseProvider } from './plugins/nativeBase';

export const AppEmbed = (): JSX.Element => {
  return (
    <NativeBaseProvider>
      <GeolocationProvider>
        <GeolocationCheck>
          <EmbedRoutes />
        </GeolocationCheck>
      </GeolocationProvider>
    </NativeBaseProvider>
  );
};

export const AppPublicEmbed = (): JSX.Element => {
  return (
    <NativeBaseProvider>
      <GeolocationProvider>
        <GeolocationCheck>
          <PublicEmbedRoutes />
        </GeolocationCheck>
      </GeolocationProvider>
    </NativeBaseProvider>
  );
};
