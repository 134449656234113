import { get } from 'lodash';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';
import { SectionColor } from '../../graphQL';

const NOTE_PADDING = '3rem';

type StyleProps = {
  bg?: SectionColor | null;
  border?: SectionColor | null;
};

export const StyledNoteContainer = styled.div<StyleProps>`
  background-color: ${({ bg }) => (bg && get(colors, bg)) ?? 'inherit'};
  border-color: ${({ border }) => (border && get(colors, border)) ?? 'black'};
  padding: ${({ bg, border }) => (bg || border ? '1.5rem' : '0rem')};
  border-radius: ${({ bg, border }) => (bg || border ? '0.25rem' : 'none')};
`;

// [height: 0] this makes it so that the height is set entirely by the flex-grow prop
export const NoteWrapper = styled.div`
  background: white;
  padding: 2rem ${NOTE_PADDING} ${NOTE_PADDING};
  width: 100%;
  box-sizing: border-box;
  max-height: 100%;
  position: relative;
  overflow-y: scroll;
  height: 0;
  flex-grow: 1;

  & hr {
    margin-right: -${NOTE_PADDING};
    margin-left: -${NOTE_PADDING};
  }
`;

export const AddButton = styled(Text.body)`
  cursor: pointer;
  color: ${colors.primary};
  display: inline-block;
  margin-right: 10px;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;

  &:hover {
    background: ${colors.grey.light};
  }
`;

export const DeleteButton = styled.button`
  cursor: pointer;
  padding: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: none;
  border-radius: 0.175rem;

  &:hover {
    background: ${colors.grey.light};
  }
`;

export const QuestionContainer = styled(StyledNoteContainer)<{ isChild?: boolean }>`
  position: relative;
  &:not(:first-of-type) {
    margin-top: ${({ isChild }) => (isChild ? `1.5rem` : `2.5rem`)};
  }
`;

export const NotePopoverContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Error = styled.p`
  color: ${colors.danger};
  border: 1px solid ${colors.danger};
  border-radius: 4px;
  margin: 1rem auto;
  padding: 0.5rem;
`;

export const Warning = styled.div`
  color: ${colors.text};
  background-color: ${colors.warningBg};
  border-radius: 4px;
  padding: 22px;
  margin-bottom: 44px;
`;

export const ProtocolContainer = styled.div`
  padding: 18px 24px 15px 24px;
  border: black solid 1px;
  border-left-width: 8px;
  width: 100%;
`;

export const ProtocolContent = styled.div`
  & table {
    width: 100%;
    border-collapse: collapse;
  }
  & table td,
  & table td * {
    vertical-align: top;
  }
  & tr:not(:last-of-type) {
    border-bottom: 1px solid #d8d8d8;
  }
  & td {
    text-align: left;
    padding: 0.688rem 0.5rem 0.688rem 0rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

export const QuestionList = styled.ul`
  margin-top: 0.25rem;
  padding-left: 1rem;
  list-style-type: disc;
  & li {
    margin-top: 0.25rem;
  }
`;

export const WarningContainer = styled(StyledNoteContainer)`
  width: 100%;
  border-radius: 5px;
  padding: 1.25rem;
`;

export const SelectedCircle = styled.div`
  border: 3px solid ${colors.grey.border};
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
`;
