import React from 'react';
import { CareType } from '../../../../graphQL';
import { useQueryParams } from '../../../../Hooks';
import { BookingWizard } from '../../../../Pages/Booking/BookingWizard';
import { UnexpectedError } from '../../../../Pages/Shared';
import { RouteParams, useParams } from '../../../routes';
import { PageContent } from '../pageCore';

export const PatientBookingPage = (): JSX.Element => {
  const { patientId } = useParams<RouteParams<'patientBooking'>>();

  const [parsedSearch] = useQueryParams();
  const rescheduleId = Number(parsedSearch.rescheduleId) || undefined;
  const careType: CareType | undefined = parsedSearch.careType ?? undefined;
  const outOfPolicy = Boolean(Number(parsedSearch.outOfPolicy ?? 0) && rescheduleId);

  // TODO: Convert embed patientId into userId.
  const userId = Number(patientId);
  if (!userId) {
    return <UnexpectedError message="Patient not found" cannotRetry />;
  }

  return (
    <PageContent testID="booking-page">
      <BookingWizard
        careType={careType}
        outOfPolicy={outOfPolicy}
        rescheduleId={rescheduleId}
        userId={userId}
      />
    </PageContent>
  );
};
