import { without } from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '../../globalStyles';
import { cx, keyDownHandler } from '../../utils';

const checkboxKinds = ['primary', 'black'] as const;
type CheckboxKind = typeof checkboxKinds[number];
type CheckboxKindProps = { kind?: CheckboxKind };

type CheckboxListRHFProps = {
  options: { id: string | number | null; label: string }[];
  name: string;
} & CheckboxKindProps;

/* @TODO - CHANGE THIS TO CHECKBOXLIST AND RHF SEPERATE */
export const CheckboxListRHF = ({ options, name, kind }: CheckboxListRHFProps) => {
  return (
    <Controller
      name={name}
      render={({ onChange, value }) => (
        <div>
          {options.map(option => (
            <div className="flex items-start justify-start mt3 mb3" key={option.id!}>
              <div className="relative">
                <Checkbox
                  kind={kind}
                  checked={value.includes(option.id)}
                  onChange={c => onChange(c ? [...value, option.id] : without(value, option.id))}
                >
                  {option.label}
                </Checkbox>

                <div className="checkbox-content" />
              </div>
            </div>
          ))}
        </div>
      )}
    />
  );
};

export type CheckboxProps = {
  className?: string;
  error?: boolean;
  required?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange: (val: boolean) => void;
  children?: React.ReactNode;
  name?: string;
} & CheckboxKindProps;

export const Checkbox = ({
  className,
  children,
  error,
  required,
  checked,
  disabled,
  onChange,
  ...props
}: CheckboxProps) => {
  const handleChange = () => onChange(!checked);

  return (
    <div className={`flex items-start justify-start ${className}`}>
      <div className="relative">
        <StyledCheckbox
          {...props}
          tabIndex={0}
          onClick={() => {
            !disabled && handleChange();
          }}
          onChange={() => {
            !disabled && handleChange();
          }}
          onKeyDown={() => {
            !disabled && keyDownHandler({ Enter: handleChange });
          }}
          role="checkbox"
          className={cx({ error, checked, disabled }, className)}
          aria-invalid={!!error}
        />
        <div className="checkbox-content" />
      </div>
      {/* eslint-disable-next-line */}
      <label htmlFor={props.name} onClick={handleChange}>
        {children}
      </label>
    </div>
  );
};

const kindToBorder: Record<CheckboxKind, FlattenSimpleInterpolation> = {
  black: css`
    border: 2px solid #b6b4b4;
  `,
  primary: css`
    border: 2px solid #b6b4b4;
  `,
};

const kindToBackground: Record<
  CheckboxKind,
  Record<'checked' | 'unchecked' | 'disabled', FlattenSimpleInterpolation>
> = {
  black: {
    checked: css`
      background-color: black;
    `,
    unchecked: css`
      background-color: white;
    `,
    disabled: css`
      background-color: rgb(239, 239, 239);
    `,
  },
  primary: {
    checked: css`
      background-color: rgb(1, 120, 255);
    `,
    unchecked: css`
      background-color: white;
    `,
    disabled: css`
      background-color: rgb(239, 239, 239);
    `,
  },
};

const StyledCheckbox = styled.div<CheckboxKindProps>`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;

  ~ .checkbox-content {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.6rem;
    ${({ kind = 'primary' }) => kindToBorder[kind]}
  }

  &:focus-visible ~ .checkbox-content {
    outline: 2px solid ${colors.primary};
  }

  & ~ .checkbox-content {
    border-radius: 20%;
    padding: 10%;
    ${({ kind = 'primary' }) => kindToBackground[kind].unchecked}
  }

  &.checked ~ .checkbox-content {
    ${({ kind = 'primary' }) => kindToBackground[kind].checked}
    background-image: url('data:image/svg+xml,%0A%20%20%20%20%3Csvg%20width%3D%2217%22%20height%3D%2213%22%20viewBox%3D%220%200%2017%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M6.50002%2012.6L0.400024%206.60002L2.60002%204.40002L6.50002%208.40002L13.9%200.900024L16.1%203.10002L6.50002%2012.6Z%22%20fill%3D%22%23FFFFFF%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E%0A%20%20');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-origin: content-box;
    border: initial;
  }

  &.disabled ~ .checkbox-content {
    ${({ kind = 'primary' }) => kindToBackground[kind].disabled}
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.error:not(:focus) ~ .checkbox-content {
    background-color: rgb(255, 160, 147);
  }
`;
