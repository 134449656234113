import React, { ReactNode } from 'react';
import { getStylesheet } from '../../../styles/stylesheet';
import { Layout } from '../../core';
import { EMBED_MAX_WIDTH_PX } from '../sizes';

type EmbedLayoutProps = {
  children: ReactNode;
};

export const EmbedLayout = ({ children }: EmbedLayoutProps): JSX.Element => {
  return (
    <Layout.Center {...styles.outer} testID="simple-layout-outer">
      <Layout.View {...styles.inner} testID="simple-layout-inner">
        {children}
      </Layout.View>
    </Layout.Center>
  );
};

const styles = getStylesheet({
  outer: {
    backgroundColor: 'white.200',
    height: '100vh',
    justifyContent: 'flex-start',
    overflowY: 'hidden',
  },
  inner: {
    height: '100%',
    maxWidth: EMBED_MAX_WIDTH_PX,
    overflowY: 'auto',
    padding: 6,
    width: '100%',
  },
});
