import React from 'react';
import styled from 'styled-components';

interface Props {
  showFullText?: boolean;
  isExpanded?: boolean;
  isOzView?: boolean;
  children: React.ReactNode;
}

export const AnnouncementsContainerDiv: React.FC = ({ children }) => (
  <div className="flex flex-column items-center ma4">{children}</div>
);

export const AnnouncementDiv: React.FC = ({ children }) => (
  <div className="flex flex-row pb2 pt3 mt3 bt b--moon-gray">{children}</div>
);

export const AnnouncementsSectionDiv: React.FC = ({ children }) => (
  <div className="flex flex-column pa4 mb4 w-100 bg-white ba b--moon-gray">{children}</div>
);

export const AnnouncementContentDiv: React.FC = ({ children }) => (
  <div className="flex flex-row pb2 pt3 mt3 bt b--moon-gray">{children}</div>
);

export const EmptyContentDiv: React.FC = ({ children }) => (
  <div className="flex flex-row mv2">{children}</div>
);

export const CurrentUpdatesIcon: React.FC = ({ children }) => (
  <div className="br-pill bg-light-gray ml3 ph2 pv0 f7 fw6 mid-gray lh-copy-l">{children}</div>
);

export const HeaderDiv: React.FC = ({ children }) => (
  <div className="flex flex-row pb2">{children}</div>
);

export const HeaderContainerDiv = ({ children, isOzView }: Props) => (
  <div className={`flex justify-between ph4 ${isOzView ? 'flex-row' : 'flex-column pt4'}`}>
    {children}
  </div>
);

export const LeftSectionDiv: React.FC = ({ children }) => (
  <div className="flex flex-column w-20 mr3">{children}</div>
);

export const RightSectionDiv: React.FC = ({ children }) => (
  <div className="flex flex-column w-75">{children}</div>
);

export const ContentDiv = styled.div<Props>`
  height: ${props => (props.isExpanded && props.showFullText ? 'auto' : '2.8em')};
  overflow: hidden;
`;

export const Divider: React.FC = ({ children }) => (
  <div className="mv3 bb b--moon-gray">{children}</div>
);
