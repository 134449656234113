import { useState } from 'react';
import {
  CreateProviderStateAllocationsModelInput,
  UpdateProviderStateAllocationsModelInput,
} from '../../../../../graphQL';

type HourKey = 'maxHours' | 'maxIntakeHours';

export type StateAllocationMap = {
  [key: string]:
    | CreateProviderStateAllocationsModelInput
    | UpdateProviderStateAllocationsModelInput;
};

export type ErrorMap = {
  [key: string]: StateAllocError;
};

export type StateAllocError = {
  maxHours?: string;
  maxIntakeHours?: string;
};

type EditStateHookArgs = {
  initialState: StateAllocationMap;
  maxWeekHours: number;
  maxWeekIntakeHours: number;
};

export const useEditStateAllocations = ({
  initialState,
  maxWeekHours,
  maxWeekIntakeHours,
}: EditStateHookArgs) => {
  const [localStateAllocations, setLocalStateAllocations] =
    useState<StateAllocationMap>(initialState);

  const [hasChanged, setHasChanged] = useState(false);
  const [stateAllocationErrors, setStateAllocationErrors] = useState<ErrorMap>({});
  const [formErrors, setFormErrors] = useState<StateAllocError>({});

  const hasErrors = Boolean(
    Object.values(stateAllocationErrors).length || Object.values(formErrors).length
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber;
    if (!isNaN(value)) {
      const [geoState, hourKey] = e.target.name.split('-') as [string, HourKey];

      let errorMsg: string = '';
      let formErrorMsg: string = '';

      // if intake field
      if (hourKey === 'maxIntakeHours') {
        const individualComparisonValue =
          localStateAllocations[geoState] &&
          (localStateAllocations[geoState].maxHours ||
            localStateAllocations[geoState].maxHours === 0)
            ? localStateAllocations[geoState].maxHours
            : Infinity;
        const currentIntakeHoursSum = Object.keys(localStateAllocations).reduce(
          (acc: number, allocationGeoState) => {
            if (allocationGeoState !== geoState) {
              acc += localStateAllocations[allocationGeoState].maxIntakeHours
                ? localStateAllocations[allocationGeoState].maxIntakeHours
                : 0;
            } else {
              acc += value;
            }
            return acc;
          },
          0
        );
        // check it's a whole number
        if (!Number.isInteger(value)) {
          errorMsg = 'Only positive whole numbers allowed for Intakes.';
        } else if (value > individualComparisonValue) {
          errorMsg = 'Intake allocation exceeds number of allocated sponsored hours.';
        }
        if (maxWeekIntakeHours < currentIntakeHoursSum) {
          formErrorMsg = `Sum of intake allocations exceeds provider weekly max intakes. (${maxWeekIntakeHours})`;
        }
      } else if (hourKey === 'maxHours') {
        // field is non intake

        const individualComparisonValue =
          localStateAllocations[geoState] &&
          (localStateAllocations[geoState].maxIntakeHours ||
            localStateAllocations[geoState].maxIntakeHours === 0)
            ? localStateAllocations[geoState].maxIntakeHours
            : Infinity;
        const currentHoursSum = Object.keys(localStateAllocations).reduce(
          (acc: number, allocationGeoState) => {
            if (allocationGeoState !== geoState) {
              acc += localStateAllocations[allocationGeoState].maxHours
                ? localStateAllocations[allocationGeoState].maxHours
                : 0;
            } else {
              acc += value;
            }
            return acc;
          },
          0
        );

        const maxHoursRegex = /^\d+(\.(25|5|75|0)0*)?$/;
        if (value.toString().match(maxHoursRegex) === null) {
          errorMsg = 'Only positive whole numbers and increments of .25, .5, and .75 allowed.';
        } else if (value < individualComparisonValue) {
          errorMsg = 'Intake allocation exceeds number of allocated sponsored hours.';
        }
        if (maxWeekHours < currentHoursSum) {
          formErrorMsg = `Sum of sponsored hour allocations exceeds available pooled hours. (${maxWeekHours})`;
        }
      }

      const currentRecord = localStateAllocations[geoState];
      currentRecord[hourKey] = value;
      setLocalStateAllocations(currentState => ({ ...currentState, [geoState]: currentRecord }));
      // if (errorMsg !== '') {
      const existingErrorForAllocation = stateAllocationErrors[geoState] || {};
      if (errorMsg === '') {
        delete existingErrorForAllocation[hourKey];
      } else {
        existingErrorForAllocation[hourKey] = errorMsg;
      }
      if (Object.values(existingErrorForAllocation).length) {
        setStateAllocationErrors(currentState => ({
          ...currentState,
          [geoState]: existingErrorForAllocation,
        }));
      } else {
        const errorState = stateAllocationErrors;
        delete errorState[geoState];
        setStateAllocationErrors(errorState);
      }

      const existingFormErrors: StateAllocError = formErrors || {};
      if (formErrorMsg === '') {
        delete existingFormErrors[hourKey];
      } else {
        existingFormErrors[hourKey] = formErrorMsg;
      }
      setFormErrors(existingFormErrors);

      if (!hasChanged) {
        setHasChanged(true);
      }
    }
  };

  return [
    handleChange,
    { localStateAllocations, stateAllocationErrors, formErrors, hasErrors, hasChanged },
  ] as const;
};
