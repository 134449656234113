import { isArray } from 'lodash';
import React, { HTMLProps } from 'react';
import styled from 'styled-components';
import { Text } from '../globalStyles';
import {
  SectionCopyFragment as NoteCopyFragment,
  ListCopy,
  TextTag,
  SectionCopy as NoteCopy,
} from '../graphQL';

export const textTagMap: Record<TextTag, React.FC<HTMLProps<any>>> = {
  [TextTag.H1]: Text.h1,
  [TextTag.H2]: Text.h2,
  [TextTag.H3]: Text.h3,
  [TextTag.Body]: Text.body,
  [TextTag.BodyBold]: Text.bodyBold,
  [TextTag.Bodysmall]: Text.bodySmall,
  [TextTag.BodySmallBold]: Text.bodySmallBold,
  [TextTag.Caption]: Text.caption,
  [TextTag.Label]: Text.label,
  [TextTag.BodyGrey]: Text.bodyGrey,
};

export const CopyContent = ({ copy }: { copy: NoteCopyFragment }) => {
  const { style } = copy;
  const kind = style?.kind ?? undefined;
  const italic = !!style?.italic;
  const Tag = style?.tag ? textTagMap[style.tag] : Text.body;
  if (isNoteList(copy)) {
    return (
      <CopyList>
        {copy.bullets.map((b, i) => (
          <li key={i}>
            <Tag kind={kind} className={italic ? 'i' : ''}>
              {b}
            </Tag>
          </li>
        ))}
      </CopyList>
    );
  }
  return (
    <Tag kind={kind} className={italic ? 'i' : ''}>
      {copy.text}
    </Tag>
  );
};

const isNoteList = (copy: NoteCopy): copy is ListCopy => {
  return 'bullets' in copy && isArray(copy.bullets);
};

const CopyList = styled.ul`
  margin-top: 0.25rem;
  padding-left: 1rem;
  list-style-type: disc;
  & li {
    margin-top: 0.25rem;
  }
`;
