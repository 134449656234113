import { ApolloError as FullApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

type ApolloError = Pick<FullApolloError, 'graphQLErrors'> &
  Partial<Pick<FullApolloError, 'networkError'>>;

type GraphqlExtension = {
  code: string;
  customCode?: string;
};

/**
 * Determines if the graphQL error(s) are of a specific custom code type.
 *
 * @param apolloError - The complete apollo error object (must have a graphQLErrors property).
 * @param type        - Matches the customCode defined by the API.
 * @param entryPoint  - Optionally only search errors for a specific query/mutation by providing the
 *                      name of the query/mutation here. If not provided, every error is checked.
 */
export const isGraphqlErrorOfType = (
  apolloError: ApolloError,
  type: string,
  entryPoint?: string
): boolean => {
  return apolloError.graphQLErrors.some(({ extensions, path }) => {
    const customCodeExtension = extensions as GraphqlExtension;
    if (customCodeExtension.code !== 'CUSTOM_CODE') {
      return false;
    }

    if (entryPoint !== undefined && path?.[0] !== entryPoint) {
      return false;
    }

    return customCodeExtension.customCode === type;
  });
};

/**
 * Fetches all format errors from the graphQL error(s).
 *
 * @param apolloError - The complete apollo error object (must have a graphQLErrors property).
 * @param entryPoint  - Optionally only search errors for a specific query/mutation by providing the
 *                      name of the query/mutation here. If not provided, every error is checked.
 */
export const getGraphqlFormatErrors = (
  apolloError: ApolloError | undefined,
  entryPoint?: string
): Record<string, string[]> => {
  if (!apolloError?.networkError || !('result' in apolloError.networkError)) {
    return {};
  }

  const errors: GraphQLError[] = [
    ...apolloError.graphQLErrors,
    ...(apolloError.networkError.result?.errors ?? []),
  ];

  return errors.reduce((acc, { extensions, path, message }) => {
    const extension = extensions as GraphqlExtension;
    if (extension.code !== 'FORMAT') {
      return acc;
    }

    if (entryPoint !== undefined && path?.[0] !== entryPoint) {
      return acc;
    }

    const fieldMatch = message.match(/^Variable "\$([^"]+)" (.+)/);
    if (!fieldMatch) {
      return {
        ...acc,
        unknown: [...(acc.unknown ?? []), message],
      };
    }

    const field = fieldMatch[1];
    const fieldMessage = fieldMatch[2];

    return {
      ...acc,
      [field]: [...(acc[field] ?? []), fieldMessage],
    };
  }, {} as Record<string, string[]>);
};

/*
 * Global GraphQL Errors
 */

/**
 * Determines if the graphQL error(s) are of a specific global type.
 * This should not be exported, instead specific functions for each global error should be created.
 *
 * @param apolloError - The complete apollo error object (must have a graphQLErrors property).
 * @param globalType  - Matches the exception code defined by the API.
 * @param entryPoint  - Optionally only search errors for a specific query/mutation by providing the
 *                      name of the query/mutation here. If not provided, every error is checked.
 */
const isGraphqlErrorOfGlobalType = (
  apolloError: ApolloError,
  globalType: string,
  entryPoint?: string
): boolean => {
  return apolloError.graphQLErrors.some(({ extensions, path }) => {
    if (entryPoint !== undefined && path?.[0] !== entryPoint) {
      return false;
    }

    const globalCodeExtension = extensions as GraphqlExtension;
    return globalCodeExtension.code === globalType;
  });
};

/**
 * Whether the request has been blocked due to rate limiting.
 */
export const isRateLimited = (apolloError: ApolloError): boolean => {
  return isGraphqlErrorOfGlobalType(apolloError, 'RATE_LIMIT');
};

/**
 * Whether this specific graphQL error is an invalid authentication error.
 */
export const isInvalidAuthentication = (apolloError: ApolloError): boolean => {
  return isGraphqlErrorOfGlobalType(apolloError, 'INVALID_AUTHENTICATION');
};

/**
 * Whether this specific graphQL error is an geolocation error.
 */
export const isGeolocationBlocked = (apolloError: ApolloError): boolean => {
  return isGraphqlErrorOfGlobalType(apolloError, 'GEOLOCATION_BLOCKED');
};
