import React, { ReactNode, useState, createContext, useContext, useEffect } from 'react';
import { errorLink } from '../../ApolloProvider';

type GeolocationContextType = {
  isGeolocationBlocked: boolean;
};

export const GeolocationContext = createContext<GeolocationContextType>({
  isGeolocationBlocked: true,
});

export function GeolocationProvider({ children }: { children: ReactNode }): JSX.Element {
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    errorLink.setGeolocationBlockedHandler((): void => {
      setIsBlocked(true);
    });
  }, []);

  const providerValue: GeolocationContextType = {
    isGeolocationBlocked: isBlocked,
  };

  return (
    <GeolocationContext.Provider value={providerValue}>{children}</GeolocationContext.Provider>
  );
}

export const useGeolocationContext = (): GeolocationContextType => {
  return useContext(GeolocationContext);
};
