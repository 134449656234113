import React from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';

type TitleProps = {
  step?: number;
  title?: string;
  subtitle?: string;
  label?: string;
};
export const Title = ({ step, title, subtitle, label }: TitleProps) => (
  <div className="mv3">
    {step && <Text.label>step {step}</Text.label>}
    {title && <Text.h3 className="mb2">{title}</Text.h3>}
    {subtitle && <Text.body className="mb3">{subtitle}</Text.body>}
    {label && <Text.bodyGrey className="mb3">{label}</Text.bodyGrey>}
  </div>
);

type ControlProps = {
  title?: string;
  caption?: string;
  warning?: string;
};
export const Control: React.FC<ControlProps> = ({ children, title, warning, caption }) => (
  <div className="mv3">
    {title && <Text.bodyBold className={caption ? '' : 'mb3'}>{title}</Text.bodyBold>}
    {caption && <Text.caption className="mb2">{caption}</Text.caption>}
    {warning && (
      <Text.body kind="danger" className="mb3">
        {warning}
      </Text.body>
    )}
    {children}
  </div>
);

export const ExampleBox = styled.div`
  padding: 1rem;
  border: 1px solid ${colors.grey.border};
  border-radius: 4px;
  background-color: white;

  & ul {
    padding-left: 1.5rem;
  }
`;
