import React from 'react';
import { createIcon, Path } from './index';

export const IconAlertTriangle = createIcon({
  title: 'An exclamation mark inside of a triangle.',
  viewBox: '0 0 24 24',
  path: [
    <Path
      d="M21.7304 18L13.7304 3.99998C13.556 3.69218 13.303 3.43617 12.9973 3.25805C12.6917 3.07993 12.3442 2.98608 11.9904 2.98608C11.6366 2.98608 11.2892 3.07993 10.9835 3.25805C10.6778 3.43617 10.4249 3.69218 10.2504 3.99998L2.25042 18C2.0741 18.3053 1.98165 18.6519 1.98243 19.0045C1.98321 19.3571 2.0772 19.7032 2.25486 20.0078C2.43253 20.3124 2.68757 20.5646 2.99411 20.7388C3.30066 20.9131 3.64783 21.0032 4.00042 21H20.0004C20.3513 20.9996 20.6959 20.9069 20.9997 20.7313C21.3035 20.5556 21.5556 20.3031 21.7309 19.9991C21.9062 19.6951 21.9985 19.3504 21.9984 18.9995C21.9983 18.6486 21.9059 18.3039 21.7304 18Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M12 9V13"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <Path
      d="M12 17H12.01"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
