import { CareStatus, ContinuityOfCareModel, PaymentSource, NextSteps } from '../../graphQL';

export const paymentSourceCopy: Record<PaymentSource, string> = {
  [PaymentSource.SelfPay]: 'Self-Pay',
  [PaymentSource.Organization]: 'University Sponsored',
  [PaymentSource.Insurance]: 'Insurance',
  [PaymentSource.OonInsurance]: 'OON Insurance',
};

export const careStatusCopy: Record<CareStatus, string> = {
  [CareStatus.Active]: 'Active',
  [CareStatus.Cancelled]: 'Cancelled',
  [CareStatus.Deleted]: 'Deleted',
  [CareStatus.Discharged]: 'Discharged',
  [CareStatus.ScreenedOut]: 'Screened Out',
  [CareStatus.New]: 'New',
  [CareStatus.OnHold]: 'On Hold',
};

export const cocNextStepsCopy = (c: Pick<ContinuityOfCareModel, 'nextSteps' | 'pendingUser'>) => {
  const typeCopy: Record<NextSteps, string> = {
    [NextSteps.ActiveSelfPay]: 'Self-Pay',
    [NextSteps.ActiveSponsored]: 'Continue Sponsored',
    [NextSteps.OnHold]: 'On Hold',
    [NextSteps.Cancel]: 'Cancel',
  };
  if (c.nextSteps !== NextSteps.ActiveSelfPay) return typeCopy[c.nextSteps];
  return `${typeCopy[c.nextSteps]} (${c.pendingUser ? 'Pending' : 'Completed'})`;
};
