import { uniq } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Text } from '../../globalStyles';
import { SentEmailFragment } from '../../graphQL';
import { Icon } from '../Icons';
import { ListView } from '../ListView';

interface Props {
  emails: SentEmailFragment[];
  unsubscribedAt?: Date | null;
  onSetSubscribed?: (subscribed: boolean) => void;
}

export function EmailTable({ emails, onSetSubscribed, unsubscribedAt }: Props) {
  const subjectOptions = uniq(emails.map(i => i.subject))
    .sort((a, b) => a.localeCompare(b))
    .map(i => ({ label: i, id: i }));

  return (
    <>
      {window.location.hostname === 'localhost' && (
        <Text.body className="mb3">
          It looks like you’re developing this page locally. Note that the the
          delivered/opened/bounced columns won’t fill out unless you set up your API to receive
          events from AWS. Check out emailNotifications.ts in the API for instructions.
        </Text.body>
      )}
      {onSetSubscribed && (
        <ToggleSubscribed unsubscribedAt={unsubscribedAt} onSetSubscribed={onSetSubscribed} />
      )}
      <ListView
        data={emails}
        getKey={email => email.id}
        columns={[
          {
            key: 'sentAt',
            title: 'Sent At',
            render: email => moment(email.sentAt).format('M/D/YY h:mm a'),
            sort: (a, b) => moment(a.sentAt).diff(moment(b.sentAt)),
          },
          {
            key: 'subject',
            title: 'Subject',
            render: email => email.subject,
            sort: (a, b) => a.subject.localeCompare(b.subject),
          },
          {
            key: 'delivered',
            title: 'Delivered',
            render: email => <Boolean value={email.delivered} />,
          },
          {
            key: 'opened',
            title: 'Opened',
            render: email => <Boolean value={email.opened} />,
          },
          {
            key: 'bounced',
            title: 'Bounced',
            render: email => <Boolean value={email.bounced} />,
          },
        ]}
        filters={[
          {
            key: 'subject',
            test: (email, value) => email.subject === value,
            options: subjectOptions,
            placeholder: 'Subject',
          },
        ]}
        initialSortColumn="sentAt"
        initialSortDirection="desc"
      />
    </>
  );
}

const Boolean = ({ value }: { value: boolean }) => (
  <div className="flex items-center justify-center">
    <Icon icon={value ? 'iconsCheckSvg' : 'iconsRedXSvg'} size={20} />
  </div>
);

export function ToggleSubscribed({
  unsubscribedAt,
  onSetSubscribed,
}: {
  unsubscribedAt?: Date | null;
  onSetSubscribed: (value: boolean) => void;
}) {
  return (
    <div className="flex flex-row items-center mb3">
      <div className="mr3">
        Email subscription status:{' '}
        {unsubscribedAt
          ? `Unsubscribed as of ${moment(unsubscribedAt).format('M/D/YYYY')}.`
          : 'Subscribed.'}
      </div>
      <Text.linkButton onClick={() => onSetSubscribed(!!unsubscribedAt)} className="b">
        {unsubscribedAt ? 'Resubscribe' : 'Unsubscribe'}
      </Text.linkButton>
    </div>
  );
}
