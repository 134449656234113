import { IHeadingProps, Heading } from 'native-base';
import React from 'react';
import * as styles from '../../styles/components/header';

export type HeadingProps = IHeadingProps & {
  center?: boolean;
};

const factory =
  (textStyle: IHeadingProps): React.FC<HeadingProps> =>
  ({ center, children, ...props }) => {
    const textAlign = center === true ? 'center' : undefined;

    return (
      <Heading {...textStyle} textAlign={textAlign} {...props}>
        {children}
      </Heading>
    );
  };

export const h1Xl = factory(styles.heading1Xl);
export const h1 = factory(styles.heading1);
export const h2 = factory(styles.heading2);
export const h3 = factory(styles.heading3);
export const h4 = factory(styles.heading4);
export const h5 = factory(styles.heading5);
export const h6 = factory(styles.heading6);

export const overline = factory(styles.overline);
export const overlineLarge = factory(styles.overlineLarge);
