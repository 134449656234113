import React from 'react';
import styled from 'styled-components';
import { colors, Text } from '../globalStyles';
import { SmallInput } from './Form/Input';
import { Tooltip } from './Tooltip';

export type RowType = Array<Array<string>>;
export type EditableColumnsType = Array<Array<boolean>>;

export interface ISubcolumn {
  heading: string;
  tooltip?: () => React.ReactNode;
}

export interface IColumn {
  groupHeading?: string;
  headings: Array<ISubcolumn>;
}

export interface UtilizationRow {
  id: number;
  rowData: RowType;
}

export interface TableProps {
  heading?: string;
  rows: UtilizationRow[];
  columns: IColumn[];
  editable?: boolean;
  editableColumns?: EditableColumnsType;
  handleChange?: (
    newValue: string,
    isIntake: boolean,
    allocationId: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export interface GridProps {
  numColumns: number;
  verticalAlign?: 'start' | 'center';
}

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ numColumns }) =>
    `${Array.from(Array(numColumns))
      .map((_, i) => `minmax(0, ${i === 0 ? 1 : 1.5}fr)`)
      .join(' ')}`};
  grid-template-rows: 1fr;
`;

const Subgroup = styled(Grid)<GridProps>`
  display: grid;
  grid-template-columns: ${({ numColumns }) => `repeat(${numColumns}, minmax(50px, 1fr))`};
  grid-template-rows: 1fr;
  justify-items: ${({ verticalAlign }) => verticalAlign};
`;

const RowItem = styled.div``;

const InputRowItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SmallUtilizationInput = styled(SmallInput)<{ narrowInput: boolean }>`
  margin: 0.5rem 0;
  width: ${({ narrowInput }) => (narrowInput ? `4rem` : `5rem`)};
`;

const TableHeader = ({ heading, columns }: Pick<TableProps, 'heading' | 'columns'>) => {
  return (
    <>
      {columns.map((c: IColumn, index: number) => {
        return (
          <div key={`heading-${index}`}>
            <div
              style={{ height: 40 }}
              role="columnheader"
              className={`flex items-end ${index === 0 ? `justify-start` : `justify-center`}`}
            >
              {index === 0 && !c?.groupHeading && <Text.h3>{heading}</Text.h3>}
              {c.groupHeading && <Text.label className="mb2">{c.groupHeading}</Text.label>}
            </div>
            <Subgroup
              numColumns={c.headings.length}
              verticalAlign={index === 0 ? `start` : `center`}
            >
              {c.headings.map((s: ISubcolumn, subindex: number) => {
                return (
                  <RowItem key={`table-header-${subindex}`} className="flex gap-1 tc">
                    <Text.bodyCaption
                      style={{ fontWeight: 500, whiteSpace: 'nowrap' }}
                      role="columnheader"
                    >
                      {s.heading}
                    </Text.bodyCaption>
                    {s.tooltip && (
                      <Tooltip
                        content={s.tooltip?.()}
                        iconType="iconsInfoSvg"
                        size={12}
                        fontSize="f7"
                      />
                    )}
                  </RowItem>
                );
              })}
            </Subgroup>
          </div>
        );
      })}
    </>
  );
};

export const UtilizationTable = ({
  heading,
  rows,
  columns,
  editable,
  editableColumns,
  handleChange,
}: TableProps) => {
  // Check if all rows, columns, and editableColumnIndexes are same length
  const columnsSubheadings = columns.map(c => c.headings);
  const areRowLengthsEqualToColumnLengths = rows[0]?.rowData.every(
    (rowSubgroup, i) => rowSubgroup?.length === columnsSubheadings[i]?.length
  );

  const areColumnLengthsEqualToEditableIndexesLength =
    !editableColumns ||
    (editableColumns as EditableColumnsType).every(
      (editableColumn, i) => editableColumn?.length === columnsSubheadings[i]?.length
    );
  if (!areRowLengthsEqualToColumnLengths || !areColumnLengthsEqualToEditableIndexesLength) {
    return null;
  }

  return (
    <div role="table">
      <Grid numColumns={columns.length}>
        {/* If there's a group heading for the first column, render it outside of the main table */}
        {columns[0] && columns[0].groupHeading && heading && <Text.h3>{heading}</Text.h3>}

        <TableHeader heading={heading} columns={columns} />
      </Grid>

      {rows.map((allocationRow, rowIndex) => {
        return (
          <Grid
            key={`row-${rowIndex}`}
            numColumns={columns.length}
            style={{ borderTop: `1px solid ${colors.grey.lightBorder}` }}
            role="row"
            role-index={rowIndex}
          >
            {allocationRow.rowData.map((rowSubgroup, rowSubgroupIndex) => {
              return (
                <Subgroup
                  numColumns={rowSubgroup.length}
                  verticalAlign={rowSubgroupIndex === 0 ? `start` : `center`}
                  key={`row-subgroup-${rowIndex}-${rowSubgroupIndex}`}
                >
                  {rowSubgroup.map((rowItem, rowItemIndex) => {
                    if (
                      editable &&
                      editableColumns &&
                      !!editableColumns[rowSubgroupIndex][rowItemIndex]
                    ) {
                      const cellIsIntakeHours = isIntake(rowSubgroupIndex);
                      return (
                        handleChange && (
                          <InputRowItem
                            key={`row-item-editable-${rowSubgroupIndex}-${rowItemIndex}`}
                            role="cell"
                          >
                            <SmallUtilizationInput
                              name={`row-item-editable-${rowSubgroupIndex}-${rowItemIndex}`}
                              narrowInput={cellIsIntakeHours}
                              placeholder={rowItem}
                              type="number"
                              min="0"
                              step={cellIsIntakeHours ? '1' : '0.25'}
                              onFocus={e => {
                                e.currentTarget.value = e.currentTarget.value || rowItem;
                              }}
                              onBlur={e => {
                                handleChange(
                                  e.currentTarget.value,
                                  cellIsIntakeHours,
                                  allocationRow.id,
                                  e
                                );
                                e.currentTarget.placeholder = '--';
                              }}
                            />
                            <Text.label style={{ margin: 0 }} kind="danger" />
                          </InputRowItem>
                        )
                      );
                    }

                    return (
                      <RowItem key={`row-item-${rowSubgroupIndex}-${rowItemIndex}`} role="cell">
                        <Text.bodySmallGrey
                          style={{
                            padding:
                              rowItemIndex === 0 && rowSubgroupIndex === 0
                                ? '10px 20px 10px 0'
                                : '10px 20px',
                            border: `1px solid transparent`,
                            margin: editableColumns ? '0.5rem 0' : '0',
                          }}
                        >
                          {rowItem ?? '--'}
                        </Text.bodySmallGrey>
                      </RowItem>
                    );
                  })}
                </Subgroup>
              );
            })}
          </Grid>
        );
      })}
    </div>
  );
};

// With our current data structure and how we prepare rows, we know that if the supgroupIndex is 2, it's an intake value
function isIntake(rowSubgroupIndex: number): boolean {
  return rowSubgroupIndex === 2;
}
