import React, { useState } from 'react';
import { Styles, Text } from '../../styles';
import { FinalButton } from '../../../../Components/FinalButton';
import { OverrideDOBModal } from './OverrideDOBModal';
import { ApproveMinorModal } from './ApproveMinorModal';
import { UserType } from '../../types';
import styled from 'styled-components';
import { OzOnly } from '../../../../Components/Permissions';
import { MinorConsentStatus, useMinorConsentStatusForUserQuery } from '../../../../graphQL';

type MinorConsentNeededProps = {
  user: UserType;
};

export const MinorConsentNeeded = ({ user }: MinorConsentNeededProps) => {
  const { data: minorConsentData, refetch: refetchMinorConsent } =
    useMinorConsentStatusForUserQuery({
      notifyOnNetworkStatusChange: true,
      variables: { userId: user.id },
    });

  const [openDOBModal, setOpenDOBModal] = useState(false);
  const [openApproveMinorModal, setOpenApproveMinorModal] = useState(false);

  const consentIsPending =
    minorConsentData?.minorConsentStatusForUser.status === MinorConsentStatus.Incomplete;

  if (!consentIsPending) {
    return <></>;
  }

  return (
    <Styles.widget>
      <ContentDiv>
        <Text.h3 className="mb2">Minor - Consent Needed</Text.h3>

        <StatusContainer className="mb2">
          <Text.bodyBold>Current Status:</Text.bodyBold>

          <Text.bodyBold kind="orange">Incomplete</Text.bodyBold>
        </StatusContainer>

        <BottomSection>
          <BottomTextContainer>
            <Text.bodySmall>
              Student is currently unable to continue sign up for Mantra care until minor consent
              forms have been completed. Consent process is currently done through email with Mantra
              Care Navigation (hi@mantrahealth.com).
            </Text.bodySmall>
          </BottomTextContainer>

          <OzOnly>
            <ButtonContainer>
              <FinalButton
                iconLeft={{ icon: 'iconsBlackThumbUpSvg', size: 20, alt: 'Thumbs Up' }}
                kind="outline_black"
                onClick={() => setOpenApproveMinorModal(true)}
                style={{ width: '210px' }}
              >
                Mark as Approved
              </FinalButton>

              <Text.linkButton onClick={() => setOpenDOBModal(true)}>
                Override Incorrect DOB (non-minor)
              </Text.linkButton>
            </ButtonContainer>
          </OzOnly>
        </BottomSection>
      </ContentDiv>

      {openDOBModal && (
        <OverrideDOBModal
          onClose={() => setOpenDOBModal(false)}
          user={user}
          refetchMinorConsent={refetchMinorConsent}
        />
      )}

      {openApproveMinorModal && (
        <ApproveMinorModal
          onClose={() => setOpenApproveMinorModal(false)}
          userId={user.id}
          refetchMinorConsent={refetchMinorConsent}
        />
      )}
    </Styles.widget>
  );
};

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

const BottomTextContainer = styled.div`
  max-width: 50%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 50%;
  gap: 15px;
`;
