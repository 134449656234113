import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from '../../Hooks';
import { useEvents } from '../Events/EventsProvider';
import { DateRangePickerProps, SelectProps } from '../Form';
import { NestedSelectProps } from '../Form/Select/MantraNestedSelect';

export const parseFilterStateFromQuery = (
  query: any,
  filters: (Filter | NestedFilter)[]
): Record<string, any> => {
  return filters.reduce(
    (acc, { key, defaultOptionId }) => ({
      ...acc,
      [key]: query[key] ? query[key] : defaultOptionId,
    }),
    {}
  );
};

export interface ListViewConfig<T> {
  columns: Column<T>[];
  search?: (item: T, value: string) => boolean;
  searchable?: boolean;
  searchPlaceholder?: string;
  getKey: (item: T) => number | string;
  link?: (item: T) => string;
  initialSortColumn?: string | null;
  initialSortDirection?: SortDirection;
  filters?: (Filter | NestedFilter)[];
  dateFilters?: DateFilter<T>[];
  pageSize?: number;
  paginate?: boolean;
  hideTopControls?: boolean;
  empty?: React.ReactNode;
  onClick?: (item: T) => void;
  persist?: boolean;
  fontSize?: 'f4' | 'f5' | 'f6';
  analyticsName?: string;
  headRow?: JSX.Element;
  additionalControls?: React.ReactNode;
}

export interface Column<T> {
  key: string;
  title: string;
  render: (data: T) => React.ReactNode;
  sortable?: boolean;
  sort?: (a: T, b: T) => number;
  className?: string;
  width?: string;
  hidden?: boolean;
}

export type DateFilter<T> = {
  key: string;
  getTime: (data: T) => Date | null | undefined;
  hidden?: boolean;
} & Pick<DateRangePickerProps, 'placeholder' | 'clearable' | 'iconLeft'>;

export type Filter = {
  key: string;
  defaultOptionId?: string;
  hidden?: boolean;
  width?: string;
} & Pick<SelectProps<any>, 'options' | 'placeholder' | 'clearable' | 'renderOption' | 'iconLeft'>;

export type NestedFilter = {
  key: `nested-${string}`;
  defaultOptionId?: string;
  hidden?: boolean;
  width?: string;
} & Pick<NestedSelectProps, 'options' | 'placeholder' | 'renderOption'>;

export const isNestedFilter = (v: Filter | NestedFilter): v is NestedFilter => {
  return v.key.startsWith('nested-');
};

type SortDirection = 'asc' | 'desc';

export interface ListViewUiProps<T> {
  permissibleColumns: Column<T>[];
  searchValue: string;
  debouncedSearchValue: string;
  setSearchValue: (value: string) => void;
  filterValues: Record<string, any>;
  setFilterValue: (key: string, value: any) => void;
  currentPageNum: number;
  setPage: (page: number) => void;
  sortColumn: string | null;
  sortDirection: SortDirection;
  setSort: (column: string) => void;
  limit?: number;
}

export function useListViewUi<T>({
  initialSortDirection,
  initialSortColumn,
  filters = [],
  columns,
  persist,
  analyticsName,
  pageSize,
}: ListViewConfig<T>): ListViewUiProps<T> {
  const metrics = useEvents();
  const history = useHistory();
  const query = qs.parse(history.location.search) as any;
  const [searchValue, setSearchValue] = useState<string>(query.searchValue || '');
  const [sortDir, setSortDir] = useState<SortDirection>(query.sortDir || initialSortDirection);
  const [sortCol, setSortCol] = useState<string | null>(query.sortCol || initialSortColumn);
  const [filterValues, setFilterValues] = useState(parseFilterStateFromQuery(query, filters));
  const [currentPageNum, setPage] = useState(1);
  const debouncedSearchValue = useDebounce(searchValue, 250);

  const permissibleColumns = columns.filter(({ hidden }) => !hidden);

  useEffect(() => {
    if (!persist) return;
    const toStore = Object.entries(filterValues).reduce(
      (acc, [key, value]) => (value?.id ? { ...acc, [key]: value.id } : acc),
      { sortDir, sortCol, searchValue: debouncedSearchValue }
    );
    history.replace({ search: qs.stringify(toStore) });
    // eslint-disable-next-line
  }, [sortDir, sortCol, debouncedSearchValue, filterValues]);

  const setSort = (key: string) => {
    const col = permissibleColumns.find(i => i.key === key);
    if (!col || !(col.sort || col.sortable)) return;
    let nextSortDir: SortDirection = 'asc';
    if (sortCol === key) {
      nextSortDir = sortDir === 'asc' ? 'desc' : 'asc';
      setSortDir(nextSortDir);
    } else {
      setSortCol(key);
      setSortDir(nextSortDir);
    }

    if (analyticsName) {
      metrics.track(`list.${analyticsName}.sort`, {
        key,
        direction: nextSortDir,
      });
    }
  };

  const setFilterValue = (key: string, value: any) => {
    setFilterValues(f => ({ ...f, [key]: value }));
    if (analyticsName) {
      const action = value ? 'added' : 'removed';
      const payload: any = { key };
      if (value) {
        payload.selectionId = value.id;
        payload.selectionLabel = value.label;
      }
      metrics.track(`list.${analyticsName}.filter.${action}`, payload);
    }
  };

  return {
    filterValues,
    setFilterValue,
    searchValue,
    debouncedSearchValue,
    setSearchValue,
    currentPageNum,
    setPage,
    sortColumn: sortCol,
    sortDirection: sortDir,
    setSort,
    permissibleColumns,
    limit: pageSize,
  };
}
