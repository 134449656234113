import { Input } from 'baseui/input';
import { Modal } from 'baseui/modal';
import { Notification } from 'baseui/notification';
import { StatefulSelect } from 'baseui/select';
import { camelCase, compact, get, mapValues } from 'lodash';
import omit from 'lodash/omit';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEvents } from '../../Components/Events/EventsProvider';
import { FinalButton } from '../../Components/FinalButton';
import { ImageUploadSelector } from '../../Components/ImageUploadSelector';
import { OzOnly } from '../../Components/Permissions';
import { Modal as MantraModal, Text } from '../../globalStyles';
import {
  CurrentProviderQuery,
  UpdateStaffRole,
  useAdminCompleteProviderPhotoUploadMutation,
  useAdminUpdateProviderMutation,
} from '../../graphQL';
import { useImageUploader } from '../../Hooks';
import * as Styles from '../Provider/Styles';

interface Props {
  provider: CurrentProviderQuery['currentProvider'];
  onClose: () => void;
  refetch: () => void;
  // onSettingsPage: boolean;
}

interface FormFields {
  role: string;
  firstName: string;
  lastName: string;
  postNominalTitles: string;
  pronouns: string;
  npi?: string;
  classification: string;
  email: string;
  phone: string;
  photo?: File;
}

const pronounOptions = ['He/Him/His', 'She/Her/Hers', 'They/Them/Theirs'].map(i => ({
  label: i,
  id: i,
}));

export function SettingsPageEditProfileModal({ provider, refetch, onClose }: Props) {
  const { track } = useEvents();
  const { register, handleSubmit, setValue } = useForm<FormFields>({
    defaultValues: provider as FormFields,
  });
  useEffect(() => {
    register({ name: 'role' });
    register({ name: 'pronouns' });
    register({ name: 'photo' });
  });
  const [updateProvider, updateProviderMutation] = useAdminUpdateProviderMutation();
  const [updatePortrait] = useAdminCompleteProviderPhotoUploadMutation();
  const formRef = useRef<HTMLFormElement>(null);
  const [loading, setLoading] = useState(false);

  const { upload, accepts, error, onDropRejected } = useImageUploader({
    completeFunction: (key: string) =>
      updatePortrait({ variables: { providerId: provider.id, key } }),
    callback: refetch,
  });

  const onSubmit = handleSubmit(async (values: FormFields) => {
    try {
      setLoading(true);
      await updateProvider({
        variables: {
          input: {
            ...omit(values, 'photo', 'role'),
            id: provider.id,
            role:
              values.role !== provider.role
                ? (camelCase(values.role) as UpdateStaffRole)
                : undefined,
          },
        },
      });
      track('provider.profile.edited', {
        providerId: provider.id,
        changedFields: mapValues(values, (value, key) => !!value && get(provider, key) !== value),
      });
      if (values.photo) {
        await upload(values.photo);
      } else if (!values.photo && provider.portrait?.url) {
        await updatePortrait({ variables: { providerId: provider.id, key: null } });
      }
      refetch();
      onClose();
    } catch (err) {
      if (formRef.current) {
        formRef.current.scrollIntoView();
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      <MantraModal.body>
        <Text.h1>Edit Profile</Text.h1>
        <Styles.EditProfileForm onSubmit={onSubmit} ref={formRef}>
          {updateProviderMutation.error && (
            <Notification kind="negative">
              {updateProviderMutation.error.message.replace('GraphQL Error:', '')}
            </Notification>
          )}
          <div>
            <Styles.FieldLabel>Phone Number</Styles.FieldLabel>
            <Input inputRef={register} name="phone" type="tel" />
          </div>
          <div>
            <Styles.FieldLabel>Pronouns</Styles.FieldLabel>
            <StatefulSelect
              options={pronounOptions}
              initialState={{
                value: compact([pronounOptions.find(i => i.id === provider.pronouns)]),
              }}
              onChange={({ option }) => setValue('pronouns', option!.id as string)}
              clearable={false}
            />
          </div>
          <Styles.Divider />
          <div>
            <Styles.FieldLabel>Job Title</Styles.FieldLabel>
            <Input inputRef={register} name="classification" />
          </div>
          <OzOnly>
            <div>
              <Styles.FieldLabel>NPI</Styles.FieldLabel>
              <Input inputRef={register} name="npi" />
            </div>
          </OzOnly>
          <Styles.Divider />
          <Text.h2>Your Photo</Text.h2>
          <ImageUploadSelector
            initialUrl={provider.portrait?.url}
            onSelect={file => setValue('photo', file ?? undefined)}
            accepts={accepts}
            errorMessage={error}
            onDropRejected={onDropRejected}
          />
          <FinalButton type="submit" kind="primary" className="w-100" loading={loading}>
            Save
          </FinalButton>
        </Styles.EditProfileForm>
      </MantraModal.body>
    </Modal>
  );
}
