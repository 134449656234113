import { ModalBody, ModalHeader } from 'baseui/modal';
import React, { useState } from 'react';
import { Accordion } from '../../../Components/Accordion';
import { modalFactory } from '../../../Components/Modal';
import { Text } from '../../../globalStyles';
import { borderRadius } from '../../../utils';

const Modal = modalFactory({ style: { ...borderRadius('4px'), maxWidth: '500px' } });

export const ConsentFAQ = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Text.linkButtonSmall onClick={() => setModalOpen(!modalOpen)}>
        Learn more
      </Text.linkButtonSmall>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalHeader>
          <Text.h3 className="mh3">Patient Record Access FAQs</Text.h3>
        </ModalHeader>
        <ModalBody>
          <div className="mh3">
            <Accordion
              items={faq.map(f => ({
                title: f.title,
                content: typeof f.content === 'string' ? <p>{f.content}</p> : f.content,
              }))}
            />
          </div>
          <div className="mh3 ph1 mt3 mb5 pt3 bt">
            <p className="black f5">
              If you have any additional questions about patient record access, please email us at
              <a className="b link" href="mailto:hi@mantrahealth.com">
                {' hi@mantrahealth.com. '}
              </a>
              We&apos;re here to help.
            </p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const faq: { title: string; content: string | JSX.Element }[] = [
  {
    title: `What is "university sponsored" care?`,
    content: `University sponsored care is all care received from the Wellround Provider Group which is paid for, in whole or in part, by a patient's college or university.`,
  },
  {
    title: `What is "non-sponsored" care?`,
    content: `Non-sponsored care is all care received from the Wellround Provider Group which is NOT paid for by a patient's college or university. Non-sponsored care includes visits for which the patient paid self-pay prices or billed their insurance.`,
  },
  {
    title: `What is 'active consent'?`,
    content: (
      <>
        <p className="mb3">
          Active consent is additional permission given by a patient (as requested by a campus
          collaborator) to:
        </p>
        <ul className="mb3">
          <li>share all past non-sponsored records</li>
          <li>
            share future non-sponsored records for the next 180 days, unless revoked by patient
          </li>
          <li>- allow campus collaborators to collaborate with their provider</li>
        </ul>
        <p>
          If this patient has received university-sponsored care, the records from the sponsored
          care period(s) are always available and do not need additional patient consent to be
          viewed.
        </p>
      </>
    ),
  },
  {
    title: `What is full record access?`,
    content: (
      <>
        <p className="mb3">
          If your clinic currently has active consent to view the patient&apos;s full record, you
          have full record access for a patient, meaning you can:
        </p>
        <ul>
          <li>View all sponsored care data &amp; records</li>
          <li>
            View future non-sponsored records for the next 180 days, unless revoked by patient
          </li>
          <li>
            Collaborate with patient&apos;s Mantra-affiliated provider(s) via
            <span className="b"> Care Team Messaging</span>
          </li>
        </ul>
      </>
    ),
  },
  {
    title: `How can I get full access to a patient's record?`,
    content: (
      <>
        <p className="mb3">
          You must request full access to the patient&apos;s record in order for the patient to
          receive a request for consent.
        </p>
        <ol className="mb3">
          <li>
            To request access to the patient&apos;s full record, click &quot;Request full
            record&quot; in the sidebar.
          </li>
          <li>
            The patient will receive an email of the request. They will need to grant access through
            their patient portal.
          </li>
          <li>
            Once full access is granted, you&apos;ll receive an email that a patient has opened up
            access to their record
          </li>
        </ol>
        <p className="mb3">
          <span className="b">Note: </span>
          When a student grants access, this will not only share their past records, but ongoing
          access to their full record (and provider collaboration) for 180 days, unless:
        </p>
        <ul className="mb3">
          <li>ongoing access is revoked by the patient, or</li>
          <li>your access to the MCP is revoked by your organization</li>
        </ul>
        <p>
          After the 180 day period expires, the previously shared records will be visible to the
          clinic for the remainder of your contract period with Mantra Health.
        </p>
      </>
    ),
  },
  {
    title: `What is a limited patient record?`,
    content: (
      <>
        <p className="mb3">
          A patient record may be limited if the clinic does not currently have active consent to
          view the patient&apos;s full record.
        </p>
        <p className="mb3">
          This means that there may (or may not) be additional records from non-sponsored visits,
          e.g. non-sponsored visits during a campus break, that exist with Mantra Health, but
          aren&apos;t currently visible. To request access to the patient&apos;s full record, click
          &lsquo;Request full record&rsquo; in the sidebar.
        </p>
        <p className="mb3">
          If you have limited access to a patient&apos;s record, it means you can:
        </p>
        <ul className="mb3">
          <li>View all university sponsored care data &amp; records</li>
          <li>View any data from non-sponsored visits that had been shared in the past</li>
        </ul>
        <p className="mb3">
          When a patient is currently in university sponsored care, you are able to collaborate with
          this their provider through the Care Team Messaging feature in the patient&apos;s profile.
          Care Team Messaging is available if you have any questions or need to share important
          treatment information with the patient&apos;s provider.
        </p>
        <p className="mb3">
          <span className="b">Note: </span>If the patient is NOT currently in sponsored care, the
          campus collaboration team will not have collaboration access for this patient.
          <span className="b">
            {' '}
            If you&apos;d like to collaborate with this patient&apos;s provider, request full record
            access.
          </span>
        </p>
      </>
    ),
  },
  {
    title: `What happens if consent is revoked or expires?`,
    content: (
      <>
        <p className="mb3">
          After the 180 day period expires, or if a student revokes access before the 180 days has
          passed, all previously shared records up until the date of expiration (or revocation) will
          be visible to the clinic for the remainder of your contract period with Mantra Health.
        </p>
        <p>
          Any future non-sponsored care received from Wellround Provider Group would therefore not
          be shared (unless consent was requested &apos; given again). If consent is no longer
          active, you may make an additional request for full access to the patient&apos;s record by
          clicking &lsquo;Request full access&rsquo; in the sidebar.
        </p>
      </>
    ),
  },
];
