import { StatefulCheckbox } from 'baseui/checkbox';
import { StatefulDatePicker as BaseWebDatePicker } from 'baseui/datepicker';
import { Input as BInput } from 'baseui/input';
import { StatefulSelect as BSelect } from 'baseui/select';
import { sample, times } from 'lodash';
import moment from 'moment';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FinalButton } from '../../Components/FinalButton';
import {
  CheckboxRHF,
  DatePickerRHF,
  DateRangePickerRHF,
  InputRHF,
  MultiSelectRHF,
  SelectRHF,
} from '../../Components/Form';
import { Textarea } from '../../Components/Form/Input';
import { Grid } from '../../Components/Grid';
import { overrides } from '../../globalStyles';

const firstNames = ['johnson johnson', 'will', 'ben', 'mark', 'danny', 'joey'];
const lastNames = ['johnson johnsonjohnson', 'smith', 'stiller', 'wahlberg', 'deVito', 'chestnut'];
const options = times(24, id => ({
  id,
  label: `${sample(firstNames)} ${sample(lastNames)} (${id})`,
}));

export const DebugForm = () => {
  const formContext = useForm();

  // eslint-disable-next-line
  const submit = formContext.handleSubmit(async values => console.log(values));

  const sections: Record<string, { mantra: JSX.Element; baseui: JSX.Element }> = {
    input: {
      mantra: (
        <div>
          <InputRHF placeholder="Mantra Input" name="input.normal" />
          <InputRHF placeholder="Disabled input" disabled name="input.disabled" />
        </div>
      ),
      baseui: (
        <div>
          <BInput />
          <BInput error />
          <BInput placeholder="fine wine" />
        </div>
      ),
    },

    select: {
      mantra: (
        <div>
          <SelectRHF placeholder="normal select" options={options} name="select.normal" />
          <SelectRHF
            placeholder="clearable select"
            options={options}
            name="select.clearable"
            clearable
          />
          <SelectRHF
            placeholder="Icon select"
            options={options}
            iconLeft="iconsUserSvg"
            name="select.icon"
            clearable
          />
          <SelectRHF
            placeholder="custom render select"
            options={options}
            clearable
            renderOption={({ option }) => <div className="pink b mv3">{option.label}</div>}
            name="select.custom"
          />

          <SelectRHF
            placeholder="disabled select"
            options={options}
            disabled
            name="select.disabled"
          />
        </div>
      ),

      baseui: (
        <div>
          <BSelect options={options} />
          <BSelect options={options} clearable maxDropdownHeight="12rem" />
          <BSelect options={options} error value={[{ id: 1 }]} />
          <BSelect options={options} error maxDropdownHeight="12rem" />
        </div>
      ),
    },
    multiselect: {
      mantra: (
        <div>
          <MultiSelectRHF placeholder="normal" options={options} name="multi.normal" />
          <MultiSelectRHF
            placeholder="disabled multi select"
            disabled
            options={options}
            name="multi.clearable"
          />
        </div>
      ),
      baseui: (
        <div>
          <BSelect multi options={options} />
        </div>
      ),
    },
    checkbox: {
      mantra: (
        <div>
          <CheckboxRHF name="checkbox.mantra">Checkbox</CheckboxRHF>
          <CheckboxRHF rules={{ required: true }} name="checkbox.error">
            Required checkbox
          </CheckboxRHF>
        </div>
      ),
      baseui: (
        <div>
          <div className="flex">
            <StatefulCheckbox overrides={overrides.checkbox} />
            <div>BaseUI</div>
          </div>
        </div>
      ),
    },
    textarea: {
      mantra: (
        <div>
          <p className="gray">TODO: {'<TextareaRHF />'}</p>
          <Textarea placeholder="placeholder" />
        </div>
      ),
      baseui: <div>-</div>,
    },

    datepicker: {
      mantra: (
        <div>
          <DatePickerRHF placeholder="Select Date" name="date.mantra1" />
          <DatePickerRHF placeholder="Select Date clearable" clearable name="date.mantra2" />

          <DatePickerRHF
            placeholder="Select Date w/ Icon"
            iconLeft="iconsInfoSvg"
            clearable
            name="date.mantra3"
          />
          <DatePickerRHF placeholder="Min Date" min={moment()} clearable name="date.mantra4" />

          <DatePickerRHF placeholder="Disabled" clearable disabled name="date.mantra5" />
        </div>
      ),
      baseui: (
        <div>
          <BaseWebDatePicker />
        </div>
      ),
    },
    daterange: {
      mantra: (
        <div>
          <DateRangePickerRHF placeholder="Select Dates" name="daterange.mantra1" />
          <DateRangePickerRHF
            placeholder="Select Dates clearable"
            clearable
            name="daterange.mantra2"
          />
          <DateRangePickerRHF
            placeholder="Select Dates Icon"
            clearable
            iconLeft="iconsGrayTimerSvg"
            name="daterange.mantra3"
          />
        </div>
      ),
      baseui: (
        <div>
          <BaseWebDatePicker range />
        </div>
      ),
    },
  };

  return (
    <FormProvider {...formContext}>
      <div className="flex flex-wrap gap-2 mb4">
        {Object.keys(sections).map(k => (
          <a href={`#${k}`} key={k} className="link">
            <FinalButton kind="outline_black" size="tiny">
              {k}
            </FinalButton>
          </a>
        ))}
      </div>
      <form onSubmit={submit} className="mb6">
        <Grid gap="1.5rem" gridTemplateColumns="1fr 1fr">
          {Object.entries(sections).map(([title, { mantra, baseui }]) => {
            return (
              <React.Fragment key={title}>
                <div id={title}>
                  <h3 className="f3 ttc mb4">Mantra {title}</h3>
                  {mantra}
                </div>
                <div>
                  <h3 className="f3 ttc mb4">BaseUI {title}</h3>
                  {baseui}
                </div>
              </React.Fragment>
            );
          })}
        </Grid>
        <FinalButton className="mb3" type="submit">
          submit
        </FinalButton>
      </form>
    </FormProvider>
  );
};
