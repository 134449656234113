import {
  ArcElement,
  Chart,
  ChartConfiguration,
  DoughnutController,
  Tooltip as TooltipElement,
} from 'chart.js';
import { sumBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { Dot } from '../../../Components/Flair';
import { useCurrentProvider } from '../../../Components/Permissions';
import { Tooltip } from '../../../Components/Tooltip';
import { Flag } from '../../../featureFlags';
import { colors, Text } from '../../../globalStyles';
import { HELVETICA } from '../../../globalStyles/text';
import { titleCase } from '../../../utils';
import { WidgetContainer } from '../Styles';
import { WidgetProps } from './interface';

Chart.register(DoughnutController, ArcElement, TooltipElement);

const colorMap: Record<string, string> = {
  complete: colors.primary,
  'no show': '#f7807b',
  cancelled: '#CFD8DC',
};

export const VisitAttendance = ({ data }: WidgetProps) => {
  const ref = useRef<HTMLCanvasElement>(null);

  const { featureFlags } = useCurrentProvider();
  const useDemoNumbers = featureFlags.includes(Flag.DemoReportingNumbers);
  if (useDemoNumbers) {
    // Hardcoding attendance numbers to look better in demo.
    data.visitAttendance.attendance = [{ key: 'complete', value: 4 }];
  }

  useEffect(() => {
    if (!data || !ref.current) return;
    const total = sumBy(data.visitAttendance.attendance, 'value');
    const chart = new Chart(ref.current, getConfig({ data, total, useDemoNumbers }));
    return () => {
      chart.destroy();
    };
  }, [data]);

  if (sumBy(data.visitAttendance.attendance, 'value') === 0) return null;
  const { carePeriod } = data.visitAttendance;
  return (
    <WidgetContainer>
      <div className="mb4">
        <div className="flex justify-between items-center">
          <Text.h2 className="ma0">Visit Attendance</Text.h2>
          <Tooltip content="The most recent care period’s overall attendance percentages. A ‘cancellation’ represents an appointment that was rescheduled or cancelled less than 24 hours prior to the scheduled appointment’s start time." />
        </div>
        {carePeriod && (
          <Text.bodyGrey>Since {moment(carePeriod.startDate).format('l')}</Text.bodyGrey>
        )}
      </div>
      <div className="flex justify-center">
        <div className="relative flex-auto" style={{ maxWidth: 240 }}>
          <canvas ref={ref} />
        </div>
      </div>
      <div className="mt3 flex justify-center">
        {[
          { title: 'Attended', color: colorMap.complete },
          { title: 'Cancellation', color: colorMap.cancelled },
          { title: 'No Show', color: colorMap['no show'] },
        ].map(i => (
          <div key={i.title} className="mv2 mh3">
            <Dot color={i.color}>
              <span className="f6 nowrap">{i.title}</span>
            </Dot>
          </div>
        ))}
      </div>
    </WidgetContainer>
  );
};

const getConfig = ({
  data,
  total,
  useDemoNumbers,
}: {
  data: WidgetProps['data'];
  total: number;
  useDemoNumbers: boolean;
}): ChartConfiguration<'doughnut'> => {
  const { attendance } = data.visitAttendance;
  return {
    type: 'doughnut',
    data: {
      labels: attendance.map(i => i.key),
      datasets: [
        {
          data: attendance.map(i => i.value),
          backgroundColor: attendance.map(i => colorMap[i.key]),
        },
      ],
    },
    options: {
      // @ts-ignore
      animation: {
        animateRotate: true,
      },
      cutout: '80%',
      aspectRatio: 1,
      responsive: true,
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: true,
          displayColors: false,
          cornerRadius: 4,
          backgroundColor: '#101426',
          padding: { top: 12, bottom: 12, left: 24, right: 24 },
          bodyFont: { size: 14, family: HELVETICA },
          titleFont: { size: 16, family: HELVETICA, weight: 'bold' },
          titleMarginBottom: 2,
          callbacks: {
            title: ([{ dataIndex }]) => {
              const d = attendance[dataIndex];
              if (!d) return [];
              if (useDemoNumbers) {
                return `95% ${titleCase(d.key)}`;
              }
              return `${Math.round((d.value / total) * 100)}% ${titleCase(d.key)}`;
            },
            label: ({ dataIndex }) => {
              const d = attendance[dataIndex];
              if (!d) return [];
              if (useDemoNumbers) {
                return `Total: 120`;
              }
              return `Total: ${d.value}`;
            },
          },
        },
      },
    },
    plugins: [
      {
        id: 'attendanceDisplay',
        beforeDatasetDraw: c => {
          const ctx = c.canvas?.getContext('2d');
          if (!ctx || total === 0) return;
          const completed = attendance.find(i => i.key === 'complete')?.value ?? 0;

          let display = `${Math.round((completed / total) * 100)}%`;
          if (useDemoNumbers) {
            display = '95%';
          }

          const height = c.height!; // assume width and height are equal
          const percent = height / 100; // used for nudging stuff around

          ctx.textAlign = 'center';
          ctx.fillStyle = ' #263238';
          ctx.font = `bold ${height / 4.5}px 'Helvetica', sans-serif`;
          ctx.fillText(display, height / 2, height / 2 - 3 * percent);
          ctx.font = `bold ${height / 16}px 'Helvetica', sans-serif`;
          ctx.fillText('OVERALL', height / 2, height / 2 + 14 * percent);
          ctx.fillText('ATTENDANCE', height / 2, height / 2 + 22 * percent);
        },
      },
    ],
  };
};
