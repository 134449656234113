import { useState } from 'react';

export const usePossiblyExpanded = <T extends string | Array<K>, K = {}>(
  full: T,
  maxSize: number
) => {
  const [expanded, setExpanded] = useState(false);
  const shortened = full.slice(0, maxSize);
  const hasMore = shortened.length < full.length;
  const current = (expanded ? full : shortened) as T;
  return { current, expanded, hasMore, setExpanded };
};
