import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FinalButton } from '../../../../Components/FinalButton';
import { modalFactory } from '../../../../Components/Modal';
import { Text, colorMap } from '../../../../globalStyles';
import { useAdminUpdateAllocationsForDateMutation } from '../../../../graphQL';
import { getFullName } from '../../../../modelUtils/users';
import { TimeZone, cx } from '../../../../utils';
import { FriendlyAllocation, getAllocationsForBlock } from '../../util';
import * as Styles from '../Styles';
import { Allocation } from './Allocation';
import { ConfirmAvailabilityOverrideModalV2 } from './ConfirmAvailabilityOverrideModal';
import { LocalAllocation, isCompletedAllocation } from './Hooks/types';
import { useConflictWarnings } from './Hooks/useConflictWarnings';
import { useEditAvailability } from './Hooks/useEditAvailability';
import { HoursCounters } from './HoursCounter';
import { RepeatingScheduleConfirmModal } from './RepeatingScheduleConfirmModal';
import { RepeatsDropdown } from './RepeatsDropdown';
import {
  formatAllocation,
  getSingleDayIntersections,
  hasSingleDayIntersection,
} from './availabilityUtils';
import { AvailabilityProvider, ScheduleType } from './types';
import { isMantraAdmin, useCurrentProvider } from '../../../../Components/Permissions';

const Modal = modalFactory({
  style: { width: '940px', background: colorMap.background[0] },
});

type UpdateFn = ({ scheduleType }: { scheduleType: ScheduleType }) => void;

type ModalProps = {
  onCancel: () => void;
  onSuccess: () => void;
  provider: AvailabilityProvider;
  date: Moment;
  timezone: TimeZone;
  allocations: FriendlyAllocation[];
};

export const SetAvailabilityModal = ({
  onCancel,
  date,
  timezone,
  allocations,
  provider,
  onSuccess,
}: ModalProps) => {
  // Gets all current allocations
  // move to start on the date we're editing (for recurring)
  const blockAllocations = useMemo(() => {
    return getAllocationsForBlock(date, allocations).map(alloc => ({
      // convert blockDate to the relevant timezone
      ...alloc,
      startTime: moment.tz(
        {
          date: date.date(),
          month: date.month(),
          year: date.year(),
          hour: alloc.startTime.hour(),
          minute: alloc.startTime.minute(),
        },
        timezone
      ),
      endTime: moment.tz(
        {
          date: date.date(),
          month: date.month(),
          year: date.year(),
          hour: alloc.endTime.hour(),
          minute: alloc.endTime.minute(),
        },
        timezone
      ),
      repeatsUntil: alloc.repeatsUntil || null,
    }));
  }, [date, allocations, timezone]);

  const { currentProvider } = useCurrentProvider();
  const isAdmin = isMantraAdmin(currentProvider);

  const [updateAllocations, { loading }] = useAdminUpdateAllocationsForDateMutation();
  const [scheduleType, setScheduleType] = useState<ScheduleType>('single');
  const [scheduleTypeFromRepeatModal, setScheduleTypeFromRepeatModal] =
    useState<ScheduleType>('single');
  const [repeatsUntilDate, setRepeatsUntilDate] = useState<Date | null>(
    blockAllocations[0]?.repeatsUntil?.toDate() || null
  );
  const [updateScheduleForText, setUpdateScheduleForText] = useState<string>('');

  // confirmation modals
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRepeatingScheduleConfirmModal, setShowRepeatingScheduleConfirmModal] = useState(
    // has weekly allocations
    blockAllocations.some(i => i.weekly)
  );

  // `localAllocations` refer to the allocations currently being updated but haven't been saved yet
  const [dispatch, { localAllocations, errors, hasErrors, canSave: editAvailabilityCanSave }] =
    useEditAvailability({
      blockAllocations,
      timezone,
      ffs: !isAdmin,
    });

  const { firstNConflicts, remainingConflictCount, hasConflicts } = useConflictWarnings({
    variables: {
      date,
      timezone,
      providerId: provider.id,
      weekly: scheduleType === 'recurring',
      allocations: localAllocations.map(alloc => ({
        ...alloc,
        repeatsUntil: repeatsUntilDate ? moment(repeatsUntilDate) : null,
      })),
    },
    skip: !blockAllocations.length,
  });

  // Ensure non-admin providers cannot save if conflicts are present.
  // Note: the final canSave check must be done with localAllocation results so it cannot be in useEditAvailability
  //       unless useConflictWarnings is combined with it.
  const canSave = editAvailabilityCanSave && (isAdmin || !hasConflicts);

  // On save
  const handleUpdate: UpdateFn = async args => {
    if (args.scheduleType !== scheduleType) {
      setScheduleType(args.scheduleType);
    }

    const isRecurring = args.scheduleType === 'recurring' && repeatsUntilDate;
    const completedAllocations = (
      isRecurring
        ? localAllocations.map(alloc => ({ ...alloc, repeatsUntil: moment(repeatsUntilDate) }))
        : localAllocations
    ).filter(isCompletedAllocation);

    await updateAllocations({
      variables: {
        date: date.format('YYYY-MM-DD'),
        providerId: provider.id,
        timezone,
        weekly: args.scheduleType === 'recurring',
        allocations: completedAllocations.map(formatAllocation),
      },
    });

    onSuccess();
  };

  // On repeat dropdown
  const handleScheduleRepeats = (value: ScheduleType) => {
    setScheduleType(value);

    if (!localAllocations.length) {
      return;
    }
    for (const alloc of localAllocations) {
      dispatch({
        type: 'update',
        args: {
          allocationId: alloc.id,
          field: 'weekly',
          value: value === 'recurring',
        },
      });
    }

    // Remove repeatsUntil in localAllocations if 'Does not repeat' is selected
    if (value !== 'recurring') {
      for (const alloc of localAllocations) {
        dispatch({
          type: 'update',
          args: {
            allocationId: alloc.id,
            field: 'repeatsUntil',
            value: null,
          },
        });
      }
    }
  };

  // On repeats until date picker
  const handleRepeatsUntil = (endDate: Date | null) => {
    if (endDate) {
      setRepeatsUntilDate(endDate);
      if (localAllocations.length) {
        for (const alloc of localAllocations) {
          dispatch({
            type: 'update',
            args: {
              allocationId: alloc.id,
              field: 'repeatsUntil',
              value: moment(endDate),
            },
          });
        }
      }
    }
  };

  // Set update schedule for text
  useEffect(() => {
    if (scheduleType === 'recurring' && repeatsUntilDate) {
      setUpdateScheduleForText(
        `Update schedule for all ${date.format('dddd')}s from ${date.format(
          'MM/DD/YY'
        )} to ${moment(repeatsUntilDate).format('MM/DD/YY')}`
      );
    } else if (scheduleType === 'single') {
      setUpdateScheduleForText(`Update schedule for ${date.format('MM/DD/YY')}`);
    } else {
      setUpdateScheduleForText('');
    }
  }, [updateScheduleForText, scheduleType, date, repeatsUntilDate]);

  if (showConfirmModal) {
    return (
      <ConfirmAvailabilityOverrideModalV2
        onCancel={onCancel}
        onConfirm={() => handleUpdate({ scheduleType: 'recurring' })}
        dates={getSingleDayIntersections(allocations, date)}
      />
    );
  }

  if (showRepeatingScheduleConfirmModal) {
    return (
      <RepeatingScheduleConfirmModal
        onCancel={onCancel}
        onConfirmRepeating={() => {
          setScheduleType('recurring');
          setScheduleTypeFromRepeatModal('recurring');
          setShowRepeatingScheduleConfirmModal(false);
        }}
        onConfirmSingle={() => {
          setScheduleType('single');
          setScheduleTypeFromRepeatModal('single');
          setShowRepeatingScheduleConfirmModal(false);
          if (localAllocations.length) {
            for (const alloc of localAllocations) {
              dispatch({
                type: 'update',
                args: {
                  allocationId: alloc.id,
                  field: 'weekly',
                  value: false,
                },
              });
            }
          }
        }}
        date={date.format('M/D/YY')}
      />
    );
  }

  return (
    <Modal isOpen onClose={onCancel}>
      <Styles.AvailabilityHeader className="flex flex-row justify-between gap-3">
        <div>
          <Text.h1>Edit Availability</Text.h1>
          <Text.h3 kind="grayText">{date.format('dddd, MMM D, YYYY')}</Text.h3>
        </div>
        <HoursCounters
          date={date}
          allAllocations={allocations}
          blockAllocations={localAllocations
            .filter(a => !errors[a.id])
            .filter(isCompletedAllocation)}
        />
      </Styles.AvailabilityHeader>
      <Styles.AvailabilityModalBody>
        <Styles.AvailabilitySection>
          {!localAllocations.length && <Styles.Unavailable>Unavailable</Styles.Unavailable>}
          {!!localAllocations.length && (
            <>
              <Styles.AllocationGridV2>
                <Text.bodyBold>From</Text.bodyBold>
                <div />
                <Text.bodyBold>To</Text.bodyBold>
                <Text.bodyBold>Hours</Text.bodyBold>
                <Text.bodyBold>Organization</Text.bodyBold>
                <Text.bodyBold>Type</Text.bodyBold>
                <Text.bodyBold>FFS</Text.bodyBold>
                <div />
              </Styles.AllocationGridV2>
              {localAllocations.map(alloc => {
                const allocError = errors[alloc.id];
                return (
                  <React.Fragment key={alloc.id}>
                    <Allocation
                      date={date}
                      disabled={!isLocalAllocEditable(alloc, isAdmin)}
                      allocation={alloc}
                      timezone={timezone}
                      provider={provider}
                      dispatch={dispatch}
                      error={allocError}
                    />
                    {allocError && <Styles.Error>{allocError.msg}</Styles.Error>}
                  </React.Fragment>
                );
              })}
            </>
          )}
          {!hasErrors && localAllocations.every(isCompletedAllocation) && (
            <Styles.AddLinkV2
              className="mt3"
              onClick={() => dispatch({ type: 'add', args: { timezone, ffs: !isAdmin } })}
            >
              + Add time block
            </Styles.AddLinkV2>
          )}
        </Styles.AvailabilitySection>
        <Styles.AvailabilitySection>
          <RepeatsDropdown
            scheduleType={scheduleType}
            scheduleTypeFromRepeatModal={scheduleTypeFromRepeatModal}
            date={date}
            blockAllocations={blockAllocations}
            localAllocations={localAllocations}
            repeatsUntilDate={repeatsUntilDate}
            onChangeScheduleType={handleScheduleRepeats}
            onChangeRepeatsUntil={handleRepeatsUntil}
          />
        </Styles.AvailabilitySection>
        <WarningContainer
          className={cx(
            `${!hasErrors && hasConflicts && 'show-conflicts mb3'} flex flex-column gap-2`
          )}
        >
          <Text.body>
            The availability you are attempting to remove contains the following scheduled
            appointments:
          </Text.body>
          <div>
            {firstNConflicts.map((a, idx) => (
              <div key={`${a.user.id}-${idx}`} className="flex flex-row gap-2 items-center">
                <Text.link className="b" to={`/users/${a.user.id}`} target="_blank">
                  {getFullName(a.user)} {a.user.customerId}
                </Text.link>
                <div>|</div>
                <Text.body>
                  {moment(a.startTime).format('M/D/YYYY hh:mma')} -{' '}
                  {moment(a.endTime).format('hh:mma')}
                </Text.body>
              </div>
            ))}
          </div>
          {remainingConflictCount > 0 && <Text.body>+ {remainingConflictCount} more</Text.body>}
          {isAdmin && (
            <Text.body>
              If you continue with this change, please follow-up with all patients currently booked
              during this time block.
            </Text.body>
          )}
          {!isAdmin && (
            <Text.body>
              You must cancel or rescheduled all booked appointments during this time block to
              remove it.
            </Text.body>
          )}
        </WarningContainer>
        <Styles.AvailabilitySection
          className={`flex gap-4 items-center ${
            canSave && updateScheduleForText ? 'justify-between' : 'justify-end'
          }`}
        >
          {canSave && updateScheduleForText && (
            <Text.bodyBold>{updateScheduleForText}</Text.bodyBold>
          )}
          <div className="flex gap-4 justify-end">
            <Text.linkButton kind="grayText" className="b" onClick={onCancel}>
              Cancel
            </Text.linkButton>
            <FinalButton
              className="mw5 w-100"
              kind="primary"
              onClick={() => {
                if (scheduleType === 'recurring' && hasSingleDayIntersection(allocations, date)) {
                  setShowConfirmModal(true);
                } else {
                  handleUpdate({ scheduleType });
                }
              }}
              disabled={!canSave}
              loading={loading}
            >
              Save
            </FinalButton>
          </div>
        </Styles.AvailabilitySection>
      </Styles.AvailabilityModalBody>
    </Modal>
  );
};

const WarningContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  height: 0;
  opacity: 0;
  transform: scaleY(0);
  transition: opacity 0.03s, transform 0.07s;
  // for better UI hide children elements
  &:not(.show-conflicts) > * {
    display: none;
    background: transparent;
  }
  &.show-conflicts {
    opacity: 1;
    transform: scaleY(1);
    height: 100%;
    background: ${colorMap.danger[6]};
  }
`;

const isLocalAllocEditable = (alloc: LocalAllocation, isAdmin: boolean): boolean => {
  // Admins can edit all allocations
  if (isAdmin) {
    return true;
  }

  // Providers can edit allocations that are time off or admin
  if (['timeOff', 'admin'].includes(alloc.type)) {
    return true;
  }

  // Providers can edit billable visits which are FFS=true and are one of: default, intake only, follow-up only
  return (
    alloc.isFeeForServiceTime === true && ['checkin', 'default', 'intake'].includes(alloc.type)
  );
};
