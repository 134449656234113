import React from 'react';
import styled from 'styled-components';
import { Text } from '../../globalStyles';
import { FinalButton } from '../FinalButton';
import { InlineSVG } from '../Icons';
import { CenteredMaxWidth } from './Styles';

export const ConsentNeededBlocker = () => {
  const openMail = () => {
    window.open('mailto:hi@mantrahealth.com');
  };

  return (
    <CenteredMaxWidth>
      <ConsentBlockerContent>
        <InlineSVG kind="dangerBgAlt" icon="alert-circle" size={40} />

        <Text.h3 className="mt2">Consent needed to schedule</Text.h3>

        <Text.body kind="grey.ozGray2" className="mt2">
          Visits cannot be scheduled for minors until they have completed the consent process.
        </Text.body>

        <FinalButton onClick={openMail}>Contact Mantra Patient Support</FinalButton>
      </ConsentBlockerContent>
    </CenteredMaxWidth>
  );
};

const ConsentBlockerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  justify-content: space-between;
  height: 220px;
`;
