import { has, map } from 'lodash';
import { Select as NativeBaseSelect, ISelectProps } from 'native-base';
import React from 'react';

type SelectProps<OptionValue extends string> = Omit<ISelectProps, 'onValueChange'> & {
  onValueChange?: (itemValue: OptionValue) => void;
  options: Record<OptionValue, string>;
};

export const Select = <OptionValue extends string>({
  onValueChange,
  options,
  ...selectProps
}: SelectProps<OptionValue>): JSX.Element => {
  const onChanged = (itemValue: string): void => {
    if (onValueChange === undefined) {
      return;
    }

    if (has(options, itemValue)) {
      onValueChange(itemValue as OptionValue);
    }
  };

  return (
    <NativeBaseSelect {...selectProps} onValueChange={onChanged}>
      {map(options, (optionLabel, optionValue) => (
        <NativeBaseSelect.Item key={optionValue} label={optionLabel} value={optionValue} />
      ))}
    </NativeBaseSelect>
  );
};
