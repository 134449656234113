import { CareType, usePayersQuery } from '../../../graphQL';

export const useNetworkSearchOptions = () => {
  const { data } = usePayersQuery({ fetchPolicy: 'cache-first' });
  const payers = data?.payers.map(p => ({ id: p.id, label: p.name })) ?? [];
  const careTypes = [
    { id: CareType.Psychiatry, label: 'Psychiatry' },
    { id: CareType.Therapy, label: 'Therapy' },
  ];
  return { payers, careTypes };
};
