import { Delete, Menu } from 'baseui/icon';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import FocusTrap from 'focus-trap-react';
import styled from 'styled-components';
import { colors, hideBrowserOutline, HideItemsWhen } from '../../globalStyles';
import { InlineSVG } from '../Icons';
import { useCurrentProvider } from '../Permissions';
import { NavContent } from './Navigation';
import { ItemId, useNavItems } from './NavItems';
import { NavButton } from './Styles';

const HEIGHT = '51px';

// some sections have shorter titles for smaller screens
const titleOverrides: Partial<Record<ItemId, string>> = {
  [ItemId.Appointments]: 'Appts',
};

const showTop: Set<ItemId> = new Set([
  ItemId.Users,
  ItemId.Students,
  ItemId.Providers,
  ItemId.Staff,
  ItemId.Appointments,
  ItemId.Messaging,
]);

interface NavigationSmallProps {
  isImpersonating?: boolean;
}

export const NavigationSmall = ({ isImpersonating }: NavigationSmallProps) => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const { navItems, activeItem } = useNavItems();
  const { logout } = useCurrentProvider();

  const escapeMenu = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && showMenu) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    setShowMenu(false);
  }, [activeItem]);

  // Accessibility: If user presses Escape, menu should close
  useEffect(() => {
    document.addEventListener('keyup', escapeMenu);

    return () => document.removeEventListener('keyup', escapeMenu);
  }, []);

  return (
    <StickyHeader style={{ height: showMenu ? '100%' : HEIGHT }}>
      <Wrapper
        className="flex justify-between items-center dn-l w-100 pb10"
        style={{ height: HEIGHT }}
      >
        {showMenu && (
          <FocusTrap>
            <Wrapper className="aspect-ratio--object bg-white">
              <div className="flex justify-between items-center" style={{ height: HEIGHT }}>
                <IconButton className="mh3 pa2" onClick={() => history.push('/*****')}>
                  <InlineSVG
                    kind={isImpersonating ? 'dangerBgLogo' : 'white'}
                    icon="logo-small"
                    title="Mantra Health logo"
                    width={22}
                  />
                </IconButton>

                <IconButton className="mh3 ph2 bg-none" onClick={() => setShowMenu(m => !m)}>
                  <Delete size={26} color="white" />
                </IconButton>
              </div>
              <NavContent navItems={navItems} activeItem={activeItem} />
              <NavButton type="button" onClick={logout} className="w-100 mb10">
                <span className="o-50">Logout</span>
              </NavButton>
            </Wrapper>
          </FocusTrap>
        )}
        {/* the catch-all redirect will take the user home */}
        <IconButton className="ml3 pa2" onClick={() => history.push('/*****')}>
          <InlineSVG
            kind={isImpersonating ? 'dangerBgLogo' : 'white'}
            icon="logo-small"
            title="Mantra Health logo"
            width={22}
          />
        </IconButton>
        <div className="flex items-center">
          <HideItemsWhen className="dib" maxWidth={630}>
            <div className="flex" style={{ height: HEIGHT }}>
              {navItems
                .filter(i => showTop.has(i.itemId))
                .map(item => {
                  const isActive = item.itemId === activeItem;
                  const { Count } = item;

                  return (
                    <NavTab
                      to={item.path}
                      key={item.itemId}
                      data-active={isActive}
                      className="link f6"
                    >
                      <p className={`fw5 ${isActive ? '' : 'o-50'}`}>
                        {titleOverrides[item.itemId] ?? item.title}
                      </p>
                      {Count && (
                        <Count
                          active={isActive}
                          wrapper={({ children }) => <div className="ml3">{children}</div>}
                        />
                      )}
                    </NavTab>
                  );
                })}
            </div>
          </HideItemsWhen>
          <IconButton className="mr3 ph2" onClick={() => setShowMenu(m => !m)}>
            <Menu size={24} color="white" />
          </IconButton>
        </div>
      </Wrapper>
    </StickyHeader>
  );
};

const NavTab = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0rem 0.75rem;
  margin: 0rem 0.25rem;
  color: white;
  border-bottom: 4px solid;
  border-color: ${colors.navBg};

  ${hideBrowserOutline}

  :focus {
    border-color: ${colors.white};
  }

  &[data-active='true'] {
    border-color: ${colors.primary};
  }
`;

const Wrapper = styled.div`
  background-color: ${colors.navBg};
  color: white;
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 99;
  overflow: auto;
  background-color: ${colors.navBg};
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
