import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colorMap, Text } from '../../globalStyles';
import { FinalButton } from '../FinalButton';
import { InlineSVG } from '../Icons';
import { MaxWidthDiv } from '../MaxWidth';
import { ContractWeeklyLimitReachedMoreInfoModal } from './ContractWeeklyLimitReachedMoreInfoModal';
import { moveToWeekStart } from '../Scheduler/index';
import { AppView } from '../../graphQL';
import { useCurrentProvider } from '../Permissions';
import { cx } from '../../utils';

type ContractWeeklyLimitReachedBannerProps = {
  onSelectStartDate: (m: Moment) => void;
  organizationName?: string;
  nextAvailableDate?: Date;
  gridSpan?: number;
};

export const ContractWeeklyLimitReachedBanner = ({
  organizationName,
  onSelectStartDate,
  nextAvailableDate,
  gridSpan,
}: ContractWeeklyLimitReachedBannerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { appView } = useCurrentProvider();

  const nextAvailableMoment = nextAvailableDate ? moment(nextAvailableDate) : undefined;
  const moveToWeek = nextAvailableMoment ? moveToWeekStart(nextAvailableMoment) : undefined;

  if (appView !== AppView.Oz) {
    // Only Oz users see internal weekly limit messaging. Availability should have been
    // filtered out for all other users.
    return null;
  }

  // Oz users see weekly limit messaging
  return (
    <ContractWeeklyLimitReachedBannerContainer className={className}>
      <MaxWidthDiv className="center flex flex-column gap-2" mw="30rem">
        <div className="tc center flex flex-row gap-2 items-center">
          <InlineSVG icon="alert-circle" size={30} kind="greyIcon" />
        </div>
        <Text.h3>
          {organizationName ?? 'This organization'} has reached its weekly allocated limit.
        </Text.h3>
        <ModalButton className="b" onClick={() => setIsModalOpen(true)}>
          What does this mean?
        </ModalButton>
        <ContractWeeklyLimitReachedMoreInfoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
        <Text.bodyCaption kind="grayText" className={cx({ dn: gridSpan && gridSpan <= 2 })}>
          If there's an urgent clinical need, you may submit a request for earlier accommodation via
          Slack.
        </Text.bodyCaption>
        {nextAvailableMoment && moveToWeek && (
          <div>
            <FinalButton
              className="mt3 bg-white"
              kind="outline_black"
              onClick={() => onSelectStartDate(moveToWeek)}
            >
              Next availability {nextAvailableMoment.format('ddd, MMMM D')}
            </FinalButton>
          </div>
        )}
      </MaxWidthDiv>
    </ContractWeeklyLimitReachedBannerContainer>
  );
};

const className = 'absolute top-0 left-0 right-0';

const ContractWeeklyLimitReachedBannerContainer = styled.div`
  padding: 2rem;
  background: ${colorMap.warning[5]};
  position: relative;
  opacity: 1;
  width: 100%;
`;

const ModalButton = styled(Text.linkButton)`
  text-align: left;
`;
