import React, { useState } from 'react';
import { Modal, ModalBody } from 'baseui/modal';
import { FormProvider, useForm } from 'react-hook-form';
import { FinalButton } from '../FinalButton';
import { Text } from '../../globalStyles';
import { TextareaRHF } from '../Form';
import { useNetworkSearchAccommodationRequestMutation } from '../../graphQL';
import { useCurrentProvider } from '../Permissions';
import { Icon } from '../Icons';
import { Warning } from '../Warning';

type ReferralAccommodationModalProps = {
  showAccommodationModal: boolean;
  setShowAccommodationModal: (value: boolean) => void;
};

export const ReferralAccommodationModal = ({
  showAccommodationModal,
  setShowAccommodationModal,
}: ReferralAccommodationModalProps) => {
  const modalForm = useForm({ defaultValues: { accommodationRequestDetails: '' } });
  const [request] = useNetworkSearchAccommodationRequestMutation();
  const [formComplete, setFormComplete] = useState<boolean>(false);
  const { currentProvider } = useCurrentProvider();

  const onClickCancel = () => {
    modalForm.reset();
    setShowAccommodationModal(false);
  };

  const onSubmit = modalForm.handleSubmit(async values => {
    await request({
      variables: { accommodationRequestDetails: values.accommodationRequestDetails },
    });
    modalForm.reset();
    setFormComplete(true);
  });

  return (
    <Modal isOpen={showAccommodationModal} onClose={() => setShowAccommodationModal(false)}>
      <ModalBody>
        {!formComplete && (
          <>
            <Text.h2 className="f4 mb3 pt5">Request student care</Text.h2>
            <Text.body>
              Complete this form to request that Mantra Health coordinate care for a student in this
              state.
            </Text.body>
            <Text.bodyBold className="mt3">
              Please make sure your request includes <span className="underline">all</span> of the
              following information:
            </Text.bodyBold>
            <Text.body>
              <ul>
                <li>Student Full Name </li>
                <li>Student Date of Birth </li>
                <li>Student University Email Address</li>
                <li>Student Phone Number </li>
                <li>Student Zip Code </li>
                <li>Referral type (Therapy or Psychiatry)</li>
                <li>Any additional referral details</li>
              </ul>
            </Text.body>
            <FormProvider {...modalForm}>
              <div>
                <TextareaRHF
                  name="accommodationRequestDetails"
                  controlProps={{ label: 'Request details', required: true }}
                  rules={{ required: true }}
                />
                <div className="flex flex-column">
                  <FinalButton className="w-100" type="submit" onClick={onSubmit}>
                    Send Request
                  </FinalButton>
                  <FinalButton className="w-100" onClick={onClickCancel} kind="minimal_gray">
                    Close without saving
                  </FinalButton>
                </div>
              </div>
            </FormProvider>
          </>
        )}
        {formComplete && (
          <>
            <Icon
              icon="iconsCircleCheckSvg"
              alt="Success"
              className="center db pt5 pb4"
              size={26}
            />
            <Text.h2 className="pb4">Your request has been received</Text.h2>
            <Text.bodySmallGrey>
              <div className="b">NEXT STEPS:</div>
            </Text.bodySmallGrey>
            <ul>
              <li className="pb2">
                <Text.bodyBold>
                  Mantra Health will reach out to this patient directly to coordinate care.
                </Text.bodyBold>
              </li>
              <li>
                Any questions or updates about this request will be sent to your email (
                {currentProvider.email})
              </li>
            </ul>
            <Warning>
              <Text.body>
                <Text.bold>Note: </Text.bold>
                This student does not have a Mantra Health account, so they will not receive an
                account activation link. We&apos;ll reach out to this student directly via email
                and/or phone to coordinate.
              </Text.body>
            </Warning>
            <FinalButton className="w-100" onClick={onClickCancel} kind="minimal_gray">
              Close
            </FinalButton>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
