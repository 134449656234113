import { first, memoize } from 'lodash';
import { CareType, Provider, Role } from '../graphQL';

export const formatProviderName = ({
  firstName,
  lastName,
  postNominalTitles,
}: Pick<Provider, 'firstName' | 'lastName' | 'postNominalTitles' | 'personalTitle'>) => {
  let name = `${firstName} ${lastName}`;
  if (postNominalTitles) {
    name += `, ${postNominalTitles}`;
  }
  return name;
};

export const formatProviderCareTypes = ({ careTypes }: Pick<Provider, 'careTypes'>) => {
  return careTypes.map(i => i[0].toUpperCase() + i.slice(1)).join(', ');
};

type SuperAdminCheck = Pick<Provider, 'role'> & { organizations: any[] };
export const isProviderSuperAdmin = (provider: SuperAdminCheck) =>
  provider.role === Role.UniversityAdmin && first(provider.organizations)?.children.length > 0;

export const prettifyRole = memoize((uglyRole: string) => {
  // camelcase -> spaces & capitalize first letter
  return uglyRole.replace(/([A-Z])/g, ' $1').replace(/^./, s => s.toUpperCase());
});

export const getInitials = ({ firstName, lastName }: Pick<Provider, 'firstName' | 'lastName'>) =>
  `${firstName?.[0] ?? '-'}${lastName?.[0] ?? '-'}`;

export const careTypeToProviderTitle: Record<CareType, string> = {
  [CareType.Psychiatry]: 'Psychiatric Provider',
  [CareType.Therapy]: 'Therapist',
};

export const careTypeToProviderType: Record<CareType, string> = {
  [CareType.Psychiatry]: 'psychiatrist',
  [CareType.Therapy]: 'therapist',
};
