import { Delete } from 'baseui/icon';
import React from 'react';
import styled from 'styled-components';
import { Text } from '../../globalStyles';
import * as Styles from '../EzForm/styles';

type Props<T> = {
  component: (props: SearchAndAddMultirowComponentArgs<T>) => JSX.Element;
  values?: T[];
  max?: number;
  noRemove?: boolean;
  canRemoveIf?: (v: T) => boolean;
  testIdPrefix?: string;
  onAddValue: (v: T) => void;
  onRemoveValue: (removed: T, idx: number) => void;
  onEditValue: (updated: T, idx: number) => void;
};

export type SearchAndAddMultirowComponentArgs<T> = {
  values: T[];
  index: number;
  onChange: (updated: T, idx: number) => void;
  removeRow: (removed: T, idx: number) => void;
  addRow: (v: T) => void;
};

export type RemoveCampusTeamMemberModalProps<T> = {
  campusTeam: T[];
  index?: number;
  isOpen: boolean;
  onClose: () => void;
  removeCampusTeamMember: (removed: T, idx: number) => void;
  modalOpenIdx: number | null;
  currentProviderName: string;
};

export function SearchAndAddEzMultirow<T>({
  values = [],
  component: C,
  noRemove,
  canRemoveIf,
  testIdPrefix = '',
  onAddValue,
  onRemoveValue,
  onEditValue,
}: Props<T>) {
  return (
    <SearchAndAddMultirowDiv className="mt3">
      {values.length ? (
        values.map((value, index) => (
          <div className="flex mb3" key={index}>
            {!noRemove && (!canRemoveIf || canRemoveIf(value)) && (
              <DeleteButton
                onClick={() => onRemoveValue(values[index], index)}
                data-testid={`${testIdPrefix}-delete-item-button-${index}`}
                tabIndex={0}
              >
                <Delete size={24} color="red" />
              </DeleteButton>
            )}
            <C
              key={index}
              values={values}
              index={index}
              addRow={onAddValue}
              onChange={onEditValue}
              removeRow={onRemoveValue}
            />
          </div>
        ))
      ) : (
        <Text.bodySmallItalicized>No Members</Text.bodySmallItalicized>
      )}
    </SearchAndAddMultirowDiv>
  );
}

const DeleteButton = styled(Styles.DeleteButton)`
  margin: auto 0;
`;

const SearchAndAddMultirowDiv = styled.div`
  margin-bottom: 60px;
  min-height: 50px;
`;
