import styled from 'styled-components';
import { colors, Text } from '../../../globalStyles';

export const Required = styled.span`
  color: ${colors.danger};
`;

export const FieldName = styled.label`
  display: block;
  font-weight: bold;
  color: ${colors.black};
`;

export const Caption = styled(Text.captionGrey)`
  line-height: 16px;
`;

export const ProgressContainer = styled.div`
  border-radius: 12px;
  background: ${colors.grey.disabledBackground};
`;

type ProgressProps = {
  progress: number;
};

export const Progress = styled.div<ProgressProps>`
  height: 100%;
  min-height: 4px;
  width: ${({ progress }) => `${progress}%`};
  background-color: ${colors.green};
  border-radius: inherit;
`;
