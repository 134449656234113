import { ApolloError } from '@apollo/client';
import { KIND } from 'baseui/notification';
import React from 'react';
import { CenteredNotification } from '../../Components/Notification';

export function AuthError({
  error,
  contents = 'An unexpected error occurred.',
}: {
  error?: ApolloError;
  contents?: React.ReactNode;
}) {
  if (error?.graphQLErrors[0]?.extensions?.exception.code === 'RATE_LIMIT') {
    contents = (
      <div>
        Your account has been locked due to too many unsuccessful login attempts. Please wait 15
        minutes or <a href="/resetpassword">reset your password</a> to log in.
      </div>
    );
  }
  return <CenteredNotification kind={KIND.negative}>{contents}</CenteredNotification>;
}
