import React from 'react';
import styled from 'styled-components';
import { PopoverTabFooter, PopoverTabFooterProps } from './PopoverTabFooter';

type PopoverTabInnerProps = {
  footer?: PopoverTabFooterProps;
};
export const PopoverTabInner: React.FC<PopoverTabInnerProps> = ({ children, footer }) => {
  return (
    <StyledInnerPopover>
      <StyledContent>{children}</StyledContent>
      {footer && <PopoverTabFooter {...footer} />}
    </StyledInnerPopover>
  );
};

export const StyledInnerPopover = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledContent = styled.div`
  background: white;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
  max-height: 100%;
  position: relative;
  overflow-y: scroll;
  height: 0;
  flex-grow: 1;
`;
