import { ModalBody, ModalHeader } from 'baseui/modal';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AdminAssessmentQuery,
  AssessmentKey,
  useAdminAssessmentQuery,
  useAdminSubmitAssessmentMutation,
} from '../../graphQL';
import { useDrilldownContext } from '../../Pages/Users/helpers';
import { borderRadius } from '../../utils';
import { FinalButton } from '../FinalButton';
import { LoadingPage } from '../LoadingOverlay';
import { modalFactory } from '../Modal';
import { TabBodyProps } from '../PopoverTabs/PopoverTab';
import { PopoverTabInner } from '../PopoverTabs/PopoverTabInner';
import { AssessmentQuestion } from './AssessmentQuestion';
import { AssessmentResults } from './AssessmentResults';

type AssessmentPopoverProps = {
  tab: TabBodyProps;
  assessmentKey: AssessmentKey;
};

export const AssessmentPopover = ({ assessmentKey, tab }: AssessmentPopoverProps) => {
  const { user } = useDrilldownContext();
  const { data } = useAdminAssessmentQuery({
    variables: { assessment: assessmentKey, userId: user.id },
  });

  if (!data) return <LoadingPage />;
  return (
    <AssessmentPopoverContent
      tab={tab}
      assessment={data.assessment}
      assessmentKey={assessmentKey}
    />
  );
};

type AssessmentPopoverContentProps = {
  tab: TabBodyProps;
  assessmentKey: AssessmentKey;
  assessment: AdminAssessmentQuery['assessment'];
};

const AssessmentPopoverContent = ({
  assessment,
  assessmentKey,
  tab,
}: AssessmentPopoverContentProps) => {
  const { user, refetch } = useDrilldownContext();
  const formContext = useForm({ shouldFocusError: true });
  const [submitAssessment, { data }] = useAdminSubmitAssessmentMutation();

  const { setDirty } = tab;
  useEffect(() => {
    setDirty(true);
  }, [setDirty]);

  const onSubmit = formContext.handleSubmit(async values => {
    for (const [key1, qValues] of Object.entries(values)) {
      for (const [key2, value] of Object.entries(qValues)) {
        if (!value) {
          return document.getElementById(`${key1}.${key2}`)?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }

    await submitAssessment({
      variables: {
        userId: user.id,
        assessment: assessmentKey,
        questionnaires: assessment.questionnaires.map(({ key, questions }) => {
          const qAnswers = values[key];
          return {
            key,
            answers: questions.map(q => ({
              key: q.key,
              ...(Array.isArray(qAnswers[q.key])
                ? { multiText: qAnswers[q.key] }
                : { text: qAnswers[q.key] }),
            })),
          };
        }),
      },
    });
    await refetch();
  });

  if (data)
    return (
      <PopoverTabInner>
        <AssessmentResults results={data.result} />
        <div className="mt4 flex flex-column items-stretch">
          <FinalButton type="button" kind="outline_black" onClick={() => tab.confirmExit()}>
            Close
          </FinalButton>
        </div>
        <p className="mt4 f6 tc">
          You can view submitted answers &amp; results in the patient’s Assessments tab at any time.
        </p>
      </PopoverTabInner>
    );
  return (
    <PopoverTabInner>
      <ConfirmExitModal tab={tab} />
      <FormProvider {...formContext}>
        <form onSubmit={onSubmit} className="mb5">
          {assessment.questionnaires.map(questionnaire => (
            <div key={questionnaire.key}>
              <h4 className="mb3 f5">{questionnaire.title}</h4>
              <p>{questionnaire.description}</p>
              <div className="">
                {questionnaire.questions.map(question => (
                  <AssessmentQuestion
                    question={question}
                    name={`${questionnaire.key}.${question.key}`}
                    key={question.key}
                  />
                ))}
              </div>
            </div>
          ))}
          <div className="mt4 flex flex-column items-stretch">
            <FinalButton type="submit" kind="primary">
              Submit &amp; Score
            </FinalButton>
          </div>
          <div className="mt3 flex flex-column items-stretch">
            <FinalButton type="button" kind="minimal_black" onClick={() => tab.handleClose()}>
              Quit without saving
            </FinalButton>
          </div>
        </form>
      </FormProvider>
    </PopoverTabInner>
  );
};

const Modal = modalFactory({ style: { ...borderRadius('4px') } });

const ConfirmExitModal = ({ tab }: { tab: TabBodyProps }) => (
  <Modal isOpen={tab.showConfirm} onClose={() => tab.cancelExit()}>
    <ModalHeader>
      <h4 className="f4 mb3">Close without submitting?</h4>
    </ModalHeader>
    <ModalBody>
      <p className="mb4">
        If you close without submitting, all the changes you have made will be lost.
      </p>
      <div className="flex items-center justify-around gap-3">
        <FinalButton kind="outline_black" onClick={() => tab.confirmExit()}>
          Discard Changes
        </FinalButton>
        <FinalButton kind="primary" onClick={() => tab.cancelExit()}>
          Return to Assessment
        </FinalButton>
      </div>
    </ModalBody>
  </Modal>
);
