import { ModalBody, ModalHeader } from 'baseui/modal';
import { Notification } from 'baseui/notification';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FinalButton } from '../../../Components/FinalButton';
import { modalFactory } from '../../../Components/Modal';
import {
  isMantraAdmin,
  isMantraProvider,
  isMcpOrReferralUser,
  useCurrentProvider,
} from '../../../Components/Permissions';
import { Text } from '../../../globalStyles';
import {
  GetAllUploadsOnUserQuery,
  UploadType,
  useAdminDeleteUploadMutation,
  useAdminUpdateUploadMutation,
} from '../../../graphQL';
import { borderRadius } from '../../../utils';
import { useDrilldownContext } from '../helpers';
import {
  ShareableCheck,
  UploadName,
  uploadTypeCopy,
  uploadTypeLabels,
  UploadTypeSelect,
} from './Input';
import { wasCreatedByProvider } from './utils';

interface FileUploadProps<T> {
  closeModal: () => void;
  refetch?: () => Promise<T> | T;
  upload: GetAllUploadsOnUserQuery['adminUser']['uploads'][number];
}

type Form = {
  type: UploadType;
  nameOverride: string;
  shareable?: boolean;
};

const Modal = modalFactory({
  style: {
    ...borderRadius('4px'),
    paddingLeft: '4rem',
    paddingRight: '4rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
});

export function FileEdit<T>({ closeModal, refetch, upload }: FileUploadProps<T>) {
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { permissibleUploadTypes } = useDrilldownContext();
  const { currentProvider } = useCurrentProvider();
  const [deleteUploadMutation] = useAdminDeleteUploadMutation();
  const [updateUpload] = useAdminUpdateUploadMutation();

  // roles and permissions
  const isMcp = isMcpOrReferralUser(currentProvider);
  const isTreatmentProvider = isMantraProvider(currentProvider);
  const isAdmin = isMantraAdmin(currentProvider);
  const canDelete = isAdmin || (wasCreatedByProvider(currentProvider, upload) && !isMcp);

  // normalized data
  const { name, id, globallyShared, type: uploadType } = upload;

  const { register, control, handleSubmit, errors, watch } = useForm<Form>({
    defaultValues: {
      type: uploadType,
      nameOverride: name,
      shareable: !!globallyShared,
    },
  });

  const save = handleSubmit(async ({ type, nameOverride, shareable }) => {
    try {
      setSaving(true);
      setError('');
      await updateUpload({
        variables: {
          input: {
            uploadId: id,
            type: type ?? upload.type,
            name: nameOverride,
            globallyShared: !!shareable || isMcp,
          },
        },
      });
      refetch && refetch();
      closeModal();
    } catch {
      setError('Update unsuccessful. Please try again.');
    } finally {
      setSaving(false);
    }
  });

  const deleteUpload = async () => {
    try {
      setDeleting(true);
      setError('');
      await deleteUploadMutation({
        variables: { uploadId: id },
      });
      refetch && refetch();
      closeModal();
    } catch {
      setError('Delete unsuccessful. Please try again.');
    } finally {
      setDeleting(false);
    }
  };

  // permissions
  const { type, nameOverride, shareable } = watch();
  const submittable =
    type !== uploadType || nameOverride?.trim() !== name || shareable !== !!globallyShared;
  const allowShare = !isMcp;
  const canEditType = !isTreatmentProvider;
  const typeCopy = canEditType && !allowShare && type && uploadTypeCopy[type];

  return (
    <>
      <Modal isOpen={!deleteOpen} onClose={closeModal}>
        <ModalHeader>
          <Text.h2 className="mb4" style={{ maxWidth: '325px' }}>
            Edit Upload
          </Text.h2>
        </ModalHeader>
        <ModalBody>
          {error && <Notification kind="negative">{error}</Notification>}
          <form style={{ width: '325px' }} onSubmit={save}>
            <UploadName
              name="nameOverride"
              titleOverride="Upload name"
              inputRef={register}
              optional
            />
            <UploadTypeSelect
              name="type"
              control={control}
              errorCaption={errors.type?.message}
              defaultValue={type ?? undefined}
              options={permissibleUploadTypes.map(v => ({
                id: v,
                label: uploadTypeLabels[v],
              }))}
              display={canEditType}
            />
            {typeCopy && (
              <Text.bodySmall kind="black" className="mb4" style={{ lineHeight: 'normal' }}>
                {typeCopy}
              </Text.bodySmall>
            )}
            <ShareableCheck
              name="shareable"
              control={control}
              errorCaption={errors.shareable?.message}
              display={allowShare}
              checked={!!globallyShared}
            />
            <div className="mt4 flex flex-column gap-2">
              <FinalButton loading={saving} kind="primary" type="submit" disabled={!submittable}>
                Save Changes
              </FinalButton>
              {canDelete && (
                <FinalButton
                  kind="minimal_danger"
                  onClick={() => setDeleteOpen(true)}
                  type="button"
                >
                  Delete File
                </FinalButton>
              )}
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal isOpen={deleteOpen} onClose={closeModal}>
        <ModalHeader>
          <Text.h2 className="mb3" style={{ maxWidth: '325px' }}>
            Delete this file from patient profile?
          </Text.h2>
        </ModalHeader>
        <ModalBody>
          <Text.body kind="danger" className="mb4 mt1">
            This action cannot be undone.
          </Text.body>
          <div className="flex flex-column gap-2">
            <FinalButton kind="outline_danger" onClick={deleteUpload} disabled={deleting}>
              Yes, delete this file
            </FinalButton>
            <FinalButton kind="minimal_black" onClick={() => setDeleteOpen(false)}>
              Cancel
            </FinalButton>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
