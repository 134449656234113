import styled, { css } from 'styled-components';
import { colors, hideBrowserOutline } from '../../globalStyles';
import { HELVETICA } from '../../globalStyles/text';

export type InputProps = Pick<
  React.HTMLProps<HTMLInputElement>,
  | 'className'
  | 'type'
  | 'placeholder'
  | 'id'
  | 'disabled'
  | 'min'
  | 'max'
  | 'inputMode'
  | 'pattern'
  | 'autoComplete'
>;

export type TextareaProps = Pick<
  React.HTMLProps<HTMLTextAreaElement>,
  'className' | 'placeholder' | 'id' | 'disabled' | 'defaultValue'
>;

export const baseInputStyles = css<{ disabled?: boolean }>`
  font-family: ${HELVETICA};
  background-color: ${colors.grey.light};
  padding: 0.75rem;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1rem;
  line-height: 20px;

  border-width: 0.125rem;
  border-radius: 0.25rem;
  border-style: solid;
  border-color: ${colors.grey.light};

  &.error:not(:focus):not(.focus) {
    background-color: rgb(255, 239, 237);
    border-color: rgb(241, 153, 142);
  }

  &.focus:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled),
  &:active:not(:disabled):not(.disabled) {
    outline: none;
    border-color: black;
  }
`;

export const Input = styled.input`
  ${baseInputStyles}
`;

export const SmallInput = styled.input`
  ${baseInputStyles}
  font-size: 0.875em;
  line-height: 1.125em;
  width: 5rem;
  padding: 10px 10px;
  text-align: center;
  border: 1px solid ${colors.grey.lightBorder};
`;

export const Textarea = styled.textarea`
  ${baseInputStyles}

  height: 8rem;
  min-height: 6rem;
  max-height: 16rem;
  resize: vertical;
`;

export const InvisibleInput = styled.input`
  background: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${hideBrowserOutline}
`;
