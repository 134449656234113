import { BarController, BarElement, CategoryScale, Chart, LinearScale, Tooltip } from 'chart.js';
import { chain, keys, mapValues, pick } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useEvents } from '../../../../Components/Events/EventsProvider';
import { Dot } from '../../../../Components/Flair';
import { Select } from '../../../../Components/Form';
import { MantraSpinner } from '../../../../Components/LoadingOverlay';
import { useCurrentProvider } from '../../../../Components/Permissions';
import { Text } from '../../../../globalStyles';
import { CurrentProviderQuery, ReportScope } from '../../../../graphQL';
import { isProviderSuperAdmin } from '../../../../modelUtils/provider';
import { useDiagnosesBreakdown } from '../../hooks';
import { WidgetContainer } from '../../Styles';
import { colorsMain, getChartConfig } from './config';
import { CategoryModal } from './Modal';
import { generateDataBreakdown } from './utils';

Chart.register(BarController, CategoryScale, LinearScale, BarElement, Tooltip);

export const DiagnosesWidget = ({
  organizationId,
  scope,
}: {
  organizationId?: number;
  scope: ReportScope;
}) => {
  const metrics = useEvents();
  const { currentProvider } = useCurrentProvider();
  const [orgId, setOrgId] = useState(organizationId);
  const [category, setCategory] = useState<string | null>(null);
  const ref = useRef<HTMLCanvasElement>(null);

  const { data, loading } = useDiagnosesBreakdown({ orgId, scope });
  const dataGroups = useMemo(() => data && generateDataBreakdown(data), [data]);

  useEffect(() => {
    if (!dataGroups || !ref.current) return;

    const chart = new Chart(
      ref.current,
      getChartConfig({
        breakdown: mapValues(dataGroups, v => pick(v, 'additionalPercent', 'primaryPercent')),
        colors: colorsMain,
        onClick: c => setCategory(c),
      })
    );

    return () => {
      chart.destroy();
    };
  }, [setCategory, dataGroups]);

  if (loading || !dataGroups) {
    return <MantraSpinner simple />;
  }

  const organizationOptions = organizationId
    ? getOrganizationOptions(currentProvider, organizationId)
    : [];

  const categoryCount = keys(dataGroups).length;
  // Since super admins can select what data they want to view
  if (categoryCount === 0 && !isProviderSuperAdmin(currentProvider)) return null;

  return (
    <WidgetContainer>
      <div className="flex flex-row flex-wrap">
        {category && (
          <CategoryModal
            categoryKey={category}
            onClose={() => setCategory(null)}
            dataGroups={dataGroups}
          />
        )}
        <div className="flex-1 flex flex-column justify-between mr3" style={{ minWidth: 200 }}>
          <div>
            <Text.h2 className="mb2">Diagnoses Breakdown</Text.h2>
            <Text.bodyGrey>
              Common conditions of referred patients from your organization that have had at least
              one visit with Mantra
            </Text.bodyGrey>
            {isProviderSuperAdmin(currentProvider) && (
              <div className="mt4">
                <Select
                  options={organizationOptions}
                  value={orgId}
                  onChange={id => {
                    metrics.track(`reporting.diagnosis.organization.selected`, {
                      organizationId: id,
                    });
                    setOrgId(id as number);
                  }}
                />
              </div>
            )}
          </div>
          <div className="mv4">
            <div className="mb2">
              <Dot color={colorsMain.primary} size="1rem">
                <Text.caption className="b">Primary Diagnosis</Text.caption>
              </Dot>
            </div>
            <div>
              <Dot color={colorsMain.secondary} size="1rem">
                <Text.caption className="b">Additional Diagnosis</Text.caption>
              </Dot>
            </div>
          </div>
        </div>
        <CanvasContainer className="flex-2" categoryCount={categoryCount}>
          <div className="absolute absolute--fill">
            <canvas ref={ref} />
          </div>
        </CanvasContainer>
      </div>
    </WidgetContainer>
  );
};

const CanvasContainer = styled.div<{ categoryCount: number }>`
  position: relative;
  // Desired min width of every bar = ((categoryCount + 1) * 42)px
  // Top + Bottom padding (in config) = 32px
  min-height: min(${p => (p.categoryCount + 1) * 42 + 32 * 2}px, 1500px);
  min-width: 400px;
`;

export const getOrganizationOptions = (
  provider: CurrentProviderQuery['currentProvider'],
  parentOrgId: number
): { id: number; label: string }[] => {
  return [
    { id: parentOrgId, label: 'All Campuses' },
    ...chain(provider.organizations)
      .filter(o => o.id === parentOrgId)
      .flatMap(o => o.children)
      .map(o => ({ id: o.id, label: o.name }))
      .value(),
  ];
};
