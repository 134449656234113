import { first, flatten } from 'lodash';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../../globalStyles';
import { cx } from '../../../utils';
import { Icon, IconKey, InlineSVG, InlineSVGProps } from '../../Icons';
import { baseInputStyles, InputProps, InvisibleInput } from '../Input';
import { InlineControls } from '../Shared';
import { Option, Value } from './types';

type SelectOptionProps = {
  width?: string;
  left?: string;
  right?: string;
  color?: string;
  backgroundColor?: string;
  activeColor?: string;
  activeBackgroundColor?: string;
  paddingUl?: string;
  paddingLi?: string;
};

export type SelectProps<T> = Pick<InputProps, 'placeholder'> & {
  value?: number | string | null;
  onChange: (val: Value) => void;
  onBlur?: () => void;
  options: Option<T>[] | Record<string, Option<T>[]>;
  clearable?: boolean;
  defaultValue?: Value;
  disabled?: boolean;
  hideKaret?: boolean;
  onSelect?: (v: Value) => void;
  renderOption?: RenderOption<T>;
  iconLeft?: IconKey;
  svgLeft?: InlineSVGProps;
  className?: string;
  error?: boolean;
  noResultsText?: string;
  name?: string;
  id?: string;
  maxResults?: number;
  style?: React.CSSProperties;
  optionStyles?: SelectOptionProps;
};
export function Select<T = undefined>({
  options,
  placeholder = 'Select...',
  clearable,
  value,
  onBlur,
  onChange,
  disabled,
  hideKaret,
  svgLeft,
  iconLeft,
  renderOption: RenderOpt = defaultRenderOption,
  className,
  noResultsText = 'No Results',
  maxResults = 500,
  error,
  name,
  id,
  style,
  optionStyles,
}: SelectProps<T>) {
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const [localValue, setLocalValue] = useState<string>();

  const groupedOptions = Array.isArray(options) ? { __ungrouped: options } : options;
  const flattenedOptions = flatten(Object.values(groupedOptions));
  const selected = flattenedOptions.find(opt => opt.id === value);
  const matchesLocalValue = (o: Option<T>) =>
    !localValue || o.label?.toLowerCase().includes(localValue.toLowerCase());
  const filteredGroups = Object.entries(groupedOptions).reduce(
    (acc, [header, group]: [string, Option<T>[]]) => ({
      ...acc,
      [header]: group.filter(matchesLocalValue),
    }),
    {} as Record<string, Option<T>[]>
  );

  const numOptions = flattenedOptions.filter(matchesLocalValue).length;
  const hasOptions = numOptions > 0;
  const hasTooManyOptions = numOptions > maxResults;

  const clear = () => {
    if (disabled) return;
    setLocalValue('');
    onChange(null);
  };

  const onFocus = () => {
    if (!localValue) setLocalValue('');
    setFocus(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLLIElement>) => {
    const isListItem = (e.relatedTarget as Node)?.parentNode === listRef.current;
    const isInput = e.relatedTarget === inputRef.current;
    if (isListItem || isInput) return;
    setLocalValue(undefined);
    setFocus(false);
    setOpen(false);
    onBlur?.();
  };

  const onSelect = (selectedId: Value) => {
    setLocalValue(undefined);
    onChange(selectedId);
    setOpen(false);
    inputRef.current?.focus();
  };

  const onOptionKeyDown = (e: React.KeyboardEvent<HTMLLIElement>, opt: Option<T>) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        return onSelect(opt.id);
      case 'ArrowDown':
        e.preventDefault();
        // @ts-ignore
        return e.currentTarget.nextSibling?.focus?.();
      case 'ArrowUp':
        e.preventDefault();
        // eslint-disable-next-line no-case-declarations
        const sibling = e.currentTarget.previousSibling;
        // @ts-ignore
        return sibling ? sibling.focus?.() : inputRef.current?.focus();
      default:
        if (e.key.length === 1) {
          e.preventDefault();
          inputRef.current?.focus();
          return setLocalValue(e.key);
        }
        break;
    }
  };

  return (
    <StyledSelectDiv
      style={style}
      className={cx({ focus, disabled, error }, className)}
      onClick={() => {
        if (disabled) return;
        inputRef.current?.focus();
        if (!open) setOpen(true);
      }}
    >
      {svgLeft && <InlineSVG width={16} height={16} className="nl2" {...svgLeft} />}
      {iconLeft && <Icon icon={iconLeft} size={18} className="nl2" />}
      <InvisibleInput
        autoComplete="off"
        name={name}
        id={id}
        data-baseweb="select" // for now since tests use it
        tabIndex={0}
        className="flex-auto"
        value={(open ? localValue ?? selected?.label : selected?.label) ?? ''}
        ref={inputRef}
        onChange={e => setLocalValue(e.target.value)}
        onFocus={onFocus}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={focus ? selected?.label ?? placeholder : placeholder}
        onClick={() => setOpen(true)}
        onKeyDown={e => {
          if (e.key === 'Tab') return;
          if (!disabled) setOpen(true);
          if (e.key === 'Enter' && hasOptions && !hasTooManyOptions) {
            const opt = first(first(Object.values(filteredGroups)));
            if (opt) onSelect(opt.id);
          }
          if (e.key === 'ArrowDown') {
            // @ts-ignore
            listRef.current?.firstChild?.focus?.();
            e.preventDefault();
          }
        }}
        aria-haspopup
      />
      <InlineControls
        value={value}
        disabled={disabled}
        onClear={clearable ? () => clear() : undefined}
        onClickKarat={
          hideKaret
            ? undefined
            : () => {
                if (disabled) return;
                setFocus(true);
                setOpen(true);
                inputRef.current?.focus();
              }
        }
      />
      {open && (
        <SingleSelectDropdown {...optionStyles}>
          {!hasOptions && <Text.bodyGrey className="pa3 tc">{noResultsText}</Text.bodyGrey>}
          {hasTooManyOptions && (
            <Text.bodyGrey className="pa3 tc">
              Please enter a more specific search term
            </Text.bodyGrey>
          )}
          {hasOptions && !hasTooManyOptions && (
            <ul role="listbox" ref={listRef}>
              {Object.entries(filteredGroups).map(([header, group], index) => (
                <React.Fragment key={header}>
                  {index > 0 && <hr className="ml3 mr3" />}
                  {header !== '__ungrouped' && (
                    <li key={header} className="group-header">
                      {header}
                    </li>
                  )}
                  {group.map((opt, optionIndex) => (
                    <li
                      className={cx('option', { active: opt.id === selected?.id })}
                      key={String(opt.id)}
                      role="option"
                      aria-disabled={false}
                      aria-label={opt.label}
                      aria-selected={opt.id === selected?.id}
                      tabIndex={-1}
                      onKeyDown={e => onOptionKeyDown(e, opt)}
                      onBlur={handleBlur}
                      onClick={() => onSelect(opt.id)}
                    >
                      <RenderOpt
                        option={opt}
                        isActive={opt.id === selected?.id}
                        isFirst={optionIndex === 0}
                      />
                    </li>
                  ))}
                </React.Fragment>
              ))}
            </ul>
          )}
        </SingleSelectDropdown>
      )}
    </StyledSelectDiv>
  );
}

export type RenderOption<T> = (props: {
  option: Option<T>;
  isActive: Boolean;
  isFirst: Boolean;
}) => JSX.Element;
const defaultRenderOption: RenderOption<any> = ({ option }) => <>{option.label}</>;

export const Dropdown = styled.div<SelectOptionProps>`
  top: 110%;
  max-height: 12rem;
  border-radius: 0.25rem;
  overflow-y: auto;
  position: absolute;
  z-index: 10;
  background-color: white;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
  transition-duration: 0.1s;
  transition-property: transform;
  width: ${({ width = `100%` }) => width};
  right: ${({ right = `inherit` }) => right};
  left: ${({ left = `0` }) => left};
`;

export const SingleSelectDropdown = styled(Dropdown)<SelectOptionProps>`
  & ul {
    list-style: none;
    padding: ${({ paddingUl = `0` }) => paddingUl};
    margin: 0;
  }

  & li {
    outline: none;

    &.group-header {
      padding: 0.5rem;
      font-weight: bold;
      cursor: default;
    }

    &.option {
      cursor: pointer;
      padding: ${({ paddingLi = `0.55rem 1rem` }) => paddingLi};
      &:hover,
      &:focus,
      &.selected {
        background-color: rgb(238, 238, 238);
      }
    }
  }

  .active {
    color: ${({ activeColor = `initial` }) => activeColor};
    background-color: ${({ activeBackgroundColor = `initial` }) => activeBackgroundColor};
  }
`;

export const StyledSelectDiv = styled.div`
  ${baseInputStyles}

  display:inline-flex;
  position: relative;
  cursor: text;
  gap: 0.5rem;

  /* still used by other select elements */
  & div.end {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    padding-right: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: default;
  }
`;
