import { useEffect } from 'react';
import { CareType } from '../../../graphQL';
import { useQueryParams } from '../../../Hooks';
import { useCurrentProvider } from '../../Permissions';

// Ensures a care type is always returned and query params update accordingly
export const useDefaultCareTypeParam = (defaultCareType: CareType = CareType.Psychiatry) => {
  const [{ careType, ...rest }, updateQueryParams] = useQueryParams<'careType'>();

  useEffect(() => {
    if (careType !== defaultCareType) {
      updateQueryParams({ ...rest, careType: defaultCareType });
    }
  }, [defaultCareType, rest, careType, updateQueryParams]);

  return careType as CareType;
};

export const useDefaultProviderOrgParam = () => {
  const { currentProvider } = useCurrentProvider();
  const [{ organizationId, ...rest }, updateQueryParams] = useQueryParams<'organizationId'>();
  const providerOrgId = currentProvider.organizations[0]?.id;

  useEffect(() => {
    if (providerOrgId && !organizationId) {
      updateQueryParams({ ...rest, organizationId: providerOrgId });
    }
  }, [providerOrgId, updateQueryParams, organizationId, rest]);

  return Number(organizationId) || undefined;
};
