import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useCurrentProvider } from '../Permissions';
import { mediaScreenFeatures } from './common';
import { VideoPopover } from './Popover';
import { ProviderVideoStream } from './ProviderVideoStream';
import { useVideoContextV2 } from './VideoProvider';

export const RootVideoOverlay = () => {
  const { currentProvider } = useCurrentProvider();
  const history = useHistory();
  const ctx = useVideoContextV2();

  const handleReturnToCall = () => {
    if (!ctx.sessionState) return;
    ctx.setState({ showingPopover: false });
    history.push(`/users/${ctx.sessionState.user.id}/video/${ctx.sessionState.id}`);
  };

  const showProviderVideo = !ctx.showingPopover && ctx.isSessionConnected;

  return (
    <>
      <StyledProviderView style={{ display: showProviderVideo ? undefined : 'none' }}>
        <ProviderVideoStream divId="providerView" providerId={currentProvider.id} />
        {ctx.additionalProviders.map(p => (
          <ProviderVideoStream key={p} providerId={p} divId={`provider-view-${p}`} />
        ))}
      </StyledProviderView>
      <VideoPopover handleReturnToCall={handleReturnToCall} />
    </>
  );
};

const StyledProviderView = styled.div`
  position: fixed;
  inset: 1rem 2rem 6rem auto;
  flex-wrap: wrap-reverse;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (${mediaScreenFeatures.mobile}) {
    inset: 1rem 0.75rem 6rem auto;
  }
`;
