import React, { useMemo } from 'react';
import { Moment } from 'moment-timezone';
import { modalFactory } from '../../../../Components/Modal';
import { FinalButton } from '../../../../Components/FinalButton';
import { SmallInput } from '../../../../Components/Form/Input';
import { ColorLookup, colorMap, Text } from '../../../../globalStyles';
import { useAdminUpdateStateAllocationsForDateMutation } from '../../../../graphQL';
import { states } from '../../../../states';
import { cx, TimeZone } from '../../../../utils';
import { UIStateAllocation } from '../WeekSummaryCell/WeekSummaryCell';
import * as util from '../../util';
import { UIProviderState } from '../Availability';
import { useEditStateAllocations } from '../Modal/Hooks/useEditStateAllocations';
import * as Styles from './Styles';

const Modal = modalFactory({
  style: { width: '540px', background: colorMap.background[0] },
});

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
  providerStates: UIProviderState[];
  date: Moment;
  timezone: TimeZone;
  providerStateAllocations: UIStateAllocation[];
  maxWeekHours: number;
  maxWeekIntakeHours: number;
}

export function SetProviderStateAvailabilityModal({
  onCancel,
  date,
  timezone,
  providerStates,
  onSuccess,
  providerStateAllocations,
  maxWeekHours,
  maxWeekIntakeHours,
}: Props) {
  const weekStateAllocations = useMemo(
    () => util.getStateAllocationsForWeekStartDate(date, providerStateAllocations, timezone),
    []
  );

  const initialStateAllocationsState = useMemo(
    () => util.prepInitialStateAllocations(date, weekStateAllocations, providerStates),
    []
  );

  const [updateAllocations, { loading }] = useAdminUpdateStateAllocationsForDateMutation();

  const [
    handleChange,
    { localStateAllocations, stateAllocationErrors, formErrors, hasErrors, hasChanged },
  ] = useEditStateAllocations({
    initialState: initialStateAllocationsState,
    maxWeekHours,
    maxWeekIntakeHours,
  });

  const usedPooledHours = useMemo(
    () => util.calculatedUsedPooledHours(Object.values(localStateAllocations)),
    [localStateAllocations]
  );

  // On save
  const handleUpdate = async () => {
    const allocationsToCreate = Object.values(localStateAllocations).filter(
      util.isCreateProviderStateAllocationsModelInput
    );
    const allocationsToUpdate = Object.values(localStateAllocations).filter(
      util.isUpdateProviderStateAllocationsModelInput
    );

    await updateAllocations({
      variables: {
        allocationsToCreate,
        allocationsToUpdate,
      },
    });
    onSuccess();
  };

  return (
    <Modal isOpen onClose={onCancel}>
      <Styles.ModalHeader>
        <Text.h3 kind="grayText">
          {date.format('M/D/YY')} - {date.clone().endOf('week').format('M/D/YY')}
        </Text.h3>
        <Text.h2>
          Pooled Availability: <br /> Sponsored Care Allocation
        </Text.h2>
        <Text.bodySmall>
          Allocate provider’s pooled availability hours for sponsored care for universities within a
          specific state.
        </Text.bodySmall>
        <Styles.FlexAlignCenter>
          <Text.h2 className="mr2" kind={getProgressColor(usedPooledHours, maxWeekHours)}>
            {`${usedPooledHours}/${maxWeekHours}`}
          </Text.h2>
          <Text.caption> of pooled hours dedicated to sponsored care</Text.caption>
        </Styles.FlexAlignCenter>
      </Styles.ModalHeader>
      <Styles.StateAllocationsGrid>
        <Text.bodyBold kind="black">State</Text.bodyBold>
        <Styles.FlexJustifyCenter>
          <Text.bodyBold kind="black">Spon Hours</Text.bodyBold>
        </Styles.FlexJustifyCenter>
        <Styles.FlexJustifyCenter>
          <Text.bodyBold kind="black">Spon Intakes</Text.bodyBold>
        </Styles.FlexJustifyCenter>
        {Object.keys(localStateAllocations).map((geoState, geoStateIndex) => {
          const stateAllocError = stateAllocationErrors[geoState];
          return (
            <React.Fragment key={geoStateIndex}>
              <Styles.FlexAlignCenter>
                <Text.body>{states[geoState]}</Text.body>
              </Styles.FlexAlignCenter>

              <Styles.FlexJustifyCenter>
                <SmallInput
                  name={`${geoState}-maxHours`}
                  className={cx({
                    error: stateAllocError && stateAllocError.maxHours,
                  })}
                  type="number"
                  min="0"
                  step="0.25"
                  placeholder="0"
                  value={localStateAllocations[geoState].maxHours || 0}
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </Styles.FlexJustifyCenter>
              <Styles.FlexJustifyCenter>
                <SmallInput
                  name={`${geoState}-maxIntakeHours`}
                  className={cx({
                    error: stateAllocError && stateAllocError.maxIntakeHours,
                  })}
                  type="number"
                  min="0"
                  step="1"
                  placeholder="0"
                  value={localStateAllocations[geoState].maxIntakeHours || 0}
                  onChange={handleChange}
                  onBlur={handleChange}
                />
              </Styles.FlexJustifyCenter>
            </React.Fragment>
          );
        })}
      </Styles.StateAllocationsGrid>
      <Styles.ModalBottomContainer>
        {Object.keys(stateAllocationErrors).map((geoState, geoStateErrorIndex) => (
          <React.Fragment key={geoStateErrorIndex}>
            {stateAllocationErrors[geoState].maxHours && (
              <Text.label kind="danger">{`${states[geoState]} Spon Hours: ${stateAllocationErrors[geoState].maxHours}`}</Text.label>
            )}
            {stateAllocationErrors[geoState].maxIntakeHours && (
              <Text.label kind="danger">{`${states[geoState]} Intakes: ${stateAllocationErrors[geoState].maxIntakeHours}`}</Text.label>
            )}
          </React.Fragment>
        ))}
        {Object.values(formErrors).map((error, errorIndex) => (
          <Text.label key={errorIndex} kind="danger">
            {error}
          </Text.label>
        ))}
        <FinalButton
          className="mb4 mt3"
          kind="primary"
          onClick={() => {
            handleUpdate();
          }}
          disabled={!hasChanged || hasErrors}
          loading={loading}
        >
          Save
        </FinalButton>
        <Text.linkButton kind="grayText" className="b" onClick={onCancel}>
          Cancel
        </Text.linkButton>
      </Styles.ModalBottomContainer>
    </Modal>
  );
}
function getProgressColor(usedPooledHours: number, maxWeekHours: number) {
  let hourBarGraphKind: ColorLookup;
  if (usedPooledHours < maxWeekHours) {
    hourBarGraphKind = 'green';
  } else if (usedPooledHours > maxWeekHours) {
    hourBarGraphKind = 'danger';
  } else {
    hourBarGraphKind = 'primary';
  }
  return hourBarGraphKind;
}
