import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../Components/Icons';
import { AsyncListView, AsyncListViewFetcher } from '../../Components/ListView/AsyncListView';
import { McpOnly, isMantraAdmin, useCurrentProvider } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import {
  AppView,
  Entitlement,
  OnDemandSession,
  useOnDemandCallReportsQuery,
  useOrganizationsListLazyQuery,
} from '../../graphQL';
import { hasEntitlement } from '../../utils';
import { OrganizationSmallGrayDisplay } from '../Organizations/OrganizationTopDisplay';
import { useShowOnDemandCallReports } from './useShowOnDemandCallReport';

type ReportHeaderProps = {
  organization: {
    name: string;
    abbreviation: string;
  };
};

const ReportHeader = ({ organization }: ReportHeaderProps) => {
  return (
    <>
      <McpOnly>
        <OrganizationSmallGrayDisplay organization={organization} />
      </McpOnly>
      <Text.h1 className="fw4">Call Reports: On-Demand Emotional Support</Text.h1>
      <Text.body className="mb2">
        Mantra’s in-house On-Demand Emotional Support and Crisis service operates daily from 12pm to
        12am ET.
      </Text.body>
    </>
  );
};

type OnDemandSessionData = {
  id: number;
  callStartTime?: Date | null;
  firstName: string;
  lastName: string;
  email: string;
  customerId: string;
  userId: number;
  orgName: string;
  orgId: number;
};

export function OnDemandCallReports() {
  const { currentProvider, appView } = useCurrentProvider();
  const history = useHistory();

  const [organizationsList, setOrganizationsList] = useState<any>([]);

  const shouldRedirect = !useShowOnDemandCallReports(); // Calling the hook at the top level

  useEffect(() => {
    if (shouldRedirect) {
      history.push('/'); // Using the value obtained from the hook inside useEffect
    }
  }, [shouldRedirect]);

  const showDdhLink =
    appView === AppView.Mcp &&
    hasEntitlement(currentProvider.organizations[0], Entitlement.DdhSync);

  const { refetch } = useOnDemandCallReportsQuery({ variables: { params: {} } });
  const [getOrganizationsList, { data: dataOrganizationsList }] = useOrganizationsListLazyQuery();

  useEffect(() => {
    const fetchOrganizationsList = async () => {
      if (isMantraAdmin(currentProvider)) {
        await getOrganizationsList();
      }
    };

    fetchOrganizationsList();
  }, [currentProvider]);

  if (dataOrganizationsList && organizationsList.length === 0) {
    const filteredOrganizations = dataOrganizationsList.organizations
      .filter(
        org =>
          org.entitlements &&
          org.entitlements.some(entitlement => entitlement.key === Entitlement.OnDemand)
      )
      .map(org => ({
        id: org.id,
        label: org.name,
      }));

    setOrganizationsList(filteredOrganizations);
  }

  const fetchData = useCallback(
    async (uiState, prevPage) => {
      const res = await refetch({
        params: {
          cursor: prevPage?.cursor,
          limit: uiState.limit,
          search: uiState.debouncedSearchValue,
          organizationId: uiState.filterValues?.organization,
        },
      });
      if (!res.data || !res.data.onDemandOrgSessions) throw new Error('Error fetching');

      const transformed = transformOdesSessions(res.data.onDemandOrgSessions.items);

      return {
        items: transformed,
        cursor: res.data.onDemandOrgSessions.cursor,
        hasMore: res.data.onDemandOrgSessions.hasMore,
        count: res.data.onDemandOrgSessions.count,
        limitCountExceeded: res.data.onDemandOrgSessions.limitCountExceeded,
      };
    },
    [refetch]
  );

  return (
    <div className="mw5 mw8-ns center pa2 pa4-ns">
      <ReportHeader organization={currentProvider.organizations[0]} />
      {showDdhLink && (
        <McpOnly>
          <div className="flex align-items mb4">
            <Text.link to="/crisisreports" kind="danger" className="b">
              Didi Hirsch 24/7 Crisis Reports
            </Text.link>
            <Icon icon="iconsExternalLinkRedSvg" size={16} className="ml2 mt1" />
          </div>
        </McpOnly>
      )}
      {isMantraAdmin(currentProvider) ? (
        <OzAdminListView fetchData={fetchData} organizationsFilter={organizationsList} />
      ) : (
        <McpListView fetchData={fetchData} />
      )}
    </div>
  );
}

type ListViewProps = {
  fetchData: AsyncListViewFetcher<OnDemandSessionData>;
  organizationsFilter?: any;
};

const formattedStartTime = (callStartTime: Date | undefined) => {
  const tz = moment.tz.guess();
  return `${moment(callStartTime, 'YYYY-MM-DD HH:mm:ssZ').tz(tz).format('M/D/YYYY hh:mm a z')}`;
};

const McpListView = ({ fetchData }: ListViewProps) => (
  <AsyncListView
    fetchData={fetchData}
    persist
    searchable
    searchPlaceholder="Search name, Mantra ID, or email"
    empty="No calls reported"
    pageSize={10}
    getKey={session => session.id}
    link={session => `/users/${session.userId}/?tab=files`}
    columns={[
      {
        title: 'Call Start Time',
        key: 'callStartTime',
        render: session => {
          return <Text.body>{formattedStartTime(session.callStartTime ?? undefined)} </Text.body>;
        },
      },
      {
        title: 'Student',
        key: 'role',
        render: session => {
          return (
            <div>
              <Text.body>
                {session.lastName}, {session.firstName}
              </Text.body>
              <Text.bodySmallGrey>{session.customerId}</Text.bodySmallGrey>
            </div>
          );
        },
      },
      {
        title: 'Email',
        key: 'email',
        render: session => session.email,
      },
    ]}
  />
);

const OzAdminListView = ({ fetchData, organizationsFilter }: ListViewProps) => (
  <AsyncListView
    fetchData={fetchData}
    persist
    pageSize={10}
    searchable
    searchPlaceholder="Search name, Mantra ID, or email"
    empty="No calls reported"
    getKey={session => session.id}
    link={session => `/users/${session.userId}/?tab=files`}
    columns={[
      {
        title: 'Call Start Time',
        key: 'callStartTime',
        render: session => (
          <Text.body>{formattedStartTime(session.callStartTime ?? undefined)}</Text.body>
        ),
      },
      {
        title: 'Student',
        key: 'student',
        render: session => {
          return (
            <div>
              <Text.body>
                {session.lastName}, {session.firstName}
              </Text.body>
              <Text.bodySmallGrey>{session.customerId}</Text.bodySmallGrey>
            </div>
          );
        },
      },
      {
        title: 'Email',
        key: 'email',
        render: session => session.email,
      },
      {
        title: 'Campus',
        key: 'campus',
        render: session => session.orgName,
      },
    ]}
    filters={[
      {
        key: 'organization',
        options: organizationsFilter,
        placeholder: 'All Campuses',
      },
    ]}
  />
);

export const transformOdesSessions = (
  odesSessions: OnDemandSession[] | undefined
): OnDemandSessionData[] => {
  if (!odesSessions) return [];

  return odesSessions
    .map(
      ({
        id,
        callStartTime,
        user: { firstName, lastName, email, customerId, id: userId, organization },
      }) => ({
        id,
        callStartTime,
        firstName,
        lastName,
        email,
        customerId,
        userId,
        orgName: organization?.name,
        orgId: organization?.id,
      })
    )
    .sort((prev, next) => {
      if (!prev.callStartTime || !next.callStartTime) return 0;
      return prev.callStartTime > next.callStartTime ? -1 : 1;
    });
};
