import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FinalButton } from '../../../Components/FinalButton';
import { Icon } from '../../../Components/Icons';
import { WizardRenderProps, WizardWrapper } from '../../../Components/Wizard';
import { colors, Text } from '../../../globalStyles';
import { getFullName } from '../../../modelUtils/users';
import { WizardData } from './types';

export function Success({ data }: WizardRenderProps<WizardData>) {
  const history = useHistory();
  const start = data.appointment!.startTime;
  const end = data.appointment!.endTime;
  return (
    <WizardWrapper data-cy="appointment-success">
      <div className="mt5">
        <Icon icon="iconsCircleCheckSvg" alt="Success" className="center db" size={26} />
        <Text.h2 className="mv3">Appointment booked!</Text.h2>
        <p className="mb3">Student will receive a confirmation email with appointment details.</p>
        <p className="mb3">
          This appointment will take place in the student’s Mantra Health account. Please advise
          student to complete onboarding before their appointment, including reviewing & agreeing to
          all releases.
        </p>
        <AppointmentCard className="mb3">
          <Text.bodySmall>{getFullName(data.user!)}</Text.bodySmall>
          <Text.bodySmall>
            {data.appointmentTemplate!.description} with {data.appointment!.provider!.name}
          </Text.bodySmall>
          <div className="flex flex-row gap-2">
            <Icon icon="iconsNavApptSvg" />
            <Text.bodySmall>
              {start.format('MM/DD/YYYY hh:mm a')} - {end.format('hh:mm a z')}
            </Text.bodySmall>
          </div>
        </AppointmentCard>
        <FinalButton
          kind="primary"
          className="w-100"
          onClick={() => history.push(`/users/${data.user!.id}`)}
        >
          Return to patient profile
        </FinalButton>
      </div>
    </WizardWrapper>
  );
}

const AppointmentCard = styled.div`
  padding: 24px;
  background: ${colors.greyBg};
  border: 1px solid ${colors.grey.border};
  border-radius: 4px;
`;
