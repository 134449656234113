import React from 'react';
import styled from 'styled-components';
import { Link } from '../Components/Links';
import { Text } from '../globalStyles';

export const PageTitle = styled(Text.h1)`
  font-size: 2rem;
`;

export const UnexpectedError = ({
  message,
  cannotRetry,
}: {
  message?: string;
  cannotRetry?: boolean;
}) => (
  <div className="h-100 flex flex-column justify-center items-center">
    <Text.h1>{message ?? 'Sorry, something went wrong.'}</Text.h1>
    {!cannotRetry && <Text.h2>Please try again.</Text.h2>}
  </div>
);
export const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;
