import { ModalBody, ModalHeader } from 'baseui/modal';
import { pick } from 'lodash';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FinalButton } from '../../Components/FinalButton';
import { CheckboxRHF, PhoneInputRHF } from '../../Components/Form';
import { PillLabel } from '../../Components/Labels';
import { modalFactory } from '../../Components/Modal';
import { Notification } from '../../Components/Notification';
import { Text } from '../../globalStyles';
import {
  Provider,
  TwoFactorConfig,
  useAdminProviderTwoFactorConfigQuery,
  useAdminSetTwoFactorConfigMutation,
} from '../../graphQL';
import { Nullable } from '../../types';
import { formatPhoneNumber, stripPhoneNumber } from '../../utils';
import { useProviderDrilldownContext } from './context';

export const Authentication = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { provider, refetch } = useProviderDrilldownContext();

  const { data: twoFactorData, refetch: refetchTwoFactor } = useAdminProviderTwoFactorConfigQuery({
    variables: { providerId: Number(provider.id) },
    fetchPolicy: 'no-cache',
  });

  const twoFactorConfig = twoFactorData?.adminProvider.twoFactorConfig;

  return (
    <div className="ph5 pv3">
      {modalOpen && (
        <UpsertConfigModal
          twoFactorConfig={twoFactorConfig}
          onClose={() => setModalOpen(false)}
          refetch={() => {
            refetchTwoFactor();
            refetch();
          }}
          provider={provider}
        />
      )}
      <div className="flex flex-row items-center">
        <Text.h3 className="mr3">Provider Two Factor Authentication</Text.h3>
        {twoFactorConfig && (
          <PillLabel>
            <Text.label>{twoFactorConfig.enabled ? 'Enabled' : 'Disabled'}</Text.label>
          </PillLabel>
        )}
      </div>
      {twoFactorConfig && (
        <div className="mb2">
          <Text.label>Phone</Text.label>
          <Text.body>{formatPhoneNumber(twoFactorConfig.phone)}</Text.body>
          {!twoFactorConfig.phoneVerified && (
            <Text.caption className="i">Pending Provider Verification</Text.caption>
          )}
        </div>
      )}
      <Text.linkButton onClick={() => setModalOpen(true)}>
        {twoFactorConfig ? 'Edit' : '+ Add'}
      </Text.linkButton>
    </div>
  );
};

type UpsertProps = {
  provider: Pick<Provider, 'id'>;
  twoFactorConfig: Nullable<Pick<TwoFactorConfig, 'enabled' | 'phone'>>;
  onClose: () => void;
  refetch: () => void;
};

type TwoFactorConfigForm = Pick<TwoFactorConfig, 'enabled' | 'phone'>;

export const UpsertConfigModal = ({ twoFactorConfig, onClose, refetch, provider }: UpsertProps) => {
  const type = twoFactorConfig ? 'Edit' : 'Add';

  const [setPhone, { error, loading }] = useAdminSetTwoFactorConfigMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted: () => refetch(),
  });

  const form = useForm<TwoFactorConfigForm>({
    defaultValues: pick(twoFactorConfig, ['phone', 'enabled']),
  });

  const submit = form.handleSubmit(async ({ phone, enabled }) => {
    await setPhone({
      variables: {
        phone: stripPhoneNumber(phone),
        providerId: provider.id,
        enabled: Boolean(enabled),
      },
    });
    onClose();
  });

  const Modal = modalFactory({});

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader>
        <Text.body>{type} Provider 2-Factor Configuration</Text.body>
      </ModalHeader>
      <ModalBody>
        {error && <Notification kind="negative">{error.message}</Notification>}
        <FormProvider {...form}>
          <form onSubmit={submit}>
            <CheckboxRHF name="enabled" controlProps={{ label: 'Enabled', labelSize: 'f6' }} />
            <PhoneInputRHF name="phone" controlProps={{ label: 'Mobile Phone', labelSize: 'f6' }} />
            <FinalButton loading={loading} className="w-100" type="submit">
              Save
            </FinalButton>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};
