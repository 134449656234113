import React from 'react';
import { Modal, ModalBody } from 'baseui/modal';
import { SamlConfigurationType } from '../../../graphQL';
import { Text } from '../../../globalStyles';
import { FinalButton } from '../../../Components/FinalButton';

const SamlTypeLabels = {
  [SamlConfigurationType.User]: 'Patient',
  [SamlConfigurationType.Provider]: 'MCP User',
};

export const SsoConfirmationModal = ({
  isOpen,
  samlType,
  onClose,
  onSave,
}: {
  isOpen: boolean;
  samlType: SamlConfigurationType;
  onClose: () => void;
  onSave: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBody className="pa4">
        <Text.h1Bold>Confirm</Text.h1Bold>
        <Text.body className="mb2">
          You are making changes to the <Text.span>{SamlTypeLabels[samlType]}</Text.span>{' '}
          configuration.
        </Text.body>
        <FinalButton onClick={onSave}>{`Save ${SamlTypeLabels[samlType]} changes`} </FinalButton>
      </ModalBody>
    </Modal>
  );
};
