import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { buttonKinds, buttonSizes, FinalButton } from '../../Components/FinalButton';
import { InputRHF, RenderOption, SelectRHF, ToggleRHF } from '../../Components/Form';
import { Grid } from '../../Components/Grid';
import { Icon, icons } from '../../Components/Icons';

export const DebugButton = () => {
  const formContext = useForm({
    defaultValues: {
      children: 'click me',
      kind: buttonKinds[0],
      size: buttonSizes[2],
      icon: null,
      pill: false,
      disabled: false,
      loading: false,
    },
  });

  const values = formContext.watch();
  const btn = <FinalButton {...values} />;

  return (
    <Grid gap="4rem" gridTemplateColumns="20rem 16rem">
      <FormProvider {...formContext}>
        <div className="ba bg-white pa3 br2">
          <h3 className="f3 mb4">Config</h3>
          <SelectRHF
            controlProps={{ label: 'button kind' }}
            name="kind"
            options={buttonKinds.map(k => ({ id: k, label: k }))}
          />

          <SelectRHF
            controlProps={{ label: 'size' }}
            name="size"
            options={buttonSizes.map(k => ({ id: k, label: k }))}
          />
          <SelectRHF
            controlProps={{ label: 'Left Icon' }}
            name="iconLeft"
            clearable
            options={iconOptions}
            renderOption={renderIconOption}
          />
          <SelectRHF
            controlProps={{ label: 'Right Icon' }}
            name="iconRight"
            clearable
            options={iconOptions}
            renderOption={renderIconOption}
          />
          <InputRHF
            controlProps={{ label: 'button text' }}
            name="children"
            placeholder="edit me please"
          />
          <Grid gridTemplateColumns="1fr 1fr">
            <ToggleRHF name="pill">Pill</ToggleRHF>
            <ToggleRHF name="disabled">Disabled</ToggleRHF>
            <ToggleRHF name="loading">Loading</ToggleRHF>
          </Grid>
        </div>
      </FormProvider>
      <div>
        <div className="ba br2 pa3 bg-white">
          <h3 className="f3 mb5 ">Result</h3>
          <p>left justified</p>
          <div className="mb3">{btn}</div>
          <p>center justified</p>
          <div className="flex flex-column">{btn}</div>
        </div>
      </div>
    </Grid>
  );
};

const iconOptions = Object.keys(icons).map(k => ({ id: k, label: k }));

const renderIconOption: RenderOption<any> = ({ option }) => (
  <div className="flex items-center gap-2">
    <Icon icon={option.id as any} size={16} />
    {option.label}
  </div>
);
