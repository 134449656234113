import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormControl, RadioRHF } from '../../Components/Form';
import { Grid } from '../../Components/Grid';
import { InlineSVG, SVGs } from '../../Components/Icons';
import { ColorLookup, colorMap } from '../../globalStyles';
import { getColorByBgColor } from './DebugIcons';

const options = [
  'black',
  'primary',
  'danger',
  'success',
  'grayText',
  'warning',
  'white',
] as ColorLookup[];

export const DebugSVGs = () => {
  const iconsArr = Object.keys(SVGs);

  const form = useForm({
    defaultValues: { kind: 'black' as ColorLookup, size: 36, bg: colorMap.background[0] },
  });
  const { kind, bg, size } = form.watch();

  return (
    <Grid gridTemplateColumns="12rem 1fr">
      <FormProvider {...form}>
        <div>
          <FormControl label="BG" name="bg">
            <input ref={form.register()} name="bg" title="background color" type="color" />
          </FormControl>
          <FormControl label="Size" name="size">
            <input type="range" name="size" min={1} max={164} ref={form.register()} />
          </FormControl>
          <RadioRHF
            withLabel
            controlProps={{ label: 'Kind' }}
            name="kind"
            options={options.map(id => ({ label: id, id }))}
          />
        </div>
      </FormProvider>
      <div
        className="flex flex-wrap gap-3 pa4"
        style={{ backgroundColor: bg, color: getColorByBgColor(bg) }}
      >
        {iconsArr.map(name => {
          return (
            <div key={name} className="flex flex-column items-center pa3">
              <InlineSVG icon={name as any} kind={kind} width={size} height={size} />
              <pre className="mv0 f5">{name}</pre>
            </div>
          );
        })}
      </div>
    </Grid>
  );
};
