import * as mixpanel from 'mixpanel-browser';
import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTrackMutation } from '../../graphQL';
import { isLoggedIn, uuid } from '../../utils';
import { useClickCounter } from '../MedicalNote/hooks';

export { events } from '../../events';

export const MIXPANEL_KEY = process.env.REACT_APP_MIXPANEL_KEY;

type Tracker = (event: string, data?: Record<string, any>) => void;
export const EventsContext = createContext<{ track: Tracker } | null>(null);

export const EventsProvider: React.FC<{}> = ({ children }) => {
  const { resetClickCount } = useClickCounter();
  const tracker = useRef({ sessionId: `${new Date().getTime()}-${uuid()}`, num: 0 });
  const { pathname } = useLocation();
  const [pushEvent] = useTrackMutation({ ignoreResults: true });

  const track = useCallback(
    (event: string, data?: Record<string, any>) => {
      const tag = `provider-dashboard.${event}`;
      // eslint-disable-next-line no-console
      // if (process.env.NODE_ENV === 'development') console.log(tag, data);
      if (MIXPANEL_KEY) mixpanel.track(tag, data);
      // only sent authenticated data to the backed
      if (isLoggedIn()) pushEvent({ variables: { tag, data } });
    },
    [pushEvent]
  );

  useEffect(() => {
    if (MIXPANEL_KEY) mixpanel.init(MIXPANEL_KEY);
  }, []);

  useEffect(() => {
    const cachedTracker = tracker.current; // gotta do this or CRA complains
    const { sessionId, num } = cachedTracker;

    const visitTime = new Date();
    const visitId = `${visitTime.getTime()}-${uuid()}`;
    const simplePath = simplifyPathname(pathname);

    track(`visit.path`, { pathname, simplePath, visitId, sessionId, num });

    return () => {
      const timeSpentMs = new Date().getTime() - visitTime.getTime();
      const clicks = resetClickCount();
      track('exit.path', { pathname, simplePath, visitId, timeSpentMs, clicks, sessionId, num });
      cachedTracker.num += 1;
    };
  }, [pathname, track, resetClickCount]);

  return <EventsContext.Provider value={{ track }}>{children}</EventsContext.Provider>;
};

export const useEvents = () => {
  const context = useContext(EventsContext);
  if (!context) throw new Error('Context not initialized');
  return context;
};

// for metrics, want to merge paths with url paramaters
// ex: user/34 and user/55 -> user/#
export const simplifyPathname = (pathname: string) =>
  pathname
    .replace(/[^a-z/]/gi, '')
    .split('/')
    .map((v, i) => (i === 0 ? '' : v || '#'))
    .join('/');
