import {
  Modal as BaseModal,
  ModalOverrides,
  ModalProps as BaseModalProps,
  ROLE,
} from 'baseui/modal';
import { clearAllBodyScrollLocks, enableBodyScroll } from 'body-scroll-lock';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { HELVETICA } from '../globalStyles/text';
import { borderRadius, cx, padding } from '../utils';
import { InlineSVG } from './Icons';

const MantraPortal: React.FC<{ containerId: string }> = ({ children, containerId }) => {
  const [containerEl, setContainerEl] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let elementSystemCreated = false;
    const el = window.document.getElementById(containerId);
    if (el) {
      setContainerEl(el);
    }
    // element does not already exist, create it automatically
    else {
      elementSystemCreated = true;
      // create a wrapper and append to the body
      const systemCreatedContainerEl = window.document.createElement('div');
      systemCreatedContainerEl.setAttribute('id', containerId);
      document.body.appendChild(systemCreatedContainerEl);
      setContainerEl(systemCreatedContainerEl);
    }
    return () => {
      if (elementSystemCreated) {
        el?.parentNode?.removeChild(el);
      }
    };
  }, [containerId]);

  return containerEl ? createPortal(children, containerEl) : null;
};

type ModalSize = 's' | 'm' | 'l';

type ModalProps = Omit<
  BaseModalProps,
  'closeable' | 'animate' | 'size' | 'role' | 'overrides' | 'unstable_ModalBackdropScroll'
>;

export const modalFactory = (dialogOverrides: ModalOverrides['Dialog']): React.FC<ModalProps> => {
  return ({ children, ...props }) => (
    <BaseModal
      closeable
      animate
      size="auto"
      role={ROLE.dialog}
      unstable_ModalBackdropScroll
      overrides={{ Dialog: dialogOverrides }}
      {...props}
    >
      {children}
    </BaseModal>
  );
};

export const GenericSmallModal = modalFactory({
  style: { ...borderRadius('4px'), ...padding('2rem'), paddingTop: '3rem', maxWidth: '24rem' },
});

export const MantraModalHeaderOld = styled.div`
  margin: 2rem 1.5rem 1.5rem 1.5rem;
  font-size: 1.5rem;
  font-family: ${HELVETICA};
  font-weight: bold;
`;

export const MantraModalBodyOld = styled.div`
  margin: 1rem 1.5rem 1rem 1.5rem;
  font-size: 0.875rem;
  font-family: ${HELVETICA};
`;

type MantraModalProps = {
  isOpen?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string | JSX.Element;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
  style?: React.CSSProperties;
  size?: ModalSize;
};

export const MantraModal = ({
  isOpen,
  onClose,
  children,
  title,
  className,
  bodyClassName,
  headerClassName,
  style,
  size = 'm',
}: MantraModalProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const closeWhenClickedOutsideModal = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!modalRef?.current?.contains(e.target as any)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen && modalRef.current) {
      enableBodyScroll(modalRef.current);
    } else if (!isOpen) {
      clearAllBodyScrollLocks();
    }
  }, [modalRef, isOpen]);

  return (
    <MantraPortal containerId="mantra-modal">
      <ModalBackdrop onClick={closeWhenClickedOutsideModal} className={cx(isOpen && 'modal-open')}>
        <MantraModalContainer
          ref={modalRef}
          className={cx(className, !isOpen && 'dn')}
          style={style}
          modalSize={size}
        >
          <InlineSVG
            className="pointer absolute right-1 top-1"
            icon="x"
            width={19}
            height={19}
            onClick={() => onClose()}
          />
          {title && (
            <MantraModalHeader className={headerClassName} modalSize={size}>
              {typeof title === 'string' ? <p>{title}</p> : title}
            </MantraModalHeader>
          )}
          <MantraModalBody modalSize={size} className={bodyClassName}>
            {children}
          </MantraModalBody>
        </MantraModalContainer>
      </ModalBackdrop>
    </MantraPortal>
  );
};

const mantraSizeContainerStyleMap: Record<ModalSize, FlattenSimpleInterpolation> = {
  s: css`
    padding: 1.75rem;
    max-width: 30rem;
  `,
  m: css`
    padding: 2.5rem 2.125rem;
    max-width: 36rem;
  `,
  l: css`
    padding: 2.5rem;
    max-width: 43rem;
  `,
};

const mantraSizeHeaderStyleMap: Record<ModalSize, FlattenSimpleInterpolation> = {
  s: css`
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
  `,
  m: css`
    margin-bottom: 1.75rem;
    font-size: 1.85rem;
  `,
  l: css`
    margin-bottom: 2.125rem;
    font-size: 2.125rem;
  `,
};

const mantraSizeBodyStyleMap: Record<ModalSize, FlattenSimpleInterpolation> = {
  s: css`
    font-size: 0.75rem;
  `,
  m: css`
    font-size: 0.875rem;
  `,
  l: css`
    font-size: 1rem;
  `,
};

const ModalBackdrop = styled.div`
  background: transparent;
  opacity: 0;
  &.modal-open {
    position: fixed;
    inset: 0;
    opacity: 1;
    overflow: auto;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: opacity 0.1s ease;
    // center the modal
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const MantraModalContainer = styled.div<{ modalSize: ModalSize }>`
  ${({ modalSize }) => mantraSizeContainerStyleMap[modalSize]};
  width: 100%;
  max-height: calc(100vh - 1rem);
  overflow-y: scroll;
  background: white;
  position: relative;
`;

const MantraModalHeader = styled.div<{ modalSize: ModalSize }>`
  ${({ modalSize }) => mantraSizeHeaderStyleMap[modalSize]};
  font-family: ${HELVETICA};
  font-weight: bold;
`;

const MantraModalBody = styled.div<{ modalSize: ModalSize }>`
  ${({ modalSize }) => mantraSizeBodyStyleMap[modalSize]};
  font-family: ${HELVETICA};
`;
