import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { CampusTeamActivity } from '../../Components/CampusTeam/CampusTeamActivity';
import { UniversityLogo } from '../../Components/Organization/UniversityLogo';
import {
  isSupportingClinician,
  isUniversityReferrer,
  isUniversityStaff,
  useCurrentProvider,
} from '../../Components/Permissions';
import { mediaScreenFeatures } from '../../Components/Video/common';
import { When } from '../../Components/When';
import { PaddedPage, Text } from '../../globalStyles';
import { TwoFactorVerifyWidget } from '../Home/Widgets/TwoFactorVerifyWidget';
import { WidgetContainer } from './styles';
import { ProviderNetworkWidget } from './Widgets/ProviderNetworkWidget';
import { ReferStudentWidget } from './Widgets/ReferStudentWidget';
import { UnreadMessagesWidget } from './Widgets/UnreadMessagesWidget';

export function CounselorDashboard() {
  const { currentProvider } = useCurrentProvider();

  const isReferrer = isUniversityReferrer(currentProvider);
  if (isReferrer) {
    return <ReferrerDashboard providerName={currentProvider.name} />;
  }

  return <NewCounselorDashboard />;
}

function DashboardHeader({ providerName }: { providerName: string }) {
  const now = moment();
  const nowHour = now.hour();

  let timeOfDay = 'Evening';
  if (nowHour >= 5 && nowHour < 12) {
    timeOfDay = 'Morning';
  } else if (nowHour >= 12 && nowHour < 17) {
    timeOfDay = 'Afternoon';
  }

  return (
    <DashboardTopDiv>
      <DashboardTopWrapperDiv>
        <UniversityLogo right="3em" />
        <Text.h1>
          Good {timeOfDay}, {providerName}
        </Text.h1>
        <Text.bodySmall kind="grayText">{now.format('dddd, MMMM D, YYYY, h:mma')}</Text.bodySmall>
      </DashboardTopWrapperDiv>
    </DashboardTopDiv>
  );
}

function TopWidgets() {
  return (
    <DashboardBodyDiv>
      <DashboardLeftDiv flex={1}>
        <ReferStudentWidget />
      </DashboardLeftDiv>

      <DashboardRightDiv flex={2}>
        <ProviderNetworkWidget />
      </DashboardRightDiv>
    </DashboardBodyDiv>
  );
}

function ReferrerDashboard({ providerName }: { providerName: string }) {
  return (
    <DashboardContainerDiv>
      <TwoFactorVerifyWidget />
      <DashboardHeader providerName={providerName} />

      <TopWidgets />
    </DashboardContainerDiv>
  );
}

export function NewCounselorDashboard() {
  const { currentProvider } = useCurrentProvider();

  const isCollaborator = isSupportingClinician(currentProvider);
  const isStaff = isUniversityStaff(currentProvider);

  return (
    <DashboardContainerDiv>
      <DashboardHeader providerName={currentProvider.name} />

      <TwoFactorVerifyWidget />

      <When isTruthy={isStaff}>
        <DashboardBodyDiv>
          <UnreadMessagesWidget />
        </DashboardBodyDiv>
      </When>

      <When isTruthy={!isCollaborator}>
        <TopWidgets />
      </When>

      <DashboardBodyDiv>
        <DashboardFullDiv>
          <WidgetContainer>
            <CampusTeamActivity />
          </WidgetContainer>
        </DashboardFullDiv>
      </DashboardBodyDiv>
    </DashboardContainerDiv>
  );
}

const dashboardMaxWidth = 1400;

const DashboardContainerDiv = styled.div``;
const DashboardTopDiv = styled(PaddedPage)`
  background-color: white;
  padding-top: 3rem;
  padding-right: 0;
  padding-bottom: 2.5rem;
`;
const DashboardTopWrapperDiv = styled.div`
  max-width: calc(${dashboardMaxWidth}px - 3rem);
  position: relative;

  @media only screen and (${mediaScreenFeatures.mobile}) {
    img {
      display: none;
    }
  }
`;
const DashboardBodyDiv = styled(PaddedPage)`
  display: flex;
  justify-content: space-between;
  max-width: ${dashboardMaxWidth}px;
  padding-top: 0;
  padding-bottom: 0;

  @media only screen and (${mediaScreenFeatures.tablet}) {
    flex-direction: column;
  }
`;

type DashboardColumnProps = {
  flex?: number;
};
const DashboardLeftDiv = styled.div<DashboardColumnProps>`
  display: flex;
  flex: ${({ flex = '0 1 auto' }) => flex};
  flex-flow: column;
`;
const DashboardRightDiv = styled.div<DashboardColumnProps>`
  display: flex;
  flex: ${({ flex = '0 1 auto' }) => flex};
  flex-flow: column;
`;
const DashboardFullDiv = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
`;
