import styled from 'styled-components';
import { colorMap, colors } from '../../../../globalStyles';

export const ModalHeader = styled.div`
  padding: 50px 50px 30px 50px;
  background-color: ${colorMap.background[0]};
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexJustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const StateAllocationsGrid = styled.div`
  padding: 30px 50px 30px 50px;
  background-color: ${colors.white};
  display: grid;
  grid-template-columns: 180px 110px 110px;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
`;

export const ModalBottomContainer = styled.div`
  padding: 0 50px 50px 50px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`;
