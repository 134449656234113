import React from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';
import {
  hasSupportingClinicianRole,
  hasReferrerRole,
  OrgWithEntitlements,
} from './organizationUtils';

type Props = {
  organization: OrgWithEntitlements;
};

export const OrganizationStaffRoles = ({ organization }: Props) => (
  <Container>
    <Text.h3>Account Types</Text.h3>
    <AccountTypeSection>
      <Text.bodyBold>Staff</Text.bodyBold>
      <Text.body>Can refer new students AND collaborate.</Text.body>
    </AccountTypeSection>
    {hasReferrerRole(organization) && (
      <AccountTypeSection>
        <Text.bodyBold>Referrer</Text.bodyBold>
        <Text.body>Can refer new students, but cannot collaborate or see patient data.</Text.body>
      </AccountTypeSection>
    )}
    {hasSupportingClinicianRole(organization) && (
      <AccountTypeSection>
        <Text.bodyBold>Supporting Clinician</Text.bodyBold>
        <Text.body>Can collaborate and see patient data, but cannot refer students.</Text.body>
      </AccountTypeSection>
    )}
    <AccountTypeSection>
      <Text.bodyBold>Admin</Text.bodyBold>
      <Text.body>Can collaborate, refer students, and add new university roles.</Text.body>
    </AccountTypeSection>
  </Container>
);

const Container = styled.div`
  padding: 2rem;
  border: 1px solid ${colors.grey.border};
  border-radius: 0.25rem;
  height: fit-content;
  max-width: 23.5rem;
`;

const AccountTypeSection = styled.div`
  padding: 1.25rem 0;
  :not(:last-child) {
    border-bottom: 1px solid ${colors.grey.border};
  }
`;
