import { Entitlement, Organization, OrganizationEntitlement } from '../../graphQL';
import { hasEntitlement } from '../../utils';

export type OrgWithEntitlements<OrgKey extends keyof Organization | undefined = undefined> =
  (OrgKey extends keyof Organization ? Pick<Organization, OrgKey> : {}) & {
    entitlements: Pick<OrganizationEntitlement, 'key'>[];
  };

export const hasReferrerRole = (org: OrgWithEntitlements) => {
  return org.entitlements.some(e => e.key === Entitlement.ReferrerRole);
};

export const hasSupportingClinicianRole = (org: OrgWithEntitlements) => {
  return org.entitlements.some(e => e.key === Entitlement.SupportingClinicianRole);
};

export const hasDedicatedGroupModel = (org: OrgWithEntitlements) => {
  return org.entitlements.some(e => e.key === Entitlement.DedicatedGroupModel);
};

export const hasSessionsModel = (org: OrgWithEntitlements) => {
  return org.entitlements.some(e => e.key === Entitlement.SessionsModel);
};

export const hasConnectNow = (org: OrgWithEntitlements) => {
  return hasEntitlement(org, Entitlement.ConnectNow);
};

export const hasDdh = (org: OrgWithEntitlements) => {
  return hasEntitlement(org, Entitlement.DdhSync);
};
