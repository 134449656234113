import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FinalButton } from '../../../Components/FinalButton';
import { useCurrentProvider } from '../../../Components/Permissions';
import { mediaScreenFeatures } from '../../../Components/Video/common';
import { Text } from '../../../globalStyles';
import { WidgetContainer } from '../styles';

export function ReferStudentWidget() {
  const history = useHistory();
  const { currentProvider } = useCurrentProvider();

  const organizationId = currentProvider.organizations[0].id;

  return (
    <ReferStudentContainerDiv>
      <Text.h3 className="mt4 mr4 mb0">Refer Student</Text.h3>
      <FinalButton
        className="mt4 mr4"
        iconLeft="iconsWhitePlusSvg"
        onClick={() => history.push(`/organizations/${organizationId}/students/enroll`)}
      >
        Add Student
      </FinalButton>
    </ReferStudentContainerDiv>
  );
}

const ReferStudentContainerDiv = styled(WidgetContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 0;
  padding-right: 0;

  @media only screen and (${mediaScreenFeatures.tablet}) {
    align-items: center;
    flex-direction: row;

    h3 {
      flex: 3;
    }
  }

  @media only screen and (${mediaScreenFeatures.mobile}) {
    align-items: stretch;
    flex-direction: column;
  }
`;
