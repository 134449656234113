import { isEmpty } from 'lodash';
import { matchPath } from 'react-router-dom';
import { useCurrentRouteSearch } from '../../../../routes';

type UseLoadEmbedUidResult = {
  error?: Error;
  healthieId?: string;
};

export const useParseHealthieId = (): UseLoadEmbedUidResult => {
  // Gets the embed UID from the parent frame URL and redirects using it.
  const searchParams = useCurrentRouteSearch();

  const referrer = searchParams.referrer_url ?? '';
  if (!referrer) {
    return { error: new Error('Invalid Healthie patient reference') };
  }

  let referrerUrl;
  try {
    referrerUrl = new URL(referrer);
  } catch (urlError) {
    return { error: new Error('Invalid Healthie patient reference') };
  }

  // Parse the userId out of the referrerUrl.
  const pathMatch = matchPath<{ userId: string }>(referrerUrl.pathname, {
    path: '/users/:userId',
    exact: false,
    strict: false,
  });

  const parentUserId = pathMatch?.params?.userId ?? '';
  if (isEmpty(parentUserId)) {
    return { error: new Error('Invalid Healthie patient reference') };
  }

  return { healthieId: parentUserId };
};
