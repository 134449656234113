import { isEqual } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { colorMap, colors, Text } from '../../../globalStyles';
import { ProviderFragment } from '../../../graphQL';
import { getInitials } from '../../../modelUtils/provider';
import { useDrilldownContext } from '../../../Pages/Users/helpers';
import { FinalButton } from '../../FinalButton';
import { Icon } from '../../Icons';
import { useCurrentProvider } from '../../Permissions';
import { MessageContainer } from '../Styles';
import { Message } from './Messaging';

type Props = {
  messages: Message[];
  provider?: ProviderFragment;
};

export const MessagingView = React.memo(
  ({ messages, provider }: Props) => {
    const scrollContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const { current } = scrollContainer;
      if (!current) return;
      current.scrollTop = current.scrollHeight;
    }, [messages]);

    return (
      <Container ref={scrollContainer}>
        {messages.length === 0 && <p className="tc i f6">No message history</p>}
        {messages.map(m => (
          <React.Fragment key={m.id}>
            <MessageBubble message={m} provider={provider} />
          </React.Fragment>
        ))}
      </Container>
    );
  },
  (a, b) =>
    isEqual(
      a.messages.map(i => i.id),
      b.messages.map(i => i.id)
    )
);

const MessageBubble = ({
  message,
  provider,
}: {
  message: Props['messages'][number];
  provider?: ProviderFragment;
}) => {
  const { currentProvider } = useCurrentProvider();
  const { user } = useDrilldownContext();
  const { userId, content, createdAt } = message;
  const time = moment(createdAt).format('LT l');
  const incoming = !!userId;

  const userCard = <SenderIcon>{getInitials(user)}</SenderIcon>;
  const providerCard = <SenderIcon>{getInitials(provider || currentProvider)}</SenderIcon>;

  return (
    <>
      {message.content && (
        <div className={`mb3 flex items-center justify-${incoming ? 'start' : 'end'}`}>
          {incoming && userCard}
          <StyledBubble className={incoming ? 'in' : 'out'}>
            <MessageContainer className="f5 mb2">{content}</MessageContainer>
            <Text.bodySmall kind="grayText" className={incoming ? 'tl' : 'tr'}>
              {time}
            </Text.bodySmall>
          </StyledBubble>
          {!incoming && providerCard}
        </div>
      )}
      {message.attachments.map(attach => (
        <div
          key={attach.id}
          className={`mb3 flex items-center justify-${incoming ? 'start' : 'end'}`}
        >
          {incoming && userCard}
          <StyledBubble className={incoming ? 'in' : 'out'}>
            <div className="flex flex-row items-center">
              <Icon icon="iconsFileSvg" className="mr2" />
              <Text.body className="mr2">{attach.name}</Text.body>

              <FinalButton
                onClick={() => window.open(attach.url, '_blank')}
                kind="minimal_black"
                size="tiny"
                iconLeft="iconsDownloadSvg"
              />
            </div>
            <Text.caption kind="grayText" className={incoming ? 'tl' : 'tr'}>
              {time}
            </Text.caption>
          </StyledBubble>
          {!incoming && providerCard}
        </div>
      ))}
    </>
  );
};

const SenderIcon = styled.div`
  border-radius: 50vmin;
  text-transform: uppercase;
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${colors.grey.light};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  border: 1px solid ${colors.grey.light};
  border-radius: 0.25rem;
  padding: 2rem 1rem;

  overflow-y: scroll;
  max-height: 35rem;
`;

const StyledBubble = styled.div`
  padding: 0.75rem;
  border-radius: 0.5rem;
  max-width: 80%;
  margin: 0rem 1rem;

  &.in {
    background-color: ${colors.grey.light};
    border-bottom-left-radius: 0;
  }
  &.out {
    background-color: ${colorMap.primary[6]};
    border-bottom-right-radius: 0;
  }
  &.attach {
    margin-top: 1rem;
  }
`;
