import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { ColorLookup, colors, Text } from '../../globalStyles';
import { useCampusTeamOptionsQuery } from '../../graphQL';
import { CampusTeamMember } from '../../Pages/Users/EditModals/CampusTeamMember';
import { MantraAccordion, MantraAccordionPanel } from '../Accordion';
import { Grid } from '../Grid';
import { GridSpan } from '../GridSpan';
import { InlineSVG } from '../Icons';
import { Notification } from '../Notification';
import { useCurrentProvider } from '../Permissions';
import { SearchAndAdd } from '../SearchAndAdd/SearchAndAdd';
import { EditCampusTeamMember, Setter } from './types';

type MonitorType = 'referrer' | 'monitor' | 'collaborator';
type Faq = { title: string; content: string; viewers: MonitorType[] };

const faq: Faq[] = [
  {
    title: 'Track referral completion',
    content: 'Visibility of scheduled and completed intake sessions for students you refer.',
    viewers: ['referrer', 'monitor', 'collaborator'],
  },
  {
    title: 'Emails for high or imminent risk',
    content: `Receive email alerts when a student's risk level changes to 'high' or 'imminent'.`,
    viewers: ['monitor', 'collaborator'],
  },
  {
    title: 'Track no-shows and status changes',
    content: 'View student no-shows and changes in care status.',
    viewers: ['monitor', 'collaborator'],
  },
  {
    title: 'Ongoing care activity',
    content: 'View initiation of a medication plan, eligibility and next steps.',
    viewers: ['collaborator'],
  },
  {
    title: 'Provider messaging',
    content: `Communicate with the student's provider(s) via messaging.`,
    viewers: ['collaborator'],
  },
];

const monitorTypeCopy: Record<MonitorType, string> = {
  referrer: 'Referring User',
  monitor: 'Monitoring',
  collaborator: 'Collaborating',
};

type CampusTeamSelectionsProps = {
  showAccessFaq?: boolean;
  organizationId: number;
  campusTeam: EditCampusTeamMember[];
  setCampusTeam: Setter<EditCampusTeamMember[]>;
  relationshipError?: string;
};

export const CampusTeamSelection = ({
  organizationId,
  showAccessFaq,
  campusTeam,
  setCampusTeam,
  relationshipError,
}: CampusTeamSelectionsProps) => {
  const { currentProvider } = useCurrentProvider();
  const { data } = useCampusTeamOptionsQuery({ variables: { organizationId } });

  const searchOptions: EditCampusTeamMember[] = data?.organization.campusTeamOptions ?? [];

  return (
    <div className="flex flex-row gap-4 items-center flex-wrap">
      <div>
        <Text.h3 className="mb2">Referring User</Text.h3>
        <Text.body className="mb2">{currentProvider.name} (You)</Text.body>
        <Text.caption kind="grayText" className="mw6 mb5">
          This student will show up in your <strong>Referrals list</strong>, and you’ll be able to
          track if they scheduled and completed their first visit.
        </Text.caption>
        <Text.h3>Campus Team</Text.h3>
        <Text.caption kind="grayText" className="mw6">
          Monitor a patient’s ongoing care, with an option to allow provider collaboration.
        </Text.caption>
        {relationshipError && <Notification kind="negative">{relationshipError}</Notification>}
        <div className="mw6">
          <SearchAndAdd
            selectedValues={campusTeam}
            searchOptions={searchOptions.map(provider => ({
              data: provider,
              label: provider.name,
            }))}
            onAddValue={provider => setCampusTeam(prevTeam => [...prevTeam, provider])}
            onEditValue={editProvider =>
              setCampusTeam(prevTeam =>
                prevTeam.map(existingProvider =>
                  existingProvider.id === editProvider.id ? editProvider : existingProvider
                )
              )
            }
            onRemoveValue={removeProvider =>
              setCampusTeam(prevTeam =>
                prevTeam.filter(existingProvider => existingProvider.id !== removeProvider.id)
              )
            }
            component={CampusTeamMember}
          />
        </div>
      </div>
      {showAccessFaq && <CollaborationLevelMatrix />}
    </div>
  );
};

const CollaborationLevelMatrix = () => {
  const monitors: MonitorType[] = ['referrer', 'monitor', 'collaborator'];
  const gridTemplateColumns = '14rem 7.5rem 6rem 1fr';
  return (
    <MatrixContainer>
      <Grid className="mb1" gridTemplateColumns="14rem 7rem 1fr">
        {/* skip first two */}
        <GridSpan
          start={3}
          span={2}
          style={{ background: colors.background, border: '1px solid #e5e5e5' }}
        >
          <Text.label className="tc ph3 pv2">CAMPUS TEAM</Text.label>
        </GridSpan>
      </Grid>
      <Grid className="mb3" gridTemplateColumns={gridTemplateColumns}>
        <Text.bodyGrey>Involvement</Text.bodyGrey>
        {monitors.map(m => (
          <Text.body key={m}>{monitorTypeCopy[m]}</Text.body>
        ))}
      </Grid>
      <MantraAccordion style={{ marginRight: '2.5rem' }}>
        {faq.map((f, i) => (
          <MantraAccordionPanel
            key={`${f.title}-${i}`}
            title={
              <Grid gridTemplateColumns={gridTemplateColumns}>
                <Text.bodyBold>{f.title}</Text.bodyBold>
                {monitors.map(m => (f.viewers.includes(m) ? <CheckCircle /> : <XCircle />))}
              </Grid>
            }
          >
            <Text.caption kind="grayText" style={{ maxWidth: '14.5rem' }}>
              {f.content}
            </Text.caption>
          </MantraAccordionPanel>
        ))}
      </MantraAccordion>
    </MatrixContainer>
  );
};

const CheckCircle = () => (
  <Circle size={21} color="successBg">
    <InlineSVG size={11} icon="check" kind="success" />
  </Circle>
);

const XCircle = () => (
  <Circle size={21} color="dangerBg">
    <InlineSVG size={15} icon="x" kind="danger" />
  </Circle>
);

const Circle = styled.div<{ size: number; color: ColorLookup }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => Math.floor(size / 2)}px;
  background: ${({ color }) => get(colors, color)};
  color: ${({ color }) => get(colors, color)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0.75rem;
`;

const MatrixContainer = styled.div`
  padding: 0 0 2rem 1.5rem;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
`;
