import React from 'react';
import { useVideoContextV2 } from './VideoProvider';

export type MatchConnection = { id?: number; actorType: 'provider' | 'user' };
export function useVideoStatus(match: MatchConnection) {
  const { getSession } = useVideoContextV2();
  const [hasAudio, setHasAudio] = React.useState(true);
  const [hasVideo, setHasVideo] = React.useState(true);

  const session = getSession();

  React.useEffect(() => {
    if (!session) return;
    const isDesiredConnection = (connection: OT.Connection) => {
      try {
        const parsed = JSON.parse(connection.data) as MatchConnection;
        if (parsed.actorType === match.actorType) {
          if (!match.id || match.id === parsed.id) return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    };

    const handlePropertyChanged = (event: any) => {
      if (!isDesiredConnection(event.stream.connection)) return;
      if (event.changedProperty === 'hasAudio') setHasAudio(event.newValue);
      else if (event.changedProperty === 'hasVideo') setHasVideo(event.newValue);
    };
    const handleConnection = (event: any) => {
      if (isDesiredConnection(event.stream.connection)) {
        setHasAudio(event.stream.hasAudio);
        setHasVideo(event.stream.hasVideo);
      }
    };
    session.on('streamPropertyChanged', handlePropertyChanged);
    session.on('streamCreated', handleConnection);
    return () => {
      session.off('streamPropertyChanged', handlePropertyChanged);
      session.off('streamCreated', handleConnection);
    };
  }, [session, match, setHasAudio, setHasVideo]);

  return { hasAudio, hasVideo };
}

export const otCallback = (cb?: () => void) => (err?: OT.OTError) => {
  if (err) throw new Error(`${err.name}:${err.message}`);
  cb?.();
};
