import React from 'react';
import icons from './_icons.json';

/**
 * ### WHAT'S GOING ON??? ###
 *
 * This component dynamically loads icons from the public folder
 *
 * In order to add icons, you must follow these steps:
 * 1. add an icon to the public folder
 * 2. run "yarn icons"
 */

export type IconKey = keyof typeof icons;
export type IconProps = { icon: IconKey; size?: number | string } & Pick<
  React.HTMLProps<HTMLImageElement>,
  'alt' | 'width' | 'height' | 'className' | 'onClick' | 'style' | 'title' | 'tabIndex'
>;

export const Icon = ({ icon, size, alt, width, height, ...props }: IconProps) => {
  return (
    <img
      src={icons[icon]}
      alt={alt || icon}
      width={width || size}
      height={height || size}
      {...props}
    />
  );
};

export { icons };
