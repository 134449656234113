import { CheckboxProps } from 'baseui/checkbox';
import { borderColor, borderWidth } from '../utils';
import { colors } from './colors';

export const checkbox: CheckboxProps['overrides'] = {
  Checkmark: {
    style: ({ $checked }) => ({
      backgroundColor: $checked ? colors.primary : colors.grey.disabledBackground,
      ...($checked ? {} : borderWidth('0px')),
      ...($checked ? {} : borderColor('rgba(143, 155, 179, 0.48)')),
    }),
  },
};
