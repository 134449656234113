import React from 'react';
import { OzProfile } from '../ProviderSettings/OzProfile';
import { useProviderDrilldownContext } from './context';

export function Overview() {
  const { provider } = useProviderDrilldownContext();
  return (
    <div className="mh5 mt3">
      <OzProfile providerId={provider.id} />
    </div>
  );
}
