import React, { useState } from 'react';
import { ComponentArgs, EzMultirow } from '../../Components/EzForm';
import { FinalButton } from '../../Components/FinalButton';
import { Input } from '../../Components/Form/Input';
import {
  ProviderCertification,
  ProviderEducation,
  ProviderQualification,
  useUpdateBioMutation,
} from '../../graphQL';
import { useProviderDrilldownContext } from './context';

export function QualificationsTab() {
  const { provider, refetch } = useProviderDrilldownContext();
  const [qualifications, setQualifications] = useState(
    provider.qualifications2.map(i => ({
      ...i,
      states: i.states.join(', '),
      licences: i.licences.join(', '),
    }))
  );
  const [education, setEducation] = useState(provider.degrees);
  const [certifications, setCertifications] = useState(provider.certifications);
  const [save, { loading, data }] = useUpdateBioMutation();

  const onSave = async () => {
    await save({
      variables: {
        providerId: provider.id,
        qualifications: qualifications.map(({ abbreviation, states, licences, name }) => ({
          name,
          abbreviation,
          states: states.split(',').map(s => s.trim()),
          licences: licences.split(',').map(l => l.trim()),
        })),
        education: education.map(({ university, degree, abbreviation }) => ({
          university,
          degree,
          abbreviation,
        })),
        certifications: certifications.map(({ name, abbreviation }) => ({ name, abbreviation })),
      },
    });
    await refetch();
  };

  return (
    <div className="mh5 mv3">
      <p className="mb3">
        This tab is for setting the qualifications that appear in the provider’s info when the user
        messages them. To set the actual states that they can operate in, use the state field in the
        “Edit Profile” modal or the “Insurance” tab.
      </p>
      <h3 className="mb1">Qualifications</h3>
      <p className="mb3">
        To include multiple states or licenses for the same qualification, separate them with
        commas, e.g. “Michigan, New York”.
      </p>
      <EzMultirow
        values={qualifications}
        component={Qualification}
        setValues={setQualifications}
        blank={{ id: -1, name: '', abbreviation: '', states: '', licences: '' }}
      />
      <h3 className="mt4 mb3">Education</h3>
      <EzMultirow
        values={education}
        component={Education}
        setValues={setEducation}
        blank={{ id: -1, university: '', degree: '', abbreviation: '' }}
      />
      <h3 className="mt4 mb3">Certifications</h3>
      <EzMultirow
        values={certifications}
        component={Certification}
        setValues={setCertifications}
        blank={{ id: -1, name: '', abbreviation: '' }}
      />
      <div className="mt3 flex flex-row items-center">
        <FinalButton onClick={onSave} loading={loading}>
          Save all
        </FinalButton>
        {data && !loading && <div className="ml3">Changes saved.</div>}
      </div>
    </div>
  );
}

function Qualification({
  value,
  onChange,
}: ComponentArgs<
  Omit<ProviderQualification, 'states' | 'licences'> & { states: string; licences: string }
>) {
  return (
    <div className="flex flex-row">
      <Input
        className="mr2"
        value={value.name}
        placeholder="Name"
        onChange={e => onChange({ ...value, name: e.target.value })}
      />
      <Input
        className="mr2"
        value={value.abbreviation}
        placeholder="Abbreviation"
        onChange={e => onChange({ ...value, abbreviation: e.target.value })}
      />
      <Input
        className="mr2"
        value={value.states}
        placeholder="States"
        onChange={e => onChange({ ...value, states: e.target.value })}
      />
      <Input
        className="mr2"
        value={value.licences}
        placeholder="Licences"
        onChange={e => onChange({ ...value, licences: e.target.value })}
      />
    </div>
  );
}

function Education({ value, onChange }: ComponentArgs<ProviderEducation>) {
  return (
    <div className="flex flex-row">
      <Input
        className="mr2"
        value={value.university}
        placeholder="University"
        onChange={e => onChange({ ...value, university: e.target.value })}
      />
      <Input
        className="mr2"
        value={value.degree}
        placeholder="Degree"
        onChange={e => onChange({ ...value, degree: e.target.value })}
      />
      <Input
        className="mr2"
        value={value.abbreviation}
        placeholder="Abbreviation"
        onChange={e => onChange({ ...value, abbreviation: e.target.value })}
      />
    </div>
  );
}

function Certification({ value, onChange }: ComponentArgs<ProviderCertification>) {
  return (
    <div className="flex flex-row">
      <Input
        className="mr2"
        value={value.name}
        placeholder="Name"
        onChange={e => onChange({ ...value, name: e.target.value })}
      />
      <Input
        className="mr2"
        value={value.abbreviation}
        placeholder="Abbreviation"
        onChange={e => onChange({ ...value, abbreviation: e.target.value })}
      />
    </div>
  );
}
