import React from 'react';
import BaseModal, { Props } from 'react-modal';
import styled from 'styled-components';
import { Icon } from '../Icons';

type Size = 'tiny' | 'small' | 'medium' | 'large' | 'full' | 'campusTeam';

export interface ModalProps extends Props {
  children: React.ReactNode;
  size: Size;
  onClose?: () => void;
}

const sizes: Record<Size, string> = {
  tiny: '480px',
  small: '600px',
  medium: '900px',
  large: '1200px',
  full: '100%',
  campusTeam: '544px',
};

export function Modal({ onClose, size, style, children, ...rest }: ModalProps) {
  return (
    <BaseModal
      ariaHideApp={false}
      style={{
        overlay: { ...baseOverlayStyle, ...style?.overlay },
        content: {
          ...baseContentStyle,
          ...(onClose && { paddingTop: 40 }),
          ...(size && { maxWidth: sizes[size] }),
          ...style?.content,
        },
      }}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      {...rest}
    >
      {onClose && (
        <CloseButton onClick={onClose}>
          <Icon icon="iconsBlackXSvg" size={18} />
        </CloseButton>
      )}
      {children}
    </BaseModal>
  );
}

const baseContentStyle: NonNullable<Props['style']>['content'] = {
  margin: 'auto',
  height: 'min-content',
  padding: 32,
  border: 'none',
  borderRadius: 1,
};

const baseOverlayStyle: NonNullable<Props['style']>['overlay'] = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  overflowY: 'auto',
  zIndex: 100,
};

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.75rem;
  position: absolute;
  top: 12px;
  right: 12px;
`;
