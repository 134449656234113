import { pick } from 'lodash';
import moment from 'moment';
import { User, UserBasicFragment } from '../graphQL';

export type SortDirection = 'asc' | 'desc';

// todo: make provider first and last non-nullable
type HasName = {
  firstName?: string | null;
  lastName?: string | null;
  preferredName?: string | null;
};

// NameFormat and getFullPreferredName are ~copied from api/src/utils/utils.ts
// and can be merged into a shared tool in the future
// Note: the HasName type remains different between the two
type NameFormat = 'f (p) l' | 'f, p, l' | 'f, "p", l' | 'f "p" l' | 'l f' | 'f l' | 'p l';

export function getFullPreferredName<T extends HasName>(user: T, format: NameFormat = 'f (p) l') {
  const { firstName: f, preferredName: p, lastName: l } = user;

  if (!p) {
    // default to fullname if preferred does not exist. This can change later
    return getFullName(user);
  }
  const result: string[] = [];
  for (const char of format) {
    if (f && char === 'f') {
      result.push(f);
    } else if (char === 'p') {
      result.push(p);
    } else if (l && char === 'l') {
      result.push(l);
    } else {
      result.push(char);
    }
  }
  return result.join('');
}

export function getFullName<T extends HasName>(user: T, opts?: { noPreferred?: boolean }) {
  if (!opts?.noPreferred && user.preferredName) {
    return `${user.firstName?.trim()} (${user.preferredName?.trim()}) ${user.lastName?.trim()}`;
  }
  return `${user.firstName?.trim()} ${user.lastName?.trim()}`;
}

export function getFullNameReversed<T extends HasName>(
  user: T,
  opts: { noPreferred?: boolean } = {}
) {
  if (!opts.noPreferred && user.preferredName) {
    return `${user.lastName?.trim()}, ${user.firstName?.trim()} (${user.preferredName?.trim()})`;
  }
  return `${user.lastName?.trim()}, ${user.firstName?.trim()}`;
}

export const compareLastNames = (
  a: Pick<User, 'firstName' | 'lastName'>,
  b: Pick<User, 'firstName' | 'lastName'>
) => {
  const compared = a.lastName.localeCompare(b.lastName);
  return compared !== 0 ? compared : a.firstName.localeCompare(b.firstName);
};

export const compareNames = (
  a: { firstName: string; lastName: string },
  b: { firstName: string; lastName: string }
) => {
  const comparedFirstNames = a.firstName.localeCompare(b.firstName);
  if (comparedFirstNames !== 0) {
    return comparedFirstNames;
  }
  // if first names are the same, use last name as a tiebreaker
  return a.lastName.localeCompare(b.lastName);
};

export const compareDates = (a: string | null, b: string | null) => {
  const mA = moment(a || '');
  const mB = moment(b || '');
  if (!mA.isValid() && mB.isValid()) {
    return -1;
  }
  if (mA.isValid() && !mB.isValid()) {
    return 1;
  }
  return moment(mA).diff(moment(mB));
};

export const isOnCareTeam = (
  provider: { id: number },
  user: {
    provider?: { id: number } | null;
    therapist?: { id: number } | null;
    campusTeam?: { relationshipType: string; provider: { id: number } }[] | null;
  }
) => {
  return (
    provider.id === user.provider?.id ||
    provider.id === user.therapist?.id ||
    Boolean(user.campusTeam?.find(i => i.provider.id === provider.id))
  );
};

export const formatMantraProviders = (user: {
  provider?: { name: string } | null;
  therapist?: { name: string } | null;
}) => {
  if (!user.provider && !user.therapist) {
    return 'None';
  }
  return [user.provider?.name, user.therapist?.name].filter(i => i !== undefined).join(', ');
};

export const taskStatuses = [
  'Account Setup',
  'Enrolled',
  'Intake',
  'Initial Evaluation',
  'Ongoing Care',
  'Discharged',
];

export function getUserOrgAbbreviation(user: {
  organization?: { parent?: { abbreviation: string } | null; abbreviation: string } | null;
}) {
  return user.organization?.parent?.abbreviation ?? user.organization?.abbreviation;
}

export const pickBasicUser = <T extends UserBasicFragment>(user: T) =>
  pick(
    user,
    'id',
    'firstName',
    'preferredName',
    'lastName',
    'customerId',
    'createdAt'
  ) as UserBasicFragment;
