import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useDrilldownContext } from '../../Pages/Users/helpers';
import { Text, colorMap, colors } from '../../globalStyles';
import {
  CareType,
  ContinuityOfCareEligibilityResult,
  useAddUserCareEligibilitySubmissionMutation,
  usePriceConfigurationQuery,
  useProviderProfileQuery,
} from '../../graphQL';
import { locationOptions } from '../../locations';
import { careTypeToProviderType } from '../../modelUtils/provider';
import { formatMoney } from '../../utils';
import { FinalButton } from '../FinalButton';
import { RadioRHF, SelectRHF, TextareaRHF } from '../Form';
import { MantraSpinner } from '../LoadingOverlay';
import { Notification } from '../Notification';
import { isMantraAdmin, useCurrentProvider } from '../Permissions';
import { TabBodyProps } from '../PopoverTabs/PopoverTab';
import { usePopoverTabContext } from '../PopoverTabs/PopoverTabContainer';
import { ConfirmExitModal } from './ConfirmExitModal';
import { formCopy } from './EligibilityCopy';
import { EligibilityFormResult } from './EligibilityFormResult';
import { useCocEligibility } from './hooks/useCocEligibility';
import * as Styles from './styles';

interface EligibilityFormProps {
  tab: TabBodyProps;
  careType: CareType;
}

export const EligibilityFormPopover = ({ tab, careType }: EligibilityFormProps) => {
  const { currentProvider } = useCurrentProvider();
  const { user, refetch } = useDrilldownContext();
  const { scrollToTop } = usePopoverTabContext();

  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<ContinuityOfCareEligibilityResult | null>(null);

  const formContext = useForm({
    shouldFocusError: true,
    mode: 'onChange',
  });

  const { showSponsoredForm, currentCarePeriod, nextCarePeriod, remainingSessions } =
    useCocEligibility({ careType });

  const providerId = careType === CareType.Psychiatry ? user?.provider?.id! : user?.therapist?.id!;

  const { data: providerData } = useProviderProfileQuery({
    variables: { providerId: providerId! },
    notifyOnNetworkStatusChange: true,
    skip: !providerId,
  });
  const caregiver = providerData?.adminProvider;

  const [addUserCareEligibilitySubmissionMutation] = useAddUserCareEligibilitySubmissionMutation({
    onCompleted: ({ addUserCareEligibilitySubmission }) => {
      setResult(addUserCareEligibilitySubmission);
    },
    onError: err => {
      if (!providerId && !isMantraAdmin(currentProvider)) {
        setError(`Missing ${careTypeToProviderType[careType]}`);
      } else {
        setError(err.message.replace('GraphQL error:', ''));
      }
      scrollToTop();
    },
  });

  useEffect(() => {
    tab.setDirty(true);
  }, [tab.setDirty]);

  // Close tab if user hasn't filled out anything
  useEffect(() => {
    if (!formContext.formState.isDirty && tab.showConfirm) {
      tab.closeTab();
    }
  }, [tab.showConfirm, tab.closeTab, formContext.formState.isDirty]);

  const organization = user?.organization?.name;
  const copy = showSponsoredForm ? formCopy.sponsored : formCopy.selfPay;

  const enrollemntHandbookLink: any = () => {
    return (
      <Text.link
        to={`/organizations/${user.organization?.id}/handbook#Continuity of Care`}
        target="_blank"
      >
        {copy.enrollment?.subtitle &&
          copy.enrollment?.subtitle.replace(
            '[ORGANIZATION]',
            organization || 'the same organization'
          )}
      </Text.link>
    );
  };

  const onSubmit = formContext.handleSubmit(async values => {
    await addUserCareEligibilitySubmissionMutation({
      variables: {
        submission: {
          residentialLocationDuringBreak: values.residentialLocationDuringBreak,
          interestedInContinuingCare: Boolean(values.interestedInContinuingCare),
          enrollment: Boolean(values.enrollment),
          additionalNotes: values.additionalNotes,
          paymentType: showSponsoredForm ? 'sponsored' : 'selfPay',
          careType,
          remainingSessions,
          providerId: caregiver?.id,
          userId: user.id,
        },
      },
    });
    refetch();
  });

  return (
    <Styles.PopoverContainer>
      <ConfirmExitModal tab={tab} />
      <Styles.PopoverBody>
        {result && (
          <EligibilityFormResult
            {...result}
            currentPeriod={currentCarePeriod}
            nextPeriod={nextCarePeriod}
            careType={careType}
            onRedoForm={() => {
              formContext.reset();
              setResult(null);
            }}
          />
        )}
        {!result && (
          <>
            {error && <Notification kind="negative">{error}</Notification>}
            <FormProvider {...formContext}>
              <form onSubmit={onSubmit}>
                <div className="mb4">
                  <Text.h2 className="mb2">{careType} Eligibility: Upcoming Break</Text.h2>
                  <div className="flex items-start">
                    <Text.bodySmall>
                      <b>Sponsored Care Term</b> ends{' '}
                      <b>
                        {currentCarePeriod
                          ? moment(currentCarePeriod?.endDate)?.format('MM/DD/YYYY')
                          : null}
                      </b>
                    </Text.bodySmall>
                  </div>
                </div>
                <Block>
                  <SelectRHF
                    controlProps={copy.residentialLocationDuringBreak}
                    rules={{ required: copy.residentialLocationDuringBreak.required }}
                    name="residentialLocationDuringBreak"
                    options={locationOptions}
                    className="mt3"
                  />
                </Block>
                <Block>
                  <Fieldset>
                    <RadioRHF
                      optionsInline
                      optionType="pill"
                      withLabel
                      controlProps={{
                        ...copy.enrollment,
                        label: copy.enrollment.label.replace(
                          '[ORGANIZATION]',
                          organization || 'the same organization'
                        ),
                        subtitle: enrollemntHandbookLink(),
                      }}
                      rules={{ required: copy.enrollment.required }}
                      name="enrollment"
                      options={[
                        { id: 1, label: 'Yes' },
                        { id: 0, label: 'No' },
                      ]}
                    />
                  </Fieldset>
                </Block>
                <Block>
                  <Fieldset>
                    <RadioRHF
                      optionsInline
                      optionType="pill"
                      withLabel
                      controlProps={{
                        ...copy.interestedInContinuingCare,
                        label: copy.interestedInContinuingCare.label(),
                        subtitle: copy.interestedInContinuingCare.subtitle(),
                      }}
                      rules={{ required: copy.interestedInContinuingCare.required }}
                      name="interestedInContinuingCare"
                      options={[
                        { id: 1, label: 'Yes' },
                        { id: 0, label: 'No' },
                      ]}
                    />
                  </Fieldset>
                  {!showSponsoredForm && <SelfPayInfo careType={careType} />}
                </Block>
                <Block>
                  <TextareaRHF
                    controlProps={copy.additionalNotes}
                    name="additionalNotes"
                    className="mt3"
                  />
                </Block>
                <div className="mt4">
                  <FinalButton
                    type="submit"
                    kind="primary"
                    disabled={!formContext.formState.isValid}
                    style={{ paddingLeft: 64, paddingRight: 64 }}
                  >
                    Submit &amp; Continue
                  </FinalButton>
                </div>
              </form>
            </FormProvider>
          </>
        )}
      </Styles.PopoverBody>
    </Styles.PopoverContainer>
  );
};

interface SelfPayProps {
  careType: CareType;
}

const SelfPayInfo = ({ careType }: SelfPayProps) => {
  const { data, loading, error } = usePriceConfigurationQuery();

  if (loading) {
    return <MantraSpinner />;
  }

  if (!data || error) {
    return (
      <div>
        <Text.label className="mb2">Failed to load price information</Text.label>
        <Text.bodySmall>Please reopen the form</Text.bodySmall>
      </div>
    );
  }

  const { psychiatryFollowUpPriceInCents, therapyFollowUpPriceInCents } = data.pricesInCents;

  return (
    <Alert className="mt2">
      <div>
        <Text.label className="mb2">Cash pricing</Text.label>
        <Text.bodySmall className="mb2">
          If not in-network, they can still continue to see their provider at the standard pricing:
        </Text.bodySmall>
        {careType === CareType.Therapy && (
          <Text.bodyBold>Ongoing Therapy: {formatMoney(therapyFollowUpPriceInCents)}</Text.bodyBold>
        )}
        {careType === CareType.Psychiatry && (
          <Text.bodyBold>
            Ongoing Psychiatry: {formatMoney(psychiatryFollowUpPriceInCents)}
          </Text.bodyBold>
        )}
      </div>
    </Alert>
  );
};

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;

const Block = styled.div`
  margin-bottom: 60px;
`;

const Alert = styled.div`
  border: 1px solid ${colorMap.warning[0]};
  border-radius: 4px;
  background-color: ${colors.warningBg};
  padding: 20px 52px 20px 20px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 28px;
`;
