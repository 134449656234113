import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '../globalStyles';

type BorderedProps = {
  borderColor?: string;
  borderSize?: string | number;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
  borderAll?: boolean;
};

const getBorderedStyle = (args: BorderedProps): FlattenSimpleInterpolation => {
  const {
    borderColor = colors.grey.light,
    borderSize = 1,
    borderLeft,
    borderRight,
    borderTop,
    borderBottom,
    borderAll,
  } = args;
  const sizeStr = typeof borderSize === 'number' ? `${borderSize}px` : borderSize;

  const t = css`
    border-top: ${sizeStr} solid ${borderColor};
  `;
  const r = css`
    border-right: ${sizeStr} solid ${borderColor};
  `;
  const b = css`
    border-bottom: ${sizeStr} solid ${borderColor};
  `;
  const l = css`
    border-left: ${sizeStr} solid ${borderColor};
  `;

  return css`
    ${borderTop || borderAll ? t : ''};
    ${borderRight || borderAll ? r : ''};
    ${borderBottom || borderAll ? b : ''};
    ${borderLeft || borderAll ? l : ''};
  `;
};

export const Bordered = styled.div<BorderedProps>`
  margin: 0;
  padding: 0;
  ${p => getBorderedStyle(p)}
`;
