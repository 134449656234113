import { Select } from 'baseui/select';
import React from 'react';
import styled from 'styled-components';
import { Nullable } from '../types';
import { TimeZone } from '../utils';
import { icons } from './Icons';

interface Props {
  value: Nullable<TimeZone>;
  onChange: (value: TimeZone) => void;
}

const timezoneOptions: { id: TimeZone; label: string }[] = [
  { id: 'America/New_York', label: 'Eastern Time (ET)' },
  { id: 'America/Chicago', label: 'Central Time (CT)' },
  { id: 'America/Denver', label: 'Mountain Time (MT)' },
  { id: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
];

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`;
const GlobeIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export function TimezonePicker({ value, onChange }: Props) {
  const selectedOption = timezoneOptions.find(i => i.id === value);
  const overrides = {
    SingleValue: {
      component: ({ children }: { children: React.ReactElement }) => (
        <ValueContainer>
          <GlobeIcon src={icons.iconsGlobeSvg} />
          {children}
        </ValueContainer>
      ),
    },
    Popover: {
      props: {
        overrides: {
          Body: { style: { zIndex: '10' } },
        },
      },
    },
  };
  return (
    <Select
      maxDropdownHeight="24rem"
      options={timezoneOptions}
      value={selectedOption ? [selectedOption] : undefined}
      onChange={e => onChange(e.option!.id as TimeZone)}
      clearable={false}
      overrides={overrides}
    />
  );
}
