import React from 'react';
import { styled } from 'baseui';
import { Check } from 'baseui/icon';
import { borderRadius, padding, margin, borderColor } from '../utils';
import { colors } from '../globalStyles';

type Layout = 'vertical' | 'horizontal';

const Wrapper = styled<{ layout: Layout }, 'div'>('div', props => ({
  display: 'flex',
  flexFlow: props.layout === 'vertical' ? 'column' : 'row',
  justifyContent: 'space-between',
  ...margin('1em 0'),
}));
const Button = styled<{ $active: boolean; $deemphasized: boolean; layout: Layout }, 'button'>(
  'button',
  props => ({
    backgroundColor: props.$active ? '#E5F1FF' : '#F9F9F9',
    ...borderColor(props.$active ? colors.primary : '#C6CBD4'),
    color: props.$deemphasized ? '#757575' : '#000',
    width: '100%',
    fontSize: '1em',
    outline: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.layout === 'vertical' ? 'flex-start' : 'space-between',
    flex: '0',
    whiteSpace: 'nowrap',
    ...borderRadius('50px'),
    ...padding('0.5em 0.5em'),
    ...margin('0.5em 0'),
  })
);
const CheckWrapper = styled('div', {
  width: '20px',
  height: '25px',
  marginRight: '0.35em',
  marginLeft: '0.35em',
});

interface Props<T> {
  options: {
    label: string;
    id: T;
  }[];
  value?: T;
  onChange?: (value: T) => void;
  layout?: Layout;
}

export function FancyRadio<T>({ options, value, onChange, layout = 'vertical' }: Props<T>) {
  return (
    <Wrapper layout={layout}>
      {options.map(({ label, id }) => (
        <Button
          $active={value === id}
          $deemphasized={Boolean(value) && value !== id}
          onClick={() => onChange && onChange(id)}
          layout={layout}
          key={String(id)}
        >
          <CheckWrapper>{value === id && <Check size={24} />}</CheckWrapper>
          {label}
          <CheckWrapper />
        </Button>
      ))}
    </Wrapper>
  );
}
