import { toaster } from 'baseui/toast';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { IconButton } from '../../Components/Buttons';
import { Icon } from '../../Components/Icons';
import { PermsOnly } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import {
  Permission,
  TicketAssignOption,
  TicketUserFragment,
  TicketsQuery,
  useTicketQuery,
} from '../../graphQL';
import { compareJsDates, englishList, padLeft } from '../../utils';
import { TaskForm } from './TaskForm';
import { IEditTask, useTaskPageContext } from './tasks.context';
import { ErrorContainer } from '../../Components/ProviderNetwork/Styles';

type EditTaskAsideProps = {
  editing: IEditTask;
  onClose: () => void;
  onSuccess: () => void;
  lockUser: TicketUserFragment;
  defaultValues?: Record<string, any>;
};
export const EditTaskAside = ({
  editing,
  lockUser,
  onClose,
  onSuccess,
  defaultValues,
}: EditTaskAsideProps) => {
  const [editMode, setEditMode] = useState(editing === 'new');
  const { data, refetch } = useTicketQuery({
    variables: { id: editing as number },
    skip: !_.isNumber(editing),
  });

  if (!editing) return null;

  if (!data && editing !== 'new') return null;

  const canClickEdit =
    !!data?.ticket &&
    (data.ticket.canEdit || data.ticket.assignOptions !== TicketAssignOption.None);

  const copyLink = async () => {
    const link = `${window.location.protocol}//${window.location.host}/tasks?openTaskId=${editing}`;
    await navigator.clipboard.writeText(link);
    toaster.info('Link copied to clipboard.', {});
  };

  return (
    <div>
      <div className="flex justify-between items-center bb b--light-gray pb2 mb4">
        <div className="flex items-center">
          {editing === 'new' ? <h3>New Task</h3> : <h4>#{padLeft(editing)}</h4>}
          {editing !== 'new' && (
            <IconButton onClick={copyLink}>
              <Icon icon="iconsBlackLinkSvg" style={{ height: 18, width: 18 }} className="ml2" />
            </IconButton>
          )}
        </div>
        <div className="flex items-center gap-3">
          {canClickEdit && !editMode && (
            <Text.linkButton onClick={() => setEditMode(true)}>Edit</Text.linkButton>
          )}
          {data && (
            <div className="gray f6">Created {moment(data.ticket.createdAt).format('L')}</div>
          )}
          <div
            tabIndex={0}
            role="button"
            onClick={onClose}
            onKeyDown={e => e.key === 'Enter' && onClose()}
            className="pointer"
          >
            <Icon icon="iconsCollapseSvg" size={20} />
          </div>
        </div>
      </div>
      <TaskForm
        key={editing}
        ticket={data?.ticket}
        defaultValues={defaultValues}
        lockUser={lockUser}
        onComplete={shouldClose => {
          if (editing === 'new') {
            onClose();
          } else {
            setEditMode(false);
            if (!shouldClose) refetch();
          }
          if (shouldClose) onClose();
          onSuccess();
        }}
        onCancel={() => {
          if (editing === 'new') {
            onClose();
          } else {
            setEditMode(false);
            refetch();
          }
        }}
        viewOnly={!editMode}
      />
      {/* THIS IS A CONCEPT AND NEEDS FURTHER DISCUSSION - SHOWS TASK HISTORY SIMILAR TO CLUBHOUSE */}
      <PermsOnly allowed={Permission.MantraAdmin}>
        <div className="mt5 flex flex-column">
          <Text.bodyBold className="mb3">Ticket timeline (admin only)</Text.bodyBold>
          {data?.ticket.events
            .sort((a, b) => compareJsDates({ a: a.createdAt, b: b.createdAt }))
            .map(e => {
              const parsedTime = moment(e.createdAt);
              return (
                <div className="mb2 flex items-center" key={e.id}>
                  <div className="mr3">
                    <Text.caption>
                      {parsedTime.format('MM/DD/YYYY')}
                      <br />
                      {parsedTime.format('hh:mm a')}
                    </Text.caption>
                  </div>
                  <div>
                    <Text.body className="mb0 truncate">{e.data?.name ?? 'a provider'}</Text.body>
                    <Text.bodySmall className="mt0" kind="grayText">
                      {ticketNameMap.get(e.tag)?.(e.data) ?? e.tag}
                    </Text.bodySmall>
                  </div>
                </div>
              );
            })}
        </div>
      </PermsOnly>
    </div>
  );
};

const ticketNameMap = new Map<string, (data: any) => string>([
  ['provider:ticket:status-changed', data => `Changed ticket status to ${data.to}`],
  [
    'provider:ticket:updated',
    data => `Updated ${englishList(data.updates?.map(({ key }: any) => key)) ?? 'the ticket'}`,
  ],
  ['provider:ticket:created', () => 'Created ticket'],
  ['provider:ticket:assigned', data => `Assigned ticket to ${data.to?.name ?? data.toName}`],
]);

export const TaskPageAside = ({ tickets }: Pick<TicketsQuery, 'tickets'>) => {
  const { editing, setEditing, refetch } = useTaskPageContext();

  const user = tickets.find(task => task.id === editing)?.user;

  if (!user) {
    return (
      <ErrorContainer>
        <Text.bodySmall kind="danger">
          Could not load the selected task since this task is associated with a deleted user.
        </Text.bodySmall>
      </ErrorContainer>
    );
  }

  return (
    <div className="bg-white pa4 pb5">
      <EditTaskAside
        lockUser={user}
        key={String(editing)}
        editing={editing}
        onClose={() => setEditing(null)}
        onSuccess={() => refetch()}
      />
    </div>
  );
};
