import React from 'react';
import styled from 'styled-components';
import { FinalButton } from '../../Components/FinalButton';
import { Grid } from '../../Components/Grid';
import { useCurrentProvider } from '../../Components/Permissions';
import { mediaScreenFeatures } from '../../Components/Video/common';
import { VideoPageIconButton } from '../../Components/Video/VideoComponents';
import { useVideoContextV2 } from '../../Components/Video/VideoProvider';
import { useVideoStatus } from '../../Components/Video/videoUtil';
import { colorMap } from '../../globalStyles';
import { VideoDevicePicker } from './DevicePicker';
import { VideoCallPageProps } from './VideoCallPage';
import { VideoCallSidebar } from './VideoCallSidebar';

type VideoCallControlProps = Pick<VideoCallPageProps, 'appointment'> & {
  endCall: () => void;
};

export function VideoCallControls({ endCall, appointment }: VideoCallControlProps) {
  const { currentProvider } = useCurrentProvider();
  const { setState, publishingVideo, publishingAudio } = useVideoContextV2();

  const isAppointmentProvider = currentProvider.id === appointment.provider.id;

  return (
    <Container className="mb4">
      <div id="warningContainer" className="flex flex-column items-center gap-3 mb4">
        <PatientVideoWarning />
      </div>
      <Grid gridTemplateColumns="1fr 2fr 1fr">
        <div className="flex justify-start items-center">
          {isAppointmentProvider && <VideoCallSidebar appointment={appointment} />}
        </div>
        <MainButtonPanel>
          <VideoPageIconButton
            toggled={publishingAudio}
            onClick={() => setState({ publishingAudio: !publishingAudio })}
            icon={{ icon: publishingAudio ? 'mic' : 'mic-off', kind: 'white' }}
          />
          <FinalButton kind="white" pill onClick={endCall}>
            <LeaveCallText className="ph2 nowrap">Leave Call</LeaveCallText>
          </FinalButton>
          <VideoPageIconButton
            toggled={publishingVideo}
            onClick={() => setState({ publishingVideo: !publishingVideo })}
            icon={{ icon: publishingVideo ? 'video' : 'video-off', kind: 'white' }}
          />
        </MainButtonPanel>
        <div className="flex justify-end items-center">
          <VideoDevicePicker />
        </div>
      </Grid>
    </Container>
  );
}

const PatientVideoWarning = () => {
  const { isUserConnected, sessionState } = useVideoContextV2();
  const { hasAudio, hasVideo } = useVideoStatus({ actorType: 'user', id: sessionState?.user.id });

  if (!isUserConnected) return null;
  if (hasAudio && hasVideo) return null;
  return (
    <PatientAudioVideoWarning>
      {!hasAudio && <div>Patient&rsquo;s microphone is disabled.</div>}
      {!hasVideo && <div>Patient&rsquo;s camera is disabled.</div>}
    </PatientAudioVideoWarning>
  );
};

const Container = styled.div`
  margin: 2rem 0;
  position: absolute;
  z-index: 1;
  inset: auto 2rem 1rem;

  @media only screen and (max-width: 800px) {
    inset: auto 1rem 0.5rem;
    margin: 2.75rem 0;
  }
`;

const MainButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  @media only screen and (${mediaScreenFeatures.mobile}) {
    margin: 0 0.25rem;
  }
`;

const LeaveCallText = styled.span`
  @media only screen and (${mediaScreenFeatures.mobile}) {
    font-size: 0.875rem;
  }
`;

const PatientAudioVideoWarning = styled.div`
  padding: 0.75rem;
  border-radius: 0.75rem;
  background: ${colorMap.danger[5]};
  color: ${colorMap.danger[0]};
  border: 1px solid ${colorMap.danger[1]};
`;
