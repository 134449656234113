import { flatMap, pick, times } from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '../globalStyles';
import { HELVETICA } from '../globalStyles/text';
import { cx } from '../utils';
import { simplifyPathname, useEvents } from './Events/EventsProvider';
import { Icon, IconKey, IconProps, InlineSVG, InlineSVGProps, SVGKey } from './Icons';
import { MantraSpinner } from './LoadingOverlay';
import { findFirstTextChild } from './utils';

/* naming convention: <style>_<color> */
export const buttonKinds = [
  'primary',
  'danger',
  'white',
  'outline_white',
  'outline_black',
  'outline_danger',
  'minimal_black',
  'minimal_gray',
  'minimal_gray_bg',
  'minimal_danger',
] as const;
export type ButtonKind = typeof buttonKinds[number];

export const buttonSizes = ['tiny', 'compact', 'normal'] as const;
export type ButtonSize = typeof buttonSizes[number];

export type FinalButtonProps = {
  analyticsName?: string;
  kind?: ButtonKind;
  size?: ButtonSize;
  pill?: boolean;
  iconLeft?: IconKey | IconProps | null;
  svgLeft?: SVGKey | InlineSVGProps | null;
  iconRight?: IconKey | IconProps | null;
  svgRight?: SVGKey | InlineSVGProps | null;
  loading?: boolean;
  children?: React.ReactNode;
} & Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'className' | 'onClick' | 'type' | 'disabled' | 'style'
>;

export const FinalButton = ({
  kind = 'primary',
  size = 'normal',
  type = 'button',
  iconLeft,
  svgLeft,
  iconRight,
  svgRight,
  className,
  pill,
  children,
  onClick,
  analyticsName,
  loading,
  ...props
}: FinalButtonProps) => {
  const cn = cx(
    'pointer',
    `btn_kind_${kind}`,
    `btn_size_${size}`,
    { loading, btn_pill: pill },
    className
  );
  const metrics = useEvents();
  const { pathname } = useLocation();
  const childText = findFirstTextChild(children);
  return (
    <StyledButton
      className={cn}
      type={type}
      onClick={e => {
        if (childText) {
          const [firstWord, ...rest] = childText.split(' ');
          const buttonName = [
            firstWord,
            ...flatMap(rest, word =>
              times(word.length)
                .map(_ => '*')
                .join('')
            ),
          ].join('-');
          metrics.track(`button.clicked`, {
            analyticsName,
            buttonName,
            pathname,
            simplePath: simplifyPathname(pathname),
            ...pick(e, 'altKey', 'shiftKey', 'ctrlKey', 'metaKey'),
          });
        }
        onClick?.(e);
      }}
      {...props}
    >
      {iconLeft &&
        (typeof iconLeft === 'string' ? (
          <Icon icon={iconLeft} size={14} />
        ) : (
          <Icon size={14} {...iconLeft} />
        ))}
      {svgLeft &&
        (typeof svgLeft === 'string' ? (
          <InlineSVG icon={svgLeft} />
        ) : (
          <InlineSVG size={14} {...svgLeft} />
        ))}
      {children && <div className="flex-1">{children}</div>}
      {iconRight &&
        (typeof iconRight === 'string' ? (
          <Icon icon={iconRight} size={14} />
        ) : (
          <Icon size={14} {...iconRight} />
        ))}
      {svgRight &&
        (typeof svgRight === 'string' ? (
          <InlineSVG icon={svgRight} />
        ) : (
          <InlineSVG size={14} {...svgRight} />
        ))}
      {loading && (
        <div className="btn_loading_overlay">
          <MantraSpinner simple size={16} />
        </div>
      )}
    </StyledButton>
  );
};

const kindMap: Record<ButtonKind, FlattenSimpleInterpolation> = {
  primary: css`
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.white};

    :before {
      background-color: ${colors.black};
    }
  `,
  danger: css`
    background-color: ${colors.danger};
    border-color: ${colors.danger};
    color: ${colors.white};

    :before {
      background-color: ${colors.grayText};
    }
  `,
  white: css`
    background-color: ${colors.white};
    border-color: ${colors.white};
    color: ${colors.black};

    :before {
      background-color: ${colors.grayText};
    }
  `,
  outline_white: css`
    background: none;
    border-color: ${colors.white};
    color: ${colors.white};

    :before {
      background-color: ${colors.grayText};
    }
  `,
  outline_black: css`
    background: none;
    border-color: ${colors.black};
    color: ${colors.black};

    :before {
      background-color: ${colors.grayText};
    }
  `,
  outline_danger: css`
    background: none;
    border-color: ${colors.danger};
    color: ${colors.danger};

    :before {
      background-color: ${colors.grayText};
    }
  `,
  minimal_black: css`
    background: none;
    border: none;
    font-weight: 500;
    color: ${colors.black};

    :before {
      background-color: ${colors.grayText};
    }
  `,
  minimal_gray: css`
    background: none;
    border: none;
    font-weight: 500;
    color: ${colors.grayText};

    :before {
      background-color: ${colors.grayText};
    }
  `,
  minimal_gray_bg: css`
    background: ${colors.white};
    border: none;
    font-weight: 500;
    color: ${colors.grayText};

    :before {
      background-color: ${colors.grayText};
    }
  `,
  minimal_danger: css`
    background: none;
    border: none;
    font-weight: 500;
    color: ${colors.danger};

    :before {
      background-color: ${colors.grayText};
    }
  `,
};

const sizeMap: Record<ButtonSize, FlattenSimpleInterpolation> = {
  tiny: css`
    padding: 0.25rem 0.75rem;
    gap: 0.25rem;
  `,
  compact: css`
    padding: 0.5rem 0.875rem;
    gap: 0.5rem;
  `,
  normal: css`
    padding: 0.875rem 1rem;
    gap: 0.75rem;
  `,
};

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  overflow: hidden;
  padding: 0.25rem 0.75rem;
  border: 1px solid;
  outline: none;

  font-family: ${HELVETICA};
  font-weight: bold;

  &:not(.btn_pill) {
    border-radius: 0.15rem;
  }

  &.btn_pill {
    border-radius: 50vmin;
  }

  &.loading {
    pointer-events: none;
    cursor: wait;

    & > :not(.btn_loading_overlay) {
      opacity: 0;
    }
  }

  & .btn_loading_overlay {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${Object.entries(kindMap).map(
    ([key, value]) => css`
      &.btn_kind_${key} {
        ${value}
      }
    `
  )}

  ${Object.entries(sizeMap).map(
    ([key, value]) => css`
      &.btn_size_${key} {
        ${value}
      }
    `
  )}


&.bg-white {
    background: white;
  }

  :focus-visible {
    outline: 2px solid black;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  :before {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  :not(:disabled) {
    :hover:before {
      opacity: 0.05;
    }
    :active:before {
      opacity: 0.1;
    }
  }
`;
