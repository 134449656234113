/* eslint-disable no-case-declarations */
import { clamp, debounce, first } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../globalStyles';
import { getFullName } from '../../modelUtils/users';
import { Icon } from '../Icons';
import { useVideoContextV2 } from './VideoProvider';

const POPOVER_WIDTH = 310;
const POPOVER_HEIGHT = 266;

interface VideoPopoverProps {
  handleReturnToCall: () => void;
}

// @TODO indicate number of providers here
export const VideoPopover = React.memo(({ handleReturnToCall }: VideoPopoverProps) => {
  const ctx = useVideoContextV2();

  const initialPosition = {
    top: window.innerHeight - POPOVER_HEIGHT - 10,
    left: window.innerWidth - POPOVER_WIDTH - 10,
  };
  const [position, setPosition] = useState(initialPosition);
  const [dragging, setDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const didDrag = useRef(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const rootEl = document.querySelector('#root');

    const observer = new ResizeObserver(
      debounce(entries => {
        const observedEntry = first(entries);
        if (!observedEntry) return;
        const { bottom, right } = observedEntry.contentRect;

        setPosition(v => {
          const newLeft = right - POPOVER_WIDTH;
          const newTop = bottom - POPOVER_HEIGHT;
          return {
            left: clamp(didDrag.current ? v.left : newLeft, 0, newLeft),
            top: clamp(didDrag.current ? v.top : newTop, 0, newTop),
          };
        });
      }, 100)
    );

    if (rootEl) {
      observer.observe(rootEl);
    }

    return () => {
      if (rootEl) {
        observer.unobserve(rootEl);
      }
    };
  }, [setPosition, didDrag]);

  const handleMouseDrag = useCallback(
    (event: MouseEvent) => {
      // the dragOffset represents where on the video the user originally clicked, and the
      // calculation using it moves the video in such a way that it remains stationary
      // relative to the user's mouse
      setPosition({
        left: clamp(event.clientX - dragOffset.x, 0, window.innerWidth - POPOVER_WIDTH),
        top: clamp(event.clientY - dragOffset.y, 0, window.innerHeight - POPOVER_HEIGHT),
      });
      didDrag.current = true;
    },
    [setPosition, dragOffset, didDrag]
  );

  useEffect(() => {
    if (dragging) {
      const handleMouseUp = () => {
        setDragging(false);
      };
      document.addEventListener('mousemove', handleMouseDrag);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mousemove', handleMouseDrag);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [dragging, handleMouseDrag, setDragging]);

  const onMouseDown = (event: React.MouseEvent) => {
    if (event.button === 0 /* left button */) {
      setDragOffset({
        x: event.clientX - (containerRef.current?.offsetLeft ?? 0),
        y: event.clientY - (containerRef.current?.offsetTop ?? 0),
      });
      didDrag.current = false;
      setDragging(true);
    }
  };

  const onClickTitle = () => {
    if (!dragging && !didDrag.current) {
      handleReturnToCall();
      setPosition(initialPosition);
    }
  };

  return (
    <div
      role="presentation"
      style={ctx.showingPopover ? { ...popoverWrapperStyle, ...position } : staticWrapperStyle}
      onMouseDown={onMouseDown}
      ref={containerRef}
    >
      {ctx.showingPopover && ctx.sessionState && (
        <TitleBar onClick={onClickTitle}>
          <Text.body kind="white" className="truncate mv0">
            Video call with {getFullName(ctx.sessionState.user)}
          </Text.body>
          <Icon icon="iconsExternalLinkWhiteSvg" alt="Open" />
        </TitleBar>
      )}
      {/* it's important that this element not be unmounted by react, otherwise
        the video will get disconnected from the call */}
      <div
        id="patientView"
        style={{
          ...(ctx.showingPopover ? popoverVideoStyle : staticVideoStyle),
          cursor: dragging ? 'grabbing' : 'grab',
        }}
      />
      {ctx.showingPopover && ctx.isSessionConnected && !ctx.isUserConnected && (
        <WaitingText>Waiting for patient</WaitingText>
      )}
      {ctx.showingPopover && !ctx.isSessionConnected && <WaitingText>Reconnecting...</WaitingText>}
    </div>
  );
});

const staticWrapperStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  pointerEvents: 'none',
} as const;
const popoverWrapperStyle = {
  position: 'absolute',
  zIndex: 10,
  background: 'black',
  borderRadius: 5,
  padding: 5,
  width: POPOVER_WIDTH,
  height: POPOVER_HEIGHT,
  userSelect: 'none',
} as const;
const staticVideoStyle = {
  width: '100%',
  height: '100%',
} as const;
const popoverVideoStyle = {
  height: 225,
  zIndex: 11,
} as const;

const TitleBar = styled.a`
  background: black;
  color: white;
  padding: 5px;
  white-space: nowrap;
  font-weight: bold;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  cursor: pointer;
`;

const WaitingText = styled.div`
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  pointer-events: none;
`;
