import { AppointmentTemplateFragment, AppointmentUserV2Fragment, CareType } from '../../graphQL';
import { Nullable } from '../../types';
import { AppointmentDetails, AppointmentType } from './types';

export const findIntakeApptTemplate = (
  careType: CareType,
  templates: AppointmentTemplateFragment[]
) => {
  return templates.reduce((result, template, idx) => {
    const hasCareType = template.careType === careType;
    const isIntake = template.appointmentType === 'intake';
    return hasCareType && isIntake ? ([template, idx] as const) : result;
  }, null as Nullable<readonly [AppointmentTemplateFragment, number]>);
};

export const canBookIntake = (
  careType: CareType,
  patient: Pick<
    AppointmentUserV2Fragment,
    'therapyComplete' | 'therapyUpcoming' | 'psychComplete' | 'psychUpcoming'
  >
) => {
  const { therapyComplete, therapyUpcoming, psychComplete, psychUpcoming } = patient;
  const careTypes: Record<CareType, boolean> = {
    [CareType.Psychiatry]: !psychUpcoming && !psychComplete,
    [CareType.Therapy]: !therapyUpcoming && !therapyComplete,
  };
  return careTypes[careType];
};

export const getInitialAppointmentDetails = (
  user: AppointmentUserV2Fragment,
  careType: CareType
) => {
  const { appointmentTemplates } = user;
  const appointmentType = determineAppointmentType(user, careType);

  return appointmentTemplates.reduce((details, template, templateIndex) => {
    const hasCareType = template.careType === careType;
    const hasApptType = template.appointmentType === appointmentType;

    if (hasCareType && hasApptType) {
      return {
        appointmentType,
        templateIndex,
        careType,
        duration: template.duration,
      } as AppointmentDetails;
    }

    return details;
  }, null as Nullable<AppointmentDetails>);
};

const determineAppointmentType = (user: AppointmentUserV2Fragment, careType: CareType) => {
  const careTypeToApptType: Record<CareType, AppointmentType> = {
    [CareType.Psychiatry]: user.psychComplete ? 'checkin' : 'intake',
    [CareType.Therapy]: user.therapyComplete ? 'checkin' : 'intake',
  };
  return careTypeToApptType[careType];
};

export const getIntakeApptData = (
  careType: CareType,
  appointmentTemplates: AppointmentTemplateFragment[]
) => {
  const result = findIntakeApptTemplate(careType, appointmentTemplates);
  if (!result) return;
  const [template, templateIndex] = result;
  return {
    templateIndex,
    duration: template.duration,
    careType,
    appointmentType: 'intake',
  };
};
