import { entries, groupBy, size, take } from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../globalStyles';
import { Availability, Provider } from '../../../graphQL';

type SimpleProps = {
  provider: Pick<Provider, 'id'> & { upcomingAvailability?: Pick<Availability, 'start'>[] };
  maxDisplayableDates?: number;
  maxDisplayableTimes?: number;
};

export const SimpleAvailability = ({
  provider,
  maxDisplayableDates = 4,
  maxDisplayableTimes = 3,
}: SimpleProps) => {
  const byDate = groupBy(provider.upcomingAvailability ?? [], a =>
    moment(a.start).format('ddd, MMM DD')
  );

  // if there are no appointments in the next two weeks, render the below component instead
  const next2Weeks = moment().add(2, 'weeks').toDate();

  const weekCheck =
    size(byDate) > 0 &&
    !moment(take(entries(byDate), 1)[0][1][0].start).isBefore(moment(next2Weeks));

  if (weekCheck) {
    return (
      <UpcomingAppointmentsContainer>
        <Text.label className="mb2">Upcoming Availability</Text.label>
        <Text.bodySmall className="mt1">
          <span className="b">{take(entries(byDate), 1)[0][0]}</span>
          <span>
            {' -- '} {moment(take(entries(byDate), 1)[0][1][0].start).format('h:mma')}
          </span>
        </Text.bodySmall>
      </UpcomingAppointmentsContainer>
    );
  }

  return (
    <UpcomingAppointmentsContainer>
      <Text.label className="mb2">Upcoming Availability</Text.label>
      {take(entries(byDate), maxDisplayableDates).map(([date, slots]) => (
        <Text.bodySmall key={date} className="mb1">
          <span className="b">{date}</span>
          <span>
            {' -- '}
            {take(slots, maxDisplayableTimes)
              .map(s => moment(s.start).format('h:mma'))
              .join(', ')}
            {slots.length > maxDisplayableTimes ? ' + more' : ''}
          </span>
        </Text.bodySmall>
      ))}
      {size(byDate) <= 0 && <Text.bodySmall className="mt1">None</Text.bodySmall>}
    </UpcomingAppointmentsContainer>
  );
};

const UpcomingAppointmentsContainer = styled.div`
  margin: auto 0 auto 0;
  width: fit-content;
`;
