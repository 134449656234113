import React from 'react';
import { createIcon, Path } from './index';

export const IconChevronRight = createIcon({
  viewBox: '0 0 24 24',
  title: 'A right chevron',
  path: [
    <Path
      d="M9 18L15 12L9 6"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
