import { chain, Dictionary, isString } from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Text } from '../../globalStyles';
import { useDrilldownContext } from '../../Pages/Users/helpers';
import { orNullFactory } from '../OrNull';

type InfoDisplayProps = {
  items: Record<string, string[]>;
};
const InfoDisplay = ({ items }: InfoDisplayProps) => {
  const { user } = useDrilldownContext();
  const medHistory = user.latestMedicalHistoryResult;
  const medHistoryLookup = chain(medHistory?.questionnaires ?? [])
    .flatMap(questionnaire => questionnaire.questions)
    .reduce((acc, q) => (q.value ? { ...acc, [q.key]: q.value } : acc), {} as Dictionary<any>)
    .value();

  return (
    <InfoContainer>
      <div className="mb4">
        <Text.label className="mb2" kind="success">
          Patient SUBMITTED: Past MEDICAL History
        </Text.label>
        {medHistory ? (
          <p className="f6">Submitted {moment(medHistory.createdAt).format('L')}</p>
        ) : (
          <p className="f6 i">Patient has not yet submitted medical history</p>
        )}
      </div>
      {medHistory &&
        Object.entries(items).map(([text, keys], idx) => {
          const textArr = keys.reduce((acc, cur) => {
            const val = medHistoryLookup[cur];
            if (Array.isArray(val)) acc.push(val.join(', '));
            else if (isString(val)) acc.push(val);
            return acc;
          }, new Array<string>());

          const textVal = textArr.join('; ') || 'N/A';

          return (
            <div key={idx} className="flex mb3">
              <div className="flex-1">
                <p className="f6 b">{text}</p>
              </div>
              <div className="flex-1">
                <p className="f6 ml2">{textVal}</p>
              </div>
            </div>
          );
        })}
    </InfoContainer>
  );
};

const FamilyHistory = () => (
  <InfoDisplay
    items={{
      'Has anyone in your family been diagnosed with the following conditions?': [
        'family-diagnosed-condition',
      ],
    }}
  />
);

const PsychHistory = () => (
  <InfoDisplay
    items={{
      'Have you ever been diagnosed with a mental health condition?': [
        'mental-health-condition',
        'other-any-additional-issues',
      ],
      'Have you taken any steps towards ending your life?': ['taken-steps-ending-life'],
      'Have you ever been in a psychiatric hospital or emergency room?': [
        'psychiatric-hospital-emergency-room',
      ],
      'Have you ever been prescribed medications for your mental health?': [
        'prescribed-medication-mental-health',
        'prescribed-medication-mental-health-detail',
      ],
      'Have you ever engaged in self-harm behavior, such as cutting, burning or bruising yourself?':
        ['self-harm', 'self-harm-explain'],
      'Are you currently working with a therapist?': [
        'working-with-therapist',
        'working-with-therapist-name',
        'working-with-therapist-contact',
      ],
    }}
  />
);
const RosTitle = () => (
  <InfoDisplay
    items={{
      'Have you ever had or been told you had the following medical problems:': [
        'past-medical-problems',
      ],
    }}
  />
);

export const QuestionInfo = orNullFactory({
  'history-family': FamilyHistory,
  'history-psychiatric': PsychHistory,
  'history-therapy': PsychHistory,
  'history-ros': RosTitle,
});

const InfoContainer = styled.div`
  background-color: #40962114;
  padding: 24px;
`;
