import { Accordion, Panel, PanelOverrides } from 'baseui/accordion';
import { isNil } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useCurrentProvider } from '../../Components/Permissions';
import { AppView } from '../../graphQL';

const StyledFaq = styled.div`
  ul {
    list-style-type: circle;
  }

  & li,
  & p {
    margin-bottom: 1em;
  }
`;

type FaqItem = {
  title: string;
  content: React.ReactNode;
};

const mcpFAQ: FaqItem[] = [
  {
    title: 'How do I make a referral?',
    content: (
      <StyledFaq>
        <ol>
          <li>At the bottom left corner of the screen, click &quot;Add Student&quot;.</li>
          <li>You can also click this button at the top of the Patient caseload list.</li>
          <li>
            Fill out the patient&apos;s information - be sure to use their university email address.
          </li>
          <li>Add collaborator, if relevant.</li>
          <ul>
            <li>
              Collaborators receive alerts for ongoing treatment progress and are considered the
              main contact at the university for this patient.
            </li>
          </ul>
          <li>Click submit - the referral has officially been made!</li>
        </ol>
        <p>
          After you make the referral, the patient will automatically receive an email with next
          steps for creating their account and onboarding with Mantra. There are two things you can
          do to streamline the intake process: schedule the appointment and send a referral note.
          These steps aren&apos;t required, but helps improve patient engagement and continuity of
          care with the Mantra provider.
        </p>
      </StyledFaq>
    ),
  },
  {
    title: 'How do I schedule an appointment?',
    content: (
      <StyledFaq>
        <ol>
          <li>Go to your Patient caseload list and click into the patient&apos;s chart.</li>
          <li>At the top right hand corner, click &quot;Book appointment&quot;.</li>
          <ul>
            <li>
              If it&apos;s an initial consult, you will also see a &quot;Schedule&quot; button in
              the list of incomplete tasks.
            </li>
          </ul>
          <li>
            Choose a provider. This will open up this provider&apos;s dedicated schedule to the
            school.
          </li>
          <li>
            If there is minimal upcoming availability, look at one of the other provider&apos;s
            schedules by clicking &quot;Back&quot; and choosing another provider. Note: This is only
            relevant for contracts with more than one provider.
          </li>
          <li>
            Once you and the patient decide on a date and time, click &quot;Book appointment&quot;.
          </li>
        </ol>
        <p>
          Scheduling an appointment isn&apos;t required, but it helps improve patient engagement and
          accountability.
        </p>
      </StyledFaq>
    ),
  },
  {
    title: 'How do I send a referral note?',
    content: (
      <StyledFaq>
        <p>
          After making the referral, you can complete a referral note. Go to the patient&apos;s
          profile and do one of the following:
        </p>
        <p>Option 1: Fill out a referral note within the MCP</p>
        <ol>
          <li>
            Click &quot;Complete Referral&quot; on the main page of the patient&apos;s profile.
          </li>
          <li>
            Fill out the referral form with relevant treatment information for the Wellround
            provider.
          </li>
          <li>
            Click ‘eSign’ at the bottom of the note and Submit. *Note: You can save your progress at
            any time, but no one else will see the referral note until it is eSigned and submitted.*
          </li>
        </ol>
        <p>Option 2: Upload a PDF export of a referral note from your university EMR</p>
        <ol>
          <li>
            Go to the patient&apos;s profile and click &quot;Upload File&quot; at the top of the
            screen.
          </li>
          <li>Select the file you&apos;d like to upload.</li>
          <li>
            If the file successfully uploaded, you&apos;ll see a green message in the bottom right
            corner of the screen.
          </li>
          <li>
            On the &quot;Overview&quot; tab of the patient&apos;s profile, click &quot;Mark as
            Complete&quot; on the ‘Complete Referral Note&apos; task. This student&apos;s referral
            is now complete and they will no longer show up in the ‘Incomplete Referrals&apos; list
            on your dashboard.
          </li>
        </ol>
        <p>
          Your note will now show up in our internal EMR for the Wellround provider to review prior
          to the initial visit, helping set clinical expectations. Completing a referral isn&apos;t
          required, but it helps improve continuity of care with the new provider.
        </p>
      </StyledFaq>
    ),
  },
  {
    title: 'How do I message the Mantra provider about a patient?',
    content: (
      <StyledFaq>
        <ol>
          <li>Go to your Patient caseload list and click into the patient&apos;s chart.</li>
          <li>
            On the horizontal toolbar below the patient&apos;s name, click &quot;Collaboration
            Team&quot;.
          </li>
          <li>Type in your message, and send!</li>
        </ol>
        <p>
          Note: Providers can answer within 24 hours. The &quot;Messages&quot; Tab on the left
          toolbar will show green bubble notifications if there is a pending message for you to
          view, and you will also get an email notification informing you that there&apos;s a new
          message in the portal.
        </p>
      </StyledFaq>
    ),
  },
  {
    title: 'How do I know if a referred patient received Mantra services?',
    content: (
      <StyledFaq>
        <ol>
          <li>Go to your Patient caseload list and click into the patient&apos;s chart.</li>
          <li>On the horizontal toolbar, click &quot;Appointments&quot;.</li>
          <li>
            If a patient was seen by one of the Mantra providers, you will see a list of
            appointments with adherence outcomes (Complete, Rescheduled, Cancel, No show). This
            informs you whether an appointment was scheduled and the outcome for that appointment.
          </li>
          <li>
            To view more specific treatment information on a completed visit, click the
            &quot;Notes&quot; tab in the patient&apos;s chart and click into any of the treatment
            notes (will be ordered by most recent visit).
          </li>
        </ol>

        <p>
          Note: Your Dashboard tab on the left sidebar will also show a summary of the more recent
          three ‘New Intake Notes’.
        </p>
      </StyledFaq>
    ),
  },
  {
    title: 'How do I access a patient’s safety plan?',
    content: (
      <StyledFaq>
        <ol>
          <li>Go to your Patient caseload list and click into the patient&apos;s chart.</li>
          <li>On the horizontal toolbar, click &quot;Safety&quot;.</li>
          <li>
            If you are activating the safety plan for the patient, click &quot;Activate Safety
            Plan&quot;. This will send an email notification to the Mantra provider and the patient,
            and the safety plan will now appear in the Patient Portal.
          </li>
          <li>
            Alternatively, if a Mantra provider activated the safety plan you can view it in this
            tab.
          </li>
        </ol>
        <p>
          Note: Safety plans can only be activated and edited by members of the patient&apos;s care
          team.
        </p>
      </StyledFaq>
    ),
  },
  {
    title: 'Can I add another staff member to a patient’s collaboration team?',
    content: (
      <StyledFaq>
        <ol>
          <li>Go to your Patient caseload list and click into the patient&apos;s chart.</li>
          <li>On the right sidebar, click &quot;Edit&quot; next to the Patient Info section.</li>
          <li>
            This will open up a box to adjust a patient&apos;s primary collaborator, including
            adding and removing staff.
          </li>
        </ol>
        <p>
          Note: Being assigned to a patient&apos;s collaboration team allows users to view the
          patient chart in their patient list, access the collaboration team messaging to
          communicate with a Mantra provider, activate and edit the patient&apos;s safety plan, and
          receive email notifications on treatment updates.
        </p>
      </StyledFaq>
    ),
  },
  {
    title:
      'How do I access a patient’s profile if needed for coverage or for a crisis or emergency situation?',
    content: (
      <StyledFaq>
        <p>
          All patients enrolled in Mantra at your college or university are searchable on an
          &quot;as needed&quot; basis, even if you&apos;re not on the student&apos;s collaboration
          team. To access the chart, follow these steps:
        </p>
        <ol>
          <li>
            On the left sidebar, click the &quot;Students&quot; tab to access the caseload list
            (this may be empty if you are not listed on anyone&apos;s collaboration team).
          </li>
          <li>
            In the search function, type the name of the patient whose profile you need to view.
            This person should now show up in the list.
          </li>
          <li>Click on the patient&apos;s name to enter the profile.</li>
        </ol>
        <p>Important notes for crisis situations:</p>
        <ul>
          <li>
            A user can add themselves to a student&apos;s collaboration team if needed for coverage
            or due to an emergency situation. They will then receive any necessary updates on this
            patient.
          </li>
          <li>
            If a user no longer needs to engage with this student&apos;s care, they can easily
            remove themselves from the student&apos;s collaboration team. They will no longer
            receive alerts regarding this patient, and they won&apos;t show up in their personal
            patient list.
          </li>
        </ul>
      </StyledFaq>
    ),
  },
];

const referralFAQ: FaqItem[] = [
  {
    title:
      'How do I know if a student should be referred to Mantra or should be sent to emergency resources?',
    content: (
      <StyledFaq>
        <p>
          <span className="i underline">
            Importantly, Mantra Health does not offer crisis or emergency services.
          </span>{' '}
          Therefore, the biggest determinant of whether a student can be referred to Mantra is if
          they are expressing high risk symptoms:
        </p>
        <ol>
          <li>Expressing a desire to hurt themselves</li>
          <li>Expressing a desire to hurt someone else</li>
        </ol>
        <p>
          <span className="underline">
            If at least one of the above is true, please refer the student to a local emergency
            resource.
          </span>{' '}
          All other students can be referred to Mantra to be further evaluated by our mental health
          professionals. We aim to keep the time between referral and the first appointment within
          one week.
        </p>
      </StyledFaq>
    ),
  },
  {
    title: 'How do I explain what Mantra Health offers to students?',
    content: (
      <StyledFaq>
        <p>
          The university/college staff play a key role in the students&apos; care pathway to
          connecting with Mantra, particularly by helping students understand the benefits of
          engaging in the services. You can explain a summary of the below:
        </p>
        <p>
          &quot;Mantra Health is a service we offer to make sure you have access to mental health
          care through the school. It&apos;s a telemental health platform that helps connect you to
          board-certified psychiatry providers or licensed therapists to address your specific needs
          through video and messaging, at your convenience. Your provider will work with you to
          establish a personalized treatment plan, which may include evidence-based therapy,
          medication management, lifestyle changes, and tools to track your progress.&quot;
        </p>
        <p>
          Importantly, it&apos;s often helpful to explain Mantra Health as being included in the
          resources provided by the school, as opposed to a completely separate organization. This
          message helps increase student buy-in, as they&apos;ve often already built trust and
          rapport with you on campus.
        </p>
      </StyledFaq>
    ),
  },
  {
    title: 'How do I know if a student should be referred for therapy or psychiatry (or both)?',
    content: (
      <StyledFaq>
        <p>
          Although we offer both care options (psychiatry and therapy), some students may only need
          one or the other. You can follow these guidelines:
        </p>
        <ul>
          <li>
            In general, if a student explicitly expresses that they&apos;ve either been on
            medications before and want to start again, or are currently on meds and need to get a
            refill or change in their prescription, then you can refer the student to our psychiatry
            provider.
          </li>
          <li>
            For all other students, we recommend first sending them to our therapy providers who can
            then decide whether a referral to psychiatry is appropriate.
          </li>
        </ul>
      </StyledFaq>
    ),
  },
  {
    title: 'Can I refer a student who is under 18 years old?',
    content: (
      <StyledFaq>
        <p>
          Mantra does not treat individuals under 18 years old at the moment. If you have a student
          who needs mental health support and is under 18, you can either call the Mantra support
          line for referral help at 1-800-464-2083, or refer directly to one of the following
          community resources:
        </p>
        <ul>
          <li>
            Community Health Centers of Pinellas, Inc. <span className="b">(727-824-8181)</span>
          </li>
          <li>
            Suncoast Center, Inc. <span className="b">(727-388-1220)</span>
          </li>
        </ul>
      </StyledFaq>
    ),
  },
  {
    title: 'What are the best practices in scheduling an appointment?',
    content: (
      <StyledFaq>
        <p>
          In our experience, having the appointment scheduled at the time of the referral increases
          likelihood that the student will attend that appointment. If possible, you can schedule
          the visit before the student leaves your office by following these steps:
        </p>
        <ol>
          <li>
            After completing the New Student form, go to your Student List, which shows all students
            that have been referred by you.
          </li>
          <li>
            Click on the &quot;Schedule Therapy&quot; or &quot;Schedule Psychiatry&quot; option
            (depending on their care type). This will open up our booking flow, with direct access
            to our providers&apos; schedules.
          </li>
        </ol>
      </StyledFaq>
    ),
  },
  {
    title: 'How do I communicate what the student is required to do after the referral is made?',
    content: (
      <StyledFaq>
        <p>
          After referring a student to Mantra, it&apos;s helpful to explain the next steps to set
          expectations and keep the student engaged:
        </p>
        <ol>
          <li>
            &quot;After I add your account, check your <span className="b">school</span> email for
            an invite from Mantra Health to complete your profile.&quot;
          </li>
          <li>
            &quot;Once your account is activated, you can log into the Patient Portal using your
            school email address at www.mantrahealth.com.&quot;
          </li>
          <li>
            &quot;Fill out the onboarding forms in the Patient Portal - this takes about 10 minutes.
            Be sure to do this before your appointment! This helps your provider focus the time
            spent with you on discussing your goals and establishing a treatment plan that&apos;s
            right for you.&quot;
          </li>
          <li>
            Join your first scheduled appointment with your provider through the Mantra Patient
            Portal (accessed at www.mantrahealth.com).
          </li>
        </ol>
      </StyledFaq>
    ),
  },
];

const overrideFactory = (isLastRow?: boolean): PanelOverrides<any> => ({
  Header: {
    style: { fontWeight: 'bold', ...(isLastRow ? { borderBottomColor: 'transparent' } : {}) },
  },
  Content: {
    style: { backgroundColor: 'white', ...(isLastRow ? { borderBottomColor: 'transparent' } : {}) },
  },
});

const FAQS: Record<AppView, FaqItem[] | null> = {
  mcp: mcpFAQ,
  referral: referralFAQ,
  oz: null,
};

export const FaqTab = () => {
  const { appView } = useCurrentProvider();
  const faq = FAQS[appView];
  if (isNil(faq)) return null;
  return (
    <div>
      <div className="ba b--black-10 br2 pv3 ph4 bg-white mw7">
        <Accordion>
          {faq.map((f, i) => (
            <Panel key={f.title} title={f.title} overrides={overrideFactory(i === faq.length - 1)}>
              {f.content}
            </Panel>
          ))}
        </Accordion>
      </div>
    </div>
  );
};
