import React, { createContext, useState, useContext, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { PopoverTab, TabProps } from './PopoverTab';

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  z-index: 2;
`;

export type TabDetails = Pick<TabProps, 'title' | 'renderBody' | 'tabKey'>;

interface Context {
  pushTab: (details: TabDetails) => void;
  scrollToTop: (opts?: { smooth?: boolean }) => void;
}

interface Props {
  children: React.ReactNode;
}

const PopoverTabContext = createContext<Context | null>(null);
export const usePopoverTabContext = () => {
  const ctx = useContext(PopoverTabContext);
  if (!ctx) throw new Error('Context is missing!');
  return ctx;
};

export function PopoverTabContainer({ children }: Props) {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [tabs, setTabs] = useState<TabDetails[]>([]);

  const scrollToTop = useCallback(
    (opts?: { smooth?: boolean }) => {
      scrollRef.current?.scrollTo({ top: 0, behavior: opts?.smooth ? 'smooth' : undefined });
    },
    [scrollRef]
  );

  const ctx: Context = {
    pushTab: props => {
      if (tabs.some(t => t.tabKey === props.tabKey)) return;
      setTabs([...tabs, props]);
    },
    scrollToTop,
  };
  const handleClose = (idx: number) => {
    setTabs(val => [...val.slice(0, idx), ...val.slice(idx + 1)]);
  };

  return (
    <PopoverTabContext.Provider value={ctx}>
      <Container>
        {tabs.map((tab, idx) => (
          <PopoverTab ref={scrollRef} key={tab.tabKey} {...tab} onClose={() => handleClose(idx)} />
        ))}
      </Container>
      {children}
    </PopoverTabContext.Provider>
  );
}
