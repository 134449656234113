// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable global-require */

module.exports = (deps = {}) => {
  const getNativeEnumerateDevices = deps.getNativeEnumerateDevices || require('./getNativeEnumerateDevices.js')();
  const getNativeMediaDevices = deps.getNativeMediaDevices || require('./get-native-media-devices')();
  const { getCurrentAudioOutputDeviceId } = deps.currentAudioOutputDevice || require('../ot/current-audio-output-device');
  const env = deps.env || require('./env');
  const windowMock = deps.global || global;
  const deviceHelpers = {};

  // /
  // Device Helpers
  //
  // Support functions to enumerating and querying device info
  //

  const deviceKindsMap = {
    audio: 'audioInput',
    video: 'videoInput',
    audioinput: 'audioInput',
    videoinput: 'videoInput',
    audiooutput: 'audioOutput',
    audioInput: 'audioInput',
    videoInput: 'videoInput',
    audioOutput: 'audioOutput',
  };

  const enumerateDevices = () => {
    // Our mocking currently requires that this be re-evaluated each time.
    const fn = getNativeEnumerateDevices();
    return fn();
  };

  deviceHelpers.getNativeMediaDevices = () => getNativeMediaDevices();

  // Indicates whether this browser supports the enumerateDevices (getSources) API.
  //
  deviceHelpers.hasEnumerateDevicesCapability = () =>
    typeof getNativeEnumerateDevices() === 'function';

  deviceHelpers.hasDeviceChangeCapability = () => {
    const mediaDevices = getNativeMediaDevices();
    return 'ondevicechange' in mediaDevices;
  };

  deviceHelpers.hasSetSinkIdCapability = () =>
    'setSinkId' in windowMock?.HTMLMediaElement.prototype;

  deviceHelpers.hasAudioOutputApiSupport = () =>
    deviceHelpers.hasSetSinkIdCapability() && deviceHelpers.hasDeviceChangeCapability();

  // OPENTOK-40733, see https://bugs.webkit.org/show_bug.cgi?id=209739#c2
  // multiple calls to enumerateDevices fail to return video devices
  // note that this causes any devices without a camera (mac minis) to make a video gum call
  // we believe this is worth it to prevent safari video breaking
  deviceHelpers.hasEnumerateDevicesBug = () => env.isSafari && env.version === 13.1;

  deviceHelpers.getMediaDevices = () => {
    if (!deviceHelpers.hasEnumerateDevicesCapability()) {
      return Promise.reject(
        new Error('This browser does not support enumerateDevices APIs')
      );
    }

    return enumerateDevices()
      .then(devices =>
        // Normalise the device kinds
        devices.map(device => ({
          deviceId: device.deviceId || device.id,
          label: device.label,
          kind: deviceKindsMap[device.kind],
        })));
  };

  deviceHelpers.getInputMediaDevices = () =>
    deviceHelpers.getMediaDevices()
      .then(devices =>
        devices.filter(device => /^(audio|video)Input$/.test(device.kind)));

  deviceHelpers.getAudioOutputMediaDevices = () =>
    deviceHelpers.getMediaDevices()
      .then(devices =>
        devices
          .filter(device => device.kind === 'audioOutput')
          .map(({ deviceId, label }) => ({ deviceId, label }))
      );

  deviceHelpers.shouldAskForDevices = () => {
    if (!deviceHelpers.hasEnumerateDevicesCapability()) {
      // Assume video and audio exists if enumerateDevices is not available
      return Promise.resolve({ video: true, audio: true });
    }

    return deviceHelpers.getInputMediaDevices()
      .then((devices) => {
        if (devices.length === 0) {
          // If no devices reported, might as well try anyway, maybe it was just an issue with
          // device enumeration.
          return { video: true, audio: true };
        }

        const audioDevices = devices.filter(device => device.kind === 'audioInput');
        const videoDevices = devices.filter(device => device.kind === 'videoInput');

        if (deviceHelpers.hasEnumerateDevicesBug() && videoDevices.length === 0) {
          return {
            audio: true, video: true,
          };
        }

        return {
          video: videoDevices.length > 0,
          audio: audioDevices.length > 0,
          videoDevices,
          audioDevices,
        };
      })
      .catch(() =>
        // There was an error. It may be temporally. Just assume
        // all devices exist for now.
        ({ video: true, audio: true }));
  };

  deviceHelpers.getDefaultAudioOutputDeviceId = (devices) => {
    if (!Array.isArray(devices) || devices.length === 0) {
      return '';
    }

    const { deviceId } = devices.find(device => device.deviceId === 'default') || devices[0];
    return deviceId;
  };

  deviceHelpers.hasDevice = (devicesList, deviceId) => {
    let devices = devicesList;
    if (!Array.isArray(devices)) {
      devices = [];
    }
    return devices.some(device => device.deviceId === deviceId);
  };

  deviceHelpers.getActiveAudioOutputDevice = async () => {
    let devices;

    try {
      devices = await deviceHelpers.getAudioOutputMediaDevices();
    } catch (error) {
      throw error;
    }

    // In both Safari and FF (without the flag that enables the setSinkId feature),
    // the browser won't return any output device when using enumerateDevices API.
    // Also, there could be a case in which there are no output devices.
    // In these cases, we will just return an object with empty device info.
    if (devices.length === 0) {
      return { deviceId: null, label: null };
    }

    // Let's find the current output device object in the list.
    let currentDevice = devices.find(device => device.deviceId === getCurrentAudioOutputDeviceId());
    if (!currentDevice) {
      // The current audio output device is not in the list, let's return the default device instead
      const defaultDeviceId = deviceHelpers.getDefaultAudioOutputDeviceId(devices);
      currentDevice = devices.find(device => device.deviceId === defaultDeviceId);
    }

    return currentDevice;
  };

  return deviceHelpers;
};
