import { Modal, SIZE } from 'baseui/modal';
import { BarController, BarElement, CategoryScale, Chart, LinearScale, Tooltip } from 'chart.js';
import { get, keys } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useEvents } from '../../../../Components/Events/EventsProvider';
import { Dot } from '../../../../Components/Flair';
import { Modal as MantraModal, Text } from '../../../../globalStyles';
import { colorsAlternative, getChartConfig } from './config';
import { generateDataBreakdown } from './utils';

Chart.register(BarController, CategoryScale, LinearScale, BarElement, Tooltip);

type ModalProps = {
  categoryKey: string;
  dataGroups: ReturnType<typeof generateDataBreakdown>;
  onClose: () => void;
};

export const CategoryModal = ({ categoryKey, dataGroups, onClose }: ModalProps) => {
  const metrics = useEvents();
  const [delayForRef, setDelayForRef] = useState(false);
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    metrics.track(`reporting.diagnosis.modal.open`, { categoryKey });
    return () => metrics.track(`reporting.diagnosis.modal.close`, { categoryKey });
  }, [categoryKey, metrics]);

  const { subCategories, primaryPercent, additionalPercent } = useMemo(
    () => get(dataGroups, categoryKey),
    [dataGroups, categoryKey]
  );

  useEffect(() => {
    setTimeout(() => setDelayForRef(true), 200);
  }, [setDelayForRef]);

  useEffect(() => {
    if (!ref.current || !delayForRef) return;

    const chart = new Chart(
      ref.current,
      getChartConfig({
        breakdown: subCategories,
        colors: colorsAlternative,
      })
    );
    return () => {
      chart.destroy();
    };
  }, [subCategories, delayForRef]);

  const categoryCount = keys(subCategories).length;
  return (
    <Modal isOpen onClose={onClose} unstable_ModalBackdropScroll size={SIZE.auto}>
      <MantraModal.body>
        <MantraModal.header className="tc">{categoryKey}</MantraModal.header>
        <div className="tc">
          <Text.bodyGrey>
            Present in <b>{(primaryPercent + additionalPercent).toFixed(1)}%</b> of students
          </Text.bodyGrey>
          <Text.bodyGrey>
            Primary diagnosis for <b>{primaryPercent.toFixed(1)}%</b> of students
          </Text.bodyGrey>
        </div>
        <CanvasContainer categoryCount={categoryCount}>
          <div className="absolute absolute--fill">
            <canvas ref={ref} />
          </div>
        </CanvasContainer>
        <div className="tc">
          <div className="mr4 dib">
            <Dot color={colorsAlternative.primary} size="1rem">
              <Text.caption className="b">Primary Diagnosis</Text.caption>
            </Dot>
          </div>
          <div className="dib">
            <Dot color={colorsAlternative.secondary} size="1rem">
              <Text.caption className="b">Additional Diagnosis</Text.caption>
            </Dot>
          </div>
        </div>
      </MantraModal.body>
    </Modal>
  );
};

const CanvasContainer = styled.div<{ categoryCount: number }>`
  position: relative;
  // Desired min width of every bar = ((categoryCount + 1) * 42)px
  // Top + Bottom padding (in config) = 32px
  min-height: min(${p => (p.categoryCount + 1) * 42 + 32 * 2}px, 700px);
  @media only screen and (min-width: 650px) {
    min-width: 550px;
  }
`;
