import { isNil, last } from 'lodash';
import flatMap from 'lodash/flatMap';
import {
  AdminUserQuery,
  GetAllNotesForUserQuery,
  QuestionnaireResult,
  SectionFormat,
  TriageInfo,
  UserDemographics,
} from '../graphQL';
import { DeepPartial } from '../types';

type MedicalHistory = NonNullable<AdminUserQuery['adminUser']['latestMedicalHistoryResult']>;
type Note = GetAllNotesForUserQuery['adminUser']['notes'][number];

export const formatMedicalHistoryAsNote = (medHistoryResult: MedicalHistory): Note => ({
  id: 0,
  key: medHistoryResult.uuid,
  createdAt: medHistoryResult.createdAt,
  updatedAt: medHistoryResult.updatedAt,
  submittedAt: medHistoryResult.createdAt,
  provider: { id: -1, name: '' },
  template: {
    key: medHistoryResult.uuid,
    title: 'Medical History',
    uuid: medHistoryResult.uuid,
    questions: flatMap(medHistoryResult.questionnaires, s =>
      s.questions.map(q => {
        let rawValue = q.value;
        // Empty arrays should appear as "None" instead of "Not answered"
        if (Array.isArray(rawValue) && rawValue.length === 0) rawValue = ['None'];
        const value = JSON.stringify(rawValue);
        const format = Array.isArray(rawValue) ? SectionFormat.Multitext : SectionFormat.Textarea;
        return { title: q.text, key: q.key, value, format, optional: true };
      })
    ),
  },
});

export const formatTriageInfoAsNote = (triageInfo: TriageInfo): Note => {
  const { assessmentId, createdAt, updatedAt } = triageInfo;
  return {
    id: 0,
    key: String(assessmentId),
    createdAt,
    updatedAt,
    provider: { id: -1, name: '' },
    submittedAt: createdAt,
    template: {
      key: String(assessmentId),
      title: 'Self Refer Screening 1',
      uuid: String(assessmentId),
      questions: triageInfo.answers.map(answer => {
        const { text, legend: list, key } = answer;
        let rawValue = answer.value;
        const legend = (list && list.join('\n\u2022 ')) ?? '';
        // Empty arrays should appear as "None" instead of "Not answered"
        if (Array.isArray(rawValue) && rawValue.length === 0) rawValue = ['None'];
        const value = JSON.stringify(rawValue);
        const format = Array.isArray(rawValue) ? SectionFormat.Multitext : SectionFormat.Textarea;
        const title = legend ? `${text}\n\u2022 ${legend}` : text;
        return { title, key, value, format, optional: true };
      }),
    },
  };
};

const fieldMap = {
  genderIdentity: 'Gender Identity',
  ethnicity: 'Ethnicity',
  sexualOrientation: 'Sexual Orientation',
  isInternationalStudent: 'International Student',
  academicProgram: 'Academic Program',
  yearInProgram: 'Year in Program',
};
const genderOptions = {
  agender: 'Agender',
  cisgenderMan: 'Cisgender Man',
  cisgenderWoman: 'Cisgender Woman',
  genderfluid: 'Genderfluid',
  nonBinary: 'Nonbinary',
  transgenderMan: 'Transgender Man',
  transgenderWoman: 'Transgender Woman',
  unsure: 'Unsure',
  preferNotToSay: 'Prefer not to say',
  notListed: 'Another option not listed here',
};
const raceOptions = {
  nativeAmerican: 'American Indian or Alaska Native',
  asian: 'Asian',
  africanAmerican: 'Black or African American',
  hispanic: 'Hispanic, Latino or Spanish origin',
  mideastern: 'Middle Eastern or North African',
  nativeHawaiian: 'Native Hawaiian or Other Pacific Islander',
  white: 'White',
  notListed: 'Some other race, ethnicity or origin',
};
const sexualOrientationOptions = {
  asexual: 'Asexual',
  bisexual: 'Bisexual',
  pansexual: 'Pansexual',
  straight: 'Straight',
  lesbian: 'Lesbian',
  gay: 'Gay',
  queer: 'Queer',
  unsure: 'Unsure',
  preferNotToSay: 'Prefer not to say',
  notListed: 'Another option not listed here',
};
const academicOptions = {
  tech: 'Associates or other 2-year technical degree',
  bachelor: 'Bachelor’s Program or other 4-year degree',
  postBachelor: 'Graduate, Professional, or other post-bachelor program',
  online: 'Online-only student',
  nonDegree: 'Continuing education and/or non-degree seeking student',
  notListed: 'Other',
  unsure: 'Unsure',
};

const academicYearsOptions = {
  one: '1',
  two: '2',
  three: '3',
  four: '4',
  five: '5',
  six: '6',
  seven: '7',
  eightOrMore: '8+',
  unsure: 'Unsure',
};

const isInternationalStudentOptions = {
  true: 'Yes',
  false: 'No',
};

const fieldOptionsMap: { [K in keyof typeof fieldMap]: Record<string, string> } = {
  genderIdentity: genderOptions,
  ethnicity: raceOptions,
  sexualOrientation: sexualOrientationOptions,
  isInternationalStudent: isInternationalStudentOptions,
  academicProgram: academicOptions,
  yearInProgram: academicYearsOptions,
};

export const formatDemographicsAsNote = (userDemographics: UserDemographics): Note => {
  const { id, createdAt, updatedAt } = userDemographics;
  const questions = Object.keys(fieldMap).map((field, index) => {
    const valueData = userDemographics[field as keyof UserDemographics];

    let valueKeys: string[];

    if (typeof valueData === 'string') {
      valueKeys = valueData.split(',');
    } else {
      valueKeys = [String(valueData)];
    }

    const mappedValues = valueKeys
      .map(key => fieldOptionsMap[field as keyof typeof fieldMap][key.trim()])
      .join(', ');

    return {
      title: fieldMap[field as keyof typeof fieldMap],
      key: String(index + 1),
      value: JSON.stringify(mappedValues),
      format: SectionFormat.Textarea,
      optional: false,
    };
  });

  return {
    id: 0,
    key: String(id),
    provider: { id: -1, name: '' },
    createdAt: createdAt,
    updatedAt: updatedAt,
    submittedAt: createdAt,
    template: {
      key: String(id),
      title: 'User Demographics Info',
      uuid: String(id),
      questions,
    },
  };
};

// [short name, long name if it exists]
const titleMap = new Map<string, [string, string?]>([
  ['mental-health-history', ['Mental Health History']],
  ['phq9', ['PHQ-8']],
  ['phq8', ['PHQ-8']],
  ['phq', ['PHQ-8']],
  ['gad7', ['GAD-7']],
  ['asrsv11', ['ASRS v1.1']],
  ['lifestyle', ['Lifestyle']],
  ['sds', ['SDS', 'Sheehan Disability Scale (SDS)']],
  ['mdq', ['MDQ', 'Mood Disorder Questionnaire (MDQ)']],
  ['ymrs', ['YMRS', 'Young Mania Rating Scale (YMRS)']],
  ['dropout-risk-measurement', ['Dropout Risk']],
  ['flourishing-scale', ['Flourishing Scale']],
  ['coaching-kickoff', ['Coaching Goals Assessment']],
  ['coaching-executive-functioning-kickoff', ['Coaching Executive Functioning Goals Assessment']],
  ['perceived-stress-scale', ['Perceived Stress Scale']],
  ['academic-stress-scale', ['Academic Stress Scale']],
  ['aefi', ['AEFI', 'Amsterdam Executive Function Inventory (AEFI)']],
]);

export const getTitle = (titleKey: string, long?: boolean) => {
  const entry = titleMap.get(titleKey);
  if (!entry) return 'Other';
  if (long) return last(entry)!;
  return entry[0];
};

export const assessmentEventTitle = (key: string) => {
  switch (key) {
    case 'bipolar-1-screener':
    case 'mdq':
      return 'the Mood Disorder Questionnaire';
    case 'medical-history':
      return 'the Medical History Questionnaire';
    case 'scales':
      return 'the monthly scales';
    case 'intake':
      return 'the intake assessment';
    default:
      return 'an assessment';
  }
};

export const formatResult = ({
  score,
  symptoms,
  key,
  answers,
}: DeepPartial<QuestionnaireResult>) => {
  if (key === 'dropout-risk-measurement' && answers) return `${answers[0]?.score} of 6`;
  if (key === 'flourishing-scale' && answers) return `${Number(score)} of 56`;
  if (key === 'mdq' && symptoms) return symptoms;
  const scoreText = isNil(score) ? 'n/a' : `${score}`;
  const symptomText = isNil(symptoms) ? '' : ` (${symptoms})`;
  return `${scoreText}${symptomText}`;
};

const weDoNotCare = new Set(['basicInformation', 'lifestyle']);
export const shouldShow = ({ key }: Pick<QuestionnaireResult, 'key'>) => !weDoNotCare.has(key);
