import { Checkbox } from 'baseui/checkbox';
import { Modal } from 'baseui/modal';
import { StatefulSelect } from 'baseui/select';
import { Tag } from 'baseui/tag';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FinalButton } from '../../Components/FinalButton';
import { Icon } from '../../Components/Icons';
import { ListView } from '../../Components/ListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { BackButton } from '../../Components/Wizard/BackButton';
import { Modal as MantraModal, Text } from '../../globalStyles';
import {
  AdminProviderQuery,
  Payer,
  useDeleteInsuranceCredentialMutation,
  usePayersQuery,
  useUpsertInsuranceCredentialMutation,
} from '../../graphQL';
import { stateOptions } from '../../states';
import { useProviderDrilldownContext } from './context';

type Credential = AdminProviderQuery['adminProvider']['insuranceCredentials'][number];

export function ProviderInsuranceTab() {
  const { provider, refetch } = useProviderDrilldownContext();
  const [editingCredential, setEditingCredential] = useState<Credential | 'new' | null>(null);
  const { data, loading } = usePayersQuery();
  const onModalClose = (saved: boolean) => {
    if (saved) refetch();
    setEditingCredential(null);
  };
  if (loading) return <LoadingPage />;
  return (
    <div className="mh5 mv3">
      {editingCredential && (
        <UpsertCredentialModal
          credential={editingCredential === 'new' ? undefined : editingCredential}
          onClose={onModalClose}
          providerId={provider.id}
          payers={data?.payers ?? []}
        />
      )}
      <div className="flex flex-row items-center">
        <Text.h3 className="mr4">In-Network Insurance Plans</Text.h3>
        <Text.linkButton className="b" onClick={() => setEditingCredential('new')}>
          + Add
        </Text.linkButton>
      </div>
      <ListView
        data={provider.insuranceCredentials}
        getKey={i => i.id}
        paginate={false}
        initialSortColumn="updatedAt"
        initialSortDirection="desc"
        columns={[
          {
            key: 'status',
            title: 'Status',
            render: i => (
              <Tag kind={i.active ? 'positive' : 'accent'} closeable={false}>
                {i.active ? 'Active' : 'Inactive'}
              </Tag>
            ),
          },
          {
            key: 'payer',
            title: 'Payer',
            render: i => i.payer.name,
            sort: (a, b) => a.payer.name.localeCompare(b.payer.name),
          },
          {
            key: 'state',
            title: 'State',
            render: i => i.state,
            sort: (a, b) => a.state.localeCompare(b.state),
          },
          {
            key: 'createdAt',
            title: 'Date Added',
            render: i => moment(i.createdAt).format('M/D/YYYY h:mma'),
            sort: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
          },
          {
            key: 'updatedAt',
            title: 'Last Modified',
            render: i => moment(i.updatedAt).format('M/D/YYYY h:mma'),
            sort: (a, b) => moment(a.updatedAt).diff(moment(b.updatedAt)),
          },
          {
            key: 'edit',
            title: '',
            render: i => (
              <button
                type="button"
                style={{ margin: 'auto', background: 'none', border: 'none', cursor: 'pointer' }}
                onClick={() => setEditingCredential(i)}
              >
                <Icon icon="iconsPencilSvg" alt="Edit" />
              </button>
            ),
          },
        ]}
      />
    </div>
  );
}

interface UpsertModalProps {
  onClose: (saved: boolean) => void;
  credential?: Credential;
  providerId: number;
  payers: Pick<Payer, 'id' | 'name' | 'inNetworkStates'>[];
}

interface ModalFormFields {
  payerId: number;
  state: string;
  active: boolean;
}

function UpsertCredentialModal({ onClose, credential, providerId, payers }: UpsertModalProps) {
  const { provider } = useProviderDrilldownContext();
  const payerOptions = payers.map(payer => ({ id: payer.id, label: payer.name }));
  const form = useForm<ModalFormFields>({
    defaultValues: {
      payerId: credential?.payer.id,
      state: credential?.state,
      active: credential ? credential.active : true,
    },
  });
  const [upsert, { loading }] = useUpsertInsuranceCredentialMutation();
  const [deleting, setDeleting] = useState(false);

  const onSubmit = form.handleSubmit(async values => {
    await upsert({ variables: { credential: { ...values, providerId } } });
    onClose(true);
  });

  const { payerId, state } = form.watch(['payerId', 'state']);
  const payer = payerId ? payers.find(i => i.id === payerId)! : null;
  const payerStateMismatch = Boolean(payer && state && !payer.inNetworkStates.includes(state));
  const duplicate = provider.insuranceCredentials.some(
    i => i.payer.id === payerId && i.state === state && i !== credential
  );

  useEffect(() => {
    if (payerStateMismatch) {
      form.setValue('active', false);
    }
  }, [payerStateMismatch, form]);

  if (deleting && credential) {
    return (
      <DeleteCredentialModal
        credential={credential}
        onClose={onClose}
        onBack={() => setDeleting(false)}
      />
    );
  }

  return (
    <Modal isOpen onClose={() => onClose(false)} unstable_ModalBackdropScroll>
      <MantraModal.body>
        <MantraModal.header>
          {credential ? 'Edit Credential Info' : 'New Insurance Credential'}
        </MantraModal.header>
        <Text.bodyBold className="mt4 mb2">Payer</Text.bodyBold>
        <Controller
          name="payerId"
          control={form.control}
          rules={{ required: true }}
          render={({ onChange, value, onBlur }) => (
            <StatefulSelect
              options={payerOptions}
              clearable={false}
              onChange={({ option }) => onChange(option?.id)}
              value={value ? [{ id: value }] : []}
              maxDropdownHeight="24rem"
              onBlur={onBlur}
              initialState={{
                value: credential ? [payerOptions.find(i => i.id === credential?.payer.id)!] : [],
              }}
              disabled={!!credential}
            />
          )}
        />
        <Text.bodyBold className="mt4 mb2">State</Text.bodyBold>
        <Controller
          name="state"
          control={form.control}
          rules={{ required: true }}
          render={({ onChange, value, onBlur }) => (
            <StatefulSelect
              options={stateOptions}
              clearable={false}
              onChange={({ option }) => onChange(option?.id)}
              value={value ? [{ id: value }] : []}
              maxDropdownHeight="24rem"
              onBlur={onBlur}
              initialState={{
                value: credential ? [stateOptions.find(i => i.id === credential?.state)!] : [],
              }}
              disabled={!!credential}
            />
          )}
        />
        {duplicate && (
          <Text.bodyBold kind="danger" className="mt3">
            The selected payer &amp; state combination has already been added to this provider’s
            account.
          </Text.bodyBold>
        )}
        {payerStateMismatch && (
          <div
            className="mt4 pa3 flex flex-row items-start"
            style={{ backgroundColor: 'rgba(246,137,88,0.16)' }}
          >
            <Icon icon="iconsAlertSvg" alt="Alert" style={{ marginRight: 12, marginTop: 8 }} />
            <div>
              <Text.bodyBold className="mb3">
                Mantra Health is not currently able to submit claims to this payer in the state you
                selected.
              </Text.bodyBold>
              <Text.bodyBold>
                You may add this credential to this provider, but it will be set as inactive.
              </Text.bodyBold>
            </div>
          </div>
        )}
        <Text.bodyBold className="mt4">Active within Mantra?</Text.bodyBold>
        <Text.bodySmall className="mb2">
          “Active” means eligible Mantra patients with this plan can book with this provider.
        </Text.bodySmall>
        <Controller
          name="active"
          control={form.control}
          render={({ onChange, value, ...handler }) => (
            <Checkbox
              onChange={event => onChange(event.currentTarget.checked)}
              checked={value}
              disabled={payerStateMismatch}
              {...handler}
            />
          )}
        />
        <div className="mt4">
          <FinalButton
            type="submit"
            disabled={!payerId || !state || duplicate}
            loading={loading}
            className="w-100 mt3"
            onClick={onSubmit}
          >
            Save
          </FinalButton>
          {credential && (
            <Text.linkButton
              className="mt4 tc w-100"
              kind="danger"
              onClick={() => setDeleting(true)}
            >
              Delete
            </Text.linkButton>
          )}
        </div>
      </MantraModal.body>
    </Modal>
  );
}

interface DeleteModalProps {
  credential: Credential;
  onClose: (saved: boolean) => void;
  onBack: () => void;
}

function DeleteCredentialModal({ credential, onClose, onBack }: DeleteModalProps) {
  const [deleteCredential, { loading }] = useDeleteInsuranceCredentialMutation({
    variables: { id: credential.id },
    onCompleted: () => onClose(true),
  });

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={() => onClose(false)}>
      <MantraModal.body>
        <BackButton onClick={onBack} />
        <MantraModal.header className="mt3 mb3">
          Delete credential from this provider?
        </MantraModal.header>
        <Text.body className="mt3 mb3">
          <span className="b">Payer:</span> {credential.payer.name}
        </Text.body>
        <Text.body className="mt3 mb3">
          <span className="b">State:</span> {credential.state}
        </Text.body>
        <FinalButton
          kind="danger"
          onClick={() => deleteCredential()}
          loading={loading}
          className="w-100 mt3"
        >
          Confirm Deletion
        </FinalButton>
        <MantraModal.closeLink className="mt3" onClick={() => onClose(false)}>
          Close
        </MantraModal.closeLink>
      </MantraModal.body>
    </Modal>
  );
}
