import { Avatar } from 'baseui/avatar';
import React from 'react';
import styled from 'styled-components';
import { EditCampusTeamMember } from '../../../Components/CampusTeam/types';
import { Select } from '../../../Components/Form';
import { Option } from '../../../Components/Form/Select/types';
import { InlineSVG, SVGKey } from '../../../Components/Icons';
import { SearchAndAddMultirowComponentArgs } from '../../../Components/SearchAndAdd/SearchAndAddMultirow';
import { colors, Text } from '../../../globalStyles';
import { UniversityRelationship } from '../../../graphQL';

type OptionData = { subtext: string; iconName: SVGKey };

export const CampusTeamMember = ({
  onChange,
  values,
  index,
}: SearchAndAddMultirowComponentArgs<EditCampusTeamMember>) => {
  const selectOptions: Option<OptionData>[] = [
    {
      id: UniversityRelationship.UniversityCollaborator,
      label: 'Collaborating',
      data: { subtext: 'Provider messaging + all patient updates', iconName: 'message' },
    },
    {
      id: UniversityRelationship.UniversityMonitor,
      label: 'Monitoring',
      data: {
        subtext: 'Alerts of major patient events (risk elevations, no-shows or crises',
        iconName: 'bell',
      },
    },
  ];
  const updateMember = (relationshipType: UniversityRelationship) => {
    onChange({ ...values[index], relationshipType }, index);
  };

  return (
    <TeamMemberComponent>
      <Avatar
        size="32px"
        src={values[index].portrait?.url}
        name={values[index].name}
        overrides={{
          Root: { style: { minWidth: '32px', margin: 'auto' } },
          Avatar: { style: { border: '1px solid white' } },
        }}
      />
      <TeamMemberName>{values[index].name}</TeamMemberName>
      <Select
        defaultValue="Select..."
        value={values[index].relationshipType}
        options={selectOptions}
        onChange={relationshipType => updateMember(relationshipType as UniversityRelationship)}
        style={{ width: '36%' }}
        optionStyles={{
          width: '260px',
          right: '0',
          left: 'unset',
          backgroundColor: colors.white,
          activeBackgroundColor: colors.friendlyWarningBg,
          color: colors.black,
          activeColor: colors.primary,
          paddingLi: `0`,
          paddingUl: '0',
        }}
        renderOption={({ option, isActive, isFirst }) => {
          return <CareTeamMemberRoleOption option={option} isActive={isActive} isFirst={isFirst} />;
        }}
      />
    </TeamMemberComponent>
  );
};

type RoleOptionProps = {
  option: Option<OptionData>;
  isActive: Boolean;
  isFirst: Boolean;
};

const CareTeamMemberRoleOption = ({ option, isActive, isFirst }: RoleOptionProps) => {
  const fillColor = isActive ? 'primary' : 'black';
  return (
    <RoleOptionContainer isFirst={isFirst}>
      <InlineSVG
        className="flex-shrink-0 mt1"
        icon={option.data.iconName}
        size={17}
        kind={fillColor}
      />
      <RoleInfoDiv>
        <Text.bodySmallBold kind={fillColor}>{option.label}</Text.bodySmallBold>
        <Text.caption kind="grey.ozGray2">{option.data.subtext}</Text.caption>
      </RoleInfoDiv>
    </RoleOptionContainer>
  );
};

const TeamMemberComponent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
`;

const TeamMemberName = styled.p`
  font-size: 16px;
  font-weight: 700;
  width: 54%;
  min-width: 54%;
  margin: auto;
`;

const RoleOptionContainer = styled.div<{ isFirst: Boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  gap: 0.4rem;
  border-top: ${({ isFirst }) => (isFirst ? '0' : `1px solid ${colors.grayTextBg}`)};
  padding: 16px;
  &:hover {
    background-color: ${colors.grayTextBg};
    border-top: ${({ isFirst }) => (isFirst ? '0' : `1px solid transparent`)};
  }
  &:active {
    border-top: ${({ isFirst }) => (isFirst ? '0' : `1px solid transparent`)};
  }
`;

const RoleInfoDiv = styled.div`
  padding: 0;
`;
