import React from 'react';
import { useCurrentProvider } from '../../Components/Permissions';
import { WizardStep } from '../../Components/Wizard';
import {
  AppView,
  CareType,
  useReferralUserBookingQuery,
  useReschedulingAppointmentQuery,
  useUserBookingQuery,
} from '../../graphQL';
import { RequireJust } from '../../types';
import { canBookIntake, getInitialAppointmentDetails, getIntakeApptData } from './bookingUtils';
import { Confirm } from './Steps/Confirm';
import { SelectAppointmentType } from './Steps/SelectAppointmentType';
import { SelectTime } from './Steps/SelectTime';
import { BookingWizardData } from './types';

type HookArgs = {
  userId: number;
  rescheduleId?: number;
  careType?: CareType;
  outOfPolicy?: boolean;
};

type HookResult = RequireJust<SubHookResult, 'loading'> & {
  steps: WizardStep<BookingWizardData>[];
};

type SubHookArgs<T> = {
  skip?: boolean;
  variables: T;
};

type SubHookResult = {
  loading?: boolean;
  error?: string;
  data?: BookingWizardData;
};

const viewSteps: Record<AppView, (keyof typeof possibleSteps)[]> = {
  oz: ['type', 'dateTimeDisplayAllHours', 'confirm'],
  mcp: ['type', 'dateTime', 'confirm'],
  referral: ['dateTime', 'confirm'],
};

const possibleSteps: Record<string, WizardStep<BookingWizardData>> = {
  type: {
    name: 'Details',
    render: SelectAppointmentType,
  },
  dateTime: {
    name: 'Schedule',
    render: SelectTime,
  },
  dateTimeDisplayAllHours: {
    name: 'Schedule',
    render: props => <SelectTime {...props} displayAllHours />,
  },
  confirm: {
    name: 'Confirm',
    render: Confirm,
  },
};

const baseData = (): Omit<BookingWizardData, 'patient'> => ({
  appointmentTemplates: [],
  initialStepIndex: 0,
  appointment: {},
  didEncounterTimeConflict: false,
});

export const useBookingFlow = ({
  userId,
  rescheduleId,
  careType,
  outOfPolicy,
}: HookArgs): HookResult => {
  const { appView } = useCurrentProvider();
  const isReferralView = appView === 'referral';
  const steps = getSteps(appView);

  const {
    data: userBookingData,
    loading: userBookingLoading,
    error: userBookingError,
  } = usePatientBookingFlow({
    skip: !!rescheduleId || isReferralView,
    variables: { userId, careType },
  });

  const {
    data: rescheduleData,
    loading: rescheduleLoading,
    error: rescheduleError,
  } = useReschedulingFlow({
    skip: !rescheduleId || isReferralView,
    variables: { rescheduleId: rescheduleId!, outOfPolicy },
  });

  const {
    data: simpleBookingData,
    loading: simpleBookingLoading,
    error: simpleBookingError,
  } = useSimpleBookingFlow({
    skip: !isReferralView,
    variables: { userId, careType },
  });

  const loading = rescheduleLoading || simpleBookingLoading || userBookingLoading;
  const error = userBookingError || rescheduleError || simpleBookingError;

  if (loading) {
    return { loading: true, steps };
  }

  if (error) {
    return { loading: false, error, steps };
  }

  if (rescheduleData) {
    return {
      // drop reschedules right into time selection
      loading: false,
      data: {
        ...rescheduleData,
        initialStepIndex: appView === 'referral' ? 0 : 1,
      },
      steps,
    };
  }

  if (userBookingData) {
    return { loading: false, data: userBookingData, steps };
  }

  if (simpleBookingData) {
    return { loading: false, data: simpleBookingData, steps };
  }

  return { loading: false, steps };
};

/* ~~ sub hooks ~~ */

type PatientBookingArgs = SubHookArgs<{ userId: number; careType?: CareType }>;

const usePatientBookingFlow = ({ skip, variables }: PatientBookingArgs): SubHookResult => {
  const { userId, careType } = variables;

  const { data, loading, error } = useUserBookingQuery({
    variables: { userId },
    skip,
  });

  if (loading) {
    return { loading: true };
  }

  if (!data || error) {
    return { error: error?.message };
  }

  const patient = data.adminUser;
  const { appointmentTemplates } = patient;
  const appointment = careType && getInitialAppointmentDetails(patient, careType);

  return {
    data: {
      ...baseData(),
      appointmentTemplates,
      patient,
      appointment: appointment ?? {},
      initialStepIndex: appointment ? 1 : 0,
    },
  };
};

type ReschedulingArgs = SubHookArgs<{ rescheduleId: number; outOfPolicy?: boolean }>;

const useReschedulingFlow = ({ skip, variables }: ReschedulingArgs): SubHookResult => {
  const { rescheduleId, outOfPolicy } = variables;

  const { data, loading, error } = useReschedulingAppointmentQuery({
    variables: { rescheduleId },
    skip,
  });

  if (loading) {
    return { loading: true };
  }

  if (!data || error) {
    return { error: error?.message };
  }

  const appointment = data.adminAppointment;
  const patient = appointment.user;
  const { appointmentTemplates } = patient;

  const templateIndex = appointmentTemplates.findIndex(
    t => t.appointmentType === appointment.appointmentType && t.careType === appointment.careType
  );

  return {
    data: {
      ...baseData(),
      appointmentTemplates,
      patient,
      reschedulingAppointment: { ...appointment, outOfPolicy },
      appointment: {
        ...appointment,
        templateIndex,
        duration: appointmentTemplates[templateIndex].duration,
      },
    },
  };
};

type SimpleBookingArgs = SubHookArgs<{ careType?: CareType; userId: number }>;

const useSimpleBookingFlow = ({ skip, variables }: SimpleBookingArgs): SubHookResult => {
  const { userId, careType } = variables;

  const { data, loading, error } = useReferralUserBookingQuery({
    variables: { userId },
    skip,
  });

  if (loading) {
    return { loading: true };
  }
  if (!data || error) {
    return { error: error?.message };
  }

  const patient = data.adminUser;
  const { appointmentTemplates } = patient;

  const canBookPsych = canBookIntake(CareType.Psychiatry, patient);
  const canBookTherapy = canBookIntake(CareType.Therapy, patient);
  const psychAppt = getIntakeApptData(CareType.Psychiatry, appointmentTemplates);
  const therapyAppt = getIntakeApptData(CareType.Therapy, appointmentTemplates);

  if (!careType && canBookPsych && psychAppt) {
    return {
      data: {
        ...baseData(),
        appointmentTemplates,
        patient,
        appointment: psychAppt,
      },
    };
  }
  if (!careType && canBookTherapy && therapyAppt) {
    return {
      data: {
        ...baseData(),
        appointmentTemplates,
        patient,
        appointment: therapyAppt,
      },
    };
  }
  if (!careType) {
    return { error: 'User has booked all available intake appointments.' };
  }

  const appt = getIntakeApptData(careType, appointmentTemplates);
  const isBookable = canBookIntake(careType, patient);

  if (!isBookable) {
    return { error: 'User has already booked an intake appointment for this care type.' };
  }
  if (!appt) {
    return { error: 'User cannot book an intake appointment for this care type.' };
  }
  return {
    data: {
      ...baseData(),
      appointmentTemplates,
      patient,
      appointment: appt,
    },
  };
};

const getSteps = (appView: AppView) => viewSteps[appView].map(k => possibleSteps[k]);

export default useBookingFlow;
