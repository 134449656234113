import { Input } from 'baseui/input';
import { Pagination } from 'baseui/pagination';
import { StyledTable } from 'baseui/table-grid';
import { compact } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DateRangePicker, Select } from '../Form';
import { NestedSelect } from '../Form/Select/MantraNestedSelect';
import { Grid } from '../Grid';
import { Icon } from '../Icons';
import { MantraSpinner } from '../LoadingOverlay';
import { SortIndicator } from './SortIndicator';
import { BodyCell, HeadCell, LinkCell, TableControlWrapper } from './styles';
import { isNestedFilter, ListViewConfig, ListViewUiProps } from './utils';

type Props<T> = {
  data: T[];
  hasNextPage: boolean;
  count?: number;
  countExceeded?: boolean;
  isLoading?: boolean;
} & ListViewConfig<T> &
  ListViewUiProps<T>;

export function ListViewInterface<T>({
  data,
  permissibleColumns,
  search,
  searchable,
  getKey,
  link,
  filters = [],
  dateFilters,
  paginate = true,
  empty = 'None',
  searchPlaceholder = 'Search',
  onClick,
  fontSize,
  searchValue,
  setSearchValue,
  filterValues,
  setFilterValue,
  currentPageNum,
  pageSize = 50,
  sortColumn,
  sortDirection,
  setSort,
  hasNextPage,
  count,
  countExceeded,
  setPage,
  isLoading,
  headRow,
  additionalControls,
  hideTopControls,
}: Props<T>) {
  const pageIndexStart = pageSize * (currentPageNum - 1);
  const pageIndexEnd = pageIndexStart + data.length;

  const filterComponents = compact([
    ...filters
      .filter(i => !i.hidden)
      .map((filter, index) => {
        const { key, defaultOptionId, hidden, width, ...props } = filter;
        const value = filterValues[key];
        return (
          <TableControlWrapper fullWidth key={index} style={{ width }}>
            {isNestedFilter(filter) ? (
              <NestedSelect
                onChange={val => setFilterValue(key, val)}
                value={value}
                clearable
                {...filter}
              />
            ) : (
              <Select
                onChange={val => setFilterValue(key, val)}
                value={value}
                clearable
                {...props}
              />
            )}
          </TableControlWrapper>
        );
      }),
    ...(dateFilters ?? [])
      .filter(i => !i.hidden)
      .map(({ key, hidden, ...props }, index) => {
        const value = filterValues[key];
        return (
          <div className="mr3 mb3 mw5" key={index}>
            <DateRangePicker
              onChange={val => setFilterValue(key, val)}
              value={value}
              clearable
              {...props}
            />
          </div>
        );
      }),
    additionalControls && <div>{additionalControls}</div>,
    paginate && !hideTopControls && (
      <TableControlWrapper fullWidth style={{ marginLeft: 'auto', width: 'auto' }}>
        <Pagination
          numPages={hasNextPage ? currentPageNum + 1 : currentPageNum}
          currentPage={currentPageNum}
          onPageChange={({ nextPage }) => setPage(nextPage)}
          overrides={{ DropdownContainer: () => <></>, MaxLabel: () => <></> }}
        />
      </TableControlWrapper>
    ),
  ]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const setWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', setWidth);
    return () => window.removeEventListener('resize', setWidth);
  }, [screenWidth]);

  let slots = 1;
  if (screenWidth >= 1400) slots = 4;
  if (screenWidth < 1400 && screenWidth >= 1110) slots = 3;
  if (screenWidth < 1110) slots = 2;

  const filterSlots = Math.min(filterComponents.length, slots);

  function formatCount(totalCount: number, isCountExceeded: boolean) {
    return `of ${totalCount}${isCountExceeded ? '+' : ''}`;
  }

  return (
    <div>
      {(search || searchable) && (
        <TableControlWrapper fullWidth className="w5">
          <Input
            value={searchValue}
            onChange={event => setSearchValue((event.target as HTMLInputElement).value)}
            startEnhancer={<Icon icon="iconsSearchSvg" alt="Search" />}
            placeholder={searchPlaceholder}
          />
        </TableControlWrapper>
      )}

      <Grid columnGap="1.5rem" gridTemplateColumns={`repeat(${filterSlots}, 1fr)`} width="100%">
        {filterComponents.map((f, i) => {
          const key = `list-filter-${i}`;
          return <React.Fragment key={key}>{f}</React.Fragment>;
        })}
      </Grid>
      <StyledTable $gridTemplateColumns={permissibleColumns.map(c => c.width || '1fr').join(' ')}>
        {permissibleColumns.map(col => (
          <HeadCell className={col.className} onClick={() => setSort(col.key)} key={col.key}>
            {col.sortable || col.sort ? (
              <SortIndicator direction={sortColumn === col.key && sortDirection}>
                {col.title}
              </SortIndicator>
            ) : (
              col.title
            )}
          </HeadCell>
        ))}
        {headRow && <div style={{ gridColumn: '1 / -1' }}>{headRow}</div>}
        {data.map((item, idx) => {
          const WrapperCell = link ? LinkCell : DudLink;
          const to = link?.(item)!;

          return (
            <React.Fragment key={getKey(item)}>
              {permissibleColumns.map(col => (
                <WrapperCell key={`${col.key}-${getKey(item)}`} to={to!}>
                  <BodyCell
                    onClick={onClick && (() => onClick(item))}
                    className={col.className || fontSize}
                    data-cy={`row_${idx}`}
                  >
                    {col.render(item)}
                  </BodyCell>
                </WrapperCell>
              ))}
            </React.Fragment>
          );
        })}
        {isLoading && (
          <div className="flex justify-center pa3" style={{ gridColumn: '1 / -1' }}>
            <MantraSpinner size={24} simple />
          </div>
        )}
        {data.length === 0 && !isLoading && (
          <BodyCell style={{ gridColumn: '1 / -1' }}>{empty}</BodyCell>
        )}
      </StyledTable>
      {paginate && (
        <div className="mt3">
          <Pagination
            numPages={hasNextPage ? currentPageNum + 1 : currentPageNum}
            currentPage={currentPageNum}
            onPageChange={({ nextPage }) => setPage(nextPage)}
            overrides={{
              MaxLabel: () => (
                <div className="mh3">
                  Viewing{' '}
                  {data.length === 0
                    ? '0-0'
                    : `${pageIndexStart + 1}-${pageIndexEnd} ${
                        count ? formatCount(count, countExceeded ?? false) : ''
                      }`}
                </div>
              ),
              DropdownContainer: () => <></>,
            }}
          />
        </div>
      )}
    </div>
  );
}

const DudLink: React.FC<{ to?: string }> = ({ children }) => <>{children}</>;
