import { useEffect } from 'react';
import { useRefreshProviderLoginMutation } from '../graphQL';
import { getAuthToken, setAuthToken } from '../token';
import { doesTokenExpireWithin, isLoggedIn } from '../utils';

export const useRefreshTokenBeforeExpiration = (milliseconds: number) => {
  const [refresh, mutation] = useRefreshProviderLoginMutation();
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const token = getAuthToken();
      if (!isLoggedIn()) return;
      if (token && doesTokenExpireWithin(token, milliseconds) && !mutation.loading) {
        try {
          const { data } = await refresh();
          setAuthToken(data?.refreshProviderLogin!);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Token refresh failed:', err);
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  });
};
