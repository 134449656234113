import React from 'react';
import styled from 'styled-components';
import { colorMap, Text } from '../../globalStyles';
import { InlineSVG } from '../Icons';
import { MaxWidthDiv } from '../MaxWidth';
import { cx } from '../../utils';

type SimpleBannerProps = {
  headerText: string;
  bodyText: string;
};

export const SimpleBanner = ({ headerText, bodyText }: SimpleBannerProps) => {
  return (
    <SimpleBannerContainer className={className}>
      <MaxWidthDiv className="center flex flex-column gap-2" mw="30rem">
        <div className="tc center flex flex-row gap-2 items-center">
          <InlineSVG icon="alert-circle" size={30} kind="greyIcon" />
        </div>
        <Text.h3>{headerText}</Text.h3>
        <Text.bodyCaption kind="grayText" className={cx()}>
          {bodyText}
        </Text.bodyCaption>
      </MaxWidthDiv>
    </SimpleBannerContainer>
  );
};

const className = 'absolute top-0 left-0 right-0';

const SimpleBannerContainer = styled.div`
  padding: 2rem;
  background: ${colorMap.warning[5]};
  position: relative;
  opacity: 1;
  width: 100%;
`;
