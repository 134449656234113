import React, { createContext, ReactNode, useContext } from 'react';
import { EmbedPatientQuery, useEmbedPatientQuery } from '../../graphQL';
import { useParams } from '../../routes';

type PatientContextType = {
  patientId: number;
  patient?: EmbedPatientQuery['embedPatient'];
  patientLoading: boolean;
};

const PatientContext = createContext<PatientContextType>({
  patientId: 0,
  patient: undefined,
  patientLoading: false,
});

export const PatientConsumer = PatientContext.Consumer;

export const PatientProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const params = useParams<{ patientId?: string }>();
  const patientId = Number(params.patientId ?? 0);

  const { data: patientData, loading: patientLoading } = useEmbedPatientQuery({
    variables: { patientId },
    skip: !patientId,
  });

  const patient = patientData?.embedPatient ?? undefined;

  const patientValue: PatientContextType = {
    patientId,
    patient,
    patientLoading,
  };

  return <PatientContext.Provider value={patientValue}>{children}</PatientContext.Provider>;
};

export const usePatientContext = (): PatientContextType => {
  return useContext<PatientContextType>(PatientContext);
};
