import React from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';
import { useProviderPopoverQuery } from '../../graphQL';
import { cx } from '../../utils';
import { Icon, InlineSVG } from '../Icons';
import { useCurrentProvider } from '../Permissions';
import { mediaScreenFeatures } from './common';
import { useVideoContextV2 } from './VideoProvider';
import { useVideoStatus } from './videoUtil';

type VideoContainerProps = {
  divId: string;
  providerId: number;
};

export const ProviderVideoStream = ({ divId, providerId }: VideoContainerProps) => {
  // ignore errors in case of querying provider without permission
  const { data } = useProviderPopoverQuery({
    variables: { providerId },
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  });

  const { currentProvider } = useCurrentProvider();
  const { removeProviderFromCall } = useVideoContextV2();
  const { hasVideo, hasAudio } = useVideoStatus({ actorType: 'provider', id: providerId });

  let name = data?.adminProvider.name ?? `Provider`;
  if (providerId === currentProvider.id) name = 'You';

  return (
    <StyledContainer id={divId}>
      <div className="video-overlay">
        <div className="flex justify-between items-end">
          <div>
            <div className="flex items-center bg-black gap-1 ph1">
              {removeProviderFromCall && providerId !== currentProvider.id && (
                <Icon
                  size={12}
                  title="remove from call"
                  className="pointer"
                  icon="iconsRedXSvg"
                  onClick={() => removeProviderFromCall(providerId)}
                />
              )}
              <Text.bodySmall className="truncate" kind="white">
                {name}
              </Text.bodySmall>
            </div>
          </div>

          <StreamStatusIcons>
            <InlineSVG
              className={cx({ disabled: !hasAudio })}
              icon={hasAudio ? 'mic' : 'mic-off'}
              width={16}
              height={16}
              kind="white"
            />
            <InlineSVG
              className={cx({ disabled: !hasVideo })}
              icon={hasVideo ? 'video' : 'video-off'}
              width={16}
              height={16}
              kind="white"
            />
          </StreamStatusIcons>
        </div>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  width: 264px;
  height: 198px;

  @media only screen and (${mediaScreenFeatures.mobile}) {
    height: min(15em, 18vh);
    width: min(15em, 18vw);
  }

  border-radius: 0.25rem;
  overflow: hidden;
  background-color: black;

  & > div.video-overlay {
    color: white;
    position: absolute;
    z-index: 1;
    inset: auto 0.5rem 0.5rem 0.5rem;
  }
`;

const StreamStatusIcons = styled.div`
  display: flex;
  gap: 0.5rem;

  & svg {
    display: none;
    padding: 0.25rem;
    border-radius: 10%;

    &.disabled {
      display: initial;
      background-color: ${colors.danger};
    }
  }
`;
