import styled, { css } from 'styled-components';
import * as Modal from './modal';
import * as Text from './text';
import * as overrides from './overrides';

export * from './colors';
export { Text, Modal, overrides };

export const PaddedPage = styled.div`
  padding-top: 4rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
`;

export const CenteredOverlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const hideBrowserOutline = css`
  outline: none;

  :active,
  :focus {
    outline: none;
  }
`;

export const HideItemsWhen = styled.span<{ maxWidth: number }>`
  @media only screen and (max-width: ${({ maxWidth }) => maxWidth}px) {
    display: none;
  }
`;
