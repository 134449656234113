import { chain, isNil } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { FinalButton } from '../../Components/FinalButton';
import { Select } from '../../Components/Form';
import { usePanelRef } from '../../Components/NavLayout';
import { OzOnly, PermsOnly, useCurrentProvider } from '../../Components/Permissions';
import { TimezonePicker } from '../../Components/TimezonePicker';
import { Text } from '../../globalStyles';
import { Permission, useProvidersQuery } from '../../graphQL';
import { useQueryParams } from '../../Hooks';
import { states } from '../../states';
import { TimeZone } from '../../utils';
import { AppointmentListItem } from './AppointmentListItem';
import * as Styles from './styles';
import { Appointment } from './types';

type Props = {
  appointments: Appointment[];
  startDate: Moment;
  endDate: Moment;
  timeZone: TimeZone;
  onAdvanceWeek: () => void;
  onChangeTimeZone: (value: TimeZone) => void;
  refetch: () => void;
};

export function AppointmentList({
  appointments,
  startDate,
  endDate,
  timeZone,
  onAdvanceWeek,
  onChangeTimeZone,
  refetch,
}: Props) {
  const apptRefs = useRef<HTMLDetailsElement[]>([]);

  const dtcStates = useMemo(
    () =>
      chain(appointments)
        .filter(a => !a.user.organization)
        .map(a => a.user.primaryAddressState)
        .filter((s): s is NonNullable<string> => !!s)
        .uniq()
        .value(),
    [appointments]
  );

  const sponsoredCareOrgs = useMemo(
    () =>
      chain(appointments)
        .map(a => a.user.organization)
        .filter((o): o is NonNullable<typeof o> => !!o)
        .uniqBy(o => o.id)
        .value(),
    [appointments]
  );

  const [parsedSearch, updateQueryParams] = useQueryParams();
  const [selectedProviderId, setSelectedProviderId] = useState<number>();
  const [organizationId, setOrganizationId] = useState<number>();
  const [geoState, setGeoState] = useState<string>();

  const parsedOrgId = Number(parsedSearch?.organizationId) || null;
  const parsedProviderId = Number(parsedSearch?.providerId) || null;
  const parsedGeoState = parsedSearch?.geoState;
  const parsedTimeZone = parsedSearch?.timeZone;

  // Update filters according to query params
  useEffect(() => {
    if (!isNil(parsedOrgId)) {
      setOrganizationId(parsedOrgId);
    }
    if (!isNil(parsedProviderId)) {
      setSelectedProviderId(parsedProviderId);
    }
    if (parsedGeoState) {
      setGeoState(parsedGeoState);
      if (parsedTimeZone) {
        onChangeTimeZone(parsedTimeZone);
      }
    }
  }, [
    setOrganizationId,
    setSelectedProviderId,
    setGeoState,
    onChangeTimeZone,
    parsedOrgId,
    parsedProviderId,
    parsedGeoState,
    parsedTimeZone,
  ]);

  useEffect(() => {
    const observerOptions = {
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const hiddenEl = entry.target.querySelector('div.hidden');
          if (hiddenEl) {
            hiddenEl.classList.remove('hidden');
            observer.unobserve(hiddenEl);
          }
        }
      }
    }, observerOptions);
    apptRefs.current.filter(r => !!r).forEach(el => observer.observe(el));
    return () => observer.disconnect();
  }, [apptRefs]);

  const panel = usePanelRef();

  const { hasPermission } = useCurrentProvider();
  const { data: providersData } = useProvidersQuery({
    fetchPolicy: 'cache-first',
    skip: !hasPermission(Permission.MantraAdmin),
  });

  const providerOptions = providersData
    ? providersData.providers.map(i => ({ id: i.id, label: i.name }))
    : [];

  const hasSelectedProvider = useCallback(
    (appt: Appointment) => !selectedProviderId || selectedProviderId === appt.provider.id,
    [selectedProviderId]
  );

  const hasSelectedOrg = useCallback(
    (appt: Appointment) => !organizationId || organizationId === appt.user?.organization?.id,
    [organizationId]
  );

  const hasGeoState = useCallback(
    (appt: Appointment, day: Moment | string) =>
      !geoState ||
      (appt.user.primaryAddressState === geoState &&
        !appt.user.organization &&
        appt.startMoment.isSame(day, 'date')),
    [geoState]
  );

  const apptByDayLookup = useMemo(() => {
    return chain(appointments)
      .groupBy(appt => appt.startMoment.clone().tz(timeZone).format('YYYY-MM-DD'))
      .mapValues((as, k) => {
        return as.filter(a => hasSelectedProvider(a) && hasSelectedOrg(a) && hasGeoState(a, k));
      })
      .value();
  }, [hasSelectedProvider, hasSelectedOrg, hasGeoState, appointments, timeZone]);

  const days = useMemo(() => {
    const d: Moment[] = [];
    for (let index = 0; index <= endDate.diff(startDate, 'days'); index += 1) {
      d.push(startDate.clone().add(index, 'days'));
    }
    return d;
  }, [startDate, endDate]);

  const handleClickNextWeek = (event: React.MouseEvent) => {
    event.preventDefault();
    if (panel) panel.scrollTop = 0;
    onAdvanceWeek();
  };

  return (
    <>
      <div className="flex justify-end flex-wrap">
        <OzOnly>
          <Styles.DropdownContainer>
            <Select
              options={{
                DTC: dtcStates.map(state => ({ id: state, label: states[state] })),
                'Sponsored Care': sponsoredCareOrgs.map(org => ({
                  label: org.name,
                  id: org.id,
                })),
              }}
              placeholder="Organization"
              value={organizationId || geoState}
              onChange={val => {
                const newOrganizationId = typeof val === 'number' ? val : undefined;
                const newGeoState = typeof val === 'string' ? val : undefined;
                setOrganizationId(newOrganizationId);
                setGeoState(newGeoState);
                updateQueryParams({
                  ...parsedSearch,
                  organizationId: newOrganizationId,
                  geoState: newGeoState,
                });
              }}
              clearable
            />
          </Styles.DropdownContainer>
        </OzOnly>
        <PermsOnly allowed={Permission.MantraAdmin}>
          <Styles.DropdownContainer>
            <Select
              options={providerOptions}
              value={selectedProviderId}
              onChange={providerId => {
                setSelectedProviderId(providerId as number);
                updateQueryParams({
                  ...parsedSearch,
                  providerId: providerId as number,
                });
              }}
              clearable
              placeholder="All Providers"
            />
          </Styles.DropdownContainer>
        </PermsOnly>
        <Styles.DropdownContainer>
          <TimezonePicker
            value={timeZone}
            onChange={newTimeZone => {
              onChangeTimeZone(newTimeZone);
              updateQueryParams({
                ...parsedSearch,
                timeZone,
              });
            }}
          />
        </Styles.DropdownContainer>
      </div>
      {days.map(d => {
        const currentDay = d.clone().tz(timeZone);
        return (
          <DayRows
            key={currentDay.format('YYYY-MM-DD')}
            ref={(ref: HTMLDetailsElement) => apptRefs.current.push(ref)}
            day={currentDay}
            apptsForDay={apptByDayLookup[currentDay.format('YYYY-MM-DD')] ?? []}
            refetch={refetch}
          />
        );
      })}
      <FinalButton
        className="mt3"
        kind="outline_black"
        iconRight="iconsRightChevronSvg"
        onClick={handleClickNextWeek}
      >
        Jump to Next Week
      </FinalButton>
    </>
  );
}

type DaysRowsProps = {
  day: Moment;
  apptsForDay: Appointment[];
  refetch: () => void;
};

const DayRows = React.forwardRef<HTMLDetailsElement, DaysRowsProps>(
  ({ day, apptsForDay, refetch }, ref) => {
    const nowRef = useRef(moment());

    const isPast = day.isBefore(nowRef.current.startOf('d'));
    const isSameWeek = day.isSame(nowRef.current, 'week');
    const isSameDay = day.isSame(nowRef.current, 'day');

    const counts = apptsForDay.reduce((acc, cur) => {
      let { status } = cur;

      /*
    "upcoming" appts on past days are incomplete
    "upcoming" appts earlier on the same day are unmarked
    */
      if (status === 'upcoming') {
        if (isSameDay && cur.endMoment.isBefore(nowRef.current)) status = 'unmarked';
        else if (isPast) status = 'incomplete';
      }
      acc[status] = (acc[status] ?? 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    return (
      <Styles.DayDetails ref={ref} key={day.valueOf().toString()} open={!isSameWeek || !isPast}>
        <summary>
          <Styles.AppointmentItemGridRow>
            <div className={isPast ? 'gray' : ''}>
              <h3 className="f4">{day.format('dddd, MM/DD')}</h3>
              <Text.label>
                {apptsForDay.length} Appointment{apptsForDay.length === 1 ? '' : 's'}
              </Text.label>
            </div>
            <div className="flex items-center justify-between">
              <p>
                {Object.entries(counts).map(([key, val], idx, arr) => {
                  const isIncomplete = key === 'incomplete';
                  // @TODO pluralize
                  // https://www.npmjs.com/package/pluralize ?
                  return (
                    <Text.body as="span" key={key} kind={isIncomplete ? 'danger' : 'text'}>
                      {`${val} ${key}`}
                      {idx !== arr.length - 1 && <span className="mh2">/</span>}
                    </Text.body>
                  );
                })}
              </p>
              <div>
                <p className="icon-open">-</p>
                <p className="icon-close">+</p>
              </div>
            </div>
          </Styles.AppointmentItemGridRow>
        </summary>
        <ApptsBlock className="hidden">
          {apptsForDay.length === 0 ? (
            <Text.body kind="grayText" className="ml4 i">
              No appointments scheduled
            </Text.body>
          ) : (
            apptsForDay
              .sort((a, b) => a.startMoment.diff(b.startMoment))
              .map(appt => (
                <AppointmentListItem appointment={appt} key={appt.id} refetch={refetch} />
              ))
          )}
        </ApptsBlock>
      </Styles.DayDetails>
    );
  }
);

const ApptsBlock = styled.div`
  &.hidden {
    display: none;
  }
`;
