import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useEvents } from '../../Components/Events/EventsProvider';
import { SortIndicator } from '../../Components/ListView/SortIndicator';
import { Text } from '../../globalStyles';

type ListProps<T> = {
  data: T[];
  columns: ListColumn<T>[];
  getKey: (item: T) => string | number;
  onClickRow?: (item: T) => void;
  centered?: boolean;
  viewMoreCutoff?: number;
  analyticsName?: string;
};

type ListColumn<T> = {
  title: string;
  render: (item: T) => JSX.Element | string;
  sort?: (a: T, b: T) => number;
  initial?: 'asc' | 'desc';
};

export const DashbaordListView = <T extends any>({
  data,
  columns,
  getKey,
  onClickRow,
  centered,
  viewMoreCutoff,
  analyticsName,
}: ListProps<T>) => {
  const metrics = useEvents();
  const [viewMore, setViewMore] = useState(false);
  const [sortCol, setSortCol] = useState(columns.findIndex(c => c.initial));
  const [sortDir, setSortDir] = useState(columns.find(c => c.initial)?.initial ?? 'asc');

  const onClickCol = (idx: number) => {
    let dir: 'asc' | 'desc' = 'desc';
    if (idx === sortCol) {
      dir = sortDir === 'asc' ? 'desc' : 'asc';
    } else {
      setSortCol(idx);
    }
    setSortDir(dir);

    if (analyticsName) {
      metrics.track(`reporting.${analyticsName}.sort`, {
        column: columns[idx].title,
        direction: dir,
      });
    }
  };

  const processed = useMemo(() => {
    const sortFn = columns[sortCol]?.sort;
    const sortedData = sortFn ? data.sort(sortFn) : data;
    if (sortDir === 'asc') sortedData.reverse();
    const slicedData =
      viewMoreCutoff && !viewMore ? sortedData.slice(0, viewMoreCutoff) : sortedData;

    return slicedData;
  }, [sortCol, sortDir, viewMore, columns, data, viewMoreCutoff]);

  return (
    <StyledDashboardList centeredList={centered}>
      <table>
        <thead>
          <tr>
            {columns.map((c, i) => (
              <Text.label
                className={c.sort ? 'pointer' : undefined}
                tabIndex={c.sort ? 0 : undefined}
                onKeyDown={(e: any) => c.sort && e.key === 'Enter' && onClickCol(i)}
                onClick={() => c.sort && onClickCol(i)}
                key={c.title}
                as="th"
              >
                {c.sort ? (
                  <SortIndicator direction={sortCol === i && sortDir}>{c.title}</SortIndicator>
                ) : (
                  c.title
                )}
              </Text.label>
            ))}
          </tr>
        </thead>
        <tbody>
          {processed.map(d => (
            <tr
              key={getKey(d)}
              onClick={() => onClickRow?.(d)}
              onKeyDown={e => e.key === 'Enter' && onClickRow?.(d)}
              tabIndex={onClickRow ? 0 : undefined}
              className={onClickRow ? 'pointer' : undefined}
            >
              {columns.map(c => (
                <td key={c.title}>{c.render(d)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {viewMoreCutoff && viewMoreCutoff < data.length && (
        <Text.linkButton className="mt3" onClick={() => setViewMore(v => !v)}>
          View {viewMore ? 'less' : 'all'}
        </Text.linkButton>
      )}
    </StyledDashboardList>
  );
};

const StyledDashboardList = styled.div<{ centeredList?: boolean }>`
  & table {
    width: 100%;
    border-collapse: collapse;
  }

  /* maybe scrolling? */
  & tbody {
  }

  & tbody tr:not(:last-of-type) {
    border-bottom: 1px solid #d8d8d8;
  }

  & th {
    text-align: left;
    padding: 0.25rem 0.5rem;
  }

  & td {
    text-align: left;
    padding: 0.688rem 0.5rem;
    font-size: 0.875rem;
  }

  ${({ centeredList }) =>
    centeredList
      ? `& thead th:not(:first-child) div {
    justify-content: center;
  }

  & td:not(:first-of-type) {
    text-align: center;
  }`
      : ''}
`;
