import React from 'react';
import { CoachingStatus, useEmbedPatientCoachingStatusQuery } from '../../../../graphQL';
import { Badge } from '../../../core';

export const CoachingStatusBadge = ({ patientId }: { patientId: number }): JSX.Element => {
  const { data: coachingStatusData, loading: coachingStatusIsLoading } =
    useEmbedPatientCoachingStatusQuery({
      variables: {
        userId: patientId,
      },
    });

  if (coachingStatusIsLoading) {
    return <Badge.defaultSubtle medium>Loading...</Badge.defaultSubtle>;
  }

  switch (coachingStatusData?.embedPatientCoachingStatus) {
    case CoachingStatus.Active:
      return <Badge.successSubtle medium>Active</Badge.successSubtle>;
    case CoachingStatus.Onboarding:
      return <Badge.warningSubtle medium>Onboarding</Badge.warningSubtle>;
    case CoachingStatus.Inactive:
      return <Badge.dangerSubtle medium>Inactive</Badge.dangerSubtle>;
    default:
      return <Badge.defaultSubtle medium>Not in Coaching</Badge.defaultSubtle>;
  }
};
