import { styled } from 'baseui';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProgressBar, ProgressCrumbs } from './Progress';

const progressBars = {
  crumbs: ProgressCrumbs,
  bar: ProgressBar,
} as const;

export type ChangeStepFn<T> = (data?: T | ((prev: T) => T)) => void;
export type UpdateDataFn<T> = (data: T | ((prev: T) => T)) => void;
export type RenderStep<T> = (props: WizardRenderProps<T>) => React.ReactElement | null;

export interface WizardProps<T> {
  initialData: T;
  initialStepIndex?: number;
  disableProgress?: boolean;
  steps: WizardStep<T>[];
  renderSuccess: (renderProps: WizardRenderProps<T>) => React.ReactElement;
  progressKind?: keyof typeof progressBars;
}

export interface WizardStep<T> {
  name: string;
  render: RenderStep<T>;
}

export interface WizardRenderProps<T> {
  data: T;
  setData: UpdateDataFn<T>;
  nextStep: ChangeStepFn<T>;
  prevStep: ChangeStepFn<T>;
}

export const WizardWrapper = styled('div', {
  maxWidth: '400px',
  margin: 'auto',
});

export function Wizard<T>({
  initialData,
  steps,
  initialStepIndex,
  disableProgress,
  renderSuccess,
  progressKind = 'crumbs',
}: WizardProps<T>) {
  const history = useHistory();
  const [data, setData] = useState(initialData);
  const [stepIndex, setStepIndex] = useState(initialStepIndex || 0);
  const [isComplete, setIsComplete] = useState(false);

  const handleNextStep: ChangeStepFn<T> = dataOrFn => {
    if (dataOrFn) {
      setData(dataOrFn);
    }
    if (stepIndex < steps.length - 1) {
      setStepIndex(stepIndex + 1);
    } else {
      setIsComplete(true);
    }
  };
  const handlePrevStep: ChangeStepFn<T> = dataOrFn => {
    if (dataOrFn) {
      setData(dataOrFn);
    }
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
    if (stepIndex <= 0) {
      history.goBack();
    }
  };

  const goToStep = (idx: number) => {
    setStepIndex(Math.max(Math.min(idx, steps.length - 1), 0));
  };

  const renderProps: WizardRenderProps<T> = {
    data,
    setData,
    nextStep: handleNextStep,
    prevStep: handlePrevStep,
  };

  const StepComponent = isComplete ? renderSuccess : steps[stepIndex].render;
  const Progress = progressBars[progressKind];

  return (
    <div
      style={{
        backgroundColor: '#fff',
        height: '100%',
        overflowY: 'auto',
        paddingTop: '3em',
        boxSizing: 'border-box',
      }}
    >
      {!disableProgress && (
        <WizardWrapper>
          <Progress
            labels={steps.map(i => i.name)}
            stepIdx={stepIndex}
            complete={isComplete}
            onClick={goToStep}
          />
        </WizardWrapper>
      )}
      <StepComponent {...renderProps} />
    </div>
  );
}
