import React, { ReactNode } from 'react';
import { ConfigProvider } from '../../../../Components/ConfigContext';
import { EventsProvider } from '../../../../Components/Events/EventsProvider';
import { AuthProvider } from '../../../../Components/Permissions';
import { Providers } from '../../../../Providers';
import { EmbedLayout } from '../embed/EmbedLayout';

type LegacyLayoutProps = {
  children: ReactNode;
};

export const LegacyLayout = ({ children }: LegacyLayoutProps): JSX.Element => {
  return (
    <LegacyLayoutPublic>
      <AuthProvider>{children}</AuthProvider>
    </LegacyLayoutPublic>
  );
};

export const LegacyLayoutPublic = ({ children }: LegacyLayoutProps): JSX.Element => {
  return (
    <EmbedLayout>
      <Providers>
        <EventsProvider>
          <ConfigProvider>{children}</ConfigProvider>
        </EventsProvider>
      </Providers>
    </EmbedLayout>
  );
};
