import React, { HTMLProps } from 'react';
import styled, { css } from 'styled-components';
import { Tooltip, TooltipProps } from '../../../Components/Tooltip';
import { colorMap, colors, Text } from '../../../globalStyles';

type CellProps = HTMLProps<HTMLDivElement> & { editable?: boolean; past?: boolean };
export const AvailabilityCell = styled.div<CellProps>`
  position: relative;
  padding: 0.5em 0.75em;
  ${({ editable, past }) => css`
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    cursor: ${editable ? 'pointer' : 'auto'};
    background: ${past ? '#EEEEEE' : '#FFFFFF'};
    &:hover {
      ${!past ? `background: ${editable ? colors.accent : 'unset'};` : ''}
      ${EditText} {
        display: block;
      }
    }
  `}
`;

export const SummaryCell = styled.div`
  overflow-y: scroll;
  position: relative;
  padding: 0.5em 0.75em;
  width: 200px;
  padding-top: 4px;
  padding-right: 23px;
  background: ${colorMap.background[0]};
  cursor: auto;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
`;

export const TimePercentageText = styled(Text.captionGrey)`
  margin-right: auto;
  margin-left: 2px;
`;

export const TimeSummaryContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const NoWidthToolTip = ({ style = {}, ...props }: TooltipProps) => {
  return <Tooltip style={{ ...style, width: 'width: auto !important' }} {...props} />;
};

const availabilityModalVPadding = '2rem';
const availabilityModalHPadding = '2.5rem';

export const AvailabilityModalBody = styled.div`
  padding: ${availabilityModalVPadding} ${availabilityModalHPadding};
  background: white;
`;

export const AvailabilityHeader = styled.header`
  padding: ${availabilityModalVPadding} ${availabilityModalHPadding} 1rem
    ${availabilityModalHPadding};
`;

export const AvailabilitySection = styled.section`
  padding-bottom: 1rem;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.grey.lightBorder};
    margin-bottom: 1.25rem;
  }
`;

export const AvailabilityModalHeader = styled.h2`
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 0.25em;
`;

export const AvailabilityModalSubheader = styled.div`
  margin-bottom: 1em;
`;

export const EditText = styled.div`
  top: 40%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 13px;
  line-height: 22px;
  color: ${colors.primary};
  display: none;
`;

export const Divider = styled.hr`
  border-top: 0;
  border-bottom: 1px solid ${colors.black};
  width: 95%;
`;

export const HoursCounter = styled.div`
  position: absolute;
  right: 1.5rem;
  color: ${colors.grayText};
  align-items: center;
  display: flex;
  font-size: 13px;
`;

export const DayNumber = styled.div<{ isInActiveMonth?: boolean }>`
  position: absolute;
  color: ${({ isInActiveMonth }) => (isInActiveMonth ? colors.black : colors.grey.ozGray2)};
  font-weight: bold;
  font-size: 20px;
`;

export const OrganizationName = styled.div<{ spaced?: boolean }>`
  color: ${colors.grey.dark};
  font-size: 11px;
  line-height: normal;
  padding-bottom: 2px;
  margin-top: ${({ spaced }) => (spaced ? '12px' : '0')};
`;

export const Times = styled.div`
  margin-top: 1.7rem;
  padding-bottom: 15px;
  overflow-y: scroll;
  scrollbar-width: 0px;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
    background: transparent;
    width: 0px;
  }
`;

export const TimeBreakdownContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 16px;
`;

export const StateAllocationLabelText = styled(Text.caption)`
  margin-right: auto;
`;

export const Time = styled.div<{ admin?: boolean }>`
  font-size: 13px;
  margin-bottom: 0.7em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 18px;
  font-style: ${({ admin }) => (admin ? 'italic' : 'normal')};
`;

export const Unavailable = styled.div`
  border: 1px dashed ${colors.grey.dark};
  color: ${colors.grey.dark};
  text-align: center;
  padding: 0.75em 0;
`;

export const AllocationGrid = styled.div`
  margin-top: 1em;
  display: grid;
  grid-template-columns: 125px 5px 125px 250px 170px 30px;
  grid-column-gap: 15px;
`;

export const AllocationGridV2 = styled.div`
  margin-top: 1em;
  display: grid;
  grid-template-columns: 130px 5px 130px 59px 200px 170px 40px 24px;
  grid-column-gap: 15px;
`;

export const AllocationGridStatic = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;

export const AddLink = styled.div`
  cursor: pointer;
  color: ${colors.primary};
  margin: 1em 0;
  display: inline-block;
`;

export const AddLinkV2 = styled(Text.linkButton).attrs({ kind: 'primary' })`
  cursor: pointer;
  font-weight: bold;
  text-decoration: none !important;
`;

export const Error = styled.div`
  color: ${colors.danger};
  margin: 1em 0;
`;

export const FFSBox = styled.div`
  background-color: ${colors.primary};
  color: ${colors.white};
  font-weight: bold;
  font-size: 10px;
  border-radius: 2px;
  height: 16px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
