import React from 'react';
import { MantraSpinner } from '../../../Components/LoadingOverlay';
import { Styles } from '../styles';

export const LoadingWidget = () => {
  return (
    <Styles.widget>
      <div className="flex justify-center pv3">
        <MantraSpinner size={25} simple />
      </div>
    </Styles.widget>
  );
};
