import { Modal } from 'baseui/modal';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FinalButton } from '../../Components/FinalButton';
import { CheckboxRHF, InputRHF, MultiSelectRHF, SelectRHF } from '../../Components/Form';
import { ListView } from '../../Components/ListView';
import { LoadingPage } from '../../Components/LoadingOverlay';
import {
  CareType,
  CouponFragment,
  CouponsQuery,
  DiscountType,
  useCouponsQuery,
  useOrganizationsSimpleQuery,
  useUpdateCouponMutation,
} from '../../graphQL';
import { UnexpectedError } from '../Shared';

type Coupon = CouponsQuery['coupons'][number];

export function Coupons() {
  const { data, loading, error, refetch } = useCouponsQuery();
  const [editing, setEditing] = useState<Coupon | 'new' | undefined>();

  const onSaveModal = () => {
    refetch();
    setEditing(undefined);
  };

  if (loading) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  return (
    <>
      <ListView
        additionalControls={
          <div>
            <FinalButton onClick={() => setEditing('new')}>New coupon</FinalButton>
          </div>
        }
        data={data.coupons}
        getKey={i => i.id}
        onClick={setEditing}
        columns={[
          {
            key: 'code',
            title: 'Code',
            render: i => i.code,
            sort: (a, b) => a.code.localeCompare(b.code),
          },
          {
            key: 'discount',
            title: 'Discount',
            render: describeCoupon,
          },
          {
            key: 'status',
            title: 'Status',
            render: i => (i.active ? 'Active' : 'Inactive'),
          },
        ]}
        initialSortColumn="code"
        initialSortDirection="asc"
        filters={[
          {
            key: 'status',
            options: [
              { id: 'active', label: 'Active' },
              { id: 'inactive', label: 'Inactive' },
            ],
            test: (coupon, value) => (value === 'active' ? coupon.active : !coupon.active),
            placeholder: 'Status',
          },
        ]}
        paginate={false}
      />
      {editing && (
        <EditCouponModal
          coupon={typeof editing === 'string' ? undefined : editing}
          onClose={() => setEditing(undefined)}
          onSave={onSaveModal}
        />
      )}
    </>
  );
}

const describeCoupon = (coupon: CouponFragment) => {
  let amount = '';
  if (coupon.discountType === DiscountType.PercentDiscount) {
    amount = `${coupon.percentDiscount}% off`;
  } else if (coupon.discountType === DiscountType.ReplacePrice) {
    amount = `$${coupon.replacePriceCents! / 100}`;
  }
  const careTypes = coupon.careTypes.map(i => i.toLowerCase()).join(' or ');
  return `${amount} for ${coupon.maxUses} ${careTypes} ${
    coupon.maxUses === 1 ? 'appointment' : 'appointments'
  }`;
};

interface ModalProps {
  coupon?: Coupon;
  onClose: () => void;
  onSave: () => void;
}

interface FormFields {
  code: string;
  active: boolean;
  discountType: DiscountType;
  replacePriceDollars?: number | null;
  percentDiscount?: number | null;
  maxUses: number;
  autoApplyOnConversion: boolean;
  organizationId?: number | null;
  careTypes: CareType[];
}

function EditCouponModal({ coupon, onClose, onSave }: ModalProps) {
  const { data, loading } = useOrganizationsSimpleQuery();
  const [updateCoupon, mutation] = useUpdateCouponMutation();
  const form = useForm<FormFields>({
    defaultValues: {
      active: true,
      ...coupon,
      organizationId: coupon?.organization?.id,
      replacePriceDollars: coupon?.replacePriceCents ? coupon.replacePriceCents / 100 : 0,
    },
  });

  if (loading) return null;

  const handleSave = form.handleSubmit(async values => {
    await updateCoupon({
      variables: {
        updateCoupon: {
          id: coupon?.id,
          code: values.code,
          active: values.active,
          discountType: values.discountType,
          percentDiscount: Number(values.percentDiscount),
          replacePriceCents:
            values.replacePriceDollars && Math.floor(Number(values.replacePriceDollars) * 100),
          maxUses: Number(values.maxUses),
          organizationId: values.organizationId,
          autoApplyOnConversion: values.autoApplyOnConversion,
          careTypes: values.careTypes,
        },
      },
    });
    onSave();
  });

  const discountType = form.watch('discountType');
  const autoApplyOnConversion = form.watch('autoApplyOnConversion');

  const discountTypeOptions = [
    { id: DiscountType.PercentDiscount, label: 'Percent Discount' },
    { id: DiscountType.ReplacePrice, label: 'Replace Price' },
  ];
  const careTypeOptions = [
    { id: CareType.Psychiatry, label: 'Psychiatry' },
    { id: CareType.Therapy, label: 'Therapy' },
  ];
  const organizationOptions = data?.organizations.map(i => ({ id: i.id, label: i.name })) ?? [];

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      <FormProvider {...form}>
        <form className="mt5 mb4 mh4" onSubmit={handleSave}>
          <h3 className="mb4">{coupon ? 'Editing coupon' : 'Creating new coupon'}</h3>

          <InputRHF controlProps={{ label: 'Code' }} name="code" rules={{ required: true }} />
          <SelectRHF
            controlProps={{ label: 'Discount type' }}
            name="discountType"
            defaultValue={null}
            rules={{ required: true }}
            options={discountTypeOptions}
          />
          {discountType === DiscountType.PercentDiscount && (
            <InputRHF
              controlProps={{ label: 'Percent discount' }}
              name="percentDiscount"
              type="number"
              min={0}
              max={100}
              rules={{ required: true, max: 100, min: 0 }}
            />
          )}
          {discountType === DiscountType.ReplacePrice && (
            <InputRHF
              controlProps={{ label: 'Replacement price ($)' }}
              name="replacePriceDollars"
              rules={{ required: true }}
              type="number"
            />
          )}

          <InputRHF
            controlProps={{ label: 'Number of appointments' }}
            name="maxUses"
            rules={{ required: true, min: 1 }}
            min={1}
            type="number"
          />

          <MultiSelectRHF
            controlProps={{ label: 'Care types' }}
            name="careTypes"
            rules={{ required: true }}
            options={careTypeOptions}
          />

          <CheckboxRHF name="active">Active</CheckboxRHF>
          <CheckboxRHF name="autoApplyOnConversion">
            Auto-apply to organization users when converting to self-pay
          </CheckboxRHF>

          {autoApplyOnConversion && (
            <div className="mb3">
              <SelectRHF
                controlProps={{ label: 'Organization to apply to' }}
                name="organizationId"
                rules={{ required: true }}
                options={organizationOptions}
              />
            </div>
          )}
          <FinalButton type="submit" loading={mutation.loading}>
            Save
          </FinalButton>
        </form>
      </FormProvider>
    </Modal>
  );
}
