import React, { ReactNode } from 'react';
import { getStylesheet } from '../../../../styles';
import { Layout } from '../../../core';

export const GrayBox = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <Layout.Center {...styles.grayBox} testID="gray-box">
      {children}
    </Layout.Center>
  );
};

const styles = getStylesheet({
  grayBox: {
    backgroundColor: 'secondary.200',
    borderRadius: '4px',
    paddingX: 4,
    paddingY: 6,
    width: '100%',
  },
});
