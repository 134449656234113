import { Avatar } from 'baseui/avatar';
import { toaster } from 'baseui/toast';
import { mapValues, partition, values } from 'lodash';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm, ValidationRules } from 'react-hook-form';
import styled from 'styled-components';
import { FinalButton } from '../../Components/FinalButton';
import { Checkbox, TimeRangeSelectRHF } from '../../Components/Form';
import { TimeRange } from '../../Components/Form/TimeRangeSelect';
import { Grid } from '../../Components/Grid';
import { icons } from '../../Components/Icons';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { isMantraAdmin } from '../../Components/Permissions';
import { Text } from '../../globalStyles';
import {
  DayOfWeek,
  OrganizationCrisisTeamQuery,
  useOrganizationCrisisTeamQuery,
  useUpdateCrisisTeamHoursMutation,
} from '../../graphQL';
import { useOrganizationId } from './util';

const daysOfWeek: DayOfWeek[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

type CrisisTeamProvider =
  OrganizationCrisisTeamQuery['organization']['crisisTeamProviders'][number];

export const OrganizationCrisisTeam = () => {
  const organizationId = useOrganizationId();
  const { data, loading, refetch } = useOrganizationCrisisTeamQuery({
    variables: { organizationId },
  });

  if (!data || loading) {
    return <LoadingPage />;
  }

  return (
    <Grid gridTemplateColumns="30em 1fr">
      <CrisisHours crisisTeamHours={data.organization.crisisHours} refetch={refetch} />
      <CrisisTeam crisisTeamProviders={data.organization.crisisTeamProviders} />
    </Grid>
  );
};

type CrisisTeamProps = {
  crisisTeamProviders: CrisisTeamProvider[];
};

const CrisisTeam = ({ crisisTeamProviders }: CrisisTeamProps) => {
  const [mantraTeam, organizationTeam] = partition(crisisTeamProviders, isMantraAdmin);
  return (
    <div>
      <div className="mb5">
        <Text.h2>Crisis Team</Text.h2>
        <Text.body>To add or remove a crisis team member, go to their profile.</Text.body>
        {organizationTeam.map(provider => (
          <div className="flex flex-row gap-2 items-center mt3" key={provider.name}>
            {/* todo on click go to profile */}
            <Avatar
              size="2.5rem"
              overrides={{ Avatar: { style: { maxWidth: '2.5rem' } } }}
              src={provider.portrait?.url ?? icons.assetsDefaultPicturePng}
              name={provider.name}
            />
            <Text.link to={`/providers/${provider.id}`} kind="grayText" className="b">
              {provider.name}
            </Text.link>
          </div>
        ))}
      </div>
      <Text.h2>Mantra Team</Text.h2>
      <Text.body>To add or remove a Mantra team member, go to their profile.</Text.body>
      {mantraTeam.map(provider => (
        <div className="flex flex-row gap-2 items-center mt3" key={provider.name}>
          <Avatar
            size="2.5rem"
            overrides={{ Avatar: { style: { maxWidth: '2.5rem' } } }}
            src={icons.iconsMantraCircleSvg}
            name={provider.name}
          />
          <Text.bodyBold kind="grayText">{provider.name}</Text.bodyBold>
        </div>
      ))}
    </div>
  );
};

type CrisisTeamHoursProps = {
  crisisTeamHours: OrganizationCrisisTeamQuery['organization']['crisisHours'];
  refetch: () => Promise<any>;
};

const CrisisHours = ({ crisisTeamHours, refetch }: CrisisTeamHoursProps) => {
  const organizationId = useOrganizationId();

  const defaultValues = crisisTeamHours.reduce((acc, curr) => {
    const startTime = moment(curr.startTime);
    const endTime = moment(curr.endTime);

    /* set to this week's day of week */
    const startForThisWeek = moment().day(curr.dayOfWeek).set({
      hour: startTime.hour(),
      minute: startTime.minute(),
      second: startTime.second(),
    });

    const endForThisWeek = moment().day(curr.dayOfWeek).set({
      hour: endTime.hour(),
      minute: endTime.minute(),
      second: endTime.second(),
    });

    return { ...acc, [curr.dayOfWeek]: [startForThisWeek.format(), endForThisWeek.format()] };
  }, {} as Record<DayOfWeek, TimeRange>);

  const [checkedMap, setCheckedMap] = useState(
    mapValues(defaultValues, _ => true) as Record<DayOfWeek, boolean>
  );

  const [loading, setLoading] = useState(false);
  const [save] = useUpdateCrisisTeamHoursMutation();

  const form = useForm({ defaultValues });

  const submit = form.handleSubmit(async days => {
    try {
      setLoading(true);
      await save({
        variables: {
          organizationId,
          crisisTeamHours: values(days).map(([lower, upper]) => ({
            startTime: moment(lower!).toDate(),
            endTime: moment(upper!).toDate(),
          })),
        },
      });
      await refetch();
      toaster.positive('Hours successfully updated.', {});
    } catch {
      toaster.negative('Unable to save, please try again.', {});
    } finally {
      setLoading(false);
    }
  });

  return (
    <div>
      <Text.h2>Crisis Team Hours</Text.h2>
      <Text.body>Select which days to set active crisis hours.</Text.body>
      <CheckboxContainer>
        <FormProvider {...form}>
          {daysOfWeek.map(d => (
            <React.Fragment key={d}>
              <DayOfWeekSelection
                dayOfWeek={d}
                isChecked={checkedMap[d]}
                onCheck={v => setCheckedMap(m => ({ ...m, [d]: v }))}
              />
            </React.Fragment>
          ))}
        </FormProvider>
        <FinalButton onClick={submit} loading={loading} className="w5 mt2">
          Save
        </FinalButton>
      </CheckboxContainer>
    </div>
  );
};

type DayOfWeekProps = {
  isChecked: boolean;
  onCheck: (v: boolean) => void;
  dayOfWeek: DayOfWeek;
};

const DayOfWeekSelection = ({ dayOfWeek, isChecked, onCheck }: DayOfWeekProps) => {
  const startTime = useRef(moment().day(dayOfWeek).startOf('day'));
  return (
    <div className="mb4">
      <Checkbox className="items-center" checked={isChecked} onChange={onCheck} kind="black">
        <Text.h3>{dayOfWeek}</Text.h3>
      </Checkbox>
      {isChecked && (
        <TimeRangeSelectRHF
          startTime={startTime.current}
          name={dayOfWeek}
          rules={validatationRules}
        />
      )}
    </div>
  );
};

const validatationRules: ValidationRules = {
  validate: {
    isCompleted: (range: TimeRange) => !!(range && range[0] && range[1]) || 'Date range incomplete',
    isSequential: ([lower, upper]: TimeRange) =>
      moment(lower!).isSameOrBefore(upper!) || 'Start time must be before end time',
  },
};

const CheckboxContainer = styled.div`
  margin: 1.5em 0;
`;
