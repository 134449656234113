import React from 'react';
import { UserType } from '../../types';
import { Modal } from '../../../../Components/Modal/Modal';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { Text } from '../../styles';
import styled from 'styled-components';
import { BirthdateInputRHF } from '../../../../Components/Form';
import { FinalButton } from '../../../../Components/FinalButton';
import { useMinorConsentOverrideUserMutation } from '../../../../graphQL';
import { getGraphqlFormatErrors } from '../../../../utils/graphql';

type OverrideDOBModalProps = {
  onClose: () => void;
  user: UserType;
  refetchMinorConsent: () => void;
};

export const OverrideDOBModal = ({ onClose, user, refetchMinorConsent }: OverrideDOBModalProps) => {
  return (
    <Modal size="tiny" isOpen={true} onClose={onClose}>
      <ModalContent>
        <Text.h1Bold>Override age and unblock an eligible non-minor</Text.h1Bold>

        <Text.body>
          If a non-minor was flagged for minor consent because of an incorrect birthday, override
          with the correct date so the student can continue onboarding.
        </Text.body>

        <Text.bodyBold>
          If you need to update the date of birth for someone who is currently a minor, update in
          the Patient Info section of the sidebar.
        </Text.bodyBold>
      </ModalContent>

      <FormSection>
        <OverrideDOBForm onCancel={onClose} user={user} refetchMinorConsent={refetchMinorConsent} />
      </FormSection>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FormSection = styled.div`
  margin-top: 20px;
`;

const isBirthdateValid = (birthdate: string): boolean => {
  const age = moment().diff(moment(birthdate), 'years');

  return age >= 18;
};

type OverrideDOBFormProps = {
  onCancel: () => void;
  user: UserType;
  refetchMinorConsent: () => void;
};

const OverrideDOBForm = ({ onCancel, user, refetchMinorConsent }: OverrideDOBFormProps) => {
  const formContext = useForm({
    defaultValues: {
      birthDate: '',
    },
    reValidateMode: 'onSubmit',
  });

  const { clearErrors, handleSubmit } = formContext;

  const [overrideBirthdate] = useMinorConsentOverrideUserMutation({
    onCompleted: () => {
      clearErrors();
      refetchMinorConsent();
      onCancel();
    },
    onError: overrideError => {
      const formatErrors = getGraphqlFormatErrors(overrideError);
      if (formatErrors.birthDate) {
        formContext.setError('birthDate', {
          type: 'invalid',
          message: 'Please enter a valid date.',
        });
        return;
      }

      formContext.setError('birthDate', {
        type: 'internal',
        message: 'An internal error has occurred.',
      });
    },
  });

  const submit = handleSubmit(async values => {
    void overrideBirthdate({
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: user.id,
        birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
      },
    });
  });

  return (
    <FormProvider {...formContext}>
      <FormContentSection>
        <DOBFormSection>
          <BirthdateInputRHF
            name="birthDate"
            controlProps={{
              label: 'Date of Birth',
              required: true,
            }}
            placeholder={
              user.birthDate
                ? moment(user.birthDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
                : 'MM/DD/YYYY'
            }
            rules={{
              validate: {
                'too-young': (value: string) =>
                  isBirthdateValid(value) ||
                  'Birthdate entered above still requires legal guardian consent. Student cannot be unblocked until appropriate consent is collected, uploaded, and marked as Approved.',
              },
            }}
          />
        </DOBFormSection>

        <DOBFormButtonSection>
          <FinalButton kind="outline_black" onClick={submit}>
            Update age and unblock patient
          </FinalButton>

          <FinalButton kind="minimal_gray" onClick={onCancel}>
            Cancel
          </FinalButton>
        </DOBFormButtonSection>
      </FormContentSection>
    </FormProvider>
  );
};

const DOBFormSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

const DOBFormButtonSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContentSection = styled.div`
  gap: 10px;
`;
