import { TabOverrides, TabsOverrides } from 'baseui/tabs';
import styled from 'styled-components';
import { Link } from '../../Components/Links';
import { colors } from '../../globalStyles';

export const Container = styled.div`
  max-width: 1120px;
  margin: auto;
`;

export const HeaderWrapper = styled.div<{ shadow?: boolean }>`
  padding: 1.5em 3.5em;
  background: white;
  box-shadow: ${({ shadow }) => (shadow ? '0px 6px 8px 1px rgba(0, 0, 0, 0.15)' : 'none')};
`;

export const BreadcrumbLink = styled(Link)`
  color: ${colors.text};
`;
export const BreadCrumbCurrent = styled.span`
  color: ${colors.grey.dark};
`;

export const TopWrapper = styled.div`
  padding: 0.75em 3.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${colors.grey.hr};
  background: white;
`;

export const Tab: TabOverrides<any> = {
  Tab: {
    style: ({ $active }) => ({
      color: colors.grey.dark,
      outline: 'none',
    }),
  },
};

export const Tabs: TabsOverrides<any> = {
  TabBar: {
    style: {
      backgroundColor: 'white',
      borderBottomWidth: '1px',
      borderBottomColor: colors.grey.light,
      borderBottomStyle: 'solid',
      paddingLeft: '3em',
      paddingRight: '3em',
    },
  },
  Root: {
    style: {},
  },
  TabContent: {
    style: {
      overflowX: 'auto',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      paddingLeft: '0',
      paddingRight: '0',
      backgroundColor: '#FAFAFA',
      height: '100%',
    },
  },
};

export const EditProfileForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  margin: 2em 0;
`;

export const FieldLabel = styled.div`
  margin-bottom: 0.75em;
`;

export const Divider = styled.hr`
  border-top: 0;
  border-bottom: 1px solid #ddd;
  width: 100%;
`;
