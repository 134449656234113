import React, { createContext } from 'react';
import styled from 'styled-components';
import { MantraAccordion, MantraAccordionPanel } from '../../../Components/Accordion';
import { Text } from '../../../globalStyles';
import { VideoCallSidebarQuery } from '../../../graphQL';
import { HANDBOOK_PANELS } from '../../Handbook/panels';

type SafeOperatingHandbookProps = {
  data: VideoCallSidebarQuery;
  options?: SafeOperatingHandbookOptions;
};

type SafeOperatingHandbookOptions = {
  vertical?: boolean; // Controls flow direction for handbook layout
};

type SohFlowContextType = {
  vertical?: boolean;
};

export const SohFlowContext = createContext<SohFlowContextType | null>(null);

export const SafeOperatingHandbookTab = ({ data, options }: SafeOperatingHandbookProps) => {
  const { organization } = data.user;

  if (!organization) {
    return <></>;
  }

  const vertical = options?.vertical ?? false;

  return (
    <SohFlowContext.Provider value={{ vertical }}>
      <HandbookTabSection>
        <Text.h2 className="mb4">{organization.name}</Text.h2>
        <MantraAccordion multi>
          {HANDBOOK_PANELS.map(({ title, Component, editableSectionRowInfo }, idx) => (
            <MantraAccordionPanel
              key={`panel-${idx}-${title}`}
              className="pb3"
              withBottomBorder
              title={
                <Text.bodyBold id={title} className="mr4">
                  {title}
                </Text.bodyBold>
              }
            >
              <Component
                verticalDisplay
                organizationId={organization.id}
                editable={false}
                handbookInfo={organization.safeOperatingHandbook}
                editableSectionRowInfo={editableSectionRowInfo}
                vertical={vertical}
              />
            </MantraAccordionPanel>
          ))}
        </MantraAccordion>
      </HandbookTabSection>
    </SohFlowContext.Provider>
  );
};

const HandbookTabSection = styled.section`
  padding: 2rem 0.25rem;
`;
