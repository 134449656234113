import React from 'react';
import styled from 'styled-components';
import { colors } from '../../globalStyles';
import Icons from '../../Icons';

interface Props {
  status: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;
const IconWrapper = styled.img`
  border-radius: 50%;
  background-color: ${({ status }: { status: string }) => statusBackground(status)};
  margin-right: 8px;
  height: 13px;
  width: 13px;
  padding: 3px;
`;
const Text = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1px;
  text-align: right;
  margin-right: 1em;
  color: ${({ status }: { status: string }) => statusColor(status)};
`;

function statusColor(status: string) {
  if (status === 'complete') return '#00D473';
  if (status === 'cancelled') return colors.danger;
  return 'black';
}

function statusBackground(status: string) {
  if (status === 'complete') return '#CBF6E3';
  if (status === 'cancelled') return '#F8CFCE';
  return '#E7E7E7';
}

function statusIcon(status: string) {
  const map: Record<string, string> = {
    complete: Icons.Check,
    cancelled: Icons.RedX,
    'no show': Icons.Black.noShow,
    rescheduled: Icons.Black.reschedule,
  };
  return map[status];
}

export function AppointmentStatusIndicator({ status }: Props) {
  if (status === 'upcoming') return null;
  return (
    <Container>
      <IconWrapper status={status} src={statusIcon(status)} />
      <Text className="truncate" status={status}>
        {status}
      </Text>
    </Container>
  );
}
