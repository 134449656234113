import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Text } from '../../../globalStyles';
import { useDrilldownContext } from '../helpers';
import { ConsentFAQ } from './ConsentFAQ';

export const ActiveConsentNotification = () => {
  const { user } = useDrilldownContext();
  const consent = user.dataSharingConsent;

  if (!consent || !consent.isActive) return null;

  return (
    <NotificationContainer className="mb4">
      <Text.label kind="success">FULL RECORD ACCESS</Text.label>
      <Text.bodySmall className="b">
        This patient has temporarily shared their full Mantra care record.
      </Text.bodySmall>
      <Text.bodySmall className="mt1">
        Full access expires on {moment(consent.endDate).format('M/DD/YY')}, unless patient consents
        to continue sharing.
      </Text.bodySmall>
      <ConsentFAQ />
    </NotificationContainer>
  );
};

const NotificationContainer = styled.div`
  padding: 0.75em 1.25em 0.75em 1.25em;
  background-color: rgba(64, 150, 33, 0.24);
  border-radius: 0.25em;
`;
