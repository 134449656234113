import { Modal } from 'baseui/modal';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FinalButton } from '../../../Components/FinalButton';
import { useCurrentProvider } from '../../../Components/Permissions';
import { Modal as ModalStyle, Text } from '../../../globalStyles';
import {
  Entitlement,
  useCreateDataSharingRequestMutation,
  useSendDataSharingRequestReminderMutation,
} from '../../../graphQL';
import { hasEntitlement } from '../../../utils';
import { useDrilldownContext } from '../helpers';
import { ConsentFAQ } from './ConsentFAQ';

export function LimitedRecordAlert() {
  const { user, refetch } = useDrilldownContext();
  const { currentProvider } = useCurrentProvider();
  const [createRequest] = useCreateDataSharingRequestMutation();
  const [sendReminder] = useSendDataSharingRequestReminderMutation();
  const [working, setWorking] = useState(false);
  const [showingRequestModal, setShowingRequestModal] = useState(false);
  const [showingConfirmationModal, setShowingConfirmationModal] = useState(false);

  if (
    !user.organization ||
    !hasEntitlement(user.organization, Entitlement.RestrictedPatientProfiles) ||
    user.dataSharingConsent
  ) {
    return null;
  }

  const handleCreateRequest = async () => {
    setWorking(true);
    await createRequest({ variables: { userId: user.id } });
    await refetch();
    setShowingRequestModal(false);
    setWorking(false);
    setShowingConfirmationModal(true);
  };

  const handleSendReminder = async () => {
    await sendReminder({ variables: { userId: user.id } });
    await refetch();
  };

  const lastRequestReminderDate = user.dataSharingRequest?.lastReminderSentDate;
  const canProviderSendReminder = user.dataSharingRequest?.canProviderSendReminder;
  const onCareTeam = user.campusTeam?.some(i => i.provider.id === currentProvider.id);

  return (
    <>
      <Box>
        <Text.bodyBold className="mt0 mb2">About this patient record</Text.bodyBold>
        <Text.bodySmall className="mb2">
          This record currently contains all data from university-sponsored visits and previously
          shared self-pay records.
        </Text.bodySmall>
        <Text.bodySmall className="b mb2">
          Self-pay records that have not been shared will not be visible.
        </Text.bodySmall>
        <div className="mb3">
          <ConsentFAQ />
        </div>
        {user.dataSharingRequest ? (
          <>
            <Text.bodySmall className="i b mb0">Pending patient consent.</Text.bodySmall>
            {!canProviderSendReminder ? (
              <Text.bodySmall>Reminder email sent.</Text.bodySmall>
            ) : (
              <Text.linkButtonSmall className="b" onClick={handleSendReminder}>
                Send reminder to patient?
              </Text.linkButtonSmall>
            )}
            <Text.bodySmall className="i mb0">
              {user.dataSharingRequest.provider.name} requested full access on{' '}
              {moment(user.dataSharingRequest.createdAt).format('M/D/YYYY')}.
            </Text.bodySmall>
            {canProviderSendReminder && lastRequestReminderDate && (
              <Text.bodySmall className="i mb0">
                Last reminder sent to patient on{' '}
                {moment(lastRequestReminderDate).format('M/D/YYYY')}.
              </Text.bodySmall>
            )}
          </>
        ) : (
          <FinalButton
            kind="outline_black"
            onClick={() => setShowingRequestModal(true)}
            style={{ background: 'white' }}
            iconLeft={{ icon: 'iconsBlackNoteSvg', alt: 'Note', size: 24 }}
          >
            Request full record
          </FinalButton>
        )}
      </Box>
      <Modal
        isOpen={showingRequestModal}
        onClose={() => setShowingRequestModal(false)}
        unstable_ModalBackdropScroll
      >
        <ModalStyle.body>
          <Text.h2 className="mb3">
            Request full access to patient record{!onCareTeam && ' & join collaboration team'}?
          </Text.h2>
          <Text.body className="mb3">
            The patient will receive an email to complete the record sharing process.
          </Text.body>
          <Text.body className="mb3">
            Once the patient consents to sharing their record, you will be given access to their
            full Mantra record for 180 days.
          </Text.body>
          {!onCareTeam && (
            <Text.body className="mb3">
              <span className="b">Note:</span> You will be added to this patient’s campus
              collaboration team.
            </Text.body>
          )}
          <FinalButton className="w-100" onClick={handleCreateRequest} loading={working}>
            Request full access
          </FinalButton>
        </ModalStyle.body>
      </Modal>
      <Modal
        isOpen={showingConfirmationModal}
        onClose={() => setShowingConfirmationModal(false)}
        unstable_ModalBackdropScroll
      >
        <ModalStyle.body>
          <Text.h2 className="mb3">Patient record has been requested </Text.h2>
          <Text.body className="mb3">
            If the patient consents to sharing their full record with your organization, once the
            patient consents to sharing their record, you will be given access to their full Mantra
            record for 180 days.
          </Text.body>
          <FinalButton
            onClick={() => setShowingConfirmationModal(false)}
            className="w-100"
            kind="outline_black"
          >
            Close
          </FinalButton>
        </ModalStyle.body>
      </Modal>
    </>
  );
}

const Box = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px 20px;
  margin-bottom: 20px;
`;
