import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useEmbedPatientIdFromHealthieIdQuery } from '../../../../graphQL';
import { useParseHealthieId } from './useParseHealthieId';

type UseLoadEmbedUidResult = {
  error?: Error;
  patientId?: string;
  loading?: boolean;
};

export const useLoadEmbedUid = (): UseLoadEmbedUidResult => {
  const { healthieId, error: parseError } = useParseHealthieId();

  const {
    data: queryData,
    loading,
    error: queryError,
  } = useEmbedPatientIdFromHealthieIdQuery({
    skip: isEmpty(healthieId),
    variables: {
      healthieId: healthieId ?? '',
    },
  });

  const patientId = useMemo(() => queryData?.embedPatientIdFromHealthieId ?? 0, [queryData]);
  const error = useMemo(() => {
    if (parseError) {
      return parseError;
    }
    if (!patientId) {
      return new Error('Patient not found');
    }
    if (queryError) {
      return new Error('Could not fetch patient');
    }

    return undefined;
  }, [parseError, queryError, patientId]);

  return { patientId: String(patientId), error, loading };
};
