import { Accordion, Panel } from 'baseui/accordion';
import { ChevronDown, ChevronUp } from 'baseui/icon';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';
import { simpleAccordionOverride } from '../../Pages/Users/baseuiOverrides';
import { orNullFactory } from '../OrNull';
import { ProtocolContainer, ProtocolContent } from './styles';

const protocolData = [
  {
    riskLevel: 'Very Low',
    criteria: [
      'No SI or HI, including passive',
      'No history of violence, suicide attempt or self-injury',
    ],
    responses: ['Routine outpatient clinical practice'],
  },
  {
    riskLevel: 'Low',
    criteria: [
      'Recent HI/SI WITHOUT plan or intent',
      'Frequency and duration of ideation is low',
      'No evidence of preparation or difficulty controlling impulses',
      'No suicide attempt or violence in the last 6 months',
    ],
    responses: [
      'Address coping strategies, protective factors',
      'Confirm emergency contact information',
      'Address social supports, means restriction and limiting substance use',
      'Consider creating a safety plan (available in risk documentation section of patient’s chart)',
    ],
  },
  {
    riskLevel: 'Intermediate',
    criteria: [
      'Current HI or SI with/without plan but WITHOUT intent or behavior',
      'Intact impulse control',
      'Frequency/duration of ideation is moderate to high.',
      'No violence, suicide attempt, or preparations in last 6 months',
    ],
    responses: [
      'Create/send safety plan (available in risk documentation section of the chart)',
      'Increase frequency and/or intensity of visits',
      'To consider: Peer consult, med  change, add in-person therapist,  refer for in-person treatment, discuss with supervisor or medical director',
    ],
  },
  {
    riskLevel: 'High',
    criteria: [
      'Persistent SI/HI with plan or intent to act on a plan',
      'Poor impulse control, poor insight and/or judgment',
      'Recent (<1yr) violence, suicide attempt, or preparations',
      'Severe psych symptoms, or acute precipitating event',
      'Protective factors not relevant',
    ],
    responses: [
      'Create/send safety plan (available in risk documentation section of the chart)',
      'To consider: call 911/EMS for wellness check/eval',
      'Contact emergency contact if appropriate',
      'Initiate process to refer out of Mantra',
      'Report to Medical Director',
      'Document safety plan and disposition before ending appointment',
    ],
  },
  {
    riskLevel: 'Imminent',
    criteria: [
      'Potentially lethal suicide attempt',
      'Persistent SI/HI with strong intent',
      'Suicide rehearsal or preparatory behavior',
      'HI with intent and/or named possible victim',
    ],
    responses: [
      'Safety plan with means restriction',
      'Call 911 for eval',
      'Contact emergency contact and f/u',
      'Initiate process to refer out of Mantra',
      'Report to Medical Director',
      'Document safety plan and disposition before ending appointment',
      'In case of HI duty to warn question will automatically be included in safety/risk section of chart',
    ],
  },
];

const ClinicalProtocols = () => {
  const [showExpandMsg, setShowExpandMsg] = useState(true);
  const title = (
    <div>
      <Text.label>Wellround Clinical Protocols</Text.label>
      <Text.bodyBold>Risk Stratification &amp; Clinical Response</Text.bodyBold>
    </div>
  );
  return (
    <ProtocolContainer>
      <Accordion>
        <Panel
          onChange={({ expanded }) => setShowExpandMsg(!expanded)}
          title={title}
          overrides={simpleAccordionOverride({
            isLastRow: true,
            ToggleIcon: ({ $expanded }) => (
              <div className="flex flex-row justify-center">
                <Text.linkButton className="b" kind="primary">
                  {$expanded ? 'Close' : 'Open'}
                </Text.linkButton>
                {$expanded ? (
                  <ChevronUp size={24} color={colors.primary} />
                ) : (
                  <ChevronDown size={24} color={colors.primary} />
                )}
              </div>
            ),
          })}
        >
          <ProtocolContent>
            <table>
              <thead>
                <tr>
                  {['Risk Level', 'Criteria', 'Clinical responses'].map(l => (
                    <Text.label as="th" key={l}>
                      {l}
                    </Text.label>
                  ))}
                </tr>
              </thead>
              <tbody>
                {protocolData.map(p => (
                  <tr key={p.riskLevel}>
                    <Text.bodySmall className="b" as="td">
                      {p.riskLevel}
                    </Text.bodySmall>
                    <td>
                      <ExplanationList>
                        {p.criteria.map((c, i) => (
                          <li key={i}>{c}</li>
                        ))}
                      </ExplanationList>
                    </td>
                    <td>
                      <ExplanationList>
                        {p.responses.map((r, i) => (
                          <li key={i}>{r}</li>
                        ))}
                      </ExplanationList>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ProtocolContent>
        </Panel>
      </Accordion>
      {showExpandMsg && (
        <Text.bodySmall>
          Expand for standard guidelines for risk assessment &amp; suggested clinical response.
        </Text.bodySmall>
      )}
    </ProtocolContainer>
  );
};

export const ExplanationOrNull = orNullFactory({
  'status-suicidal': ClinicalProtocols,
});

const ExplanationList = styled.ul`
  margin-top: 0.25rem;
  padding-left: 1rem;
  list-style-type: disc;
  & li {
    margin-top: 0.25rem;
  }
`;
