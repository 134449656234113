import React from 'react';
import styled from 'styled-components';
import { FinalButton } from '../../Components/FinalButton';
import { CenteredOverlay, Text } from '../../globalStyles';
import { Nullable } from '../../types';
import { UnstyledLink } from '../Shared';

type CallBlockedProps = {
  title: Nullable<string>;
  body: Nullable<string>;
};
export const VideoCallBlocked = ({ title, body }: CallBlockedProps) => {
  return (
    <BlockedScreen>
      <div className="mw6">
        <Text.h1 kind="white" className="mb4">
          {title ?? 'Something went wrong'}
        </Text.h1>
        {body && (
          <Text.h3 kind="white" className="mb4">
            {body ?? 'Something went wrong'}
          </Text.h3>
        )}
        <UnstyledLink to="/home">
          <FinalButton kind="white" className="w5">
            Close
          </FinalButton>
        </UnstyledLink>
      </div>
    </BlockedScreen>
  );
};

export const VideoCallDismissed = () => (
  <VideoCallBlocked
    title="You’ve been dismissed from this call."
    body="Please contact Mantra Health if you think this is an error at 1-800-464-2083"
  />
);

const BlockedScreen = styled(CenteredOverlay)`
  text-align: center;
  background-color: black;
`;
