import React from 'react';
import { Text } from '../../globalStyles';
import { Permission, useDebugLoginAsUserMutation } from '../../graphQL';
import { useCurrentProvider } from '../Permissions';

export const LoginAsUser = ({ userId }: { userId: number }) => {
  const { featureFlags, hasPermission } = useCurrentProvider();
  const [loginAsUser] = useDebugLoginAsUserMutation();

  const onClick = async () => {
    const mutData = await loginAsUser({ variables: { userId } });
    if (!mutData.data) return;
    window.open(mutData.data.debugLoginAsUser, '_blank');
  };

  if (!featureFlags.includes('SEED') || !hasPermission(Permission.MantraAdmin)) return null;
  return <Text.linkButton onClick={onClick}>Login As</Text.linkButton>;
};
