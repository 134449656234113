import { AppView } from '../../graphQL';

export type CopyKey = 'patient' | 'patients' | 'provider' | 'providers';

export const thesaurus: Record<AppView, Record<CopyKey, string>> = {
  oz: {
    patient: 'patient',
    patients: 'patients',
    provider: 'provider',
    providers: 'providers',
  },
  mcp: {
    patient: 'student',
    patients: 'students',
    provider: 'staff',
    providers: 'staff',
  },
  referral: {
    patient: 'student',
    patients: 'students',
    provider: 'staff',
    providers: 'staff',
  },
};
