import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colorMap, Text } from '../../globalStyles';
import { Nullable } from '../../types';
import { FinalButton } from '../FinalButton';
import { InlineSVG } from '../Icons';
import { MaxWidthDiv } from '../MaxWidth';
import { BookableHoursModal } from './BookableHoursModal';
import { OzOnly, McpOnly } from '../Permissions/Only';
import { moveToWeekStart } from '../Scheduler/index';
import { MCPSoonerAvailabilityModal } from '../ProviderNetwork/ProvidersSoonerAvailability';
import { AppView } from '../../graphQL';
import { useCurrentProvider } from '../Permissions';

type BookableFollowupsDepletedProps = {
  onSelectStartDate: (m: Moment) => void;
  organizationName?: string;
  nextAvailableDGMDate?: Nullable<Date>;
  nextAvailableDGMProviderDate?: Date;
  className?: string;
  wholeCampusCare?: boolean;
};

export const BookableFollowupsDepleted = ({
  organizationName,
  onSelectStartDate,
  nextAvailableDGMProviderDate,
  className,
  wholeCampusCare = false,
}: BookableFollowupsDepletedProps) => {
  const [isOzModalOpen, setIsOzModalOpen] = useState(false);
  const [isMCPModalOpen, setIsMCPModalOpen] = useState(false);

  const { appView } = useCurrentProvider();

  const nextAvailableMoment = nextAvailableDGMProviderDate
    ? moment(nextAvailableDGMProviderDate)
    : undefined;
  const moveToWeek = nextAvailableMoment ? moveToWeekStart(nextAvailableMoment) : undefined;

  // MCP providers in DGM orgs do not see dgm-related messaging
  if (appView === AppView.Mcp && wholeCampusCare) {
    return (
      <BookableFollowupsDepletedContainer className={className}>
        <MaxWidthDiv className="center flex flex-column gap-2" mw="30rem">
          <Text.h3>No provider availability for the selected timeframe.</Text.h3>
          <McpOnly>
            <ModalButton className="b" onClick={() => setIsMCPModalOpen(true)}>
              Looking for sooner availability?
            </ModalButton>
            <MCPSoonerAvailabilityModal
              isOpen={isMCPModalOpen}
              onClose={() => setIsMCPModalOpen(false)}
            />
          </McpOnly>
          {nextAvailableMoment && moveToWeek && (
            <div>
              <Text.bodySmall>
                The next availability to book a follow-up for a {organizationName ?? ''} student is
                on <strong>{nextAvailableMoment.format('MMMM D, YYYY')}.</strong>
              </Text.bodySmall>
              <FinalButton
                className="mt3 bg-white"
                kind="outline_black"
                onClick={() => onSelectStartDate(moveToWeek)}
              >
                Next availability {nextAvailableMoment.format('ddd, MMMM D')}
              </FinalButton>
            </div>
          )}
        </MaxWidthDiv>
      </BookableFollowupsDepletedContainer>
    );
  }
  return (
    <BookableFollowupsDepletedContainer className={className}>
      <MaxWidthDiv className="center flex flex-column gap-2" mw="30rem">
        <div className="tc center flex flex-row gap-2 items-center">
          <InlineSVG icon="alert-circle" size={15} kind="warning" />
          <Text.label kind="black">Limited bookable hours for organization</Text.label>
        </div>
        <Text.h3>
          {organizationName ?? 'This organization'} does not have sufficient bookable hours for the
          selected week
        </Text.h3>
        <OzOnly>
          <ModalButton className="b" onClick={() => setIsOzModalOpen(true)}>
            What does this mean?
          </ModalButton>
          <BookableHoursModal isOpen={isOzModalOpen} onClose={() => setIsOzModalOpen(false)} />
        </OzOnly>
        <McpOnly>
          <ModalButton className="b" onClick={() => setIsMCPModalOpen(true)}>
            Looking for sooner availability?
          </ModalButton>
          <MCPSoonerAvailabilityModal
            isOpen={isMCPModalOpen}
            onClose={() => setIsMCPModalOpen(false)}
          />
        </McpOnly>
        {nextAvailableMoment && moveToWeek && (
          <div>
            <Text.bodySmall>
              The next availability to book a follow-up for a {organizationName ?? ''} student is on{' '}
              <strong>{nextAvailableMoment.format('MMMM D, YYYY')}.</strong>
            </Text.bodySmall>
            <FinalButton
              className="mt3 bg-white"
              kind="outline_black"
              onClick={() => onSelectStartDate(moveToWeek)}
            >
              Next availability {nextAvailableMoment.format('ddd, MMMM D')}
            </FinalButton>
          </div>
        )}
      </MaxWidthDiv>
    </BookableFollowupsDepletedContainer>
  );
};

const BookableFollowupsDepletedContainer = styled.div`
  padding: 2rem;
  background: ${colorMap.warning[5]};
  position: relative;
  opacity: 1;
  width: 100%;
`;

const ModalButton = styled(Text.linkButton)`
  text-align: left;
`;
