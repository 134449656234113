import { ExternalToOptions, useExternalNavigate } from './useExternalNavigate';
import { useNavigate, NavigateOptions, To } from './useNavigate';

export type { NavigateOptions, To };

export const useSmartNavigate = (): ((
  to: To,
  toOptions?: NavigateOptions,
  externalToOptions?: ExternalToOptions
) => void) => {
  const navigate = useNavigate();
  const externalNavigate = useExternalNavigate();

  return (to: To, toOptions?: NavigateOptions, externalToOptions?: ExternalToOptions): void => {
    const isExternal = to.substring(0, 4) === 'http';
    const isMailTo = to.substring(0, 6) === 'mailto';

    if (isExternal || isMailTo) {
      externalNavigate(to, externalToOptions);
      return;
    }

    navigate(to, toOptions);
  };
};
