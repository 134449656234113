import React, { useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import moment from 'moment';
import {
  CareType,
  ContractWeek,
  ContractWeeks,
  EditContractWeekIntakesInput,
  EditContractWeekSessionsInput,
  useUpdateContractWeeksMutation,
} from '../../../graphQL';
import { FinalButton } from '../../../Components/FinalButton';
import { pastColumnCopy, thisWeekColumnCopy, upcomingColumnCopy } from './ContractUtilizationCopy';
import { ContractUtilizationRow, ContractUtilizationTable } from './ContractUtilizationTable';

type UtilizationDashboardProps = {
  careType: CareType;
  editable?: boolean;
  // Only the records for this careType
  utilizationRecords: ContractWeeks;
  refetch: () => void;
};

// The server version of contract week input is composed differently (EditContractWeekIntakesInput, EditContractWeekSessionsInput)
// But this ContractUtilizationTable component still has each week combined.
type ContractWeekInput = {
  id: number;
  psychiatryMaxSessions?: number;
  therapyMaxSessions?: number;
  psychiatryMaxIntakes?: number;
  therapyMaxIntakes?: number;
};

type ContractWeekKey = keyof ContractWeekInput;

type UpdateMap = {
  [key: number]: ContractWeekInput;
};

export const ContractUtilizationDashboard = ({
  careType,
  editable,
  utilizationRecords,
  refetch,
}: UtilizationDashboardProps) => {
  const [isEditable, setIsEditable] = useState(false);
  const [update] = useUpdateContractWeeksMutation();
  const [errorCount, setErrorCount] = useState(0);

  const [updatedContractWeeks, setUpdatedContractWeeks] = useState<UpdateMap>({});

  const getValueComparator = (
    sessionCategoryKey: ContractWeekKey,
    isIntake: boolean,
    contractWeekId: number
  ): number | undefined => {
    // Define keys for checking for comparison values in state and db records
    const oppositeStateKey = isIntake
      ? sessionCategoryKey.replace('Intakes', 'Sessions')
      : sessionCategoryKey.replace('Sessions', 'Intakes');

    const oppositeRecordKey = isIntake ? 'sessionsMax' : 'intakesMax';

    // First, check if value exists in current state
    if (
      updatedContractWeeks[contractWeekId] &&
      Object.keys(updatedContractWeeks[contractWeekId]).includes(oppositeStateKey)
    ) {
      return updatedContractWeeks[contractWeekId][oppositeStateKey as ContractWeekKey] as number;
    }

    // if no value in the current form state to compare to, check if the DB record has a value to compare to

    // No need to error check past weeks
    const possibleRecord = [utilizationRecords.current, ...utilizationRecords.upcoming].find(ur => {
      return ur?.id === contractWeekId;
    });

    if (!possibleRecord) {
      return undefined;
    }

    if (possibleRecord) {
      const relatedSessions = possibleRecord;

      // set Comparator to DB record's maxSessions or null
      return oppositeRecordKey in relatedSessions
        ? (relatedSessions[oppositeRecordKey] as number)
        : undefined;
    }
    return undefined;
  };

  const {
    past: completedRows,
    current: currentRows,
    upcoming: upcomingRows,
  } = useMemo(() => {
    return prepUtilizationRows(utilizationRecords);
  }, [utilizationRecords]);

  const handleChange = (
    newValue: string,
    isIntake: boolean,
    contractWeekId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const errorElement = event.target.nextElementSibling;
    if (!errorElement) {
      return;
    }
    // Prep record access key based on careType and intake status
    const sessionCategoryKey = `${careType.toLowerCase()}Max${
      isIntake ? 'Intakes' : 'Sessions'
    }` as ContractWeekKey;

    // Then update state with new value
    const updatedValue: UpdateMap = {};
    updatedValue[contractWeekId] = updatedContractWeeks[contractWeekId]
      ? updatedContractWeeks[contractWeekId]
      : { id: contractWeekId };
    updatedValue[contractWeekId][sessionCategoryKey as ContractWeekKey] = getNumFromVal(
      newValue,
      isIntake
    );

    setUpdatedContractWeeks(currentState => ({
      ...currentState,
      ...updatedValue,
    }));

    const comparator: number | undefined = getValueComparator(
      sessionCategoryKey,
      isIntake,
      contractWeekId
    );

    const { isValid, errorString } = validateAllocationValue(newValue, isIntake, comparator);

    // Then update UI based on validity and error message
    if (isValid) {
      if (errorElement.textContent !== '') {
        setErrorCount(errorCount - 1);
        errorElement.textContent = '';
        event.target.classList.remove('error');
        (errorElement as HTMLElement).style.cssText = 'margin:0';
      }
    } else {
      if (errorElement.textContent === '') {
        event.target.className += ' error';
        (errorElement as HTMLElement).style.cssText = 'text-align:center;margin-bottom:10px';
        setErrorCount(errorCount + 1);
      }
      errorElement.textContent = errorString;
    }
  };

  // TODO: Handle errors from api (note this is a old DGM todo, not a new sessions todo)
  const onSave = async () => {
    if (!Object.keys(updatedContractWeeks).length) {
      return;
    }
    let psychiatryWeekIntakes: EditContractWeekIntakesInput[] = [];
    let psychiatryWeekSessions: EditContractWeekSessionsInput[] = [];
    let therapyWeekIntakes: EditContractWeekIntakesInput[] = [];
    let therapyWeekSessions: EditContractWeekSessionsInput[] = [];
    Object.values(updatedContractWeeks).forEach(contractWeek => {
      if (contractWeek.psychiatryMaxIntakes != null) {
        psychiatryWeekIntakes.push({
          id: contractWeek.id,
          intakes: contractWeek.psychiatryMaxIntakes,
        });
      }
      if (contractWeek.psychiatryMaxSessions != null) {
        psychiatryWeekSessions.push({
          id: contractWeek.id,
          sessions: contractWeek.psychiatryMaxSessions,
        });
      }
      if (contractWeek.therapyMaxIntakes != null) {
        therapyWeekIntakes.push({
          id: contractWeek.id,
          intakes: contractWeek.therapyMaxIntakes,
        });
      }
      if (contractWeek.therapyMaxSessions != null) {
        therapyWeekSessions.push({
          id: contractWeek.id,
          sessions: contractWeek.therapyMaxSessions,
        });
      }
    });

    await update({
      variables: {
        input: {
          psychiatryWeekIntakes,
          psychiatryWeekSessions,
          therapyWeekIntakes,
          therapyWeekSessions,
        },
      },
    });
    refetch();
  };

  // TODO: handle attempts to cancel or change tabs with unsaved changed (note this is a old DGM todo, not a new sessions todo)
  return (
    <div>
      {completedRows.length > 0 && (
        <div className="mb4">
          {/* <CompletedUtilizationTable records={utilizationRecords.past} editable={false} /> */}
          <ContractUtilizationTable
            heading="Completed"
            rows={completedRows}
            columns={pastColumnCopy(careType)}
            editable={false}
          />
        </div>
      )}
      {currentRows.length > 0 && (
        <div className="mb4">
          <ContractUtilizationTable
            heading="This Week"
            rows={currentRows}
            columns={thisWeekColumnCopy(careType)}
            editable={isEditable}
            // Columns for the current week are (Max Sessions - EDITABLE, Sessions Utilized, Sessions Scheduled, Max Intakes - EDITABLE, Intakes Utilized, Intakes Scheduled)
            editableColumns={[[false], [true, false, false], [true, false, false]]}
            handleChange={handleChange}
          />
        </div>
      )}
      {upcomingRows.length > 0 && (
        <div className="mb4">
          <ContractUtilizationTable
            heading="Upcoming"
            rows={upcomingRows}
            columns={upcomingColumnCopy(careType)}
            editable={isEditable}
            // Columns for the current week are (Max Sessions - EDITABLE, Sessions Scheduled, Max Intakes - EDITABLE, Intakes Scheduled)
            editableColumns={[[false], [true, false], [true, false]]}
            handleChange={handleChange}
          />
        </div>
      )}

      {editable && (
        <div>
          {!isEditable ? (
            <FinalButton
              kind="outline_black"
              onClick={() => {
                setIsEditable(true);
              }}
            >
              Edit {capitalize(careType)} Sessions
            </FinalButton>
          ) : (
            <>
              <FinalButton
                kind="primary"
                className="mr2"
                onClick={() => {
                  setIsEditable(false);
                  onSave();
                }}
              >
                Save Changes
              </FinalButton>
              <FinalButton
                kind="minimal_gray"
                onClick={() => {
                  setIsEditable(false);
                }}
              >
                Cancel
              </FinalButton>
            </>
          )}
        </div>
      )}
    </div>
  );
};

function validateAllocationValue(
  newValue: string,
  isIntake: boolean,
  comparator: number | undefined
) {
  const newValNum = getNumFromVal(newValue, isIntake);

  // Allow users to clear out values
  if (newValue === '') {
    return { isValid: true, errorString: '' };
  }

  if (isIntake) {
    // Validation for Intake Fields
    // First check that it's a whole number
    const intakeRegex = /^\d+$/;
    if (newValue.match(intakeRegex) === null) {
      return { isValid: false, errorString: 'Only positive whole numbers allowed.' };
    }

    // Then, if have a comparator, compare, otherwise valid
    if ((comparator === 0 || comparator) && newValNum > comparator) {
      return {
        isValid: false,
        errorString: 'Number of max intakes cannot exceed max bookable sessions.',
      };
    }
  } else {
    // Validation for Sessions Fields (Non Intake)
    // First check that it's a whole number or 0.25, .5, .75, .0
    // First check that it's a whole number
    const sessionsRegex = /^\d+$/;
    if (newValue.match(sessionsRegex) === null) {
      return { isValid: false, errorString: 'Only positive whole numbers allowed.' };
    }
    // Then, if have a comparator, compare, otherwise valid
    if ((comparator === 0 || comparator) && comparator > newValNum) {
      return {
        isValid: false,
        errorString: 'Number of max bookable sessions cannot be less than max intakes',
      };
    }
  }
  return { isValid: true, errorString: '' };
}

type PreppedRows = {
  past: ContractUtilizationRow[];
  current: ContractUtilizationRow[];
  upcoming: ContractUtilizationRow[];
};

function prepUtilizationRows(utilizationRecords: ContractWeeks): PreppedRows {
  const result: PreppedRows = { past: [], current: [], upcoming: [] };
  // Completed weeks in contract
  for (const record of utilizationRecords.past) {
    const rowWeekText = prepWeekText(record);
    result.past.push({
      id: record.id,
      rowData: [
        [rowWeekText],
        [
          record.sessionsMax.toLocaleString(),
          record.sessionsUtilized.toLocaleString(),
          record.intakesUtilized.toLocaleString(),
        ],
      ],
    });
  }
  // Current week in contract
  if (utilizationRecords.current) {
    result.current.push({
      id: utilizationRecords.current.id,
      rowData: [
        [prepWeekText(utilizationRecords.current)],
        [
          utilizationRecords.current.sessionsMax.toLocaleString(),
          utilizationRecords.current.sessionsUtilized.toLocaleString(),
          utilizationRecords.current.sessionsScheduled.toLocaleString(),
        ],
        [
          utilizationRecords.current.intakesMax.toLocaleString(),
          utilizationRecords.current.intakesUtilized.toLocaleString(),
          utilizationRecords.current.intakesScheduled.toLocaleString(),
        ],
      ],
    });
  }

  // Upcoming weeks in contract
  for (const record of utilizationRecords.upcoming) {
    const rowWeekText = prepWeekText(record);
    result.upcoming.push({
      id: record.id,
      rowData: [
        [rowWeekText],
        [record.sessionsMax.toLocaleString(), record.sessionsScheduled.toLocaleString()],
        [record.intakesMax.toLocaleString(), record.intakesScheduled.toLocaleString()],
      ],
    });
  }

  result.upcoming.sort((session1, session2) => session1.id - session2.id);

  return result;
}

function prepWeekText(contractWeek: ContractWeek) {
  const mmtStart = moment(contractWeek.startDate);
  const mmtEnd = moment(contractWeek.endDate);
  const diff = mmtEnd.diff(mmtStart, 'days') + 1;

  let rowWeekText = `${moment(contractWeek.startDate).format('M/D/YYYY')} - ${moment(
    contractWeek.endDate
  ).format('M/D/YYYY')}`;

  if (diff < 7) {
    rowWeekText += ` (${diff} day${diff > 1 ? 's' : ''})`;
  }
  return rowWeekText;
}

const getNumFromVal = (stringValue: string, isIntake: boolean) => {
  if (isNaN(parseInt(stringValue, 10))) {
    return 1000;
  }
  return isIntake ? parseInt(stringValue, 10) : parseFloat(stringValue);
};
