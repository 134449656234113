import { compact, flatten } from 'lodash';
import moment from 'moment';
import { CalendarEvent, FormSection as MedicalNoteSection } from '../../graphQL';
import { guessTzShorthand } from '../../utils';
import { EditSection } from './formTypes';

export const formatQuestionsForEdit = (sections: MedicalNoteSection[]) => {
  // strip these so we dont accidantally reference them in the form
  return countQuestions(sections, ({ value: v1, children, ...s }) => ({
    ...s,
    children:
      children &&
      countQuestions(children, ({ value: v2, ...v }) => ({
        ...v,
        children: undefined,
      })),
  }));
};

export const formatQuestionsForView = (template: MedicalNoteSection[]) => {
  const formatValue = ({ value }: Pick<MedicalNoteSection, 'value'>) => {
    return value ? JSON.parse(value) : null;
  };
  return countQuestions(template, s => ({
    ...s,
    value: formatValue(s),
    children:
      s.children &&
      countQuestions(s.children, v => ({
        ...v,
        value: formatValue(v),
        children: undefined,
      })),
  }));
};

export const formatSingleValue = (value?: any) => {
  return value ? JSON.parse(value) : null;
};

const countQuestions = <T extends {}>(
  template: MedicalNoteSection[],
  getVal: (section: MedicalNoteSection) => T
) => {
  let currentNum = 0;
  return template.map(s => {
    if (!s.uncounted) currentNum += 1;
    const number = !s.uncounted ? currentNum : undefined;
    return { number, ...getVal(s) };
  });
};

const timezone = guessTzShorthand();

export const appointmentLabel = (appt: Pick<CalendarEvent, 'startTime' | 'description'>) => {
  return `${moment(appt.startTime).format('M/D/YYYY h:mm A')} ${timezone} - ${appt.description}`;
};

export const showDependentQuestion = ({
  dependsOn,
  getValue,
}: {
  dependsOn: NonNullable<EditSection['dependsOn']>;
  getValue: (k: string) => any;
}) => {
  const dependsOnValue = getValue(dependsOn.key);
  const dependsOnValues = compact(flatten([dependsOnValue]));

  if (!dependsOnValues.length) return false;
  if (!dependsOn.showValues) return true;

  // lodash intersection does not work as expected
  const showValueLookup = new Set(dependsOn.showValues);
  return dependsOnValues.some(v => showValueLookup.has(v));
};
