import React from 'react';
import styled from 'styled-components';
import { Text, colors } from '../../globalStyles';
import { cx } from '../../utils';

type HandbookNavProps = {
  navigationIds: {
    navId: string;
    /** emergency used to color the emergency protocol nav link red * */
    emergency?: boolean;
  }[];
  activeNav: string;
  setActiveNav: (navId: string) => void;
  emergency?: boolean;
};

export const HandbookNavigation = ({
  navigationIds,
  activeNav,
  setActiveNav,
}: HandbookNavProps) => {
  return (
    <div>
      {navigationIds.map(({ navId, emergency }, idx) => (
        <HandbookNavLink
          key={`${idx}-${navId}`}
          navId={navId}
          activeNav={activeNav}
          setActiveNav={setActiveNav}
          emergency={emergency}
        />
      ))}
    </div>
  );
};

type HandbookNavLinkProps = {
  navId: string;
  activeNav: string;
  setActiveNav: (navId: string) => void;
  emergency?: boolean;
};

const HandbookNavLink = ({ navId, activeNav, setActiveNav, emergency }: HandbookNavLinkProps) => {
  const scrollToSection = (click: React.MouseEvent) => {
    click.preventDefault();
    setActiveNav(navId);
    const identity = document.getElementById(`${navId}`);
    identity?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <HandbookNavLinkDiv>
      <Text.link
        to="/"
        onClick={scrollToSection}
        className={cx(activeNav === navId ? 'active' : '', emergency ? 'emergency' : '')}
        kind="black"
      >
        {navId}
      </Text.link>
    </HandbookNavLinkDiv>
  );
};

const HandbookNavLinkDiv = styled.div`
  padding-top: 2em;
  font-weight: 700;

  .active {
    color: ${colors.link};
  }

  .emergency {
    color: ${colors.danger};
  }
`;
