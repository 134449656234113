import moment, { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';
import { colors, Text } from '../../globalStyles';
import { Nullable } from '../../types';
import { cx } from '../../utils';
import { InlineSVG } from '../Icons';
import { moveToWeekStart } from '../Scheduler/index';
import { useCurrentProvider } from '../Permissions';
import { AppView } from '../../graphQL';

type BookableIntakesDepletedProps = {
  onSelectStartDate: (m: Moment) => void;
  nextAvailableDGMDate?: Nullable<Date>;
  nextAvailableDGMProviderDate?: Date;
  className?: string;
  gridSpan?: number;
  wholeCampusCare?: boolean;
};

export const BookableIntakesDepleted = ({
  nextAvailableDGMProviderDate,
  onSelectStartDate,
  className,
  gridSpan,
  wholeCampusCare = false,
}: BookableIntakesDepletedProps) => {
  const nextAvailableMoment = moment(nextAvailableDGMProviderDate);
  const moveToWeek = moveToWeekStart(nextAvailableMoment);

  const { appView } = useCurrentProvider();

  if (appView === AppView.Mcp && wholeCampusCare) {
    return (
      <BookableIntakesDepletedContainer
        className={cx('tc flex flex-column items-center gap-1', className)}
      >
        <InlineSVG icon="lock" size={22} kind="grayText" />
        <Text.bodyBold>No provider availability for the selected timeframe</Text.bodyBold>
        {nextAvailableDGMProviderDate && (
          <Text.linkButton className="b mt3" onClick={() => onSelectStartDate(moveToWeek)}>
            Next bookable intake: {nextAvailableMoment.format('ddd, MMMM D')}
          </Text.linkButton>
        )}
      </BookableIntakesDepletedContainer>
    );
  }
  return (
    <BookableIntakesDepletedContainer
      className={cx('tc flex flex-column items-center gap-1', className)}
    >
      <InlineSVG icon="lock" size={22} kind="grayText" />
      <Text.bodyBold>No bookable intakes remaining</Text.bodyBold>
      <Text.bodyCaption kind="grayText" className={cx({ dn: gridSpan && gridSpan <= 2 })}>
        An intake cannot be booked for any day of the selected week(s) because your organization has
        used its maximum allotted bookable hours and/or intakes.
      </Text.bodyCaption>
      {nextAvailableDGMProviderDate && (
        <Text.linkButton className="b mt3" onClick={() => onSelectStartDate(moveToWeek)}>
          Next bookable intake: {nextAvailableMoment.format('ddd, MMMM D')}
        </Text.linkButton>
      )}
    </BookableIntakesDepletedContainer>
  );
};

// width is used in provider-network search to prevent crowded text
const BookableIntakesDepletedContainer = styled.div`
  padding: 2rem;
  background: ${colors.grayTextBg};
  position: relative;
  opacity: 1;
  width: 100%;
`;
