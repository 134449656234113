import styled from 'styled-components';

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const BodyWrapper = styled.div`
  max-width: 930px;
`;

export const AppointmentItemContainer = styled.div<{ isPast?: boolean }>`
  background: ${({ isPast }) => (isPast ? '#F9F9F9' : 'white')};
  border: 1px solid #e5e5e5;
  border-collapse: collapse;

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const AppointmentItemGridRow = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 12rem 2fr;
  grid-gap: 1rem;
  padding: 1rem 2rem;

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const Details = styled.details`
  & > summary {
    cursor: pointer;
    outline: 0;
    display: block;

    &::-webkit-details-marker {
      display: none;
    }
  }

  & > summary .icon-open {
    display: none;
  }
  & > summary .icon-close {
    display: block;
  }
  &[open] > summary .icon-open {
    display: block;
  }
  &[open] > summary .icon-close {
    display: none;
  }
`;

export const DayDetails = styled(Details)`
  border-bottom: 1px solid lightgray;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;

export const AppointmentItemGridBottomRow = styled(AppointmentItemGridRow)`
  padding-top: 0;
`;

export const PatientDetailGrid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

export const Thumbnail = styled.img`
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 50%;
`;

export const Divider = styled.hr`
  border-top: none;
  border-bottom: 1px solid #d8d8d8;
  margin: 1em 0 1.5em 0;
`;
export const DropdownContainer = styled.div`
  width: 250px;
  margin-left: 1rem;
  margin-bottom: 1rem;
`;
export const ModalAppointmentBox = styled.div`
  background: #f9f9f9;
  padding: 20px;
  display: flex;
  margin: 20px auto;
`;
export const ModalAppointmentText = styled.div`
  flex: 1;
  margin-left: 10px;
`;
