import React from 'react';
import { Modal, ROLE } from 'baseui/modal';
import styled from 'styled-components';
import { MantraModalBodyOld } from '../Modal';
import { Text, colors } from '../../globalStyles';

type BookableHoursModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ModalLink = styled(Text.linkButton)`
  width: 100%;
  color: ${colors.grey.ozGray2};
  align-items: center;
  text-align: center;
`;

const BookableModalBodyDiv = styled(MantraModalBodyOld)`
  max-width: 429px;
`;

const BookableModalH2 = styled(Text.h2)`
  padding-bottom: 10px;
`;

export function BookableHoursModal({ isOpen, onClose }: BookableHoursModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeable={false}
      role={ROLE.dialog}
      size="auto"
      overrides={{
        Dialog: {
          style: {
            borderRadius: '4px',
            padding: '1rem',
          },
        },
      }}
    >
      <BookableModalBodyDiv>
        <BookableModalH2>Student&apos;s university has limited bookable hours</BookableModalH2>
        <div className="mb4 pt1 flex-1">
          <Text.h3>Why can’t I book this student for earlier availability?</Text.h3>
          <Text.body>
            Some universities have a limited number of bookable sponsored care hours per week that
            they cannot exceed, regardless of a provider’s availability.
          </Text.body>
          <br />
          <Text.body>
            Once a university has met or exceeded this limit in a given week, provider availability
            for that week will no longer be bookable when scheduling a patient from this university.
          </Text.body>
        </div>
        <div className="mb4 pt1 flex-1">
          <Text.h3>What if I need to see this patient sooner?</Text.h3>
          <Text.body>
            We cannot guarantee that we can accommodate seeing this patient sooner, so please
            schedule the soonest time available, even if it’s further away than desired.
          </Text.body>
          <br />
          <Text.body>
            If there’s an urgent clinical need, you may submit a request for earlier accommodation
            by messaging the
            <Text.externalLink
              href="https://wellroundprov-drj6019.slack.com/archives/C032RLRL7A5"
              target="_blank"
            >
              {' '}
              #provider-admin-support channel on the Wellround Provider Group Slack
            </Text.externalLink>
            , with details about the urgent clinical need.
          </Text.body>
        </div>
        <ModalLink className="b" onClick={onClose}>
          Close
        </ModalLink>
      </BookableModalBodyDiv>
    </Modal>
  );
}
