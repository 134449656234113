import { descriptionsByDiagnosisCode } from './diagnosisCodesOld';

// used as of 2022 these are the same ones used on the api side in the diagnoses.ts file
export const dsm5DiagnosesCodes = [
  {
    Key: '0',
    Code: 'E66.9',
    Description: 'Overweight or obesity',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Additional Conditions or Problems That May Be a Focus of Clinical Attention',
  },
  {
    Key: '1',
    Code: 'F01.50',
    Description:
      'Major neurocognitive disorder possibly due to vascular disease, Without behavioral disturbance',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '2',
    Code: 'F01.50',
    Description:
      'Major neurocognitive disorder probably due to vascular disease, Without behavioral disturbance',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '3',
    Code: 'F01.51',
    Description:
      'Major neurocognitive disorder possibly due to vascular disease, With behavioral disturbance',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '4',
    Code: 'F01.51',
    Description:
      'Major neurocognitive disorder probably due to vascular disease, With behavioral disturbance',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '5',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder due to another medical condition, Without behavioral disturbance',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '6',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder due to HIV infection, Without behavioral disturbance (code first B20 HIV infection)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '7',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder due to Huntington’s disease, Without behavioral disturbance (code first G10 Huntington’s disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '8',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder due to multiple etiologies, Without behavioral disturbance',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '9',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder due to possible Alzheimer’s disease, Without behavioral disturbance (code first G30.9 Alzheimer’s disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '10',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder due to possible frontotemporal degeneration, Without behavioral disturbance (code first G31.09 frontotemporal degeneration)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '11',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder with possible Lewy bodies, Without behavioral disturbance (code first G31.83 Lewy body disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '12',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder possibly due to Parkinson’s disease, Without behavioral disturbance (code first G20 Parkinson’s disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '13',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder due to prion disease, Without behavioral disturbance (code first A81.9 prion disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '14',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder due to probable Alzheimer’s disease, Without behavioral disturbance (code first G30.9 Alzheimer’s disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '15',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder due to probable frontotemporal degeneration, Without behavioral disturbance (code first G31.09 frontotemporal degeneration)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '16',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder with probable Lewy bodies, Without behavioral disturbance (code first G31.83 Lewy body disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '17',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder probably due to Parkinson’s disease, Without behavioral disturbance (code first G20 Parkinson’s disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '18',
    Code: 'F02.80',
    Description:
      'Major neurocognitive disorder due to traumatic brain injury, Without behavioral disturbance (code first S06.2X9S diffuse traumatic brain injury with loss of consciousness of unspecified duration, sequela)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '19',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder due to another medical condition, With behavioral disturbance',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '20',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder due to HIV infection, With behavioral disturbance (code first B20 HIV infection)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '21',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder due to Huntington’s disease, With behavioral disturbance (code first G10 Huntington’s disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '22',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder due to multiple etiologies, With behavioral disturbance',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '23',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder due to possible Alzheimer’s disease, With behavioral disturbance (code first G30.9 Alzheimer’s disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '24',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder due to possible frontotemporal degeneration, With behavioral disturbance (code first G31.09 frontotemporal degeneration)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '25',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder with possible Lewy bodies, With behavioral disturbance (code first G31.83 Lewy body disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '26',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder possibly due to Parkinson’s disease, With behavioral disturbance (code first G20 Parkinson’s disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '27',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder due to prion disease, With behavioral disturbance (code first A81.9 prion disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '28',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder due to probable Alzheimer’s disease, With behavioral disturbance (code first G30.9 Alzheimer’s disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '29',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder due to probable frontotemporal degeneration, With behavioral disturbance (code first G31.09 frontotemporal degeneration)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '30',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder with probable Lewy bodies, With behavioral disturbance (code first G31.83 Lewy body disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '31',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder probably due to Parkinson’s disease, With behavioral disturbance (code first G20 Parkinson’s disease)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '32',
    Code: 'F02.81',
    Description:
      'Major neurocognitive disorder due to traumatic brain injury, With behavioral disturbance (code first S06.2X9S diffuse traumatic brain injury with loss of consciousness of unspecified duration, sequela)',
    Category: 'Neurocognitive Disorders',
    Subcategory: 'Major and Mild Neurocognitive Disorders',
  },
  {
    Key: '33',
    Code: 'F05',
    Description: 'Delirium due to another medical condition ',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '34',
    Code: 'F05',
    Description: 'Delirium due to multiple etiologies',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '35',
    Code: 'F06.0',
    Description: 'Psychotic disorder due to another medical condition, With hallucinations',
    Category: 'Schizophrenia Spectrum and Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '36',
    Code: 'F06.1',
    Description: 'Catatonia associated with another mental disorder (catatonia specifier)',
    Category: 'Schizophrenia Spectrum and Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '37',
    Code: 'F06.1',
    Description: 'Catatonic disorder due to another medical condition',
    Category: 'Schizophrenia Spectrum and Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '38',
    Code: 'F06.1',
    Description:
      'Unspecified catatonia (code first R29.818 other symptoms involving nervous and musculoskeletal systems)',
    Category: 'Schizophrenia Spectrum and Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '39',
    Code: 'F06.2',
    Description: 'Psychotic disorder due to another medical condition, With delusions',
    Category: 'Schizophrenia Spectrum and Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '40',
    Code: 'F06.31',
    Description: 'Depressive disorder due to another medical condition, With depressive features',
    Category: 'Schizophrenia Spectrum and Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '41',
    Code: 'F06.32',
    Description:
      'Depressive disorder due to another medical condition, With major depressive–like episode',
    Category: 'Schizophrenia Spectrum and Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '42',
    Code: 'F06.33',
    Description:
      'Bipolar and related disorder due to another medical condition, With manic features',
    Category: 'Schizophrenia Spectrum and Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '43',
    Code: 'F06.33',
    Description:
      'Bipolar and related disorder due to another medical condition, With manic- or hypomanic-like episode',
    Category: 'Schizophrenia Spectrum and Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '44',
    Code: 'F06.34',
    Description:
      'Bipolar and related disorder due to another medical condition, With mixed features',
    Category: 'Schizophrenia Spectrum and Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '45',
    Code: 'F06.34',
    Description: 'Depressive disorder due to another medical condition, With mixed features',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '46',
    Code: 'F06.4',
    Description: 'Anxiety disorder due to another medical condition',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '47',
    Code: 'F06.8',
    Description: 'Obsessive-compulsive and related disorder due to another medical condition',
    Category: 'Obsessive-Compulsive and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '48',
    Code: 'F06.8',
    Description: 'Other specified mental disorder due to another medical condition',
    Category: 'Other Mental Disorders and Additional Codes',
    Subcategory: null,
  },
  {
    Key: '49',
    Code: 'F07.0',
    Description: 'Personality change due to another medical condition',
    Category: 'Personality disorders ',
    Subcategory: null,
  },
  {
    Key: '50',
    Code: 'F09',
    Description: 'Unspecified mental disorder due to another medical condition',
    Category: 'Other Mental Disorders and Additional Codes',
    Subcategory: null,
  },
  {
    Key: '51',
    Code: 'F10.10',
    Description: 'Alcohol use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '52',
    Code: 'F10.11',
    Description: 'Alcohol use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '53',
    Code: 'F10.11',
    Description: 'Alcohol use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '54',
    Code: 'F10.120',
    Description: 'Alcohol intoxication, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '55',
    Code: 'F10.121',
    Description: 'Alcohol intoxication delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '56',
    Code: 'F10.130',
    Description: 'Alcohol withdrawal, Without perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '57',
    Code: 'F10.131',
    Description: 'Alcohol withdrawal delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '58',
    Code: 'F10.132',
    Description: 'Alcohol withdrawal, With perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '59',
    Code: 'F10.14',
    Description: 'Alcohol-induced bipolar and related disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '60',
    Code: 'F10.14',
    Description: 'Alcohol-induced depressive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '61',
    Code: 'F10.159',
    Description: 'Alcohol-induced psychotic disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '62',
    Code: 'F10.180',
    Description: 'Alcohol-induced anxiety disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '63',
    Code: 'F10.181',
    Description: 'Alcohol-induced sexual dysfunction, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '64',
    Code: 'F10.182',
    Description: 'Alcohol-induced sleep disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '65',
    Code: 'F10.188',
    Description: 'Alcohol-induced mild neurocognitive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '66',
    Code: 'F10.20',
    Description: 'Alcohol use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '67',
    Code: 'F10.20',
    Description: 'Alcohol use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '68',
    Code: 'F10.21',
    Description: 'Alcohol use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '69',
    Code: 'F10.21',
    Description: 'Alcohol use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '70',
    Code: 'F10.21',
    Description: 'Alcohol use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '71',
    Code: 'F10.21',
    Description: 'Alcohol use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '72',
    Code: 'F10.220',
    Description: 'Alcohol intoxication, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '73',
    Code: 'F10.221',
    Description: 'Alcohol intoxication delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '74',
    Code: 'F10.230',
    Description:
      'Alcohol withdrawal, Without perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '75',
    Code: 'F10.231',
    Description: 'Alcohol withdrawal delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '76',
    Code: 'F10.232',
    Description:
      'Alcohol withdrawal, With perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '77',
    Code: 'F10.24',
    Description:
      'Alcohol-induced bipolar and related disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '78',
    Code: 'F10.24',
    Description: 'Alcohol-induced depressive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '79',
    Code: 'F10.259',
    Description: 'Alcohol-induced psychotic disorder, With moder- ate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '80',
    Code: 'F10.26',
    Description:
      'Alcohol-induced major neurocognitive disorder, Amnestic-confabulatory type, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '81',
    Code: 'F10.27',
    Description:
      'Alcohol-induced major neurocognitive disorder, Nonamnestic-confabulatory type, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '82',
    Code: 'F10.280',
    Description: 'Alcohol-induced anxiety disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '83',
    Code: 'F10.281',
    Description: 'Alcohol-induced sexual dysfunction, With moder- ate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '84',
    Code: 'F10.282',
    Description: 'Alcohol-induced sleep disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '85',
    Code: 'F10.288',
    Description:
      'Alcohol-induced mild neurocognitive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '86',
    Code: 'F10.920',
    Description: 'Alcohol intoxication, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '87',
    Code: 'F10.921',
    Description: 'Alcohol intoxication delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '88',
    Code: 'F10.930',
    Description: 'Alcohol withdrawal, Without perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '89',
    Code: 'F10.931',
    Description: 'Alcohol withdrawal delirium, without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '90',
    Code: 'F10.932',
    Description: 'Alcohol withdrawal, With perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '91',
    Code: 'F10.94',
    Description: 'Alcohol-induced bipolar and related disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '92',
    Code: 'F10.94',
    Description: 'Alcohol-induced depressive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '93',
    Code: 'F10.959',
    Description: 'Alcohol-induced psychotic disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '94',
    Code: 'F10.96',
    Description:
      'Alcohol-induced major neurocognitive disorder, Amnestic-confabulatory type, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '95',
    Code: 'F10.97',
    Description:
      'Alcohol-induced major neurocognitive disorder, Nonamnestic-confabulatory type, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '96',
    Code: 'F10.980',
    Description: 'Alcohol-induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '97',
    Code: 'F10.981',
    Description: 'Alcohol-induced sexual dysfunction, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '98',
    Code: 'F10.982',
    Description: 'Alcohol-induced sleep disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '99',
    Code: 'F10.988',
    Description: 'Alcohol-induced mild neurocognitive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '100',
    Code: 'F10.99',
    Description: 'Unspecified alcohol-related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Alcohol-related disorders ',
  },
  {
    Key: '101',
    Code: 'F11.10',
    Description: 'Opioid use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '102',
    Code: 'F11.11',
    Description: 'Opioid use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '103',
    Code: 'F11.11',
    Description: 'Opioid use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '104',
    Code: 'F11.120',
    Description: 'Opioid intoxication, Without perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '105',
    Code: 'F11.121',
    Description: 'Opioid intoxication delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '106',
    Code: 'F11.122',
    Description: 'Opioid intoxication, With perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '107',
    Code: 'F11.13',
    Description: 'Opioid withdrawal, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '108',
    Code: 'F11.14',
    Description: 'Opioid-induced depressive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '109',
    Code: 'F11.181',
    Description: 'Opioid-induced sexual dysfunction, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '110',
    Code: 'F11.182',
    Description: 'Opioid-induced sleep disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '111',
    Code: 'F11.188',
    Description: 'Opioid-induced anxiety disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '112',
    Code: 'F11.188',
    Description: 'Opioid withdrawal delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '113',
    Code: 'F11.20',
    Description: 'Opioid use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '114',
    Code: 'F11.20',
    Description: 'Opioid use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '115',
    Code: 'F11.21',
    Description: 'Opioid use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '116',
    Code: 'F11.21',
    Description: 'Opioid use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '117',
    Code: 'F11.21',
    Description: 'Opioid use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '118',
    Code: 'F11.21',
    Description: 'Opioid use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '119',
    Code: 'F11.220',
    Description:
      'Opioid intoxication, Without perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '120',
    Code: 'F11.221',
    Description: 'Opioid intoxication delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '121',
    Code: 'F11.222',
    Description:
      'Opioid intoxication, With perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '122',
    Code: 'F11.23',
    Description: 'Opioid withdrawal, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '123',
    Code: 'F11.24',
    Description: 'Opioid-induced depressive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '124',
    Code: 'F11.281',
    Description: 'Opioid-induced sexual dysfunction, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '125',
    Code: 'F11.282',
    Description: 'Opioid-induced sleep disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '126',
    Code: 'F11.288',
    Description: 'Opioid-induced anxiety disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '127',
    Code: 'F11.288',
    Description: 'Opioid withdrawal delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '128',
    Code: 'F11.920',
    Description: 'Opioid intoxication, Without perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '129',
    Code: 'F11.921',
    Description: 'Opioid-induced delirium (opioid medication taken as prescribed)',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '130',
    Code: 'F11.921',
    Description: 'Opioid intoxication delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '131',
    Code: 'F11.922',
    Description: 'Opioid intoxication, With perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '132',
    Code: 'F11.93',
    Description: 'Opioid withdrawal, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '133',
    Code: 'F11.94',
    Description: 'Opioid-induced depressive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '134',
    Code: 'F11.981',
    Description: 'Opioid-induced sexual dysfunction, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '135',
    Code: 'F11.982',
    Description: 'Opioid-induced sleep disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '136',
    Code: 'F11.988',
    Description: 'Opioid-induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '137',
    Code: 'F11.988',
    Description:
      'Opioid-induced delirium (during withdrawal from opioid medication taken as prescribed)',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '138',
    Code: 'F11.988',
    Description: 'Opioid withdrawal delirium, Without use disorder ',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '139',
    Code: 'F11.99',
    Description: 'Unspecified opioid-related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Opioid-related disorders ',
  },
  {
    Key: '140',
    Code: 'F12.10',
    Description: 'Cannabis use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '141',
    Code: 'F12.11',
    Description: 'Cannabis use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '142',
    Code: 'F12.11',
    Description: 'Cannabis use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '143',
    Code: 'F12.120',
    Description: 'Cannabis intoxication, Without perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '144',
    Code: 'F12.121',
    Description: 'Cannabis intoxication delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '145',
    Code: 'F12.122',
    Description: 'Cannabis intoxication, With perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '146',
    Code: 'F12.13',
    Description: 'Cannabis withdrawal, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '147',
    Code: 'F12.159',
    Description: 'Cannabis-induced psychotic disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '148',
    Code: 'F12.180',
    Description: 'Cannabis-induced anxiety disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '149',
    Code: 'F12.188',
    Description: 'Cannabis-induced sleep disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '150',
    Code: 'F12.20',
    Description: 'Cannabis use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '151',
    Code: 'F12.20',
    Description: 'Cannabis use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '152',
    Code: 'F12.21',
    Description: 'Cannabis use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '153',
    Code: 'F12.21',
    Description: 'Cannabis use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '154',
    Code: 'F12.21',
    Description: 'Cannabis use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '155',
    Code: 'F12.21',
    Description: 'Cannabis use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '156',
    Code: 'F12.220',
    Description:
      'Cannabis intoxication, Without perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '157',
    Code: 'F12.221',
    Description: 'Cannabis intoxication delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '158',
    Code: 'F12.222',
    Description:
      'Cannabis intoxication, With perceptual distur- bances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '159',
    Code: 'F12.23',
    Description: 'Cannabis withdrawal, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '160',
    Code: 'F12.259',
    Description: 'Cannabis-induced psychotic disorder, With moder- ate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '161',
    Code: 'F12.280',
    Description: 'Cannabis-induced anxiety disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '162',
    Code: 'F12.288',
    Description: 'Cannabis-induced sleep disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '163',
    Code: 'F12.920',
    Description: 'Cannabis intoxication, Without perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '164',
    Code: 'F12.921',
    Description: 'Cannabis intoxication delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '165',
    Code: 'F12.921',
    Description:
      'Pharmaceutical cannabis receptor agonist–induced delirium (pharmaceutical cannabis receptor agonist medication taken as prescribed)',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '166',
    Code: 'F12.922',
    Description: 'Cannabis intoxication, With perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '167',
    Code: 'F12.93',
    Description: 'Cannabis withdrawal, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '168',
    Code: 'F12.959',
    Description: 'Cannabis-induced psychotic disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '169',
    Code: 'F12.980',
    Description: 'Cannabis-induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '170',
    Code: 'F12.988',
    Description: 'Cannabis-induced sleep disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '171',
    Code: 'F12.99',
    Description: 'Unspecified cannabis-related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cannabis-related disorders ',
  },
  {
    Key: '172',
    Code: 'F13.10',
    Description: 'Sedative, hypnotic, or anxiolytic use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '173',
    Code: 'F13.11',
    Description: 'Sedative, hypnotic, or anxiolytic use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '174',
    Code: 'F13.11',
    Description: 'Sedative, hypnotic, or anxiolytic use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '175',
    Code: 'F13.120',
    Description: 'Sedative, hypnotic, or anxiolytic intoxication, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '176',
    Code: 'F13.121',
    Description: 'Sedative, hypnotic, or anxiolytic intoxication delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '177',
    Code: 'F13.130',
    Description:
      'Sedative, hypnotic, or anxiolytic withdrawal, Without perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '178',
    Code: 'F13.131',
    Description: 'Sedative, hypnotic, or anxiolytic withdrawal delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '179',
    Code: 'F13.132',
    Description:
      'Sedative, hypnotic, or anxiolytic withdrawal, With perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '180',
    Code: 'F13.14',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced bipolar and related disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '181',
    Code: 'F13.14',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced depressive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '182',
    Code: 'F13.159',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced psychotic disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '183',
    Code: 'F13.180',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced anxiety disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '184',
    Code: 'F13.181',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced sexual dysfunction, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '185',
    Code: 'F13.182',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced sleep disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '186',
    Code: 'F13.188',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced mild neurocognitive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '187',
    Code: 'F13.20',
    Description: 'Sedative, hypnotic, or anxiolytic use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '188',
    Code: 'F13.20',
    Description: 'Sedative, hypnotic, or anxiolytic use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '189',
    Code: 'F13.21',
    Description: 'Sedative, hypnotic, or anxiolytic use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '190',
    Code: 'F13.21',
    Description: 'Sedative, hypnotic, or anxiolytic use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '191',
    Code: 'F13.21',
    Description: 'Sedative, hypnotic, or anxiolytic use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '192',
    Code: 'F13.21',
    Description: 'Sedative, hypnotic, or anxiolytic use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '193',
    Code: 'F13.220',
    Description:
      'Sedative, hypnotic, or anxiolytic intoxication, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '194',
    Code: 'F13.221',
    Description:
      'Sedative, hypnotic, or anxiolytic intoxication delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '195',
    Code: 'F13.230',
    Description:
      'Sedative, hypnotic, or anxiolytic withdrawal, Without perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '196',
    Code: 'F13.231',
    Description:
      'Sedative, hypnotic, or anxiolytic withdrawal delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '197',
    Code: 'F13.232',
    Description:
      'Sedative, hypnotic, or anxiolytic withdrawal, With perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '198',
    Code: 'F13.24',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced bipolar and related disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '199',
    Code: 'F13.24',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced depressive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '200',
    Code: 'F13.259',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced psychotic disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '201',
    Code: 'F13.27',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced major neurocognitive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '202',
    Code: 'F13.280',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced anxiety disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '203',
    Code: 'F13.281',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced sexual dysfunction, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '204',
    Code: 'F13.282',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced sleep disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '205',
    Code: 'F13.288',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced mild neurocognitive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '206',
    Code: 'F13.920',
    Description: 'Sedative, hypnotic, or anxiolytic intoxication, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '207',
    Code: 'F13.921',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced delirium (sedative, hypnotic, or anxiolytic medication taken as prescribed)',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '208',
    Code: 'F13.921',
    Description: 'Sedative, hypnotic, or anxiolytic intoxication delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '209',
    Code: 'F13.930',
    Description:
      'Sedative, hypnotic, or anxiolytic withdrawal, Without perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '210',
    Code: 'F13.931',
    Description:
      'Sedative, hypnotic, or anxiolytic–induced delirium (during withdrawal from sedative, hypnotic, or anxiolytic medication taken as prescribed)',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '211',
    Code: 'F13.931',
    Description: 'Sedative, hypnotic, or anxiolytic withdrawal delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '212',
    Code: 'F13.932',
    Description:
      'Sedative, hypnotic, or anxiolytic withdrawal, With perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '213',
    Code: 'F13.94',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced bipolar and related disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '214',
    Code: 'F13.94',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced depressive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '215',
    Code: 'F13.959',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced psychotic disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '216',
    Code: 'F13.97',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced major neurocognitive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '217',
    Code: 'F13.980',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '218',
    Code: 'F13.981',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced sexual dysfunction, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '219',
    Code: 'F13.982',
    Description: 'Sedative-, hypnotic-, or anxiolytic-induced sleep disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '220',
    Code: 'F13.988',
    Description:
      'Sedative-, hypnotic-, or anxiolytic-induced mild neurocognitive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '221',
    Code: 'F13.99',
    Description: 'Unspecified sedative-, hypnotic-, or anxiolytic- related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Sedative-, Hypnotic-, or Anxiolytic-Related Disorders',
  },
  {
    Key: '222',
    Code: 'F14.10',
    Description: 'Cocaine use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '223',
    Code: 'F14.11',
    Description: 'Cocaine use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '224',
    Code: 'F14.11',
    Description: 'Cocaine use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '225',
    Code: 'F14.120',
    Description: 'Cocaine intoxication, Without perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '226',
    Code: 'F14.121',
    Description: 'Cocaine intoxication delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '227',
    Code: 'F14.122',
    Description: 'Cocaine intoxication, With perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '228',
    Code: 'F14.13',
    Description: 'Cocaine withdrawal, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '229',
    Code: 'F14.14',
    Description: 'Cocaine-induced bipolar and related disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '230',
    Code: 'F14.14',
    Description: 'Cocaine-induced depressive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '231',
    Code: 'F14.159',
    Description: 'Cocaine-induced psychotic disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '232',
    Code: 'F14.180',
    Description: 'Cocaine-induced anxiety disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '233',
    Code: 'F14.181',
    Description: 'Cocaine-induced sexual dysfunction, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '234',
    Code: 'F14.182',
    Description: 'Cocaine-induced sleep disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '235',
    Code: 'F14.188',
    Description: 'Cocaine-induced mild neurocognitive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '236',
    Code: 'F14.188',
    Description:
      'Cocaine-induced obsessive-compulsive and related disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '237',
    Code: 'F14.20',
    Description: 'Cocaine use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '238',
    Code: 'F14.20',
    Description: 'Cocaine use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '239',
    Code: 'F14.21',
    Description: 'Cocaine use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '240',
    Code: 'F14.21',
    Description: 'Cocaine use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '241',
    Code: 'F14.21',
    Description: 'Cocaine use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '242',
    Code: 'F14.21',
    Description: 'Cocaine use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '243',
    Code: 'F14.220',
    Description:
      'Cocaine intoxication, Without perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '244',
    Code: 'F14.221',
    Description: 'Cocaine intoxication delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '245',
    Code: 'F14.222',
    Description:
      'Cocaine intoxication, With perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '246',
    Code: 'F14.23',
    Description: 'Cocaine withdrawal, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '247',
    Code: 'F14.24',
    Description:
      'Cocaine-induced bipolar and related disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '248',
    Code: 'F14.24',
    Description: 'Cocaine-induced depressive disorder, With moder- ate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '249',
    Code: 'F14.259',
    Description: 'Cocaine-induced psychotic disorder, With moder- ate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '250',
    Code: 'F14.280',
    Description: 'Cocaine-induced anxiety disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '251',
    Code: 'F14.281',
    Description: 'Cocaine-induced sexual dysfunction, With moder- ate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '252',
    Code: 'F14.282',
    Description: 'Cocaine-induced sleep disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '253',
    Code: 'F14.288',
    Description:
      'Cocaine-induced mild neurocognitive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '254',
    Code: 'F14.288',
    Description:
      'Cocaine-induced obsessive-compulsive and related disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '255',
    Code: 'F14.920',
    Description: 'Cocaine intoxication, Without perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '256',
    Code: 'F14.921',
    Description: 'Cocaine intoxication delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '257',
    Code: 'F14.922',
    Description: 'Cocaine intoxication, With perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '258',
    Code: 'F14.93',
    Description: 'Cocaine withdrawal, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '259',
    Code: 'F14.94',
    Description: 'Cocaine-induced bipolar and related disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '260',
    Code: 'F14.94',
    Description: 'Cocaine-induced depressive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '261',
    Code: 'F14.959',
    Description: 'Cocaine-induced psychotic disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '262',
    Code: 'F14.980',
    Description: 'Cocaine-induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '263',
    Code: 'F14.981',
    Description: 'Cocaine-induced sexual dysfunction, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '264',
    Code: 'F14.982',
    Description: 'Cocaine-induced sleep disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '265',
    Code: 'F14.988',
    Description: 'Cocaine-induced mild neurocognitive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '266',
    Code: 'F14.988',
    Description: 'Cocaine-induced obsessive-compulsive and related disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '267',
    Code: 'F14.99',
    Description: 'Unspecified cocaine-related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Cocaine-related disorders ',
  },
  {
    Key: '268',
    Code: 'F15.10',
    Description: 'Amphetamine-type substance use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '269',
    Code: 'F15.10',
    Description: 'Other or unspecified stimulant use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '270',
    Code: 'F15.11',
    Description: 'Amphetamine-type substance use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '271',
    Code: 'F15.11',
    Description: 'Amphetamine-type substance use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '272',
    Code: 'F15.11',
    Description: 'Other or unspecified stimulant use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '273',
    Code: 'F15.11',
    Description: 'Other or unspecified stimulant use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '274',
    Code: 'F15.120',
    Description:
      'Amphetamine-type substance intoxication, Without perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '275',
    Code: 'F15.120',
    Description:
      'Other stimulant intoxication, Without perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '276',
    Code: 'F15.121',
    Description:
      'Amphetamine-type substance (or other stimulant) intoxication delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '277',
    Code: 'F15.122',
    Description:
      'Amphetamine-type substance intoxication, With perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '278',
    Code: 'F15.122',
    Description:
      'Other stimulant intoxication, With perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '279',
    Code: 'F15.13',
    Description: 'Amphetamine-type substance withdrawal, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '280',
    Code: 'F15.13',
    Description: 'Other stimulant withdrawal, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '281',
    Code: 'F15.14',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced bipolar and related disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '282',
    Code: 'F15.14',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced depressive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '283',
    Code: 'F15.159',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced psychotic disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '284',
    Code: 'F15.180',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced anxiety disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '285',
    Code: 'F15.181',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced sexual dysfunction, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '286',
    Code: 'F15.182',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced sleep disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '287',
    Code: 'F15.188',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced mild neurocognitive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '288',
    Code: 'F15.188',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced obsessive-compulsive and related disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '289',
    Code: 'F15.20',
    Description: 'Amphetamine-type substance use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '290',
    Code: 'F15.20',
    Description: 'Amphetamine-type substance use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '291',
    Code: 'F15.20',
    Description: 'Other or unspecified stimulant use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '292',
    Code: 'F15.20',
    Description: 'Other or unspecified stimulant use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '293',
    Code: 'F15.21',
    Description: 'Amphetamine-type substance use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '294',
    Code: 'F15.21',
    Description: 'Amphetamine-type substance use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '295',
    Code: 'F15.21',
    Description: 'Amphetamine-type substance use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '296',
    Code: 'F15.21',
    Description: 'Amphetamine-type substance use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '297',
    Code: 'F15.21',
    Description: 'Other or unspecified stimulant use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '298',
    Code: 'F15.21',
    Description: 'Other or unspecified stimulant use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '299',
    Code: 'F15.21',
    Description: 'Other or unspecified stimulant use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '300',
    Code: 'F15.21',
    Description: 'Other or unspecified stimulant use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '301',
    Code: 'F15.220',
    Description:
      'Amphetamine-type substance intoxication, Without perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '302',
    Code: 'F15.220',
    Description:
      'Other stimulant intoxication, Without perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '303',
    Code: 'F15.221',
    Description:
      'Amphetamine-type substance (or other stimulant) intoxication delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '304',
    Code: 'F15.222',
    Description:
      'Amphetamine-type substance intoxication, With perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '305',
    Code: 'F15.222',
    Description:
      'Other stimulant intoxication, With perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '306',
    Code: 'F15.23',
    Description: 'Amphetamine-type substance withdrawal, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '307',
    Code: 'F15.23',
    Description: 'Other stimulant withdrawal, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '308',
    Code: 'F15.24',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced bipolar and related disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '309',
    Code: 'F15.24',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced depressive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '310',
    Code: 'F15.259',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced psychotic disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '311',
    Code: 'F15.280',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced anxiety disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '312',
    Code: 'F15.281',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced sexual dysfunction, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '313',
    Code: 'F15.282',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced sleep disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '314',
    Code: 'F15.288',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced mild neurocognitive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '315',
    Code: 'F15.288',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced obsessive-compulsive and related disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '316',
    Code: 'F15.920',
    Description:
      'Amphetamine-type substance intoxication, Without perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '317',
    Code: 'F15.920',
    Description: 'Caffeine intoxication',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '318',
    Code: 'F15.920',
    Description:
      'Other stimulant intoxication, Without perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '319',
    Code: 'F15.921',
    Description:
      'Amphetamine-type (or other stimulant) medication–induced delirium (amphetamine- type or other stimulant medication taken as prescribed)',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '320',
    Code: 'F15.921',
    Description:
      'Amphetamine-type substance (or other stimulant) intoxication delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '321',
    Code: 'F15.922',
    Description:
      'Amphetamine-type substance intoxication, With perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '322',
    Code: 'F15.922',
    Description: 'Other stimulant intoxication, With perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '323',
    Code: 'F15.93',
    Description: 'Amphetamine-type substance withdrawal, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '324',
    Code: 'F15.93',
    Description: 'Caffeine withdrawal',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '325',
    Code: 'F15.93',
    Description: 'Other stimulant withdrawal, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '326',
    Code: 'F15.94',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced bipolar and related disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '327',
    Code: 'F15.94',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced depressive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '328',
    Code: 'F15.959',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced psychotic disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '329',
    Code: 'F15.980',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '330',
    Code: 'F15.980',
    Description: 'Caffeine-induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '331',
    Code: 'F15.981',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced sexual dysfunction, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '332',
    Code: 'F15.982',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced sleep disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '333',
    Code: 'F15.982',
    Description: 'Caffeine-induced sleep disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '334',
    Code: 'F15.988',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced mild neurocognitive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '335',
    Code: 'F15.988',
    Description:
      'Amphetamine-type substance (or other stimulant)– induced obsessive-compulsive and related disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '336',
    Code: 'F15.99',
    Description: 'Unspecified amphetamine-type substance-related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '337',
    Code: 'F15.99',
    Description: 'Unspecified caffeine-related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '338',
    Code: 'F15.99',
    Description: 'Unspecified other stimulant–related disorder ',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Stimulant-related disorders ',
  },
  {
    Key: '339',
    Code: 'F16.10',
    Description: 'Other hallucinogen use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '340',
    Code: 'F16.10',
    Description: 'Phencyclidine use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '341',
    Code: 'F16.11',
    Description: 'Other hallucinogen use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '342',
    Code: 'F16.11',
    Description: 'Other hallucinogen use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '343',
    Code: 'F16.11',
    Description: 'Phencyclidine use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '344',
    Code: 'F16.11',
    Description: 'Phencyclidine use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '345',
    Code: 'F16.120',
    Description: 'Other hallucinogen intoxication, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '346',
    Code: 'F16.120',
    Description: 'Phencyclidine intoxication, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '347',
    Code: 'F16.121',
    Description: 'Other hallucinogen intoxication delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '348',
    Code: 'F16.121',
    Description: 'Phencyclidine intoxication delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '349',
    Code: 'F16.14',
    Description: 'Other hallucinogen–induced bipolar and related disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '350',
    Code: 'F16.14',
    Description: 'Other hallucinogen–induced depressive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '351',
    Code: 'F16.14',
    Description: 'Phencyclidine-induced bipolar and related disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '352',
    Code: 'F16.14',
    Description: 'Phencyclidine-induced depressive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '353',
    Code: 'F16.159',
    Description: 'Other hallucinogen–induced psychotic disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '354',
    Code: 'F16.159',
    Description: 'Phencyclidine-induced psychotic disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '355',
    Code: 'F16.180',
    Description: 'Other hallucinogen–induced anxiety disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '356',
    Code: 'F16.180',
    Description: 'Phencyclidine-induced anxiety disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '357',
    Code: 'F16.20',
    Description: 'Other hallucinogen use disorder, Moderate  ',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '358',
    Code: 'F16.20',
    Description: 'Other hallucinogen use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '359',
    Code: 'F16.20',
    Description: 'Phencyclidine use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '360',
    Code: 'F16.20',
    Description: 'Phencyclidine use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '361',
    Code: 'F16.21',
    Description: 'Other hallucinogen use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '362',
    Code: 'F16.21',
    Description: 'Other hallucinogen use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '363',
    Code: 'F16.21',
    Description: 'Other hallucinogen use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '364',
    Code: 'F16.21',
    Description: 'Other hallucinogen use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '365',
    Code: 'F16.21',
    Description: 'Phencyclidine use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '366',
    Code: 'F16.21',
    Description: 'Phencyclidine use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '367',
    Code: 'F16.21',
    Description: 'Phencyclidine use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '368',
    Code: 'F16.21',
    Description: 'Phencyclidine use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '369',
    Code: 'F16.220',
    Description: 'Other hallucinogen intoxication, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '370',
    Code: 'F16.220',
    Description: 'Phencyclidine intoxication, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '371',
    Code: 'F16.221',
    Description: 'Other hallucinogen intoxication delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '372',
    Code: 'F16.221',
    Description: 'Phencyclidine intoxication delirium, With moder- ate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '373',
    Code: 'F16.24',
    Description:
      'Other hallucinogen–induced bipolar and related disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '374',
    Code: 'F16.24',
    Description:
      'Other hallucinogen–induced depressive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '375',
    Code: 'F16.24',
    Description:
      'Phencyclidine-induced bipolar and related disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '376',
    Code: 'F16.24',
    Description: 'Phencyclidine-induced depressive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '377',
    Code: 'F16.259',
    Description:
      'Other hallucinogen–induced psychotic disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '378',
    Code: 'F16.259',
    Description: 'Phencyclidine-induced psychotic disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '379',
    Code: 'F16.280',
    Description:
      'Other hallucinogen–induced anxiety disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '380',
    Code: 'F16.280',
    Description: 'Phencyclidine-induced anxiety disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '381',
    Code: 'F16.920',
    Description: 'Other hallucinogen intoxication, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '382',
    Code: 'F16.920',
    Description: 'Phencyclidine intoxication, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '383',
    Code: 'F16.921',
    Description:
      'Ketamine or other hallucinogen–induced delirium (ketamine or other hallucinogen medication taken as prescribed or for medical reasons)',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '384',
    Code: 'F16.921',
    Description: 'Other hallucinogen intoxication delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '385',
    Code: 'F16.921',
    Description: 'Phencyclidine intoxication delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '386',
    Code: 'F16.94',
    Description: 'Other hallucinogen–induced bipolar and related disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '387',
    Code: 'F16.94',
    Description: 'Other hallucinogen–induced depressive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '388',
    Code: 'F16.94',
    Description: 'Phencyclidine-induced bipolar and related disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '389',
    Code: 'F16.94',
    Description: 'Phencyclidine-induced depressive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '390',
    Code: 'F16.959',
    Description: 'Other hallucinogen–induced psychotic disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '391',
    Code: 'F16.959',
    Description: 'Phencyclidine-induced psychotic disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '392',
    Code: 'F16.980',
    Description: 'Other hallucinogen–induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '393',
    Code: 'F16.980',
    Description: 'Phencyclidine-induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '394',
    Code: 'F16.983',
    Description: 'Hallucinogen persisting perception disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '395',
    Code: 'F16.99',
    Description: 'Unspecified hallucinogen-related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '396',
    Code: 'F16.99',
    Description: 'Unspecified phencyclidine-related disorder ',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Hallucinogen-related disorders ',
  },
  {
    Key: '397',
    Code: 'F17.200',
    Description: 'Tobacco use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Tobacco-related disorders ',
  },
  {
    Key: '398',
    Code: 'F17.200',
    Description: 'Tobacco use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Tobacco-related disorders ',
  },
  {
    Key: '399',
    Code: 'F17.201',
    Description: 'Tobacco use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Tobacco-related disorders ',
  },
  {
    Key: '400',
    Code: 'F17.201',
    Description: 'Tobacco use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Tobacco-related disorders ',
  },
  {
    Key: '401',
    Code: 'F17.201',
    Description: 'Tobacco use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Tobacco-related disorders ',
  },
  {
    Key: '402',
    Code: 'F17.201',
    Description: 'Tobacco use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Tobacco-related disorders ',
  },
  {
    Key: '403',
    Code: 'F17.203',
    Description: 'Tobacco withdrawal',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Tobacco-related disorders ',
  },
  {
    Key: '404',
    Code: 'F17.208',
    Description: 'Tobacco-induced sleep disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Tobacco-related disorders ',
  },
  {
    Key: '405',
    Code: 'F17.209',
    Description: 'Unspecified tobacco-related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Tobacco-related disorders ',
  },
  {
    Key: '406',
    Code: 'F18.10',
    Description: 'Inhalant use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '407',
    Code: 'F18.11',
    Description: 'Inhalant use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '408',
    Code: 'F18.11',
    Description: 'Inhalant use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '409',
    Code: 'F18.120',
    Description: 'Inhalant intoxication, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '410',
    Code: 'F18.121',
    Description: 'Inhalant intoxication delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '411',
    Code: 'F18.14',
    Description: 'Inhalant-induced depressive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '412',
    Code: 'F18.159',
    Description: 'Inhalant-induced psychotic disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '413',
    Code: 'F18.17',
    Description: 'Inhalant-induced major neurocognitive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '414',
    Code: 'F18.180',
    Description: 'Inhalant-induced anxiety disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '415',
    Code: 'F18.188',
    Description: 'Inhalant-induced mild neurocognitive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '416',
    Code: 'F18.20',
    Description: 'Inhalant use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '417',
    Code: 'F18.20',
    Description: 'Inhalant use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '418',
    Code: 'F18.21',
    Description: 'Inhalant use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '419',
    Code: 'F18.21',
    Description: 'Inhalant use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '420',
    Code: 'F18.21',
    Description: 'Inhalant use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '421',
    Code: 'F18.21',
    Description: 'Inhalant use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '422',
    Code: 'F18.220',
    Description: 'Inhalant intoxication, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '423',
    Code: 'F18.221',
    Description: 'Inhalant intoxication delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '424',
    Code: 'F18.24',
    Description: 'Inhalant-induced depressive disorder, With moder- ate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '425',
    Code: 'F18.259',
    Description: 'Inhalant-induced psychotic disorder, With moder- ate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '426',
    Code: 'F18.27',
    Description:
      'Inhalant-induced major neurocognitive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '427',
    Code: 'F18.280',
    Description: 'Inhalant-induced anxiety disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '428',
    Code: 'F18.288',
    Description:
      'Inhalant-induced mild neurocognitive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '429',
    Code: 'F18.920',
    Description: 'Inhalant intoxication, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '430',
    Code: 'F18.921',
    Description: 'Inhalant intoxication delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '431',
    Code: 'F18.94',
    Description: 'Inhalant-induced depressive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '432',
    Code: 'F18.959',
    Description: 'Inhalant-induced psychotic disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '433',
    Code: 'F18.97',
    Description: 'Inhalant-induced major neurocognitive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '434',
    Code: 'F18.980',
    Description: 'Inhalant-induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '435',
    Code: 'F18.988',
    Description: 'Inhalant-induced mild neurocognitive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '436',
    Code: 'F18.99',
    Description: 'Unspecified inhalant-related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Inhalant-related disorders ',
  },
  {
    Key: '437',
    Code: 'F19.10',
    Description: 'Other (or unknown) substance use disorder, Mild',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '438',
    Code: 'F19.11',
    Description: 'Other (or unknown) substance use disorder, Mild, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '439',
    Code: 'F19.11',
    Description: 'Other (or unknown) substance use disorder, Mild, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '440',
    Code: 'F19.120',
    Description:
      'Other (or unknown) substance intoxication, Without perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '441',
    Code: 'F19.121',
    Description: 'Other (or unknown) substance intoxication delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '442',
    Code: 'F19.122',
    Description:
      'Other (or unknown) substance intoxication, With perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '443',
    Code: 'F19.130',
    Description:
      'Other (or unknown) substance withdrawal, Without perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '444',
    Code: 'F19.131',
    Description: 'Other (or unknown) substance withdrawal delirium, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '445',
    Code: 'F19.132',
    Description:
      'Other (or unknown) substance withdrawal, With perceptual disturbances, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '446',
    Code: 'F19.14',
    Description:
      'Other (or unknown) substance–induced bipolar and related disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '447',
    Code: 'F19.14',
    Description: 'Other (or unknown) substance–induced depressive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '448',
    Code: 'F19.159',
    Description: 'Other (or unknown) substance–induced psychotic disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '449',
    Code: 'F19.17',
    Description:
      'Other (or unknown) substance–induced major neurocognitive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '450',
    Code: 'F19.180',
    Description: 'Other (or unknown) substance–induced anxiety disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '451',
    Code: 'F19.181',
    Description: 'Other (or unknown) substance–induced sexual dysfunction, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '452',
    Code: 'F19.182',
    Description: 'Other (or unknown) substance–induced sleep disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '453',
    Code: 'F19.188',
    Description:
      'Other (or unknown) substance–induced mild neurocognitive disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '454',
    Code: 'F19.188',
    Description:
      'Other (or unknown) substance–induced obsessive- compulsive and related disorder, With mild use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '455',
    Code: 'F19.20',
    Description: 'Other (or unknown) substance use disorder, Moderate',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '456',
    Code: 'F19.20',
    Description: 'Other (or unknown) substance use disorder, Severe',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '457',
    Code: 'F19.21',
    Description: 'Other (or unknown) substance use disorder, Moderate, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '458',
    Code: 'F19.21',
    Description: 'Other (or unknown) substance use disorder, Moderate, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '459',
    Code: 'F19.21',
    Description: 'Other (or unknown) substance use disorder, Severe, In early remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '460',
    Code: 'F19.21',
    Description: 'Other (or unknown) substance use disorder, Severe, In sustained remission',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '461',
    Code: 'F19.220',
    Description:
      'Other (or unknown) substance intoxication, Without perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '462',
    Code: 'F19.221',
    Description:
      'Other (or unknown) substance intoxication delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '463',
    Code: 'F19.222',
    Description:
      'Other (or unknown) substance intoxication, With perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '464',
    Code: 'F19.230',
    Description:
      'Other (or unknown) substance withdrawal, Without perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '465',
    Code: 'F19.231',
    Description:
      'Other (or unknown) substance withdrawal delirium, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '466',
    Code: 'F19.232',
    Description:
      'Other (or unknown) substance withdrawal, With perceptual disturbances, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '467',
    Code: 'F19.24',
    Description:
      'Other (or unknown) substance–induced bipolar and related disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '468',
    Code: 'F19.24',
    Description:
      'Other (or unknown) substance–induced depressive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '469',
    Code: 'F19.259',
    Description:
      'Other (or unknown) substance–induced psychotic disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '470',
    Code: 'F19.27',
    Description:
      'Other (or unknown) substance–induced major neurocognitive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '471',
    Code: 'F19.280',
    Description:
      'Other (or unknown) substance–induced anxiety disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '472',
    Code: 'F19.281',
    Description:
      'Other (or unknown) substance–induced sexual dysfunction, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '473',
    Code: 'F19.282',
    Description:
      'Other (or unknown) substance–induced sleep disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '474',
    Code: 'F19.288',
    Description:
      'Other (or unknown) substance–induced mild neurocognitive disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '475',
    Code: 'F19.288',
    Description:
      'Other (or unknown) substance–induced obsessive- compulsive and related disorder, With moderate or severe use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '476',
    Code: 'F19.920',
    Description:
      'Other (or unknown) substance intoxication, Without perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '477',
    Code: 'F19.921',
    Description:
      'Other (or unknown) medication–induced delirium (other [or unknown] medication taken as prescribed)',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '478',
    Code: 'F19.921',
    Description: 'Other (or unknown) substance intoxication delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '479',
    Code: 'F19.922',
    Description:
      'Other (or unknown) substance intoxication, With perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '480',
    Code: 'F19.930',
    Description:
      'Other (or unknown) substance withdrawal, Without perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '481',
    Code: 'F19.931',
    Description:
      'Other (or unknown) medication–induced delirium (during withdrawal from other [or unknown] medication taken as prescribed)',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '482',
    Code: 'F19.931',
    Description: 'Other (or unknown) substance withdrawal delirium, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '483',
    Code: 'F19.932',
    Description:
      'Other (or unknown) substance withdrawal, With perceptual disturbances, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '484',
    Code: 'F19.94',
    Description:
      'Other (or unknown) substance–induced bipolar and related disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '485',
    Code: 'F19.94',
    Description: 'Other (or unknown) substance–induced depressive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '486',
    Code: 'F19.959',
    Description: 'Other (or unknown) substance–induced psychotic disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '487',
    Code: 'F19.97',
    Description:
      'Other (or unknown) substance–induced major neurocognitive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '488',
    Code: 'F19.980',
    Description: 'Other (or unknown) substance–induced anxiety disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '489',
    Code: 'F19.981',
    Description: 'Other (or unknown) substance–induced sexual dysfunction, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '490',
    Code: 'F19.982',
    Description: 'Other (or unknown) substance–induced sleep disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '491',
    Code: 'F19.988',
    Description:
      'Other (or unknown) substance–induced mild neurocognitive disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '492',
    Code: 'F19.988',
    Description:
      'Other (or unknown) substance–induced obsessive- compulsive and related disorder, Without use disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '493',
    Code: 'F19.99',
    Description: 'Unspecified other (or unknown) substance–related disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Substance-related disorders ',
  },
  {
    Key: '494',
    Code: 'F20.81',
    Description: 'Schizophreniform disorder',
    Category: 'Schizophrenia Spectrum\nand Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '495',
    Code: 'F20.9',
    Description: 'Schizophrenia',
    Category: 'Schizophrenia Spectrum\nand Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '496',
    Code: 'F21',
    Description: 'Schizotypal personality disorder',
    Category: 'Schizophrenia Spectrum\nand Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '497',
    Code: 'F22',
    Description: 'Delusional disorder',
    Category: 'Schizophrenia Spectrum\nand Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '498',
    Code: 'F23',
    Description: 'Brief psychotic disorder',
    Category: 'Schizophrenia Spectrum\nand Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '499',
    Code: 'F25.0',
    Description: 'Schizoaffective disorder, Bipolar type',
    Category: 'Schizophrenia Spectrum\nand Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '500',
    Code: 'F25.1',
    Description: 'Schizoaffective disorder, Depressive type',
    Category: 'Schizophrenia Spectrum\nand Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '501',
    Code: 'F28',
    Description: 'Other specified schizophrenia spectrum and other psychotic disorder',
    Category: 'Schizophrenia Spectrum\nand Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '502',
    Code: 'F29',
    Description: 'Unspecified schizophrenia spectrum and other psychotic disorder',
    Category: 'Schizophrenia Spectrum\nand Other Psychotic Disorders',
    Subcategory: null,
  },
  {
    Key: '503',
    Code: 'F31.0',
    Description: 'Bipolar I disorder, Current or most recent episode hypomanic',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '504',
    Code: 'F31.11',
    Description: 'Bipolar I disorder, Current or most recent episode manic, Mild',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '505',
    Code: 'F31.12',
    Description: 'Bipolar I disorder, Current or most recent episode manic, Moderate',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '506',
    Code: 'F31.13',
    Description: 'Bipolar I disorder, Current or most recent episode manic, Severe',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '507',
    Code: 'F31.2',
    Description:
      'Bipolar I disorder, Current or most recent episode manic, With psychotic features',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '508',
    Code: 'F31.31',
    Description: 'Bipolar I disorder, Current or most recent episode depressed, Mild',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '509',
    Code: 'F31.32',
    Description: 'Bipolar I disorder, Current or most recent episode depressed, Moderate',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '510',
    Code: 'F31.4',
    Description: 'Bipolar I disorder, Current or most recent episode depressed, Severe',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '511',
    Code: 'F31.5',
    Description:
      'Bipolar I disorder, Current or most recent episode depressed, With psychotic features',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '512',
    Code: 'F31.71',
    Description:
      'Bipolar I disorder, Current or most recent episode hypomanic, In partial remission',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '513',
    Code: 'F31.72',
    Description: 'Bipolar I disorder, Current or most recent episode hypomanic, In full remission',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '514',
    Code: 'F31.73',
    Description: 'Bipolar I disorder, Current or most recent episode manic, In partial remission',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '515',
    Code: 'F31.74',
    Description: 'Bipolar I disorder, Current or most recent episode manic, In full remission',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '516',
    Code: 'F31.75',
    Description:
      'Bipolar I disorder, Current or most recent episode depressed, In partial remission',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '517',
    Code: 'F31.76',
    Description: 'Bipolar I disorder, Current or most recent episode depressed, In full remission',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '518',
    Code: 'F31.81',
    Description: 'Bipolar II disorder',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '519',
    Code: 'F31.89',
    Description: 'Other specified bipolar and related disorder',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '520',
    Code: 'F31.9',
    Description: 'Bipolar I disorder, Current or most recent episode depressed, Unspecified',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '521',
    Code: 'F31.9',
    Description: 'Bipolar I disorder, Current or most recent episode hypomanic, Unspecified',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '522',
    Code: 'F31.9',
    Description: 'Bipolar I disorder, Current or most recent episode manic, Unspecified',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '523',
    Code: 'F31.9',
    Description: 'Bipolar I disorder, Current or most recent episode unspecified',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '524',
    Code: 'F31.9',
    Description: 'Unspecified bipolar and related disorder',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '525',
    Code: 'F32.0',
    Description: 'Major depressive disorder, Single episode, Mild',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '526',
    Code: 'F32.1',
    Description: 'Major depressive disorder, Single episode, Moderate',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '527',
    Code: 'F32.2',
    Description: 'Major depressive disorder, Single episode, Severe',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '528',
    Code: 'F32.3',
    Description: 'Major depressive disorder, Single episode, With psychotic features',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '529',
    Code: 'F32.4',
    Description: 'Major depressive disorder, Single episode, In partial remission',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '530',
    Code: 'F32.5',
    Description: 'Major depressive disorder, Single episode, In full remission',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '531',
    Code: 'F32.81',
    Description: 'Premenstrual dysphoric disorder',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '532',
    Code: 'F32.89',
    Description: 'Other specified depressive disorder',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '533',
    Code: 'F32.9',
    Description: 'Major depressive disorder, Single episode, Unspecified',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '534',
    Code: 'F32.A',
    Description: 'Unspecified depressive disorder',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '535',
    Code: 'F33.0',
    Description: 'Major depressive disorder, Recurrent episode, Mild',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '536',
    Code: 'F33.1',
    Description: 'Major depressive disorder, Recurrent episode, Moderate',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '537',
    Code: 'F33.2',
    Description: 'Major depressive disorder, Recurrent episode, Severe',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '538',
    Code: 'F33.3',
    Description: 'Major depressive disorder, Recurrent episode, With psychotic features',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '539',
    Code: 'F33.41',
    Description: 'Major depressive disorder, Recurrent episode, In partial remission',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '540',
    Code: 'F33.42',
    Description: 'Major depressive disorder, Recurrent episode, In full remission',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '541',
    Code: 'F33.9',
    Description: 'Major depressive disorder, Recurrent episode, Unspecified',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '542',
    Code: 'F34.0',
    Description: 'Cyclothymic disorder',
    Category: 'Bipolar and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '543',
    Code: 'F34.1',
    Description: 'Persistent depressive disorder',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '544',
    Code: 'F34.81',
    Description: 'Disruptive mood dysregulation disorder ',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '545',
    Code: 'F39',
    Description: 'Unspecified mood disorder',
    Category: 'Depressive Disorders',
    Subcategory: null,
  },
  {
    Key: '546',
    Code: 'F40.00',
    Description: 'Agoraphobia',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '547',
    Code: 'F40.10',
    Description: 'Social anxiety disorder',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '548',
    Code: 'F40.218',
    Description: 'Specific phobia, Animal',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '549',
    Code: 'F40.228',
    Description: 'Specific phobia, Natural environment',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '550',
    Code: 'F40.230',
    Description: 'Specific phobia, Fear of blood',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '551',
    Code: 'F40.231',
    Description: 'Specific phobia, Fear of injections and transfusions',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '552',
    Code: 'F40.232',
    Description: 'Specific phobia, Fear of other medical care',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '553',
    Code: 'F40.233',
    Description: 'Specific phobia, Fear of injury',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '554',
    Code: 'F40.248',
    Description: 'Specific phobia, Situational ',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '555',
    Code: 'F40.298',
    Description: 'Specific phobia, Other',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '556',
    Code: 'F41.0',
    Description: 'Panic disorder',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '557',
    Code: 'F41.1',
    Description: 'Generalized anxiety disorder',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '558',
    Code: 'F41.8',
    Description: 'Other specified anxiety disorder',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '559',
    Code: 'F41.9',
    Description: 'Unspecified anxiety disorder',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '560',
    Code: 'F42.2',
    Description: 'Obsessive-compulsive disorder',
    Category: 'Obsessive-Compulsive and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '561',
    Code: 'F42.3',
    Description: 'Hoarding disorder',
    Category: 'Obsessive-Compulsive and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '562',
    Code: 'F42.4',
    Description: 'Excoriation (skin-picking) disorder',
    Category: 'Obsessive-Compulsive and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '563',
    Code: 'F42.8',
    Description: 'Other specified obsessive-compulsive and related disorder',
    Category: 'Obsessive-Compulsive and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '564',
    Code: 'F42.9',
    Description: 'Unspecified obsessive-compulsive and related disorder',
    Category: 'Obsessive-Compulsive and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '565',
    Code: 'F43.0',
    Description: 'Acute stress disorder',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '566',
    Code: 'F43.10',
    Description: 'Posttraumatic stress disorder',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '567',
    Code: 'F43.20',
    Description: 'Adjustment disorders, Unspecified',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '568',
    Code: 'F43.21',
    Description: 'Adjustment disorders, With depressed mood',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '569',
    Code: 'F43.22',
    Description: 'Adjustment disorders, With anxiety',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '570',
    Code: 'F43.23',
    Description: 'Adjustment disorders, With mixed anxiety and depressed mood',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '571',
    Code: 'F43.24',
    Description: 'Adjustment disorders, With disturbance of conduct',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '572',
    Code: 'F43.25',
    Description: 'Adjustment disorders, With mixed disturbance of emotions and conduct',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '573',
    Code: 'F43.8',
    Description: 'Other specified trauma- and stressor-related disorder',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '574',
    Code: 'F43.8',
    Description: 'Prolonged grief disorder',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '575',
    Code: 'F43.9',
    Description: 'Unspecified trauma- and stressor-related disorder',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '576',
    Code: 'F44.0',
    Description: 'Dissociative amnesia',
    Category: 'Dissociative Disorders',
    Subcategory: null,
  },
  {
    Key: '577',
    Code: 'F44.1',
    Description: 'Dissociative amnesia, With dissociative fugue',
    Category: 'Dissociative Disorders',
    Subcategory: null,
  },
  {
    Key: '578',
    Code: 'F44.4',
    Description:
      'Functional neurological symptom disorder (conversion disorder), With abnormal movement',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '579',
    Code: 'F44.4',
    Description:
      'Functional neurological symptom disorder (conversion disorder), With speech symptom',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '580',
    Code: 'F44.4',
    Description:
      'Functional neurological symptom disorder (conversion disorder), With swallowing symptoms',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '581',
    Code: 'F44.4',
    Description:
      'Functional neurological symptom disorder (conversion disorder), With weakness/paralysis',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '582',
    Code: 'F44.5',
    Description:
      'Functional neurological symptom disorder (conversion disorder), With attacks or seizures',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '583',
    Code: 'F44.6',
    Description:
      'Functional neurological symptom disorder (conversion disorder), With anesthesia or sensory loss',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '584',
    Code: 'F44.6',
    Description:
      'Functional neurological symptom disorder (conversion disorder), With special sensory symptom',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '585',
    Code: 'F44.7',
    Description:
      'Functional neurological symptom disorder (conversion disorder), With mixed symptoms',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '586',
    Code: 'F44.81',
    Description: 'Dissociative identity disorder',
    Category: 'Dissociative Disorders',
    Subcategory: null,
  },
  {
    Key: '587',
    Code: 'F44.89',
    Description: 'Other specified dissociative disorder  ',
    Category: 'Dissociative Disorders',
    Subcategory: null,
  },
  {
    Key: '588',
    Code: 'F44.9',
    Description: 'Unspecified dissociative disorder',
    Category: 'Dissociative Disorders',
    Subcategory: null,
  },
  {
    Key: '589',
    Code: 'F45.1',
    Description: 'Somatic symptom disorder',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '590',
    Code: 'F45.21',
    Description: 'Illness anxiety disorder',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '591',
    Code: 'F45.22',
    Description: 'Body dysmorphic disorder',
    Category: 'Obsessive-Compulsive and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '592',
    Code: 'F45.8',
    Description: 'Other specified somatic symptom and related disorder',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '593',
    Code: 'F45.9',
    Description: 'Unspecified somatic symptom and related disorder',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '594',
    Code: 'F48.1',
    Description: 'Depersonalization/derealization disorder',
    Category: 'Dissociative Disorders',
    Subcategory: null,
  },
  {
    Key: '595',
    Code: 'F50.01',
    Description: 'Anorexia nervosa, Restricting type',
    Category: 'Feeding and Eating Disorders',
    Subcategory: null,
  },
  {
    Key: '596',
    Code: 'F50.02',
    Description: 'Anorexia nervosa, Binge-eating/purging type',
    Category: 'Feeding and Eating Disorders',
    Subcategory: null,
  },
  {
    Key: '597',
    Code: 'F50.2',
    Description: 'Bulimia nervosa',
    Category: 'Feeding and Eating Disorders',
    Subcategory: null,
  },
  {
    Key: '598',
    Code: 'F50.81',
    Description: 'Binge-eating disorder',
    Category: 'Feeding and Eating Disorders',
    Subcategory: null,
  },
  {
    Key: '599',
    Code: 'F50.82',
    Description: 'Avoidant/restrictive food intake disorder',
    Category: 'Feeding and Eating Disorders',
    Subcategory: null,
  },
  {
    Key: '600',
    Code: 'F50.89',
    Description: 'Other specified feeding or eating disorder ',
    Category: 'Feeding and Eating Disorders',
    Subcategory: null,
  },
  {
    Key: '601',
    Code: 'F50.89',
    Description: 'Pica, in adults',
    Category: 'Feeding and Eating Disorders',
    Subcategory: null,
  },
  {
    Key: '602',
    Code: 'F50.9',
    Description: 'Unspecified feeding or eating disorder ',
    Category: 'Feeding and Eating Disorders',
    Subcategory: null,
  },
  {
    Key: '603',
    Code: 'F51.01',
    Description: 'Insomnia disorder',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '604',
    Code: 'F51.11',
    Description: 'Hypersomnolence disorder',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '605',
    Code: 'F51.3',
    Description: 'Non–rapid eye movement sleep arousal disorders, Sleepwalking type',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '606',
    Code: 'F51.4',
    Description: 'Non–rapid eye movement sleep arousal disorders, Sleep terror type',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '607',
    Code: 'F51.5',
    Description: 'Nightmare disorder',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '608',
    Code: 'F52.0',
    Description: 'Male hypoactive sexual desire disorder',
    Category: 'Sexual Dysfunctions',
    Subcategory: null,
  },
  {
    Key: '609',
    Code: 'F52.21',
    Description: 'Erectile disorder',
    Category: 'Sexual Dysfunctions',
    Subcategory: null,
  },
  {
    Key: '610',
    Code: 'F52.22',
    Description: 'Female sexual interest/arousal disorder',
    Category: 'Sexual Dysfunctions',
    Subcategory: null,
  },
  {
    Key: '611',
    Code: 'F52.31',
    Description: 'Female orgasmic disorder',
    Category: 'Sexual Dysfunctions',
    Subcategory: null,
  },
  {
    Key: '612',
    Code: 'F52.32',
    Description: 'Delayed ejaculation',
    Category: 'Sexual Dysfunctions',
    Subcategory: null,
  },
  {
    Key: '613',
    Code: 'F52.4',
    Description: 'Premature (early) ejaculation',
    Category: 'Sexual Dysfunctions',
    Subcategory: null,
  },
  {
    Key: '614',
    Code: 'F52.6',
    Description: 'Genito-pelvic pain/penetration disorder',
    Category: 'Sexual Dysfunctions',
    Subcategory: null,
  },
  {
    Key: '615',
    Code: 'F52.8',
    Description: 'Other specified sexual dysfunction',
    Category: 'Sexual Dysfunctions',
    Subcategory: null,
  },
  {
    Key: '616',
    Code: 'F52.9',
    Description: 'Unspecified sexual dysfunction',
    Category: 'Sexual Dysfunctions',
    Subcategory: null,
  },
  {
    Key: '617',
    Code: 'F54',
    Description: 'Psychological factors affecting other medical conditions',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '618',
    Code: 'F60.0',
    Description: 'Paranoid personality disorder',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '619',
    Code: 'F60.1',
    Description: 'Schizoid personality disorder',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '620',
    Code: 'F60.2',
    Description: 'Antisocial personality disorder',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '621',
    Code: 'F60.3',
    Description: 'Borderline personality disorder',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '622',
    Code: 'F60.4',
    Description: 'Histrionic personality disorder',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '623',
    Code: 'F60.5',
    Description: 'Obsessive-compulsive personality disorder',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '624',
    Code: 'F60.6',
    Description: 'Avoidant personality disorder',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '625',
    Code: 'F60.7',
    Description: 'Dependent personality disorder',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '626',
    Code: 'F60.81',
    Description: 'Narcissistic personality disorder  ',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '627',
    Code: 'F60.89',
    Description: 'Other specified personality disorder',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '628',
    Code: 'F60.9',
    Description: 'Unspecified personality disorder ',
    Category: 'Personality Disorders',
    Subcategory: null,
  },
  {
    Key: '629',
    Code: 'F63.0',
    Description: 'Gambling disorder',
    Category: 'Substance-Related and Addictive Disorders',
    Subcategory: 'Non-Substance-Related Disorders',
  },
  {
    Key: '630',
    Code: 'F63.1',
    Description: 'Pyromania',
    Category: 'Disruptive, Impulse-Control, and Conduct Disorders',
    Subcategory: null,
  },
  {
    Key: '631',
    Code: 'F63.2',
    Description: 'Kleptomania',
    Category: 'Disruptive, Impulse-Control, and Conduct Disorders',
    Subcategory: null,
  },
  {
    Key: '632',
    Code: 'F63.3',
    Description: 'Trichotillomania (hair-pulling disorder)',
    Category: 'Obsessive-Compulsive and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '633',
    Code: 'F63.81',
    Description: 'Intermittent explosive disorder',
    Category: 'Disruptive, Impulse-Control, and Conduct Disorders',
    Subcategory: null,
  },
  {
    Key: '634',
    Code: 'F64.0',
    Description: 'Gender dysphoria in adolescents and adults ',
    Category: 'Gender Dysphoria',
    Subcategory: null,
  },
  {
    Key: '635',
    Code: 'F64.2',
    Description: 'Gender dysphoria in children',
    Category: 'Gender Dysphoria',
    Subcategory: null,
  },
  {
    Key: '636',
    Code: 'F64.8',
    Description: 'Other specified gender dysphoria',
    Category: 'Gender Dysphoria',
    Subcategory: null,
  },
  {
    Key: '637',
    Code: 'F64.9',
    Description: 'Unspecified gender dysphoria',
    Category: 'Gender Dysphoria',
    Subcategory: null,
  },
  {
    Key: '638',
    Code: 'F65.0',
    Description: 'Fetishistic disorder',
    Category: 'Paraphilic Disorder',
    Subcategory: null,
  },
  {
    Key: '639',
    Code: 'F65.1',
    Description: 'Transvestic disorder',
    Category: 'Paraphilic Disorder',
    Subcategory: null,
  },
  {
    Key: '640',
    Code: 'F65.2',
    Description: 'Exhibitionistic disorder',
    Category: 'Paraphilic Disorder',
    Subcategory: null,
  },
  {
    Key: '641',
    Code: 'F65.3',
    Description: 'Voyeuristic disorder',
    Category: 'Paraphilic Disorder',
    Subcategory: null,
  },
  {
    Key: '642',
    Code: 'F65.4',
    Description: 'Pedophilic disorder',
    Category: 'Paraphilic Disorder',
    Subcategory: null,
  },
  {
    Key: '643',
    Code: 'F65.51',
    Description: 'Sexual masochism disorder',
    Category: 'Paraphilic Disorder',
    Subcategory: null,
  },
  {
    Key: '644',
    Code: 'F65.52',
    Description: 'Sexual sadism disorder',
    Category: 'Paraphilic Disorder',
    Subcategory: null,
  },
  {
    Key: '645',
    Code: 'F65.81',
    Description: 'Frotteuristic disorder',
    Category: 'Paraphilic Disorder',
    Subcategory: null,
  },
  {
    Key: '646',
    Code: 'F65.89',
    Description: 'Other specified paraphilic disorder ',
    Category: 'Paraphilic Disorder',
    Subcategory: null,
  },
  {
    Key: '647',
    Code: 'F65.9',
    Description: 'Unspecified paraphilic disorder',
    Category: 'Paraphilic Disorder',
    Subcategory: null,
  },
  {
    Key: '648',
    Code: 'F68.10',
    Description: 'Factitious disorder imposed on self ',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '649',
    Code: 'F68.A',
    Description: 'Factitious disorder imposed on another',
    Category: 'Somatic Symptom and Related Disorders',
    Subcategory: null,
  },
  {
    Key: '650',
    Code: 'F70',
    Description: 'Intellectual developmental disorder (intellectual disability), Mild',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '651',
    Code: 'F71',
    Description: 'Intellectual developmental disorder (intellectual disability), Moderate',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '652',
    Code: 'F72',
    Description: 'Intellectual developmental disorder (intellectual disability), Severe',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '653',
    Code: 'F73',
    Description: 'Intellectual developmental disorder (intellectual disability), Profound',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '654',
    Code: 'F79',
    Description: 'Unspecified intellectual developmental disorder (intellectual disability)',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '655',
    Code: 'F80.0',
    Description: 'Speech sound disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '656',
    Code: 'F80.2',
    Description: 'Language disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '657',
    Code: 'F80.81',
    Description: 'Childhood-onset fluency disorder (stuttering)',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '658',
    Code: 'F80.82',
    Description: 'Social (pragmatic) communication disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '659',
    Code: 'F80.9',
    Description: 'Unspecified communication disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '660',
    Code: 'F81.0',
    Description: 'Specific learning disorder, With impairment in reading',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '661',
    Code: 'F81.2',
    Description: 'Specific learning disorder, With impairment in mathematics',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '662',
    Code: 'F81.81',
    Description: 'Specific learning disorder, With impairment in written expression',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '663',
    Code: 'F82',
    Description: 'Developmental coordination disorder ',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '664',
    Code: 'F84.0',
    Description: 'Autism spectrum disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '665',
    Code: 'F88',
    Description: 'Global developmental delay',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '666',
    Code: 'F88',
    Description: 'Other specified neurodevelopmental disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '667',
    Code: 'F89',
    Description: 'Unspecified neurodevelopmental disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '668',
    Code: 'F90.0',
    Description: 'Attention-deficit/hyperactivity disorder, Predominantly inattentive presentation',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '669',
    Code: 'F90.1',
    Description:
      'Attention-deficit/hyperactivity disorder, Predominantly hyperactive/impulsive presentation',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '670',
    Code: 'F90.2',
    Description: 'Attention-deficit/hyperactivity disorder, Combined presentation',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '671',
    Code: 'F90.8',
    Description: 'Other specified attention-deficit/hyperactivity disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '672',
    Code: 'F90.9',
    Description: 'Unspecified attention-deficit/hyperactivity disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '673',
    Code: 'F91.1',
    Description: 'Conduct disorder, Childhood-onset type',
    Category: 'Disruptive, Impulse-Control, and Conduct Disorders',
    Subcategory: null,
  },
  {
    Key: '674',
    Code: 'F91.2',
    Description: 'Conduct disorder, Adolescent-onset type',
    Category: 'Disruptive, Impulse-Control, and Conduct Disorders',
    Subcategory: null,
  },
  {
    Key: '675',
    Code: 'F91.3',
    Description: 'Oppositional defiant disorder',
    Category: 'Disruptive, Impulse-Control, and Conduct Disorders',
    Subcategory: null,
  },
  {
    Key: '676',
    Code: 'F91.8',
    Description: 'Other specified disruptive, impulse-control, and conduct disorder',
    Category: 'Disruptive, Impulse-Control, and Conduct Disorders',
    Subcategory: null,
  },
  {
    Key: '677',
    Code: 'F91.9',
    Description: 'Conduct disorder, Unspecified onset',
    Category: 'Disruptive, Impulse-Control, and Conduct Disorders',
    Subcategory: null,
  },
  {
    Key: '678',
    Code: 'F91.9',
    Description: 'Unspecified disruptive, impulse-control, and conduct disorder',
    Category: 'Disruptive, Impulse-Control, and Conduct Disorders',
    Subcategory: null,
  },
  {
    Key: '679',
    Code: 'F93.0',
    Description: 'Separation anxiety disorder',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '680',
    Code: 'F94.0',
    Description: 'Selective mutism',
    Category: 'Anxiety Disorders',
    Subcategory: null,
  },
  {
    Key: '681',
    Code: 'F94.1',
    Description: 'Reactive attachment disorder',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '682',
    Code: 'F94.2',
    Description: 'Disinhibited social engagement disorder',
    Category: 'Trauma-and Stressor-Related Disorders',
    Subcategory: null,
  },
  {
    Key: '683',
    Code: 'F95.0',
    Description: 'Provisional tic disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '684',
    Code: 'F95.1',
    Description: 'Persistent (chronic) motor or vocal tic disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '685',
    Code: 'F95.2',
    Description: 'Tourette’s disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '686',
    Code: 'F95.8',
    Description: 'Other specified tic disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '687',
    Code: 'F95.9',
    Description: 'Unspecified tic disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '688',
    Code: 'F98.0',
    Description: 'Enuresis',
    Category: 'Elimination disorders',
    Subcategory: null,
  },
  {
    Key: '689',
    Code: 'F98.1',
    Description: 'Encopresis',
    Category: 'Elimination disorders',
    Subcategory: null,
  },
  {
    Key: '690',
    Code: 'F98.21',
    Description: 'Rumination disorder',
    Category: 'Feeding and Eating Disorders',
    Subcategory: null,
  },
  {
    Key: '691',
    Code: 'F98.3',
    Description: 'Pica, in children',
    Category: 'Feeding and Eating Disorders',
    Subcategory: null,
  },
  {
    Key: '692',
    Code: 'F98.4',
    Description: 'Stereotypic movement disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '693',
    Code: 'F98.5',
    Description: 'Adult-onset fluency disorder',
    Category: 'Neurodevelopmental disorders',
    Subcategory: null,
  },
  {
    Key: '694',
    Code: 'F99',
    Description: 'Other specified mental disorder',
    Category: 'Other Mental Disorders and Additional Codes',
    Subcategory: null,
  },
  {
    Key: '695',
    Code: 'F99',
    Description: 'Unspecified mental disorder',
    Category: 'Other Mental Disorders and Additional Codes',
    Subcategory: null,
  },
  {
    Key: '696',
    Code: 'G21.0',
    Description: 'Neuroleptic malignant syndrome',
    Category: 'Medication-Induced Movement Disorders and Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '697',
    Code: 'G21.11',
    Description:
      'Antipsychotic medication– and other dopamine receptor blocking agent–induced parkinsonism ',
    Category: 'Medication-Induced Movement Disorders and Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '698',
    Code: 'G21.19',
    Description: 'Other medication-induced parkinsonism',
    Category: 'Medication-Induced Movement Disorders and Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '699',
    Code: 'G24.01',
    Description: 'Tardive dyskinesia',
    Category: 'Medication-Induced Movement Disorders and Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '700',
    Code: 'G24.02',
    Description: 'Medication-induced acute dystonia ',
    Category: 'Medication-Induced Movement Disorders and Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '701',
    Code: 'G24.09',
    Description: 'Tardive dystonia',
    Category: 'Medication-Induced Movement Disorders and Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '702',
    Code: 'G25.1',
    Description: 'Medication-induced postural tremor  ',
    Category: 'Medication-Induced Movement Disorders and Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '703',
    Code: 'G25.71',
    Description: 'Medication-induced acute akathisia',
    Category: 'Medication-Induced Movement Disorders and Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '704',
    Code: 'G25.71',
    Description: 'Tardive akathisia',
    Category: 'Medication-Induced Movement Disorders and Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '705',
    Code: 'G25.79',
    Description: 'Other medication-induced movement disorder',
    Category: 'Medication-Induced Movement Disorders and Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '706',
    Code: 'G25.81',
    Description: 'Restless legs syndrome',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '707',
    Code: 'G31.84',
    Description: 'Mild frontotemporal neurocognitive disorder',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '708',
    Code: 'G31.84',
    Description: 'Mild neurocognitive disorder due to Alzheimer’s disease',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '709',
    Code: 'G31.84',
    Description: 'Mild neurocognitive disorder due to another medical condition',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '710',
    Code: 'G31.84',
    Description: 'Mild neurocognitive disorder due to HIV infection',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '711',
    Code: 'G31.84',
    Description: 'Mild neurocognitive disorder due to Huntington’s disease',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '712',
    Code: 'G31.84',
    Description: 'Mild neurocognitive disorder with Lewy bodies',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '713',
    Code: 'G31.84',
    Description: 'Mild neurocognitive disorder due to multiple etiologies',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '714',
    Code: 'G31.84',
    Description: 'Mild neurocognitive disorder due to Parkinson’s disease',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '715',
    Code: 'G31.84',
    Description: 'Mild neurocognitive disorder due to prion disease',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '716',
    Code: 'G31.84',
    Description: 'Mild neurocognitive disorder due to traumatic brain injury',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '717',
    Code: 'G31.84',
    Description: 'Mild vascular neurocognitive disorder ',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '718',
    Code: 'G47.00',
    Description: 'Unspecified insomnia disorder',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '719',
    Code: 'G47.09',
    Description: 'Other specified insomnia disorder',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '720',
    Code: 'G47.10',
    Description: 'Unspecified hypersomnolence disorder',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '721',
    Code: 'G47.19',
    Description: 'Other specified hypersomnolence disorder',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '722',
    Code: 'G47.20',
    Description: 'Circadian rhythm sleep-wake disorders, Unspecified type',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '723',
    Code: 'G47.21',
    Description: 'Circadian rhythm sleep-wake disorders, Delayed sleep phase type',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '724',
    Code: 'G47.22',
    Description: 'Circadian rhythm sleep-wake disorders, Advanced sleep phase type',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '725',
    Code: 'G47.23',
    Description: 'Circadian rhythm sleep-wake disorders, Irregular sleep-wake type',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '726',
    Code: 'G47.24',
    Description: 'Circadian rhythm sleep-wake disorders, Non-24- hour sleep-wake type',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '727',
    Code: 'G47.26',
    Description: 'Circadian rhythm sleep-wake disorders, Shift work type',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '728',
    Code: 'G47.31',
    Description: 'Central sleep apnea, Idiopathic central sleep apnea',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '729',
    Code: 'G47.33',
    Description: 'Obstructive sleep apnea hypopnea',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '730',
    Code: 'G47.34',
    Description: 'Sleep-related hypoventilation, Idiopathic hypoventilation',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '731',
    Code: 'G47.35',
    Description: 'Sleep-related hypoventilation, Congenital central alveolar hypoventilation',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '732',
    Code: 'G47.36',
    Description: 'Sleep-related hypoventilation, Comorbid sleep- related hypoventilation',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '733',
    Code: 'G47.37',
    Description: 'Central sleep apnea comorbid with opioid use',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '734',
    Code: 'G47.411',
    Description: 'Narcolepsy with cataplexy or hypocretin deficiency (type 1)',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '735',
    Code: 'G47.419',
    Description:
      'Narcolepsy without cataplexy and either without hypocretin deficiency or hypocretin unmeasured (type 2)',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '736',
    Code: 'G47.421',
    Description: 'Narcolepsy with cataplexy or hypocretin deficiency due to a medical condition',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '737',
    Code: 'G47.429',
    Description:
      'Narcolepsy without cataplexy and without hypocretin deficiency due to a medical condition',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '738',
    Code: 'G47.52',
    Description: 'Rapid eye movement sleep behavior disorder',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '739',
    Code: 'G47.8',
    Description: 'Other specified sleep-wake disorder',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '740',
    Code: 'G47.9',
    Description: 'Unspecified sleep-wake disorder',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '741',
    Code: 'N39.498',
    Description: 'Other specified elimination disorder, With urinary symptoms',
    Category: 'Elimination Disorders',
    Subcategory: null,
  },
  {
    Key: '742',
    Code: 'R06.3',
    Description: 'Central sleep apnea, Cheyne-Stokes breathing',
    Category: 'Sleep-Wake Disorders',
    Subcategory: null,
  },
  {
    Key: '743',
    Code: 'R15.9',
    Description: 'Other specified elimination disorder, With fecal symptoms',
    Category: 'Elimination Disorders',
    Subcategory: null,
  },
  {
    Key: '744',
    Code: 'R15.9',
    Description: 'Unspecified elimination disorder, With fecal symptoms',
    Category: 'Elimination Disorders',
    Subcategory: null,
  },
  {
    Key: '745',
    Code: 'R32',
    Description: 'Unspecified elimination disorder, With urinary symptoms',
    Category: 'Elimination Disorders',
    Subcategory: null,
  },
  {
    Key: '746',
    Code: 'R41.0',
    Description: 'Other specified delirium',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '747',
    Code: 'R41.0',
    Description: 'Unspecified delirium',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '748',
    Code: 'R41.81',
    Description: 'Age-related cognitive decline',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '749',
    Code: 'R41.83',
    Description: 'Borderline intellectual functioning ',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '750',
    Code: 'R41.9',
    Description: 'Unspecified neurocognitive disorder ',
    Category: 'Neurocognitive Disorders',
    Subcategory: null,
  },
  {
    Key: '751',
    Code: 'R45.88',
    Description: 'Current nonsuicidal self-injury ',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Suicidal Behaviour and Nonsuicidal Self-Injury',
  },
  {
    Key: '752',
    Code: 'R45.88',
    Description: 'Current nonsuicidal self-injury',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Suicidal Behaviour and Nonsuicidal Self-Injury',
  },
  {
    Key: '753',
    Code: 'T14.91XD',
    Description: 'Current suicidal behavior, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Suicidal Behaviour and Nonsuicidal Self-Injury',
  },
  {
    Key: '754',
    Code: 'T43.205A',
    Description: 'Antidepressant discontinuation syndrome, Initial encounter',
    Category: 'Medication-Induced Movement Disorders\nand Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '755',
    Code: 'T43.205D',
    Description: 'Antidepressant discontinuation syndrome, Subsequent encounter',
    Category: 'Medication-Induced Movement Disorders\nand Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '756',
    Code: 'T43.205S',
    Description: 'Antidepressant discontinuation syndrome, Sequelae',
    Category: 'Medication-Induced Movement Disorders\nand Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '757',
    Code: 'T50.905A',
    Description: 'Other adverse effect of medication, Initial encounter',
    Category: 'Medication-Induced Movement Disorders\nand Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '758',
    Code: 'T50.905D',
    Description: 'Other adverse effect of medication, Subsequent encounter',
    Category: 'Medication-Induced Movement Disorders\nand Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '759',
    Code: 'T50.905S',
    Description: 'Other adverse effect of medication, Sequelae',
    Category: 'Medication-Induced Movement Disorders\nand Other Adverse Effects of Medication',
    Subcategory: null,
  },
  {
    Key: '760',
    Code: 'T74.01XA',
    Description: 'Spouse or partner neglect, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '761',
    Code: 'T74.01XD',
    Description: 'Spouse or partner neglect, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '762',
    Code: 'T74.02XA',
    Description: ' Child neglect, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '763',
    Code: 'T74.02XD',
    Description: 'Child neglect, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '764',
    Code: 'T74.11XA',
    Description: 'Adult physical abuse by nonspouse or nonpartner, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '765',
    Code: 'T74.11XA',
    Description: 'Spouse or partner violence, Physical, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '766',
    Code: 'T74.11XD',
    Description: 'Adult physical abuse by nonspouse or nonpartner, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '767',
    Code: 'T74.11XD',
    Description: 'Spouse or partner violence, Physical, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '768',
    Code: 'T74.12XA',
    Description: 'Child physical abuse, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '769',
    Code: 'T74.12XD',
    Description: 'Child physical abuse, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '770',
    Code: 'T74.21XA',
    Description: 'Adult sexual abuse by nonspouse or nonpartner, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '771',
    Code: 'T74.21XA',
    Description: ' Spouse or partner violence, Sexual, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '772',
    Code: 'T74.21XD',
    Description: 'Adult sexual abuse by nonspouse or nonpartner, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '773',
    Code: 'T74.21XD',
    Description: 'Spouse or partner violence, Sexual, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '774',
    Code: 'T74.22XA',
    Description: 'Child sexual abuse, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '775',
    Code: 'T74.22XD',
    Description: 'Child sexual abuse, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '776',
    Code: 'T74.31XA',
    Description:
      ' Adult psychological abuse by nonspouse or nonpartner, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '777',
    Code: 'T74.31XA',
    Description: 'Spouse or partner abuse, Psychological, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '778',
    Code: 'T74.31XD',
    Description:
      'Adult psychological abuse by nonspouse or nonpartner, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '779',
    Code: 'T74.31XD',
    Description: 'Spouse or partner abuse, Psychological, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '780',
    Code: 'T74.32XA',
    Description: 'Child psychological abuse, Confirmed, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '781',
    Code: 'T74.32XD',
    Description: 'Child psychological abuse, Confirmed, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '782',
    Code: 'T76.01XA',
    Description: 'Spouse or partner neglect, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '783',
    Code: 'T76.01XD',
    Description: 'Spouse or partner neglect, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '784',
    Code: 'T76.02XA',
    Description: ' Child neglect, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '785',
    Code: 'T76.02XD',
    Description: 'Child neglect, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '786',
    Code: 'T76.11XA',
    Description: 'Adult physical abuse by nonspouse or nonpartner, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '787',
    Code: 'T76.11XA',
    Description: ' Spouse or partner violence, Physical, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '788',
    Code: 'T76.11XD',
    Description: 'Adult physical abuse by nonspouse or nonpartner, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '789',
    Code: 'T76.11XD',
    Description: 'Spouse or partner violence, Physical, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '790',
    Code: 'T76.12XA',
    Description: 'Child physical abuse, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '791',
    Code: 'T76.12XD',
    Description: 'Child physical abuse, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '792',
    Code: 'T76.21XA',
    Description: 'Adult sexual abuse by nonspouse or nonpartner, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '793',
    Code: 'T76.21XA',
    Description: 'Spouse or partner violence, Sexual, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '794',
    Code: 'T76.21XD',
    Description: 'Adult sexual abuse by nonspouse or nonpartner, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '795',
    Code: 'T76.21XD',
    Description: 'Spouse or partner violence, Sexual, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '796',
    Code: 'T76.22XA',
    Description: 'Child sexual abuse, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '797',
    Code: 'T76.22XD',
    Description: 'Child sexual abuse, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '798',
    Code: 'T76.31XA',
    Description:
      ' Adult psychological abuse by nonspouse or nonpartner, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '799',
    Code: 'T76.31XA',
    Description: ' Spouse or partner abuse, Psychological, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '800',
    Code: 'T76.31XD',
    Description:
      'Adult psychological abuse by nonspouse or nonpartner, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '801',
    Code: 'T76.31XD',
    Description: 'Spouse or partner abuse, Psychological, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '802',
    Code: 'T76.32XA',
    Description: 'Child psychological abuse, Suspected, Initial encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '803',
    Code: 'T76.32XD',
    Description: 'Child psychological abuse, Suspected, Subsequent encounter',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '804',
    Code: 'Z03.89',
    Description: 'No diagnosis or condition ',
    Category: 'Other Mental Disorders and Additional Codes',
    Subcategory: null,
  },
  {
    Key: '805',
    Code: 'Z31.5',
    Description: 'Genetic counseling',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Other Health Service Encounters for Counseling and Medical Advice',
  },
  {
    Key: '806',
    Code: 'Z55.0',
    Description: 'Illiteracy and low-level literacy',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Educational Problems',
  },
  {
    Key: '807',
    Code: 'Z55.1',
    Description: 'Schooling unavailable and unattainable',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Educational Problems',
  },
  {
    Key: '808',
    Code: 'Z55.2',
    Description: 'Failed school examinations',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Educational Problems',
  },
  {
    Key: '809',
    Code: 'Z55.3',
    Description: 'Underachievement in school',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Educational Problems',
  },
  {
    Key: '810',
    Code: 'Z55.4',
    Description: 'Educational maladjustment and discord with teachers and classmates',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Educational Problems',
  },
  {
    Key: '811',
    Code: 'Z55.8',
    Description: 'Problems related to inadequate teaching',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Educational Problems',
  },
  {
    Key: '812',
    Code: 'Z55.9',
    Description: 'Other problems related to education and literacy',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Educational Problems',
  },
  {
    Key: '813',
    Code: 'Z56.0',
    Description: 'Unemployment',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Occupational Problems',
  },
  {
    Key: '814',
    Code: 'Z56.1',
    Description: 'Change of job',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Occupational Problems',
  },
  {
    Key: '815',
    Code: 'Z56.2',
    Description: 'Threat of job loss',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Occupational Problems',
  },
  {
    Key: '816',
    Code: 'Z56.3',
    Description: 'Stressful work schedule',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Occupational Problems',
  },
  {
    Key: '817',
    Code: 'Z56.4',
    Description: 'Discord with boss and workmates',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Occupational Problems',
  },
  {
    Key: '818',
    Code: 'Z56.5',
    Description: 'Uncongenial work environment',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Occupational Problems',
  },
  {
    Key: '819',
    Code: 'Z56.6',
    Description: 'Other physical and mental strain related to work',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Occupational Problems',
  },
  {
    Key: '820',
    Code: 'Z56.81',
    Description: 'Sexual harassment on the job',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Occupational Problems',
  },
  {
    Key: '821',
    Code: 'Z56.82',
    Description: 'Problem related to current military deployment status',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Occupational Problems',
  },
  {
    Key: '822',
    Code: 'Z56.9',
    Description: 'Other problem related to employment ',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Occupational Problems',
  },
  {
    Key: '823',
    Code: 'Z58.6',
    Description: 'Lack of safe drinking water',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Economic Problems',
  },
  {
    Key: '824',
    Code: 'Z59.01',
    Description: 'Sheltered homelessness',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Housing Problems',
  },
  {
    Key: '825',
    Code: 'Z59.02',
    Description: 'Unsheltered homelessness',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Housing Problems',
  },
  {
    Key: '826',
    Code: 'Z59.1',
    Description: 'Inadequate housing',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Housing Problems',
  },
  {
    Key: '827',
    Code: 'Z59.2',
    Description: 'Discord with neighbor, lodger, or landlord',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Housing Problems',
  },
  {
    Key: '828',
    Code: 'Z59.3',
    Description: 'Problem related to living in a residential institution',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Housing Problems',
  },
  {
    Key: '829',
    Code: 'Z59.41',
    Description: 'Food insecurity',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Economic Problems',
  },
  {
    Key: '830',
    Code: 'Z59.5',
    Description: 'Extreme poverty',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Economic Problems',
  },
  {
    Key: '831',
    Code: 'Z59.6',
    Description: 'Low income',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Economic Problems',
  },
  {
    Key: '832',
    Code: 'Z59.7',
    Description: 'Insufficient social or health insurance or welfare support',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Economic Problems',
  },
  {
    Key: '833',
    Code: 'Z59.9',
    Description: 'Other economic problem ',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Economic Problems',
  },
  {
    Key: '834',
    Code: 'Z59.9',
    Description: 'Other housing problem',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Housing Problems',
  },
  {
    Key: '835',
    Code: 'Z60.0',
    Description: 'Phase of life problem',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '836',
    Code: 'Z60.2',
    Description: 'Problem related to living alone',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems related to social environment ',
  },
  {
    Key: '837',
    Code: 'Z60.3',
    Description: 'Acculturation difficulty',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems related to social environment ',
  },
  {
    Key: '838',
    Code: 'Z60.4',
    Description: 'Social exclusion or rejection',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems related to social environment ',
  },
  {
    Key: '839',
    Code: 'Z60.5',
    Description: 'Target of (perceived) adverse discrimination or persecution',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems related to social environment ',
  },
  {
    Key: '840',
    Code: 'Z60.9',
    Description: 'Other problem related to social environment',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems related to social environment ',
  },
  {
    Key: '841',
    Code: 'Z62.29',
    Description: 'Upbringing away from parents',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems related to the family environment',
  },
  {
    Key: '842',
    Code: 'Z62.810',
    Description: 'Personal history (past history) of physical abuse in childhood',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '843',
    Code: 'Z62.810',
    Description: 'Personal history (past history) of sexual abuse in childhood',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '844',
    Code: 'Z62.811',
    Description: 'Personal history (past history) of psychological abuse in childhood',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '845',
    Code: 'Z62.812',
    Description: 'Personal history (past history) of neglect in childhood',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '846',
    Code: 'Z62.820',
    Description: 'Parent-child relational problem, Parent–biological child',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Relational Problems',
  },
  {
    Key: '847',
    Code: 'Z62.821',
    Description: 'Parent-child relational problem, Parent–adopted child',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Relational Problems',
  },
  {
    Key: '848',
    Code: 'Z62.822',
    Description: 'Parent-child relational problem, Parent–foster child',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Relational Problems',
  },
  {
    Key: '849',
    Code: 'Z62.891',
    Description: 'Sibling relational problem',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Relational Problems',
  },
  {
    Key: '850',
    Code: 'Z62.898',
    Description: 'Child affected by parental relationship distress',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems related to the family environment',
  },
  {
    Key: '851',
    Code: 'Z62.898',
    Description: 'Parent-child relational problem, Other caregiver– child',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Relational Problems',
  },
  {
    Key: '852',
    Code: 'Z63.0',
    Description: 'Relationship distress with spouse or intimate partner',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Relational Problems',
  },
  {
    Key: '853',
    Code: 'Z63.4',
    Description: 'Uncomplicated bereavement',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '854',
    Code: 'Z63.5',
    Description: 'Disruption of family by separation or divorce',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems related to the family environment',
  },
  {
    Key: '855',
    Code: 'Z63.8',
    Description: 'High expressed emotion level within family',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems related to the family environment',
  },
  {
    Key: '856',
    Code: 'Z64.0',
    Description: 'Problems related to unwanted pregnancy',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory:
      'Problems Related to Other Psychosocial, Personal, and Environmental Circumstances',
  },
  {
    Key: '857',
    Code: 'Z64.1',
    Description: 'Problems related to multiparity',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory:
      'Problems Related to Other Psychosocial, Personal, and Environmental Circumstances',
  },
  {
    Key: '858',
    Code: 'Z64.4',
    Description:
      'Discord with social service provider, including probation officer, case manager, or social services worker',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory:
      'Problems Related to Other Psychosocial, Personal, and Environmental Circumstances',
  },
  {
    Key: '859',
    Code: 'Z65.0',
    Description: 'Conviction in civil or criminal proceedings without imprisonment',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems Related to Interaction With the Legal System',
  },
  {
    Key: '860',
    Code: 'Z65.1',
    Description: 'Imprisonment or other incarceration',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems Related to Interaction With the Legal System',
  },
  {
    Key: '861',
    Code: 'Z65.2',
    Description: 'Problems related to release from prison',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems Related to Interaction With the Legal System',
  },
  {
    Key: '862',
    Code: 'Z65.3',
    Description: 'Problems related to other legal circumstances',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems Related to Interaction With the Legal System',
  },
  {
    Key: '863',
    Code: 'Z65.4',
    Description: 'Victim of crime',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory:
      'Problems Related to Other Psychosocial, Personal, and Environmental Circumstances',
  },
  {
    Key: '864',
    Code: 'Z65.4',
    Description: 'Victim of terrorism or torture',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory:
      'Problems Related to Other Psychosocial, Personal, and Environmental Circumstances',
  },
  {
    Key: '865',
    Code: 'Z65.5',
    Description: 'Exposure to disaster, war, or other hostilities',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory:
      'Problems Related to Other Psychosocial, Personal, and Environmental Circumstances',
  },
  {
    Key: '866',
    Code: 'Z65.8',
    Description: 'Religious or spiritual problem',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '867',
    Code: 'Z69.010',
    Description: 'Encounter for mental health services for victim of child neglect by parent',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '868',
    Code: 'Z69.010',
    Description:
      'Encounter for mental health services for victim of child physical abuse by parent',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '869',
    Code: 'Z69.010',
    Description:
      'Encounter for mental health services for victim of child psychological abuse by parent',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '870',
    Code: 'Z69.010',
    Description: 'Encounter for mental health services for victim of child sexual abuse by parent',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '871',
    Code: 'Z69.011',
    Description: 'Encounter for mental health services for perpetrator of parental child neglect',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '872',
    Code: 'Z69.011',
    Description:
      'Encounter for mental health services for perpetrator of parental child physical abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '873',
    Code: 'Z69.011',
    Description:
      'Encounter for mental health services for perpetrator of parental child psychological abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '874',
    Code: 'Z69.011',
    Description:
      'Encounter for mental health services for perpetrator of parental child sexual abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '875',
    Code: 'Z69.020',
    Description: 'Encounter for mental health services for victim of nonparental child neglect',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '876',
    Code: 'Z69.020',
    Description:
      'Encounter for mental health services for victim of nonparental child physical abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '877',
    Code: 'Z69.020',
    Description:
      'Encounter for mental health services for victim of nonparental child psychological abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '878',
    Code: 'Z69.020',
    Description:
      'Encounter for mental health services for victim of nonparental child sexual abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '879',
    Code: 'Z69.021',
    Description:
      'Encounter for mental health services for perpetrator of nonparental child neglect',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '880',
    Code: 'Z69.021',
    Description:
      'Encounter for mental health services for perpetrator of nonparental child physical abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '881',
    Code: 'Z69.021',
    Description:
      'Encounter for mental health services for perpetrator of nonparental child psychological abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '882',
    Code: 'Z69.021',
    Description:
      'Encounter for mental health services for perpetrator of nonparental child sexual abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '883',
    Code: 'Z69.11',
    Description: 'Encounter for mental health services for victim of spouse or partner neglect',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '884',
    Code: 'Z69.11',
    Description:
      'Encounter for mental health services for victim of spouse or partner psychological abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Child maltreatment and neglect problems',
  },
  {
    Key: '885',
    Code: 'Z69.11',
    Description:
      'Encounter for mental health services for victim of spouse or partner violence, Physical',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '886',
    Code: 'Z69.12',
    Description:
      'Encounter for mental health services for perpetrator of spouse or partner neglect',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '887',
    Code: 'Z69.12',
    Description:
      'Encounter for mental health services for perpetrator of spouse or partner psychological abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '888',
    Code: 'Z69.12',
    Description:
      'Encounter for mental health services for perpetrator of spouse or partner violence, Physical',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '889',
    Code: 'Z69.12',
    Description:
      'Encounter for mental health services for perpetrator of spouse or partner violence, Sexual',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '890',
    Code: 'Z69.81',
    Description:
      'Encounter for mental health services for victim of nonspousal or nonpartner adult abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '891',
    Code: 'Z69.81',
    Description:
      'Encounter for mental health services for victim of spouse or partner violence, Sexual',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '892',
    Code: 'Z69.82',
    Description:
      'Encounter for mental health services for perpetrator of nonspousal or nonpartner adult abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '893',
    Code: 'Z70.9',
    Description: 'Sex counseling',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Other Health Service Encounters for Counseling and Medical Advice',
  },
  {
    Key: '894',
    Code: 'Z71.3',
    Description: 'Dietary counseling',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Other Health Service Encounters for Counseling and Medical Advice',
  },
  {
    Key: '895',
    Code: 'Z71.9',
    Description: 'Other counseling or consultation ',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Other Health Service Encounters for Counseling and Medical Advice',
  },
  {
    Key: '896',
    Code: 'Z72.0',
    Description: 'Tobacco use disorder, mild',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '897',
    Code: 'Z72.810',
    Description: 'Child or adolescent antisocial behavior',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '898',
    Code: 'Z72.811',
    Description: 'Adult antisocial behavior',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '899',
    Code: 'Z72.9',
    Description: 'Problem related to lifestyle',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory:
      'Problems Related to Other Psychosocial, Personal, and Environmental Circumstances',
  },
  {
    Key: '900',
    Code: 'Z75.3',
    Description: 'Unavailability or inaccessibility of health care facilities',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems Related to Access to Medical and\nOther Health Care',
  },
  {
    Key: '901',
    Code: 'Z75.4',
    Description: 'Unavailability or inaccessibility of other helping agencies',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Problems Related to Access to Medical and\nOther Health Care',
  },
  {
    Key: '902',
    Code: 'Z76.5',
    Description: 'Malingering',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '903',
    Code: 'Z91.19',
    Description: 'Nonadherence to medical treatment',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
  {
    Key: '904',
    Code: 'Z91.410',
    Description: 'Personal history (past history) of spouse or partner violence, Physical',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '905',
    Code: 'Z91.410',
    Description: 'Personal history (past history) of spouse or partner violence, Sexual',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '906',
    Code: 'Z91.411',
    Description: 'Personal history (past history) of spouse or partner psychological abuse',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '907',
    Code: 'Z91.412',
    Description: 'Personal history (past history) of spouse or partner neglect',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Adult maltreatment and neglect problems',
  },
  {
    Key: '908',
    Code: 'Z91.49',
    Description: 'Personal history of psychological trauma',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Circumstances of Personal History',
  },
  {
    Key: '909',
    Code: 'Z91.51',
    Description: 'History of suicidal behavior',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Suicidal Behaviour and Nonsuicidal Self-Injury',
  },
  {
    Key: '910',
    Code: 'Z91.52',
    Description: 'History of nonsuicidal self-injury',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Suicidal Behaviour and Nonsuicidal Self-Injury',
  },
  {
    Key: '911',
    Code: 'Z91.82',
    Description: 'Personal history of military deployment',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: 'Circumstances of Personal History',
  },
  {
    Key: '912',
    Code: 'Z91.83',
    Description: 'Wandering associated with a mental disorder',
    Category: 'Other Conditions That May Be a Focus of Clinical Attention',
    Subcategory: null,
  },
];

export type DiagnosisCode = keyof typeof descriptionsByDiagnosisCode;

export const diagnosisCodes = dsm5DiagnosesCodes.map(diagnosis => diagnosis.Key);

// if a number is passed in, that means it this was saved after 9/30/2022, and should use the new DSM5 diagnosis list that uses KEY
// if a diagnosis code was passed in, this is legacy and has to use the old icd10 list

export function getLabeledDiagnosisCode(code: string, delimiter = ' ') {
  if (Number(code) || Number(code) === 0) {
    // edge case incase the provider picks the 0th item

    return `${dsm5DiagnosesCodes[Number(code)]?.Code} ${delimiter} ${
      dsm5DiagnosesCodes[Number(code)]?.Description
    }`;
  }

  return isDiagnosisCode(code) ? `${code}${delimiter}${descriptionsByDiagnosisCode[code]}` : code;
}

function isDiagnosisCode(code: string): code is DiagnosisCode {
  return code in descriptionsByDiagnosisCode;
}
