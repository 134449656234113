import styled from 'styled-components';
import { colors } from '../../globalStyles';
import { HELVETICA } from '../../globalStyles/text';
import { Link } from '../Links';

export const CountContainer = styled.div`
  position: absolute;
  right: 0.75rem;
  top: 25%;
`;

export const NavWrapper = styled.div`
  width: 220px;
  background-color: ${colors.navBg};
  flex-flow: column;
  justify-content: space-between;
  color: white;
  overflow-y: auto;
`;

export const NavLink = styled(Link)`
  font-family: ${HELVETICA};
  color: ${colors.white};
  text-decoration: none;
  padding: 0px 1.5rem;
  margin-bottom: 0.5rem;
  height: 2.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.active {
    background-color: ${colors.primary};
  }
`;

export const NavButton = styled.button`
  background: none;
  border: none;
  font-family: ${HELVETICA};
  color: ${colors.white};
  text-decoration: none;
  padding: 0px 1.5rem;
  margin-bottom: 0.5rem;
  height: 2.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &.active {
    background-color: ${colors.primary};
  }
`;

export const UserFooter = styled.div`
  border-top: 1px solid ${colors.grey.dark};
  padding-top: 1rem;
  margin: 0.5rem 1rem 2rem;
  display: flex;
  align-items: flex-start;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.grey.dark};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
