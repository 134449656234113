import { useMemo } from 'react';
import { GlobalAvailabilityBlockerType, SimpleGlobalAvailabilityBlocker } from '../../../graphQL';

export type ContractLimits = {
  contractStudentCapacityReached: boolean;
  contractSessionCapacityReached: boolean;
  contractWeeklyLimitReached: boolean;
};

export const useContractLimits = (
  globalBlockers?: SimpleGlobalAvailabilityBlocker[]
): ContractLimits => {
  return useMemo(() => {
    if (!globalBlockers) {
      return {
        contractStudentCapacityReached: false,
        contractSessionCapacityReached: false,
        contractWeeklyLimitReached: false,
      };
    }

    const foundBlockers = globalBlockers.map(({ type }) => type);
    const someHave = (type: GlobalAvailabilityBlockerType) => foundBlockers.includes(type);
    return {
      contractStudentCapacityReached: someHave(GlobalAvailabilityBlockerType.StudentCapacity),
      contractSessionCapacityReached: someHave(GlobalAvailabilityBlockerType.ContractSessions),
      contractWeeklyLimitReached:
        someHave(GlobalAvailabilityBlockerType.WeeklyIntakes) ||
        someHave(GlobalAvailabilityBlockerType.WeeklySessions),
    };
  }, [globalBlockers]);
};
