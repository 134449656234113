import { ApolloQueryResult, NetworkStatus } from '@apollo/client';
import { Modal } from 'baseui/modal';
import { KIND } from 'baseui/notification';
import { StyledSpinnerNext } from 'baseui/spinner';
import { capitalize, first, get, pick } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { MaskedPhoneInput } from '../../Components/EzForm/MaskedInputs';
import { FinalButton } from '../../Components/FinalButton';
import { TextIcon } from '../../Components/Flair';
import {
  CheckboxRHF,
  FormControl,
  FormControlProps,
  InputRHF,
  MultiSelectRHF,
  SelectRHF,
} from '../../Components/Form';
import { Textarea } from '../../Components/Form/Input';
import { icons } from '../../Components/Icons';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { CenteredNotification } from '../../Components/Notification';
import {
  MantraAdminOnly,
  isMantraAdmin,
  isMantraOrMcpAdmin,
  isMcpAdmin,
  isMcpOrReferralUser,
  isMcpUser,
  useCurrentProvider,
} from '../../Components/Permissions';
import { useImageUploader } from '../../Hooks';
import { providerProfileLabels } from '../../constants';
import { Modal as ModalStyle, Text } from '../../globalStyles';
import {
  Permission,
  ProviderProfileQuery,
  ProviderTrait,
  TraitCategory,
  UpdateStaffRole,
  useAdminCompleteProviderPhotoUploadMutation,
  useAdminUpdateProviderMutation,
  useProviderProfileQuery,
  useTraitCategoriesQuery,
  useUpdateProviderAndTraitsMutation,
} from '../../graphQL';
import { formatProviderCareTypes } from '../../modelUtils/provider';
import { stateOptions } from '../../states';
import { formatPhoneNumber, stripPhoneNumber } from '../../utils';
import { ProviderDrilldownContext } from '../Provider/context';
import { getMcpRoleOptionsV2 } from '../Provider/util';
import { UnexpectedError } from '../Shared';
import { Headline } from './Common';
import { McpCrisisTeam } from './CrisisTeamEdit';

interface ProfileProps {
  provider: ProviderProfileQuery['adminProvider'];
  refetch: () => Promise<ApolloQueryResult<ProviderProfileQuery>>;
  refetching: boolean;
}

export function OzProfile({ providerId }: { providerId: number }) {
  const { appView, currentProvider } = useCurrentProvider();
  const { data, error, refetch, networkStatus } = useProviderProfileQuery({
    variables: { providerId },
    notifyOnNetworkStatusChange: true,
  });

  if (!data && !error) return <LoadingPage />;
  if (error || !data) return <UnexpectedError />;

  const props = {
    provider: data.adminProvider,
    refetch,
    refetching: networkStatus === NetworkStatus.refetch,
  };

  const hasCrisisTeamEntitlement = data.adminProvider.organizations.some(o =>
    o.entitlements.some(e => e.key === 'CrisisTeam')
  );

  return (
    <div className="mb4">
      <NameAndContact {...props} />
      {appView === 'oz' && !isMcpOrReferralUser(data.adminProvider) && (
        <>
          <Divider />
          <IdentityAndSpecialties {...props} />
        </>
      )}
      {isMantraOrMcpAdmin(currentProvider) && (
        <>
          <Divider />
          <AccountDetails {...props} />
        </>
      )}
      {isMcpUser(data.adminProvider) && hasCrisisTeamEntitlement && (
        <>
          <Divider />
          <McpCrisisTeam provider={data.adminProvider} refetch={refetch} />
        </>
      )}
    </div>
  );
}

const createNameAndContactFormValues = (provider: ProfileProps['provider']) =>
  pick(
    provider,
    'personalTitle',
    'firstName',
    'lastName',
    'postNominalTitles',
    'email',
    'phone',
    'classification'
  );

function NameAndContact({ provider, refetch, refetching }: ProfileProps) {
  const { hasPermission, isTheCurrentProvider } = useCurrentProvider();
  const drilldownContext = useContext(ProviderDrilldownContext);
  const [showingModal, setShowingModal] = useState(false);
  const fields: FieldTuple[] = [
    ['Personal Title', provider.personalTitle],
    ['First Name', provider.firstName],
    ['Last Name', provider.lastName],
    ['Post Nominal Titles', provider.postNominalTitles],
    ['Email', provider.email],
    ['Phone Number', provider.phone ? formatPhoneNumber(provider.phone) : undefined],
  ];
  const modalForm = useForm({ defaultValues: createNameAndContactFormValues(provider) });
  const [save, saveMutation] = useAdminUpdateProviderMutation();
  const [savePhoto, savePhotoMutation] = useAdminCompleteProviderPhotoUploadMutation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { upload } = useImageUploader({
    completeFunction: (key: string) => savePhoto({ variables: { providerId: provider.id, key } }),
    callback: refetch,
  });

  const onSubmit = modalForm.handleSubmit(async values => {
    await save({
      variables: {
        input: { id: provider.id, ...values, phone: stripPhoneNumber(values.phone ?? '') },
      },
    });
    drilldownContext?.refetch();
    const refetched = await refetch();
    setShowingModal(false);
    if (refetched.data) {
      modalForm.reset(createNameAndContactFormValues(refetched.data.adminProvider));
    }
  });

  const onChangePhotoInput = async () => {
    const input = fileInputRef.current;
    if (!input) return;
    const file = input.files?.[0];
    if (!file) return;
    input.value = '';
    await upload(file);
    drilldownContext?.refetch();
  };

  const onRemovePhoto = async () => {
    if (!provider.portrait) return;
    await savePhoto({ variables: { providerId: provider.id, key: null } });
    refetch();
    drilldownContext?.refetch();
  };

  return (
    <>
      <Headline
        onEdit={() => setShowingModal(true)}
        canEdit={isTheCurrentProvider(provider.id) || hasPermission(Permission.ProviderEdit)}
      >
        Name &amp; Contact
      </Headline>
      <div className="flex flex-row">
        <FieldsTable fields={fields} />
        <div className="ml5">
          <Text.label className="mb3">
            {isTheCurrentProvider(provider.id) ? 'Your' : `${provider.firstName}’s`} Photo
          </Text.label>
          <div className="flex flex-row items-center">
            <PhotoContainer>
              {savePhotoMutation.loading ? (
                <StyledSpinnerNext size={60} />
              ) : (
                <Headshot
                  src={provider.portrait?.url ?? icons.assetsDefaultPicturePng}
                  alt="Picture of provider"
                />
              )}
            </PhotoContainer>
            {isTheCurrentProvider(provider.id) || hasPermission(Permission.ProviderEdit) ? (
              <div className="ml3">
                <div>
                  <Text.linkButton className="b" onClick={() => fileInputRef.current?.click()}>
                    Upload new photo
                  </Text.linkButton>
                  <span className="ml3 mr3" style={{ color: '#999999' }}>
                    •
                  </span>
                  <Text.linkButton className="b" kind="grayText" onClick={onRemovePhoto}>
                    Remove photo
                  </Text.linkButton>
                </div>
                <Text.bodySmall className="mt2">
                  This photo is visible to patients, providers, referrers, and campus collaborators
                </Text.bodySmall>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={onChangePhotoInput}
                  accept="image/png,image/jpeg"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        isOpen={showingModal}
        onClose={() => setShowingModal(false)}
        unstable_ModalBackdropScroll
        autoFocus={false}
      >
        <ModalStyle.body>
          <FormProvider {...modalForm}>
            <EditNameAndContact />
            <FinalButton
              onClick={onSubmit}
              className="w-100"
              loading={saveMutation.loading || refetching}
            >
              Save
            </FinalButton>
          </FormProvider>
        </ModalStyle.body>
      </Modal>
    </>
  );
}

function EditNameAndContact() {
  const { currentProvider } = useCurrentProvider();
  const { control } = useFormContext();
  const personalTitles = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Mx.'];
  const required = isMantraAdmin(currentProvider) ? false : 'This field is required.';
  return (
    <div>
      <Text.h3 className="mbx">Name &amp; Contact</Text.h3>
      <InputWrapper name="personalTitle" label="Personal Title">
        <SelectRHF
          clearable
          name="personalTitle"
          options={personalTitles.map(i => ({ label: i, id: i }))}
        />
      </InputWrapper>
      <InputWrapper name="firstName" label="First Name">
        <InputRHF name="firstName" rules={{ required }} />
      </InputWrapper>
      <InputWrapper name="lastName" label="Last Name">
        <InputRHF name="lastName" rules={{ required }} />
      </InputWrapper>
      <InputWrapper name="postNominalTitles" label="Post-nominal Title(s)">
        <InputRHF name="postNominalTitles" rules={{ required }} placeholder="e.g. PhD" />
      </InputWrapper>
      <InputWrapper name="email" label="Email">
        <InputRHF name="email" rules={{ required }} />
      </InputWrapper>
      <InputWrapper name="phone" label="Office Phone Number">
        <MaskedPhoneInput name="phone" rules={{ required }} control={control} ext />
      </InputWrapper>
      <MantraAdminOnly>
        <InputWrapper name="classification" label="Role">
          <InputRHF name="classification" />
        </InputWrapper>
      </MantraAdminOnly>
    </div>
  );
}

const MAX_SPECIALTIES = 7;

const getTrait = (provider: ProfileProps['provider'], category: TraitCategory) =>
  provider.traits.find(i => i.category === category)?.values ?? [];

const createIdentityAndSpecialtiesFormValues = (provider: ProfileProps['provider']) => ({
  ...pick(provider, 'gender', 'pronouns', 'funFact'),
  sexuality: getTrait(provider, TraitCategory.Sexuality),
  ethnicity: getTrait(provider, TraitCategory.Ethnicity),
  languages: getTrait(provider, TraitCategory.Languages),
  faith: getTrait(provider, TraitCategory.Faith),
  ...getTrait(provider, TraitCategory.Specialties).reduce(
    (acc, cur) => ({ ...acc, [`specialties|${cur}`]: true }),
    {}
  ),
  ...getTrait(provider, TraitCategory.TherapyTypes).reduce(
    (acc, cur) => ({ ...acc, [`therapyTypes|${cur}`]: true }),
    {}
  ),
});

function IdentityAndSpecialties({ provider, refetch, refetching }: ProfileProps) {
  const { hasPermission, isTheCurrentProvider } = useCurrentProvider();
  const [showingModal, setShowingModal] = useState(false);
  const [error, setError] = useState('');

  const { data } = useTraitCategoriesQuery();
  const fields: FieldTuple[] = [
    ['Gender Identity', provider.gender],
    ['Pronouns', provider.pronouns],
    ['Sexuality', getTrait(provider, TraitCategory.Sexuality).join(', ')],
    ['Race & Ethnicity', getTrait(provider, TraitCategory.Ethnicity).join(', ')],
    ['Languages', getTrait(provider, TraitCategory.Languages).join(', ')],
    ['Faith', getTrait(provider, TraitCategory.Faith).join(', ')],
    ['Specialties', getTrait(provider, TraitCategory.Specialties).join('\n')],
    ['Types of Therapy', getTrait(provider, TraitCategory.TherapyTypes).join('\n')],
    [providerProfileLabels.about, provider.funFact],
  ];

  const modalForm = useForm({
    defaultValues: createIdentityAndSpecialtiesFormValues(provider),
  });
  const [save, saveMutation] = useUpdateProviderAndTraitsMutation({
    onCompleted: async () => {
      const refetched = await refetch();
      if (refetched.data) {
        modalForm.reset(createIdentityAndSpecialtiesFormValues(refetched.data.adminProvider));
      }
      setShowingModal(false);
    },
    onError: err => {
      setError(err.message);
    },
  });
  const onSubmit = modalForm.handleSubmit(async values => {
    const specialties = Object.keys(values)
      .filter(i => i.startsWith('specialties|') && (values as any)[i] === true)
      .map(i => i.replace(/specialties\|/, ''));
    const therapyTypes = Object.keys(values)
      .filter(i => i.startsWith('therapyTypes|') && (values as any)[i] === true)
      .map(i => i.replace(/therapyTypes\|/, ''));

    const sexuality = first(values.sexuality);

    await save({
      variables: {
        updateProvider: {
          id: provider.id,
          gender: values.gender,
          pronouns: values.pronouns,
          funFact: values.funFact,
        },
        providerId: provider.id,
        traits: [
          { category: TraitCategory.Sexuality, values: sexuality ? [sexuality] : [] },
          { category: TraitCategory.Ethnicity, values: values.ethnicity },
          { category: TraitCategory.Languages, values: values.languages },
          { category: TraitCategory.Faith, values: values.faith },
          { category: TraitCategory.Specialties, values: specialties },
          { category: TraitCategory.TherapyTypes, values: therapyTypes },
        ],
      },
    });
  });
  return (
    <>
      <Headline
        onEdit={() => setShowingModal(true)}
        canEdit={isTheCurrentProvider(provider.id) || hasPermission(Permission.ProviderEdit)}
        subhead="Visible to patients, referrers, and campus collaborators. Used for matching patients with a preferred provider."
      >
        Identity &amp; Specialties
      </Headline>
      <FieldsTable fields={fields} />
      <Modal
        isOpen={showingModal}
        onClose={() => setShowingModal(false)}
        unstable_ModalBackdropScroll
        autoFocus={false}
      >
        <ModalStyle.body>
          <FormProvider {...modalForm}>
            {error && <CenteredNotification kind={KIND.negative}>{error}</CenteredNotification>}
            <EditIdentityAndSpecialties traitCategories={data?.traitCategories ?? []} />
            <FinalButton
              onClick={onSubmit}
              loading={saveMutation.loading || refetching}
              className="w-100"
            >
              Save
            </FinalButton>
          </FormProvider>
        </ModalStyle.body>
      </Modal>
    </>
  );
}

function EditIdentityAndSpecialties({ traitCategories }: { traitCategories: ProviderTrait[] }) {
  const { currentProvider } = useCurrentProvider();
  const { register, errors, watch, setError, clearErrors } = useFormContext();
  const options = (category: TraitCategory) =>
    (traitCategories.find(i => i.category === category)?.values ?? []).map(i => ({
      id: i,
      label: i,
    }));
  const genderOptions = ['Woman', 'Man', 'Non-Binary', 'Transgender Woman', 'Transgender Man'].map(
    i => ({ id: i, label: i })
  );
  const pronounOptions = [
    'She/Her/Hers',
    'He/Him/His',
    'They/Them/Theirs',
    'Prefer Not to Say',
    'Other',
  ].map(i => ({
    id: i,
    label: i,
  }));
  const specialties =
    traitCategories.find(i => i.category === TraitCategory.Specialties)?.values ?? [];
  const specialtiesLeft = specialties.slice(0, Math.floor(specialties.length / 2));
  const specialtiesRight = specialties.slice(
    Math.floor(specialties.length / 2),
    specialties.length
  );
  const therapyTypes =
    traitCategories.find(i => i.category === TraitCategory.TherapyTypes)?.values ?? [];

  const specialtiesFields = watch(specialties.map(i => `specialties|${i}`));
  const activeCount = Object.values(specialtiesFields).filter(i => i === true).length;
  useEffect(() => {
    if (activeCount > MAX_SPECIALTIES) {
      setError('specialties', {
        type: 'validate',
        message: `Please select no more than ${MAX_SPECIALTIES} specialties.`,
      });
    } else if (errors.specialties) {
      clearErrors('specialties');
    }
    // eslint-disable-next-line
  }, [activeCount]);

  const required = isMantraAdmin(currentProvider) ? undefined : 'This field is required.';
  const atLeastOne = {
    validate: isMantraAdmin(currentProvider)
      ? undefined
      : (value: string[]) => value.length > 0 || 'This field is required.',
  };

  return (
    <div>
      <Text.h3>Identity &amp; Specialties</Text.h3>
      <InputWrapper name="gender" label="Gender Identity">
        <SelectRHF name="gender" rules={{ required }} options={genderOptions} />
      </InputWrapper>
      <InputWrapper name="pronouns" label="Pronouns">
        <SelectRHF name="pronouns" options={pronounOptions} clearable />
      </InputWrapper>
      <InputWrapper name="sexuality[0]" label="Sexuality">
        <SelectRHF name="sexuality[0]" options={options(TraitCategory.Sexuality)} clearable />
      </InputWrapper>
      <InputWrapper name="ethnicity" label="Race & Ethnicity">
        <MultiSelectRHF
          name="ethnicity"
          rules={atLeastOne}
          options={options(TraitCategory.Ethnicity)}
        />
      </InputWrapper>
      <InputWrapper name="languages" label="Languages">
        <MultiSelectRHF
          name="languages"
          rules={atLeastOne}
          options={options(TraitCategory.Languages)}
        />
      </InputWrapper>
      <InputWrapper name="faith" label="Faith">
        <MultiSelectRHF name="faith" options={options(TraitCategory.Faith)} />
      </InputWrapper>
      <InputWrapper name="specialties" label={`Specialties (up to ${MAX_SPECIALTIES})`}>
        {errors.specialties && (
          <Text.bodyBold kind="danger">{errors.specialties.message}</Text.bodyBold>
        )}
        <div className="flex flex-row mt3">
          <div className="pr3">
            {specialtiesLeft.map(spec => (
              <CheckboxRHF name={`specialties|${spec}`} key={spec}>
                {spec}
              </CheckboxRHF>
            ))}
          </div>
          <div className="pl3">
            {specialtiesRight.map(spec => (
              <CheckboxRHF name={`specialties|${spec}`} key={spec}>
                {spec}
              </CheckboxRHF>
            ))}
          </div>
        </div>
      </InputWrapper>
      <InputWrapper name="therapyTypes" label="Types of Therapy">
        <div className="mt3">
          {therapyTypes.map(type => (
            <CheckboxRHF name={`therapyTypes|${type}`} key={type}>
              {type}
            </CheckboxRHF>
          ))}
        </div>
      </InputWrapper>
      <InputWrapper name="funFact" label={providerProfileLabels.about}>
        <Textarea name="funFact" ref={register} />
      </InputWrapper>
    </div>
  );
}

const createAccountDetailsFormValues = (provider: ProfileProps['provider']) => {
  return pick(
    provider,
    'providerType',
    'dosespotId',
    'npi',
    'geoStates',
    'role',
    'activityStatus',
    'guaranteedPay'
  );
};

const roleToAccountInfo: Record<UpdateStaffRole, { title: string; description: string }> = {
  universityAdmin: {
    title: 'Admin',
    description: 'Can collaborate, refer students, and add new university roles.',
  },
  universitySupportingClinician: {
    title: 'Supporting Clinician',
    description: 'Can collaborate and see patient data, but cannot refer students.',
  },
  universityReferrer: {
    title: 'Referrer',
    description: 'Can refer new students, but cannot collaborate or see patient data.',
  },
  universityStaff: { title: 'Staff', description: 'Can refer new students AND collaborate.' },
};

function AccountDetails({ provider, refetch, refetching }: ProfileProps) {
  const { currentProvider } = useCurrentProvider();
  const drilldownContext = useContext(ProviderDrilldownContext);

  const fields: { name: string; render: React.ReactNode; hidden?: boolean }[] = [
    {
      name: 'Account Status',
      render: get(activityTypeCopy, provider.activityStatus as string),
    },
    {
      name: 'Account Type',
      render: roleToAccountInfo[provider.role as UpdateStaffRole]?.title,
      hidden: !isMantraOrMcpAdmin(currentProvider),
    },
    {
      name: 'Provider Type',
      render: capitalize(provider.providerType ?? 'Provider'),
      hidden: isMcpOrReferralUser(provider),
    },
    {
      name: 'Guaranteed Pay (W2) Provider',
      render: provider.guaranteedPay ? 'Yes' : 'No',
      hidden: isMcpOrReferralUser(provider),
    },
    {
      name: 'Care Type',
      render: formatProviderCareTypes(provider),
      hidden: isMcpOrReferralUser(provider),
    },
    { name: 'NPI', render: provider.npi, hidden: isMcpOrReferralUser(provider) },
    {
      name: 'Active States',
      render: provider.geoStates.join(', '),
      hidden: isMcpOrReferralUser(provider),
    },
    {
      name: 'Organizations Served',
      render: (
        <>
          {provider.organizations.map(org => (
            <TextIcon preset="university" key={org.id}>
              {org.name}
            </TextIcon>
          ))}
          {provider.organizations.length === 0 && 'None'}
        </>
      ),
      hidden: isMcpOrReferralUser(provider),
    },
    {
      name: 'In-Network Plans',
      render: provider.insuranceCredentials.map(i => `${i.payer.name} - ${i.state}`).join('\n'),
      hidden: isMcpOrReferralUser(provider),
    },
  ];

  const [showingModal, setShowingModal] = useState(false);
  const modalForm = useForm({ defaultValues: createAccountDetailsFormValues(provider) });
  const [save, saveMutation] = useAdminUpdateProviderMutation();

  const onSubmit = modalForm.handleSubmit(async ({ role, ...values }) => {
    const updatedValues = { ...values, dosespotId: Number(values.dosespotId) };
    await save({
      variables: {
        input: {
          id: provider.id,
          role: Object.values(UpdateStaffRole).some(v => v === role)
            ? (role as UpdateStaffRole)
            : null,
          ...updatedValues,
        },
      },
    });
    drilldownContext?.refetch();
    const refetched = await refetch();
    setShowingModal(false);
    if (refetched.data?.adminProvider) {
      modalForm.reset(createAccountDetailsFormValues(refetched.data.adminProvider));
    }
  });

  return (
    <div>
      <Headline
        onEdit={() => setShowingModal(true)}
        canEdit={
          isMantraAdmin(currentProvider) ||
          (isMcpAdmin(currentProvider) && currentProvider.id !== provider.id)
        }
      >
        Account Details
      </Headline>
      <FieldsTable fields={fields.filter(f => !f.hidden).map(f => [f.name, f.render])} />
      <Modal
        isOpen={showingModal}
        onClose={() => setShowingModal(false)}
        unstable_ModalBackdropScroll
        autoFocus={false}
      >
        <ModalStyle.body>
          <FormProvider {...modalForm}>
            <EditAccountDetails provider={provider} />
            <FinalButton
              onClick={onSubmit}
              loading={saveMutation.loading || refetching}
              className="w-100"
            >
              Save
            </FinalButton>
          </FormProvider>
        </ModalStyle.body>
      </Modal>
    </div>
  );
}

const EditAccountDetails = ({ provider }: { provider: ProfileProps['provider'] }) => {
  const { currentProvider } = useCurrentProvider();
  const providerTypeOptions = [
    'Psychologist',
    'Licensed Therapist',
    'Psychiatric Nurse Practitioner',
    'Psychiatrist',
  ].map(i => ({ label: i, id: i }));

  const activityTypeOptions = activityTypes.map(t => ({ label: activityTypeCopy[t], id: t }));
  const mcpProviderOrg = isMcpOrReferralUser(provider) && provider.organizations[0];
  const selectedRole = useWatch<UpdateStaffRole>({ name: 'role' });
  const selectedRoleInfo = selectedRole && roleToAccountInfo[selectedRole];

  return (
    <div>
      <Text.h3>Account Details</Text.h3>
      {isMantraOrMcpAdmin(currentProvider) && mcpProviderOrg && (
        <InputWrapper name="role" label="Account Type">
          <SelectRHF name="role" options={getMcpRoleOptionsV2(mcpProviderOrg)} />
          {selectedRoleInfo && (
            <Text.caption>
              {selectedRoleInfo.title}: {selectedRoleInfo.description}
            </Text.caption>
          )}
        </InputWrapper>
      )}
      {isMantraAdmin(currentProvider) && isMcpOrReferralUser(provider) && (
        <InputWrapper name="activityStatus" label="Account Status">
          <SelectRHF name="activityStatus" options={activityTypeOptions} />
        </InputWrapper>
      )}
      {!isMcpOrReferralUser(provider) && (
        <>
          <InputWrapper name="activityStatus" label="Account Status">
            <SelectRHF name="activityStatus" options={activityTypeOptions} />
          </InputWrapper>
          <InputWrapper name="providerType" label="Provider Type">
            <SelectRHF name="providerType" options={providerTypeOptions} />
          </InputWrapper>
          <InputWrapper name="guaranteedPay" label="Guaranteed Pay (W2) Provider">
            <CheckboxRHF name="guaranteedPay" />
          </InputWrapper>
          <MantraAdminOnly>
            <InputWrapper name="dosespotId" label="Dosespot ID">
              <InputRHF type="number" name="dosespotId" />
            </InputWrapper>
          </MantraAdminOnly>
          <InputWrapper name="npi" label="NPI">
            <InputRHF name="npi" />
          </InputWrapper>
          <InputWrapper name="geoStates" label="Active States">
            {/* @TODO (add rules to ensure more than 1) */}
            <MultiSelectRHF name="geoStates" options={stateOptions} />
          </InputWrapper>
        </>
      )}
    </div>
  );
};

type FieldTuple = [string, React.ReactNode];
const FieldsTable: React.FC<{ fields: FieldTuple[] }> = ({ fields }) => {
  return (
    <Table>
      <tbody>
        {fields.map(([label, value]) => (
          <tr key={label}>
            <TableCell style={{ width: 204 }}>
              <Text.label>{label}</Text.label>
            </TableCell>
            <TableCell>
              <Text.body style={{ margin: 0 }}>{value || '--'}</Text.body>
            </TableCell>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const InputWrapper: React.FC<FormControlProps> = ({ name, ...rest }) => {
  return <FormControl className="mt4 mb4" name={name} {...rest} />;
};

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0px 8px;
  vertical-align: top;
`;

const TableCell = styled.td`
  white-space: pre-wrap;
  vertical-align: top;
`;

const Headshot = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 30px;
`;

const PhotoContainer = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.hr`
  border-bottom: none;
  border-top: 1px solid #c5cee0;
  width: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
`;

const activityTypes = ['active', 'on-hold', 'deactivated'] as const;
const activityTypeCopy: Record<typeof activityTypes[number], string> = {
  active: 'Active',
  'on-hold': 'On Hold',
  deactivated: 'Deactivated',
};
