import React, { useEffect } from 'react';
import { ListViewInterface } from './ListViewInterface';
import { Column, Filter, ListViewConfig, NestedFilter, useListViewUi } from './utils';

export interface SynchronousFilter<T> extends Filter {
  test: (item: T, filterValue: string) => boolean;
}

export interface SynchronousNestedFilter<T> extends NestedFilter {
  test: (item: T, filterValue: string) => boolean;
}

export interface SynchronousColumn<T> extends Column<T> {
  sort?: (a: T, b: T) => number;
}

export interface ListViewProps<T> extends ListViewConfig<T> {
  data: T[];
  columns: SynchronousColumn<T>[];
  filters?: (SynchronousFilter<T> | SynchronousNestedFilter<T>)[];
}

export function ListView<T>({ data, ...config }: ListViewProps<T>) {
  const { columns, search, filters = [], dateFilters, paginate = true, pageSize = 50 } = config;
  const uiState = useListViewUi(config);
  const { filterValues, sortColumn, sortDirection, currentPageNum, setPage, debouncedSearchValue } =
    uiState;

  // Search
  let filteredData: T[] = [...data];
  if (search) {
    filteredData = filteredData.filter(item => search(item, debouncedSearchValue));
  }

  // Filter
  for (const [key, value] of Object.entries(filterValues)) {
    if (value) {
      const filter = filters.find(i => i.key === key);
      if (filter) filteredData = filteredData.filter(item => filter.test(item, value));
      const dateFilter = dateFilters?.find(i => i.key === key);
      if (dateFilter && Array.isArray(value) && value.length === 2) {
        const lower = new Date(value[0]).getTime();
        const upper = new Date(value[1]).getTime();
        filteredData = filteredData.filter(item => {
          const rawItemDate = dateFilter.getTime(item);
          if (!rawItemDate) return false;
          const itemTime = new Date(rawItemDate).getTime();
          return itemTime > lower && itemTime < upper;
        });
      }
    }
  }

  // Sort
  let processedData = [...filteredData];
  if (sortColumn) {
    const col = columns.find(i => i.key === sortColumn);
    const predicate = col?.sort ? col.sort : () => 0;
    processedData.sort(sortDirection === 'desc' ? (a, b) => -predicate(a, b) : predicate);
  }

  // Paginate
  const pageIndexStart = pageSize * (currentPageNum - 1);
  const pageIndexEnd = Math.min(pageIndexStart + pageSize, processedData.length);
  const hasNextPage = processedData.length > pageIndexEnd;
  if (paginate) {
    processedData = processedData.slice(pageIndexStart, pageIndexEnd);
  }

  // Whenever length of data changes, return to first page
  useEffect(() => {
    setPage(1);
  }, [filteredData.length, setPage]);

  return (
    <ListViewInterface data={processedData} hasNextPage={hasNextPage} {...config} {...uiState} />
  );
}
