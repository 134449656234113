import { Input } from 'baseui/input';
import { Modal } from 'baseui/modal';
import { Notification } from 'baseui/notification';
import { StatefulSelect } from 'baseui/select';
import { camelCase } from 'lodash';
import omit from 'lodash/omit';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MaskedPhoneInput } from '../../Components/EzForm/MaskedInputs';
import { FinalButton } from '../../Components/FinalButton';
import { ImageUploadSelector } from '../../Components/ImageUploadSelector';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { isMcpUser, PermsOnly, useCurrentProvider } from '../../Components/Permissions';
import { Modal as MantraModal, Text } from '../../globalStyles';
import {
  SimpleAdminProviderQuery,
  Permission,
  UpdateProvider,
  UpdateStaffRole,
  useAdminCompleteProviderPhotoUploadMutation,
  useAdminUpdateProviderMutation,
  useOrganizationEntitlementsQuery,
} from '../../graphQL';
import { useImageUploader } from '../../Hooks';
import { prettifyRole } from '../../modelUtils/provider';
import { stripPhoneNumber } from '../../utils';
import * as Styles from './Styles';
import { getMcpRoleOptions } from './util';

interface Props {
  provider: SimpleAdminProviderQuery['adminProvider'];
  onClose: () => void;
  refetch: () => void;
}

interface FormFields {
  role: string;
  firstName: string;
  lastName: string;
  postNominalTitles: string;
  pronouns: string;
  npi: string;
  classification: string;
  email: string;
  phone: string;
  photo?: File;
  geoStates?: string;
}

const pronounOptions = ['He/Him/His', 'She/Her/Hers', 'They/Them/Theirs'].map(i => ({
  label: i,
  id: i,
}));

export function EditProfileModal({ provider, refetch, onClose }: Props) {
  const { hasPermission } = useCurrentProvider();
  const { register, handleSubmit, setValue, control } = useForm<FormFields>({
    defaultValues: { ...provider, geoStates: provider.geoStates.join(', ') } as FormFields,
  });
  useEffect(() => {
    register({ name: 'role' });
    register({ name: 'pronouns' });
    register({ name: 'photo' });
  });
  const [updateProvider, updateProviderMutation] = useAdminUpdateProviderMutation();
  const [updatePortrait] = useAdminCompleteProviderPhotoUploadMutation();
  const {
    data,
    loading,
    error: loadingError,
  } = useOrganizationEntitlementsQuery({
    variables: { id: provider.organizations[0]?.id },
    skip: !isMcpUser(provider) || !provider.organizations.length,
  });
  const formRef = useRef<HTMLFormElement>(null);
  const [saving, setSaving] = useState(false);

  const { upload, accepts, onDropRejected, error } = useImageUploader({
    completeFunction: (key: string) =>
      updatePortrait({ variables: { providerId: provider.id, key } }),
    callback: refetch,
  });

  if (loading)
    return (
      <Modal isOpen unstable_ModalBackdropScroll>
        <LoadingPage />
      </Modal>
    );
  if (loadingError) return null;

  const onSubmit = handleSubmit(async (values: FormFields) => {
    try {
      setSaving(true);
      const input: UpdateProvider = {
        ...omit(values, 'photo', 'role', 'phone', 'geoStates'),
        id: provider.id,
        phone: stripPhoneNumber(values.phone ?? ''),
        role:
          values.role !== provider.role ? (camelCase(values.role) as UpdateStaffRole) : undefined,
      };
      if (hasPermission(Permission.MantraAdmin)) {
        input.geoStates = values.geoStates?.split(',').map(i => i.trim());
      }
      await updateProvider({ variables: { input } });
      if (values.photo) {
        await upload(values.photo);
      } else if (!values.photo && provider.portrait?.url) {
        await updatePortrait({ variables: { providerId: provider.id, key: null } });
      }
      refetch();
      onClose();
    } catch (err) {
      if (formRef.current) {
        formRef.current.scrollIntoView();
      }
    } finally {
      setSaving(false);
    }
  });

  const roleOptions = data ? getMcpRoleOptions(data.organization) : [];

  return (
    <Modal isOpen unstable_ModalBackdropScroll onClose={onClose}>
      <MantraModal.body>
        <Text.h1>Edit User Info</Text.h1>
        <Styles.EditProfileForm onSubmit={onSubmit} ref={formRef}>
          {updateProviderMutation.error && (
            <Notification kind="negative">
              {updateProviderMutation.error.message.replace('GraphQL Error:', '')}
            </Notification>
          )}
          <div>
            <Styles.FieldLabel>Account Status</Styles.FieldLabel>
            <Text.bodyGrey>Active</Text.bodyGrey>
          </div>
          <div>
            <Styles.FieldLabel>Account Type</Styles.FieldLabel>
            {roleOptions.find(i => i.id === provider.role) &&
            hasPermission(Permission.ProviderEdit) ? (
              <StatefulSelect
                initialState={{ value: [roleOptions.find(i => i.id === provider.role)!] }}
                options={roleOptions}
                onChange={({ option }) => setValue('role', option!.id as string)}
                clearable={false}
              />
            ) : (
              provider.role && <Text.bodyGrey>{prettifyRole(provider.role)}</Text.bodyGrey>
            )}
          </div>
          <Styles.Divider />
          <Text.h2>Personal</Text.h2>
          <div>
            <Styles.FieldLabel>First Name</Styles.FieldLabel>
            <Input inputRef={register} name="firstName" />
          </div>
          <div>
            <Styles.FieldLabel>Last Name</Styles.FieldLabel>
            <Input inputRef={register} name="lastName" />
          </div>
          <div>
            <Styles.FieldLabel>Post-Nominal Title(s)</Styles.FieldLabel>
            <Input name="postNominalTitles" inputRef={register} placeholder="Ph.D, LCSW, PNP" />
          </div>
          <div>
            <Styles.FieldLabel>Pronouns</Styles.FieldLabel>
            <StatefulSelect
              options={pronounOptions}
              initialState={{
                value: provider.pronouns
                  ? [pronounOptions.find(i => i.id === provider.pronouns)!]
                  : [],
              }}
              onChange={({ option }) => setValue('pronouns', option!.id as string)}
              clearable={false}
            />
          </div>
          <div>
            <Styles.FieldLabel>NPI</Styles.FieldLabel>
            <Input inputRef={register} name="npi" />
          </div>
          <PermsOnly allowed={Permission.MantraAdmin}>
            <div>
              <Styles.FieldLabel>
                States (use two-letter abbreviations separated by commas)
              </Styles.FieldLabel>
              <Input inputRef={register} name="geoStates" />
            </div>
          </PermsOnly>
          <Styles.Divider />
          <Text.h2>Professional</Text.h2>
          <div>
            <Styles.FieldLabel>Job Title</Styles.FieldLabel>
            <Input inputRef={register} name="classification" />
          </div>
          <div>
            <Styles.FieldLabel>Email</Styles.FieldLabel>
            <Input inputRef={register} name="email" type="email" />
          </div>
          <div>
            <Styles.FieldLabel>Office Phone Number</Styles.FieldLabel>
            <MaskedPhoneInput name="phone" ext control={control} />
          </div>
          <Styles.Divider />
          <Text.h2>Profile Photo</Text.h2>
          <ImageUploadSelector
            initialUrl={provider.portrait?.url}
            onSelect={file => setValue('photo', file ?? undefined)}
            accepts={accepts}
            errorMessage={error}
            onDropRejected={onDropRejected}
          />
          <FinalButton type="submit" kind="primary" className="w-100" loading={saving}>
            Save
          </FinalButton>
        </Styles.EditProfileForm>
      </MantraModal.body>
    </Modal>
  );
}
